<template>
    <div
        v-if="files && files.length"
        class="form-file-list"
    >
        <div
            v-for="file in files"
            :key="file.id"
        >
            <file
                :file="file"
                :deletable="deletable"
                :filetype="filetype"
            />
        </div>
    </div>
    <p
        v-else
        class="text-grey4"
    >
        This {{ itemType }} has no {{ filetype }}s
    </p>
</template>
<script>
import File from './File';

export default {

    name: 'Files',
    components: {
        File,
    },
    props: {
        files: {
            type: Array,
            required: true,
        },
        filetype: {
            type: String,
            default: 'file',
        },
        deletable: {
            type: Boolean,
            default: false,
        },
        itemType: {
            type: String,
            default: 'land parcel',
        },
    },
    data() {
        return {};
    },

    methods: {},
};
</script>
