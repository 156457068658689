var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { ref: "div", staticClass: "formulate-suffix" }, [
    _c("div", { staticClass: "formulate-size" }, [
      _c(
        "div",
        {
          ref: "wrapper",
          staticClass:
            "formulate-input-wrapper formulate-input-wrapper--padding",
          style: {
            paddingRight: `${_vm.padding}px`,
          },
        },
        [
          _vm._t("default"),
          _vm._v(" "),
          _c(
            "div",
            { ref: "suffix", staticClass: "formulate-input-wrapper-suffix" },
            [_vm._t("suffix")],
            2
          ),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }