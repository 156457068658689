<template>
    <div
        class="accordion-item"
        :class="{
            'accordion-item--active': visible
        }"
    >
        <div
            ref="accordianToggle"
            class="accordion-item-title"
            @click="toggle"
        >
            <h4>{{ title }}</h4>
            <span>
                <SvgController type="caret-down" />
            </span>
        </div>

        <transition
            name="accordion"
            @enter="start"
            @after-enter="end"
            @before-leave="start"
            @after-leave="end"
        >
            >
            <div
                v-show="visible"
                class="accordion-item-body"
            >
                <slot />
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    name: 'AccordionItem',
    components: {},

    inject: ['Accordion'],

    props: {
        title: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            index: null,
        };
    },

    computed: {
        visible() {
            return this.index === this.Accordion.active;
        },
    },

    created() {
        this.index = this.Accordion.count++;
    },

    methods:{
        toggle() {
            this.Accordion.active = this.visible ? null : this.index;
        },

        start(elm) {
            elm.style.height = elm.scrollHeight + 'px';
        },

        end(elm) {
            elm.style.height = `calc(100% - ${this.$refs.accordianToggle.offsetHeight * this.Accordion.count}px)`;
        },
    },
};
</script>
