var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      !_vm.$page.props.hasItems
        ? _c(
            "empty",
            { attrs: { type: "land parcels" } },
            [
              _vm.$page.props.loggedUser.can.includes("plot_save")
                ? _c(
                    "inertia-link",
                    {
                      staticClass: "btn-reset btn btn--primary",
                      attrs: { href: _vm.$route("plot.create") },
                    },
                    [
                      _c("SvgController", { attrs: { type: "plus" } }),
                      _vm._v(" "),
                      _c("span", [_vm._v("Create My First Land Parcel")]),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _c(
            "div",
            [
              _c("TableGridLayout", {
                attrs: {
                  columns: _vm.columns,
                  rows: _vm.dataFormat,
                  mode: "remote",
                  "total-records": _vm.plots.total,
                  "grid-layout": _vm.gridLayout,
                  "has-header": _vm.hasHeader,
                  "has-footer": _vm.hasFooter,
                  output: _vm.output,
                },
                on: { onRowClick: _vm.onRowClick },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "header-left",
                      fn: function () {
                        return [
                          _c("list-filter", {
                            attrs: {
                              fields: _vm.filterOptions,
                              route: _vm.filterRoute,
                              "search-placeholder": "Search plots",
                              section: "plots",
                            },
                          }),
                        ]
                      },
                      proxy: true,
                    },
                    _vm._l(_vm.$scopedSlots, function (_, name) {
                      return {
                        key: name,
                        fn: function (slotData) {
                          return [
                            name.indexOf("cell(") === 0
                              ? _vm._t(name, null, null, slotData)
                              : _vm._e(),
                          ]
                        },
                      }
                    }),
                    _vm.plots.links
                      ? {
                          key: "footer",
                          fn: function () {
                            return [
                              _c("pagination", {
                                attrs: {
                                  links: _vm.plots.links,
                                  section: "plots",
                                },
                              }),
                            ]
                          },
                          proxy: true,
                        }
                      : null,
                  ],
                  null,
                  true
                ),
              }),
              _vm._v(" "),
              _c("modal", {
                ref: "locationModal",
                staticClass: "modal--location",
                scopedSlots: _vm._u([
                  {
                    key: "header",
                    fn: function () {
                      return [_c("h3", [_vm._v("Land Parcel Location")])]
                    },
                    proxy: true,
                  },
                  {
                    key: "body",
                    fn: function () {
                      return [
                        _c(
                          "p",
                          [
                            _c("SvgController", {
                              attrs: { type: "map-icon" },
                            }),
                            _vm._v(_vm._s(_vm.locationData.address)),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("draw-map", {
                          ref: "map",
                          attrs: {
                            edit: false,
                            "land-parcels": [_vm.locationData.plot],
                            leases: _vm.locationData.rentalLocations,
                            "show-geo-input": false,
                            "lat-long":
                              _vm.locationData.location_data.layer.center,
                            zoom: 10,
                            type: "plot",
                            "show-keys": "",
                            "fit-bounds": "landParcels",
                          },
                        }),
                        _vm._v(" "),
                        _c("p", [_vm._v(" ")]),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }