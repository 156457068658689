var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: _vm.context.classes.file }, [
    !!(_vm.imagePreview && _vm.currentFile.previewData)
      ? _c("div", { class: `formulate-file-${_vm.fileType}-preview` }, [
          _vm.fileType === "image"
            ? _c("img", {
                class: _vm.context.classes.fileImagePreviewImage,
                attrs: { src: _vm.currentFile.previewData },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.fileType === "video"
            ? _c(
                "video",
                { staticClass: "formulate-file-video-preview-video" },
                [
                  _c("source", { attrs: { src: _vm.currentFile.previewData } }),
                  _vm._v(
                    "\n            Your browser does not support the video tag.\n        "
                  ),
                ]
              )
            : _vm._e(),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", {
      class: _vm.context.classes.fileName,
      attrs: { title: _vm.currentFile.name },
      domProps: { textContent: _vm._s(_vm.currentFile.name) },
    }),
    _vm._v(" "),
    _vm.currentFile.progress !== false
      ? _c(
          "div",
          {
            class: _vm.context.classes.fileProgress,
            attrs: {
              "data-just-finished": _vm.currentFile.justFinished,
              "data-is-finished":
                !_vm.currentFile.justFinished && _vm.currentFile.complete,
            },
          },
          [
            _c("div", {
              class: _vm.context.classes.fileProgressInner,
              style: { width: _vm.currentFile.progress + "%" },
            }),
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    (_vm.file.complete && !_vm.file.justFinished) || _vm.file.progress === false
      ? _c("div", {
          class: _vm.context.classes.fileRemove,
          on: { click: _vm.file.removeFile },
        })
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }