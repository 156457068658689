var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "datetime",
    _vm._g(
      _vm._b(
        {
          class: `formulate-input-element formulate-input-element--${_vm.context.type}`,
          attrs: {
            "data-type": _vm.context.type,
            type: _vm.context.attributes.datetype,
            "min-datetime": _vm.context.attributes.min,
            "max-datetime": _vm.context.attributes.max,
            format: _vm.context.slotProps.component.format,
            flow: _vm.context.slotProps.component.flow,
            "value-zone": _vm.valueZone,
          },
          model: {
            value: _vm.context.model,
            callback: function ($$v) {
              _vm.$set(_vm.context, "model", $$v)
            },
            expression: "context.model",
          },
        },
        "datetime",
        _vm.context.attributes,
        false
      ),
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }