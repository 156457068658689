<template>
    <auth-layout
        :logo="true"
        :component="$options.name"
    >
        <FormulateForm
            :errors="{
                email: errors.email,
                password: errors.password,
                password_confirmation: errors.password_confirmation,
            }"
            @submit="form.post($route('password.update'))"
        >
            <div class="form-page">
                <h3>Reset Password</h3>
                <div class="formulate-input">
                    <p>
                        Enter your email address and then create your new password below.
                        <br>
                        If your token has expired, click Re-send Email to get another one.
                    </p>
                </div>
                <FormulateInput
                    v-model="form.email"
                    type="email"
                    name="email"
                    label="Email Address"
                    validation="required|email"
                />
                <FormulateInput
                    v-model="form.password"
                    type="password"
                    name="password"
                    label="Password"
                    validation="required|min:8,length"
                />
                <FormulateInput
                    v-model="form.password_confirmation"
                    type="password"
                    name="password_confirmation"
                    label="Password Confirmation"
                    validation="required|min:8,length|confirm:password"
                    validation-name="Password confirmation"
                />
                <div class="formulate-input btn--group">
                    <inertia-link
                        :href="$route('password.email')"
                        class="btn btn--secondary"
                    >
                        Re-send Email
                    </inertia-link>
                    <button
                        class="btn--reset btn btn--primary"
                        type="submit"
                    >
                        Submit
                    </button>
                </div>
            </div>
        </FormulateForm>
    </auth-layout>
</template>

<script>
import AuthLayout from 'Components/Layouts/AuthLayout.vue';
export default {
    name: 'ResetPassword',

    components: {AuthLayout},

    props: {
        errors: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            form: this.$inertia.form({
                email: null,
                password: null,
                password_confirmation: null,
                token: this.$route().params.token,
            }),
        };
    },
    computed:{},
    mounted() {},
};
</script>
