var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "app-layout",
    { attrs: { section: "contact" } },
    [
      _c("page-header", { attrs: { title: "Contact Us", icon: "contact" } }),
      _vm._v(" "),
      _c("div", { staticClass: "center" }, [
        _c("p", [
          _vm._v(
            "In order to contact the BNG Partnership team, please send an email to "
          ),
          _c("a", { attrs: { href: "mailto:info@bng.eco" } }, [
            _vm._v("info@bng.eco"),
          ]),
          _vm._v("."),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }