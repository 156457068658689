<template>
    <div>
        <FormulateForm
            v-if="parent"
            v-model="form"
            :schema="schema"
            :errors="form.errors"
            class="flex-col"
            @submit="submitForm"
        />
    </div>
</template>

<script>
export default {
    name: 'AddMedia',
    components: {},

    props: {
        fileType: {
            type: String,
            default: 'media',
        },
        documentTypes: {
            type: Array,
            default: () => ['Attachment'],
        },
        parent: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            splashVideo: null,
            form: this.$inertia.form({
                display_name: null,
                filepath: null,
                status: null,
                type: this.computedFileType,
                parent_type: this.parent.type,
                parent_id: this.parent.id,
            }),
            schema: [],
        };
    },
    created() {
        if (!this.parent) return;
        this.form = this.$inertia.form({
            display_name: null,
            filepath: null,
            status: null,
            document_type: null,
            type: 'photo',
            parent_type: this.parent.type,
            parent_id: this.parent.id,
        });
        this.schema = [
            {
                name: 'display_name',
                label: 'Display Name',
                type: 'text',
                required: true,
            }, {
                name: 'filepath',
                label: 'Media',
                type: 'image',
                required: true,
                validation: 'mime:image/jpeg,image/png,image/gif,video/x-flv,video/mp4,application/x-mpegURL,video/MP2T,video/3gpp,video/quicktime,video/x-msvideo,video/x-ms-wmv',
                uploadUrl: '/attachments/upload',
                uploadBehavior: 'delayed',
            }, {
                name: 'document_type',
                label: this.documentTypes.length > 1 ? 'Type of Document' : '',
                type: this.documentTypes.length > 1 ? 'select' : 'hidden',
                value: this.documentTypes.length === 1 ? this.documentTypes[0] : '',
                options: this.documentTypes,
                required: true,
            }, {
                type: 'submit',
            },
        ];
    },
    methods:{
        submitForm(data) {
            data.type = 'photo';

            if (['flv', 'mp4', 'm3u8', 'ts', '3gp', 'mov', 'avi', 'wm'].includes(data.filepath[0].ext)) {
                data.type = 'video';
            }

            this.$inertia.post(this.$route('attachment.store'), data, {
                onSuccess: () => {
                    this.$parent.closeModal();
                },
            });
        },
    },
};
</script>
