<template>
    <modal
        ref="deleteModal"
        width="50em"
    >
        <template #header>
            <h3>
                Delete Contract
            </h3>
        </template>
        <template #body>
            <p>Are you sure?</p>
            <p>Deleting this contract will remove these attachments.</p>
            <TableGridLayout
                :columns="columns"
                :rows="attachments"
                :output="['table']"
                mode="remote"
                :total-records="attachments.length"
            />
            <button
                class="btn--reset btn"
                @click="closeModal"
            >
                Cancel
            </button>
            <button
                class="btn--reset btn btn--red"
                type="submit"
                @click="deleteContract"
            >
                Confirm
            </button>
        </template>
    </modal>
</template>

<script>
import Modal from 'Utilities/modal/Modal';
export default {
    name: 'ContractDelete',
    components: {
        Modal,
    },
    props: {
        show: {
            type: Boolean,
            default: false,
        },
        parent: {
            type: Object,
            default: () => ({}),
        },
        contract: {
            type: Object,
            default: () => ({}),
        },
        attachments: {
            type: Array,
            default: () => ([]),
        },
    },
    data() {
        return {
            columns: [{
                label: 'Date',
                field: 'created_at',
                sortable: false,
            },{
                label: 'Status',
                field: 'status',
                spanClass: 'status-pill type-{status}',
                sortable: false,
            },{
                label: '',
                field: 'display_name',
                sortable: false,
            }],
        };
    },
    watch: {
        show(value) {
            if (value) {
                this.$refs.deleteModal.openModal();
            } else {
                this.$refs.deleteModal.closeModal();
            }
        },
    },
    methods: {
        openModal() {
            this.$refs.deleteModal.openModal();
        },
        closeModal() {
            this.$refs.deleteModal.closeModal();
        },
        deleteContract() {
            this.$confirm(
                {
                    title: 'Delete Contract',
                    message: 'Are you sure you want to delete this contract and attachments? This action can\'t be undone.',
                    confirmBtnClass: 'btn--red',
                    confirmBtnText: 'Delete',
                    callback: confirm => {
                        if (confirm) {
                            this.$inertia.delete(this.$route('contract.destroy', this.contract.id), {
                                onSuccess: () => {
                                    this.$refs.deleteModal.closeModal();
                                    this.$emit('afterUpdate');
                                },
                            });
                        }
                    },
                },
            );
        },
    },
};
</script>
