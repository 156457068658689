var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: _vm.context.classes.element,
      attrs: { "data-type": _vm.context.type },
    },
    [
      _c("label", { staticClass: "toggle-checkbox-container" }, [
        _c(
          "input",
          _vm._g(
            _vm._b(
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.context.model,
                    expression: "context.model",
                  },
                ],
                attrs: { type: "checkbox", disabled: _vm.disabled },
                domProps: {
                  value: _vm.context.value,
                  checked: Array.isArray(_vm.context.model)
                    ? _vm._i(_vm.context.model, _vm.context.value) > -1
                    : _vm.context.model,
                },
                on: {
                  blur: _vm.context.blurHandler,
                  change: function ($event) {
                    var $$a = _vm.context.model,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = _vm.context.value,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(_vm.context, "model", $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.context,
                            "model",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(_vm.context, "model", $$c)
                    }
                  },
                },
              },
              "input",
              _vm.attributes,
              false
            ),
            _vm.$listeners
          )
        ),
        _vm._v(" "),
        _c("div", { staticClass: "toggle-checkbox-buttons" }, [
          _c(
            "div",
            {
              staticClass: "toggle-checkbox-buttons--item",
              class: {
                "toggle-checkbox--active": !_vm.context.model,
                "toggle-checkbox--disabled": _vm.disabled,
              },
              attrs: { disabled: _vm.disabled },
            },
            [
              _vm._v(
                "\n                " +
                  _vm._s(
                    _vm.context.attributes.placeholder &&
                      typeof _vm.context.attributes.placeholder === "object"
                      ? _vm.context.attributes.placeholder[0]
                      : "No"
                  ) +
                  "\n            "
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "toggle-checkbox-buttons--item",
              class: {
                "toggle-checkbox--active": _vm.context.model,
                "toggle-checkbox--disabled": _vm.disabled,
              },
              attrs: { disabled: _vm.disabled },
            },
            [
              _vm._v(
                "\n                " +
                  _vm._s(
                    _vm.context.attributes.placeholder &&
                      typeof _vm.context.attributes.placeholder === "object"
                      ? _vm.context.attributes.placeholder[1]
                      : "Yes"
                  ) +
                  "\n            "
              ),
            ]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }