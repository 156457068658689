module.exports = {
    methods: {
        hasGroup(groups) {
            if (!Array.isArray(groups)) {
                groups = [groups];
            }

            for (const group of groups) {
                if (this.$page.props.loggedUser.groups.includes(group)) {
                    return true;
                }
            }

            return false;
        },

        hasPermission(permissions) {
            if (!Array.isArray(permissions)) {
                permissions = [permissions];
            }

            for (const permission of permissions) {
                if (this.$page.props.loggedUser.can.includes(permission)) {
                    return true;
                }
            }

            return false;
        },

        hasPolicy(policies) {
            if (!Array.isArray(policies)) {
                policies = [policies];
            }

            for (const policy of policies) {
                if (this.$page.props.loggedUser.policies.includes(policy)) {
                    return true;
                }
            }

            return false;
        },

    },
};
