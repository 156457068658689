<template>
    <section
        id="site-header"
    >
        <div
            id="site-header-center"
            class="center flex-space-between"
        >
            <div id="site-header-left">
                <div
                    id="site-header-logo"
                    class="flex-center"
                >
                    <button
                        id="site-header-sidebar"
                        class="btn-reset"
                        @click="$emit('toggleSidebar')"
                    >
                        <div />
                    </button>
                    <inertia-link :href="$route('home')">
                        <h1 class="hidden">
                            BNG Partnership
                        </h1>
                        <SvgController type="logo-full" />
                        <SvgController type="logo" />
                    </inertia-link>
                </div>
            </div>
            <div
                id="site-header-right"
                class="flex-center"
            >
                <div
                    id="site-header-notification"
                    class="site-header-item"
                >
                    <HeaderNotifications />
                </div>
                <div
                    id="site-header-user"
                    class="site-header-item"
                >
                    <HeaderUser />
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import HeaderUser from './HeaderUser';
import HeaderNotifications from './HeaderNotifications';
export default {
    name: 'Header',
    components: {
        HeaderUser,
        HeaderNotifications,
    },
};
</script>
