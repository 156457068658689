<template>
    <FormulateForm
        :errors="form.errors"
        class="flex-col"
        @submit="submitForm()"
    >
        <FormulateInput
            v-model="form.current_password"
            type="password"
            name="current_password"
            label="Current password"
            validation="required"
            validation-name="Current password"
        />
        <FormulateInput
            v-model="form.password"
            type="password"
            name="password"
            label="New Password"
            validation="required|min:8,length"
            validation-name="New password"
        />
        <FormulateInput
            v-model="form.password_confirmation"
            type="password"
            name="password_confirmation"
            label="Confirm new password"
            validation="required|min:8,length|confirm:password"
            validation-name="Password confirmation"
        />
        <div class="formulate-input btn--group">
            <a
                href="#"
                class="btn"
                @click="$emit('closeModal')"
            >
                Cancel
            </a>
            <button
                class="btn--reset btn btn--primary"
                type="submit"
            >
                <SvgController type="tick" />
                Save
            </button>
        </div>
    </FormulateForm>
</template>

<script>
export default {
    name: 'UpdatePassword',
    props: {
        errors: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            form: this.$inertia.form({
                current_password: null,
                password: null,
                password_confirmation: null,
            }),
        };
    },
    methods: {
        submitForm() {
            this.form.put(this.$route('user-password.update'), {
                onSuccess: () => {
                    this.$emit('closeModal');
                },
            });
        },
    },
};
</script>
