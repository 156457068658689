<template>
    <div
        :class="`dropdown dropdown--${position}`"
        v-on="listeners"
    >
        <slot />
        <transition name="fade">
            <div
                v-show="value"
                ref="dropdown"
                class="dropdown-menu"
                :class="dropdownMenuClass"
                :style="[positionStyles]"
                @click.stop
            >
                <slot name="dropdown" />
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    name: 'DropdownMenu',
    props: {
        position: {
            type: String,
            default: 'left', // left||right
        },
        value: {
            type: Boolean,
            default: false,
        },
        dropdownMenuClass: {
            type: String,
            default: '',
        },
        closeOnClickOutside: {
            type: Boolean,
            default: true,
        },
        trigger: {
            type: String,
            default: 'click',
        },
    },
    data() {
        return {
            top: false,
            className: '',
            transition: 350,
            positionStyles: {},
        };
    },

    computed: {
        listeners() {
            const triggers = this.trigger.split(',');
            const listeners = {};

            triggers.forEach((trigger) => {
                switch (trigger) {
                    case 'click':
                        listeners.click = () => {
                            this.toggleMenu();
                        };

                        break;
                    case 'hover':
                        listeners.mouseenter = () => {
                            this.$emit('input', true);
                        };

                        listeners.mouseleave = () => {
                            this.$emit('input', false);
                        };

                        break;
                    default: break;
                }
            });

            return listeners;
        },
    },
    watch: {
        value() {
            if (this.value) {
                this.showLogic();
            }
        },
    },
    created() {
        document.body.addEventListener('click', this.closeMenu);
        window.addEventListener('resize', this.resize);

        this.showLogic();
    },
    destroyed() {
        document.body.removeEventListener('click', this.closeMenu);
        window.removeEventListener('resize', this.resize);
    },
    methods: {
        showLogic() {
            const objThis = this;

            objThis.positionStyles = {};

            if (objThis.value) {
                setTimeout(() => {
                    const objRect = objThis.$refs.dropdown.getBoundingClientRect();
                    const intWidth = (window.innerWidth || document.documentElement.clientWidth);

                    if (this.position === 'right') {
                        if (objRect.width > intWidth) {
                            objThis.positionStyles = {
                                width: intWidth + 'px',
                                right: ((intWidth - objRect.right) * -1) + 'px',
                            };
                        } else if (objRect.left < 0) {
                            objThis.positionStyles = {
                                right: objRect.left + 'px',
                            };
                        }
                    } else {
                        if (objRect.width > intWidth) {
                            objThis.positionStyles = {
                                width: intWidth + 'px',
                                left: ((objRect.left) * -1) + 'px',
                            };
                        } else if (objRect.right > intWidth) {
                            objThis.positionStyles = {
                                left: ((objRect.right - intWidth) * -1) + 'px',
                            };
                        }
                    }
                }, 10);
            }
        },

        resize() {
            if (this.value) {
                this.closeMenu();
            }
        },
        closeMenu($event) {
            if (!$event || (this.closeOnClickOutside && !this.$el.contains($event.target))) {
                this.$emit('input', false);
            }
        },
        toggleMenu() {
            this.$emit('input', !this.value);
        },
    },
};
</script>
