<template>
    <div class="convert-file-dropzone">
        <div class="convert-file-dropzone-icon">
            <slot name="icon">
                <SvgController
                    v-if="state === 'waiting'"
                    :type="iconWaiting"
                />
                <SvgController
                    v-if="state === 'uploading'"
                    :type="iconUploading"
                />
                <SvgController
                    v-if="state === 'done'"
                    :type="iconDone"
                />
            </slot>
        </div>
        <slot />
        <slot name="document">
            <small v-if="documentName">
                {{ documentName }}
            </small>
        </slot>
    </div>
</template>

<script>
export default {
    name: 'ExcelConvert',
    props: {
        documentName: {
            type: String,
            default: null,
        },
        state: {
            type: String,
            default: 'waiting',
        },
        iconWaiting: {
            type: String,
            default: 'arrow-up',
        },
        iconUploading: {
            type: String,
            default: 'dots',
        },
        iconDone: {
            type: String,
            default: 'tick',
        },
    },
};
</script>
