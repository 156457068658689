<template>
    <div
        :key="`toggle-button-${context.name}`"
        :class="context.classes.element"
        :data-type="context.type"
    >
        <label
            :key="`toggle-button-label-${context.name}`"
            class="toggle-button-container"
        >
            <input
                :key="`toggle-button-input-${context.name}`"
                v-model="context.model"
                type="checkbox"
                :value="context.value"
                :disabled="disabled"
                v-bind="attributes"
                v-on="$listeners"
                @blur="context.blurHandler"
            >
            <div
                :key="`toggle-button-bg-${context.name}`"
                class="toggle-button-background"
                :class="{'toggle-button-background--active': context.model}"
            >
                <div class="toggle-button-slider" />
            </div>

            <slot :context="context">
                <component
                    :is="context.slotComponents.label"
                    :key="`toggle-button-component-${context.name}`"
                    v-bind="context.slotProps.label"
                    :context="context"
                />
            </slot>

            <div
                v-if="color"
                ref="color"
                class="toggle-button-key"
                :style="style"
            />
        </label>

        <tooltip
            v-if="tooltipHelp"
            :content="tooltipHelp"
        />
    </div>
</template>

<script>
import FormulateInputMixin from '@braid/vue-formulate/src/FormulateInputMixin';
import Tooltip from 'Utilities/tooltip/Tooltip';

export default {
    name:'FormulateToggleButton',
    components: {
        Tooltip,
    },
    mixins: [FormulateInputMixin],

    props: {
        color: {
            type: String,
            default: null,
        },
        svgPattern: {
            type: SVGSVGElement,
            default: null,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        tooltipHelp: {
            type: String,
            default: null,
        },
    },

    computed: {
        style() {
            return {
                backgroundColor: this.color,
                color: this.color,
                refOpen: this.$refs.opentooltip,
            };
        },
    },

    mounted() {
        if (this.svgPattern && this.color) {
            this.$refs.color.append(this.svgPattern);
        }
    },
};
</script>
