<template>
    <div
        class="link"
        :style="{color: color}"
    >
        <inertia-link
            v-if="href"
            :href="href"
        >
            {{ title }}
            <span
                v-if="subtext !== null"
                class="link_subtext"
            >{{ subtext }}</span>
        </inertia-link>

        <div v-else>
            {{ title }}
            <span
                v-if="subtext !== null"
                class="link_subtext"
            >{{ subtext }}</span>
        </div>
    </div>
</template>

<script>
export default {
    components: {},

    props: {
        title: {
            type: String,
            required: true,
        },
        color: {
            type: String,
            default: '#627086',
        },
        href: {
            type: String,
            default: null,
        },
        count: {
            type: Number,
            default: null,
        },
        text: {
            type: [String, Number],
            default: null,
        },

    },
    data() {
        return {
        };
    },

    computed: {
        subtext() {
            if (this.count !== null) {
                return this.count;
            }

            if (this.text !== null) {
                return this.text;
            }

            return null;
        },
    },

    methods:{},
};
</script>
