<template>
    <div
        class="page-header"
        :data-background="background"
    >
        <div class="page-header-center flex-space-between">
            <div class="page-header-left flex-center">
                <div
                    v-if="back"
                    class="page-header-back"
                >
                    <a :href="back.url">
                        <SvgController type="arrow-left" />
                        <span>{{ back.text }}</span>
                    </a>
                </div>
                <SvgController
                    v-if="icon"
                    :type="icon"
                />
                <h2>
                    {{ title }}
                    <span
                        v-if="count"
                        class="page-header-count"
                    >
                        {{ count }}
                    </span>
                </h2>
            </div>
            <div class="flex-center">
                <slot name="page-header-center" />
            </div>
            <div class="page-header-right flex-center">
                <slot />
            </div>
        </div>
        <slot name="page-header-bottom" />
    </div>
</template>

<script>
export default {
    components: {},

    props: {
        title: {
            type: String,
            required: true,
        },
        icon: {
            type: String,
            default: '',
        },
        count: {
            type: Number,
            default: 0,
        },
        background: {
            type: Boolean,
            required: false,
            default: true,
        },
        back: {
            type: Object,
            required: false,
            default: null,
        },
    },
    data() {
        return {
        };
    },
    created() {

    },
    methods:{},
};
</script>
