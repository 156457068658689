<template>
    <label
        :for="group.label"
        :class="selected ? 'selected' : ''"
    >
        <div class="account-select-item-checkbox">
            <SvgController
                v-if="selected"
                type="tick"
            />
        </div>
        <div class="account-select-item-icon">
            <SvgController :type="group.name" />
        </div>
        <div class="account-select-item-text">
            <h4>{{ group.label }}</h4>
            <p>{{ group.description }}</p>
        </div>
    </label>
</template>

<script>
export default {
    name: 'GroupLabel',
    components: {
    },
    props: {
        group: {
            type: Object,
            required: true,
        },
        selected: {
            type: Boolean,
            required: true,
        },
    },
};
</script>
