<template>
    <div
        ref="filter"
        class="filter"
    >
        <div class="filter-toggle">
            <button
                class="filter-toggle-btn"
                :class="{ active: showFilter }"
                @click="toggleShowFilter"
            >
                <SvgController type="filter" />
            </button>
        </div>
        <FormulateForm
            class="filter-form"
            :class="{ active: showFilter }"
        >
            <div class="filter-form-header">
                <div
                    class="btn filter-toggle-btn"
                    @click="toggleShowFilter"
                >
                    Close
                </div>
            </div>
            <InputSuffix>
                <FormulateInput
                    v-if="includeSearch || Object.prototype.hasOwnProperty.call(filterValues, 'search')"
                    v-model="filterValues.search"
                    type="text"
                    :placeholder="searchPlaceholder"
                    class="search"
                    @change="handleFilter()"
                />
                <template #suffix>
                    <div class="formulate-input-info">
                        <SvgController type="search" />
                    </div>
                </template>
            </InputSuffix>

            <template v-for="field in fields">
                <FormulateInput
                    :key="field.name"
                    v-model="filterValues[field.name]"
                    :options="field.options"
                    :type="field.type || 'select-plugin'"
                    :searchable="field.searchable ? field.searchable : false"
                    :reduce-key="field.reduce || 'value'"
                    label-key="label"
                    :label="field.label"
                    :placeholder="field.placeholder"
                    show-clear
                    @change="handleFilter()"
                />
            </template>

            <FormulateInput
                v-if="companies && companies.length > 1"
                v-model="filterValues.company_id"
                :options="companies"
                type="select-plugin"
                placeholder="All Companies"
                :searchable="false"
                @change="handleFilter()"
            />
            <button
                v-if="clear"
                class="btn-reset btn formulate-input filter-clear-btn"
                @click="resetFilter"
            >
                Clear Filter
            </button>
        </FormulateForm>
    </div>
</template>

<script>

import { throttle } from 'lodash';
import filter from 'Mixins/filter';
import InputSuffix from 'Utilities/vue-formulate/InputSuffix';

export default {
    name:'UserListFilter',

    components: {
        InputSuffix,
    },
    mixins: [filter],

    props: {
        fields: {
            type: Array,
            default:() => ([]),
        },
        filter: {
            type: Object,
            default:() => ({}),
        },
        route: {
            type: String,
            default: null,
        },
        searchPlaceholder: {
            type: String,
            default: 'Search',
        },
        section: {
            type: [String, Array],
            required: true,
        },
        includeSearch: {
            type: Boolean,
            default: true,
        },
    },

    data() {
        return {
            filters: this.filter,
            showFilter: false,
            clear: false,
            filterValues: {},
        };
    },

    computed: {
        companies() {
            if (!this.$page.props.companies) {
                return null;
            }

            return this.$page.props.companies.map((company) => {
                return {
                    value: company.id,
                    label: company.name,
                };
            });
        },
        reloadSections() {
            let section = this.section;

            if (typeof section === 'string') {
                section = [section];
            }

            return section;
        },
    },

    watch: {
        filters: {
            handler: throttle(function() {
                this.handleFilter(this.filters);
            }, 150),
            deep: true,
        },
        filterValues: {
            handler: throttle(function() {
                this.handleFilter();
            }, 150),
            deep: true,
        },
    },
    created() {
        this.checkFilters();
        this.setDefaultValues();
    },
    mounted() {
        this.handleResize();
        window.addEventListener('resize', this.handleResize);
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize);
    },
    methods: {
        handleFilter(customValues) {
            let dataTmp = this.buildFilter(),
                data = {};

            if (customValues) {
                dataTmp = {...dataTmp, ...customValues};
            }

            this.clear = false;

            Object.entries(this.filterValues).map(([key, value]) => {
                delete dataTmp[key];

                if (value && typeof value === 'object') {
                    const objNew = {};

                    for (let int=0; int<Object.entries(value).length;int++) {
                        const intObjItem = Object.entries(value)[int];

                        if (intObjItem[1] !== null) {
                            objNew[intObjItem[0]] = intObjItem[1];
                        }
                    }


                    value = objNew;

                    if (Object.keys(value).length > 0) {
                        data[key] = value;
                        this.clear = true;
                    }
                } else if (value) {
                    data[key] = value;
                    this.clear = true;
                }
            });

            data = {...data, ...dataTmp, page :1};

            this.$inertia.get(this.route || this.$route(this.$route().current()), data, {
                preserveState: true,
                preserveScroll: true,
                only: this.reloadSections,
                replace: true,
            });
        },
        checkFilters() {
            const data = Object.fromEntries(Object.entries(this.filters).filter(([, value]) => {
                if (value && typeof value === 'object') {
                    if (Object.keys(value).length > 0) {
                        return true;
                    }
                } else if (value) {
                    return true;
                }
            }));

            if (Object.keys(data).length) {
                this.clear = true;
            } else {
                this.clear = false;
            }
        },
        setDefaultValues() {
            const values = {};
            const dataTmp = this.buildFilter();

            this.fields.forEach((field) => {
                if (this.filters[field.name]) {
                    values[field.name] = this.filters[field.name];
                } else if (dataTmp[field.name]) {
                    values[field.name] = dataTmp[field.name];
                } else {
                    values[field.name] = '';

                    if (field.type === 'daterange-plugin') {
                        values[field.name] = {};
                    }
                }
            });

            if (this.includeSearch) {
                if (this.filters.search) {
                    values.search = this.filters.search;
                } else if (dataTmp.search) {
                    values.search = dataTmp.search;
                } else {
                    values.search = '';
                }
            }

            this.filterValues = {...values};
        },
        resetFilter() {
            Object.keys(this.filterValues).forEach((key) => {
                if (typeof this.filterValues[key] === 'object') {
                    if (key === 'date' || key === 'rental_startdate' || key === 'next_audit' || key === 'application_date' || key === 'bng_next_action_date') {
                        this.filterValues[key].startDate = null;
                        this.filterValues[key].endDate = null;
                    } else {
                        this.filterValues[key] = {};
                    }
                } else {
                    if (this.filterValues[key] !== null) {
                        this.filterValues[key] = undefined;
                    }
                }
            });
        },
        toggleShowFilter() {
            this.showFilter = !this.showFilter;
        },
        handleResize() {
            clearTimeout(this.resizeTimeout);
            this.resizeTimeout = setTimeout(() => {
                this.$refs.filter.classList.remove('collapse');
                const intWidth = (window.innerWidth || document.documentElement.clientWidth);
                const filterBounds = this.$refs.filter.getBoundingClientRect();
                const switcherBounds = document.querySelector('.tablegrid').getBoundingClientRect();

                if ((filterBounds.right > (switcherBounds.right - 100)) && intWidth > 767) {
                    this.$refs.filter.classList.add('collapse');
                }

                this.resizeTime = 100;
            }, this.resizeTime);

        },
    },

};
</script>
