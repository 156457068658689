import { mapColors } from 'Lib/defaults';

export default {
    data() {
        return {
            baseURL: 'https://services.arcgis.com/JJzESW51TqeY9uat/arcgis/rest/services/',
            requestOptionsDefault: {
                where: '1=1',
                outFields: '*',
                f: 'geojson',
                geometryType: 'esriGeometryEnvelope',
                resultRecordCount: 2000,
            },
        };
    },
    methods: {
        returnEndpoint(type) {
            switch (type) {
                case 'Living_England_Habitat_Map':
                    return '/Living_England_Habitat_Map_Phase4/FeatureServer/0/query';

                default:
                    return false;
            }
        },

        returnBounds(map) {
            return {
                northEast: {
                    lat: map.getBounds().getNorthEast().lat(),
                    lng: map.getBounds().getNorthEast().lng(),
                },
                southWest: {
                    lat: map.getBounds().getSouthWest().lat(),
                    lng: map.getBounds().getSouthWest().lng(),
                },
            };
        },

        buildOptions(objOptions) {
            return {...this.requestOptionsDefault, ...objOptions};
        },

        buildParam(objOptions) {
            let strParam = '';

            Object.entries(objOptions).forEach(([key, value]) => {
                strParam += `${!strParam ? '?' : '&'}${key}=${value}`;
            });

            return strParam;
        },

        returnColours(type) {
            switch (type) {
                case 'Living_England_Habitat_Map':
                    return {
                        'Acid, Calcareous, Neutral Grassland': mapColors['Acid, Calcareous, Neutral Grassland'],
                        'Arable and Horticultural': mapColors['Arable and Horticultural'],
                        'Bare Ground': mapColors['Bare Ground'],
                        'Bare Sand': mapColors['Bare Sand'],
                        'Bog': mapColors['Bog'],
                        'Bracken': mapColors['Bracken'],
                        'Broadleaved, Mixed and Yew Woodland': mapColors['Broadleaved, Mixed and Yew Woodland'],
                        'Built-up Areas and Gardens': mapColors['Built-up Areas and Gardens'],
                        'Coastal Saltmarsh': mapColors['Coastal Saltmarsh'],
                        'Coastal Sand Dunes': mapColors['Coastal Sand Dunes'],
                        'Coniferous Woodland': mapColors['Coniferous Woodland'],
                        'Dwarf Shrub Heath': mapColors['Dwarf Shrub Heath'],
                        'Fen, Marsh and Swamp': mapColors['Fen, Marsh and Swamp'],
                        'Improved Grassland': mapColors['Improved Grassland'],
                        'Scrub': mapColors['Scrub'],
                        'Unclassified': mapColors['Unclassified'],
                        'Water': mapColors['Water'],
                    };

                default:
                    return false;
            }
        },

        request(strEndpoint, objOptions = {}) {
            return new Promise((resolve, reject) => {
                if (strEndpoint) {
                    fetch(`${this.baseURL}${strEndpoint}${this.buildParam(objOptions)}`)
                        .then(response => response.json())
                        .then(data => {
                            resolve({
                                success: data && !data.error && (data.features || data.properties) ? true : false,
                                message: data.error ? data.error.message : '',
                                data: data && data.features ? data.features : [],
                                properties: data && data.properties ? data.properties : [],
                            });
                        });
                } else {
                    reject({
                        success: false,
                        message: `No API type found (${strEndpoint}).`,
                        data: [],
                    });
                }
            });
        },

        returnData(strType, objOptions = {}, intPage = 0) {
            return this.request(this.returnEndpoint(strType), this.buildOptions({
                ...objOptions,
                ...{
                    resultOffset: intPage * (objOptions.resultRecordCount || 2000),
                },
            }));
        },

        retrunGeoData(map, strType, objOptions = {}) {
            return new Promise((resolve, reject) => {
                const objNewBounds = this.returnBounds(map);

                const objCurrentOptions = this.buildOptions({
                    ...objOptions,
                    ...{
                        geometry: `${objNewBounds.northEast.lng},${objNewBounds.southWest.lat},${objNewBounds.southWest.lng},${objNewBounds.northEast.lat}`,
                    },
                });

                this.returnData(strType, {
                    ...objCurrentOptions,
                    ...{
                        returnCountOnly: true,
                    },
                }).then((data) => {
                    const objReturn = {
                        success: true,
                        message: '',
                        data: [],
                    };

                    if (data && data.properties && data.properties.count) {
                        const intPages = Math.ceil(data.properties.count / objCurrentOptions.resultRecordCount);
                        let intCount = 0,
                            arrCurrentData = [];

                        for (let intPage = 0; intPage < intPages; intPage++) {
                            this.returnData(strType, objCurrentOptions, intPage).then((features) => {
                                arrCurrentData = arrCurrentData.concat(features.data);
                                intCount += features.data.length || 0;

                                if (intCount >= data.properties.count) {
                                    objReturn.data = {
                                        features: arrCurrentData,
                                        type: 'FeatureCollection',
                                    };

                                    resolve(objReturn);
                                }
                            }).catch((features) => {
                                objReturn.success = false;
                                objReturn.data.push(features);
                                intCount += objCurrentOptions.resultRecordCount;

                                if (intCount >= data.properties.count) {
                                    reject(objReturn);
                                }
                            });
                        }
                    }
                });
            });
        },
    },
};
