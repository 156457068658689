<template>
    <div>
        <TableGridLayout
            :output="['table']"
            :columns="columns"
            :grid-layout="gridLayout"
            :rows="rows"
            mode="remote"
            :total-records="list.length"
            :has-header="hasHeader"
        >
            <template #header-left>
                <div class="filter">
                    <FormulateForm>
                        <FormulateInput
                            v-model="filters.start"
                            name="start"
                            type="date"
                            label="Start Date"
                            placeholder="Start Date"
                            :max="filters.end"
                        />
                        <FormulateInput
                            v-model="filters.end"
                            name="end"
                            type="date"
                            label="End Date"
                            placeholder="End Date"
                            :min="filters.start"
                        />
                    </FormulateForm>
                </div>
            </template>
        </TableGridLayout>
    </div>
</template>
<script>

export default {
    name: 'ReportsTable',
    components: {
    },

    props: {
        list: {
            type: Array,
            default: () => ([]),
        },
        hasHeader: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            filters: {
                start: '',
                end: '',
            },
            filterOptions: [{
                name: 'status',
                placeholder: 'All statuses',
                options: this.$page.props.statuses,
                type: 'select-plugin',
            },{
                name: 'date',
                placeholder: 'All dates',
                type: 'datetime-plugin',
            }],
            columns: [],
            rows: [],
            gridLayout: {},
        };
    },
    computed: {
        companies() {
            return this.$page.props.companies.map((company) => {
                return {
                    value: company.id,
                    label: company.name,
                };
            });
        },
    },
    created() {
        this.rows = this.list.map((objItem) => {
            return {...objItem, ...{
                download: 'Download CSV',
            }};
        });

        this.columns =[{
            label: 'Title',
            field: 'name',
            sortable: false,
        },{
            label: '',
            field: 'download',
            icon: 'download',
            sortable: false,
            rtl: true,
            onClick: (evt, row, index) => {
                this.handleClick(this.rows[index]);
            },
        }];

        this.gridLayout = {
            title: 'name',
            description: 'download',
            onClick: (evt, row, index) => {
                this.handleClick(this.rows[index]);
            },
        };
    },
    methods: {
        handleClick(objItem) {
            const link = document.createElement('a');

            link.setAttribute('download', objItem.name.replace(/\s/g, '-') + '.csv');
            link.href = `/reports/csv/${objItem.type}?start=${this.filters.start}&end=${this.filters.end}`;
            document.body.appendChild(link);
            link.click();
            link.remove();
        },
    },
};
</script>
