<template>
    <div
        :class="context.classes.element"
        :data-type="context.type"
    >
        <label
            class="toggle-checkbox-container"
        >
            <input
                v-model="context.model"
                type="text"
                v-bind="attributes"
                v-on="$listeners"
            >
            <div class="toggle-checkbox-buttons">
                <div
                    v-for="option in context.options"
                    :key="option.value"
                    class="toggle-checkbox-buttons--item"
                    :class="{
                        'toggle-checkbox--active': context.model === option.value,
                        'toggle-checkbox--disabled': disabled
                    }"
                    :disabled="disabled"
                    @click="changeValue(option)"
                >
                    {{ option.label }}
                </div>
            </div>
        </label>
    </div>
</template>

<script>
import FormulateInputMixin from '@braid/vue-formulate/src/FormulateInputMixin';
export default {
    name:'FormulateSelectButton',
    mixins: [FormulateInputMixin],

    props: {
        disabled: {
            type: Boolean,
            required: false,
        },
    },

    mounted() {
        if (!this.context.model) {
            this.context.model = this.context.options[0].value;
        }
    },

    methods: {
        changeValue(option) {
            if (!this.disabled) {
                this.context.model = option.value;
            }
        },
    },
};
</script>
