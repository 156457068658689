var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "app-layout",
    { attrs: { section: "property-developers" } },
    [
      _c(
        "page-header",
        {
          attrs: {
            title: _vm.title,
            icon: "building",
            count: _vm.propertyDevelopers.total,
          },
        },
        [
          !_vm.companyName
            ? _c(
                "button",
                {
                  staticClass: "btn btn--primary",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.addPropertyDeveloper()
                    },
                  },
                },
                [
                  _c("SvgController", { attrs: { type: "plus" } }),
                  _vm._v("\n            New Property Developer\n        "),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "center" },
        [
          _c("property-developers-table", {
            attrs: {
              "property-developers": _vm.propertyDevelopers,
              "filter-route": _vm.filterRoute,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("edit", { ref: "propetryDeveloperEdit" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }