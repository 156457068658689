var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "app-layout",
    { attrs: { section: "settings" } },
    [
      _c("page-header", { attrs: { title: "Trees" } }, [
        _c(
          "button",
          {
            staticClass: "btn btn--primary",
            attrs: { type: "button" },
            on: {
              click: function ($event) {
                return _vm.editTree({})
              },
            },
          },
          [
            _c("SvgController", { attrs: { type: "plus" } }),
            _vm._v("\n            Add Tree\n        "),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { ref: "container", staticClass: "center" },
        [
          _c("TableGridLayout", {
            attrs: {
              columns: _vm.columns,
              rows: _vm.trees,
              output: ["table"],
              "grid-layout": _vm.gridLayout,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }