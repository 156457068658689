<template>
    <label
        :for="id"
        class="file-label formulate-heading flex-space-between"
    >
        <h6>{{ label }}</h6>
        <span>
            <SvgController :type="icon" />
            {{ text }}
        </span>
    </label>
</template>

<script>
export default {
    name: 'FileLabel',
    components: {
    },
    props: {
        id: {
            type: String,
            required: true,
        },
        label: {
            type: String,
            required: false,
            default: 'Documents',
        },
        text: {
            type: String,
            required: false,
            default: 'Upload photos',
        },
        icon: {
            type: String,
            required: false,
            default: 'upload',
        },
    },
};
</script>
