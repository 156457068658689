<template>
    <div>
        <component
            :is="type"
            v-bind="reportData"
        />
        <html2pdf
            ref="html2Pdf"
            key="html2Pdf"
            :filename="filename"
            page-numbers
            pdf-content-width="1122px"
            enable-download
            pdf-orientation="landscape"
            has-map
            @afterDownload="afterDownload"
            @progress="updateProgress"
        >
            <section slot="pdf-content">
                <component
                    :is="type"
                    v-if="isDownloading"
                    ref="downloadReport"
                    v-bind="reportData"
                    is-download
                />
            </section>
        </html2pdf>
        <div class="center">
            <div class="flex justify-end">
                <button
                    class="btn-reset btn btn--primary"
                    @click="generateReport"
                >
                    Download
                </button>
            </div>
        </div>
        <modal
            ref="downloadProgress"
            :close-mask-click="false"
            :show-close="false"
        >
            <template #body>
                <div
                    class="flex-col justify-center items-center"
                >
                    <loading-progress
                        :progress="progress"
                        size="64"
                        rotate
                        fill-duration="2"
                        rotation-duration="1"
                    />
                    <h4>Generating and Downloading</h4>
                </div>
            </template>
        </modal>
    </div>
</template>

<script>
import Html2pdf from 'Utilities/html2pdf';
import Modal from 'Utilities/modal/Modal';

import Overview from './Overview';
import Development from './Development';
import Rental from './Rental';

export default {
    name: 'Report',
    components: {
        Html2pdf,
        Modal,
        Overview,
        Development,
        Rental,
    },

    props: {
        reportData: {
            type: Object,
            required: true,
        },

        type: {
            type: String,
            required: true,
            validator: function(value) {
                // The value must match one of these strings
                return ['overview', 'development', 'rental'].indexOf(value) !== -1;
            },
        },

        filename: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            isDownloading: false,
            progress: 0,
        };
    },

    mounted() {},

    methods: {
        generateReport() {
            this.isDownloading = true;
            this.$refs.downloadProgress.openModal();
            this.$refs.html2Pdf.generatePdf();
        },

        async afterDownload() {
            this.isDownloading = false;
            this.$refs.downloadProgress.closeModal();
        },

        updateProgress(val) {
            this.progress = val;
        },
    },
};
</script>
