var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.pill
        ? _c("div", {
            class: `status-pill type-${_vm.pillClass}`,
            domProps: { innerHTML: _vm._s(_vm.sanitisedPill) },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.habitatTypeOptions
        ? _c(
            "FormulateForm",
            {
              ref: "form",
              staticClass: "flex-col margin-0",
              on: { submit: _vm.submitForm },
              model: {
                value: _vm.form,
                callback: function ($$v) {
                  _vm.form = $$v
                },
                expression: "form",
              },
            },
            [
              _c(
                "p",
                { staticClass: "align-right" },
                [
                  _vm.showUnits
                    ? [
                        _vm._v(
                          "\n                Total Units: " +
                            _vm._s(_vm.numberCommas(_vm.getUnits("all"))) +
                            "\n            "
                        ),
                      ]
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.maxArea
                    ? [
                        _c("br"),
                        _vm._v(
                          "Area used: " +
                            _vm._s(_vm.getUsedSize) +
                            " Hectares\n                "
                        ),
                        _c("br"),
                        _vm._v(
                          "Area remaining: " +
                            _vm._s(_vm.getRemainingSize) +
                            " Hectares\n            "
                        ),
                      ]
                    : _vm._e(),
                ],
                2
              ),
              _vm._v(" "),
              _c("FormulateInput", {
                key: "habitats",
                attrs: {
                  name: "habitats",
                  "add-label": "+ Add Additional Habitat",
                  type: "group",
                  repeatable: !_vm.disabled && _vm.repeatable,
                  "element-class":
                    "formulate-input-element formulate-input-element--collapsable-group",
                  "outer-class": "formulate-input collapsable-group",
                },
                on: {
                  repeatableAdded: function ($event) {
                    _vm.showIndex = $event.length - 1
                  },
                  repeatableRemoved: function ($event) {
                    _vm.showIndex = $event.length - 1
                  },
                  input: _vm.inputForm,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "repeatable",
                      fn: function (context) {
                        return [
                          _c(
                            "collapsable-group-item",
                            {
                              attrs: {
                                context: context,
                                title: "Habitat",
                                "show-index": _vm.showIndex,
                              },
                              on: {
                                open: function ($event) {
                                  _vm.showIndex = $event
                                },
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "title",
                                    fn: function ({ index }) {
                                      return [
                                        _c(
                                          "div",
                                          { staticClass: "title-left" },
                                          [
                                            _vm.getMissing(index) &&
                                            _vm.getMissing(index).length
                                              ? _c(
                                                  "div",
                                                  [
                                                    _c("SvgController", {
                                                      staticClass: "text-red",
                                                      attrs: {
                                                        type: "info-information",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _vm._v(
                                              "\n                            " +
                                                _vm._s(
                                                  _vm.habitatTitle(index)
                                                ) +
                                                "\n                        "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _vm.showUnits
                                          ? _c(
                                              "div",
                                              { staticClass: "title-left" },
                                              [
                                                _vm._v(
                                                  "\n                            " +
                                                    _vm._s(
                                                      _vm.numberCommas(
                                                        _vm.getUnits(index)
                                                      )
                                                    ) +
                                                    " units\n                        "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            },
                            [
                              _vm._v(" "),
                              _vm.getMissing(context.index) &&
                              _vm.getMissing(context.index).length
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "alert-box alert-box-warning",
                                    },
                                    [
                                      _c("div", {
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.getMissingText(context.index)
                                          ),
                                        },
                                      }),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c("FormulateInput", {
                                key: "category",
                                attrs: {
                                  type: "select-button",
                                  name: "category",
                                  options: ["land", "hedgerows", "rivers"],
                                  "element-class":
                                    "formulate-input-element formulate-input-element--select-button formulate-input-element-habitat-btn",
                                  disabled: _vm.disabled,
                                },
                                on: { input: _vm.inputForm },
                              }),
                              _vm._v(" "),
                              _c("FormulateInput", {
                                key: "id",
                                attrs: { type: "hidden", name: "id" },
                              }),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  class: {
                                    "form-flex--tab": !_vm.small,
                                    "formulate-input": _vm.small,
                                  },
                                },
                                [
                                  _vm.getCategory(context.index) === "land"
                                    ? _c("FormulateInput", {
                                        key: "broad_habitat",
                                        attrs: {
                                          type: "select-plugin",
                                          name: "broad_habitat",
                                          label: "Broad Habitat",
                                          validation: "required",
                                          "validation-name": "Broad Habitat",
                                          placeholder: "Select Broad Habitat",
                                          options: _vm.getBroadHabitatOptions,
                                          disabled:
                                            !_vm.isEnabled.broad_habitat,
                                        },
                                        on: { input: _vm.inputForm },
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("FormulateInput", {
                                    key: "type",
                                    attrs: {
                                      type: "select-plugin",
                                      name: "type",
                                      validation: "required",
                                      placeholder: "Select Habitat Type",
                                      label: _vm.typeLabel(context.index),
                                      options: _vm.habitatTypes(context.index),
                                      disabled: !_vm.isEnabled.type,
                                    },
                                    on: {
                                      input: function ($event) {
                                        return _vm.setHiddenFields(
                                          context.index
                                        )
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "InputSuffix",
                                {
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "suffix",
                                        fn: function () {
                                          return [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "formulate-input-info",
                                              },
                                              [
                                                _c("p", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.sizeUnits(
                                                        context.index
                                                      )
                                                    )
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                },
                                [
                                  _c("FormulateInput", {
                                    key: "size",
                                    attrs: {
                                      type: _vm.isEnabled.size
                                        ? "number"
                                        : "fake",
                                      name: "size",
                                      label: _vm.sizeLabel(context.index),
                                      validation: "required|number",
                                      "outer-class":
                                        "formulate-input formulate-size",
                                      disabled: !_vm.isEnabled.size,
                                      "parse-value": (value) =>
                                        _vm.floatOutput(value),
                                    },
                                    on: { input: _vm.inputForm },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _vm.photo && _vm.photoUploadUrl
                                ? _c("FormulateInput", {
                                    key: "photo",
                                    attrs: {
                                      name: "photos",
                                      label: "Photo",
                                      type: "image",
                                      required: true,
                                      validation:
                                        "mime:image/jpeg,image/png,image/gif",
                                      "upload-url": _vm.photoUploadUrl,
                                      "upload-behavior": "delayed",
                                    },
                                    on: {
                                      input: _vm.inputPhoto,
                                      "file-removed": _vm.inputPhoto,
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3221109206
                ),
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }