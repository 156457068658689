var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "site-app", "page-name": _vm.$route().current() } },
    [
      _c("Header", { on: { toggleSidebar: _vm.toggleSidebar } }),
      _vm._v(" "),
      _c("Sidebar", {
        attrs: {
          active: _vm.sidebarActive,
          "profile-type": "biodiverity",
          section: _vm.section,
        },
        on: {
          toggleSidebar: function ($event) {
            _vm.sidebarActive = !_vm.sidebarActive
          },
        },
      }),
      _vm._v(" "),
      _c(
        "div",
        { attrs: { id: "site-page" } },
        [_vm._t("default"), _vm._v(" "), _c("flash-message")],
        2
      ),
      _vm._v(" "),
      _c("conformation-dialog"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }