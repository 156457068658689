var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "auth-layout",
    { attrs: { logo: true, component: _vm.$options.name } },
    [
      _c(
        "FormulateForm",
        {
          attrs: {
            errors: {
              email: _vm.errors.email,
            },
          },
          on: {
            submit: function ($event) {
              _vm.form.post(_vm.$route("password.email"))
            },
          },
        },
        [
          _c("div", { staticClass: "form-page" }, [
            _vm.flash.status === "We have emailed your password reset link!"
              ? _c("div", { staticClass: "text-center" }, [
                  _c(
                    "div",
                    { staticClass: "icon-success" },
                    [_c("SvgController", { attrs: { type: "circle-tick" } })],
                    1
                  ),
                  _vm._v(" "),
                  _c("h3", [_vm._v("Password Reset Email Sent")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "formulate-input" }, [
                    _c("p", [
                      _vm._v(
                        "\n                        An email has been sent to your email address, "
                      ),
                      _c("strong", [_vm._v(_vm._s(_vm.form.email))]),
                      _vm._v(".\n                        "),
                      _c("br"),
                      _vm._v(
                        "\n                        Follow the direction in the email to reset your password.\n                    "
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "formulate-input" }, [
                    _c(
                      "a",
                      {
                        staticClass: "btn--reset btn btn--secondary",
                        attrs: { href: _vm.$route("login") },
                      },
                      [
                        _vm._v(
                          "\n                        Back to Login\n                    "
                        ),
                      ]
                    ),
                  ]),
                ])
              : _c(
                  "div",
                  [
                    _c("h3", [_vm._v("Forgotten Your Password?")]),
                    _vm._v(" "),
                    _c("div", { staticClass: "formulate-input" }, [
                      _c("p", [
                        _vm._v(
                          "No worries! Enter your email address below and if it matches an account, we'll email you with instructions for resetting your password."
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("FormulateInput", {
                      attrs: {
                        type: "email",
                        name: "email",
                        label: "Email Address",
                        validation: "required|email",
                      },
                      model: {
                        value: _vm.form.email,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "email", $$v)
                        },
                        expression: "form.email",
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "formulate-input btn--group" },
                      [
                        _c(
                          "inertia-link",
                          {
                            staticClass: "btn btn--secondary",
                            attrs: { href: _vm.$route("login") },
                          },
                          [
                            _vm._v(
                              "\n                        Back\n                    "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "btn--reset btn btn--primary",
                            attrs: { type: "submit" },
                          },
                          [
                            _vm._v(
                              "\n                        Submit\n                    "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }