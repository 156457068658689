<template>
    <app-layout section="reports">
        <report
            type="overview"
            :report-data="{
                title,
                lpa,
                tileValues,
                linkValues,
                companyId,
                plots,
                projects,
                mapPlots,
                mapProjects,
            }"
            :filename="filename"
        />
    </app-layout>
</template>
<script>

import Report from 'Components/Reports/Report';


export default {
    name: 'Reports',
    components: {
        Report,
    },

    props: {
        lpa: {
            type: Object,
            default: null,
        },
        title: {
            type: String,
            required: true,
        },
        tileValues: {
            type: Object,
            default: null,
        },
        linkValues: {
            type: Object,
            default: null,
        },
        companyId: {
            type: Number,
            default: null,
        },
        plots: {
            type: Object,
            default: null,
        },
        projects: {
            type: Object,
            default: null,
        },
        mapPlots: {
            type: Array,
            default: null,
        },
        mapProjects: {
            type: Array,
            default: null,
        },
    },

    data() {
        return {
            isDownloading: false,
            filename: `${this.title}-bng-overview-report`,
        };
    },
};
</script>
