module.exports = {
    computed: {
        // Randoms
        getRandomID() {
            return `${Math.floor(Math.random() * 1000)}-${Date.now()}-${Math.floor(Date.now() * Math.random() * 1000)}`;
        },
    },

    methods: {
        // Parsing Numbers
        float(value, decimalPlaces = null) {
            let float = parseFloat(value);

            if (decimalPlaces !== null) {
                float = float.toFixed(decimalPlaces);
            }

            return float;
        },

        floatOutput(value, decimalPlaces = 2) {
            const float = this.float(value, decimalPlaces);

            if (!isNaN(float)) {
                return float;
            }

            return value;
        },

        isInt(number) {
            return Number(number) === number && number % 1 === 0;
        },

        isFloat(number) {
            return Number(number) === number && number % 1 !== 0;
        },

        capitalizeFirstLetter(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        },

        // Parse area sizes
        // Hectares
        hectaresToMetersSq(float) {
            if (this.isFloat(float)) {
                return this.float(float) * 10000;
            }

            return null;
        },
        hectaresToKmSq(float) {
            if (this.isFloat(float)) {
                return this.float(float) * 0.01;
            }

            return null;
        },
        hectaresToAcres(float) {
            if (this.isFloat(float)) {
                return this.float(float) * 2.4710538146717;
            }

            return null;
        },

        // Meters Squared
        metersSqToHectares(float) {
            if (this.isFloat(float)) {
                return this.float(float) * 0.0001;
            }

            return null;
        },
        metersSqToKmSq(float) {
            if (this.isFloat(float)) {
                return this.float(float) * 0.000001;
            }

            return null;
        },
        metersSqToAcres(float) {
            if (this.isFloat(float)) {
                return this.float(float) * 0.0002471054;
            }

            return null;
        },

        // Km Squared
        kmSqToHectares(float) {
            if (this.isFloat(float)) {
                return this.float(float) * 100;
            }

            return null;
        },
        kmSqToMetersSq(float) {
            if (this.isFloat(float)) {
                return this.float(float) * 1000000;
            }

            return null;
        },
        kmSqToAcres(float) {
            if (this.isFloat(float)) {
                return this.float(float) * 247.10538147;
            }

            return null;
        },

        // Meters Squared
        acresToHectares(float) {
            if (this.isFloat(float)) {
                return this.float(float) * 0.40468564224;
            }

            return null;
        },
        acresToKmSq(float) {
            if (this.isFloat(float)) {
                return this.float(float) * 0.0040468564;
            }

            return null;
        },
        acresToMetersSq(float) {
            if (this.isFloat(float)) {
                return this.float(float) * 4046.8564224;
            }

            return null;
        },
    },
};
