var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("TableGridLayout", {
        attrs: {
          output: ["table"],
          columns: _vm.columns,
          "grid-layout": _vm.gridLayout,
          rows: _vm.rows,
          mode: "remote",
          "total-records": _vm.list.length,
          "has-header": _vm.hasHeader,
        },
        scopedSlots: _vm._u([
          {
            key: "header-left",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "filter" },
                  [
                    _c(
                      "FormulateForm",
                      [
                        _c("FormulateInput", {
                          attrs: {
                            name: "start",
                            type: "date",
                            label: "Start Date",
                            placeholder: "Start Date",
                            max: _vm.filters.end,
                          },
                          model: {
                            value: _vm.filters.start,
                            callback: function ($$v) {
                              _vm.$set(_vm.filters, "start", $$v)
                            },
                            expression: "filters.start",
                          },
                        }),
                        _vm._v(" "),
                        _c("FormulateInput", {
                          attrs: {
                            name: "end",
                            type: "date",
                            label: "End Date",
                            placeholder: "End Date",
                            min: _vm.filters.start,
                          },
                          model: {
                            value: _vm.filters.end,
                            callback: function ($$v) {
                              _vm.$set(_vm.filters, "end", $$v)
                            },
                            expression: "filters.end",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }