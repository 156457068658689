<template>
    <div class="center">
        <TableGridLayout
            v-if="rentals.length > 0"
            :columns="columns"
            :rows="rentals"
            mode="remote"
            :total-records="rentals.length"
            :grid-layout="gridLayout"
            :output="['table']"
            @onRowClick="onRowClick"
        />
        <Empty
            v-else
            type="agreements"
        />
    </div>
</template>
<script>
import Empty from 'Utilities/empty/Empty';
export default {
    name: 'Rentals',
    components: {
        Empty,
    },

    props: {},
    data() {
        return {
            columns: [],
            gridLayout: {},
        };
    },
    computed: {
        rentals() {
            return this.$page.props.rentals;
        },
    },
    created() {
        this.columns = [{
            label: 'Date Agreement Started',
            field: 'rental_startdate',
            sortable: true,
        },{
            label: 'Agreement Period',
            field: 'rental_period',
            sortable: true,
        },{
            label: 'Units',
            field: 'units',
            sortable: true,

        },{
            label: 'Date next audit due',
            field: 'next_audit_date',
            sortable: true,
        }, {
            label: 'Offsetting',
            field: 'site_type',
        }];
    },
    methods: {
        onRowClick({ row }) {
            this.$inertia.visit(this.$route('rental.show', row.id));
        },
    },
};
</script>
