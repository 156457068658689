var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "FormulateForm",
    {
      staticClass: "flex-col",
      attrs: { errors: _vm.form.errors },
      on: {
        submit: function ($event) {
          return _vm.submitForm()
        },
      },
    },
    [
      _c("FormulateInput", {
        attrs: {
          type: "password",
          name: "current_password",
          label: "Current password",
          validation: "required",
          "validation-name": "Current password",
        },
        model: {
          value: _vm.form.current_password,
          callback: function ($$v) {
            _vm.$set(_vm.form, "current_password", $$v)
          },
          expression: "form.current_password",
        },
      }),
      _vm._v(" "),
      _c("FormulateInput", {
        attrs: {
          type: "password",
          name: "password",
          label: "New Password",
          validation: "required|min:8,length",
          "validation-name": "New password",
        },
        model: {
          value: _vm.form.password,
          callback: function ($$v) {
            _vm.$set(_vm.form, "password", $$v)
          },
          expression: "form.password",
        },
      }),
      _vm._v(" "),
      _c("FormulateInput", {
        attrs: {
          type: "password",
          name: "password_confirmation",
          label: "Confirm new password",
          validation: "required|min:8,length|confirm:password",
          "validation-name": "Password confirmation",
        },
        model: {
          value: _vm.form.password_confirmation,
          callback: function ($$v) {
            _vm.$set(_vm.form, "password_confirmation", $$v)
          },
          expression: "form.password_confirmation",
        },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "formulate-input btn--group" }, [
        _c(
          "a",
          {
            staticClass: "btn",
            attrs: { href: "#" },
            on: {
              click: function ($event) {
                return _vm.$emit("closeModal")
              },
            },
          },
          [_vm._v("\n            Cancel\n        ")]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn--reset btn btn--primary",
            attrs: { type: "submit" },
          },
          [
            _c("SvgController", { attrs: { type: "tick" } }),
            _vm._v("\n            Save\n        "),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }