var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "form-file form-file--bg" },
    [
      _c("SvgController", { attrs: { type: "paperclip" } }),
      _vm._v(" "),
      _c("a", { attrs: { href: _vm.url, target: "_blank" } }, [
        _vm._v("\n        " + _vm._s(_vm.name) + "\n    "),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form-file-date" }, [
        _vm._v("\n        " + _vm._s(_vm.displayDate) + "\n    "),
      ]),
      _vm._v(" "),
      _vm.deletable &&
      (_vm.$page.props.loggedUser.isSuperUser ||
        _vm.$page.props.loggedUser.id === _vm.file.user_id)
        ? _c("SvgController", {
            staticClass: "form-file-delete",
            attrs: { type: "trashcan" },
            nativeOn: {
              click: function ($event) {
                return _vm.deleteFile.apply(null, arguments)
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }