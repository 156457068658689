var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "link", style: { color: _vm.color } },
    [
      _vm.href
        ? _c("inertia-link", { attrs: { href: _vm.href } }, [
            _vm._v("\n        " + _vm._s(_vm.title) + "\n        "),
            _vm.subtext !== null
              ? _c("span", { staticClass: "link_subtext" }, [
                  _vm._v(_vm._s(_vm.subtext)),
                ])
              : _vm._e(),
          ])
        : _c("div", [
            _vm._v("\n        " + _vm._s(_vm.title) + "\n        "),
            _vm.subtext !== null
              ? _c("span", { staticClass: "link_subtext" }, [
                  _vm._v(_vm._s(_vm.subtext)),
                ])
              : _vm._e(),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }