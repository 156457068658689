var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "FormulateForm",
    {
      staticClass: "flex-col",
      attrs: { errors: _vm.form.errors, schema: _vm.formSchema },
      on: {
        submit: function ($event) {
          return _vm.submitForm()
        },
      },
      model: {
        value: _vm.form,
        callback: function ($$v) {
          _vm.form = $$v
        },
        expression: "form",
      },
    },
    [
      _c("div", { staticClass: "formulate-input btn--group" }, [
        _c(
          "a",
          {
            staticClass: "btn",
            attrs: { href: "#" },
            on: {
              click: function ($event) {
                return _vm.$emit("closeModal")
              },
            },
          },
          [_vm._v("\n            Cancel\n        ")]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn--reset btn btn--primary",
            attrs: { type: "submit" },
          },
          [
            _c("SvgController", { attrs: { type: "tick" } }),
            _vm._v("\n            Save\n        "),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }