<template>
    <modal
        ref="historyModal"
        width="50em"
    >
        <template #header>
            <h3>
                LOGS
            </h3>
        </template>
        <template #body>
            <TableGridLayout
                :columns="columns"
                :rows="logs"
                :output="['table']"
                mode="remote"
                :total-records="logs.length"
            />
        </template>
    </modal>
</template>

<script>
import Modal from 'Utilities/modal/Modal';
export default {
    name: 'ContractLogs',
    components: {
        Modal,
    },
    props: {
        show: {
            type: Boolean,
            default: false,
        },
        logs: {
            type: Array,
            default: () => ([]),
        },
    },
    data() {
        return {
            form: null,
            displayNameFocus: false,
            columns: [{
                label: 'Date',
                field: 'created_at',
                sortable: false,
            },{
                label: 'Action',
                field: 'action',
                spanClass: 'status-pill type-{status}',
                sortable: false,
            },{
                label: 'First Name',
                field: 'user.first_name',
                sortable: false,
            },{
                label: 'Last Name',
                field: 'user.last_name',
                sortable: false,
            }],
        };
    },
    watch: {
        show(value) {
            if (value) {
                this.$refs.historyModal.openModal();
            } else {
                this.$refs.historyModal.closeModal();
            }
        },
    },
    methods: {
        openModal() {
            this.$refs.historyModal.openModal();
        },
        closeModal() {
            this.$refs.historyModal.closeModal();
        },
    },
};
</script>
