var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("vue-good-table", {
    attrs: {
      columns: _vm.columnFormat,
      rows: _vm.rows,
      "row-style-class": _vm.hasRowClick ? "bng-clickable" : "",
    },
    on: { "on-row-click": _vm.onRowClick },
    scopedSlots: _vm._u([
      {
        key: "table-column",
        fn: function (props) {
          return [
            _c(
              "span",
              {
                class: {
                  sortable: props.column.customSortable,
                  "sorting-asc": props.column.sortDirection === "ASC",
                  "sorting-desc": props.column.sortDirection === "DESC",
                },
                style: props.column.style,
              },
              [
                _vm._v(
                  "\n            " +
                    _vm._s(props.column.label) +
                    "\n            "
                ),
                props.column.customSortable
                  ? _c(
                      "button",
                      {
                        staticClass: "btn-reset tablegrid-sort",
                        on: {
                          click: function ($event) {
                            return _vm.handleSort(props.column)
                          },
                        },
                      },
                      [
                        _c("SvgController", { attrs: { type: "table-sort" } }),
                        _vm._v(" "),
                        _c("SvgController", { attrs: { type: "table-up" } }),
                        _vm._v(" "),
                        _c("SvgController", { attrs: { type: "table-down" } }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            ),
          ]
        },
      },
      {
        key: "table-row",
        fn: function (props) {
          return [
            _c("TableGridLayoutTableRow", {
              attrs: {
                index: props.index,
                column: props.column,
                row: props.formattedRow[props.column.field],
                "all-rows": props.formattedRow,
                "full-row": props.row,
                "init-row": _vm.rows[props.index],
              },
              scopedSlots: _vm._u(
                [
                  _vm._l(_vm.$scopedSlots, function (_, name) {
                    return {
                      key: name,
                      fn: function (slotData) {
                        return [_vm._t(name, null, null, slotData)]
                      },
                    }
                  }),
                ],
                null,
                true
              ),
            }),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }