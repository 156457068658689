<template>
    <div class="gallery">
        <ul
            v-if="images.length > 0"
            class="gallery-list lst-reset"
        >
            <li
                v-for="(image, index) in images"
                :key="index"
                class="gallery-list-item"
            >
                <div
                    v-if="image.type === 'photo' || image.type === 'image'"
                    class="gallery-list-item-img"
                    :style="{'background-image': 'url(' + image.filepath + ')'}"
                    @click="openImage(image)"
                />

                <video
                    v-else-if="image.type === 'video'"
                    class="gallery-list-item-video"
                    @click="openImage(image)"
                >
                    <source :src="image.filepath">
                    Your browser does not support the video tag.
                </video>

                <div class="gallery-list-item-info flex-space-between">
                    <div class="gallery-list-item-info-title">
                        {{ image.display_name }}
                    </div>
                    <div
                        v-if="deletable && ($page.props.loggedUser.isSuperUser || $page.props.loggedUser.id === image.user_id)"
                        class="gallery-list-item-info-delete"
                    >
                        <SvgController
                            type="trashcan"
                            @click.native="deleteImage(image)"
                        />
                    </div>
                </div>
            </li>
        </ul>
        <p
            v-else
            class="text-grey4"
        >
            There are no photos yet.
        </p>
        <Modal
            ref="imageModal"
            class="modal--img"
        >
            <template #header>
                {{ selectedImage.display_name }}
            </template>
            <template #body>
                <div
                    v-if="selectedImage.type === 'photo' || selectedImage.type === 'image'"
                    class="modal-image"
                    :style="{'background-image': 'url(' + selectedImage.filepath + ')'}"
                />
                <video
                    v-else-if="selectedImage.type === 'video'"
                    class="modal-image"
                    controls
                    autoplay
                >
                    <source :src="selectedImage.filepath">
                    Your browser does not support the video tag.
                </video>
            </template>
        </Modal>
    </div>
</template>
<script>
import Modal from 'Utilities/modal/Modal';
export default {
    name: 'Gallery',
    components: {
        Modal,
    },
    props: {
        images: {
            type: Array,
            required: true,
        },
        deletable: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            selectedImage: {},
        };
    },
    methods: {
        deleteImage(image) {
            this.$confirm(
                {
                    title: 'Delete Image',
                    message: 'Are you sure you want to delete this image? This action can\'t be undone.',
                    confirmBtnClass: 'btn--red',
                    confirmBtnText: 'Delete',
                    callback: confirm => {
                        if (confirm) {
                            if (image.type !== 'new') {
                                this.$inertia.delete(this.$route('attachment.destroy', image.id));
                            }

                            this.$emit('deleteImage', image);
                            this.$confirm.close();
                        }
                    },
                },
            );
        },
        openImage(objImage) {
            this.selectedImage = objImage;
            this.$refs.imageModal.openModal();
        },
    },
};
</script>
