<template>
    <app-layout section="plots">
        <page-header
            title="Agreement Details"
            icon="plots"
            :back="{
                url: returnUrl,
                text: 'All BNG Agreements'
            }"
        >
            <button
                v-if="deletable"
                class="btn-reset btn btn--red--secondary"
                @click="deleteRental"
            >
                <SvgController type="trashcan" />
                <span>Delete Agreement</span>
            </button>
            <inertia-link
                v-if="hasGroup(['sequest', 'lpa']) && hasPermission('rental_save')"
                class="btn-reset btn btn--primary"
                :href="$route('rental.edit', rental.id)"
            >
                Edit Agreement
            </inertia-link>
            <template #page-header-bottom>
                <tabs
                    v-model="selectedTab"
                    :tabs="tabs"
                />
            </template>
        </page-header>
        <div
            v-if="selectedTab === 'information'"
        >
            <div class="center">
                <alert
                    v-if="rental.update_overdue"
                    type="warning"
                >
                    <p>Audit is overdue {{ rental.next_audit_date }}</p>
                </alert>
                <alert
                    v-else-if="rental.next_audit_date !== ''"
                    type="info"
                >
                    <p>Next audit is due {{ rental.next_audit_date }}</p>
                </alert>
            </div>
            <div class="details">
                <div class="center">
                    <div class="form-flex--lap">
                        <div class="details-block details-bg">
                            <div class="formulate-heading">
                                <h6>Agreement Details</h6>
                            </div>
                            <h5>Unique Reference Number</h5>
                            <p>{{ rental.unique_reference_number }}</p>
                            <div class="form-flex--tab">
                                <div>
                                    <h5>Date Agreement Started</h5>
                                    <p>{{ rental.rental_startdate }}</p>
                                </div>
                                <div>
                                    <h5>Agreement Period Remaining</h5>
                                    <p>{{ rental.rental_period }}</p>
                                </div>
                            </div>
                            <div class="form-flex--tab">
                                <div>
                                    <h5>Annual Payment</h5>
                                    <p>{{ rental.annual_rent }}</p>
                                </div>
                                <div>
                                    <h5>Agreement Status</h5>
                                    <p>{{ rental.status }}</p>
                                </div>
                            </div>
                            <div>
                                <h5>BNG Units</h5>
                                <p>{{ rental.units }}</p>
                            </div>
                        </div>
                        <div class="details-block">
                            <div class="formulate-heading">
                                <h6>Location</h6>
                            </div>
                            <draw-map
                                ref="map"
                                :edit="false"
                                :land-parcels="[rental.plot]"
                                :leases="[rental]"
                                :show-geo-input="false"
                                :lat-long="rental.plot.location_data.layer.center"
                                :zoom="10"
                                type="lease"
                                show-keys
                                fit-bounds="landParcels"
                            />
                        </div>
                    </div>

                    <Updates />
                    <file-label
                        id="management-lan"
                        label="Management Plan"
                        text="Upload Management Plan"
                        @click.native="openFileModal"
                    />
                    <files
                        :files="documents"
                        filetype="document"
                        :deletable="true"
                        item-type="agreement"
                    />
                    <file-label
                        id="media"
                        label="Media"
                        text="Upload Media"
                        @click.native="openMediaModal"
                    />
                    <gallery
                        :images="rental.media"
                        :deletable="true"
                    />
                </div>
            </div>
        </div>

        <div v-if="selectedTab === 'report'">
            <rental-report
                :rental="rental"
            />
        </div>

        <div v-if="selectedTab === 'contracts'">
            <div class="center">
                <contracts
                    :contracts-plot="rental.plot ? rental.plot.contracts : []"
                    :contracts-project="rental.project ? rental.project.contracts : []"
                    :contracts="contracts"
                    :parent="{
                        id: rental.id,
                        name: 'Rental',
                        type: 'rental',
                    }"
                />
            </div>
            <modal ref="fileModal">
                <template #header>
                    <h3>Add Management Plan</h3>
                </template>
                <template #body>
                    <add-files
                        file-type="document"
                        :parent="{
                            id: rental.id,
                            type: 'rental',
                        }"
                    />
                </template>
            </modal>
            <modal ref="mediaModal">
                <template #header>
                    <h3>Add Media</h3>
                </template>
                <template #body>
                    <add-media
                        file-type="media"
                        :parent="{
                            id: rental.id,
                            type: 'rental',
                        }"
                    />
                </template>
            </modal>
        </div>
    </app-layout>
</template>
<script>
import Alert from 'Utilities/alert/Alert';
import DrawMap from 'Utilities/maps/DrawMap';
import Updates from './Updates';
import Contracts from './Contracts';
import FileLabel from 'Utilities/vue-formulate/FileLabel';
import AddMedia from 'Utilities/uploader/AddMedia';
import AddFiles from 'Utilities/uploader/AddFiles';
import RentalReport from './Report';
import Tabs from 'Components/tabs/Tabs';
import Modal from 'Utilities/modal/Modal';
import Files from 'Utilities/files/Files.vue';
import Gallery from 'Utilities/gallery/Gallery';

export default {
    name: 'RentalShow',
    components: {
        Alert,
        DrawMap,
        Updates,
        Contracts,
        Files,
        FileLabel,
        RentalReport,
        AddMedia,
        AddFiles,
        Modal,
        Gallery,
        Tabs,
    },
    props: {
        rental: {
            type: Object,
            required: true,
        },
        contracts: {
            type: Array,
            default: () => ([]),
        },
        previousRoute: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            selectedTab: null,
            tabs: [{
                id: 'information',
                title: 'Agreement Information',
                link: route('rental.show', this.rental.id),
                only: [],
                show: true,
            },{
                id: 'report',
                title: 'Report',
                link: route('rental.show.report', {
                    rental: this.rental.id,
                }),
                only: ['report'],
                show: this.hasPermission('reports_view'),
            },{
                id: 'contracts',
                title: 'Contracts',
                link: route('rental.show.contracts', {
                    rental: this.rental.id,
                }),
                only: ['rental', 'contracts'],
                show: true,
            }],
        };
    },
    computed: {
        deletable() {
            if (this.hasGroup(['sequest', 'lpa']) && this.hasPermission('rental_delete') && this.rental.status !== 'agreement started') {
                return true;
            }

            return false;
        },

        returnUrl() {
            switch (this.previousRoute) {
                case 'rentals':
                case 'rental.show':
                    return this.$route('rentals');
                case 'plot.show':
                    return this.$route(this.previousRoute, {
                        plot: this.rental.plot_id,
                    }) + '#agreements';
                case 'project.show':
                    return this.$route(this.previousRoute, {
                        project: this.rental.project_id,
                    }) + '#agreements';
                default:
                    return null;
            }
        },
        documents() {
            const documents = this.rental.documents.filter(document => {
                return document.type === 'document';
            });

            return documents;
        },
    },
    mounted() {},
    methods: {
        deleteRental() {
            this.$confirm(
                {
                    title: 'Delete Agreement',
                    message: 'Are you sure you want to delete this agreement? This action can\'t be undone.',
                    confirmBtnClass: 'btn--red',
                    confirmBtnText: 'Delete',
                    callback: confirm => {
                        if (confirm) {
                            this.$inertia.delete(this.$route('rental.destroy', this.rental.id));
                            this.$confirm.close();
                        }
                    },
                },
            );
        },

        openFileModal() {
            this.$refs.fileModal.openModal();
        },
        openMediaModal() {
            this.$refs.mediaModal.openModal();
        },
    },
};
</script>
