<template>
    <header class="header-small">
        <div>
            <h2 v-if="title">
                {{ title }}
            </h2>
        </div>
        <div v-if="buttonArray.length">
            <button
                v-for="(buttonItem, index) in buttonArray"
                :key="`${buttonItem.svg}-${index}`"
                @click="buttonItem.click"
            >
                <SvgController :type="buttonItem.svg" />
            </button>
        </div>
    </header>
</template>

<script>
export default {
    name: 'HeaderSmall',
    props: {
        title: {
            type: String,
            default: null,
        },
        button: {
            type: Object,
            default: null,
        },
        buttons: {
            type: Array,
            default: null,
        },
    },
    data() {
        return {
            buttonArray: this.buttons ? this.buttons : [],
        };
    },
    mounted() {
        if (this.button) {
            this.buttonArray.push(this.button);
        }
    },
};
</script>
