<template>
    <div>
        <div class="formulate-heading flex-space-between">
            <h6>Recent Audits</h6>
            <button
                v-if="showNewButton"
                class="btn-reset btn--link btn--link--green btn--link--icon--left"
                @click="openSubmissionModal"
            >
                <SvgController type="plus" />
                New Audit
            </button>
        </div>
        <TableGridLayout
            :columns="columns"
            :rows="rental.updates"
            mode="remote"
            :has-header="false"
            :has-footer="false"
            :has-dropdown="false"
            :total-records="rental.updates.length"
            :output="['table']"
            @onRowClick="onRowClick"
        />
        <Modal
            ref="newSubmissionModal"
            class="modal--submission"
        >
            <template #header>
                <h3>New Agreement Audit</h3>
            </template>
            <template #body>
                <Alert type="info">
                    <p>Please upload photographs and provide further details to show that you are maintaining the land as per your BNG Agreement</p>
                </Alert>
                <FormulateForm
                    ref="submissionForm"
                    v-model="form"
                    :errors="form.errors"
                    class="formulate-form--plot flex-col"
                    @submit="submitUpdate"
                >
                    <div class="details">
                        <FormulateInput
                            type="textarea"
                            name="description"
                            label="Audit Details"
                            validation="required"
                            validation-name="Details"
                        />
                    </div>
                    <FormulateInput
                        type="file"
                        name="documents"
                        accept=".pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                        validation="mime:application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                        upload-url="/attachments/upload"
                        upload-behavior="live"
                        multiple
                    >
                        <template #label>
                            <FileLabel
                                id="documents"
                                label="Documents"
                                text="Upload documents"
                            />
                        </template>
                    </FormulateInput>
                    <Files
                        :files="formDocs"
                        filetype="document"
                        :deletable="false"
                    />
                    <FormulateInput
                        type="file"
                        name="photos"
                        accept="image/jpeg,image/png,image/gif"
                        validation="mime:image/jpeg,image/png,image/gif"
                        upload-url="/attachments/upload"
                        upload-behavior="live"
                        multiple
                    >
                        <template #label>
                            <FileLabel
                                id="photos"
                                label="Photos"
                                text="Upload photos"
                            />
                        </template>
                    </FormulateInput>
                    <Gallery
                        :images="formPhotos"
                        :deletable="false"
                    />
                    <FormulateInput type="submit" />
                </FormulateForm>
            </template>
        </Modal>
        <Modal
            ref="updateModal"
            class="modal--submission"
        >
            <template #header>
                <h3>Agreement Audit<span class="modal-header-date">{{ submission.created_at }}</span></h3>
            </template>
            <template #body>
                <ShowUpdate
                    :update="submission"
                    @closeModal="closeUpdateModal"
                />
            </template>
        </Modal>
    </div>
</template>
<script>
import FileLabel from 'Utilities/vue-formulate/FileLabel';
import Gallery from 'Utilities/gallery/Gallery';
import Files from 'Utilities/files/Files';
import Alert from 'Utilities/alert/Alert';
import Modal from 'Utilities/modal/Modal';
import ShowUpdate from './ShowUpdate';

import { DateTime } from 'luxon';

export default {
    name: 'RentalUpdates',
    components: {
        FileLabel,
        Gallery,
        Alert,
        Modal,
        ShowUpdate,
        Files,
    },
    props:{
        nextAuditDue: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            form: this.$inertia.form({
                description: null,
                photos: this.formPhotos,
                documents: this.formDocs,
                rental_id: this.$page.props.rental.id,
            }),
            columns: [
                {
                    label: 'Date of Audit',
                    field: 'created_at',
                    sortable: false,
                },{
                    label: 'Status',
                    field: 'status',
                    spanClass: 'status-pill type-{status}',
                    sortable: false,
                },{
                    label: 'Rent Paid',
                    field: 'amount_paid',
                    sortable: false,
                },
            ],
            submission: {},
        };
    },
    computed: {
        rental() {
            return this.$page.props.rental;
        },
        formPhotos() {
            const photos = [];

            if (this.form.photos) {
                this.form.photos.files.forEach((file, index) => {
                    const data = {
                        id: index,
                        display_name: file.path.name,
                        filepath: file.path.url,
                    };

                    photos.push(data);
                });
            }

            return photos;
        },
        formDocs() {
            const docs = [];

            if (this.form.documents) {
                this.form.documents.files.forEach((file, index) => {
                    const data = {
                        id: index,
                        display_name: file.path.name,
                        filepath: file.path.url,
                    };

                    docs.push(data);
                });
            }

            return docs;
        },
        showNewButton() {

            if (this.rental.status !== 'agreement started') {
                return false;
            }

            const auditDate = DateTime.fromFormat(this.rental.next_audit_date, 'dd/MM/yy');

            const dayDiffNowAndAuditDate = auditDate.diff(DateTime.now(), 'days').toObject();
            let lastAuditDate = false,
                auditDateDiff = false;

            if (this.rental.updates.length > 0) {
                lastAuditDate = DateTime.fromFormat(this.rental.updates[0].created_at, 'dd/MM/yy');
                auditDateDiff = auditDate.diff(lastAuditDate, 'days').toObject();
            }

            if (dayDiffNowAndAuditDate.days > 28) {
                return false;
            }

            if (auditDateDiff.days < 28) {
                return false;
            }

            return true;
        },
    },
    methods: {
        openSubmissionModal() {
            this.$refs.newSubmissionModal.openModal();
        },
        closeSubmissionModal() {
            this.$refs.newSubmissionModal.closeModal();
        },
        submitUpdate(data) {
            this.$inertia.post(this.$route('update.store'), data, {
                onSuccess: () => {
                    this.closeSubmissionModal();
                },
            });
        },
        openUpdateModal(data) {
            this.submission = data;
            this.$refs.updateModal.openModal();
        },
        closeUpdateModal() {
            this.$refs.updateModal.closeModal();
        },
        onRowClick({ row }) {
            this.openUpdateModal(row);
        },

    },
};
</script>
