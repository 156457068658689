var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "formulate-fake" },
    [
      _c(
        "input",
        _vm._b(
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.context.model,
                expression: "context.model",
              },
            ],
            attrs: { type: "hidden" },
            domProps: { value: _vm.context.model },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.context, "model", $event.target.value)
              },
            },
          },
          "input",
          _vm.context.attributes,
          false
        )
      ),
      _vm._v(" "),
      _c("p", [_vm._v(_vm._s(_vm.getParseValue))]),
      _vm._v(" "),
      _c(
        "FormulateSlot",
        { attrs: { name: "suffix", context: _vm.context } },
        [
          _vm.context.slotComponents.suffix
            ? _c(_vm.context.slotComponents.suffix, {
                tag: "component",
                attrs: { context: _vm.context },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }