var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "app-layout",
    { attrs: { section: "projects" } },
    [
      _c(
        "page-header",
        {
          attrs: {
            title: _vm.pageTitle,
            icon: _vm.pageIcon,
            background: false,
          },
        },
        [
          _c(
            "inertia-link",
            { staticClass: "btn-reset btn", attrs: { href: _vm.cancelRoute } },
            [_vm._v("\n            Cancel\n        ")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "FormulateForm",
        {
          ref: "form",
          staticClass: "formulate-form--project flex-col",
          attrs: { errors: _vm.$page.props.errors, "keep-model-data": true },
          on: { submit: _vm.submitForm },
          model: {
            value: _vm.form,
            callback: function ($$v) {
              _vm.form = $$v
            },
            expression: "form",
          },
        },
        [
          _c(
            "PagerContent",
            {
              ref: "pager",
              staticClass: "center",
              attrs: {
                errors: _vm.pageErrors,
                formulate: _vm.$refs.form,
                "formulate-errors": _vm.$page.props.errors,
                theme: "bng",
                validation: {
                  site_details: () =>
                    _vm.form.offset_type.includes("bng") ||
                    (_vm.form.offset_type.includes("carbon") && !_vm.form.study)
                      ? _vm.validateCustomHabitats("site_details")
                      : true,
                  baseline_study: () =>
                    _vm.validateCustomHabitats("baseline_study"),
                },
                pagination: {
                  type: "nav",
                },
              },
              scopedSlots: _vm._u([
                {
                  key: "pagination-prev-button",
                  fn: function () {
                    return [
                      _c("SvgController", { attrs: { type: "arrow-left" } }),
                      _vm._v(" "),
                      _c("span", [_vm._v("Previous")]),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "pagination-next-button",
                  fn: function () {
                    return [
                      _c("span", [_vm._v("Next")]),
                      _vm._v(" "),
                      _c("SvgController", { attrs: { type: "arrow-right" } }),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "pagination-next-last",
                  fn: function () {
                    return [
                      _c(
                        "button",
                        {
                          staticClass: "btn-reset btn btn--primary",
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.submitFormButton.apply(null, arguments)
                            },
                          },
                        },
                        [
                          !_vm.edit
                            ? _c("SvgController", { attrs: { type: "tick" } })
                            : _vm._e(),
                          _vm._v(" "),
                          _c("span", [_vm._v(_vm._s(_vm.buttonText))]),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c(
                "PagerPage",
                { attrs: { title: "Development Details" } },
                [
                  _c("FormPage1", {
                    attrs: {
                      companies: _vm.companies,
                      countries: _vm.countries,
                      "planning-authorities": _vm.planningAuthorities,
                      "river-basin-districts": _vm.riverBasinDistricts,
                      "development-type-options": _vm.developmentTypeOptions,
                      editable: _vm.editable,
                    },
                    model: {
                      value: _vm.form,
                      callback: function ($$v) {
                        _vm.form = $$v
                      },
                      expression: "form",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "PagerPage",
                {
                  attrs: {
                    name: "nutrient_offset",
                    title: "Nutrient Offset",
                    enabled: _vm.form.offset_type.includes("nutrient_offset"),
                  },
                },
                [
                  _c("FormPage2", {
                    ref: "nutrient_offset",
                    attrs: {
                      edit: _vm.edit,
                      editable: _vm.editable,
                      "planning-authorities": _vm.planningAuthorities,
                      "river-basin-districts": _vm.riverBasinDistricts,
                    },
                    on: { "file-upload": _vm.fileUpload },
                    model: {
                      value: _vm.form,
                      callback: function ($$v) {
                        _vm.form = $$v
                      },
                      expression: "form",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "PagerPage",
                {
                  attrs: {
                    name: "site_details",
                    title: "Site Details",
                    enabled: _vm.form.offset_type.includes("bng"),
                  },
                },
                [
                  _c("FormPage3", {
                    ref: "site_details",
                    attrs: {
                      edit: _vm.edit,
                      editable: _vm.editable,
                      "development-type-options": _vm.developmentTypeOptions,
                    },
                    model: {
                      value: _vm.form,
                      callback: function ($$v) {
                        _vm.form = $$v
                      },
                      expression: "form",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "PagerPage",
                {
                  attrs: {
                    name: "baseline_study",
                    title: "Baseline Study",
                    enabled:
                      _vm.form.study && _vm.form.offset_type.includes("bng"),
                  },
                },
                [
                  _c("FormPage4", {
                    ref: "baseline_study",
                    on: { change: _vm.baselineStudyHabitatChange },
                    model: {
                      value: _vm.form,
                      callback: function ($$v) {
                        _vm.form = $$v
                      },
                      expression: "form",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }