var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("RangeBetween", {
    ref: "range",
    attrs: {
      min: _vm.min,
      max: _vm.max,
      "bar-color": _vm.barColor,
      "active-bar-color": _vm.activeBarColor,
      "max-between": _vm.maxBetween,
      "edit-values": _vm.editValues,
      "allow-over-max": _vm.allowOverMax,
      disabled: _vm.disabled,
    },
    model: {
      value: _vm.value,
      callback: function ($$v) {
        _vm.value = $$v
      },
      expression: "value",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }