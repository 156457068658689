var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "label",
    {
      class: _vm.context.classes.label,
      attrs: { id: `${_vm.context.id}_label`, for: _vm.context.id },
    },
    [
      _c("span", {
        staticClass: "formulate-input-label-lebel",
        domProps: { textContent: _vm._s(_vm.context.label) },
      }),
      _vm._v(" "),
      _c("span", {
        staticClass: "formulate-input-label-description",
        domProps: { textContent: _vm._s(_vm.description) },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }