var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "app-layout",
    { attrs: { section: "plots" } },
    [
      _c(
        "page-header",
        { attrs: { title: _vm.pageTitle, icon: "plots", background: false } },
        [
          _c(
            "inertia-link",
            {
              staticClass: "btn-reset btn",
              attrs: {
                href: _vm.$route("plot.show", _vm.plot.id),
                data: { selectedTab: _vm.selectedTab },
              },
            },
            [_vm._v("\n            Cancel\n        ")]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn-reset btn btn--primary",
              attrs: { id: "form-submit" },
              on: { click: _vm.submitFormButton },
            },
            [
              _c("SvgController", { attrs: { type: "tick" } }),
              _vm._v(" "),
              _c("span", [_vm._v("Save Agreement")]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "center" },
        [
          _c(
            "FormulateForm",
            {
              ref: "form",
              staticClass: "formulate-form--plot flex-col",
              attrs: { errors: _vm.form.errors },
              on: { submit: _vm.submitForm },
              model: {
                value: _vm.form,
                callback: function ($$v) {
                  _vm.form = $$v
                },
                expression: "form",
              },
            },
            [
              _c("div", { staticClass: "form-flex--lap" }, [
                _c(
                  "div",
                  [
                    _c("div", { staticClass: "formulate-heading" }, [
                      _c("h6", [_vm._v("Agreement Details")]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "form-flex--tab" },
                      [
                        _c("FormulateInput", {
                          attrs: {
                            type: "text",
                            name: "units",
                            label: "BNG units required",
                            validation: "required",
                            "validation-name": "BNG units required",
                          },
                        }),
                        _vm._v(" "),
                        _c("FormulateInput", {
                          attrs: {
                            type: "text",
                            name: "per_unit_price",
                            label: "Agreement price per unit",
                            validation: "required",
                            "validation-name": "Agreement price per unit",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("FormulateInput", {
                      attrs: {
                        type: "select-plugin",
                        label: "Development",
                        name: "project_id",
                        options: _vm.projects,
                        validation: "required",
                        "validation-name": "Project",
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "InputSuffix",
                      {
                        scopedSlots: _vm._u([
                          {
                            key: "suffix",
                            fn: function () {
                              return [
                                _c(
                                  "div",
                                  { staticClass: "formulate-input-info" },
                                  [_c("p", [_vm._v("Hectares")])]
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      },
                      [
                        _c("FormulateInput", {
                          attrs: {
                            type: "text",
                            name: "size",
                            label: "Size",
                            validation: "required",
                            "outer-class": "formulate-input formulate-size",
                            disabled: true,
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("FormulateInput", {
                      attrs: {
                        type: "select",
                        name: "status",
                        label: "Status",
                        validation: "required",
                        "validation-name": "Status",
                        options: _vm.statusOptions,
                        placeholder: "Select Agreement status",
                      },
                    }),
                    _vm._v(" "),
                    _c("FormulateInput", {
                      attrs: {
                        type: "datetime-plugin",
                        name: "rental_startdate",
                        label: "Agreement Start Date",
                        validation: "required",
                        "validation-name": "Agreement Start Date",
                      },
                    }),
                    _vm._v(" "),
                    _vm.$page.props.loggedUser.isSuperUser
                      ? _c("FormulateInput", {
                          staticClass: "hide-year",
                          attrs: {
                            type: "datetime-plugin",
                            name: "audit_date",
                            label: "Audit Date",
                            "validation-name": "Audit Date",
                            format: "dd MMMM",
                            flow: ["month", "date"],
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  [
                    _c("div", { staticClass: "formulate-heading" }, [
                      _c("h6", [_vm._v("Location")]),
                    ]),
                    _vm._v(" "),
                    _c("alert", { attrs: { type: "info" } }, [
                      _c("p", [
                        _vm._v(
                          "Use the map below to draw around the part of the plot to be implemented, please be as accurate as possible."
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("FormulateInput", {
                      key: "map",
                      ref: "map",
                      attrs: {
                        type: "map",
                        item: _vm.form,
                        "show-geo-input": false,
                        "land-parcels": [_vm.plot],
                        leases: _vm.leases,
                        "map-type": "lease",
                        "edit-fill-color": _vm.mapColors.lease,
                        zoom: 10,
                        validation: "required",
                        errors: [_vm.$page.props.errors.location_data],
                        "fit-bounds": "landParcels",
                      },
                      model: {
                        value: _vm.form.map_location,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "map_location", $$v)
                        },
                        expression: "form.map_location",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }