<template>
    <transition name="modal">
        <div
            v-if="show"
            class="modal"
        >
            <div
                class="modal-mask"
                @click="maskClick"
            >
                <div
                    class="modal-container"
                    :style="{
                        width: width
                    }"
                    @click.stop
                >
                    <div class="modal-header">
                        <slot name="header">
                            <h3 v-if="header">
                                {{ header }}
                            </h3>
                        </slot>
                        <button
                            v-if="showClose"
                            class="btn-reset modal-close-btn"
                            @click.prevent
                            @click="closeModal()"
                        >
                            <SvgController type="times" />
                        </button>
                    </div>
                    <div class="modal-body">
                        <slot name="body">
                            Modal Content
                        </slot>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>
<script>
export default {
    name: 'Modal',
    components: {},
    props: {
        width: {
            type: String,
            default: null,
        },
        header: {
            type: String,
            default: null,
        },
        closeMaskClick: {
            type: Boolean,
            default: true,
        },
        showClose: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            show: false,
        };
    },
    mounted() {

    },
    methods:{
        closeModal() {
            this.show = false;
            document.removeEventListener('keyup', this.escWindow);
        },
        openModal() {
            this.show = true;
            document.addEventListener('keyup', this.escWindow);
        },

        maskClick() {
            if (this.closeMaskClick) {
                this.closeModal();
            }
        },
        escWindow(event) {
            if (event.which === 27) {
                this.closeModal();
            }
        },
    },
};
</script>
