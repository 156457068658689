<!-- eslint-disable vue/no-v-html -->
<template>
    <div>
        <div
            v-if="pill"
            :class="`status-pill type-${pillClass}`"
            v-html="sanitisedPill"
        />
        <FormulateForm
            v-if="habitatTypeOptions"
            ref="form"
            v-model="form"
            class="flex-col margin-0"
            @submit="submitForm"
        >
            <p class="align-right">
                <template v-if="showUnits">
                    Total Units: {{ numberCommas(getUnits('all')) }}
                </template>
                <template v-if="maxArea">
                    <br>Area used: {{ getUsedSize }} Hectares
                    <br>Area remaining: {{ getRemainingSize }} Hectares
                </template>
            </p>
            <FormulateInput
                key="habitats"
                name="habitats"
                add-label="+ Add Additional Habitat"
                type="group"
                :repeatable="!disabled && repeatable"
                element-class="formulate-input-element formulate-input-element--collapsable-group"
                outer-class="formulate-input collapsable-group"
                @repeatableAdded="showIndex = $event.length -1"
                @repeatableRemoved="showIndex = $event.length -1"
                @input="inputForm"
            >
                <template #repeatable="context">
                    <collapsable-group-item
                        :context="context"
                        title="Habitat"
                        :show-index="showIndex"
                        @open="showIndex = $event"
                    >
                        <template #title="{index}">
                            <div class="title-left">
                                <div v-if="getMissing(index) && getMissing(index).length">
                                    <SvgController
                                        class="text-red"
                                        type="info-information"
                                    />
                                </div>
                                {{ habitatTitle(index) }}
                            </div>
                            <div
                                v-if="showUnits"
                                class="title-left"
                            >
                                {{ numberCommas(getUnits(index)) }} units
                            </div>
                        </template>

                        <div
                            v-if="getMissing(context.index) && getMissing(context.index).length"
                            class="alert-box alert-box-warning"
                        >
                            <div v-html="getMissingText(context.index)" />
                        </div>

                        <FormulateInput
                            key="category"
                            type="select-button"
                            name="category"
                            :options="['land', 'hedgerows', 'rivers']"
                            element-class="formulate-input-element formulate-input-element--select-button formulate-input-element-habitat-btn"
                            :disabled="disabled"
                            @input="inputForm"
                        />

                        <FormulateInput
                            key="id"
                            type="hidden"
                            name="id"
                        />

                        <div
                            :class="{
                                'form-flex--tab': !small,
                                'formulate-input': small,
                            }"
                        >
                            <FormulateInput
                                v-if="getCategory(context.index) === 'land'"
                                key="broad_habitat"
                                type="select-plugin"
                                name="broad_habitat"
                                label="Broad Habitat"
                                validation="required"
                                validation-name="Broad Habitat"
                                placeholder="Select Broad Habitat"
                                :options="getBroadHabitatOptions"
                                :disabled="!isEnabled.broad_habitat"
                                @input="inputForm"
                            />

                            <FormulateInput
                                key="type"
                                type="select-plugin"
                                name="type"
                                validation="required"
                                placeholder="Select Habitat Type"
                                :label="typeLabel(context.index)"
                                :options="habitatTypes(context.index)"
                                :disabled="!isEnabled.type"
                                @input="setHiddenFields(context.index)"
                            />
                        </div>
                        <InputSuffix>
                            <FormulateInput
                                key="size"
                                :type="isEnabled.size ? 'number' : 'fake' "
                                name="size"
                                :label="sizeLabel(context.index)"
                                validation="required|number"
                                outer-class="formulate-input formulate-size"
                                :disabled="!isEnabled.size"
                                :parse-value="(value) => floatOutput(value)"
                                @input="inputForm"
                            />
                            <template #suffix>
                                <div class="formulate-input-info">
                                    <p>{{ sizeUnits(context.index) }}</p>
                                </div>
                            </template>
                        </InputSuffix>
                        <FormulateInput
                            v-if="photo && photoUploadUrl"
                            key="photo"
                            name="photos"
                            label="Photo"
                            type="image"
                            :required="true"
                            validation="mime:image/jpeg,image/png,image/gif"
                            :upload-url="photoUploadUrl"
                            upload-behavior="delayed"
                            @input="inputPhoto"
                            @file-removed="inputPhoto"
                        />
                    </collapsable-group-item>
                </template>
            </FormulateInput>
        </FormulateForm>
    </div>
</template>

<script>
import CollapsableGroupItem from 'Utilities/vue-formulate/CollapsableGroupItem';
import { throttle } from 'lodash';
import { addAdditionalFields } from 'Lib/defaults/habitatData';
import InputSuffix from 'Utilities/vue-formulate/InputSuffix';

export default {
    name: 'BNGCalculator',
    components: {
        CollapsableGroupItem,
        InputSuffix,
    },

    props: {
        show: {
            type: Number,
            default: 0,
        },
        showId: {
            type: Number,
            default: null,
        },
        value: {
            type: Array,
            default: null,
        },
        defaultValue: {
            type: Array,
            default: () => [{
                category: 'land',
            }],
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        excludeMissing: {
            type: Boolean,
            default: false,
        },
        maxArea: {
            type: Number,
            default: null,
        },
        pill: {
            type: String,
            default: 'Estimation only, habitat condition has been assumed <em>‘moderate’</em>',
        },
        pillClass: {
            type: String,
            default: 'red',
        },
        type: {
            type: String,
            default: 'baseline',
        },
        showUnits: {
            type: Boolean,
            default: true,
        },
        repeatable: {
            type: Boolean,
            default: true,
        },
        small: {
            type: Boolean,
            default: false,
        },
        fieldsEnabled: {
            type: Object,
            default: () => {},
        },
        additionalFields: {
            type: Object,
            default: null,
        },
        photo: {
            type: Boolean,
            default: false,
        },
        photoUploadUrl: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            formLoaded: false,
            showIndex: this.show,
            form: {
                habitats: this.valueData,
            },
            areaDetails: null,
            habitatTypeOptions: null,
            multipliers: {
                distinctiveness: {
                    'very-low': 0,
                    low: 2,
                    medium: 4,
                    high: 6,
                    'very-high': 8,
                },
                hedgerowDistinctiveness: {
                    'very-low': 1,
                    low: 2,
                    medium: 4,
                    high: 6,
                    'very-high': 8,
                },
                condition: {
                    'n-a-other': 0,
                    'condition-assessment-n-a': 1,
                    'n-a-agriculture': 1,
                    'good': 3,
                    'fairly-good': 2.5,
                    'moderate': 2,
                    'fairly-poor': 1.5,
                    'poor': 1,
                },
                strategic_significance: {
                    low: 1,
                    medium: 1.1,
                    high: 1.15,
                },
                watercourse_encroachment: {
                    'no-encroachment': 1,
                    'minor': 0.8,
                    'major': 0.5,
                    'n-a-culvert': 1,
                },
                riparian_encroachment: {
                    'no-encroachment': 1,
                    'minor': 0.95,
                    'moderate': 0.85,
                    'major': 0.75,
                },
                difficulty: {
                    'low': 1,
                    'medium': 0.67,
                    'high': 0.33,
                    'very-high': 0.1,
                },
                river_enhancement: {
                    'poor-poor': 1,
                    'poor-fairly poor': 2,
                    'poor-moderate': 4,
                    'poor-fairly good': 6,
                    'poor-good': 8,
                    'fairly-poor-fairly poor': 1,
                    'fairly-poor-moderate': 2,
                    'fairly-poor-fairly good': 4,
                    'fairly-poor-good': 6,
                    'moderate-moderate': 1,
                    'moderate-fairly good': 2,
                    'moderate-good': 4,
                    'fairly good-fairly good': 1,
                    'fairly good-good': 2,
                    'good-good': 1,
                },
                temportal: {
                    '0': {
                        'percent_of_original_unit': 100,
                        'multiplier': 1,
                    },
                    '1': {
                        'percent_of_original_unit': 96.5,
                        'multiplier': 0.965,
                    },
                    '2': {
                        'percent_of_original_unit': 93.1225,
                        'multiplier': 0.931225,
                    },
                    '3': {
                        'percent_of_original_unit': 89.8632125,
                        'multiplier': 0.898632125,
                    },
                    '4': {
                        'percent_of_original_unit': 86.71800006,
                        'multiplier': 0.8671800006,
                    },
                    '5': {
                        'percent_of_original_unit': 83.68287006,
                        'multiplier': 0.8368287006,
                    },
                    '6': {
                        'percent_of_original_unit': 80.75396961,
                        'multiplier': 0.8075396961,
                    },
                    '7': {
                        'percent_of_original_unit': 77.92758067,
                        'multiplier': 0.7792758067,
                    },
                    '8': {
                        'percent_of_original_unit': 75.20011535,
                        'multiplier': 0.7520011535,
                    },
                    '9': {
                        'percent_of_original_unit': 72.56811131,
                        'multiplier': 0.7256811131,
                    },
                    '10': {
                        'percent_of_original_unit': 70.02822742,
                        'multiplier': 0.7002822742,
                    },
                    '11': {
                        'percent_of_original_unit': 67.57723946,
                        'multiplier': 0.6757723946,
                    },
                    '12': {
                        'percent_of_original_unit': 65.21203607,
                        'multiplier': 0.6521203607,
                    },
                    '13': {
                        'percent_of_original_unit': 62.92961481,
                        'multiplier': 0.6292961481,
                    },
                    '14': {
                        'percent_of_original_unit': 60.72707829,
                        'multiplier': 0.6072707829,
                    },
                    '15': {
                        'percent_of_original_unit': 58.60163055,
                        'multiplier': 0.5860163055,
                    },
                    '16': {
                        'percent_of_original_unit': 56.55057348,
                        'multiplier': 0.5655057348,
                    },
                    '17': {
                        'percent_of_original_unit': 54.57130341,
                        'multiplier': 0.5457130341,
                    },
                    '18': {
                        'percent_of_original_unit': 52.66130779,
                        'multiplier': 0.5266130779,
                    },
                    '19': {
                        'percent_of_original_unit': 50.81816202,
                        'multiplier': 0.5081816202,
                    },
                    '20': {
                        'percent_of_original_unit': 49.03952635,
                        'multiplier': 0.4903952635,
                    },
                    '21': {
                        'percent_of_original_unit': 47.32314293,
                        'multiplier': 0.4732314293,
                    },
                    '22': {
                        'percent_of_original_unit': 45.66683292,
                        'multiplier': 0.4566683292,
                    },
                    '23': {
                        'percent_of_original_unit': 44.06849377,
                        'multiplier': 0.4406849377,
                    },
                    '24': {
                        'percent_of_original_unit': 42.52609649,
                        'multiplier': 0.4252609649,
                    },
                    '25': {
                        'percent_of_original_unit': 41.03768311,
                        'multiplier': 0.4103768311,
                    },
                    '26': {
                        'percent_of_original_unit': 39.6013642,
                        'multiplier': 0.396013642,
                    },
                    '27': {
                        'percent_of_original_unit': 38.21531646,
                        'multiplier': 0.3821531646,
                    },
                    '28': {
                        'percent_of_original_unit': 36.87778038,
                        'multiplier': 0.3687778038,
                    },
                    '29': {
                        'percent_of_original_unit': 35.58705807,
                        'multiplier': 0.3558705807,
                    },
                    '30': {
                        'percent_of_original_unit': 34.34151104,
                        'multiplier': 0.3434151104,
                    },
                    '31': {
                        'percent_of_original_unit': 33.13955815,
                        'multiplier': 0.3313955815,
                    },
                    '30+': {
                        'percent_of_original_unit': 31.97967361,
                        'multiplier': 0.3197967361,
                    },
                },
            },
        };
    },

    computed: {
        mainCategories() {
            return [{
                value: 'land',
                label: 'Land',
            },{
                value: 'hedgerows',
                label: 'Hedgerows',
            },{
                value: 'rivers',
                label: 'Rivers',
            }];
        },

        getBroadHabitatOptions() {
            const options = {};

            Object.keys(this.habitatTypeOptions).filter(key => !['hedgerows', 'rivers'].includes(key)).forEach(key => {
                options[key.replace(/\s/g, '-').toLowerCase()] = this.capitalizeFirstLetter(key.replace(/-/g, ' '));
            });

            return options;
        },

        sanitisedPill() {
            return this.pill.replace(/<script[\d\D]*?>[\d\D]*?<\/script>/gi, '');
        },

        getUsedSize() {
            return this.areaDetails ? this.numberCommas(this.areaDetails.used) : '';
        },

        getRemainingSize() {
            return this.areaDetails ? this.numberCommas(this.areaDetails.remaining) : '';
        },

        isEnabled() {
            return {
                category: this.disabled === true ? false : true,
                broad_habitat: this.disabled === true ? false : true,
                type: this.disabled === true ? false : true,
                size: this.disabled === true ? false : true,
                ...this.fieldsEnabled,
            };
        },

        valueData() {
            if (this.value) {
                return this.value.map(habitat => {
                    if (this.photo && this.photoUploadUrl) {
                        return {...habitat, photos: [{url: habitat.photo}]};
                    }

                    return {...habitat};
                });
            }

            return this.defaultValue;
        },
    },

    watch: {
        maxArea() {
            this.calcArea();
        },
        showIndex() {
            this.$emit('switch', this.showIndex, this.form.habitats[this.showIndex]);
        },
        'form.habitat': {
            handler: throttle(function() {
                this.formLoaded = true;
            }, 1000),
            deep: true,
        },
    },

    mounted() {
        this.habitatTypeOptions = addAdditionalFields(this.additionalFields);
        this.resetValue();

        if (this.showId) {
            this.form.habitats.forEach((habitat, index) => {
                if (this.showId === habitat.id) {
                    this.showIndex = index;
                }
            });
        }
    },

    methods:{
        resetValue() {
            this.form.habitats = this.valueData;

            this.calcArea();
        },

        getCategory(index) {
            return this.form.habitats[index] && Object.prototype.hasOwnProperty.call(this.form.habitats[index], 'category') && this.form.habitats[index].category;
        },

        getMissing(index) {
            return this.form.habitats[index] && Object.prototype.hasOwnProperty.call(this.form.habitats[index], 'missing') && this.form.habitats[index].missing;
        },

        getMissingText(index) {
            let text = '';
            const missing = this.getMissing(index);

            if (missing && missing.length) {
                text += `You are missing the following: ${missing.join(', ')}. `;

                if (this.excludeMissing) {
                    text += '<strong>Values will be excluded.</strong>';
                } else {
                    text += 'Values may not be accurate.';
                }
            }

            return text;
        },

        typeLabel(index) {
            const category = this.getCategory(index);

            if (!category || category === 'land') {
                return 'Habitat Type';
            }

            return `${category.slice(0, -1)} Type`;
        },

        sizeLabel(index) {
            const category = this.getCategory(index);

            if (!category || category === 'land') {
                return 'Area';
            }

            return 'Length';
        },

        sizeUnits() {
            return 'Hectares';
        },

        getOption(values) {
            const type = values.category === 'land' ? values.broad_habitat : values.category;

            if (this.habitatTypeOptions[type]) {
                const habitats = this.habitatTypeOptions[type].filter(habitat => {
                    return values.type === habitat.value;
                });

                if (habitats.length) {
                    return habitats[0];
                }
            }

            return false;
        },

        getOptions(values) {
            if (!values.category) return [];

            if (values.category === 'land') {
                const simplifyHabitat = values.broad_habitat;

                return this.habitatTypeOptions[simplifyHabitat] || [];
            }

            return this.habitatTypeOptions[values.category] || [];
        },

        habitatTypes(index) {
            const values = this.form.habitats[index] || {};
            const options = this.getOptions(values);
            const simplifyType = values.type ? values.type : '';
            const hasOption = options.findIndex((option) => {
                return option.value === simplifyType;
            }) > -1;

            if (values.type && !hasOption) {
                this.form.habitats[index].type = undefined;
            }

            return options;
        },

        getUnits(index) {
            if (!this.form.habitats) {
                return 0;
            }

            if (this.form.habitats.length > 0 && index === 'all') {
                return this.form.habitats.map((habitat, idx) => this.getUnits(idx))
                    .reduce((accumulator, currentValue) => accumulator + currentValue);
            } else if (this.form.habitats.length > 0 && index === 'land') {
                const valueList = this.form.habitats.map((habitat, idx) => ({...habitat, ...{index: idx}})).filter(habitat => habitat.category === 'land').map((habitat) => this.getUnits(habitat.index));

                if (valueList.length) return valueList.reduce((accumulator, currentValue) => accumulator + currentValue);

                return 0;
            } else if (this.form.habitats.length > 0 && index === 'hedgerows') {
                const valueList = this.form.habitats.map((habitat, idx) => ({...habitat, ...{index: idx}})).filter(habitat => habitat.category === 'hedgerows').map((habitat) => this.getUnits(habitat.index));

                if (valueList.length) return valueList.reduce((accumulator, currentValue) => accumulator + currentValue);

                return 0;
            } else if (this.form.habitats.length > 0 && index === 'rivers') {
                const valueList = this.form.habitats.map((habitat, idx) => ({...habitat, ...{index: idx}})).filter(habitat => habitat.category === 'rivers').map((habitat) => this.getUnits(habitat.index));

                if (valueList.length) return valueList.reduce((accumulator, currentValue) => accumulator + currentValue);

                return 0;
            }

            const values = this.form.habitats[index];

            if (!values || !values.size || !values.type) {
                return 0;
            }

            let size = values.size;

            if (values.category === 'hedgerows') {
                size = size / 1000;
            }

            return this.calculateUnits({...values, ...{size}});
        },

        getRetained(index = null) {
            if (!this.form.habitats || this.form.habitats.length === 0) {
                return 0;
            }

            if (index === null) {
                return this.form.habitats.map((habitat, idx) => this.getRetained(idx))
                    .reduce((accumulator, currentValue) => accumulator + currentValue);
            }

            if (typeof index === 'string') {
                let units = 0;
                const valueList = this.form.habitats.map((habitat, idx) => ({...habitat, ...{index: idx}})).filter(habitat => habitat.category === index);

                if (valueList.length) {
                    units = valueList.map(habitat => this.getRetained(habitat.index))
                        .reduce((accumulator, currentValue) => accumulator + currentValue);
                }

                return units;
            }

            const values = this.form.habitats[index];

            if (!values || !values.retained) {
                return 0;
            }

            const distinctiveness = !isNaN(this.multipliers.distinctiveness[values.distinctiveness]) ? this.multipliers.distinctiveness[values.distinctiveness] : 1;
            const condition = !isNaN(this.multipliers.condition[values.condition]) ? this.multipliers.condition[values.condition] : 1;
            const strategic_significance = !isNaN(this.multipliers.strategic_significance[values.strategic_significance]) ? this.multipliers.strategic_significance[values.strategic_significance] : 1;

            return distinctiveness * condition * strategic_significance * values.retained;
        },

        calculateUnits(values) {
            const habitatOption = this.getOption(values);
            const multipliers = this.multipliers;
            const condition = !isNaN(multipliers.condition[values.condition]) ? multipliers.condition[values.condition] : 1;
            const strategic_significance = !isNaN(multipliers.strategic_significance[values.strategic_significance]) ? multipliers.strategic_significance[values.strategic_significance] : 1;
            const watercourse_encroachment = !isNaN(multipliers.watercourse_encroachment[values.watercourse_encroachment]) ? multipliers.watercourse_encroachment[values.watercourse_encroachment] : 1;
            const riparian_encroachment = !isNaN(multipliers.riparian_encroachment[values.riparian_encroachment]) ? multipliers.riparian_encroachment[values.riparian_encroachment] : 1;
            let distinctiveness = !isNaN(multipliers.distinctiveness[values.distinctiveness]) ? multipliers.distinctiveness[values.distinctiveness] : 1,
                units = 0;

            if (values.category === 'hedgerows') {
                distinctiveness = !isNaN(multipliers.hedgerowDistinctiveness[values.distinctiveness]) ? multipliers.hedgerowDistinctiveness[values.distinctiveness] : 1;
            }

            if (this.type === 'baseline') {
                units = values.size *
                    distinctiveness *
                    condition *
                    strategic_significance *
                    watercourse_encroachment *
                    riparian_encroachment;
            } else if (this.type === 'creation' || this.type === 'enhancement') {
                let standard_time_target_years = 1,
                    final_time_target,
                    technical_difficulty_multiplier = this.multipliers.difficulty[habitatOption.technical_difficulty_creation],
                    year_created_advance = 0,
                    year_start_delay = 0,
                    baselineDistinctiveness,
                    baselineCondition;

                if (this.type === 'creation') {
                    if (habitatOption.temportal_data && habitatOption.temportal_data[values.condition]) {
                        standard_time_target_years = habitatOption.temportal_data[values.condition];
                    }
                } else if (this.type === 'enhancement') {
                    let conditionChange = `${values.baseline.condition}-${values.condition}`;

                    baselineDistinctiveness = multipliers.distinctiveness[values.baseline.distinctiveness] ? multipliers.distinctiveness[values.baseline.distinctiveness] : 1;
                    baselineCondition = multipliers.condition[values.baseline.condition] ? multipliers.condition[values.baseline.condition] : 1;

                    if (values.category === 'hedgerows') {
                        baselineDistinctiveness = !isNaN(multipliers.hedgerowDistinctiveness[values.baseline.distinctiveness]) ? multipliers.hedgerowDistinctiveness[values.baseline.distinctiveness] : 1;
                    }

                    if ((values.broad_habitat !== values.baseline.broad_habitat || values.type !== values.baseline.type) && distinctiveness > baselineDistinctiveness) {
                        conditionChange = 'lower-distinctiveness-habitat-' + values.condition;
                    }

                    technical_difficulty_multiplier = this.multipliers.difficulty[habitatOption.technical_difficulty_enhancement];

                    if (values.category === 'rivers') {
                        if (this.multipliers.river_enhancement[`${values.baseline.condition}-${values.condition}`]) {
                            standard_time_target_years = this.multipliers.river_enhancement[`${values.baseline.condition}-${values.condition}`];
                        }
                    } else {
                        if (habitatOption.temporal_enhanced && habitatOption.temporal_enhanced[conditionChange]) {
                            standard_time_target_years = habitatOption.temporal_enhanced[conditionChange];
                        }
                    }
                }

                if (!isNaN(parseInt(values.year_created_advance))) year_created_advance = parseInt(values.year_created_advance);
                if (!isNaN(parseInt(values.year_start_delay))) year_start_delay = parseInt(values.year_start_delay);

                if (standard_time_target_years === '30+') {
                    standard_time_target_years = 30;
                }

                final_time_target = (standard_time_target_years - (year_created_advance || 0)) + (year_start_delay || 0);


                if (final_time_target > 30) {
                    final_time_target = '30+';
                }

                const final_time_target_multiplier = this.multipliers.temportal[final_time_target].multiplier;

                if (this.type === 'creation') {
                    units = values.size *
                        distinctiveness *
                        condition *
                        strategic_significance *
                        watercourse_encroachment *
                        riparian_encroachment *
                        final_time_target_multiplier *
                        technical_difficulty_multiplier;
                } else if (this.type === 'enhancement') {
                    units = (
                        (
                            (
                                (values.size * distinctiveness * condition) - (values.size * baselineDistinctiveness * baselineCondition)
                            ) *
                            (technical_difficulty_multiplier * final_time_target_multiplier)
                        ) + (values.size * baselineDistinctiveness * baselineCondition)
                    ) * (strategic_significance);
                }
            }

            if (this.excludeMissing === true && values.missing && values.missing.length) {
                units = 0;
            }

            return units;
        },

        setHiddenFields(index) {
            const values = this.form.habitats[index] || {};
            const options = this.getOptions(values);

            if (!options) return;

            const currentOption = options.find((option) => {
                return option.value === values.type;
            });

            if (!currentOption) return;

            values.distinctiveness = values.distinctiveness ? values.distinctiveness : currentOption.distinctiveness;
            values.condition = values.condition ? values.condition : currentOption.condition;
            values.strategic_significance = values.strategic_significance ? values.strategic_significance : currentOption.strategic_significance;
            this.form.habitats[index] = values;
            this.inputForm();
        },

        inputForm() {
            if (!this.disabled) {
                this.$emit('input', this.form.habitats);
            }

            this.$emit('units', {
                all: this.getUnits('all'),
                land: this.getUnits('land'),
                hedgerows: this.getUnits('hedgerows'),
                rivers: this.getUnits('rivers'),
            });
            this.calcArea();
            this.$emit('area', this.areaDetails);
        },


        inputPhoto() {
            if (this.formLoaded) {
                this.form.habitats[this.showIndex].photo_upload = true;
                this.inputForm();
            }
        },

        calcArea() {
            if (this.maxArea) {
                const area = {
                    remaining: this.maxArea,
                    used: 0,
                    max: this.maxArea,
                };

                this.form.habitats.forEach(habitat => {
                    const size = parseFloat(habitat.size);

                    if (typeof size === 'number' && !isNaN(size)) {
                        area.remaining = parseFloat((area.remaining - size).toFixed(2));
                        area.used += size;
                    }
                });

                this.areaDetails = area;
            }
        },

        numberCommas(number) {
            return Number(parseFloat(number).toFixed(2)).toLocaleString('en').replace(/\.00$/g, '');
        },

        returnStandardOrAdjustedTimeToTargetCondition(values, standard_time_target_years) {
            let returnValue = 'Standard time to target condition applied',
                poorValue = -100;
            const distinctiveness = this.multipliers.distinctiveness[values.distinctiveness] ? this.multipliers.distinctiveness[values.distinctiveness] : 1;
            const habitatOption = this.getOption(values);

            if (
                habitatOption[`${values.broad_habitat}-${values.type}`] &&
                habitatOption[`${values.broad_habitat}-${values.type}`].poor &&
                !isNaN(parseInt(habitatOption[`${values.broad_habitat}-${values.type}`].poor))
            ) {
                poorValue = parseInt(habitatOption[`${values.broad_habitat}-${values.type}`].poor);
            }

            if(values.year_created_advance > 0 && values.year_start_delay > 0) {
                returnValue = 'Error -both advance and delayed habitat creation ▲';
            } else if(distinctiveness === 0) {
                returnValue = 'Standard time to target condition applied';
            } else if(values.condition === 'n-a -Agricultural' && standard_time_target_years <= values.year_created_advance) {
                returnValue = 'Check details - Is there evidence habitat creation in place? ⚠';
            } else if(standard_time_target_years <= values.year_created_advance && values.year_start_delay === 0) {
                returnValue = 'Check details - Is there evidence that habitat has reached target condition? ⚠"';
            } else if(standard_time_target_years === '') {
                returnValue = '';
            } else if(values.year_created_advance >= poorValue && values.year_created_advance > 0) {
                returnValue = 'Check details - Is there evidence habitat creation started and the threshold for Poor condition reached? ⚠';
            } else if(values.year_start_delay > 0) {
                returnValue = 'Check details- Delay in starting habitat in required condition? ⚠';
            } else if(values.year_created_advance > 0) {
                returnValue = 'Check details - Is there evidence habitat creation started/in place? ⚠';
            }

            return returnValue;
        },

        returnAppliedDifficultyInformation(standardOrAdjustedTimeToTargetCondition) {
            let returnValue = 'Standard difficulty applied';

            if(standardOrAdjustedTimeToTargetCondition === 'Check details - Is there evidence that habitat has reached target condition? ⚠') {
                returnValue = 'Low Difficulty - only applicable if all habitat created before losses';
            } else if(standardOrAdjustedTimeToTargetCondition === 'Check details - Is there evidence habitat creation started and the threshold for Poor condition reached? ⚠') {
                returnValue = 'Enhancement difficulty applied';
            }

            return returnValue;
        },


        habitatTitle(index) {
            if (this.form.habitats[index] && this.form.habitats[index].category) {
                let returnValue = `${this.capitalizeFirstLetter(this.form.habitats[index].category)}`;

                if (this.form.habitats[index].type) {
                    const type = this.habitatTypes(index).filter(type => type.value === this.form.habitats[index].type);

                    if (type.length && type[0].label) {
                        returnValue += ` - ${type[0].label}`;
                    }

                    return returnValue;
                }

                if (this.form.habitats[index].broad_habitat) {
                    returnValue += ` - ${this.getBroadHabitatOptions[this.form.habitats[index].broad_habitat]}`;

                    return returnValue;
                }
            }

            return `Habitat #${index + 1}`;
        },

        async submitForm(data) {
            this.$emit('submit-start', data);

            if (this.photo) {
                for await(const habitat of this.form.habitats) {
                    if (habitat.photos) {
                        try {
                            habitat.photo = await habitat.photos.upload();
                        } catch (err) {

                        }
                    }
                }
            }

            this.$emit('submit', data);
        },
    },
};
</script>
