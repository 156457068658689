var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "auth-layout",
    {
      attrs: { component: _vm.$options.name },
      scopedSlots: _vm._u([
        {
          key: "button",
          fn: function () {
            return [
              _c("span", [_vm._v("Don't have an account?")]),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn--primary",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      _vm.$inertia.visit(_vm.$route("register"))
                    },
                  },
                },
                [_vm._v("\n            Sign up\n        ")]
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm._v(" "),
      _c(
        "FormulateForm",
        { attrs: { errors: _vm.form.errors }, on: { submit: _vm.onSubmit } },
        [
          _c(
            "div",
            { staticClass: "form-page" },
            [
              _c("h3", [_vm._v(_vm._s(_vm.$options.name))]),
              _vm._v(" "),
              _c("FormulateInput", {
                attrs: {
                  type: "email",
                  name: "email",
                  label: "Email",
                  validation: "required|email",
                },
                model: {
                  value: _vm.form.email,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "email", $$v)
                  },
                  expression: "form.email",
                },
              }),
              _vm._v(" "),
              _c("FormulateInput", {
                attrs: {
                  type: "password",
                  name: "password",
                  label: "Password",
                },
                model: {
                  value: _vm.form.password,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "password", $$v)
                  },
                  expression: "form.password",
                },
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "formulate-input" },
                [
                  _c(
                    "inertia-link",
                    { attrs: { href: _vm.$route("password.request") } },
                    [
                      _vm._v(
                        "\n                    Forgot your password?\n                "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.flash.status === "verification-link-sent"
                ? _c("div", [
                    _c("p", [
                      _vm._v(
                        "A new verification link has been sent to the email address you provided during registration."
                      ),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("FormulateInput", {
                attrs: { type: "submit", label: "Login" },
              }),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }