<template>
    <section
        class="user-profile-dropdown"
    >
        <DropdownMenu
            v-model="show"
        >
            <button class="btn-reset flex-center site-header-item-button">
                <div class="user-profile-dropdown-image flex-center">
                    <span>{{ userInitials }}</span>
                </div>
                <div class="user-profile-dropdown-info">
                    <div class="user-profile-dropdown-info-name">
                        {{ loggedUser.first_name }} {{ loggedUser.last_name }}
                    </div>
                    <div class="user-profile-dropdown-info-company">
                        {{ loggedUser.positions[0].company.name }}
                    </div>
                    <div class="user-profile-dropdown-down">
                        <SvgController type="caret-down" />
                    </div>
                </div>
            </button>
            <div slot="dropdown">
                <dropdownMenuList :data="data" />
            </div>
        </DropdownMenu>
        <form
            v-if="!loggedUser.isImpersonator"
            id="logoutForm"
            method="POST"
            :action="$route('logout')"
        />
        <form
            v-else
            id="logoutForm"
            method="GET"
            action="/impersonate/stop"
        />
    </section>
</template>
<script>
import DropdownMenuList from 'Utilities/dropdown/DropdownMenuList';
export default {
    name: 'UserProfileDropdown',
    components: {
        DropdownMenuList,
    },
    data() {
        return {
            show: false,
            data: [
                {
                    name: 'My Profile',
                    icon: 'user',
                    href: this.$route('profile.show'),
                },
                {
                    name: this.$page.props.loggedUser.isImpersonator ? 'Revert' : 'Logout',
                    icon: 'logout',
                    svgColor: 'red',
                    onClick: () => {
                        document.getElementById('logoutForm').submit();
                    },
                },
            ],
        };
    },
    computed: {
        loggedUser() {
            return this.$page.props.loggedUser;
        },

        userInitials() {
            let initials = this.loggedUser.first_name.substring(0, 1).toUpperCase();

            initials += this.loggedUser.last_name.substring(0, 1).toUpperCase();

            return initials;
        },
    },
};
</script>
