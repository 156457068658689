module.exports = {
    methods: {
        buildFilter() {
            let data = this.$route().params;

            if (typeof data !== 'object') {
                data = {};
            }

            return {...data};
        },
    },
};
