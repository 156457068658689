<template>
    <FormulateForm
        v-model="form"
        class="flex-col"
        :errors="form.errors"
        @submit="submitForm"
    >
        <FormulateInput
            type="text"
            name="first_name"
            label="First Name"
            validation="required"
            validation-name="First Name"
        />
        <FormulateInput
            type="text"
            name="last_name"
            label="Surname"
            validation="required"
            validation-name="Surname"
        />
        <FormulateInput
            type="email"
            name="email"
            label="Email Address"
            validation="required|email"
            validation-name="Email address"
        />

        <FormulateInput
            v-if="companies.length > 1"
            type="select-plugin"
            name="company_id"
            label="Company"
            :options="companies"
            validation="required"
            validation-name="Company"
            :searchable="true"
            :clearable="false"
            @input="companyChange"
        />

        <InputSuffix>
            <FormulateInput
                v-show="form.role_id !== '0' && form.role_id !== null"
                type="select-plugin"
                name="role_id"
                label="Role"
                :options="userRoles"
                validation="required"
                validation-name="Role"
                :clearable="false"
                :searchable="false"
            />
            <template #suffix>
                <alert
                    v-show="roleWarningShow"
                    type="warning"
                >
                    After transfering Main Admin rights to another user, you will no longer be the Main Admin.
                </alert>
            </template>
        </InputSuffix>

        <div class="formulate-input btn--group">
            <a
                href="#"
                class="btn"
                @click="$emit('closeModal')"
            >
                Cancel
            </a>
            <button
                class="btn--reset btn btn--primary"
                type="submit"
            >
                <SvgController type="tick" />
                Save
            </button>
        </div>
    </FormulateForm>
</template>

<script>
import Alert from 'Utilities/alert/Alert.vue';
import InputSuffix from 'Utilities/vue-formulate/InputSuffix';

export default {
    name: 'UserEdit',
    components: {
        Alert,
        InputSuffix,
    },

    props: {
        user: {
            type: Object,
            default: () => ({}),
        },
        editMode: {
            type: String,
            default: 'new',
        },
    },
    data() {
        return {
            form: {},
            loggedUser: this.$page.props.loggedUser,
        };
    },

    computed: {
        userRoles() {
            const roles = [];

            this.$page.props.roles.forEach((role) => {
                const showMainAdmin = role.id === 1 && (this.user.positions && (this.user.positions[0].role_id === 1 || this.loggedUser.positions[0].role_id === 1));

                if (role.id !== 1 || (showMainAdmin)) {
                    let roleLabel = role.label;

                    if (role.id === 1) {
                        roleLabel = 'Transfer Main Admin rights';
                    }

                    roles.push({
                        value: role.id,
                        label: roleLabel,
                    });
                }
            });

            return roles;
        },

        companies() {
            if (this.user.positions) {
                return this.user.positions.map((position) => {
                    return {
                        value: position.company_id,
                        label: position.company_name,
                        group_id: position.group_id,
                    };
                });
            }

            return this.$page.props.companies.map((company) => {
                return {
                    value: company.id,
                    label: company.name,
                    group_id: company.group_id,
                };
            });
        },

        roleWarningShow() {
            if (parseInt(this.form.role_id, 10) === 1 && (this.user.positions && this.user.positions[0].role_id !== 1)) {
                return true;
            }

            return false;
        },
    },

    created() {
        if (this.editMode === 'edit') {
            this.form = this.$inertia.form({
                first_name: this.user.first_name,
                last_name: this.user.last_name,
                email: this.user.email,
                role_id: this.user.positions[0].role_id.toString(),
                group_id: this.user.positions[0].group_id.toString(),
                company_id: this.user.positions[0].company_id.toString(),
            });
        } else {
            let role_id = '3';
            const company_id = this.loggedUser.positions[0].company.id.toString();

            if (company_id === '1') {
                role_id = '0';
            }

            this.form = this.$inertia.form({
                first_name: null,
                last_name: null,
                email: null,
                role_id: role_id,
                group_id: this.loggedUser.positions[0].group.id.toString(),
                company_id: company_id,
                type: 'user',
            });
        }
    },

    methods:{
        companyChange(company_id) {
            if (this.user.positions) {
                const newPosition = this.user.positions.filter((position) => {
                    return position.company_id === parseInt(company_id, 10);
                });

                this.form.role_id = newPosition[0].role_id.toString();
                this.form.group_id = newPosition[0].group_id.toString();

                return;
            }

            if (company_id === '1') {
                this.form.role_id = '0';
                this.form.group_id = '1';

                return;
            }

            const selectedCompany = this.companies.find((company) => {
                return company.value === company_id;
            });

            this.form.group_id = selectedCompany.group_id.toString();
            this.form.role_id = '3';
        },
        submitForm() {
            if (this.form.role_id === '0') {
                this.form.role_id = null;
            }

            if (this.editMode === 'edit') {
                this.form.patch(this.$route('user.update', this.user.id), {
                    onSuccess: () => {
                        this.$emit('closeModal');
                    },
                });
            } else {
                this.form.put(this.$route('invite.send'), {
                    onSuccess: () => {
                        this.$emit('closeModal');
                    },
                });
            }

        },
    },
};
</script>
