<!-- eslint-disable vue/no-v-html -->
<template>
    <div class="details-block details-bg">
        <div
            v-if="title"
            class="formulate-heading mb-3"
        >
            <h3 class="m-0">
                {{ title }}
            </h3>
        </div>
        <div v-if="item.layerType === 'landParcel' || item.layerType === 'plot'">
            <div class="status-pill">
                {{ item.status }}
            </div>

            <p>Unique Reference Number: {{ item.unique_reference_number }}</p>
            <div v-if="offsetType.includes('bng')">
                <h6>BNG Details</h6>
                <p>Current Management Style: {{ item.management_style }}</p>
                <p v-if="item.confirmed_units">
                    Confirmed Units: {{ floatOutput(item.confirmed_units) }}
                </p>
                <p v-else>
                    Estimated Units: {{ floatOutput(item.potential_estimated_units) }}
                </p>
                <p
                    v-if="$page.props.loggedUser.isSuperUser && item.potential_units"
                >
                    Potential Units: {{ floatOutput(item.potential_units) }}
                </p>
            </div>

            <div v-if="offsetType.includes('nutrient_offset')">
                <h6>Nutrient Offset Details</h6>
                <p v-if="item.drainage">
                    Drainage: {{ item.drainage }}
                </p>
                <p v-if="item.soil_type">
                    Soil Type: {{ item.soil_type }}
                </p>
                <p v-if="item.river_running">
                    Stream or river on the land {{ item.river_running ? 'Yes' : 'No' }}
                </p>
            </div>

            <template v-if="item.habitats && item.habitats.length > 0">
                <p
                    v-for="(habitat, idx) in item.habitats"
                    :key="`habitat-${idx}`"
                >
                    <span v-if="habitat.broad_habitat">{{ habitat.broad_habitat }}</span>
                    <span v-else>{{ habitat.category }}</span>
                    - {{ formatString(habitat.type) }}
                </p>
            </template>
            <inertia-link
                v-if="hasPermission('plot_view')"
                :href="$route('plot.show', item.id)"
            >
                View Land Parcel
            </inertia-link>
        </div>

        <div v-else-if="item.layerType === 'lease'">
            <p>
                Land Stage: {{ item.plot.status }}
            </p>
            <p>Unique Reference Number: {{ item.unique_reference_number }}</p>
            <p>Units: {{ item.units }}</p>
            <p>Years since implementation: {{ implementedSince }}</p>
            <inertia-link
                v-if="hasGroup(['sequest', 'lpa']) && hasPermission('rental_view')"
                :href="$route('rental.show', item.id)"
            >
                View BNG Agreement
            </inertia-link>
        </div>

        <div v-else-if="item.layerType === 'project'">
            <p>Development Type: {{ item.development_type }}</p>
            <p v-if="item.planning_ref">
                Planning Status: {{ item.planning_status }}
            </p>
            <template v-if="offsetType.includes('bng')">
                <p v-if="item.local_planning_authority">
                    LPA: {{ item.local_planning_authority.name }}
                </p>
                <p v-if="item.land_status">
                    Land Status: {{ item.land_status }}
                </p>
                <p v-if="item.planning_ref">
                    Pning Reference Number: {{ item.planning_ref }}
                </p>
                <p v-if="parseFloat(item.onsite_desired_percentage) > 0">
                    Desired Net Gain Percentage: {{ item.onsite_desired_percentage }}
                </p>
                <p v-if="parseFloat(item.onsite_baseline_total) > 0">
                    Total Baseline Units: {{ item.onsite_baseline_total }}
                </p>
                <p v-if="parseFloat(item.onsite_post_intervention_total) > 0">
                    Total Post Intervention Units: {{ item.onsite_post_intervention_total }}
                </p>
                <template v-if="$page.props.loggedUser.isSuperUser">
                    <p>{{ item.study ? 'Study Done' : '' }}</p>
                    <p>{{ item.study_requested ? 'Study Requested' : '' }}</p>
                </template>
            </template>
            <template v-if="offsetType.includes('nutrient_offset')">
                <p v-if="item.river_basin_district">
                    River District: {{ item.river_basin_district.name }}
                </p>
                <p v-if="item.river_basin_district">
                    Type of development: {{ item.nutrient_development_type }}
                </p>
                <p v-if="typeNumberAmount && item.nutrient_development_amount">
                    {{ typeNumberAmount }}: {{ item.nutrient_development_amount }}
                </p>
            </template>

            <p v-if="item.unique_reference_number">
                Unique Reference Number: {{ item.unique_reference_number }}
            </p>

            <inertia-link
                v-if="hasPermission('project_view')"
                :href="$route('project.show', item.id)"
            >
                View Development
            </inertia-link>
        </div>

        <div v-else-if="item.layerType === 'vector'">
            <p
                v-for="(value, key) in item.properties"
                :key="key"
            >
                {{ key }}: {{ value }}
            </p>
        </div>

        <div
            v-else-if="item.layerType === 'habitat'"
            style="width: 500px"
        >
            <div class="form-flex--lap m-0">
                <div
                    :class="{
                        'w-full' : !item.photo,
                    }"
                >
                    <template v-if="item.item_type === 'plot'">
                        <p>Habitat Type: {{ item.type }}</p>
                        <div v-html="item.description" />
                        <p>Unique Reference Number: {{ item.unique_reference_number }}</p>
                        <p>Size: {{ item.size }} ha</p>
                    </template>
                    <template v-else>
                        <p>Category: {{ item.category }}</p>
                        <p>Habitat Type: {{ item['habitat-type'].replace(/-/g, ' ') | capitalize }}</p>
                        <p v-if="item.broad_habitat">
                            Broad Habitat: {{ item.broad_habitat.replace(/-/g, ' ') | capitalize }}
                        </p>
                        <p>Size: {{ item.size }} ha</p>
                    </template>
                </div>
                <div
                    v-if="item.photo"
                >
                    <img
                        :src="item.photo"
                        class="w-full"
                        @click="clickImage"
                    >
                </div>
            </div>
            <div
                v-if="item.item_type !== 'plot'"
                class="flex"
            >
                <inertia-link
                    :href="item.related_type === 'plot-habitat' ? `${$route('plot.show', item.related_id)}#habitats` : $route('habitat.edit', item.id)"
                >
                    Edit
                </inertia-link>
                <a
                    href="javascript:void(0)"
                    class="ml-3"
                    @click="deleteHabitat(item)"
                >
                    Delete
                </a>
            </div>
        </div>
    </div>
</template>

<script>

import { DateTime } from 'luxon';

export default {
    components: {},

    props: {
        item: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
        };
    },

    computed: {
        implementedSince() {
            const end = DateTime.now();
            const start = DateTime.fromFormat(this.item.rental_startdate, 'dd/MM/yy');

            const diffInYears = end.diff(start, 'years');

            const diff = diffInYears.toObject();

            const years = diff.years.toFixed(0);

            return years;
        },

        offsetType() {
            if (this.item.offset_type) {
                return this.item.offset_type.map(type => type.name);
            }

            return [];
        },

        typeNumberAmount() {
            if (this.item.nutrient_development_type) {
                return this.item.nutrient_development_type === 'Residential' ? 'Number of residential units' : 'Number of rooms';
            }

            return '';
        },

        title() {
            switch (this.item.layerType) {
                case 'habitat':
                    return 'Habitat Details';
                case 'lease':
                    return 'Agreement Details';
                default:
                    return this.item.name;
            }
        },
    },
    methods:{
        formatString(str) {
            return str.replace(/-/g, ' ');
        },

        clickImage() {
            this.$emit('showImage', this.item.photo);
        },

        deleteHabitat(habitat) {
            this.$confirm(
                {
                    title: 'Delete Habitat',
                    message: 'Are you sure you want to delete this habitat? This action can\'t be undone.',
                    confirmBtnClass: 'btn--red',
                    confirmBtnText: 'Delete',
                    callback: confirm => {
                        if (confirm) {
                            this.$inertia.delete(this.$route('habitat.destroy', habitat.id), {
                                onSuccess: (page) => {
                                    this.$emit('on-delete', page.props.lpa);
                                },
                            });
                            this.$confirm.close();
                        }
                    },
                },
            );
        },
    },
};
</script>
