<template>
    <app-layout section="planning-applications">
        <page-header
            title="Planning Applications"
            icon="map"
            :count="applications.total"
        />
        <div class="center">
            <TableGridLayout
                :columns="columns"
                :rows="applications.data"
                mode="remote"
                :output="['table']"
                :total-records="applications.total"
                @onRowClick="onRowClick"
            >
                <!--
                :grid-layout="gridLayout"
                 -->
                <template #header-left>
                    <list-filter
                        :fields="filterOptions"
                        search-placeholder="Search Applications"
                        section="applications"
                    />
                </template>

                <template #cell(lpas)="{row}">
                    <span
                        v-for="lpa in row.lpas"
                        :key="lpa.id"
                        class="block mb-2"
                    >
                        {{ lpa.name }}
                    </span>
                </template>

                <template
                    v-if="applications.links"
                    #footer
                >
                    <pagination
                        :links="applications.links"
                        section="applications"
                    />
                </template>
            </TableGridLayout>
        </div>
    </app-layout>
</template>

<script>
import Pagination from 'Utilities/pagination/Pagination';
import ListFilter from 'Utilities/list-filter/ListFilter';

export default {
    name: 'PlanningApplications',
    components: {
        Pagination,
        ListFilter,
    },

    props: {
        applications: {
            type: Object,
            default: null,
        },
        planningAuths: {
            type: Array,
            default: null,
        },
    },
    data() {
        return {
            columns: [{
                label: 'LPA',
                field: 'lpas',
                sortable: false,
                width: '10em',
            },{
                label: 'Application Name',
                field: 'name',
            }, {
                label: 'Application Date',
                field: 'date_received',
            }, {
                label: 'Status',
                field: 'status',
            }, {
                label: 'BNG Status',
                field: 'bng_status',
            }, {
                label: 'BNG Next Action Date',
                field: 'bng_next_action_date',
            }],
            filterOptions: [{
                name: 'planning_auth',
                placeholder: 'Planning Authority',
                options: this.planningAuths,
                type: 'select-plugin',
                searchable: true,
            },{
                name: 'application_dates',
                placeholder: 'Application Dates',
                type: 'daterange-plugin',
            },{
                name: 'bng_next_action_date',
                placeholder: 'Next Action Date',
                type: 'daterange-plugin',
            }],
        };
    },

    mounted() {},

    methods:{
        onRowClick({ row }) {
            this.$inertia.visit(this.$route('planningApplications.show', row.id));
        },
    },
};
</script>
