var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      key: `toggle-button-${_vm.context.name}`,
      class: _vm.context.classes.element,
      attrs: { "data-type": _vm.context.type },
    },
    [
      _c(
        "label",
        {
          key: `toggle-button-label-${_vm.context.name}`,
          staticClass: "toggle-button-container",
        },
        [
          _c(
            "input",
            _vm._g(
              _vm._b(
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.context.model,
                      expression: "context.model",
                    },
                  ],
                  key: `toggle-button-input-${_vm.context.name}`,
                  attrs: { type: "checkbox", disabled: _vm.disabled },
                  domProps: {
                    value: _vm.context.value,
                    checked: Array.isArray(_vm.context.model)
                      ? _vm._i(_vm.context.model, _vm.context.value) > -1
                      : _vm.context.model,
                  },
                  on: {
                    blur: _vm.context.blurHandler,
                    change: function ($event) {
                      var $$a = _vm.context.model,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = _vm.context.value,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(_vm.context, "model", $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.context,
                              "model",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(_vm.context, "model", $$c)
                      }
                    },
                  },
                },
                "input",
                _vm.attributes,
                false
              ),
              _vm.$listeners
            )
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              key: `toggle-button-bg-${_vm.context.name}`,
              staticClass: "toggle-button-background",
              class: { "toggle-button-background--active": _vm.context.model },
            },
            [_c("div", { staticClass: "toggle-button-slider" })]
          ),
          _vm._v(" "),
          _vm._t(
            "default",
            function () {
              return [
                _c(
                  _vm.context.slotComponents.label,
                  _vm._b(
                    {
                      key: `toggle-button-component-${_vm.context.name}`,
                      tag: "component",
                      attrs: { context: _vm.context },
                    },
                    "component",
                    _vm.context.slotProps.label,
                    false
                  )
                ),
              ]
            },
            { context: _vm.context }
          ),
          _vm._v(" "),
          _vm.color
            ? _c("div", {
                ref: "color",
                staticClass: "toggle-button-key",
                style: _vm.style,
              })
            : _vm._e(),
        ],
        2
      ),
      _vm._v(" "),
      _vm.tooltipHelp
        ? _c("tooltip", { attrs: { content: _vm.tooltipHelp } })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }