<template>
    <app-layout section="projects">
        <page-header
            :title="pageTitle"
            :icon="pageIcon"
            :background="false"
        >
            <inertia-link
                class="btn-reset btn"
                :href="cancelRoute"
            >
                Cancel
            </inertia-link>
        </page-header>
        <FormulateForm
            ref="form"
            v-model="form"
            :errors="$page.props.errors"
            class="formulate-form--project flex-col"
            :keep-model-data="true"
            @submit="submitForm"
        >
            <PagerContent
                ref="pager"
                class="center"
                :errors="pageErrors"
                :formulate="$refs.form"
                :formulate-errors="$page.props.errors"
                theme="bng"
                :validation="{
                    site_details: () => form.offset_type.includes('bng') || (form.offset_type.includes('carbon') && !form.study) ? validateCustomHabitats('site_details') : true,
                    baseline_study: () => validateCustomHabitats('baseline_study'),
                }"
                :pagination="{
                    type: 'nav',
                }"
            >
                <PagerPage
                    title="Development Details"
                >
                    <FormPage1
                        v-model="form"
                        :companies="companies"
                        :countries="countries"
                        :planning-authorities="planningAuthorities"
                        :river-basin-districts="riverBasinDistricts"
                        :development-type-options="developmentTypeOptions"
                        :editable="editable"
                    />
                </PagerPage>
                <PagerPage
                    name="nutrient_offset"
                    title="Nutrient Offset"
                    :enabled="form.offset_type.includes('nutrient_offset')"
                >
                    <FormPage2
                        ref="nutrient_offset"
                        v-model="form"
                        :edit="edit"
                        :editable="editable"
                        :planning-authorities="planningAuthorities"
                        :river-basin-districts="riverBasinDistricts"
                        @file-upload="fileUpload"
                    />
                </PagerPage>
                <PagerPage
                    name="site_details"
                    title="Site Details"
                    :enabled="form.offset_type.includes('bng')"
                >
                    <FormPage3
                        ref="site_details"
                        v-model="form"
                        :edit="edit"
                        :editable="editable"
                        :development-type-options="developmentTypeOptions"
                    />
                </PagerPage>
                <PagerPage
                    name="baseline_study"
                    title="Baseline Study"
                    :enabled="form.study && form.offset_type.includes('bng')"
                >
                    <FormPage4
                        ref="baseline_study"
                        v-model="form"
                        @change="baselineStudyHabitatChange"
                    />
                </PagerPage>
                <template #pagination-prev-button>
                    <SvgController type="arrow-left" />
                    <span>Previous</span>
                </template>
                <template #pagination-next-button>
                    <span>Next</span>
                    <SvgController type="arrow-right" />
                </template>
                <template #pagination-next-last>
                    <button
                        class="btn-reset btn btn--primary"
                        @click.prevent="submitFormButton"
                    >
                        <SvgController
                            v-if="!edit"
                            type="tick"
                        />
                        <span>{{ buttonText }}</span>
                    </button>
                </template>
            </PagerContent>
        </FormulateForm>
    </app-layout>
</template>
<script>
import FormPage1 from './form/FormPage1';
import FormPage2 from './form/FormPage2';
import FormPage3 from './form/FormPage3';
import FormPage4 from './form/FormPage4';

import { PagerContent, PagerPage } from 'vue2-pager';

export default {
    name: 'ProjectForm',
    components: {
        FormPage1,
        FormPage2,
        FormPage3,
        FormPage4,
        PagerContent,
        PagerPage,
    },
    props: {
        edit: {
            type: Boolean,
            default: false,
        },
        project: {
            type: Object,
            default: ()=>({}),
        },
        developmentTypeOptions: {
            type: Array,
            required: true,
        },
        companies: {
            type: Array,
            default: () => [],
        },
        countries: {
            type: Array,
            default: () => ([]),
        },
        planningAuthorities: {
            type: Array,
            default: () => ([]),
        },
        riverBasinDistricts: {
            type: Array,
            default: () => ([]),
        },
    },
    data() {
        const offsetTypes = this.edit && this.project.offset_type ? this.project.offset_type.map(offset => offset.name) : [];
        const nutrientTypes = {
            nutrient_types: [],
        };

        if (this.project && this.project.nutrient_type) {
            this.project.nutrient_type.forEach(type => {
                nutrientTypes.nutrient_types.push(type.name);
                nutrientTypes[`nutrient_types_pre_${type.name}_type`] = type.pre;
                nutrientTypes[`nutrient_types_post_${type.name}_type`] = type.post;
            });
        }

        const nutrientCalculator = this.project.attachments ? this.project.attachments
            .filter(attachment => attachment.type === 'nutrient_calculator')
            .map(attachment => ({
                name: attachment.display_name,
                url: attachment.filepath,
            })) : [];

        return {
            form: this.$inertia.form({
                // General
                name: this.project.name,
                offset_type: offsetTypes,
                country_code: this.project.country_code,
                company_id: this.edit ? this.project.company_id.toString() : null,
                status: this.project.status,
                map_location: {
                    inputGeo: this.project.address,
                    points: this.project.location_data && this.project.location_data.lat ? this.project.location_data : null,
                    ...this.project.location_data,
                },
                address: '',
                location_data: [],

                // BNG
                size: this.project.size,
                size_units: this.project.size_units,
                development_type: this.project.development_type,
                planning_authority_id: offsetTypes.includes('bng') ? this.lpaLocation() : null,
                planning_ref: this.project.planning_ref ?? '',
                planning_status: this.project.planning_status,
                land_status: this.project.land_status,
                study: this.project.study ? this.project.study : true,
                study_requested: this.project.study_requested ? this.project.study_requested : true,
                onsite_baseline: this.project.habitats ? this.project.habitats.filter(habitat => habitat.status === 'baseline') : [],
                onsite_creation: this.project.habitats ? this.project.habitats.filter(habitat => habitat.status === 'creation') : [],
                onsite_enhancement: this.project.habitats ? this.project.habitats.filter(habitat => habitat.status === 'enhancement') : [],
                onsite_baseline_total: this.project.onsite_baseline_total ? this.project.onsite_baseline_total : 0,
                onsite_post_intervention_total: this.project.onsite_post_intervention_total ? this.project.onsite_post_intervention_total : 0,
                desired_total: this.project.desired_total ? this.project.desired_total : 0,
                onsite_desired_percentage: this.project.onsite_desired_percentage ? this.project.onsite_desired_percentage : 10,

                // RIVERS
                river_basin_district_id: offsetTypes.includes('nutrient_offset') ? String(this.project.river_basin_district_id) : null,
                nutrient_development_type: this.project.nutrient_development_type ? this.project.nutrient_development_type : null,
                nutrient_development_amount: this.project.nutrient_development_amount ? this.project.nutrient_development_amount : 0,
                nutrient_planning_status: this.project.nutrient_planning_status ? this.project.nutrient_planning_status : null,
                nutrient_study: this.project.nutrient_study ? this.project.nutrient_study : 0,
                nutrient_calculator: nutrientCalculator,
                ...nutrientTypes,
            }),
            zoom: 6,
            landStatusOptions: [],
            planningStatusOptions: [],
            pageErrors: {},
            files: {},
        };
    },
    computed: {
        pageTitle() {
            return this.edit ? 'Edit Development' : 'New Development';
        },
        pageIcon() {
            return this.edit ? 'files' : 'file-plus';
        },
        buttonText() {
            return this.edit ? 'Save Changes' : 'Save';
        },
        cancelRoute() {
            return this.edit ? this.$route('project.show', this.project.id) : this.$route('projects');
        },
        attachments() {
            if (!this.project.attachments) {
                return null;
            }

            const attachments = this.project.attachments.filter((item) => {
                return item.is_contract < 1;
            });

            return attachments;
        },
        statusOptions() {
            return [{
                value: 'created',
                label: 'Created',
            },{
                value: 'applied',
                label: 'Applied',
            },{
                value: 'land matched',
                label: 'Land Matched',
            },{
                value: 'contract issued',
                label: 'Contract Issued',
            },{
                value: 'land implemented',
                label: 'Land Implemented',
            }];
        },
        editable() {
            if (this.hasGroup(['sequest'])) {
                return true;
            }

            if (!this.edit) {
                return true;
            }

            return (this.edit && this.project.status === 'created' || this.project.status === 'confirmed');
        },
    },
    watch: {
        'form.planning_authority_id'(newValue, oldValue) {
            if (this.form.offset_type.includes('bng') && newValue !== oldValue) {
                this.form.river_basin_district_id = null;
            }
        },
        'form.offset_type'() {
            if (this.form.offset_type.includes('bng') && !this.form.planning_authority_id) {
                this.form.planning_authority_id = this.lpaLocation();
            }

            if (this.form.offset_type.includes('nutrient_offset') && !this.form.river_basin_district_id && this.riverBasinDistricts.length === 1) {
                this.form.river_basin_district_id = this.riverBasinDistricts[0].id;
            }
        },
        'form.onsite_desired_percentage'(newValue) {
            this.form.onsite_desired_percentage = newValue ? parseInt(newValue) : 0;
            this.calculatePercentages();
        },
        'form.map_location': {
            handler(value) {
                this.mapChange(value);
            },
            deep: true,
        },
    },
    created() {
        this.landStatusOptions = [{
            value: 'green field',
            label: 'Green Field',
        },{
            value: 'brown field',
            label: 'Brown Field',
        }];
        this.planningStatusOptions = [{
            value:'submitted',
            label:'Submitted',
        }, {
            value:'approved',
            label:'Approved',
        }, {
            value:'resolution to approve',
            label:'Resolution to Approve',
        }];
        this.mapZoom();

        if (this.form.nutrient_calculator.length) {
            this.form.nutrient_calculator.forEach(attachment => {
                const name = attachment.name.replace(/\..+$/, '');

                this.files[name] = {
                    filename: name,
                    extension: attachment.url.replace(/^.+\./, ''),
                    url: attachment.url,
                };
            });
        }
    },
    methods: {
        submitForm() {
            this.$refs.pager.submitForm(async ({ validated }) => {
                if (validated) {
                    const {
                        inputGeo,
                        inputCountry,
                    } = this.form.map_location;

                    const mapLocation = {
                        area: {
                            meters: this.form.map_location.area.meters,
                            hectares: this.form.map_location.area.hectares,
                        },
                        layer: {
                            center: {
                                lat: this.form.map_location.layer.center.lat,
                                lng: this.form.map_location.layer.center.lng,
                            },
                            paths: this.form.map_location.layer.paths.map(path => {
                                return {
                                    lat: typeof path.lat === 'function' ? parseFloat(path.lat()) : parseFloat(path.lat),
                                    lng: typeof path.lng === 'function' ? parseFloat(path.lng()) : parseFloat(path.lng),
                                };
                            }),
                            shape: this.form.map_location.layer.shape,
                        },
                    };

                    const data = {...this.form};

                    if (data.company_id) {
                        data.company_id = parseInt(data.company_id);
                    } else if (this.companies.length) {
                        data.company_id = parseInt(this.companies[0].id);
                    }

                    if (inputGeo) {
                        data.address = data.map_location.inputGeo;
                    }

                    if (inputCountry) {
                        const country = this.countries.filter(country => country.code === inputCountry);

                        if (country.length) {
                            data.country_code = country[0].code;
                        }
                    }

                    if (mapLocation) {
                        data.location_data = mapLocation;
                    }

                    delete data.map_location;

                    if (data.nutrient_types && data.nutrient_types.length) {
                        const nutrientTypes = [];

                        data.nutrient_types.forEach(type => {
                            nutrientTypes.push({
                                name: type,
                                pre: data[`nutrient_types_pre_${type}_type`],
                                post: data[`nutrient_types_post_${type}_type`],
                            });
                        });

                        data.nutrient_types = nutrientTypes;
                    }

                    const fileNames = Object.keys(this.files);

                    fileNames.forEach(name => {
                        data[name] = this.files[name];
                    });

                    if (this.edit) {
                        this.$inertia.post(this.$route('project.update', this.project.id), {
                            _method: 'put',
                            ...data,
                        });
                    } else {
                        this.$inertia.post(this.$route('project.store'), {
                            ...data,
                        });
                    }
                }
            });
        },

        submitFormButton() {
            this.$refs.form.formSubmitted();
        },

        openFileModal() {
            this.$refs.fileModal.openModal();
        },

        mapZoom() {
            this.zoom = this.form.map_location.points && this.form.map_location.points.length > 0 ? 13 : 6;
        },

        lpaLocation() {
            let value = this.edit && this.project.planning_authority_id ? this.project.planning_authority_id.toString() : null;

            if (this.hasGroup('lpa') && this.$page.props.loggedUser.LPAs.length === 1) {
                value = this.planningAuthorities.filter((item) => item.id === this.$page.props.loggedUser.LPAs[0])[0].id.toString();
            } else if (this.planningAuthorities.length === 1) {
                value = this.planningAuthorities[0].id.toString();
            }

            return value;
        },

        validateCustomHabitats(ref) {
            delete this.pageErrors[ref];

            return true;
        },

        calculatePercentages() {
            const { habitatsUnits } = this.$refs.baseline_study;

            this.form.onsite_baseline_total = habitatsUnits.baseline.total;
            this.form.onsite_post_intervention_total = habitatsUnits.creation.total + habitatsUnits.enhancement.total + habitatsUnits.retained.total;

            this.form.onsite_post_intervention_total = parseFloat(this.form.onsite_post_intervention_total);

            if (!isNaN(this.form.onsite_post_intervention_total) && !isNaN(this.form.onsite_desired_percentage)) {
                this.form.desired_total = (this.form.onsite_baseline_total + ((this.form.onsite_baseline_total / 100) * this.form.onsite_desired_percentage)) - this.form.onsite_post_intervention_total;
            }
        },

        baselineStudyHabitatChange(value) {
            this.form.onsite_baseline = value.onsite_baseline;
            this.form.onsite_creation = value.onsite_creation;
            this.form.onsite_enhancement = value.onsite_enhancement;
            this.calculatePercentages();
            this.form.biodiversity_metric_document = value.biodiversity_metric_document;
        },

        fileUpload(name, event) {
            this.files[name] = event.path;
        },

        mapChange(value) {
            if (value.area && value.area.hectares) {
                this.form.size = value.area.hectares.toFixed(2);
                this.form.size_units = 'hectares';
            }
        },
    },
};
</script>
