var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "app-layout",
    { attrs: { section: "projects" } },
    [
      _c(
        "page-header",
        {
          attrs: { title: _vm.title, icon: "files", count: _vm.projects.total },
        },
        [
          _vm.$page.props.loggedUser.can.includes("project_save") &&
          !_vm.$page.props.loggedUser.isLPA
            ? _c(
                "inertia-link",
                {
                  staticClass: "btn-reset btn btn--primary",
                  attrs: { href: _vm.$route("project.create") },
                },
                [
                  _c("SvgController", { attrs: { type: "plus" } }),
                  _vm._v(" "),
                  _c("span", [_vm._v("New Development")]),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "center" },
        [
          _c("project-table", {
            attrs: {
              projects: _vm.projects,
              statuses: _vm.$page.props.statuses,
              "force-hide-company": _vm.isPropertyDeveloperPage,
            },
            scopedSlots: _vm._u([
              {
                key: "cell(study)",
                fn: function ({ row }) {
                  return [
                    _c("span", [
                      _vm._v(
                        "\n                    " +
                          _vm._s(
                            _vm.studyStatus(
                              row.study,
                              row.study_requested,
                              row.contacted
                            )
                          ) +
                          "\n                "
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }