var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "FormulateForm",
    {
      staticClass: "flex-col",
      attrs: { errors: _vm.form.errors },
      on: { submit: _vm.submitForm },
      model: {
        value: _vm.form,
        callback: function ($$v) {
          _vm.form = $$v
        },
        expression: "form",
      },
    },
    [
      _c("FormulateInput", {
        attrs: {
          type: "text",
          name: "first_name",
          label: "First Name",
          validation: "required",
          "validation-name": "First Name",
        },
      }),
      _vm._v(" "),
      _c("FormulateInput", {
        attrs: {
          type: "text",
          name: "last_name",
          label: "Surname",
          validation: "required",
          "validation-name": "Surname",
        },
      }),
      _vm._v(" "),
      _c("FormulateInput", {
        attrs: {
          type: "email",
          name: "email",
          label: "Email Address",
          validation: "required|email",
          "validation-name": "Email address",
        },
      }),
      _vm._v(" "),
      _vm.companies.length > 1
        ? _c("FormulateInput", {
            attrs: {
              type: "select-plugin",
              name: "company_id",
              label: "Company",
              options: _vm.companies,
              validation: "required",
              "validation-name": "Company",
              searchable: true,
              clearable: false,
            },
            on: { input: _vm.companyChange },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "InputSuffix",
        {
          scopedSlots: _vm._u([
            {
              key: "suffix",
              fn: function () {
                return [
                  _c(
                    "alert",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.roleWarningShow,
                          expression: "roleWarningShow",
                        },
                      ],
                      attrs: { type: "warning" },
                    },
                    [
                      _vm._v(
                        "\n                After transfering Main Admin rights to another user, you will no longer be the Main Admin.\n            "
                      ),
                    ]
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("FormulateInput", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.form.role_id !== "0" && _vm.form.role_id !== null,
                expression: "form.role_id !== '0' && form.role_id !== null",
              },
            ],
            attrs: {
              type: "select-plugin",
              name: "role_id",
              label: "Role",
              options: _vm.userRoles,
              validation: "required",
              "validation-name": "Role",
              clearable: false,
              searchable: false,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "formulate-input btn--group" }, [
        _c(
          "a",
          {
            staticClass: "btn",
            attrs: { href: "#" },
            on: {
              click: function ($event) {
                return _vm.$emit("closeModal")
              },
            },
          },
          [_vm._v("\n            Cancel\n        ")]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn--reset btn btn--primary",
            attrs: { type: "submit" },
          },
          [
            _c("SvgController", { attrs: { type: "tick" } }),
            _vm._v("\n            Save\n        "),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }