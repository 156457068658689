var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "li",
    { class: `gridlayout-card ${_vm.onClick ? " clickable" : ""}` },
    [
      _c(
        "div",
        { staticClass: "gridlayout-card-wrap" },
        [
          _c(
            "article",
            { on: { click: _vm.handleClick } },
            [
              _vm.includes.includes("header")
                ? _vm._t(
                    "card-header",
                    function () {
                      return [
                        _c(
                          "header",
                          { staticClass: "gridlayout-card-header flex-center" },
                          [
                            _vm.showMap && _vm.mapBinds
                              ? [
                                  _c(
                                    "draw-map",
                                    _vm._b(
                                      {
                                        key: `map-${_vm.index}-${_vm.title}`,
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          type: _vm.type,
                                          edit: false,
                                          options: {
                                            zoomControl: false,
                                            mapTypeControl: false,
                                          },
                                          "show-geo-input": false,
                                        },
                                      },
                                      "draw-map",
                                      _vm.mapBinds,
                                      false
                                    )
                                  ),
                                ]
                              : _vm.image
                              ? _c("img", { attrs: { src: _vm.image } })
                              : _c("SvgController", {
                                  attrs: { type: "logo" },
                                }),
                          ],
                          2
                        ),
                      ]
                    },
                    { item: _vm.item }
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.includes.includes("info")
                ? _c(
                    "section",
                    { staticClass: "gridlayout-card-info" },
                    [
                      _vm.title
                        ? _vm._t(
                            "card-title",
                            function () {
                              return [
                                _c(
                                  "h2",
                                  { staticClass: "gridlayout-card-info-title" },
                                  [
                                    _c(
                                      "v-clamp",
                                      {
                                        attrs: {
                                          autoresize: "",
                                          "max-lines": 2,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                            " +
                                            _vm._s(_vm.title) +
                                            "\n                        "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                            { item: _vm.item }
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.company
                        ? _vm._t(
                            "card-company",
                            function () {
                              return [
                                _c(
                                  "p",
                                  {
                                    staticClass: "gridlayout-card-info-company",
                                  },
                                  [
                                    _vm._v(
                                      "\n                        " +
                                        _vm._s(_vm.company.name) +
                                        "\n                    "
                                    ),
                                  ]
                                ),
                              ]
                            },
                            { item: _vm.item }
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.description
                        ? _vm._t(
                            "card-description",
                            function () {
                              return [
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "gridlayout-card-info-description",
                                  },
                                  [
                                    _c(
                                      "v-clamp",
                                      {
                                        attrs: {
                                          autoresize: "",
                                          "max-lines": 2,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                            " +
                                            _vm._s(_vm.description) +
                                            "\n                        "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                            { item: _vm.item }
                          )
                        : _vm._e(),
                    ],
                    2
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.includes.includes("footer")
                ? _c(
                    "footer",
                    { staticClass: "gridlayout-card-footer" },
                    [
                      _vm._t(
                        "card-footer",
                        function () {
                          return [
                            _vm.statuses
                              ? _vm._l(_vm.statuses, function (statusText) {
                                  return _c(
                                    "span",
                                    {
                                      key: statusText,
                                      staticClass: "status-pill",
                                      class: _vm.formatStatus(statusText),
                                    },
                                    [
                                      _vm._v(
                                        "\n                            " +
                                          _vm._s(statusText) +
                                          "\n                        "
                                      ),
                                    ]
                                  )
                                })
                              : _vm._e(),
                          ]
                        },
                        { item: _vm.item }
                      ),
                    ],
                    2
                  )
                : _vm._e(),
            ],
            2
          ),
          _vm._v(" "),
          _vm.dropdown &&
          _vm.dropdown.length &&
          _vm.includes.includes("dropdown")
            ? _c(
                "DropdownMenu",
                {
                  model: {
                    value: _vm.show,
                    callback: function ($$v) {
                      _vm.show = $$v
                    },
                    expression: "show",
                  },
                },
                [
                  _c(
                    "button",
                    {
                      staticClass:
                        "btn-reset flex-center gridlayout-card-footer-dropdown",
                    },
                    [_c("SvgController", { attrs: { type: "three-dots" } })],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { attrs: { slot: "dropdown" }, slot: "dropdown" },
                    [
                      _c("DropdownMenuList", {
                        attrs: { data: _vm.dropdownOptions },
                      }),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }