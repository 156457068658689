var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "label",
    {
      staticClass: "file-label formulate-heading flex-space-between",
      attrs: { for: _vm.id },
    },
    [
      _c("h6", [_vm._v(_vm._s(_vm.label))]),
      _vm._v(" "),
      _c(
        "span",
        [
          _c("SvgController", { attrs: { type: _vm.icon } }),
          _vm._v("\n        " + _vm._s(_vm.text) + "\n    "),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }