<!-- eslint-disable vue/no-v-html -->
<template>
    <div class="center">
        <div v-if="contracts.length > 0">
            <documents-table
                title=""
                new-text="New Contract"
                :allow-upload="true"
                :allow-delete="true"
                :files="contracts"
                :is-contract="true"
                :parent="parent"
            />
        </div>
        <empty
            v-else
            type="contracts"
        >
            <button
                class="btn-reset btn btn--primary"
                @click="openFileModal"
            >
                New Contract
            </button>
        </empty>
        <contract-upload
            ref="fileModal"
            :parent="parent"
        />
        <modal ref="payment">
            <template #header>
                <h3>Payment Required</h3>
            </template>
            <template #body>
                <p>Thank you for uploading your signed contract. In order to complete the transaction you now need to make the agreed payment to our account.</p>
                <p>Our bank details are:</p>
                <span
                    v-html="settings['bank-details'].value"
                />
            </template>
        </modal>
    </div>
</template>

<script>
import Empty from 'Utilities/empty/Empty';
import ContractUpload from 'Utilities/contract/Upload';
import Modal from 'Utilities/modal/Modal';
import DocumentsTable from 'Utilities/documents-table/DocumentsTable';

export default {
    name: 'Contracts',
    components: {
        Empty,
        ContractUpload,
        Modal,
        DocumentsTable,
    },
    props: {
        contracts: {
            type: Array,
            default: () => ([]),
        },
        parent: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            contract: {},
            settings: this.$page.props.settings,
        };
    },
    methods: {
        openFileModal() {
            this.$refs.fileModal.openModal();
        },
    },
};
</script>
