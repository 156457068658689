import { mapColors } from 'Lib/defaults';

export default {
    methods: {
        mapVectors(planning_authority_id, river_basin_district_id) {
            const vectors = [];

            if (planning_authority_id && this.planningAuthorities) {
                const lpa = this.planningAuthorities.filter(lpa => parseInt(lpa.id) === parseInt(planning_authority_id));
                let lpaNames = null;

                if (lpa.length) {
                    lpaNames = lpa.map(lpa => [lpa.name.replace(' LPA', ''), lpa.name, `${lpa.name} LPA`]).flat();
                }

                vectors.push({
                    name: 'lpa',
                    url: this.$mapService + '/lpa/{z}/{x}/{y}.pbf',
                    attributesUrl: this.$mapService + '/lpa.attributes.json',
                    background: mapColors.lpa,
                    backgroundOpacity: 0.1,
                    lineWidth: 2,
                    stroke: mapColors.lpa,
                    strokeOpacity: 1,
                    filter: [{
                        property: 'name',
                        compare: '=',
                        type: 'OR',
                        value: lpaNames,
                    }],
                });
            }

            if (river_basin_district_id && this.riverBasinDistricts) {
                const district = this.riverBasinDistricts.filter(district => parseInt(district.id) === parseInt(river_basin_district_id));

                vectors.push({
                    name: 'riverBasinDistricts',
                    url: this.$mapService + '/surface_water_operational_catchments/{z}/{x}/{y}.pbf',
                    attributesUrl: this.$mapService + '/surface_water_operational_catchments.attributes.json',
                    background: mapColors.river_basin_districts,
                    backgroundOpacity: 0.1,
                    lineWidth: 2,
                    stroke: mapColors.river_basin_districts,
                    strokeOpacity: 1,
                    filter: [{
                        property: 'name',
                        compare: '=',
                        value: district.length ? district[0].name : null,
                    }],
                });
            }

            return vectors;
        },
        validateMap(value) {
            if (value.value.layer && ((value.value.layer.paths && value.value.layer.paths.length && value.value.inputGeo) || (value.value.layer.shape && value.value.layer.radius))) {
                return true;
            }

            return false;
        },
    },
};

