var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "auth-background" }, [
    _c(
      "div",
      {
        staticClass: "auth-container",
        attrs: { "data-component": _vm.component, "data-logo": _vm.logo },
      },
      [
        _c("div", { staticClass: "auth-top" }, [
          _vm.logo
            ? _c(
                "div",
                { staticClass: "auth-logo" },
                [_c("SvgController", { attrs: { type: "logo-full" } })],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "auth-button" }, [_vm._t("button")], 2),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "auth-bottom" }, [
          _c(
            "div",
            { staticClass: "auth-form-container" },
            [_vm._t("default")],
            2
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }