var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "formulate-help",
      on: {
        mouseover: function ($event) {
          _vm.hover = true
        },
        mouseleave: function ($event) {
          _vm.hover = false
        },
        click: _vm.toggleHover,
      },
    },
    [
      _c(
        "div",
        { staticClass: "formulate-help-text" },
        [
          _c("SvgController", {
            staticClass: "formulate-help-icon",
            attrs: { type: "help" },
          }),
          _vm._v(" "),
          _c(
            "span",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.hover,
                  expression: "hover",
                },
              ],
            },
            [_vm._v(_vm._s(_vm.context.help))]
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.hover,
                  expression: "!hover",
                },
              ],
            },
            [_vm._v(" ")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }