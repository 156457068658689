var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "vue-google-map" },
    [
      _c(
        "google-map",
        {
          ref: "mapRef",
          staticClass: "fullpage-map",
          attrs: {
            zoom: _vm.zoom,
            center: _vm.center,
            options: _vm.mapOptions,
          },
        },
        [
          _vm._l(_vm.mapLayers, function (layer, index) {
            return [
              layer.location_data.layer.shape === "circle"
                ? _vm._l(
                    _vm.parseCircleList(layer.location_data.layer),
                    function (point, locIndex) {
                      return _c("MapLayer", {
                        key: `map-layer-${index}-${locIndex}`,
                        attrs: {
                          index: locIndex,
                          layer: { ...layer, location_data: { layer: point } },
                          type: layer.type,
                          multiple:
                            _vm.parseCircleList(layer.location_data.layer)
                              .length > 1,
                        },
                        on: { click: _vm.showInfoWindow },
                      })
                    }
                  )
                : [
                    _c("MapLayer", {
                      key: `map-layer-${index}`,
                      attrs: { layer: layer, type: layer.type },
                      on: { click: _vm.showInfoWindow },
                    }),
                  ],
            ]
          }),
          _vm._v(" "),
          _c(
            "google-map-infowindow",
            {
              attrs: {
                show: _vm.showInfo,
                position: _vm.infoWindowContext.position,
              },
              on: {
                "update:show": function ($event) {
                  _vm.showInfo = $event
                },
              },
            },
            [
              _c("info-box", {
                attrs: { item: _vm.infoWindowContext },
                on: { showImage: _vm.showImage, "on-delete": _vm.reload },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _vm._l(_vm.vectors, function (vector) {
            return _c("vector", {
              key: vector.id,
              ref: "vectors",
              refInFor: true,
              attrs: {
                "vector-url": vector.url,
                background: vector.background,
                "background-opacity": vector.backgroundOpacity,
                "line-width": vector.lineWidth,
                stroke: vector.stroke,
                "stroke-opacity": vector.strokeOpacity,
                "attributes-url": vector.attributesUrl,
                filter: vector.filter,
              },
              on: {
                ready: _vm.vectorLoad,
                onClick: (event) =>
                  _vm.showInfoWindow(event, {
                    "data-type": "vector",
                    ...vector.data,
                  }),
              },
            })
          }),
        ],
        2
      ),
      _vm._v(" "),
      _c("Modal", {
        ref: "imageModal",
        staticClass: "modal--img",
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function () {
              return [
                _vm.photo
                  ? _c("div", {
                      staticClass: "modal-image",
                      style: { "background-image": "url(" + _vm.photo + ")" },
                    })
                  : _vm._e(),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }