<template>
    <div ref="calclator">
        <FormulateForm
            v-model="form"
            class="flex-col margin-0"
        >
            <p class="align-right">
                Total Carbon {{ totalCarbon }}kg
                <br>
                Total CO<sub>2</sub>-e {{ totalCo2e }}kg
            </p>
            <FormulateInput
                name="trees"
                add-label="+ Add Tree"
                type="group"
                :repeatable="true"
                element-class="formulate-input-element formulate-input-element--collapsable-group"
                outer-class="formulate-input collapsable-group"
                @repeatableAdded="showIndex = $event.length -1"
                @repeatableRemoved="showIndex = $event.length -1"
            >
                <template #repeatable="context">
                    <collapsable-group-item
                        :context="context"
                        title="Tree"
                        :show-index="showIndex"
                        @open="showIndex = $event"
                        @itemRemoval="removeItem"
                    >
                        <template #title="{index}">
                            <div class="title-left">
                                Tree #{{ index + 1 }}
                            </div>
                            <div class="title-left">
                                Carbon {{ carbonValues[index] || 0 }}kg
                                <br>
                                CO<sub>2</sub>-e {{ co2eValues[index] || 0 }}kg
                            </div>
                        </template>
                        <template #default="{index}">
                            <p>getCategory: {{ getCategory(index) }}</p>
                            <p>soze: {{ form.trees[index] ? form.trees[index].size : 'CANNOT FIND' }}</p>
                            <FormulateInput
                                type="select-button"
                                name="category"
                                :options="['Tree', 'Shrub']"
                                element-class="formulate-input-element formulate-input-element--select-button formulate-input-element-habitat-btn"
                            />

                            <FormulateInput
                                type="hidden"
                                name="id"
                            />
                            <FormulateInput
                                v-show="getCategory(index) === 'Tree'"
                                type="select-plugin"
                                name="type"
                                label="Type"
                                validation="required"
                                validation-name="Type"
                                placeholder="Select Type"
                                label-key="name"
                                reduce-key="name"
                                :options="treeTypes"
                                @input="calculate(index)"
                            />
                            <InputSuffix>
                                <FormulateInput
                                    type="text"
                                    name="size"
                                    :label="getSizeLabel(index)"
                                    validation="required|number"
                                    outer-class="formulate-input formulate-size"
                                    @input="calculate(index)"
                                />
                                <template #suffix>
                                    <div
                                        v-if="getCategory(index) === 'Tree'"
                                        class="formulate-input-info"
                                    >
                                        <p>CM</p>
                                    </div>
                                    <div
                                        v-if="getCategory(index) === 'Shrub'"
                                        class="formulate-input-info"
                                    >
                                        <p>M</p>
                                    </div>
                                </template>
                            </InputSuffix>
                        </template>
                    </collapsable-group-item>
                </template>
            </FormulateInput>
        </FormulateForm>
    </div>
</template>

<script>
import CollapsableGroupItem from 'Utilities/vue-formulate/CollapsableGroupItem';
import InputSuffix from 'Utilities/vue-formulate/InputSuffix';

export default {
    name: 'SouthernCarbon',
    components: {
        CollapsableGroupItem,
        InputSuffix,
    },

    props: {
        value: {
            type: Array,
            default: null,
        },
    },
    data() {
        return {
            showIndex: 0,
            form: {
                trees: [],
            },
            treeTypes: [],
            carbonValues: [],
            co2eValues: [],
            totalCarbon: 0,
            totalCo2e: 0,
            loaded: false,
        };
    },

    watch: {
        loaded() {
            for (let index = 0; index < this.form.trees.length; index++) {
                this.calculate(index);
            }
        },
        totalCarbon() {
            this.inputForm();
        },
        totalCo2e() {
            this.inputForm();
        },
    },

    mounted() {
        this.resetValue();

        window.axios.get(this.$route('trees'))
            .then((response) => {
                this.treeTypes = response.data;

                this.treeTypes = this.treeTypes.map((tree) => {
                    return tree;
                });
                this.loaded = true;
            });
    },

    methods:{
        resetValue() {
            if (!this.value) {
                this.form.trees = [{
                    category: 'Tree',
                }];
            } else {
                this.form.trees = this.value;
            }
        },

        getCategory(index) {
            return this.form.trees[index] && Object.prototype.hasOwnProperty.call(this.form.trees[index], 'category') && this.form.trees[index].category ? this.form.trees[index].category : 'Tree';
        },

        getSizeLabel(index) {
            return this.form.trees[index] && Object.prototype.hasOwnProperty.call(this.form.trees[index], 'category') && this.form.trees[index].category === 'Tree' ? 'Circumference' : 'Height';
        },

        inputForm() {
            this.$emit('input', this.form.trees);
            const units = {
                carbon: this.totalCarbon,
                co2e: this.totalCo2e,
            };

            this.$emit('updateUnits', 'carbon', units);
        },

        calculate(index) {
            if (!this.loaded) {
                return;
            }

            if (!this.form.trees) {
                this.totalCarbon = 0;
                this.totalCo2e = 0;
            }

            const values = this.form.trees[index];

            if ((values.category === 'Tree' && !values.type) || !values.size) {
                this.carbonValues[index] = 0;
                this.co2eValues[index] = 0;

                return;
            }

            let biomass, tree, diameter;

            switch (values.category) {
                case 'Tree':
                    tree = this.treeTypes.find((type) => {
                        return type.name === values.type;
                    });

                    diameter = Math.log(values.size / Math.PI);

                    biomass = Math.exp(tree.constant + tree.slope * diameter);
                    break;
                case 'Shrub':
                    biomass = Math.exp(-1.0668 + 2.8807 * Math.log(values.size));
                    break;
                default:
                    break;
            }


            const carbon = Math.round(biomass * 0.5 * 1.25);

            this.co2eValues[index] = Math.round(carbon * 11 / 3);
            this.carbonValues[index] = carbon;

            this.setCarbon();
            this.setCo2e();
        },

        setCarbon() {
            if (this.carbonValues.length > 0) {
                this.totalCarbon = this.carbonValues.reduce((valA, valB) => valA + valB);

                return;
            }

            this.totalCarbon = 0;
        },
        setCo2e() {
            if (this.co2eValues.length > 0) {
                this.totalCo2e = this.co2eValues.reduce((valA, valB) => valA + valB);

                return;
            }

            this.totalCo2e = 0;
        },

        removeItem(index) {
            this.carbonValues.splice(index, 1);
            this.co2eValues.splice(index, 1);
            this.setCarbon();
            this.setCo2e();
        },
    },
};
</script>
