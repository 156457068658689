<template>
    <div>
        <div class="formulate-heading flex-space-between">
            <h6>{{ title }}</h6>
            <button
                v-if="allowUpload"
                class="btn-reset btn--link btn--link--green btn--link--icon--left"
                @click="openFileModal()"
            >
                <SvgController type="plus" />
                {{ newText || `New ${title}` }}
            </button>
        </div>
        <TableGridLayout
            :columns="columnsList"
            :rows="attachmentList"
            :output="['table']"
            mode="remote"
            :total-records="attachmentList.length"
            @onRowClick="$listeners.onRowClick ? $emit('onRowClick') : undefined"
        />
        <template v-if="isContract">
            <contract-upload
                ref="fileModal"
                :parent="parent"
                @afterUpdate="afterUpdate"
            />
            <contract-upload
                ref="signedModal"
                :parent="parent"
                :is-signed="true"
                :contract="contract"
                @afterUpdate="afterUpdate"
            />
            <contract-history
                ref="historyModal"
                :parent="parent"
                :attachments="attachmentHistory"
            />
            <contract-logs
                ref="logsModal"
                :parent="parent"
                :logs="attachmentLogs"
            />
            <contract-delete
                ref="deleteModal"
                :parent="parent"
                :contract="contract"
                :attachments="attachmentHistory"
                @afterUpdate="afterUpdate"
            />
            <contract-edit
                ref="editModal"
                :contract="contract"
                @afterUpdate="afterUpdate"
            />
        </template>
        <template v-else>
            <modal ref="fileModal">
                <template #header>
                    <h3>Add {{ fileType }}</h3>
                </template>
                <template #body>
                    <add-files
                        :file-type="fileType"
                        :parent="parent"
                    />
                </template>
            </modal>
        </template>
    </div>
</template>
<script>
import ContractUpload from 'Utilities/contract/Upload';
import ContractHistory from 'Utilities/contract/History';
import ContractLogs from 'Utilities/contract/Logs';
import ContractDelete from 'Utilities/contract/Delete';
import ContractEdit from 'Utilities/contract/Edit';
import AddFiles from 'Utilities/uploader/AddFiles';
import Modal from 'Utilities/modal/Modal';

export default {
    name: 'DocumentTable',
    components: {
        ContractUpload,
        ContractHistory,
        ContractLogs,
        ContractDelete,
        ContractEdit,
        AddFiles,
        Modal,
    },
    props: {
        allowUpload: {
            type: Boolean,
            default: false,
        },
        allowDelete: {
            type: Boolean,
            default: false,
        },
        allowViewDetail: {
            type: Boolean,
            default: false,
        },
        isContract: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default: 'Documents',
        },
        newText: {
            type: String,
            default: null,
        },
        fileType: {
            type: String,
            default: 'document',
        },
        parent: {
            type: Object,
            default: null,
        },
        files: {
            type: Array,
            default: () => [],
        },
        columns: {
            type: Array,
            default: () => null,
        },
    },
    data() {
        return {
            contract: {},
            columnsList: [],
        };
    },
    computed: {
        attachmentHistory() {
            return this.contract.attachments;
        },
        attachmentLogs() {
            return this.contract.logs;
        },
    },
    watch: {
        files: {
            handler() {
                this.formatAttachments();
            },
            deep: true,
            immediate: true,
        },
    },
    created() {

        if (!this.columns) {
            if (this.isContract) {
                this.columnsList = [{
                    label: 'Date',
                    field: 'created_at',
                    sortable: true,
                },{
                    label: 'Status',
                    field: 'status',
                    spanClass: 'status-pill type-{status}',
                    sortable: false,
                },{
                    label: 'Link',
                    field: 'display_name',
                    onClick: (evt, row, index) => {
                        this.downloadFile(this.files[index].attachments[this.attachmentList[index].attachmentIndex]);
                    },
                }];
            } else {
                this.columnsList = [{
                    label: 'Link',
                    field: 'display_name',
                    onClick: (evt, row, index) => {
                        this.downloadFile(this.files[index].attachments[this.attachmentList[index].attachmentIndex]);
                    },
                }, {
                    label: 'Date',
                    field: 'created_at',
                    sortable: true,
                }];
            }
        } else {
            this.columnsList = [...this.columns];
        }

        if (this.allowDelete) {
            this.dropdown = [
                {
                    name: 'Delete',
                    border: true,
                    onClick: (event, row, index) => {
                        this.deleteFile(this.files[index]);
                    },
                },
            ];
        }

        if (this.dropdown && this.dropdown.length) {
            this.columnsList.push({
                label: '',
                field: 'dropdown',
                type: 'dropdown',
                sortable: false,
                position: 'right',
                options: this.dropdown,
            });
        }
    },
    methods: {
        openFileModal() {
            this.$refs.fileModal.openModal();
        },
        downloadFile(attachment) {
            const link = document.createElement('a');

            link.href = '/attachments/' + attachment.id;
            link.download = attachment.display_name;
            link.click();
            link.remove();
        },
        formatAttachments() {
            this.attachmentList = [];

            if (this.isContract) {
                this.files.forEach((document) => {
                    if (document.attachments.length > 0) {
                        const arrDropdown = [];

                        if (document.status === 'contract issued') {
                            arrDropdown.push({
                                name: 'Upload signed document',
                                border: true,
                                onClick: (event, row, index) => {
                                    this.contract = this.attachmentList[index];
                                    this.openSignedModal();
                                }},
                            );
                        }

                        if (this.allowViewDetail || this.$page.props.loggedUser.isSuperUser) {
                            arrDropdown.push({
                                name: 'See Contract History',
                                border: true,
                                onClick: (event, row, index) => {
                                    this.contract = this.attachmentList[index];

                                    this.openHistoryModal();
                                },
                            }, {
                                name: 'View log history',
                                border: true,
                                onClick: (event, row, index) => {
                                    this.contract = this.attachmentList[index];

                                    this.openLogsModal();
                                },
                            });

                            if (this.allowUpload) {
                                arrDropdown.push({
                                    name: 'Edit contract status',
                                    border: true,
                                    onClick: (event, row, index) => {
                                        this.contract = this.attachmentList[index];

                                        this.openEditModal();
                                    },
                                });
                            }

                            if (this.allowDelete) {
                                arrDropdown.push({
                                    name: 'Delete contract',
                                    border: true,
                                    onClick: (event, row, index) => {
                                        this.contract = this.attachmentList[index];

                                        this.openDeleteModal();
                                    },
                                });
                            }
                        }

                        this.attachmentList.push({...document, ...{
                            attachmentIndex: 0,
                            attachmentID: document.attachments[0].id,
                            display_name: document.attachments[0].display_name,
                            dropdown: {
                                dropdown: true,
                                options: arrDropdown,
                            },
                        }});
                    }
                });
            } else {
                this.attachmentList = [...this.files];
            }
        },
        deleteFile(objFile) {
            this.$confirm(
                {
                    title: `Delete ${this.$options.filters.capitalize(this.filetype)}`,
                    message: `Are you sure you want to delete this ${this.filetype}? This action can't be undone.`,
                    confirmBtnClass: 'btn--red',
                    confirmBtnText: 'Delete',
                    callback: confirm => {
                        if (confirm) {
                            this.$inertia.delete(this.$route('attachment.destroy', objFile.id));
                            this.$confirm.close();
                        }
                    },
                },
            );
        },
        afterUpdate() {
            this.rental = this.$page.props.rental;
            this.formatAttachments();
        },
        openSignedModal() {
            this.$refs.signedModal.openModal();
        },
        openHistoryModal() {
            this.$refs.historyModal.openModal();
        },
        openLogsModal() {
            this.$refs.logsModal.openModal();
        },
        openDeleteModal() {
            this.$refs.deleteModal.openModal();
        },
        openEditModal() {
            this.$refs.editModal.openModal();
        },
    },
};
</script>
