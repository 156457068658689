<template>
    <div
        class="collapsable-group-item"
        :data-is-open="context.index === showIndex"
    >
        <div
            class="collapsable-group-item-title"
            @click="toggle"
        >
            <SvgController type="caret-down" />
            <div class="collapsable-group-item-title-text">
                <slot
                    :index="context.index"
                    name="title"
                >
                    {{ title }} #{{ context.index + 1 }}
                </slot>
            </div>
            <component
                :is="context.slotComponents.remove"
                :context="context"
                :index="context.index"
                :remove-item="removeItem"
                v-bind="context.slotProps.remove"
            />
        </div>
        <div
            v-show="context.index === showIndex"
            class="collapsable-group-item-body"
        >
            <slot :index="context.index" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'CollapsableGroupItem',
    props: {
        context: {
            type: Object,
            required: true,
        },
        showIndex: {
            type: Number,
            required: true,
        },
        title: {
            type: String,
            default: 'Item',
        },
    },

    methods: {
        toggle() {
            if (this.context.index === this.showIndex) {
                this.$emit('open', -1); // dont show anything
            } else {
                this.$emit('open', this.context.index); // show this one
            }
        },
        removeItem() {
            this.$emit('itemRemoval', this.context.index);
            this.context.removeItem();
        },
    },
};
</script>
