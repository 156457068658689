<template>
    <modal ref="settingModal">
        <template #header>
            <h3>Edit Setting</h3>
        </template>
        <template #body>
            <FormulateForm
                v-model="form"
                :errors="form.errors"
                :schema="formSchema"
                class="flex-col"
                @submit="submitForm"
            />
        </template>
    </modal>
</template>
<script>
import Modal from 'Utilities/modal/Modal';

export default {
    name: 'SettingsEdit',
    components: {
        Modal,
    },
    props: {
        data: {
            type: Object,
            required: true,
        },
    },

    computed: {
        getValue() {
            if (this.data.type === 'upload') {
                return [{ url: this.data.value }];
            } else if (this.data.type === 'option' && this.data.value) {
                return JSON.parse(this.data.value);
            }

            return this.data.value;
        },
        formSchema() {
            const arrSchema = [
                {
                    name: 'title',
                    type: 'text',
                    label: 'Title',
                    disabled: true,
                    class: 'disabled',
                },
                {
                    name: 'description',
                    type: 'textarea',
                    label: 'Description',
                    disabled: true,
                    class: 'disabled',
                },
            ];

            switch (this.data.type) {
                case 'price':
                    arrSchema.push({
                        type: 'number',
                        name: 'value',
                        label: 'Value',
                        validation: 'number',
                        required: true,
                    });
                    break;
                case 'upload':
                    arrSchema.push({
                        type: 'file',
                        name: 'value',
                        label: 'Contract',
                        accept: '.pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                        validation: 'mime:application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                        required: true,
                        uploadUrl: '/settings/upload',
                        uploadBehavior: 'delayed',
                    });
                    break;

                case 'rich-text':
                    arrSchema.push({
                        type: 'rich-text',
                        name: 'value',
                        label: 'Bank Details',
                        required: true,
                        validationName: 'Bank Details',
                    });
                    break;

                case 'option':
                    arrSchema.push({
                        type: 'group',
                        repeatable: true,
                        name: 'value',
                        addLabel: 'Add Value',
                        children: [{
                            name: 'option',
                            label: 'Option',
                        }],
                    });
                    break;
                case 'countries':
                    arrSchema.push({
                        type: 'group',
                        repeatable: true,
                        name: 'value',
                        addLabel: 'Add Value',
                        groupRepeatableClass: 'formulate-input-group-repeatable flex-col',
                        children: [{
                            name: 'code',
                            label: 'Code',
                        },{
                            name: 'name',
                            label: 'Name',
                        }],
                    });
                    break;
                case 'date':
                    arrSchema.push({
                        type: 'datetime-plugin',
                        name: 'value',
                        lable: 'Date',
                        required: true,
                        validationName: 'Date',
                        format: this.data.config.format,
                        class: 'hide-year',
                        flow: ['month', 'date'],
                    });
                    break;
                default:
                    break;
            }

            arrSchema.push({
                type: 'submit',
            });

            return arrSchema;
        },
        form: {
            get: function() {
                return this.$inertia.form({
                    title: this.data.title,
                    description: this.data.description,
                    value: this.getValue,
                    type: this.data.type,
                });
            },
            set: function() {
                return null;
            },
        },
    },
    mounted() {
        this.$refs.settingModal.openModal();
    },
    methods: {
        submitForm(data) {
            if (data.type === 'countries') {
                this.$inertia.put(this.$route('countries.update'), data, {
                    onSuccess: () => {
                        this.$refs.settingModal.closeModal();
                    },
                });

                return;
            }

            this.$inertia.put(this.$route('settings.update', this.data.id), data, {
                onSuccess: () => {
                    this.$refs.settingModal.closeModal();
                },
            });
        },
    },
};
</script>
