var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.parent
        ? _c("FormulateForm", {
            staticClass: "flex-col",
            attrs: { schema: _vm.schema, errors: _vm.form.errors },
            on: { submit: _vm.submitForm },
            model: {
              value: _vm.form,
              callback: function ($$v) {
                _vm.form = $$v
              },
              expression: "form",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }