<template>
    <div>
        <empty
            v-if="!$page.props.hasItems"
            type="local planning authorities"
        />
        <div v-else>
            <TableGridLayout
                :columns="columns"
                :rows="localPlanningAuthorities.data"
                :has-header="hasHeader"
                :has-footer="hasFooter"
                :output="['table']"
                :grid-layout-for-table="gridLayout"
            >
                <template #header-left>
                    <list-filter
                        :route="filterRoute"
                        search-placeholder="Search LPAs"
                        section="local-planning-authorities"
                    />
                </template>

                <template #footer>
                    <pagination
                        :links="localPlanningAuthorities.links"
                        section="local-planning-authorities"
                    />
                </template>
            </TableGridLayout>
            <edit
                ref="lpaEdit"
            />
            <register-user
                ref="lpaRegisterUser"
            />
        </div>
    </div>
</template>

<script>

import ListFilter from 'Utilities/list-filter/ListFilter.vue';
import Pagination from 'Utilities/pagination/Pagination.vue';
import Empty from 'Utilities/empty/Empty';
import Edit from './Edit.vue';
import RegisterUser from './RegisterUser.vue';

export default {
    name: 'LocalPlanningAuthorityTable',
    components: {
        ListFilter,
        Pagination,
        Empty,
        Edit,
        RegisterUser,
    },

    props: {
        localPlanningAuthorities: {
            type: Object,
            default: () => ({}),
        },

        hasHeader: {
            type: Boolean,
            default: true,
        },

        hasFooter: {
            type: Boolean,
            default: true,
        },

        filterRoute: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            columns: [],
            gridLayout: {},
            editingLPA: {},
        };
    },

    created() {
        const dropdown =[{
            name: 'Edit LPA',
            show: 'editable',
            onClick: (evt, row, index) => {
                this.$refs.lpaEdit.open(this.localPlanningAuthorities.data[index]);
            },
        }, {
            name: 'Register User',
            show: 'inviteAdmin',
            onClick: (evt, row, index) => {
                this.$refs.lpaRegisterUser.open(this.localPlanningAuthorities.data[index]);
            },
        }, {
            name: 'Resend Invitation',
            show: 'resendInvite',
            onClick: (evt, row, index) => {
                this.$inertia.patch(this.$route('invite.resend', this.localPlanningAuthorities.data[index].invitation_id));
            },
        }];

        this.columns = [{
            label: 'Authority Name',
            field: 'company_name',
            sort: 'name',
        },{
            label: 'First Name',
            field: 'first_name',
        },{
            label: 'Surname',
            field: 'last_name',
        },{
            label: 'Min Price',
            field: 'min_price',
            sort: 'min_price_per_unit',
        },{
            label: 'Max Price',
            field: 'max_price',
            sort: 'max_price_per_unit',
        }, {
            label: 'Total Developments',
            field: 'total_developments',
        }, {
            label: 'Total Land Parcels',
            field: 'total_land_parcels',
        }, {
            label: '',
            field: 'permissions',
            type: 'dropdown',
            sortable: false,
            position: 'right',
            options: dropdown,
        }];

        this.gridLayout = {
            title: 'company_name',
            description: 'full_name',
            dropdown,
        };
    },
};
</script>
