var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("transition", { attrs: { name: "fade" } }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.show,
            expression: "show",
          },
        ],
        staticClass: "flash-alert",
      },
      [
        _c(
          "alert",
          { attrs: { type: _vm.$page.props.flash.status || "success" } },
          [
            _vm._v(
              "\n            " +
                _vm._s(_vm.$page.props.flash.message) +
                "\n\n            "
            ),
            _c(
              "button",
              {
                staticClass: "btn-reset modal-close-btn",
                on: {
                  click: function ($event) {
                    _vm.show = false
                  },
                },
              },
              [_c("SvgController", { attrs: { type: "times" } })],
              1
            ),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }