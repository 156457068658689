<template>
    <app-layout section="dashboard">
        <page-header
            title="Dashboard"
            icon="dashboard"
        >
            <template #page-header-bottom>
                <div class="center">
                    <div class="tile-wrap tile-wrap-top flex-space-between">
                        <tile
                            :href="$route('plots')"
                            title="Total Net Gain Sites"
                            icon="plots"
                            :count="!isNaN(plots && plots.total) ? plots.total : 0"
                            color="#5EBD8A"
                        />
                        <tile
                            :href="$route('plots', {
                                'lease-status': 'agreement started'
                            })"
                            title="Implemented Net Gain Sites"
                            icon="plots"
                            :count="implementedSites"
                            color="#5EBD8A"
                        />
                        <tile
                            :href="$route('plots', {
                                status: 'BNG Units available'
                            })"
                            title="Available Net Gain Sites"
                            icon="plots"
                            :count="availableSites"
                            color="#5EBD8A"
                        />
                    </div>
                    <!-- Row 2 -->
                    <div class="tile-wrap tile-wrap-top flex-space-between">
                        <tile
                            :href="$route('plots', {
                                sortby: 'units',
                                sortdir: 'DESC',
                            })"
                            title="Total BNG units"
                            icon="plots"
                            :count="totalUnits"
                            color="#5EBD8A"
                        />
                        <tile
                            :href="$route('rentals')"
                            title="BNG units sold"
                            icon="plots"
                            :count="soldUnits"
                            color="#5EBD8A"
                        />
                        <tile
                            :href="$route('plots', {
                                status: 'BNG Units available'
                            })"
                            title="BNG units available"
                            icon="plots"
                            :count="availableUnits"
                            color="#5EBD8A"
                        />
                    </div>
                    <!-- row 3 -->
                    <div class="link-wrap flex-space-between">
                        <dashboard-link
                            :href="$route('plots', {
                                status: 'Identified land'
                            })"
                            title="Identified land"
                            :count="identifiedLand"
                        />
                        <dashboard-link
                            :href="$route('plots', {
                                status: 'Ecological Study Certified'
                            })"
                            title="Ecological Study Certified"
                            :count="ecologicalStudyCertified"
                            color="#6967D5"
                        />
                        <dashboard-link
                            :href="$route('plots', {
                                status: 'Pending contracts'
                            })"
                            title="Pending contracts"
                            :count="pendingContracts"
                            color="#E47D44"
                        />
                        <dashboard-link
                            :href="$route('plots', {
                                status: 'Completed contracts'
                            })"
                            title="Completed contracts"
                            :count="completedContracts"
                            color="#1782FF"
                        />
                        <dashboard-link
                            :href="$route('plots', {
                                status: 'BNG Units available'
                            })"
                            title="BNG Units available"
                            :count="availableSites"
                            color="#5EBD8A"
                        />
                        <dashboard-link
                            :href="$route('plots', {
                                status: 'Land Fully Leased'
                            })"
                            title="Land Fully Implemented"
                            :count="landFullyLeased"
                            :show-icon="false"
                            :show-count="false"
                            color="#E34B5D"
                        />
                    </div>
                </div>
            </template>
        </page-header>
        <div class="center dashboard">
            <template v-if="show.includes('projects') && projects && projects.projects.data">
                <div class="dashboard-heading flex-space-between">
                    <h6>Latest Developments</h6>
                    <div class="dashboard-heading-buttons">
                        <inertia-link
                            :href="$route('projects')"
                            class="btn-reset btn--link btn--link--icon--right"
                        >
                            All Developments
                            <SvgController type="arrow-right" />
                        </inertia-link>
                        <inertia-link
                            v-if="show.includes('buttonNew')"
                            :href="$route('project.create')"
                            class="btn-reset btn--link btn--link--green btn--link--icon--left"
                            as="button"
                            type="button"
                        >
                            <SvgController type="plus" />
                            New Development
                        </inertia-link>
                    </div>
                </div>
                <template v-if="projects.projects.data.length">
                    <project-table
                        :projects="projects.projects"
                        :statuses="$page.props.statuses"
                        :allow-sorting="false"
                        :has-header="false"
                        :has-footer="false"
                        :has-dropdown="false"
                        :force-hide-company="section !== 'dashboard'"
                    />
                </template>
                <template v-else>
                    <p>No Developments Created</p>
                </template>
            </template>

            <template v-if="show.includes('plots')">
                <div class="dashboard-heading flex-space-between">
                    <h6>Latest Land Parcels</h6>
                    <div class="dashboard-heading-buttons">
                        <inertia-link
                            :href="$route('plots')"
                            class="btn-reset btn--link btn--link--icon--right"
                        >
                            All Land Parcels
                            <SvgController type="arrow-right" />
                        </inertia-link>
                        <inertia-link
                            v-if="show.includes('buttonNew')"
                            :href="$route('plot.create')"
                            class="btn-reset btn--link btn--link--green btn--link--icon--left"
                            as="button"
                            type="button"
                        >
                            <SvgController type="plus" />
                            New Land Parcel
                        </inertia-link>
                    </div>
                </div>
                <template v-if="plots && plots.plots.data.length">
                    <plot-table
                        :plots="plots.plots"
                        :statuses="$page.props.statuses"
                        :allow-sorting="false"
                        :has-header="false"
                        :has-footer="false"
                        :has-dropdown="false"
                        :force-hide-company="section !== 'dashboard'"
                    />
                </template>
                <template v-else>
                    <p>No Land Parcels Created</p>
                </template>
            </template>
        </div>
    </app-layout>
</template>

<script>
import Tile from 'Components/Dashboard/Tile';
import DashboardLink from 'Components/Dashboard/DashboardLink';
import ProjectTable from '../projects/Table';
import PlotTable from '../plots/Table';

export default {
    name: 'Dashboard',
    components: {
        Tile,
        DashboardLink,
        ProjectTable,
        PlotTable,
    },
    props: {
        show: {
            type: Array,
            default: () => ([]),
        },
        projects: {
            type: Object,
            default: null,
        },
        plots: {
            type: Object,
            default: null,
        },
        section: {
            type: String,
            default: 'dashboard',
        },
        implementedSites: {
            type: Number,
            default: 0,
        },

        availableSites: {
            type: Number,
            default: 0,
        },
        totalUnits: {
            type: Number,
            default: 0,
        },
        soldUnits: {
            type: Number,
            default: 0,
        },
        availableUnits: {
            type: Number,
            default: 0,
        },

        superuserDashboard: {
            type: Boolean,
            default: false,
        },

        identifiedLand: {
            type: Number,
            default: null,
        },
        ecologicalStudyCertified: {
            type: Number,
            default: null,
        },
        pendingContracts: {
            type: Number,
            default: null,
        },
        completedContracts: {
            type: Number,
            default: null,
        },
        landFullyLeased: {
            type: Number,
            default: null,
        },
    },
    mounted() {},
};
</script>
