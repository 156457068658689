var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "app-layout",
    { attrs: { section: "settings" } },
    [
      _c("page-header", { attrs: { title: "Settings" } }),
      _vm._v(" "),
      _c(
        "div",
        { ref: "container", staticClass: "center" },
        [
          _c("TableGridLayout", {
            attrs: {
              columns: _vm.columns,
              rows: _vm.rows,
              output: ["table"],
              "grid-layout": _vm.gridLayout,
            },
            scopedSlots: _vm._u([
              {
                key: "cell(value)",
                fn: function ({ row }) {
                  return [
                    row.type === "rich-text"
                      ? _c("span", {
                          domProps: { innerHTML: _vm._s(row.value) },
                        })
                      : row.type === "upload"
                      ? _c("span", [
                          row.value
                            ? _c(
                                "a",
                                {
                                  staticClass:
                                    "btn--link btn--link--icon--right",
                                  attrs: {
                                    href: _vm.$route(
                                      "settings.download",
                                      row.id
                                    ),
                                    target: "_blank",
                                  },
                                },
                                [
                                  _vm._v("Download "),
                                  _c("SvgController", {
                                    attrs: { type: "download" },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ])
                      : row.type === "option"
                      ? _c(
                          "span",
                          _vm._l(
                            JSON.parse(row.value),
                            function (option, index) {
                              return _c("span", { key: index }, [
                                _c("span", [_vm._v(_vm._s(option.option))]),
                                _vm._v(" "),
                                index != JSON.parse(row.value).length - 1
                                  ? _c("span", [_vm._v(", ")])
                                  : _vm._e(),
                              ])
                            }
                          ),
                          0
                        )
                      : row.type === "countries"
                      ? _c("span", [
                          _vm._v(
                            "\n                    " +
                              _vm._s(row.value.length) +
                              " Countries\n                "
                          ),
                        ])
                      : row.type === "date"
                      ? _c("span", [
                          _vm._v(
                            "\n                    " +
                              _vm._s(
                                _vm.formatDate(row.value, row.config.format)
                              ) +
                              "\n                "
                          ),
                        ])
                      : _c("span", [
                          _vm._v(
                            "\n                    " +
                              _vm._s(row.value) +
                              "\n                "
                          ),
                        ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }