import { getTheme } from './mapThemes';

const colors = {
    black: '#000000',
    blue: '#1D5DDB',
    blueBaby: '#2CEFF6',
    blueBright: '#0000FF',
    brown: '#B25300',
    lime: '#A2F268',
    grayDark: '#B1B1AE',
    grayLight: '#D5D5D0',
    green: '#3AAA32',
    pink: '#FD86F0',
    purple: '#8D73DB',
    red: '#E71C1E',
    orange: '#FF8A00',
    white: '#FFFFFF',
    whiteLime: '#D1FFBE',
    whiteGreen: '#D1FFBE',
    yellow: '#FBFD80',
};

const parseTheme = (theme, background, foreground, options) => {
    return {
        fill: colors[background],
        'theme': getTheme(theme, {
            fill: colors[foreground],
            ...options,
        }),
    };
};

const habitatData = {
    'cropland': [{
        'value': 'arable-field-margins-cultivated-annually',
        'label': 'Arable field margins cultivated annually',
        'distinctiveness': 'medium',
        'condition': 'n-a-agriculture',
        'strategic_significance': 'low',
        'temportal_data': {
            'condition-assessment-n-a': 1,
        },
        'temporal_enhanced': {
            'lower-distinctiveness-habitat-condition-assessment-n-a': 1,
        },
        'technical_difficulty_creation': 'low',
        'technical_difficulty_enhancement': 'low',
        'theme': parseTheme('circles', 'white', 'orange'),
    }, {
        'value': 'arable-field-margins-game-bird-mix',
        'label': 'Arable field margins game bird mix',
        'distinctiveness': 'medium',
        'condition': 'n-a-agriculture',
        'strategic_significance': 'low',
        'temportal_data': {
            'condition-assessment-n-a': 1,
        },
        'temporal_enhanced': {
            'lower-distinctiveness-habitat-condition-assessment-n-a': 1,
        },
        'technical_difficulty_creation': 'low',
        'technical_difficulty_enhancement': 'low',
        'theme': parseTheme('circles', 'white', 'orange'),
    }, {
        'value': 'arable-field-margins-pollen-&-nectar',
        'label': 'Arable field margins pollen & nectar',
        'distinctiveness': 'medium',
        'condition': 'n-a-agriculture',
        'strategic_significance': 'low',
        'temportal_data': {
            'condition-assessment-n-a': 1,
        },
        'temporal_enhanced': {
            'lower-distinctiveness-habitat-condition-assessment-n-a': 1,
        },
        'technical_difficulty_creation': 'low',
        'technical_difficulty_enhancement': 'low',
        'theme': parseTheme('circles', 'white', 'orange'),
    }, {
        'value': 'arable-field-margins-tussocky',
        'label': 'Arable field margins tussocky',
        'distinctiveness': 'medium',
        'condition': 'n-a-agriculture',
        'strategic_significance': 'low',
        'temportal_data': {
            'condition-assessment-n-a': 1,
        },
        'temporal_enhanced': {
            'lower-distinctiveness-habitat-condition-assessment-n-a': 1,
        },
        'technical_difficulty_creation': 'low',
        'technical_difficulty_enhancement': 'low',
        'theme': parseTheme('circles', 'white', 'orange'),
    }, {
        'value': 'cereal-crops',
        'label': 'Cereal crops',
        'distinctiveness': 'low',
        'condition': 'n-a-agriculture',
        'strategic_significance': 'low',
        'temportal_data': {
            'condition-assessment-n-a': 1,
        },
        'temporal_enhanced': {},
        'technical_difficulty_creation': 'low',
        'technical_difficulty_enhancement': 'low',
        'theme': parseTheme('lines', 'white', 'orange'),
    }, {
        'value': 'cereal-crops-winter-stubble',
        'label': 'Cereal crops winter stubble',
        'distinctiveness': 'medium',
        'condition': 'n-a-agriculture',
        'strategic_significance': 'low',
        'temportal_data': {
            'condition-assessment-n-a': 1,
        },
        'temporal_enhanced': {},
        'technical_difficulty_creation': 'low',
        'technical_difficulty_enhancement': 'low',
        'theme': parseTheme('lines', 'white', 'orange'),
    }, {
        'value': 'horticulture',
        'label': 'Horticulture',
        'distinctiveness': 'low',
        'condition': 'n-a-agriculture',
        'strategic_significance': 'low',
        'temportal_data': {
            'condition-assessment-n-a': 1,
        },
        'temporal_enhanced': {},
        'technical_difficulty_creation': 'low',
        'technical_difficulty_enhancement': 'low',
        'theme': parseTheme('dimonds', 'white', 'orange'),
    }, {
        'value': 'intensive-orchards',
        'label': 'Intensive orchards',
        'distinctiveness': 'low',
        'condition': 'n-a-agriculture',
        'strategic_significance': 'low',
        'temportal_data': {
            'condition-assessment-n-a': 1,
        },
        'temporal_enhanced': {},
        'technical_difficulty_creation': 'low',
        'technical_difficulty_enhancement': 'low',
        'theme': parseTheme('dimonds', 'white', 'orange'),
    }, {
        'value': 'non-cereal-crops',
        'label': 'Non-cereal crops',
        'distinctiveness': 'low',
        'condition': 'n-a-agriculture',
        'strategic_significance': 'low',
        'temportal_data': {
            'condition-assessment-n-a': 1,
        },
        'temporal_enhanced': {},
        'technical_difficulty_creation': 'low',
        'technical_difficulty_enhancement': 'low',
        'theme': parseTheme('circles', 'orange', 'white'),
    }, {
        'value': 'temporary-grass-and-clover-leys',
        'label': 'Temporary grass and clover leys',
        'distinctiveness': 'low',
        'condition': 'n-a-agriculture',
        'strategic_significance': 'low',
        'temportal_data': {
            'condition-assessment-n-a': 1,
        },
        'temporal_enhanced': {},
        'technical_difficulty_creation': 'low',
        'technical_difficulty_enhancement': 'low',
        'theme': parseTheme('linesVertical', 'orange', 'white'),
    }],
    'grassland': [{
        'value': 'traditional-orchards',
        'label': 'Traditional orchards',
        'distinctiveness': 'high',
        'condition': 'poor',
        'strategic_significance': 'low',
        'temportal_data': {
            'good': 30,
            'fairly-good': 25,
            'moderate': 20,
            'fairly-poor': 10,
            'poor': 5,
        },
        'temporal_enhanced': {
            'lower-distinctiveness-habitat-poor': 5,
            'lower-distinctiveness-habitat-fairly-poor': 10,
            'lower-distinctiveness-habitat-moderate': 20,
            'lower-distinctiveness-habitat-fairly-good': 25,
            'lower-distinctiveness-habitat-good': 30,
            'poor-fairly-poor': 5,
            'poor-moderate': 15,
            'poor-fairly-good': 20,
            'poor-good': 25,
            'fairly-poor-moderate': 10,
            'fairly-poor-fairly-good': 15,
            'fairly-poor-good': 20,
            'moderate-fairly-good': 5,
            'moderate-good': 10,
            'fairly-good-good': 5,
        },
        'technical_difficulty_creation': 'low',
        'technical_difficulty_enhancement': 'medium',
        'theme': parseTheme('dimonds', 'white', 'orange'),
    }, {
        'value': 'bracken',
        'label': 'Bracken',
        'distinctiveness': 'low',
        'condition': 'poor',
        'strategic_significance': 'low',
        'temportal_data': {
            'condition-assessment-n-a': 1,
        },
        'temporal_enhanced': {
            'lower-distinctiveness-habitat-poor': 1,
        },
        'technical_difficulty_creation': 'low',
        'technical_difficulty_enhancement': 'low',
        'theme': parseTheme('lines', 'white', 'lime'),
    }, {
        'value': 'floodplain-wetland-mosaic-cfgm',
        'label': 'Floodplain Wetland Mosaic (CFGM)',
        'distinctiveness': 'high',
        'condition': 'poor',
        'strategic_significance': 'low',
        'temportal_data': {
            'good': 20,
            'fairly-good': 15,
            'moderate': 10,
            'fairly-poor': 8,
            'poor': 5,
        },
        'temporal_enhanced': {
            'lower-distinctiveness-habitat-poor': 5,
            'lower-distinctiveness-habitat-fairly-poor': 8,
            'lower-distinctiveness-habitat-moderate': 10,
            'lower-distinctiveness-habitat-fairly-good': 12,
            'lower-distinctiveness-habitat-good': 15,
            'poor-fairly-poor': 8,
            'poor-moderate': 10,
            'poor-fairly-good': 12,
            'poor-good': 15,
            'fairly-poor-moderate': 2,
            'fairly-poor-fairly-good': 4,
            'fairly-poor-good': 7,
            'moderate-fairly-good': 2,
            'moderate-good': 4,
            'fairly-good-good': 3,
        },
        'technical_difficulty_creation': 'high',
        'technical_difficulty_enhancement': 'medium',
        'theme': parseTheme('solid', 'lime'),
    }, {
        'value': 'lowland-calcareous-grassland',
        'label': 'Lowland calcareous grassland',
        'distinctiveness': 'high',
        'condition': 'poor',
        'strategic_significance': 'low',
        'temportal_data': {
            'good': 20,
            'fairly-good': 15,
            'moderate': 10,
            'fairly-poor': 8,
            'poor': 5,
        },
        'temporal_enhanced': {
            'lower-distinctiveness-habitat-poor': 10,
            'lower-distinctiveness-habitat-fairly-poor': 15,
            'lower-distinctiveness-habitat-moderate': 20,
            'lower-distinctiveness-habitat-fairly-good': 25,
            'lower-distinctiveness-habitat-good': 30,
            'poor-fairly-poor': 5,
            'poor-moderate': 10,
            'poor-fairly-good': 15,
            'poor-good': 20,
            'fairly-poor-moderate': 5,
            'fairly-poor-fairly-good': 10,
            'fairly-poor-good': 15,
            'moderate-fairly-good': 5,
            'moderate-good': 10,
            'fairly-good-good': 5,
        },
        'technical_difficulty_creation': 'high',
        'technical_difficulty_enhancement': 'high',
        'theme': parseTheme('lines45', 'lime', 'yellow'),
    }, {
        'value': 'lowland-dry-acid-grassland',
        'label': 'Lowland dry acid grassland',
        'distinctiveness': 'very-high',
        'condition': 'poor',
        'strategic_significance': 'low',
        'temportal_data': {
            'good': '30+',
            'fairly-good': 25,
            'moderate': 20,
            'fairly-poor': 15,
            'poor': 10,
        },
        'temporal_enhanced': {
            'lower-distinctiveness-habitat-poor': 10,
            'lower-distinctiveness-habitat-fairly-poor': 15,
            'lower-distinctiveness-habitat-moderate': 20,
            'lower-distinctiveness-habitat-fairly-good': 25,
            'lower-distinctiveness-habitat-good': '30+',
            'poor-fairly-poor': 5,
            'poor-moderate': 15,
            'poor-fairly-good': 20,
            'poor-good': '30+',
            'fairly-poor-moderate': 8,
            'fairly-poor-fairly-good': 15,
            'fairly-poor-good': 25,
            'moderate-fairly-good': 10,
            'moderate-good': 20,
            'fairly-good-good': 10,
        },
        'technical_difficulty_creation': 'high',
        'technical_difficulty_enhancement': 'high',
        'theme': parseTheme('lines45', 'lime', 'white'),
    }, {
        'value': 'lowland-meadows',
        'label': 'Lowland meadows',
        'distinctiveness': 'very-high',
        'condition': 'poor',
        'strategic_significance': 'low',
        'temportal_data': {
            'good': 15,
            'fairly-good': 12,
            'moderate': 10,
            'fairly-poor': 8,
            'poor': 5,
        },
        'temporal_enhanced': {
            'lower-distinctiveness-habitat-poor': 5,
            'lower-distinctiveness-habitat-fairly-poor': 8,
            'lower-distinctiveness-habitat-moderate': 10,
            'lower-distinctiveness-habitat-fairly-good': 12,
            'lower-distinctiveness-habitat-good': 15,
            'poor-fairly-poor': 4,
            'poor-moderate': 8,
            'poor-fairly-good': 11,
            'poor-good': 15,
            'fairly-poor-moderate': 4,
            'fairly-poor-fairly-good': 8,
            'fairly-poor-good': 11,
            'moderate-fairly-good': 4,
            'moderate-good': 8,
            'fairly-good-good': 4,
        },
        'technical_difficulty_creation': 'high',
        'technical_difficulty_enhancement': 'medium',
        'theme': parseTheme('lines45', 'lime', 'whiteLime'),
    }, {
        'value': 'modified-grassland',
        'label': 'Modified grassland',
        'distinctiveness': 'low',
        'condition': 'poor',
        'strategic_significance': 'low',
        'temportal_data': {
            'good': 7,
            'fairly-good': 5,
            'moderate': 4,
            'fairly-poor': 2,
            'poor': 1,
            'condition-assessment-n-a': 1,
        },
        'temporal_enhanced': {
            'lower-distinctiveness-habitat-condition-assessment-n-a': 1,
            'lower-distinctiveness-habitat-poor': 1,
            'lower-distinctiveness-habitat-fairly-poor': 5,
            'lower-distinctiveness-habitat-moderate': 10,
            'lower-distinctiveness-habitat-fairly-good': 12,
            'lower-distinctiveness-habitat-good': 15,
            'poor-fairly-poor': 5,
            'poor-moderate': 10,
            'poor-fairly-good': 12,
            'poor-good': 15,
            'fairly-poor-moderate': 8,
            'fairly-poor-fairly-good': 10,
            'fairly-poor-good': 12,
            'moderate-fairly-good': 8,
            'moderate-good': 10,
            'fairly-good-good': 8,
        },
        'technical_difficulty_creation': 'low',
        'technical_difficulty_enhancement': 'low',
        'theme': parseTheme('solid', 'lime'),
    }, {
        'value': 'other-lowland-acid-grassland',
        'label': 'Other lowland acid grassland',
        'distinctiveness': 'medium',
        'condition': 'poor',
        'strategic_significance': 'low',
        'temportal_data': {
            'good': 15,
            'fairly-good': 12,
            'moderate': 10,
            'fairly-poor': 5,
            'poor': 1,
        },
        'temporal_enhanced': {
            'lower-distinctiveness-habitat-poor': 1,
            'lower-distinctiveness-habitat-fairly-poor': 5,
            'lower-distinctiveness-habitat-moderate': 10,
            'lower-distinctiveness-habitat-fairly-good': 12,
            'lower-distinctiveness-habitat-good': 15,
            'poor-fairly-poor': 5,
            'poor-moderate': 10,
            'poor-fairly-good': 12,
            'poor-good': 15,
            'fairly-poor-moderate': 8,
            'fairly-poor-fairly-good': 10,
            'fairly-poor-good': 12,
            'moderate-fairly-good': 8,
            'moderate-good': 10,
            'fairly-good-good': 8,
        },
        'technical_difficulty_creation': 'low',
        'technical_difficulty_enhancement': 'low',
        'theme': parseTheme('dimonds', 'white', 'green'),
    }, {
        'value': 'other-neutral-grassland',
        'label': 'Other neutral grassland',
        'distinctiveness': 'medium',
        'condition': 'poor',
        'strategic_significance': 'low',
        'temportal_data': {
            'good': 10,
            'fairly-good': 7,
            'moderate': 5,
            'fairly-poor': 3,
            'poor': 2,
        },
        'temporal_enhanced': {
            'lower-distinctiveness-habitat-poor': 1,
            'lower-distinctiveness-habitat-fairly-poor': 5,
            'lower-distinctiveness-habitat-moderate': 10,
            'lower-distinctiveness-habitat-fairly-good': 12,
            'lower-distinctiveness-habitat-good': 15,
            'poor-fairly-poor': 5,
            'poor-moderate': 10,
            'poor-fairly-good': 12,
            'poor-good': 15,
            'fairly-poor-moderate': 8,
            'fairly-poor-fairly-good': 10,
            'fairly-poor-good': 12,
            'moderate-fairly-good': 8,
            'moderate-good': 10,
            'fairly-good-good': 8,
        },
        'technical_difficulty_creation': 'low',
        'technical_difficulty_enhancement': 'low',
        'theme': parseTheme('lines', 'whiteGreen', 'green'),
    }, {
        'value': 'tall-herb-communities',
        'label': 'Tall herb communities',
        'distinctiveness': 'high',
        'condition': 'poor',
        'strategic_significance': 'low',
        'temportal_data': {
            'good': 30,
            'fairly-good': 25,
            'moderate': 20,
            'fairly-poor': 15,
            'poor': 10,
        },
        'temporal_enhanced': {
            'lower-distinctiveness-habitat-poor': 10,
            'lower-distinctiveness-habitat-fairly-poor': 15,
            'lower-distinctiveness-habitat-moderate': 20,
            'lower-distinctiveness-habitat-fairly-good': 25,
            'lower-distinctiveness-habitat-good': 30,
            'poor-fairly-poor': 10,
            'poor-moderate': 20,
            'poor-fairly-good': 25,
            'poor-good': 30,
            'fairly-poor-moderate': 10,
            'fairly-poor-fairly-good': 10,
            'fairly-poor-good': 15,
            'moderate-fairly-good': 5,
            'moderate-good': 10,
            'fairly-good-good': 5,
        },
        'technical_difficulty_creation': 'high',
        'technical_difficulty_enhancement': 'high',
        'theme': parseTheme('solid', 'grayDark'),
    }, {
        'value': 'upland-acid-grassland',
        'label': 'Upland acid grassland',
        'distinctiveness': 'medium',
        'condition': 'poor',
        'strategic_significance': 'low',
        'temportal_data': {
            'good': 15,
            'fairly-good': 12,
            'moderate': 10,
            'fairly-poor': 5,
            'poor': 1,
        },
        'temporal_enhanced': {
            'lower-distinctiveness-habitat-poor': 1,
            'lower-distinctiveness-habitat-fairly-poor': 5,
            'lower-distinctiveness-habitat-moderate': 10,
            'lower-distinctiveness-habitat-fairly-good': 12,
            'lower-distinctiveness-habitat-good': 15,
            'poor-fairly-poor': 5,
            'poor-moderate': 10,
            'poor-fairly-good': 12,
            'poor-good': 15,
            'fairly-poor-moderate': 8,
            'fairly-poor-fairly-good': 10,
            'fairly-poor-good': 12,
            'moderate-fairly-good': 8,
            'moderate-good': 10,
            'fairly-good-good': 8,
        },
        'technical_difficulty_creation': 'low',
        'technical_difficulty_enhancement': 'low',
        'theme': parseTheme('linesVertical', 'lime', 'white'),
    }, {
        'value': 'upland-calcareous-grassland',
        'label': 'Upland calcareous grassland',
        'distinctiveness': 'high',
        'condition': 'poor',
        'strategic_significance': 'low',
        'temportal_data': {
            'good': 25,
            'fairly-good': 20,
            'moderate': 15,
            'fairly-poor': 12,
            'poor': 10,
        },
        'temporal_enhanced': {
            'lower-distinctiveness-habitat-poor': 10,
            'lower-distinctiveness-habitat-fairly-poor': 12,
            'lower-distinctiveness-habitat-moderate': 15,
            'lower-distinctiveness-habitat-fairly-good': 20,
            'lower-distinctiveness-habitat-good': 25,
            'poor-fairly-poor': 10,
            'poor-moderate': 15,
            'poor-fairly-good': 18,
            'poor-good': 20,
            'fairly-poor-moderate': 10,
            'fairly-poor-fairly-good': 15,
            'fairly-poor-good': 18,
            'moderate-fairly-good': 10,
            'moderate-good': 10,
            'fairly-good-good': 10,
        },
        'technical_difficulty_creation': 'high',
        'technical_difficulty_enhancement': 'high',
        'theme': parseTheme('linesVertical', 'lime', 'white'),
    }, {
        'value': 'upland-hay-meadows',
        'label': 'Upland hay meadows',
        'distinctiveness': 'very-high',
        'condition': 'poor',
        'strategic_significance': 'low',
        'temportal_data': {
            'good': 20,
            'fairly-good': 18,
            'moderate': 15,
            'fairly-poor': 12,
            'poor': 10,
        },
        'temporal_enhanced': {
            'lower-distinctiveness-habitat-poor': 10,
            'lower-distinctiveness-habitat-fairly-poor': 12,
            'lower-distinctiveness-habitat-moderate': 15,
            'lower-distinctiveness-habitat-fairly-good': 18,
            'lower-distinctiveness-habitat-good': 20,
            'poor-fairly-poor': 10,
            'poor-moderate': 15,
            'poor-fairly-good': 18,
            'poor-good': 20,
            'fairly-poor-moderate': 10,
            'fairly-poor-fairly-good': 15,
            'fairly-poor-good': 18,
            'moderate-fairly-good': 10,
            'moderate-good': 15,
            'fairly-good-good': 10,
        },
        'technical_difficulty_creation': 'high',
        'technical_difficulty_enhancement': 'medium',
        'theme': parseTheme('linesVertical', 'whiteLime', 'lime'),
    }],
    'heathland-and-shrub': [{
        'value': 'blackthorn-scrub',
        'label': 'Blackthorn scrub',
        'distinctiveness': 'medium',
        'condition': 'poor',
        'strategic_significance': 'low',
        'temportal_data': {
            'good': 10,
            'fairly-good': 7,
            'moderate': 5,
            'fairly-poor': 3,
            'poor': 1,
        },
        'temporal_enhanced': {
            'lower-distinctiveness-habitat-poor': 1,
            'lower-distinctiveness-habitat-fairly-poor': 3,
            'lower-distinctiveness-habitat-moderate': 5,
            'lower-distinctiveness-habitat-fairly-good': 7,
            'lower-distinctiveness-habitat-good': 10,
            'condition-assessment-n-a- fairly-poor': 1,
            'condition-assessment-n-a-moderate': 5,
            'condition-assessment-n-a-fairly-good': 7,
            'condition-assessment-n-a-good': 10,
            'poor-fairly-poor': 1,
            'poor-moderate': 5,
            'poor-fairly-good': 7,
            'poor-good': 10,
            'fairly-poor-moderate': 3,
            'fairly-poor-fairly-good': 5,
            'fairly-poor-good': 3,
            'moderate-fairly-good': 2,
            'moderate-good': 3,
            'fairly-good-good': 2,
        },
        'technical_difficulty_creation': 'low',
        'technical_difficulty_enhancement': 'low',
        'theme': parseTheme('lines-45', 'purple', 'red'),
    }, {
        'value': 'bramble-scrub',
        'label': 'Bramble scrub',
        'distinctiveness': 'medium',
        'condition': 'poor',
        'strategic_significance': 'low',
        'temportal_data': {
            'condition-assessment-n-a': 1,
        },
        'temporal_enhanced': {
            'lower-distinctiveness-habitat-poor': 1,
        },
        'technical_difficulty_creation': 'low',
        'technical_difficulty_enhancement': 'low',
        'theme': parseTheme('lines-45', 'purple', 'red'),
    }, {
        'value': 'gorse-scrub',
        'label': 'Gorse scrub',
        'distinctiveness': 'medium',
        'condition': 'poor',
        'strategic_significance': 'low',
        'temportal_data': {
            'good': 10,
            'fairly-good': 7,
            'moderate': 5,
            'fairly-poor': 3,
            'poor': 1,
        },
        'temporal_enhanced': {
            'lower-distinctiveness-habitat-poor': 1,
            'lower-distinctiveness-habitat-fairly-poor': 3,
            'lower-distinctiveness-habitat-moderate': 5,
            'lower-distinctiveness-habitat-fairly-good': 7,
            'lower-distinctiveness-habitat-good': 10,
            'condition-assessment-n-a- fairly-poor': 1,
            'condition-assessment-n-a-moderate': 5,
            'condition-assessment-n-a-fairly-good': 7,
            'condition-assessment-n-a-good': 10,
            'poor-fairly-poor': 1,
            'poor-moderate': 5,
            'poor-fairly-good': 7,
            'poor-good': 10,
            'fairly-poor-moderate': 3,
            'fairly-poor-fairly-good': 5,
            'fairly-poor-good': 7,
            'moderate-fairly-good': 2,
            'moderate-good': 3,
            'fairly-good-good': 2,
        },
        'technical_difficulty_creation': 'low',
        'technical_difficulty_enhancement': 'low',
        'theme': parseTheme('lines-45', 'purple', 'red'),
    }, {
        'value': 'hawthorn-scrub',
        'label': 'Hawthorn scrub',
        'distinctiveness': 'medium',
        'condition': 'poor',
        'strategic_significance': 'low',
        'temportal_data': {
            'good': 10,
            'fairly-good': 7,
            'moderate': 5,
            'fairly-poor': 3,
            'poor': 1,
        },
        'temporal_enhanced': {
            'lower-distinctiveness-habitat-poor': 1,
            'lower-distinctiveness-habitat-fairly-poor': 3,
            'lower-distinctiveness-habitat-moderate': 5,
            'lower-distinctiveness-habitat-fairly-good': 7,
            'lower-distinctiveness-habitat-good': 10,
            'condition-assessment-n-a- fairly-poor': 1,
            'condition-assessment-n-a-moderate': 5,
            'condition-assessment-n-a-fairly-good': 7,
            'condition-assessment-n-a-good': 10,
            'poor-fairly-poor': 1,
            'poor-moderate': 5,
            'poor-fairly-good': 7,
            'poor-good': 10,
            'fairly-poor-moderate': 3,
            'fairly-poor-fairly-good': 5,
            'fairly-poor-good': 7,
            'moderate-fairly-good': 2,
            'moderate-good': 3,
            'fairly-good-good': 2,
        },
        'technical_difficulty_creation': 'low',
        'technical_difficulty_enhancement': 'low',
        'theme': parseTheme('lines-45', 'purple', 'red'),
    }, {
        'value': 'hazel-scrub',
        'label': 'Hazel scrub',
        'distinctiveness': 'medium',
        'condition': 'poor',
        'strategic_significance': 'low',
        'temportal_data': {
            'good': 15,
            'fairly-good': 12,
            'moderate': 10,
            'fairly-poor': 7,
            'poor': 5,
        },
        'temporal_enhanced': {
            'lower-distinctiveness-habitat-poor': 5,
            'lower-distinctiveness-habitat-fairly-poor': 7,
            'lower-distinctiveness-habitat-moderate': 10,
            'lower-distinctiveness-habitat-fairly-good': 12,
            'lower-distinctiveness-habitat-good': 15,
            'condition-assessment-n-a- fairly-poor': 5,
            'condition-assessment-n-a-moderate': 7,
            'condition-assessment-n-a-fairly-good': 12,
            'condition-assessment-n-a-good': 15,
            'poor-fairly-poor': 5,
            'poor-moderate': 7,
            'poor-fairly-good': 12,
            'poor-good': 15,
            'fairly-poor-moderate': 5,
            'fairly-poor-fairly-good': 8,
            'fairly-poor-good': 12,
            'moderate-fairly-good': 5,
            'moderate-good': 7,
            'fairly-good-good': 5,
        },
        'technical_difficulty_creation': 'low',
        'technical_difficulty_enhancement': 'low',
        'theme': parseTheme('lines-45', 'purple', 'red'),
    }, {
        'value': 'lowland-heathland',
        'label': 'Lowland Heathland',
        'distinctiveness': 'high',
        'condition': 'poor',
        'strategic_significance': 'low',
        'temportal_data': {
            'good': '30+',
            'fairly-good': 25,
            'moderate': 20,
            'fairly-poor': 15,
            'poor': 10,
        },
        'temporal_enhanced': {
            'lower-distinctiveness-habitat-poor': 10,
            'lower-distinctiveness-habitat-fairly-poor': 15,
            'lower-distinctiveness-habitat-moderate': 20,
            'lower-distinctiveness-habitat-fairly-good': 25,
            'lower-distinctiveness-habitat-good': '30+',
            'condition-assessment-n-a- fairly-poor': 5,
            'condition-assessment-n-a-moderate': 10,
            'condition-assessment-n-a-fairly-good': 15,
            'condition-assessment-n-a-good': 25,
            'poor-fairly-poor': 5,
            'poor-moderate': 10,
            'poor-fairly-good': 15,
            'poor-good': 25,
            'fairly-poor-moderate': 5,
            'fairly-poor-fairly-good': 10,
            'fairly-poor-good': 20,
            'moderate-fairly-good': 5,
            'moderate-good': 15,
            'fairly-good-good': 10,
        },
        'technical_difficulty_creation': 'high',
        'technical_difficulty_enhancement': 'medium',
        'theme': parseTheme('lines45', 'purple', 'white'),
    }, {
        'value': 'mixed-scrub',
        'label': 'Mixed scrub',
        'distinctiveness': 'medium',
        'condition': 'poor',
        'strategic_significance': 'low',
        'temportal_data': {
            'good': 10,
            'fairly-good': 7,
            'moderate': 5,
            'fairly-poor': 3,
            'poor': 1,
        },
        'temporal_enhanced': {
            'lower-distinctiveness-habitat-poor': 1,
            'lower-distinctiveness-habitat-fairly-poor': 3,
            'lower-distinctiveness-habitat-fairly-good': 7,
            'lower-distinctiveness-habitat-moderate': 5,
            'lower-distinctiveness-habitat-good': 10,
            'condition-assessment-n-a- fairly-poor': 1,
            'condition-assessment-n-a-moderate': 5,
            'condition-assessment-n-a-fairly-good': 7,
            'condition-assessment-n-a-good': 10,
            'poor-fairly-poor': 1,
            'poor-moderate': 5,
            'poor-fairly-good': 7,
            'poor-good': 10,
            'fairly-poor-moderate': 3,
            'fairly-poor-fairly-good': 5,
            'fairly-poor-good': 7,
            'moderate-fairly-good': 2,
            'moderate-good': 3,
            'fairly-good-good': 2,
        },
        'technical_difficulty_creation': 'low',
        'technical_difficulty_enhancement': 'low',
        'theme': parseTheme('lines-45', 'purple', 'red'),
    }, {
        'value': 'mountain-heaths-and-willow-scrub',
        'label': 'Mountain heaths and willow scrub',
        'distinctiveness': 'very-high',
        'condition': 'poor',
        'strategic_significance': 'low',
        'temportal_data': {
            'good': '30+',
            'fairly-good': '30+',
            'moderate': 25,
            'fairly-poor': 23,
            'poor': 15,
        },
        'temporal_enhanced': {
            'lower-distinctiveness-habitat-poor': 15,
            'lower-distinctiveness-habitat-fairly-poor': 23,
            'lower-distinctiveness-habitat-moderate': 25,
            'lower-distinctiveness-habitat-fairly-good': '30+',
            'lower-distinctiveness-habitat-good': '30+',
            'condition-assessment-n-a- fairly-poor': 20,
            'condition-assessment-n-a-moderate': '30+',
            'condition-assessment-n-a-fairly-good': '30+',
            'condition-assessment-n-a-good': '30+',
            'poor-fairly-poor': 20,
            'poor-moderate': '30+',
            'poor-fairly-good': '30+',
            'poor-good': '30+',
            'fairly-poor-moderate': 20,
            'fairly-poor-fairly-good': '30+',
            'fairly-poor-good': '30+',
            'moderate-fairly-good': 20,
            'moderate-good': '30+',
            'fairly-good-good': 20,
        },
        'technical_difficulty_creation': 'high',
        'technical_difficulty_enhancement': 'high',
        'theme': parseTheme('solid', 'purple'),
    }, {
        'value': 'rhododendron-scrub',
        'label': 'Rhododendron scrub',
        'distinctiveness': 'low',
        'condition': 'poor',
        'strategic_significance': 'low',
        'temportal_data': {
            'condition-assessment-n-a': 1,
        },
        'temporal_enhanced': {
            'lower-distinctiveness-habitat-poor': 1,
        },
        'technical_difficulty_creation': 'low',
        'technical_difficulty_enhancement': 'low',
        'theme': parseTheme('lines-45', 'purple', 'red'),
    }, {
        'value': 'sea-buckthorn-scrub-annex-1',
        'label': 'Sea buckthorn scrub (Annex 1)',
        'distinctiveness': 'high',
        'condition': 'poor',
        'strategic_significance': 'low',
        'temportal_data': {
            'good': 10,
            'fairly-good': 7,
            'moderate': 5,
            'fairly-poor': 3,
            'poor': 1,
        },
        'temporal_enhanced': {
            'lower-distinctiveness-habitat-poor': 5,
            'lower-distinctiveness-habitat-fairly-poor': 7,
            'lower-distinctiveness-habitat-moderate': 10,
            'lower-distinctiveness-habitat-fairly-good': 12,
            'lower-distinctiveness-habitat-good': 15,
            'condition-assessment-n-a- fairly-poor': 5,
            'condition-assessment-n-a-moderate': 7,
            'condition-assessment-n-a-fairly-good': 10,
            'condition-assessment-n-a-good': 12,
            'poor-fairly-poor': 5,
            'poor-moderate': 7,
            'poor-fairly-good': 10,
            'poor-good': 12,
            'fairly-poor-moderate': 5,
            'fairly-poor-fairly-good': 7,
            'fairly-poor-good': 10,
            'moderate-fairly-good': 5,
            'moderate-good': 7,
            'fairly-good-good': 5,
        },
        'technical_difficulty_creation': 'medium',
        'technical_difficulty_enhancement': 'low',
        'theme': parseTheme('lines-45', 'purple', 'red'),
    }, {
        'value': 'sea-buckthorn-scrub-other',
        'label': 'Sea buckthorn scrub (other)',
        'distinctiveness': 'low',
        'condition': 'poor',
        'strategic_significance': 'low',
        'temportal_data': {
            'condition-assessment-n-a': 1,
        },
        'temporal_enhanced': {},
        'technical_difficulty_creation': 'low',
        'technical_difficulty_enhancement': 'low',
        'theme': parseTheme('lines-45', 'purple', 'red'),
    }, {
        'value': 'upland-heathland',
        'label': 'Upland Heathland',
        'distinctiveness': 'high',
        'condition': 'poor',
        'strategic_significance': 'low',
        'temportal_data': {
            'good': 30,
            'fairly-good': 25,
            'moderate': 20,
            'fairly-poor': 15,
            'poor': 10,
        },
        'temporal_enhanced': {
            'lower-distinctiveness-habitat-poor': 10,
            'lower-distinctiveness-habitat-fairly-poor': 15,
            'lower-distinctiveness-habitat-moderate': 20,
            'lower-distinctiveness-habitat-fairly-good': 25,
            'lower-distinctiveness-habitat-good': 30,
            'poor-fairly-poor': 10,
            'poor-moderate': 20,
            'poor-fairly-good': 30,
            'poor-good': '30+',
            'fairly-poor-moderate': 10,
            'fairly-poor-fairly-good': 20,
            'fairly-poor-good': 30,
            'moderate-fairly-good': 10,
            'moderate-good': 20,
            'fairly-good-good': 10,
        },
        'technical_difficulty_creation': 'medium',
        'technical_difficulty_enhancement': 'medium',
        'theme': parseTheme('linesVertical', 'purple', 'white'),
    }],
    'lakes': [{
        'value': 'aquifer-fed-naturally-fluctuating-water-bodies',
        'label': 'Aquifer fed naturally fluctuating water bodies',
        'distinctiveness': 'very-high',
        'condition': 'poor',
        'strategic_significance': 'low',
        'temportal_data': {
            'good': 30,
            'fairly-good': 20,
            'moderate': 15,
            'fairly-poor': 10,
            'poor': 1,
        },
        'temporal_enhanced': {
            'lower-distinctiveness-habitat-poor': 1,
            'lower-distinctiveness-habitat-fairly-poor': 10,
            'lower-distinctiveness-habitat-moderate': 15,
            'lower-distinctiveness-habitat-fairly-good': 20,
            'lower-distinctiveness-habitat-good': 30,
            'poor-fairly-poor': 5,
            'poor-moderate': 10,
            'poor-fairly-good': 15,
            'poor-good': 30,
            'fairly-poor-moderate': 5,
            'fairly-poor-fairly-good': 15,
            'fairly-poor-good': 25,
            'moderate-fairly-good': 5,
            'moderate-good': 20,
            'fairly-good-good': 5,
        },
        'technical_difficulty_creation': 'very-high',
        'technical_difficulty_enhancement': 'high',
        'theme': parseTheme('solid', 'blueBaby'),
    }, {
        'value': 'ornamental-lake-or-pond',
        'label': 'Ornamental lake or pond',
        'distinctiveness': 'low',
        'condition': 'poor',
        'strategic_significance': 'low',
        'temportal_data': {
            'good': 5,
            'fairly-good': 4,
            'moderate': 3,
            'fairly-poor': 2,
            'poor': 1,
        },
        'temporal_enhanced': {
            'lower-distinctiveness-habitat-poor': 1,
            'lower-distinctiveness-habitat-fairly-poor': 2,
            'lower-distinctiveness-habitat-moderate': 3,
            'lower-distinctiveness-habitat-fairly-good': 4,
            'lower-distinctiveness-habitat-good': 5,
            'poor-fairly-poor': 2,
            'poor-moderate': 4,
            'poor-fairly-good': 6,
            'poor-good': 8,
            'fairly-poor-moderate': 2,
            'fairly-poor-fairly-good': 4,
            'fairly-poor-good': 6,
            'moderate-fairly-good': 2,
            'moderate-good': 4,
            'fairly-good-good': 2,
        },
        'technical_difficulty_creation': 'low',
        'technical_difficulty_enhancement': 'high',
        'theme': parseTheme('lines-45', 'white', 'blueBaby'),
    }, {
        'value': 'high-alkalinity-lakes',
        'label': 'High alkalinity lakes',
        'distinctiveness': 'high',
        'condition': 'poor',
        'strategic_significance': 'low',
        'temportal_data': {
            'good': 30,
            'fairly-good': 20,
            'moderate': 10,
            'fairly-poor': 7,
            'poor': 5,
        },
        'temporal_enhanced': {
            'lower-distinctiveness-habitat-poor': 2,
            'lower-distinctiveness-habitat-fairly-poor': 3,
            'lower-distinctiveness-habitat-moderate': 5,
            'lower-distinctiveness-habitat-fairly-good': 7,
            'lower-distinctiveness-habitat-good': 10,
            'poor-fairly-poor': 5,
            'poor-moderate': 10,
            'poor-fairly-good': 15,
            'poor-good': 30,
            'fairly-poor-moderate': 5,
            'fairly-poor-fairly-good': 15,
            'fairly-poor-good': 25,
            'moderate-fairly-good': 10,
            'moderate-good': 20,
            'fairly-good-good': 10,
        },
        'technical_difficulty_creation': 'high',
        'technical_difficulty_enhancement': 'high',
        'theme': parseTheme('solid', 'blueBaby'),
    }, {
        'value': 'low-alkalinity-lakes',
        'label': 'Low alkalinity lakes',
        'distinctiveness': 'high',
        'condition': 'poor',
        'strategic_significance': 'low',
        'temportal_data': {
            'good': 30,
            'fairly-good': 20,
            'moderate': 10,
            'fairly-poor': 7,
            'poor': 5,
        },
        'temporal_enhanced': {
            'lower-distinctiveness-habitat-poor': 5,
            'lower-distinctiveness-habitat-fairly-poor': 10,
            'lower-distinctiveness-habitat-moderate': 15,
            'lower-distinctiveness-habitat-fairly-good': 20,
            'lower-distinctiveness-habitat-good': 30,
            'poor-fairly-poor': 5,
            'poor-moderate': 10,
            'poor-fairly-good': 15,
            'poor-good': 20,
            'fairly-poor-moderate': 5,
            'fairly-poor-fairly-good': 10,
            'fairly-poor-good': 15,
            'moderate-fairly-good': 5,
            'moderate-good': 10,
            'fairly-good-good': 5,
        },
        'technical_difficulty_creation': 'high',
        'technical_difficulty_enhancement': 'medium',
        'theme': parseTheme('solid', 'blueBaby'),
    }, {
        'value': 'marl-lakes',
        'label': 'Marl Lakes',
        'distinctiveness': 'high',
        'condition': 'poor',
        'strategic_significance': 'low',
        'temportal_data': {
            'good': 30,
            'fairly-good': 20,
            'moderate': 10,
            'fairly-poor': 7,
            'poor': 5,
        },
        'temporal_enhanced': {
            'lower-distinctiveness-habitat-poor': 5,
            'lower-distinctiveness-habitat-fairly-poor': 7,
            'lower-distinctiveness-habitat-moderate': 10,
            'lower-distinctiveness-habitat-fairly-good': 15,
            'lower-distinctiveness-habitat-good': 20,
            'poor-fairly-poor': 5,
            'poor-moderate': 10,
            'poor-fairly-good': 15,
            'poor-good': 30,
            'fairly-poor-moderate': 5,
            'fairly-poor-fairly-good': 15,
            'fairly-poor-good': 25,
            'moderate-fairly-good': 10,
            'moderate-good': 20,
            'fairly-good-good': 10,
        },
        'technical_difficulty_creation': 'high',
        'technical_difficulty_enhancement': 'high',
        'theme': parseTheme('solid', 'blueBaby'),
    }, {
        'value': 'moderate-alkalinity-lakes',
        'label': 'Moderate alkalinity lakes',
        'distinctiveness': 'high',
        'condition': 'poor',
        'strategic_significance': 'low',
        'temportal_data': {
            'good': 30,
            'fairly-good': 20,
            'moderate': 10,
            'fairly-poor': 7,
            'poor': 5,
        },
        'temporal_enhanced': {
            'lower-distinctiveness-habitat-poor': 5,
            'lower-distinctiveness-habitat-fairly-poor': 7,
            'lower-distinctiveness-habitat-moderate': 10,
            'lower-distinctiveness-habitat-fairly-good': 15,
            'lower-distinctiveness-habitat-good': 20,
            'poor-fairly-poor': 5,
            'poor-moderate': 10,
            'poor-fairly-good': 15,
            'poor-good': 30,
            'fairly-poor-moderate': 5,
            'fairly-poor-fairly-good': 15,
            'fairly-poor-good': 25,
            'moderate-fairly-good': 10,
            'moderate-good': 20,
            'fairly-good-good': 10,
        },
        'technical_difficulty_creation': 'high',
        'technical_difficulty_enhancement': 'high',
        'theme': parseTheme('solid', 'blueBaby'),
    }, {
        'value': 'peat-lakes',
        'label': 'Peat Lakes',
        'distinctiveness': 'high',
        'condition': 'poor',
        'strategic_significance': 'low',
        'temportal_data': {
            'good': 30,
            'fairly-good': 20,
            'moderate': 10,
            'fairly-poor': 7,
            'poor': 5,
        },
        'temporal_enhanced': {
            'lower-distinctiveness-habitat-poor': 5,
            'lower-distinctiveness-habitat-fairly-poor': 10,
            'lower-distinctiveness-habitat-moderate': 15,
            'lower-distinctiveness-habitat-fairly-good': 20,
            'lower-distinctiveness-habitat-good': 30,
            'poor-fairly-poor': 5,
            'poor-moderate': 10,
            'poor-fairly-good': 15,
            'poor-good': 30,
            'fairly-poor-moderate': 5,
            'fairly-poor-fairly-good': 15,
            'fairly-poor-good': 25,
            'moderate-fairly-good': 10,
            'moderate-good': 20,
            'fairly-good-good': 10,
        },
        'technical_difficulty_creation': 'high',
        'technical_difficulty_enhancement': 'high',
        'theme': parseTheme('solid', 'blueBaby'),
    }, {
        'value': 'ponds-priority-habitat',
        'label': 'Ponds (Priority Habitat)',
        'distinctiveness': 'high',
        'condition': 'poor',
        'strategic_significance': 'low',
        'temportal_data': {
            'good': 5,
            'fairly-good': 4,
            'moderate': 3,
            'fairly-poor': 2,
            'poor': 1,
        },
        'temporal_enhanced': {
            'lower-distinctiveness-habitat-poor': 2,
            'lower-distinctiveness-habitat-fairly-poor': 3,
            'lower-distinctiveness-habitat-moderate': 5,
            'lower-distinctiveness-habitat-fairly-good': 7,
            'lower-distinctiveness-habitat-good': 10,
            'poor-fairly-poor': 2,
            'poor-moderate': 4,
            'poor-fairly-good': 6,
            'poor-good': 8,
            'fairly-poor-moderate': 2,
            'fairly-poor-fairly-good': 4,
            'fairly-poor-good': 6,
            'moderate-fairly-good': 2,
            'moderate-good': 4,
            'fairly-good-good': 2,
        },
        'technical_difficulty_creation': 'medium',
        'technical_difficulty_enhancement': 'medium',
        'theme': parseTheme('solid', 'blueBaby'),
    }, {
        'value': 'ponds-non-priority-habitat',
        'label': 'Ponds (Non-Priority Habitat)',
        'distinctiveness': 'high',
        'condition': 'poor',
        'strategic_significance': 'low',
        'temportal_data': {
            'good': 5,
            'fairly-good': 4,
            'moderate': 3,
            'fairly-poor': 2,
            'poor': 1,
        },
        'technical_difficulty_creation': 'low',
        'technical_difficulty_enhancement': 'medium',
        'temporal_enhanced': {
            'lower-distinctiveness-habitat-poor': 1,
            'lower-distinctiveness-habitat-fairly-poor': 2,
            'lower-distinctiveness-habitat-moderate': 3,
            'lower-distinctiveness-habitat-fairly-good': 4,
            'lower-distinctiveness-habitat-good': 5,
            'poor-fairly-poor': 2,
            'poor-moderate': 4,
            'poor-fairly-good': 6,
            'poor-good': 8,
            'fairly-poor-moderate': 2,
            'fairly-poor-fairly-good': 4,
            'fairly-poor-good': 6,
            'moderate-fairly-good': 2,
            'moderate-good': 4,
            'fairly-good-good': 2,
        },
        'theme': parseTheme('solid', 'blueBaby'),
    }, {
        'value': 'reservoirs',
        'label': 'Reservoirs',
        'distinctiveness': 'medium',
        'condition': 'poor',
        'strategic_significance': 'low',
        'temportal_data': {
            'good': 10,
            'fairly-good': 7,
            'moderate': 5,
            'fairly-poor': 3,
            'poor': 1,
        },
        'temporal_enhanced': {
            'lower-distinctiveness-habitat-poor': 1,
            'lower-distinctiveness-habitat-fairly-poor': 3,
            'lower-distinctiveness-habitat-moderate': 5,
            'lower-distinctiveness-habitat-fairly-good': 7,
            'lower-distinctiveness-habitat-good': 10,
            'poor-fairly-poor': 5,
            'poor-moderate': 10,
            'poor-fairly-good': 15,
            'poor-good': 30,
            'fairly-poor-moderate': 5,
            'fairly-poor-fairly-good': 15,
            'fairly-poor-good': 25,
            'moderate-fairly-good': 10,
            'moderate-good': 20,
            'fairly-good-good': 10,
        },
        'technical_difficulty_creation': 'medium',
        'technical_difficulty_enhancement': 'medium',
        'theme': parseTheme('solid', 'blueBaby'),
    }, {
        'value': 'temporary-lakes-ponds-and-pools',
        'label': 'Temporary lakes, ponds and pools',
        'distinctiveness': 'high',
        'condition': 'poor',
        'strategic_significance': 'low',
        'temportal_data': {
            'good': 5,
            'fairly-good': 4,
            'moderate': 3,
            'fairly-poor': 2,
            'poor': 1,
        },
        'temporal_enhanced': {
            'lower-distinctiveness-habitat-poor': 2,
            'lower-distinctiveness-habitat-fairly-poor': 3,
            'lower-distinctiveness-habitat-moderate': 5,
            'lower-distinctiveness-habitat-fairly-good': 7,
            'lower-distinctiveness-habitat-good': 10,
            'poor-fairly-poor': 2,
            'poor-moderate': 4,
            'poor-fairly-good': 6,
            'poor-good': 8,
            'fairly-poor-moderate': 2,
            'fairly-poor-fairly-good': 4,
            'fairly-poor-good': 6,
            'moderate-fairly-good': 2,
            'moderate-good': 4,
            'fairly-good-good': 2,
        },
        'technical_difficulty_creation': 'medium',
        'technical_difficulty_enhancement': 'medium',
        'theme': parseTheme('solid', 'blueBaby'),
    }],
    'sparsely-vegetated-land': [{
        'value': 'calaminarian-grasslands',
        'label': 'Calaminarian grasslands',
        'distinctiveness': 'very-high',
        'condition': 'poor',
        'strategic_significance': 'low',
        'temportal_data': {
            'good': 10,
            'fairly-good': 7,
            'moderate': 5,
            'fairly-poor': 3,
            'poor': 2,
        },
        'temporal_enhanced': {
            'lower-distinctiveness-habitat-poor': 2,
            'lower-distinctiveness-habitat-fairly-poor': 3,
            'lower-distinctiveness-habitat-moderate': 5,
            'lower-distinctiveness-habitat-fairly-good': 7,
            'lower-distinctiveness-habitat-good': 10,
            'poor-fairly-poor': 1,
            'poor-moderate': 3,
            'poor-fairly-good': 5,
            'poor-good': 8,
            'fairly-poor-moderate': 1,
            'fairly-poor-fairly-good': 4,
            'fairly-poor-good': 7,
            'moderate-fairly-good': 2,
            'moderate-good': 5,
            'fairly-good-good': 3,
        },
        'technical_difficulty_creation': 'very-high',
        'technical_difficulty_enhancement': 'medium',
        'theme': parseTheme('solid', 'lime'),
    }, {
        'value': 'coastal-sand-dunes',
        'label': 'Coastal sand dunes',
        'distinctiveness': 'high',
        'condition': 'poor',
        'strategic_significance': 'low',
        'temportal_data': {
            'good': 20,
            'fairly-good': 15,
            'moderate': 10,
            'fairly-poor': 7,
            'poor': 5,
        },
        'temporal_enhanced': {
            'lower-distinctiveness-habitat-poor': 5,
            'lower-distinctiveness-habitat-fairly-poor': 7,
            'lower-distinctiveness-habitat-moderate': 10,
            'lower-distinctiveness-habitat-fairly-good': 15,
            'lower-distinctiveness-habitat-good': 20,
            'poor-fairly-poor': 5,
            'poor-moderate': 8,
            'poor-fairly-good': 15,
            'poor-good': 20,
            'fairly-poor-moderate': 5,
            'fairly-poor-fairly-good': 10,
            'fairly-poor-good': 18,
            'moderate-fairly-good': 7,
            'moderate-good': 12,
            'fairly-good-good': 8,
        },
        'technical_difficulty_creation': 'very-high',
        'technical_difficulty_enhancement': 'medium',
        'theme': parseTheme('lines45', 'grayDark', 'grayLight'),
    }, {
        'value': 'coastal-vegetated-shingle',
        'label': 'Coastal vegetated shingle',
        'distinctiveness': 'high',
        'condition': 'poor',
        'strategic_significance': 'low',
        'temportal_data': {
            'good': 20,
            'fairly-good': 15,
            'moderate': 10,
            'fairly-poor': 7,
            'poor': 5,
        },
        'temporal_enhanced': {
            'lower-distinctiveness-habitat-poor': 5,
            'lower-distinctiveness-habitat-fairly-poor': 7,
            'lower-distinctiveness-habitat-moderate': 10,
            'lower-distinctiveness-habitat-fairly-good': 15,
            'lower-distinctiveness-habitat-good': 20,
            'poor-fairly-poor': 5,
            'poor-moderate': 8,
            'poor-fairly-good': 15,
            'poor-good': 20,
            'fairly-poor-moderate': 5,
            'fairly-poor-fairly-good': 10,
            'fairly-poor-good': 18,
            'moderate-fairly-good': 7,
            'moderate-good': 12,
            'fairly-good-good': 8,
        },
        'technical_difficulty_creation': 'very-high',
        'technical_difficulty_enhancement': 'medium',
        'theme': parseTheme('linesVertical', 'grayDark', 'grayLight'),
    }, {
        'value': 'ruderal-ephemeral',
        'label': 'Ruderal/Ephemeral',
        'distinctiveness': 'low',
        'condition': 'poor',
        'strategic_significance': 'low',
        'temportal_data': {
            'good': 5,
            'fairly-good': 4,
            'moderate': 3,
            'fairly-poor': 2,
            'poor': 1,
        },
        'temporal_enhanced': {
            'poor-fairly-poor': 1,
            'poor-moderate': 2,
            'poor-fairly-good': 3,
            'poor-good': 5,
            'fairly-poor-moderate': 1,
            'fairly-poor-fairly-good': 2,
            'fairly-poor-good': 3,
            'moderate-fairly-good': 1,
            'moderate-good': 2,
            'fairly-good-good': 1,
        },
        'technical_difficulty_creation': 'low',
        'technical_difficulty_enhancement': 'medium',
        'theme': parseTheme('solid', 'grayDark'),
    }, {
        'value': 'inland-rock-outcrop-and-scree-habitats',
        'label': 'Inland rock outcrop and scree habitats',
        'distinctiveness': 'high',
        'condition': 'poor',
        'strategic_significance': 'low',
        'temportal_data': {
            'good': '30+',
            'fairly-good': 25,
            'moderate': 20,
            'fairly-poor': 15,
            'poor': 10,
        },
        'temporal_enhanced': {
            'lower-distinctiveness-habitat-poor': 10,
            'lower-distinctiveness-habitat-fairly-poor': 15,
            'lower-distinctiveness-habitat-moderate': 20,
            'lower-distinctiveness-habitat-fairly-good': 25,
            'lower-distinctiveness-habitat-good': '30+',
            'poor-fairly-poor': 10,
            'poor-moderate': 15,
            'poor-fairly-good': 25,
            'poor-good': '30+',
            'fairly-poor-moderate': 20,
            'fairly-poor-fairly-good': 25,
            'fairly-poor-good': 27,
            'moderate-fairly-good': 15,
            'moderate-good': 20,
            'fairly-good-good': 15,
        },
        'technical_difficulty_creation': 'high',
        'technical_difficulty_enhancement': 'low',
        'theme': parseTheme('lines45', 'grayDark', 'white'),
    }, {
        'value': 'limestone-pavement',
        'label': 'Limestone pavement',
        'distinctiveness': 'very-high',
        'condition': 'poor',
        'strategic_significance': 'low',
        'temportal_data': {
            'good': '30+',
            'fairly-good': '30+',
            'moderate': '30+',
            'fairly-poor': '30+',
            'poor': '30+',
        },
        'temporal_enhanced': {
            'lower-distinctiveness-habitat-poor': '30+',
            'lower-distinctiveness-habitat-fairly-poor': '30+',
            'lower-distinctiveness-habitat-moderate': '30+',
            'lower-distinctiveness-habitat-fairly-good': '30+',
            'lower-distinctiveness-habitat-good': '30+',
            'poor-fairly-poor': 5,
            'poor-moderate': 10,
            'poor-fairly-good': 15,
            'poor-good': 20,
            'fairly-poor-moderate': 5,
            'fairly-poor-fairly-good': 10,
            'fairly-poor-good': 15,
            'moderate-fairly-good': 5,
            'moderate-good': 10,
            'fairly-good-good': 5,
        },
        'technical_difficulty_creation': 'very-high',
        'technical_difficulty_enhancement': 'medium',
        'theme': parseTheme('solid', 'grayDark'),
    }, {
        'value': 'maritime-cliff-and-slopes',
        'label': 'Maritime cliff and slopes',
        'distinctiveness': 'high',
        'condition': 'poor',
        'strategic_significance': 'low',
        'temportal_data': {
            'good': 20,
            'fairly-good': 15,
            'moderate': 10,
            'fairly-poor': 7,
            'poor': 5,
        },
        'temporal_enhanced': {
            'lower-distinctiveness-habitat-poor': 10,
            'lower-distinctiveness-habitat-fairly-poor': 15,
            'lower-distinctiveness-habitat-moderate': 20,
            'lower-distinctiveness-habitat-fairly-good': 25,
            'lower-distinctiveness-habitat-good': '30+',
            'poor-fairly-poor': 5,
            'poor-moderate': 8,
            'poor-fairly-good': 15,
            'poor-good': 20,
            'fairly-poor-moderate': 5,
            'fairly-poor-fairly-good': 10,
            'fairly-poor-good': 18,
            'moderate-fairly-good': 7,
            'moderate-good': 12,
            'fairly-good-good': 8,
        },
        'technical_difficulty_creation': 'high',
        'technical_difficulty_enhancement': 'medium',
        'theme': parseTheme('lines45', 'grayDark', 'yellow'),
    }, {
        'value': 'other-inland-rock-and-scree',
        'label': 'Other inland rock and scree',
        'distinctiveness': 'medium',
        'condition': 'poor',
        'strategic_significance': 'low',
        'temportal_data': {
            'good': 20,
            'fairly-good': 15,
            'moderate': 10,
            'fairly-poor': 7,
            'poor': 5,
        },
        'temporal_enhanced': {
            'lower-distinctiveness-habitat-poor': 3,
            'lower-distinctiveness-habitat-fairly-poor': 5,
            'lower-distinctiveness-habitat-moderate': 10,
            'lower-distinctiveness-habitat-fairly-good': 15,
            'lower-distinctiveness-habitat-good': 20,
            'poor-fairly-poor': 5,
            'poor-moderate': 10,
            'poor-fairly-good': 15,
            'poor-good': 20,
            'fairly-poor-moderate': 5,
            'fairly-poor-fairly-good': 10,
            'fairly-poor-good': 15,
            'moderate-fairly-good': 5,
            'moderate-good': 10,
            'fairly-good-good': 5,
        },
        'technical_difficulty_creation': 'medium',
        'technical_difficulty_enhancement': 'medium',
        'theme': parseTheme('circles', 'white', 'grayDark'),
    }],
    'urban': [{
        'value': 'allotments',
        'label': 'Allotments',
        'distinctiveness': 'low',
        'condition': 'poor',
        'strategic_significance': 'low',
        'temportal_data': {
            'good': 1,
            'fairly-good': 1,
            'moderate': 1,
            'fairly-poor': 1,
            'poor': 1,
        },
        'temporal_enhanced': {
            'lower-distinctiveness-habitat-poor': 1,
            'lower-distinctiveness-habitat-fairly-poor': 1,
            'lower-distinctiveness-habitat-moderate': 1,
            'lower-distinctiveness-habitat-fairly-good': 1,
            'lower-distinctiveness-habitat-good': 1,
            'poor-fairly-poor': 1,
            'poor-moderate': 1,
            'poor-fairly-good': 1,
            'poor-good': 1,
            'fairly-poor-moderate': 1,
            'fairly-poor-fairly-good': 1,
            'fairly-poor-good': 1,
            'moderate-fairly-good': 1,
            'moderate-good': 1,
            'fairly-good-good': 1,
        },
        'technical_difficulty_creation': 'low',
        'technical_difficulty_enhancement': 'low',
        'theme': parseTheme('solid', 'red'),
    }, {
        'value': 'artificial-unvegetated-unsealed-surface',
        'label': 'Artificial unvegetated, unsealed surface',
        'distinctiveness': 'very-low',
        'condition': 'poor',
        'strategic_significance': 'low',
        'temportal_data': {
            'n-a-other': 0,
        },
        'temporal_enhanced': {},
        'technical_difficulty_creation': 'low',
        'technical_difficulty_enhancement': 'low',
        'theme': parseTheme('circles', 'red', 'white'),
    }, {
        'value': 'bioswale',
        'label': 'Bioswale',
        'distinctiveness': 'low',
        'condition': 'poor',
        'strategic_significance': 'low',
        'temportal_data': {
            'good': 3,
            'fairly-good': 2,
            'moderate': 1,
            'fairly-poor': 1,
            'poor': 1,
        },
        'temporal_enhanced': {
            'lower-distinctiveness-habitat-poor': 1,
            'lower-distinctiveness-habitat-fairly-poor': 1,
            'lower-distinctiveness-habitat-moderate': 1,
            'lower-distinctiveness-habitat-fairly-good': 2,
            'lower-distinctiveness-habitat-good': 3,
            'poor-fairly-poor': 1,
            'poor-moderate': 2,
            'poor-fairly-good': 2,
            'poor-good': 3,
            'fairly-poor-moderate': 1,
            'fairly-poor-fairly-good': 3,
            'fairly-poor-good': 3,
            'moderate-fairly-good': 2,
            'moderate-good': 2,
            'fairly-good-good': 2,
        },
        'technical_difficulty_creation': 'medium',
        'technical_difficulty_enhancement': 'low',
        'theme': parseTheme('solid', 'red'),
    }, {
        'value': 'other-green-roof',
        'label': 'Other green roof',
        'distinctiveness': 'medium',
        'condition': 'poor',
        'strategic_significance': 'low',
        'temportal_data': {
            'condition-assessment-n-a': 1,
        },
        'temporal_enhanced': {},
        'technical_difficulty_creation': 'low',
        'technical_difficulty_enhancement': 'low',
        'theme': parseTheme('solid', 'red'),
    }, {
        'value': 'built-linear-features',
        'label': 'Built linear features',
        'distinctiveness': 'very-low',
        'condition': 'poor',
        'strategic_significance': 'low',
        'temportal_data': {
            'n-a-other': 0,
        },
        'temporal_enhanced': {},
        'technical_difficulty_creation': 'low',
        'technical_difficulty_enhancement': 'low',
        'theme': parseTheme('solid', 'red'),
    }, {
        'value': 'cemeteries-and-churchyards',
        'label': 'Cemeteries and churchyards',
        'distinctiveness': 'medium',
        'condition': 'poor',
        'strategic_significance': 'low',
        'temportal_data': {
            'good': 20,
            'fairly-good': 17,
            'moderate': 15,
            'fairly-poor': 12,
            'poor': 10,
        },
        'temporal_enhanced': {
            'lower-distinctiveness-habitat-poor': 10,
            'lower-distinctiveness-habitat-fairly-poor': 12,
            'lower-distinctiveness-habitat-moderate': 15,
            'lower-distinctiveness-habitat-fairly-good': 17,
            'lower-distinctiveness-habitat-good': 20,
            'poor-fairly-poor': 5,
            'poor-moderate': 10,
            'poor-fairly-good': 15,
            'poor-good': 20,
            'fairly-poor-moderate': 10,
            'fairly-poor-fairly-good': 15,
            'fairly-poor-good': 20,
            'moderate-fairly-good': 10,
            'moderate-good': 15,
            'fairly-good-good': 5,
        },
        'technical_difficulty_creation': 'medium',
        'technical_difficulty_enhancement': 'low',
        'theme': parseTheme('solid', 'red'),
    }, {
        'value': 'developed-land-sealed-surface',
        'label': 'Developed land-sealed surface',
        'distinctiveness': 'very-low',
        'condition': 'poor',
        'strategic_significance': 'low',
        'temportal_data': {
            'n-a-other': 0,
        },
        'temporal_enhanced': {},
        'technical_difficulty_creation': 'low',
        'technical_difficulty_enhancement': 'medium',
        'theme': parseTheme('linesVertical', 'red', 'white'),
    }, {
        'value': 'facade-bound-green-wall',
        'label': 'Façade-bound green wall',
        'distinctiveness': 'low',
        'condition': 'poor',
        'strategic_significance': 'low',
        'temportal_data': {
            'good': 5,
            'fairly-good': 4,
            'moderate': 3,
            'fairly-poor': 2,
            'poor': 1,
        },
        'temporal_enhanced': {
            'lower-distinctiveness-habitat-poor': 1,
            'lower-distinctiveness-habitat-fairly-poor': 2,
            'lower-distinctiveness-habitat-moderate': 3,
            'lower-distinctiveness-habitat-fairly-good': 4,
            'lower-distinctiveness-habitat-good': 5,
            'poor-fairly-poor': 1,
            'poor-moderate': 2,
            'poor-fairly-good': 3,
            'poor-good': 5,
            'fairly-poor-moderate': 1,
            'fairly-poor-fairly-good': 2,
            'fairly-poor-good': 3,
            'moderate-fairly-good': 1,
            'moderate-good': 2,
            'fairly-good-good': 1,
        },
        'technical_difficulty_creation': 'medium',
        'technical_difficulty_enhancement': 'medium',
        'theme': parseTheme('solid', 'red'),
    }, {
        'value': 'ground-based-green-wall',
        'label': 'Ground based green wall',
        'distinctiveness': 'low',
        'condition': 'poor',
        'strategic_significance': 'low',
        'temportal_data': {
            'good': 5,
            'fairly-good': 4,
            'moderate': 3,
            'fairly-poor': 2,
            'poor': 1,
        },
        'temporal_enhanced': {
            'lower-distinctiveness-habitat-poor': 1,
            'lower-distinctiveness-habitat-fairly-poor': 2,
            'lower-distinctiveness-habitat-moderate': 3,
            'lower-distinctiveness-habitat-fairly-good': 4,
            'lower-distinctiveness-habitat-good': 5,
            'poor-fairly-poor': 1,
            'poor-moderate': 2,
            'poor-fairly-good': 3,
            'poor-good': 5,
            'fairly-poor-moderate': 1,
            'fairly-poor-fairly-good': 2,
            'fairly-poor-good': 3,
            'moderate-fairly-good': 1,
            'moderate-good': 2,
            'fairly-good-good': 1,
        },
        'technical_difficulty_creation': 'medium',
        'technical_difficulty_enhancement': 'medium',
        'theme': parseTheme('solid', 'red'),
    }, {
        'value': 'ground-level-planters',
        'label': 'Ground level planters',
        'distinctiveness': 'low',
        'condition': 'poor',
        'strategic_significance': 'low',
        'temportal_data': {
            'condition-assessment-n-a': 1,
        },
        'temporal_enhanced': {},
        'technical_difficulty_creation': 'low',
        'technical_difficulty_enhancement': 'low',
        'theme': parseTheme('solid', 'red'),
    }, {
        'value': 'intensive-green-roof',
        'label': 'Intensive green roof',
        'distinctiveness': 'medium',
        'condition': 'poor',
        'strategic_significance': 'low',
        'temportal_data': {
            'good': 5,
            'fairly-good': 4,
            'moderate': 3,
            'fairly-poor': 2,
            'poor': 1,
        },
        'temporal_enhanced': {
            'lower-distinctiveness-habitat-poor': 1,
            'lower-distinctiveness-habitat-fairly-poor': 2,
            'lower-distinctiveness-habitat-moderate': 3,
            'lower-distinctiveness-habitat-fairly-good': 4,
            'lower-distinctiveness-habitat-good': 5,
            'poor-fairly-poor': 1,
            'poor-moderate': 2,
            'poor-fairly-good': 3,
            'poor-good': 5,
            'fairly-poor-moderate': 1,
            'fairly-poor-fairly-good': 2,
            'fairly-poor-good': 3,
            'moderate-fairly-good': 1,
            'moderate-good': 2,
            'fairly-good-good': 1,
        },
        'technical_difficulty_creation': 'low',
        'technical_difficulty_enhancement': 'low',
        'theme': parseTheme('solid', 'red'),
    }, {
        'value': 'introduced-shrub',
        'label': 'Introduced shrub',
        'distinctiveness': 'low',
        'condition': 'poor',
        'strategic_significance': 'low',
        'temportal_data': {
            'condition-assessment-n-a': 1,
        },
        'temporal_enhanced': {},
        'technical_difficulty_creation': 'low',
        'technical_difficulty_enhancement': 'low',
        'theme': parseTheme('solid', 'purple'),
    }, {
        'value': 'open-mosaic-habitats-on-previously-developed-land',
        'label': 'Open Mosaic Habitats on Previously Developed Land',
        'distinctiveness': 'high',
        'condition': 'poor',
        'strategic_significance': 'low',
        'temportal_data': {
            'good': 10,
            'fairly-good': 7,
            'moderate': 4,
            'fairly-poor': 2,
            'poor': 0,
        },
        'temporal_enhanced': {
            'lower-distinctiveness-habitat-poor': 0,
            'lower-distinctiveness-habitat-fairly-poor': 2,
            'lower-distinctiveness-habitat-moderate': 4,
            'lower-distinctiveness-habitat-fairly-good': 7,
            'lower-distinctiveness-habitat-good': 10,
            'poor-fairly-poor': 2,
            'poor-moderate': 4,
            'poor-fairly-good': 7,
            'poor-good': 10,
            'fairly-poor-moderate': 2,
            'fairly-poor-fairly-good': 5,
            'fairly-poor-good': 8,
            'moderate-fairly-good': 3,
            'moderate-good': 4,
            'fairly-good-good': 3,
        },
        'technical_difficulty_creation': 'medium',
        'technical_difficulty_enhancement': 'medium',
        'theme': parseTheme('lines45', 'red', 'white'),
    }, {
        'value': 'rain-garden',
        'label': 'Rain garden',
        'distinctiveness': 'low',
        'condition': 'poor',
        'strategic_significance': 'low',
        'temportal_data': {
            'good': 5,
            'fairly-good': 4,
            'moderate': 3,
            'fairly-poor': 2,
            'poor': 1,
        },
        'temporal_enhanced': {
            'lower-distinctiveness-habitat-poor': 1,
            'lower-distinctiveness-habitat-fairly-poor': 1,
            'lower-distinctiveness-habitat-moderate': 1,
            'lower-distinctiveness-habitat-fairly-good': 1,
            'lower-distinctiveness-habitat-good': 1,
            'poor-fairly-poor': 1,
            'poor-moderate': 1,
            'poor-fairly-good': 1,
            'poor-good': 1,
            'fairly-poor-moderate': 1,
            'fairly-poor-fairly-good': 1,
            'fairly-poor-good': 1,
            'moderate-fairly-good': 1,
            'moderate-good': 1,
            'fairly-good-good': 1,
        },
        'technical_difficulty_creation': 'low',
        'technical_difficulty_enhancement': 'low',
        'theme': parseTheme('solid', 'red'),
    }, {
        'value': 'sand-pit-quarry-or-open-cast-mine',
        'label': 'Sand pit quarry or open cast mine',
        'distinctiveness': 'low',
        'condition': 'poor',
        'strategic_significance': 'low',
        'temportal_data': {
            'condition-assessment-n-a': 1,
        },
        'temporal_enhanced': {
            'lower-distinctiveness-habitat-poor': 1,
        },
        'technical_difficulty_creation': 'medium',
        'technical_difficulty_enhancement': 'medium',
        'theme': parseTheme('solid', 'red'),
    }, {
        'value': 'urban-tree',
        'label': 'Urban Tree',
        'distinctiveness': 'medium',
        'condition': 'poor',
        'strategic_significance': 'low',
        'temportal_data': {
            'good': '30+',
            'fairly-good': '30+',
            'moderate': 27,
            'fairly-poor': 15,
            'poor': 10,
        },
        'temporal_enhanced': {
            'poor-fairly-poor': 8,
            'poor-moderate': 16,
            'poor-fairly-good': 24,
            'poor-good': '30+',
            'fairly-poor-moderate': 8,
            'fairly-poor-fairly-good': 16,
            'fairly-poor-good': 24,
            'moderate-fairly-good': 8,
            'moderate-good': 16,
            'fairly-good-good': 8,
        },
        'technical_difficulty_creation': 'low',
        'technical_difficulty_enhancement': 'low',
        'theme': parseTheme('solid', 'red'),
    }, {
        'value': 'sustainable-urban-drainage-feature',
        'label': 'Sustainable urban drainage feature',
        'distinctiveness': 'low',
        'condition': 'poor',
        'strategic_significance': 'low',
        'temportal_data': {
            'good': 5,
            'fairly-good': 4,
            'moderate': 3,
            'fairly-poor': 2,
            'poor': 1,
        },
        'temporal_enhanced': {
            'lower-distinctiveness-habitat-poor': 1,
            'lower-distinctiveness-habitat-fairly-poor': 2,
            'lower-distinctiveness-habitat-moderate': 3,
            'lower-distinctiveness-habitat-fairly-good': 4,
            'lower-distinctiveness-habitat-good': 5,
            'poor-fairly-poor': 1,
            'poor-moderate': 2,
            'poor-fairly-good': 3,
            'poor-good': 5,
            'fairly-poor-moderate': 1,
            'fairly-poor-fairly-good': 2,
            'fairly-poor-good': 3,
            'moderate-fairly-good': 1,
            'moderate-good': 2,
            'fairly-good-good': 1,
        },
        'technical_difficulty_creation': 'medium',
        'technical_difficulty_enhancement': 'medium',
        'theme': parseTheme('solid', 'red'),
    }, {
        'value': 'un-vegetated-garden',
        'label': 'Un-vegetated garden',
        'distinctiveness': 'very-low',
        'condition': 'poor',
        'strategic_significance': 'low',
        'temportal_data': {
            'n-a-other': 0,
        },
        'temporal_enhanced': {},
        'technical_difficulty_creation': 'low',
        'technical_difficulty_enhancement': 'low',
        'theme': parseTheme('solid', 'red'),
    }, {
        'value': 'vacant-derelict-land-bareground',
        'label': 'Vacant/derelict land/ bareground',
        'distinctiveness': 'low',
        'condition': 'poor',
        'strategic_significance': 'low',
        'temportal_data': {
            'good': 5,
            'fairly-good': 4,
            'moderate': 3,
            'fairly-poor': 2,
            'poor': 1,
        },
        'temporal_enhanced': {
            'lower-distinctiveness-habitat-poor': 1,
            'lower-distinctiveness-habitat-fairly-poor': 1,
            'lower-distinctiveness-habitat-moderate': 1,
            'lower-distinctiveness-habitat-fairly-good': 1,
            'lower-distinctiveness-habitat-good': 1,
            'poor-fairly-poor': 1,
            'poor-moderate': 1,
            'poor-fairly-good': 1,
            'poor-good': 1,
            'fairly-poor-moderate': 1,
            'fairly-poor-fairly-good': 1,
            'fairly-poor-good': 1,
            'moderate-fairly-good': 1,
            'moderate-good': 1,
            'fairly-good-good': 1,
        },
        'technical_difficulty_creation': 'low',
        'technical_difficulty_enhancement': 'low',
        'theme': parseTheme('solid', 'red'),
    }, {
        'value': 'vegetated-garden',
        'label': 'Vegetated garden',
        'distinctiveness': 'low',
        'condition': 'poor',
        'strategic_significance': 'low',
        'temportal_data': {
            'condition-assessment-n-a': 1,
        },
        'temporal_enhanced': {
            'lower-distinctiveness-habitat-poor': 1,
            'lower-distinctiveness-habitat-fairly-poor': 2,
            'lower-distinctiveness-habitat-moderate': 3,
            'lower-distinctiveness-habitat-fairly-good': 4,
            'lower-distinctiveness-habitat-good': 5,
            'poor-fairly-poor': 1,
            'poor-moderate': 2,
            'poor-fairly-good': 3,
            'poor-good': 5,
            'fairly-poor-moderate': 1,
            'fairly-poor-fairly-good': 2,
            'fairly-poor-good': 3,
            'moderate-fairly-good': 1,
            'moderate-good': 2,
            'fairly-good-good': 1,
        },
        'technical_difficulty_creation': 'low',
        'technical_difficulty_enhancement': 'low',
        'theme': parseTheme('solid', 'red'),
    }],
    'wetland': [{
        'value': 'blanket-bog',
        'label': 'Blanket bog',
        'distinctiveness': 'very-high',
        'condition': 'poor',
        'strategic_significance': 'low',
        'temportal_data': {
            'good': '30+',
            'fairly-good': '30+',
            'moderate': '30+',
            'fairly-poor': '30+',
            'poor': '30+',
        },
        'temporal_enhanced': {
            'lower-distinctiveness-habitat-poor': 15,
            'lower-distinctiveness-habitat-fairly-poor': 25,
            'lower-distinctiveness-habitat-moderate': 30,
            'lower-distinctiveness-habitat-fairly-good': '30+',
            'lower-distinctiveness-habitat-good': '30+',
            'poor-fairly-poor': 10,
            'poor-moderate': 20,
            'poor-fairly-good': '30+',
            'poor-good': 30,
            'fairly-poor-moderate': 10,
            'fairly-poor-fairly-good': '30+',
            'fairly-poor-good': '30+',
            'moderate-fairly-good': 30,
            'moderate-good': '30+',
            'fairly-good-good': 30,
        },
        'technical_difficulty_creation': 'very-high',
        'technical_difficulty_enhancement': 'high',
        'theme': parseTheme('lines45', 'pink', 'white'),
    }, {
        'value': 'depressions-on-peat-substrates-h7150',
        'label': 'Depressions on Peat substrates (H7150)',
        'distinctiveness': 'very-high',
        'condition': 'poor',
        'strategic_significance': 'low',
        'temportal_data': {
            'good': '30+',
            'fairly-good': '30+',
            'moderate': 30,
            'fairly-poor': 25,
            'poor': 15,
        },
        'temporal_enhanced': {
            'lower-distinctiveness-habitat-poor': 15,
            'lower-distinctiveness-habitat-fairly-poor': 25,
            'lower-distinctiveness-habitat-moderate': 30,
            'lower-distinctiveness-habitat-fairly-good': '30+',
            'lower-distinctiveness-habitat-good': '30+',
            'poor-fairly-poor': 10,
            'poor-moderate': 20,
            'poor-fairly-good': 25,
            'poor-good': 30,
            'fairly-poor-moderate': 10,
            'fairly-poor-fairly-good': 20,
            'fairly-poor-good': 25,
            'moderate-fairly-good': 10,
            'moderate-good': 20,
            'fairly-good-good': 10,
        },
        'technical_difficulty_creation': 'very-high',
        'technical_difficulty_enhancement': 'high',
        'theme': parseTheme('solid', 'pink'),
    }, {
        'value': 'fens-upland-and-lowland',
        'label': 'Fens (upland and lowland)',
        'distinctiveness': 'very-high',
        'condition': 'poor',
        'strategic_significance': 'low',
        'temportal_data': {
            'good': 30,
            'fairly-good': 25,
            'moderate': 20,
            'fairly-poor': 15,
            'poor': 10,
        },
        'temporal_enhanced': {
            'lower-distinctiveness-habitat-poor': 10,
            'lower-distinctiveness-habitat-fairly-poor': 12,
            'lower-distinctiveness-habitat-moderate': 15,
            'lower-distinctiveness-habitat-fairly-good': 25,
            'lower-distinctiveness-habitat-good': 30,
            'poor-fairly-poor': 10,
            'poor-moderate': 12,
            'poor-fairly-good': 15,
            'poor-good': 18,
            'fairly-poor-moderate': 10,
            'fairly-poor-fairly-good': 12,
            'fairly-poor-good': 15,
            'moderate-fairly-good': 10,
            'moderate-good': 12,
            'fairly-good-good': 10,
        },
        'technical_difficulty_creation': 'high',
        'technical_difficulty_enhancement': 'high',
        'theme': parseTheme('lines45', 'pink', 'yellow'),
    }, {
        'value': 'lowland-raised-bog',
        'label': 'Lowland raised bog',
        'distinctiveness': 'very-high',
        'condition': 'poor',
        'strategic_significance': 'low',
        'temportal_data': {
            'good': '30+',
            'fairly-good': '30+',
            'moderate': 30,
            'fairly-poor': 20,
            'poor': 15,
        },
        'temporal_enhanced': {
            'lower-distinctiveness-habitat-poor': 15,
            'lower-distinctiveness-habitat-fairly-poor': 20,
            'lower-distinctiveness-habitat-moderate': 30,
            'lower-distinctiveness-habitat-fairly-good': '30+',
            'lower-distinctiveness-habitat-good': '30+',
            'poor-fairly-poor': 10,
            'poor-moderate': 20,
            'poor-fairly-good': 25,
            'poor-good': 30,
            'fairly-poor-moderate': 10,
            'fairly-poor-fairly-good': 20,
            'fairly-poor-good': 20,
            'moderate-fairly-good': 10,
            'moderate-good': 15,
            'fairly-good-good': 10,
        },
        'technical_difficulty_creation': 'very-high',
        'technical_difficulty_enhancement': 'high',
        'theme': parseTheme('linesVertical', 'pink', 'white'),
    }, {
        'value': 'oceanic-valley-mire1-d21',
        'label': 'Oceanic Valley Mire[1] (D2.1)',
        'distinctiveness': 'very-high',
        'condition': 'poor',
        'strategic_significance': 'low',
        'temportal_data': {
            'good': '30+',
            'fairly-good': '30+',
            'moderate': 30,
            'fairly-poor': 20,
            'poor': 15,
        },
        'temporal_enhanced': {
            'lower-distinctiveness-habitat-poor': 15,
            'lower-distinctiveness-habitat-fairly-poor': 20,
            'lower-distinctiveness-habitat-moderate': 30,
            'lower-distinctiveness-habitat-fairly-good': '30+',
            'lower-distinctiveness-habitat-good': '30+',
            'poor-fairly-poor': 10,
            'poor-moderate': 20,
            'poor-fairly-good': 25,
            'poor-good': 30,
            'fairly-poor-moderate': 10,
            'fairly-poor-fairly-good': 20,
            'fairly-poor-good': 20,
            'moderate-fairly-good': 10,
            'moderate-good': 15,
            'fairly-good-good': 10,
        },
        'technical_difficulty_creation': 'very-high',
        'technical_difficulty_enhancement': 'high',
        'theme': parseTheme('solid', 'pink'),
    }, {
        'value': 'purple-moor-grass-and-rush-pastures',
        'label': 'Purple moor grass and rush pastures',
        'distinctiveness': 'very-high',
        'condition': 'poor',
        'strategic_significance': 'low',
        'temportal_data': {
            'good': 30,
            'fairly-good': 25,
            'moderate': 20,
            'fairly-poor': 15,
            'poor': 10,
        },
        'temporal_enhanced': {
            'lower-distinctiveness-habitat-poor': 10,
            'lower-distinctiveness-habitat-fairly-poor': 12,
            'lower-distinctiveness-habitat-moderate': 15,
            'lower-distinctiveness-habitat-fairly-good': 17,
            'lower-distinctiveness-habitat-good': 20,
            'poor-fairly-poor': 10,
            'poor-moderate': 10,
            'poor-fairly-good': 15,
            'poor-good': 20,
            'fairly-poor-moderate': 10,
            'fairly-poor-fairly-good': 15,
            'fairly-poor-good': 20,
            'moderate-fairly-good': 10,
            'moderate-good': 15,
            'fairly-good-good': 10,
        },
        'technical_difficulty_creation': 'high',
        'technical_difficulty_enhancement': 'high',
        'theme': parseTheme('circles', 'lime', 'whiteLime'),
    }, {
        'value': 'reedbeds',
        'label': 'Reedbeds',
        'distinctiveness': 'high',
        'condition': 'poor',
        'strategic_significance': 'low',
        'temportal_data': {
            'good': 12,
            'fairly-good': 10,
            'moderate': 7,
            'fairly-poor': 5,
            'poor': 3,
        },
        'temporal_enhanced': {
            'lower-distinctiveness-habitat-poor': 5,
            'lower-distinctiveness-habitat-fairly-poor': 7,
            'lower-distinctiveness-habitat-moderate': 10,
            'lower-distinctiveness-habitat-fairly-good': 12,
            'lower-distinctiveness-habitat-good': 15,
            'poor-fairly-poor': 5,
            'poor-moderate': 7,
            'poor-fairly-good': 10,
            'poor-good': 12,
            'fairly-poor-moderate': 5,
            'fairly-poor-fairly-good': 7,
            'fairly-poor-good': 10,
            'moderate-fairly-good': 5,
            'moderate-good': 7,
            'fairly-good-good': 5,
        },
        'technical_difficulty_creation': 'medium',
        'technical_difficulty_enhancement': 'medium',
        'theme': parseTheme('lines', 'yellow', 'pink'),
    }, {
        'value': 'transition-mires-and-quaking-bogs-h7140',
        'label': 'Transition mires and quaking bogs (H7140)',
        'distinctiveness': 'very-high',
        'condition': 'poor',
        'strategic_significance': 'low',
        'temportal_data': {
            'good': '30+',
            'fairly-good': '30+',
            'moderate': 30,
            'fairly-poor': 25,
            'poor': 15,
        },
        'temporal_enhanced': {
            'lower-distinctiveness-habitat-poor': 15,
            'lower-distinctiveness-habitat-fairly-poor': 25,
            'lower-distinctiveness-habitat-moderate': 30,
            'lower-distinctiveness-habitat-fairly-good': '30+',
            'lower-distinctiveness-habitat-good': '30+',
            'poor-fairly-poor': 10,
            'poor-moderate': 20,
            'poor-fairly-good': 25,
            'poor-good': 30,
            'fairly-poor-moderate': 10,
            'fairly-poor-fairly-good': 20,
            'fairly-poor-good': 20,
            'moderate-fairly-good': 10,
            'moderate-good': 15,
            'fairly-good-good': 10,
        },
        'technical_difficulty_creation': 'very-high',
        'technical_difficulty_enhancement': 'high',
        'theme': parseTheme('solid', 'pink'),
    }],
    'woodland-and-forest': [{
        'value': 'felled',
        'label': 'Felled',
        'distinctiveness': 'high',
        'condition': 'poor',
        'strategic_significance': 'low',
        'temporal_enhanced': {},
        'technical_difficulty_creation': 'low',
        'technical_difficulty_enhancement': 'low',
        'temportal_data': {
            'good': '30+',
        },
        'theme': parseTheme('solid', 'green'),
    }, {
        'value': 'lowland-beech-and-yew-woodland',
        'label': 'Lowland beech and yew woodland',
        'distinctiveness': 'high',
        'condition': 'poor',
        'strategic_significance': 'low',
        'temporal_enhanced': {
            'lower-distinctiveness-habitat-poor': 10,
            'lower-distinctiveness-habitat-fairly-poor': 25,
            'lower-distinctiveness-habitat-moderate': '30+',
            'lower-distinctiveness-habitat-fairly-good': '30+',
            'lower-distinctiveness-habitat-good': '30+',
            'poor-fairly-poor': 25,
            'poor-moderate': '30+',
            'poor-fairly-good': '30+',
            'poor-good': '30+',
            'fairly-poor-moderate': '30+',
            'fairly-poor-fairly-good': '30+',
            'fairly-poor-good': '30+',
            'moderate-fairly-good': '30+',
            'moderate-good': '30+',
            'fairly-good-good': '30+',
        },
        'technical_difficulty_creation': 'high',
        'technical_difficulty_enhancement': 'high',
        'temportal_data': {
            'good': '30+',
            'fairly-good': '30+',
            'moderate': '30+',
            'fairly-poor': 25,
            'poor': 10,
        },
        'theme': parseTheme('lines', 'white', 'green'),
    }, {
        'value': 'lowland-mixed-deciduous-woodland',
        'label': 'Lowland mixed deciduous woodland',
        'distinctiveness': 'high',
        'condition': 'poor',
        'strategic_significance': 'low',
        'temporal_enhanced': {
            'lower-distinctiveness-habitat-poor': 10,
            'lower-distinctiveness-habitat-fairly-poor': 25,
            'lower-distinctiveness-habitat-moderate': '30+',
            'lower-distinctiveness-habitat-fairly-good': '30+',
            'lower-distinctiveness-habitat-good': '30+',
            'poor-fairly-poor': 10,
            'poor-moderate': 20,
            'poor-fairly-good': 25,
            'poor-good': '30+',
            'fairly-poor-moderate': 10,
            'fairly-poor-fairly-good': 20,
            'fairly-poor-good': 25,
            'moderate-fairly-good': 10,
            'moderate-good': 20,
            'fairly-good-good': 10,
        },
        'technical_difficulty_creation': 'high',
        'technical_difficulty_enhancement': 'high',
        'temportal_data': {
            'good': '30+',
            'fairly-good': '30+',
            'moderate': '30+',
            'fairly-poor': 25,
            'poor': 10,
        },
        'theme': parseTheme('lines', 'brown', 'green'),
    }, {
        'value': 'native-pine-woodlands',
        'label': 'Native pine woodlands',
        'distinctiveness': 'high',
        'condition': 'poor',
        'strategic_significance': 'low',
        'temporal_enhanced': {
            'lower-distinctiveness-habitat-poor': 25,
            'lower-distinctiveness-habitat-fairly-poor': 30,
            'lower-distinctiveness-habitat-moderate': '30+',
            'lower-distinctiveness-habitat-fairly-good': '30+',
            'lower-distinctiveness-habitat-good': '30+',
            'poor-fairly-poor': 10,
            'poor-moderate': 15,
            'poor-fairly-good': 20,
            'poor-good': '30+',
            'fairly-poor-moderate': 15,
            'fairly-poor-fairly-good': 20,
            'fairly-poor-good': 25,
            'moderate-fairly-good': 10,
            'moderate-good': 15,
            'fairly-good-good': 10,
        },
        'technical_difficulty_creation': 'high',
        'technical_difficulty_enhancement': 'high',
        'temportal_data': {
            'good': '30+',
            'fairly-good': '30+',
            'moderate': '30+',
            'fairly-poor': 25,
            'poor': 10,
        },
        'theme': parseTheme('lines45', 'green', 'yellow'),
    }, {
        'value': 'other-coniferous-woodland',
        'label': 'Other coniferous woodland',
        'distinctiveness': 'low',
        'condition': 'poor',
        'strategic_significance': 'low',
        'temporal_enhanced': {
            'poor-fairly-poor': 5,
            'poor-moderate': 25,
            'poor-fairly-good': '30+',
            'poor-good': '30+',
            'fairly-poor-moderate': 20,
            'fairly-poor-fairly-good': 15,
            'fairly-poor-good': 25,
            'moderate-fairly-good': 5,
            'moderate-good': 7,
            'fairly-good-good': 10,
        },
        'technical_difficulty_creation': 'low',
        'technical_difficulty_enhancement': 'low',
        'temportal_data': {
            'good': '30+',
            'fairly-good': '30+',
            'moderate': 30,
            'fairly-poor': 10,
            'poor': 5,
        },
        'theme': parseTheme('lines', 'yellow', 'green'),
    }, {
        'value': 'other-scots-pine-woodland',
        'label': "Other Scot's Pine woodland",
        'distinctiveness': 'medium',
        'condition': 'poor',
        'strategic_significance': 'low',
        'temportal_data': {
            'good': '30+',
            'fairly-good': '30+',
            'moderate': '30+',
            'fairly-poor': 25,
            'poor': 10,
        },
        'temporal_enhanced': {
            'lower-distinctiveness-habitat-poor': 20,
            'lower-distinctiveness-habitat-fairly-poor': 25,
            'lower-distinctiveness-habitat-moderate': '30+',
            'lower-distinctiveness-habitat-fairly-good': '30+',
            'lower-distinctiveness-habitat-good': '30+',
            'poor-fairly-poor': 10,
            'poor-moderate': 15,
            'poor-fairly-good': 20,
            'poor-good': '30+',
            'fairly-poor-moderate': 15,
            'fairly-poor-fairly-good': 20,
            'fairly-poor-good': 25,
            'moderate-fairly-good': 10,
            'moderate-good': 15,
            'fairly-good-good': 10,
        },
        'technical_difficulty_creation': 'medium',
        'technical_difficulty_enhancement': 'medium',
        'theme': parseTheme('linesVertical', 'green', 'yellow'),
    }, {
        'value': 'other-woodland-broadleaved',
        'label': 'Other woodland broadleaved',
        'distinctiveness': 'medium',
        'condition': 'poor',
        'strategic_significance': 'low',
        'temportal_data': {
            'good': '30+',
            'fairly-good': 25,
            'moderate': 15,
            'fairly-poor': 7,
            'poor': 5,
        },
        'temporal_enhanced': {
            'lower-distinctiveness-habitat-poor': 5,
            'lower-distinctiveness-habitat-fairly-poor': 10,
            'lower-distinctiveness-habitat-moderate': 15,
            'lower-distinctiveness-habitat-fairly-good': 20,
            'lower-distinctiveness-habitat-good': 25,
            'poor-fairly-poor': 5,
            'poor-moderate': 10,
            'poor-fairly-good': 15,
            'poor-good': 20,
            'fairly-poor-moderate': 5,
            'fairly-poor-fairly-good': 10,
            'fairly-poor-good': 15,
            'moderate-fairly-good': 5,
            'moderate-good': 10,
            'fairly-good-good': 5,
        },
        'technical_difficulty_creation': 'low',
        'technical_difficulty_enhancement': 'low',
        'theme': parseTheme('circles', 'green', 'brown'),
    }, {
        'value': 'other-woodland-mixed',
        'label': 'Other woodland mixed',
        'distinctiveness': 'medium',
        'condition': 'poor',
        'strategic_significance': 'low',
        'temportal_data': {
            'good': '30+',
            'fairly-good': '30+',
            'moderate': 30,
            'fairly-poor': 10,
            'poor': 5,
        },
        'temporal_enhanced': {
            'lower-distinctiveness-habitat-poor': 5,
            'lower-distinctiveness-habitat-fairly-poor': 10,
            'lower-distinctiveness-habitat-moderate': 15,
            'lower-distinctiveness-habitat-fairly-good': 20,
            'lower-distinctiveness-habitat-good': 25,
            'poor-fairly-poor': 5,
            'poor-moderate': 10,
            'poor-fairly-good': 15,
            'poor-good': 20,
            'fairly-poor-moderate': 5,
            'fairly-poor-fairly-good': 10,
            'fairly-poor-good': 15,
            'moderate-fairly-good': 5,
            'moderate-good': 10,
            'fairly-good-good': 5,
        },
        'technical_difficulty_creation': 'low',
        'technical_difficulty_enhancement': 'low',
        'theme': parseTheme('circles', 'brown', 'green'),
    }, {
        'value': 'upland-birchwoods',
        'label': 'Upland birchwoods',
        'distinctiveness': 'high',
        'condition': 'poor',
        'strategic_significance': 'low',
        'temporal_enhanced': {
            'lower-distinctiveness-habitat-poor': 10,
            'lower-distinctiveness-habitat-fairly-poor': 20,
            'lower-distinctiveness-habitat-moderate': 25,
            'lower-distinctiveness-habitat-fairly-good': 30,
            'lower-distinctiveness-habitat-good': '30+',
            'poor-fairly-poor': 10,
            'poor-moderate': 15,
            'poor-fairly-good': 20,
            'poor-good': '30+',
            'fairly-poor-moderate': 15,
            'fairly-poor-fairly-good': 20,
            'fairly-poor-good': 25,
            'moderate-fairly-good': 10,
            'moderate-good': 15,
            'fairly-good-good': 10,
        },
        'technical_difficulty_creation': 'medium',
        'technical_difficulty_enhancement': 'medium',
        'temportal_data': {
            'good': '30+',
            'fairly-good': 30,
            'moderate': 25,
            'fairly-poor': 20,
            'poor': 10,
        },
        'theme': parseTheme('linesVertical', 'green', 'brown'),
    }, {
        'value': 'upland-mixed-ashwoods',
        'label': 'Upland mixed ashwoods',
        'distinctiveness': 'high',
        'condition': 'poor',
        'strategic_significance': 'low',
        'temporal_enhanced': {
            'lower-distinctiveness-habitat-poor': 10,
            'lower-distinctiveness-habitat-fairly-poor': 25,
            'lower-distinctiveness-habitat-moderate': '30+',
            'lower-distinctiveness-habitat-fairly-good': '30+',
            'lower-distinctiveness-habitat-good': '30+',
            'poor-fairly-poor': 10,
            'poor-moderate': 15,
            'poor-fairly-good': 20,
            'poor-good': '30+',
            'fairly-poor-moderate': 15,
            'fairly-poor-fairly-good': 20,
            'fairly-poor-good': 25,
            'moderate-fairly-good': 10,
            'moderate-good': 15,
            'fairly-good-good': 10,
        },
        'technical_difficulty_creation': 'high',
        'technical_difficulty_enhancement': 'high',
        'temportal_data': {
            'good': '30+',
            'fairly-good': '30+',
            'moderate': '30+',
            'fairly-poor': 25,
            'poor': 10,
        },
        'theme': parseTheme('linesVertical', 'green', 'white'),
    }, {
        'value': 'upland-oakwood',
        'label': 'Upland oakwood',
        'distinctiveness': 'high',
        'condition': 'poor',
        'strategic_significance': 'low',
        'temporal_enhanced': {
            'lower-distinctiveness-habitat-poor': 10,
            'lower-distinctiveness-habitat-fairly-poor': 25,
            'lower-distinctiveness-habitat-moderate': '30+',
            'lower-distinctiveness-habitat-fairly-good': '30+',
            'lower-distinctiveness-habitat-good': '30+',
            'poor-fairly-poor': 25,
            'poor-moderate': '30+',
            'poor-fairly-good': '30+',
            'poor-good': '30+',
            'fairly-poor-moderate': '30+',
            'fairly-poor-fairly-good': '30+',
            'fairly-poor-good': '30+',
            'moderate-fairly-good': '30+',
            'moderate-good': '30+',
            'fairly-good-good': '30+',
        },
        'technical_difficulty_creation': 'high',
        'technical_difficulty_enhancement': 'high',
        'temportal_data': {
            'good': '30+',
            'fairly-good': '30+',
            'moderate': '30+',
            'fairly-poor': 25,
            'poor': 10,
        },
        'theme': parseTheme('lines45', 'green', 'white'),
    }, {
        'value': 'wet-woodland',
        'label': 'Wet woodland',
        'distinctiveness': 'high',
        'condition': 'poor',
        'strategic_significance': 'low',
        'temporal_enhanced': {
            'lower-distinctiveness-habitat-poor': 10,
            'lower-distinctiveness-habitat-fairly-poor': 20,
            'lower-distinctiveness-habitat-moderate': 25,
            'lower-distinctiveness-habitat-fairly-good': 30,
            'lower-distinctiveness-habitat-good': '30+',
            'poor-fairly-poor': 10,
            'poor-moderate': 10,
            'poor-fairly-good': 15,
            'poor-good': '30+',
            'fairly-poor-moderate': 15,
            'fairly-poor-fairly-good': 20,
            'fairly-poor-good': 25,
            'moderate-fairly-good': 10,
            'moderate-good': 15,
            'fairly-good-good': 10,
        },
        'technical_difficulty_creation': 'medium',
        'technical_difficulty_enhancement': 'medium',
        'temportal_data': {
            'good': '30+',
            'fairly-good': 30,
            'moderate': 15,
            'fairly-poor': 10,
            'poor': 5,
        },
        'theme': parseTheme('lines45', 'green', 'brown'),
    }, {
        'value': 'wood-pasture-and-parkland',
        'label': 'Wood-pasture and parkland',
        'distinctiveness': 'very-high',
        'condition': 'poor',
        'strategic_significance': 'low',
        'temporal_enhanced': {
            'lower-distinctiveness-habitat-poor': 10,
            'lower-distinctiveness-habitat-fairly-poor': 25,
            'lower-distinctiveness-habitat-moderate': '30+',
            'lower-distinctiveness-habitat-fairly-good': '30+',
            'lower-distinctiveness-habitat-good': '30+',
            'poor-fairly-poor': 25,
            'poor-moderate': '30+',
            'poor-fairly-good': '30+',
            'poor-good': '30+',
            'fairly-poor-moderate': '30+',
            'fairly-poor-fairly-good': '30+',
            'fairly-poor-good': '30+',
            'moderate-fairly-good': '30+',
            'moderate-good': '30+',
            'fairly-good-good': '30+',
        },
        'technical_difficulty_creation': 'very-high',
        'technical_difficulty_enhancement': 'high',
        'temportal_data': {
            'good': '30+',
            'fairly-good': '30+',
            'moderate': '30+',
            'fairly-poor': 25,
            'poor': 10,
        },
        'theme': parseTheme('circles', 'white', 'green', {width: 25, height: 25, space: 12, stroke2: 'lime'}),
    }],
    'coastal-lagoons': [{
        'value': 'coastal-lagoons',
        'label': 'Coastal lagoons',
        'distinctiveness': 'high',
        'condition': 'poor',
        'strategic_significance': 'low',
        'temportal_data': {
            'good': 10,
            'fairly-good': 8,
            'moderate': 5,
            'fairly-poor': 3,
            'poor': 1,
        },
        'temporal_enhanced': {
            'poor-fairly-poor': 1,
            'poor-moderate': 4,
            'poor-fairly-good': 8,
            'poor-good': 12,
            'fairly-poor-moderate': 3,
            'fairly-poor-fairly-good': 7,
            'fairly-poor-good': 11,
            'moderate-fairly-good': 4,
            'moderate-good': 8,
            'fairly-good-good': 4,
        },
        'technical_difficulty_creation': 'medium',
        'technical_difficulty_enhancement': 'medium',
        'theme': parseTheme('lines', 'white', 'orange'),
    }],
    'rocky-shore': [{
        'value': 'high-energy-littoral-rock',
        'label': 'High energy littoral rock',
        'distinctiveness': 'high',
        'condition': 'poor',
        'strategic_significance': 'low',
        'temportal_data': {
            'good': 10,
            'fairly-good': 7,
            'moderate': 4,
            'fairly-poor': 2,
            'poor': 1,
        },
        'temporal_enhanced': {
            'poor-fairly-poor': 2,
            'poor-moderate': 4,
            'poor-fairly-good': 6,
            'poor-good': 10,
            'fairly-poor-moderate': 2,
            'fairly-poor-fairly-good': 4,
            'fairly-poor-good': 8,
            'moderate-fairly-good': 2,
            'moderate-good': 6,
            'fairly-good-good': 4,
        },
        'technical_difficulty_creation': 'high',
        'technical_difficulty_enhancement': 'medium',
        'theme': parseTheme('lines-45', 'white', 'blueBright'),
    }, {
        'value': 'high-energy-littoral-rock-on-peat-clay-or-chalk',
        'label': 'High energy littoral rock - on peat, clay or chalk',
        'distinctiveness': 'very-high',
        'condition': 'poor',
        'strategic_significance': 'low',
        'temportal_data': {
            'good': '30+',
            'fairly-good': '30+',
            'moderate': '30+',
            'fairly-poor': '30+',
            'poor': '30+',
        },
        'temporal_enhanced': {
            'poor-fairly-poor': 2,
            'poor-moderate': 4,
            'poor-fairly-good': 6,
            'poor-good': 10,
            'fairly-poor-moderate': 2,
            'fairly-poor-fairly-good': 4,
            'fairly-poor-good': 8,
            'moderate-fairly-good': 2,
            'moderate-good': 6,
            'fairly-good-good': 4,
        },
        'technical_difficulty_creation': 'very-high',
        'technical_difficulty_enhancement': 'medium',
        'theme': parseTheme('lines-45', 'white', 'blueBright'),
    }, {
        'value': 'moderate-energy-littoral-rock',
        'label': 'Moderate energy littoral rock',
        'distinctiveness': 'high',
        'condition': 'poor',
        'strategic_significance': 'low',
        'temporal_enhanced': {
            'poor-fairly-poor': 2,
            'poor-moderate': 4,
            'poor-fairly-good': 6,
            'poor-good': 11,
            'fairly-poor-moderate': 2,
            'fairly-poor-fairly-good': 4,
            'fairly-poor-good': 9,
            'moderate-fairly-good': 2,
            'moderate-good': 7,
            'fairly-good-good': 5,
        },
        'technical_difficulty_creation': 'high',
        'technical_difficulty_enhancement': 'medium',
        'temportal_data': {
            'good': 13,
            'fairly-good': 8,
            'moderate': 4,
            'fairly-poor': 2,
            'poor': 1,
        },
        'theme': parseTheme('lines-45', 'white', 'blueBright'),
    }, {
        'value': 'moderate-energy-littoral-rock-on-peat-clay-or-chalk',
        'label': 'Moderate energy littoral rock - on peat, clay or chalk',
        'distinctiveness': 'very-high',
        'condition': 'poor',
        'strategic_significance': 'low',
        'temportal_data': {
            'good': '30+',
            'fairly-good': '30+',
            'moderate': '30+',
            'fairly-poor': '30+',
            'poor': '30+',
        },
        'temporal_enhanced': {
            'poor-fairly-poor': 2,
            'poor-moderate': 4,
            'poor-fairly-good': 6,
            'poor-good': 11,
            'fairly-poor-moderate': 2,
            'fairly-poor-fairly-good': 4,
            'fairly-poor-good': 9,
            'moderate-fairly-good': 2,
            'moderate-good': 7,
            'fairly-good-good': 5,
        },
        'technical_difficulty_creation': 'very-high',
        'technical_difficulty_enhancement': 'medium',
        'theme': parseTheme('lines-45', 'white', 'blueBright'),
    }, {
        'value': 'low-energy-littoral-rock',
        'label': 'Low energy littoral rock',
        'distinctiveness': 'high',
        'condition': 'poor',
        'strategic_significance': 'low',
        'temporal_enhanced': {
            'poor-fairly-poor': 2,
            'poor-moderate': 4,
            'poor-fairly-good': 6,
            'poor-good': 12,
            'fairly-poor-moderate': 2,
            'fairly-poor-fairly-good': 4,
            'fairly-poor-good': 10,
            'moderate-fairly-good': 2,
            'moderate-good': 8,
            'fairly-good-good': 6,
        },
        'technical_difficulty_creation': 'high',
        'technical_difficulty_enhancement': 'medium',
        'temportal_data': {
            'good': 15,
            'fairly-good': 10,
            'moderate': 5,
            'fairly-poor': 1,
            'poor': 1,
        },
        'theme': parseTheme('lines-45', 'white', 'blueBright'),
    }, {
        'value': 'low-energy-littoral-rock-on-peat-clay-or-chalk',
        'label': 'Low energy littoral rock - on peat, clay or chalk',
        'distinctiveness': 'very-high',
        'condition': 'poor',
        'strategic_significance': 'low',
        'temportal_data': {
            'good': '30+',
            'fairly-good': '30+',
            'moderate': '30+',
            'fairly-poor': '30+',
            'poor': '30+',
        },
        'temporal_enhanced': {
            'poor-fairly-poor': 2,
            'poor-moderate': 4,
            'poor-fairly-good': 6,
            'poor-good': 12,
            'fairly-poor-moderate': 2,
            'fairly-poor-fairly-good': 4,
            'fairly-poor-good': 10,
            'moderate-fairly-good': 2,
            'moderate-good': 8,
            'fairly-good-good': 6,
        },
        'technical_difficulty_creation': 'very-high',
        'technical_difficulty_enhancement': 'medium',
        'theme': parseTheme('lines-45', 'white', 'blueBright'),
    }, {
        'value': 'features-of-littoral-rock',
        'label': 'Features of littoral rock',
        'distinctiveness': 'high',
        'condition': 'poor',
        'strategic_significance': 'low',
        'temporal_enhanced': {
            'poor-fairly-poor': 2,
            'poor-moderate': 4,
            'poor-fairly-good': 6,
            'poor-good': 11,
            'fairly-poor-moderate': 2,
            'fairly-poor-fairly-good': 4,
            'fairly-poor-good': 9,
            'moderate-fairly-good': 2,
            'moderate-good': 7,
            'fairly-good-good': 5,
        },
        'technical_difficulty_creation': 'high',
        'technical_difficulty_enhancement': 'medium',
        'temportal_data': {
            'good': 13,
            'fairly-good': 8,
            'moderate': 4,
            'fairly-poor': 2,
            'poor': 1,
        },
        'theme': parseTheme('lines-45', 'white', 'blueBright'),
    }, {
        'value': 'features-of-littoral-rock-on-peat-clay-or-chalk',
        'label': 'Features of littoral rock - on peat, clay or chalk',
        'distinctiveness': 'very-high',
        'condition': 'poor',
        'strategic_significance': 'low',
        'temportal_data': {
            'good': '30+',
            'fairly-good': '30+',
            'moderate': '30+',
            'fairly-poor': '30+',
            'poor': '30+',
        },
        'temporal_enhanced': {
            'poor-fairly-poor': 2,
            'poor-moderate': 4,
            'poor-fairly-good': 6,
            'poor-good': 11,
            'fairly-poor-moderate': 2,
            'fairly-poor-fairly-good': 4,
            'fairly-poor-good': 9,
            'moderate-fairly-good': 2,
            'moderate-good': 7,
            'fairly-good-good': 5,
        },
        'technical_difficulty_creation': 'very-high',
        'technical_difficulty_enhancement': 'medium',
        'theme': parseTheme('lines-45', 'white', 'blueBright'),
    }],
    'coastal-saltmarsh': [{
        'value': 'saltmarshes-and-saline-reedbeds',
        'label': 'Saltmarshes and saline reedbeds',
        'distinctiveness': 'high',
        'condition': 'poor',
        'strategic_significance': 'low',
        'temporal_enhanced': {
            'poor-fairly-poor': 2,
            'poor-moderate': 6,
            'poor-fairly-good': 10,
            'poor-good': 20,
            'fairly-poor-moderate': 4,
            'fairly-poor-fairly-good': 8,
            'fairly-poor-good': 18,
            'moderate-fairly-good': 4,
            'moderate-good': 14,
            'fairly-good-good': 10,
        },
        'technical_difficulty_creation': 'high',
        'technical_difficulty_enhancement': 'medium',
        'temportal_data': {
            'good': 15,
            'fairly-good': 10,
            'moderate': 7,
            'fairly-poor': 3,
            'poor': 1,
        },
        'theme': parseTheme('lines45', 'blue', 'yellow'),
    }, {
        'value': 'artificial-saltmarshes-and-saline-reedbeds',
        'label': 'Artificial saltmarshes and saline reedbeds',
        'distinctiveness': 'low',
        'condition': 'poor',
        'strategic_significance': 'low',
        'temporal_enhanced': {
            'poor-fairly-poor': 2,
            'poor-moderate': 6,
            'poor-fairly-good': 10,
            'poor-good': 20,
            'fairly-poor-moderate': 4,
            'fairly-poor-fairly-good': 8,
            'fairly-poor-good': 18,
            'moderate-fairly-good': 4,
            'moderate-good': 14,
            'fairly-good-good': 10,
        },
        'technical_difficulty_creation': 'high',
        'technical_difficulty_enhancement': 'medium',
        'temportal_data': {
            'good': 15,
            'fairly-good': 10,
            'moderate': 7,
            'fairly-poor': 3,
            'poor': 1,
        },
        'theme': parseTheme('lines45', 'blue', 'yellow'),
    }],
    'intertidal-sediment': [{
        'value': 'littoral-coarse-sediment',
        'label': 'Littoral coarse sediment',
        'distinctiveness': 'medium',
        'condition': 'poor',
        'strategic_significance': 'low',
        'temporal_enhanced': {
            'poor-fairly-poor': 1,
            'poor-moderate': 2,
            'poor-fairly-good': 3,
            'poor-good': 4,
            'fairly-poor-moderate': 1,
            'fairly-poor-fairly-good': 2,
            'fairly-poor-good': 3,
            'moderate-fairly-good': 1,
            'moderate-good': 2,
            'fairly-good-good': 1,
        },
        'technical_difficulty_creation': 'medium',
        'technical_difficulty_enhancement': 'medium',
        'temportal_data': {
            'good': 3,
            'fairly-good': 2,
            'moderate': 1,
            'fairly-poor': 1,
            'poor': 1,
        },
        'theme': parseTheme('lines-45', 'yellow', 'blueBright'),
    }, {
        'value': 'littoral-mud',
        'label': 'Littoral mud',
        'distinctiveness': 'high',
        'condition': 'poor',
        'strategic_significance': 'low',
        'temporal_enhanced': {
            'poor-fairly-poor': 2,
            'poor-moderate': 4,
            'poor-fairly-good': 6,
            'poor-good': 8,
            'fairly-poor-moderate': 2,
            'fairly-poor-fairly-good': 4,
            'fairly-poor-good': 6,
            'moderate-fairly-good': 2,
            'moderate-good': 4,
            'fairly-good-good': 2,
        },
        'technical_difficulty_creation': 'high',
        'technical_difficulty_enhancement': 'medium',
        'temportal_data': {
            'good': 6,
            'fairly-good': 4,
            'moderate': 3,
            'fairly-poor': 2,
            'poor': 1,
        },
        'theme': parseTheme('lines-45', 'yellow', 'blueBright'),
    }, {
        'value': 'littoral-mixed-sediments',
        'label': 'Littoral mixed sediments',
        'distinctiveness': 'high',
        'condition': 'poor',
        'strategic_significance': 'low',
        'temporal_enhanced': {
            'poor-fairly-poor': 1,
            'poor-moderate': 2,
            'poor-fairly-good': 3,
            'poor-good': 4,
            'fairly-poor-moderate': 1,
            'fairly-poor-fairly-good': 2,
            'fairly-poor-good': 3,
            'moderate-fairly-good': 1,
            'moderate-good': 2,
            'fairly-good-good': 1,
        },
        'technical_difficulty_creation': 'high',
        'technical_difficulty_enhancement': 'medium',
        'temportal_data': {
            'good': 5,
            'fairly-good': 4,
            'moderate': 3,
            'fairly-poor': 2,
            'poor': 1,
        },
        'theme': parseTheme('lines-45', 'yellow', 'blueBright'),
    }, {
        'value': 'littoral-seagrass',
        'label': 'Littoral seagrass',
        'distinctiveness': 'high',
        'condition': 'poor',
        'strategic_significance': 'low',
        'temporal_enhanced': {
            'poor-fairly-poor': 3,
            'poor-moderate': 13,
            'poor-fairly-good': 23,
            'poor-good': 0,
            'fairly-poor-moderate': 10,
            'fairly-poor-fairly-good': 20,
            'fairly-poor-good': 30,
            'moderate-fairly-good': 10,
            'moderate-good': 20,
            'fairly-good-good': 10,
        },
        'technical_difficulty_creation': 'high',
        'technical_difficulty_enhancement': 'high',
        'temportal_data': {
            'good': 20,
            'fairly-good': 15,
            'moderate': 10,
            'fairly-poor': 5,
            'poor': 2,
        },
        'theme': parseTheme('lines-45', 'yellow', 'blueBright'),
    }, {
        'value': 'littoral-seagrass-on-peat-clay-or-chalk',
        'label': 'Littoral seagrass on peat, clay or chalk',
        'distinctiveness': 'very-high',
        'condition': 'poor',
        'strategic_significance': 'low',
        'temportal_data': {
            'good': '30+',
            'fairly-good': '30+',
            'moderate': '30+',
            'fairly-poor': '30+',
            'poor': '30+',
        },
        'technical_difficulty_creation': 'very-high',
        'technical_difficulty_enhancement': 'high',
        'temporal_enhanced': {
            'poor-fairly-poor': 2,
            'poor-moderate': 4,
            'poor-fairly-good': 7,
            'poor-good': 0,
            'fairly-poor-moderate': 2,
            'fairly-poor-fairly-good': 3,
            'fairly-poor-good': 8,
            'moderate-fairly-good': 3,
            'moderate-good': 6,
            'fairly-good-good': 3,
        },
        'theme': parseTheme('lines-45', 'yellow', 'blueBright'),
    }, {
        'value': 'littoral-biogenic-reefs-mussels',
        'label': 'Littoral biogenic reefs - Mussels',
        'distinctiveness': 'high',
        'condition': 'poor',
        'strategic_significance': 'low',
        'temporal_enhanced': {
            'poor-fairly-poor': 2,
            'poor-moderate': 4,
            'poor-fairly-good': 7,
            'poor-good': 10,
            'fairly-poor-moderate': 2,
            'fairly-poor-fairly-good': 3,
            'fairly-poor-good': 8,
            'moderate-fairly-good': 3,
            'moderate-good': 6,
            'fairly-good-good': 3,
        },
        'technical_difficulty_creation': 'high',
        'technical_difficulty_enhancement': 'medium',
        'temportal_data': {
            'good': 15,
            'fairly-good': 10,
            'moderate': 5,
            'fairly-poor': 3,
            'poor': 3,
        },
        'theme': parseTheme('lines-45', 'yellow', 'blueBright'),
    }, {
        'value': 'littoral-biogenic-reefs-sabellaria',
        'label': 'Littoral biogenic reefs - Sabellaria',
        'distinctiveness': 'high',
        'condition': 'poor',
        'strategic_significance': 'low',
        'temporal_enhanced': {
            'poor-fairly-poor': 2,
            'poor-moderate': 4,
            'poor-fairly-good': 7,
            'poor-good': 10,
            'fairly-poor-moderate': 2,
            'fairly-poor-fairly-good': 3,
            'fairly-poor-good': 8,
            'moderate-fairly-good': 3,
            'moderate-good': 6,
            'fairly-good-good': 3,
        },
        'temportal_data': {
            'good': 15,
            'fairly-good': 10,
            'moderate': 5,
            'fairly-poor': 3,
            'poor': 3,
        },
        'technical_difficulty_creation': 'high',
        'technical_difficulty_enhancement': 'medium',
        'theme': parseTheme('lines-45', 'yellow', 'blueBright'),
    }, {
        'value': 'features-of-littoral-sediment',
        'label': 'Features of littoral sediment',
        'distinctiveness': 'high',
        'condition': 'poor',
        'strategic_significance': 'low',
        'temporal_enhanced': {
            'poor-fairly-poor': 1,
            'poor-moderate': 2,
            'poor-fairly-good': 3,
            'poor-good': 5,
            'fairly-poor-moderate': 1,
            'fairly-poor-fairly-good': 2,
            'fairly-poor-good': 4,
            'moderate-fairly-good': 1,
            'moderate-good': 3,
            'fairly-good-good': 2,
        },
        'technical_difficulty_creation': 'high',
        'technical_difficulty_enhancement': 'medium',
        'temportal_data': {
            'good': 10,
            'fairly-good': 7,
            'moderate': 5,
            'fairly-poor': 3,
            'poor': 3,
        },
        'theme': parseTheme('lines-45', 'yellow', 'blueBright'),
    }, {
        'value': 'artificial-littoral-coarse-sediment',
        'label': 'Artificial littoral coarse sediment',
        'distinctiveness': 'low',
        'condition': 'poor',
        'strategic_significance': 'low',
        'temporal_enhanced': {
            'poor-fairly-poor': 1,
            'poor-moderate': 2,
            'poor-fairly-good': 3,
            'poor-good': 4,
            'fairly-poor-moderate': 1,
            'fairly-poor-fairly-good': 2,
            'fairly-poor-good': 3,
            'moderate-fairly-good': 1,
            'moderate-good': 2,
            'fairly-good-good': 1,
        },
        'technical_difficulty_creation': 'medium',
        'technical_difficulty_enhancement': 'medium',
        'temportal_data': {
            'good': 3,
            'fairly-good': 2,
            'moderate': 1,
            'fairly-poor': 1,
            'poor': 1,
        },
        'theme': parseTheme('lines-45', 'yellow', 'blueBright'),
    }, {
        'value': 'artificial-littoral-mud',
        'label': 'Artificial littoral mud',
        'distinctiveness': 'low',
        'condition': 'poor',
        'strategic_significance': 'low',
        'temporal_enhanced': {
            'poor-fairly-poor': 2,
            'poor-moderate': 4,
            'poor-fairly-good': 6,
            'poor-good': 8,
            'fairly-poor-moderate': 2,
            'fairly-poor-fairly-good': 4,
            'fairly-poor-good': 6,
            'moderate-fairly-good': 2,
            'moderate-good': 4,
            'fairly-good-good': 2,
        },
        'technical_difficulty_creation': 'high',
        'technical_difficulty_enhancement': 'medium',
        'temportal_data': {
            'good': 6,
            'fairly-good': 4,
            'moderate': 3,
            'fairly-poor': 2,
            'poor': 1,
        },
        'theme': parseTheme('lines-45', 'yellow', 'blueBright'),
    }, {
        'value': 'artificial-littoral-muddy-sand',
        'label': 'Artificial littoral muddy sand',
        'distinctiveness': 'low',
        'condition': 'poor',
        'strategic_significance': 'low',
        'temporal_enhanced': {
            'poor-fairly-poor': 2,
            'poor-moderate': 4,
            'poor-fairly-good': 6,
            'poor-good': 8,
            'fairly-poor-moderate': 2,
            'fairly-poor-fairly-good': 4,
            'fairly-poor-good': 6,
            'moderate-fairly-good': 2,
            'moderate-good': 4,
            'fairly-good-good': 2,
        },
        'technical_difficulty_creation': 'high',
        'technical_difficulty_enhancement': 'medium',
        'temportal_data': {
            'good': 5,
            'fairly-good': 4,
            'moderate': 3,
            'fairly-poor': 2,
            'poor': 1,
        },
        'theme': parseTheme('lines-45', 'yellow', 'blueBright'),
    }, {
        'value': 'artificial-littoral-mixed-sediments',
        'label': 'Artificial littoral mixed sediments',
        'distinctiveness': 'low',
        'condition': 'poor',
        'strategic_significance': 'low',
        'temporal_enhanced': {
            'poor-fairly-poor': 1,
            'poor-moderate': 2,
            'poor-fairly-good': 3,
            'poor-good': 4,
            'fairly-poor-moderate': 1,
            'fairly-poor-fairly-good': 2,
            'fairly-poor-good': 3,
            'moderate-fairly-good': 1,
            'moderate-good': 2,
            'fairly-good-good': 1,
        },
        'technical_difficulty_creation': 'high',
        'technical_difficulty_enhancement': 'medium',
        'temportal_data': {
            'good': 5,
            'fairly-good': 4,
            'moderate': 3,
            'fairly-poor': 2,
            'poor': 1,
        },
        'theme': parseTheme('lines-45', 'yellow', 'blueBright'),
    }, {
        'value': 'artificial-littoral-seagrass',
        'label': 'Artificial littoral seagrass',
        'distinctiveness': 'low',
        'condition': 'poor',
        'strategic_significance': 'low',
        'temporal_enhanced': {
            'poor-fairly-poor': 3,
            'poor-moderate': 13,
            'poor-fairly-good': 23,
            'poor-good': '30+',
            'fairly-poor-moderate': 10,
            'fairly-poor-fairly-good': 20,
            'fairly-poor-good': 30,
            'moderate-fairly-good': 10,
            'moderate-good': 20,
            'fairly-good-good': 10,
        },
        'technical_difficulty_creation': 'high',
        'technical_difficulty_enhancement': 'high',
        'temportal_data': {
            'good': 20,
            'fairly-good': 15,
            'moderate': 10,
            'fairly-poor': 5,
            'poor': 2,
        },
        'theme': parseTheme('lines-45', 'yellow', 'blueBright'),
    }, {
        'value': 'artificial-littoral-biogenic-reefs',
        'label': 'Artificial littoral biogenic reefs',
        'distinctiveness': 'low',
        'condition': 'poor',
        'strategic_significance': 'low',
        'temporal_enhanced': {
            'poor-fairly-poor': 2,
            'poor-moderate': 4,
            'poor-fairly-good': 7,
            'poor-good': 10,
            'fairly-poor-moderate': 2,
            'fairly-poor-fairly-good': 3,
            'fairly-poor-good': 8,
            'moderate-fairly-good': 3,
            'moderate-good': 6,
            'fairly-good-good': 3,
        },
        'technical_difficulty_creation': 'high',
        'technical_difficulty_enhancement': 'medium',
        'temportal_data': {
            'good': 15,
            'fairly-good': 10,
            'moderate': 5,
            'fairly-poor': 3,
            'poor': 3,
        },
        'theme': parseTheme('lines-45', 'yellow', 'blueBright'),
    }, {
        'value': 'littoral-sand',
        'label': 'Littoral sand',
        'distinctiveness': 'medium',
        'condition': 'poor',
        'strategic_significance': 'low',
        'temporal_enhanced': {
            'poor-fairly-poor': 2,
            'poor-moderate': 3,
            'poor-fairly-good': 4,
            'poor-good': 6,
            'fairly-poor-moderate': 1,
            'fairly-poor-fairly-good': 2,
            'fairly-poor-good': 4,
            'moderate-fairly-good': 1,
            'moderate-good': 3,
            'fairly-good-good': 2,
        },
        'technical_difficulty_creation': 'medium',
        'technical_difficulty_enhancement': 'medium',
        'temportal_data': {
            'good': 4,
            'fairly-good': 2,
            'moderate': 1,
            'fairly-poor': 1,
            'poor': 1,
        },
        'theme': parseTheme('lines-45', 'yellow', 'blueBright'),
    }, {
        'value': 'littoral-muddy-sand',
        'label': 'Littoral muddy sand',
        'distinctiveness': 'high',
        'condition': 'poor',
        'strategic_significance': 'low',
        'temporal_enhanced': {
            'poor-fairly-poor': 2,
            'poor-moderate': 4,
            'poor-fairly-good': 6,
            'poor-good': 8,
            'fairly-poor-moderate': 2,
            'fairly-poor-fairly-good': 4,
            'fairly-poor-good': 6,
            'moderate-fairly-good': 2,
            'moderate-good': 4,
            'fairly-good-good': 2,
        },
        'technical_difficulty_creation': 'high',
        'technical_difficulty_enhancement': 'medium',
        'temportal_data': {
            'good': 5,
            'fairly-good': 4,
            'moderate': 3,
            'fairly-poor': 2,
            'poor': 1,
        },
        'theme': parseTheme('lines-45', 'yellow', 'blueBright'),
    }],
    'intertidal-hard-structures': [{
        'value': 'artificial-hard-structures',
        'label': 'Artificial hard structures',
        'distinctiveness': 'low',
        'condition': 'poor',
        'strategic_significance': 'low',
        'temporal_enhanced': {
            'poor-fairly-poor': 6,
            'poor-moderate': 4,
            'poor-fairly-good': 10,
            'poor-good': 2,
            'fairly-poor-moderate': 2,
            'fairly-poor-fairly-good': 8,
            'fairly-poor-good': 6,
            'moderate-fairly-good': 2,
            'moderate-good': 12,
            'fairly-good-good': 4,
        },
        'technical_difficulty_creation': 'medium',
        'technical_difficulty_enhancement': 'medium',
        'temportal_data': {
            'good': 15,
            'fairly-good': 10,
            'moderate': 5,
            'fairly-poor': 2,
            'poor': 1,
        },
        'theme': parseTheme('solid', 'grayDark'),
    }, {
        'value': 'artificial-features-of-hard-structures',
        'label': 'Artificial features of hard structures',
        'distinctiveness': 'low',
        'condition': 'poor',
        'strategic_significance': 'low',
        'temporal_enhanced': {
            'poor-fairly-poor': 5,
            'poor-moderate': 4,
            'poor-fairly-good': 9,
            'poor-good': 2,
            'fairly-poor-moderate': 2,
            'fairly-poor-fairly-good': 7,
            'fairly-poor-good': 6,
            'moderate-fairly-good': 2,
            'moderate-good': 11,
            'fairly-good-good': 4,
        },
        'technical_difficulty_creation': 'medium',
        'technical_difficulty_enhancement': 'medium',
        'temportal_data': {
            'good': 13,
            'fairly-good': 8,
            'moderate': 4,
            'fairly-poor': 2,
            'poor': 1,
        },
        'theme': parseTheme('solid', 'grayDark'),
    }, {
        'value': 'artificial-hard-structures-with-integrated-greening-of-grey-infrastructure-iggi',
        'label': 'Artificial hard structures with Integrated Greening of Grey Infrastructure (IGGI)',
        'distinctiveness': 'medium',
        'condition': 'poor',
        'strategic_significance': 'low',
        'temporal_enhanced': {
            'poor-fairly-poor': 5,
            'poor-moderate': 4,
            'poor-fairly-good': 9,
            'poor-good': 2,
            'fairly-poor-moderate': 2,
            'fairly-poor-fairly-good': 7,
            'fairly-poor-good': 6,
            'moderate-fairly-good': 2,
            'moderate-good': 11,
            'fairly-good-good': 4,
        },
        'technical_difficulty_creation': 'medium',
        'technical_difficulty_enhancement': 'medium',
        'temportal_data': {
            'good': 13,
            'fairly-good': 8,
            'moderate': 4,
            'fairly-poor': 2,
            'poor': 1,
        },
        'theme': parseTheme('solid', 'grayDark'),
    }],
    'hedgerows': [{
        'value': 'native-species-rich-hedgerow-with-trees-associated-with-bank-or-ditch',
        'label': 'Native Species Rich Hedgerow with trees - Associated with bank or ditch',
        'distinctiveness': 'very-high',
        'condition': 'poor',
        'strategic_significance': 'low',
        'temportal_data': {
            'poor': 1,
            'moderate': 10,
            'good': 20,
        },
        'temporal_enhanced': {
            'poor-moderate': '6',
            'poor-good': '10',
            'moderate-good': '4',
        },
        'technical_difficulty_creation': 'low',
        'technical_difficulty_enhancement': 'low',
        'theme': parseTheme('squares', 'purple', 'black'),
    }, {
        'value': 'native-species-rich-hedgerow-with-trees',
        'label': 'Native Species Rich Hedgerow with trees',
        'distinctiveness': 'high',
        'condition': 'poor',
        'strategic_significance': 'low',
        'temportal_data': {
            'poor': 1,
            'moderate': 10,
            'good': 20,
        },
        'temporal_enhanced': {
            'poor-moderate': '6',
            'poor-good': '10',
            'moderate-good': '4',
        },
        'technical_difficulty_creation': 'low',
        'technical_difficulty_enhancement': 'low',
        'theme': parseTheme('squares', 'purple', 'black'),
    }, {
        'value': 'native-species-rich-hedgerow-associated-with-bank-or-ditch',
        'label': 'Native Species Rich Hedgerow - Associated with bank or ditch',
        'distinctiveness': 'high',
        'condition': 'poor',
        'strategic_significance': 'low',
        'temportal_data': {
            'poor': 1,
            'moderate': 5,
            'good': 12,
        },
        'temporal_enhanced': {
            'poor-moderate': '3',
            'poor-good': '5',
            'moderate-good': '2',
        },
        'technical_difficulty_creation': 'low',
        'technical_difficulty_enhancement': 'low',
        'theme': parseTheme('squares', 'purple', 'black'),
    }, {
        'value': 'native-hedgerow-with-trees-associated-with-bank-or-ditch',
        'label': 'Native Hedgerow with trees - Associated with bank or ditch',
        'distinctiveness': 'high',
        'condition': 'poor',
        'strategic_significance': 'low',
        'temportal_data': {
            'poor': 1,
            'moderate': 10,
            'good': 20,
        },
        'temporal_enhanced': {
            'poor-moderate': '6',
            'poor-good': '10',
            'moderate-good': '4',
        },
        'technical_difficulty_creation': 'low',
        'technical_difficulty_enhancement': 'low',
        'theme': parseTheme('squares', 'yellow', 'purple'),
    }, {
        'value': 'native-species-rich-hedgerow',
        'label': 'Native Species Rich Hedgerow',
        'distinctiveness': 'medium',
        'condition': 'poor',
        'strategic_significance': 'low',
        'temportal_data': {
            'poor': 1,
            'moderate': 5,
            'good': 12,
        },
        'temporal_enhanced': {
            'poor-moderate': '3',
            'poor-good': '5',
            'moderate-good': '2',
        },
        'technical_difficulty_creation': 'low',
        'technical_difficulty_enhancement': 'low',
        'theme': parseTheme('squares', 'purple', 'black'),
    }, {
        'value': 'native-hedgerow-associated-with-bank-or-ditch',
        'label': 'Native Hedgerow - Associated with bank or ditch',
        'distinctiveness': 'medium',
        'condition': 'poor',
        'strategic_significance': 'low',
        'temportal_data': {
            'poor': 1,
            'moderate': 5,
            'good': 12,
        },
        'temporal_enhanced': {
            'poor-moderate': '3',
            'poor-good': '5',
            'moderate-good': '2',
        },
        'technical_difficulty_creation': 'low',
        'technical_difficulty_enhancement': 'low',
        'theme': parseTheme('squares', 'yellow', 'purple'),
    }, {
        'value': 'native-hedgerow-with-trees',
        'label': 'Native Hedgerow with trees',
        'distinctiveness': 'medium',
        'condition': 'poor',
        'strategic_significance': 'low',
        'temportal_data': {
            'poor': 1,
            'moderate': 10,
            'good': 20,
        },
        'temporal_enhanced': {
            'poor-moderate': '6',
            'poor-good': '10',
            'moderate-good': '4',
        },
        'technical_difficulty_creation': 'low',
        'technical_difficulty_enhancement': 'low',
        'theme': parseTheme('squares', 'yellow', 'purple'),
    }, {
        'value': 'line-of-trees-ecologically-valuable',
        'label': 'Line of Trees (Ecologically Valuable)',
        'distinctiveness': 'medium',
        'condition': 'poor',
        'strategic_significance': 'low',
        'temportal_data': {
            'poor': 5,
            'moderate': 20,
            'good': '30+',
        },
        'temporal_enhanced': {
            'poor-moderate': '20',
            'poor-good': '30',
            'moderate-good': '10',
        },
        'technical_difficulty_creation': 'low',
        'technical_difficulty_enhancement': 'low',
        'theme': parseTheme('squares', 'green', 'black'),
    }, {
        'value': 'line-of-trees-ecologically-valuable-with-bank-or-ditch',
        'label': 'Line of Trees (Ecologically Valuable) - with Bank or Ditch',
        'distinctiveness': 'medium',
        'condition': 'poor',
        'strategic_significance': 'low',
        'temportal_data': {
            'poor': 5,
            'moderate': 20,
            'good': '30+',
        },
        'temporal_enhanced': {
            'poor-moderate': '20',
            'poor-good': '30',
            'moderate-good': '10',
        },
        'technical_difficulty_creation': 'low',
        'technical_difficulty_enhancement': 'low',
        'theme': parseTheme('squares', 'green', 'black'),
    }, {
        'value': 'native-hedgerow',
        'label': 'Native Hedgerow',
        'distinctiveness': 'low',
        'condition': 'poor',
        'strategic_significance': 'low',
        'temportal_data': {
            'poor': 1,
            'moderate': 5,
            'good': 12,
        },
        'temporal_enhanced': {
            'poor-moderate': '3',
            'poor-good': '5',
            'moderate-good': '2',
        },
        'technical_difficulty_creation': 'low',
        'technical_difficulty_enhancement': 'low',
        'theme': parseTheme('squares', 'yellow', 'purple'),
    }, {
        'value': 'line-of-trees',
        'label': 'Line of Trees',
        'distinctiveness': 'low',
        'condition': 'poor',
        'strategic_significance': 'low',
        'temportal_data': {
            'poor': 5,
            'moderate': 20,
            'good': '30+',
        },
        'temporal_enhanced': {
            'poor-moderate': '20',
            'poor-good': '30',
            'moderate-good': '10',
        },
        'technical_difficulty_creation': 'low',
        'technical_difficulty_enhancement': 'low',
        'theme': parseTheme('squares', 'green', 'black'),
    }, {
        'value': 'line-of-trees-associated-with-bank-or-ditch',
        'label': 'Line of Trees - Associated with bank or ditch',
        'distinctiveness': 'low',
        'condition': 'poor',
        'strategic_significance': 'low',
        'temportal_data': {
            'poor': 5,
            'moderate': 20,
            'good': '30+',
        },
        'temporal_enhanced': {
            'poor-moderate': '20',
            'poor-good': '30',
            'moderate-good': '10',
        },
        'technical_difficulty_creation': 'low',
        'technical_difficulty_enhancement': 'low',
        'theme': parseTheme('squares', 'green', 'black'),
    }, {
        'value': 'hedge-ornamental-non-native',
        'label': 'Hedge Ornamental Non Native',
        'distinctiveness': 'very-low',
        'condition': 'poor',
        'strategic_significance': 'low',
        'temportal_data': {
            'poor': 1,
            'moderate': 'na',
            'good': 'na',
        },
        'temporal_enhanced': {
            'poor-moderate': 'n-a',
            'poor-good': 'n-a',
            'moderate-good': 'n-a',
        },
        'technical_difficulty_creation': 'low',
        'technical_difficulty_enhancement': 'low',
        'theme': parseTheme('squares', 'purple', 'red'),
    }],
    'rivers': [{
        'value': 'priority-habitat',
        'label': 'Priority Habitat',
        'distinctiveness': 'very-high',
        'condition': 'poor',
        'strategic_significance': 'low',
        'temportal_data': {
            'good': 10,
            'fairly-good': 8,
            'moderate': 5,
            'fairly-poor': 2,
            'poor': 1,
        },
        'technical_difficulty_creation': 'high',
        'technical_difficulty_enhancement': 'medium',
        'theme': parseTheme('solid', 'blueBaby'),
    }, {
        'value': 'other-rivers-and-streams',
        'label': 'Other Rivers and Streams',
        'distinctiveness': 'high',
        'condition': 'poor',
        'strategic_significance': 'low',
        'temportal_data': {
            'good': 10,
            'fairly-good': 8,
            'moderate': 5,
            'fairly-poor': 2,
            'poor': 1,
        },
        'technical_difficulty_creation': 'high',
        'technical_difficulty_enhancement': 'medium',
        'theme': parseTheme('circles', 'grayLight', 'green'),
    }, {
        'value': 'ditches',
        'label': 'Ditches',
        'distinctiveness': 'medium',
        'condition': 'poor',
        'strategic_significance': 'low',
        'temportal_data': {
            'good': 10,
            'fairly-good': 8,
            'moderate': 5,
            'fairly-poor': 2,
            'poor': 1,
        },
        'technical_difficulty_creation': 'low',
        'technical_difficulty_enhancement': 'medium',
        'theme': parseTheme('squares', 'grayLight', 'yellow'),
    }, {
        'value': 'canals',
        'label': 'Canals',
        'distinctiveness': 'medium',
        'condition': 'poor',
        'strategic_significance': 'low',
        'temportal_data': {
            'good': 10,
            'fairly-good': 8,
            'moderate': 5,
            'fairly-poor': 2,
            'poor': 1,
        },
        'technical_difficulty_creation': 'low',
        'technical_difficulty_enhancement': 'medium',
        'theme': parseTheme('circles', 'blueBaby', 'grayLight'),
    }, {
        'value': 'culvert',
        'label': 'Culvert',
        'distinctiveness': 'medium',
        'condition': 'poor',
        'strategic_significance': 'low',
        'temportal_data': {
            'good': 10,
            'fairly-good': 8,
            'moderate': 5,
            'fairly-poor': 2,
            'poor': 1,
        },
        'technical_difficulty_creation': 'low',
        'technical_difficulty_enhancement': 'medium',
        'theme': parseTheme('circles', 'black', 'grayLight'),
    }],
};

const orderedData = (habitats = habitatData) => {
    const sorted = {};

    Object.keys(habitats).sort().forEach(category => {
        sorted[category] = habitats[category].sort((value1, value2) => {
            if (value1.label > value2.label) return 1;
            if (value1.label < value2.label) return -1;

            return 0;
        }).map(habitat => habitat);
    });

    return sorted;
};

const addAdditionalFields = (additionalFields) => {
    if (!additionalFields) return {...habitatData};
    const habitats = {...habitatData};

    if (additionalFields.land) {
        Object.keys(additionalFields.land).forEach(category => {
            if (!habitats[category]) {
                habitats[category] = [];
            }

            habitats[category] = habitats[category].concat(additionalFields.land[category]);
        });
    }

    if (additionalFields.hedgerows) {
        habitats.hedgerows = habitats.hedgerows.concat(additionalFields.hedgerows);
    }

    if (additionalFields.rivers) {
        habitats.rivers = habitats.rivers.concat(additionalFields.rivers);
    }

    return orderedData(habitats);
};

const orderedDataReturn = orderedData();

export default {...habitatData};

export { orderedDataReturn as orderedData, addAdditionalFields };
