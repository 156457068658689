var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("modal", {
    ref: "deleteModal",
    attrs: { width: "50em" },
    scopedSlots: _vm._u([
      {
        key: "header",
        fn: function () {
          return [_c("h3", [_vm._v("\n            Delete Contract\n        ")])]
        },
        proxy: true,
      },
      {
        key: "body",
        fn: function () {
          return [
            _c("p", [_vm._v("Are you sure?")]),
            _vm._v(" "),
            _c("p", [
              _vm._v("Deleting this contract will remove these attachments."),
            ]),
            _vm._v(" "),
            _c("TableGridLayout", {
              attrs: {
                columns: _vm.columns,
                rows: _vm.attachments,
                output: ["table"],
                mode: "remote",
                "total-records": _vm.attachments.length,
              },
            }),
            _vm._v(" "),
            _c(
              "button",
              { staticClass: "btn--reset btn", on: { click: _vm.closeModal } },
              [_vm._v("\n            Cancel\n        ")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn--reset btn btn--red",
                attrs: { type: "submit" },
                on: { click: _vm.deleteContract },
              },
              [_vm._v("\n            Confirm\n        ")]
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }