<template>
    <div>
        <template
            v-if="!value.study"
        >
            <div
                class="form-flex--tab form-flex--vcenter"
            >
                <FormulateInput
                    type="text"
                    name="potential_estimated_units"
                    :label="potentialTextEstimate"
                    :disabled="true"
                />
                <FormulateInput
                    v-if="value.offset_type.includes('carbon')"
                    type="text"
                    name="potential_secondary_estimated_units"
                    label=" Potential CO2-e(kg)"
                    :disabled="true"
                />
            </div>
            <BNGCalculator
                v-if="value.offset_type.includes('bng')"
                key="bng_calculator"
                ref="calcutator"
                v-model="tmpValue.potential_habitats"
                :max-area="value.size ? parseFloat(value.size) : null"
                @input="updateHabitatData"
                @area="updateHabitatArea"
            />
            <SouthernCarbon
                v-if="value.offset_type.includes('carbon')"
                key="southern_carbon"
                ref="calcutator"
                v-model="tmpValue.potential_habitats"
                @updateUnits="updateUnits"
            />
        </template>
    </div>
</template>

<script>
import BNGCalculator from 'Components/Calculator/Bng';
import SouthernCarbon from 'Components/Calculator/SouthernCarbon';

export default {
    name: 'PlotFormPage3',
    components: {
        BNGCalculator,
        SouthernCarbon,
    },
    props: {
        value: {
            type: Object,
            default: () => {},
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            areaDetails: null,
            tmpValue: this.value,
            habitatsUnits: {},
        };
    },
    computed: {
        potentialTextEstimate() {
            return this.value.offset_type.includes('bng') ? 'Potential total units' : 'Potential Carbon(kg)';
        },
    },
    'value.potential_habitats': {
        handler(value) {
            this.tmpValue.potential_habitats = value;
        },
        deep: true,
    },
    methods: {
        updateHabitatData() {
            if (!this.value.study && !this.documentUploaded) {
                this.habitatsUnits = {
                    units: this.$refs.calcutator.getUnits('all'),
                };
            }

            this.$emit('change', this.tmpValue);
        },

        updateUnits(type, units) {
            if (type === 'carbon') {
                this.habitatsUnits = {
                    units: units.carbon,
                    secondary_units: units.co2e,
                };
            }

            this.$emit('change', this.tmpValue);
        },

        updateHabitatArea(value) {
            this.areaDetails = value;
            this.$emit('change', this.tmpValue);
        },
    },
};
</script>
