var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("modal", {
    ref: "edit",
    scopedSlots: _vm._u([
      {
        key: "header",
        fn: function () {
          return [
            _c("h3", [
              _vm._v("Edit " + _vm._s(_vm.localPlanningAuthority.company_name)),
            ]),
          ]
        },
        proxy: true,
      },
      {
        key: "body",
        fn: function () {
          return [
            _c(
              "FormulateForm",
              {
                ref: "form",
                staticClass: "flex-col",
                attrs: { errors: _vm.$page.props.errors },
                on: { submit: _vm.submitForm },
                model: {
                  value: _vm.form,
                  callback: function ($$v) {
                    _vm.form = $$v
                  },
                  expression: "form",
                },
              },
              [
                _c("FormulateInput", {
                  attrs: {
                    type: "number",
                    name: "min_price_per_unit",
                    label: "Min Price Per Unit",
                  },
                }),
                _vm._v(" "),
                _c("FormulateInput", {
                  attrs: {
                    type: "number",
                    name: "max_price_per_unit",
                    label: "Max Price Per Unit",
                  },
                }),
                _vm._v(" "),
                _c("FormulateInput", {
                  attrs: {
                    type: "url",
                    name: "url",
                    label: "Website URL",
                    validation: "url",
                  },
                }),
                _vm._v(" "),
                _c("div", { staticClass: "formulate-input btn--group" }, [
                  _c(
                    "a",
                    {
                      staticClass: "btn",
                      attrs: { href: "#" },
                      on: { click: _vm.$refs.edit.closeModal },
                    },
                    [_vm._v("\n                    Cancel\n                ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn--reset btn btn--primary",
                      attrs: { type: "submit" },
                    },
                    [
                      _c("SvgController", { attrs: { type: "tick" } }),
                      _vm._v("\n                    Save\n                "),
                    ],
                    1
                  ),
                ]),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }