<template>
    <empty
        v-if="rental.project === null || rental.plot === null"
        type="error"
    >
        <template #message>
            <h3>{{ errorMessage }}</h3>
        </template>
    </empty>
    <report
        v-else
        type="rental"
        :report-data="{
            rental,
            title
        }"
        :filename="filename"
    />
</template>

<script>

import Report from 'Components/Reports/Report';
import Empty from 'Utilities/empty/Empty';

export default {
    name: 'AgreementReport',
    components: {
        Report,
        Empty,
    },

    props: {
        rental: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {};
    },

    computed: {
        filename() {
            return `${this.title}-agreement-site-report-${this.rental.project.name}`;
        },

        title() {
            return this.$page.props.loggedUser.positions[0].company.name;
        },

        errorMessage() {
            if (this.rental.project === null && this.rental.plot === null) {
                return 'Unable to find Development and Land Parcel.';
            } else if (this.rental.project === null) {
                return 'Unable to find Development.';
            } else if (this.rental.plot === null) {
                return 'Unable to find Land Parcel.';
            }

            return 'Unknown Error';
        },
    },

    mounted() {
    },

    methods: {},
};
</script>
