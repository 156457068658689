var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "app-layout",
    { attrs: { section: "plots" } },
    [
      _c(
        "page-header",
        {
          attrs: { title: _vm.title, icon: "plots", count: _vm.plots.total },
          scopedSlots: _vm._u([
            {
              key: "page-header-bottom",
              fn: function () {
                return [
                  _c("div", { staticClass: "center" }, [
                    _vm.hasGroup(["sequest", "lpa"])
                      ? _c(
                          "div",
                          { staticClass: "tile-wrap flex-space-between" },
                          [
                            _c("tile", {
                              attrs: {
                                href: _vm.$route("rentals"),
                                title: "All BNG Agreements",
                                icon: "plots",
                                count: _vm.rentals.length,
                                color: "#5EBD8A",
                              },
                            }),
                            _vm._v(" "),
                            _c("tile", {
                              attrs: {
                                href: _vm.$route("rentals", {
                                  latest_update_status: "awaiting review",
                                }),
                                title: "BNG Agreements Awaiting Review",
                                icon: "file-info",
                                count: _vm.rentalsAwaitingReview,
                                color: "#1782ff",
                              },
                            }),
                            _vm._v(" "),
                            _c("tile", {
                              attrs: {
                                href: _vm.$route("rentals", {
                                  latest_update_status: "awaiting payment",
                                }),
                                title: "BNG Agreements Awaiting Payment",
                                icon: "card",
                                count: _vm.rentalsAwaitingPayment,
                                color: "#E47D44",
                              },
                            }),
                            _vm._v(" "),
                            _c("tile", {
                              attrs: {
                                href: _vm.$route("rentals", {
                                  update_overdue: "true",
                                }),
                                title: "BNG Agreements Overdue Updates",
                                icon: "bell",
                                count: _vm.rentalsOverdue,
                                color: "#E34B5D",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _vm.$page.props.loggedUser.can.includes("plot_save") &&
          !_vm.$page.props.loggedUser.isLPA
            ? _c(
                "inertia-link",
                {
                  staticClass: "btn-reset btn btn--primary",
                  attrs: { href: _vm.$route("plot.create") },
                },
                [
                  _c("SvgController", { attrs: { type: "plus" } }),
                  _vm._v(" "),
                  _c("span", [_vm._v("New Land Parcel")]),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "center" },
        [
          _c("plot-table", {
            attrs: {
              plots: _vm.plots,
              "filter-route": _vm.filterRoute,
              "force-hide-company": _vm.isLandownerPage,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }