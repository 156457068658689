var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("auth-layout", { attrs: { component: _vm.$options.name } }, [
    _c(
      "form",
      {
        attrs: { method: "POST" },
        on: {
          submit: function ($event) {
            $event.preventDefault()
            _vm.form.post(_vm.$route("verification.send"))
          },
        },
      },
      [
        _c("div", { staticClass: "form-page bg-white" }, [
          _vm.flash.status === "verification-link-sent"
            ? _c("div", { staticClass: "text-center" }, [
                _c(
                  "div",
                  { staticClass: "icon-success" },
                  [_c("SvgController", { attrs: { type: "circle-tick" } })],
                  1
                ),
                _vm._v(" "),
                _c("h3", [_vm._v("Verification Email Sent")]),
                _vm._v(" "),
                _c("div", { staticClass: "formulate-input" }, [
                  _c("p", [
                    _vm._v(
                      "A new verification link has been sent to the email address you provided during registration."
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "formulate-input" },
                  [
                    _c(
                      "inertia-link",
                      {
                        staticClass: "btn btn--secondary btn--large",
                        attrs: { href: _vm.$route("login") },
                      },
                      [
                        _vm._v(
                          "\n                        Back to Login\n                    "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ])
            : _c(
                "div",
                [
                  _c("h3", [_vm._v("Verify Email Address")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "formulate-input" }, [
                    _c("p", [
                      _c("strong", [_vm._v("Thank you for signing up.")]),
                      _vm._v(" "),
                      _c("br"),
                      _vm._v(
                        "\n                        All you need to do now is confirm your email address. In the next 5 minutes you should receive an email from us with a link to confirm your email address. You will then be able to log in.\n                    "
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("FormulateInput", {
                    attrs: {
                      type: "submit",
                      label: "Resend Verification Email",
                    },
                  }),
                ],
                1
              ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }