var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "auth-layout",
    { attrs: { logo: true, component: _vm.$options.name } },
    [
      _c(
        "FormulateForm",
        {
          attrs: {
            errors: {
              email: _vm.errors.email,
              password: _vm.errors.password,
              password_confirmation: _vm.errors.password_confirmation,
            },
          },
          on: {
            submit: function ($event) {
              _vm.form.post(_vm.$route("password.update"))
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "form-page" },
            [
              _c("h3", [_vm._v("Reset Password")]),
              _vm._v(" "),
              _c("div", { staticClass: "formulate-input" }, [
                _c("p", [
                  _vm._v(
                    "\n                    Enter your email address and then create your new password below.\n                    "
                  ),
                  _c("br"),
                  _vm._v(
                    "\n                    If your token has expired, click Re-send Email to get another one.\n                "
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("FormulateInput", {
                attrs: {
                  type: "email",
                  name: "email",
                  label: "Email Address",
                  validation: "required|email",
                },
                model: {
                  value: _vm.form.email,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "email", $$v)
                  },
                  expression: "form.email",
                },
              }),
              _vm._v(" "),
              _c("FormulateInput", {
                attrs: {
                  type: "password",
                  name: "password",
                  label: "Password",
                  validation: "required|min:8,length",
                },
                model: {
                  value: _vm.form.password,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "password", $$v)
                  },
                  expression: "form.password",
                },
              }),
              _vm._v(" "),
              _c("FormulateInput", {
                attrs: {
                  type: "password",
                  name: "password_confirmation",
                  label: "Password Confirmation",
                  validation: "required|min:8,length|confirm:password",
                  "validation-name": "Password confirmation",
                },
                model: {
                  value: _vm.form.password_confirmation,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "password_confirmation", $$v)
                  },
                  expression: "form.password_confirmation",
                },
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "formulate-input btn--group" },
                [
                  _c(
                    "inertia-link",
                    {
                      staticClass: "btn btn--secondary",
                      attrs: { href: _vm.$route("password.email") },
                    },
                    [
                      _vm._v(
                        "\n                    Re-send Email\n                "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn--reset btn btn--primary",
                      attrs: { type: "submit" },
                    },
                    [_vm._v("\n                    Submit\n                ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }