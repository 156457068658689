class MVT {
    constructor(map, options, readyCallback = () => {}) {
        this.loadIndex = 0;
        this.map = map;
        this.options = options;
        const scriptURL = '/js/vector-tiles-google-maps.js';

        this.ready = readyCallback;

        if (!document.querySelector(`[src="${scriptURL}"]`)) {
            const script = document.createElement('script');

            script.onload = () => {
                this.loadedScript();
            };

            script.src = scriptURL;

            document.head.appendChild(script); //or something of the likes
        } else {
            this.loadedScript();
        }
    }

    loadedScript() {
        if (typeof MVTSource === 'function') {
            this.readyCallback();
        } else if (this.loadIndex < 1000) {
            this.loadIndex++;
            setTimeout(() => {
                this.loadedScript();
            }, 20);
        }
    }

    readyCallback() {
        if (this.ready) {
            const newClass = this.returnExtendedClass();

            this.MVTSource = new newClass(this.map, this.options);

            if (this.map.getBounds()) {
                this.ready(this.MVTSource);
            } else {
                google.maps.event.addListenerOnce(this.map, 'idle', () => {
                    this.ready(this.MVTSource);
                });
            }
        }
    }

    returnExtendedClass() {
        // eslint-disable-next-line no-undef
        class MVTTmp extends MVTSource {
            constructor(map, options) {
                super(map, options);
                // eslint-disable-next-line no-undef
                this.mercator = MERCATOR;
                this.id = this.getRandomID();
            }

            getRandomID() {
                return `${Math.floor(Math.random() * 1000)}-${Date.now()}-${Math.floor(Date.now() * Math.random() * 1000)}`;
            }

            async requestAllTiles() {
                return new Promise((resolve, reject) => {
                    const self = this;

                    const src = this._url
                        .replace('{z}', 0)
                        .replace('{x}', 0)
                        .replace('{y}', 0);

                    const xmlHttpRequest = new XMLHttpRequest();

                    xmlHttpRequest.onload = function() {
                        if (xmlHttpRequest.status === 200 && xmlHttpRequest.response) {
                            resolve(self.requestAllTilesOk(xmlHttpRequest.response));
                        } else {
                            reject();
                        }
                    };

                    xmlHttpRequest.open('GET', src, true);

                    for (const header in this._xhrHeaders) {
                        xmlHttpRequest.setRequestHeader(header, this._xhrHeaders[header]);
                    }

                    xmlHttpRequest.responseType = 'arraybuffer';
                    xmlHttpRequest.send();
                });
            }

            requestAllTilesOk(response) {
                const uint8Array = new Uint8Array(response);
                // eslint-disable-next-line no-undef
                const pbf = new Pbf(uint8Array);

                // eslint-disable-next-line no-undef
                return new VectorTile(pbf);
            }
        }

        return MVTTmp;
    }
}

export default MVT;
