<template>
    <app-layout section="contact">
        <page-header
            title="Contact Us"
            icon="contact"
        />
        <div class="center">
            <p>In order to contact the BNG Partnership team, please send an email to <a href="mailto:info@bng.eco">info@bng.eco</a>.</p>
        </div>
    </app-layout>
</template>
<script>
export default {
    name: 'Contact',
    components: {},
};
</script>
