<template>
    <div
        ref="tooltip"
        class="tooltip tooltip--center"
        :class="{
            'tooltip--hovered': hovered,
        }"
    >
        <div class="tooltip--content">
            {{ content }}
        </div>
    </div>
</template>

<script>
export default {
    name: 'Tooltip',
    props: {
        content: {
            type: String,
            default: null,
        },
        parent: {
            type: Element,
            default: null,
        },
    },
    data() {
        return {
            parentElement: this.parent,
            hovered: false,
        };
    },
    mounted() {
        if (!this.parentElement) {
            this.parentElement = this.$refs.tooltip.parentElement;
        }

        this.parentElement.addEventListener('mouseover', this.open);
        this.parentElement.addEventListener('mouseleave', this.close);
    },
    methods: {
        open() {
            this.hovered = true;
        },

        close() {
            this.hovered = false;
        },
    },
};
</script>
