<template>
    <div>
        <terms
            :show="showTerms"
            @close="showTerms = false"
        />
        <div v-show="!showTerms">
            <div class="form-page-info">
                <p>
                    Create a secure password to access your account.
                </p>
            </div>
            <FormulateInput
                type="password"
                name="password"
                label="Password"
                validation="required|min:8,length"
            />
            <FormulateInput
                type="password"
                name="password_confirmation"
                label="Password Confirmation"
                validation="confirm:password"
                validation-name="Password confirmation"
            />
            <FormulateInput
                id="terms"
                type="checkbox"
                name="terms"
                validation="required"
            >
                <template #label>
                    <label for="terms">
                        I agree to the terms of the
                        <a @click="showTerms = true">
                            BNG Partnership Customer Agreement
                        </a>
                    </label>
                </template>
            </FormulateInput>
            <FormulateInput
                type="group"
            >
                <FormulateInput
                    label="Back"
                    type="button"
                    @click="$emit('back')"
                />
                <FormulateInput
                    type="submit"
                    label="Sign Up"
                />
            </FormulateInput>
        </div>
    </div>
</template>

<script>
import Terms from '../auth/Terms';

export default {
    components: {
        Terms,
    },

    props: {},

    data() {
        return {
            showTerms: false,
        };
    },

    methods:{},
};
</script>
