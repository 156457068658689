import { mapColors, mapServiceProperties } from 'Lib/defaults';

export default {
    data() {
        return {
            mapStyle: [{'featureType': 'poi','elementType': 'labels.icon','stylers': [{'visibility': 'off'}]}],
            mapColors: mapColors,
            mapServiceProperties: mapServiceProperties,
        };
    },

    methods: {
        getMapIcon(type = 'project') {
            let fillColor = this.mapColors[type];

            if (type.indexOf('#') === 0) {
                fillColor = type;
            }

            return {
                path: 'M12.8,35.9C15.5,32.1,24,19.3,24,12c0-6.6-5.4-12-12-12s-12,5.4-12,12c0,7.3,8.5,20.1,11.2,23.9C11.6,36.5,12.5,36.5,12.8,35.9z M12.1,20c-4.4,0-8-3.6-8-8s3.6-8,8-8s8,3.6,8,8S16.5,20,12.1,20z',
                fillColor: fillColor,
                fillOpacity: 1,
                strokeWeight: 0,
                scaledSize: {
                    width: 40,
                    height: 42,
                },
                origin: {
                    x: 0,
                    y: 0,
                },
                anchor: {
                    x: 12,
                    y: 39,
                },
            };
        },

        setMapStyle(objLayers, objData, strInfowindow) {
            if (strInfowindow) {
                objLayers.addListener('click', (event) => {
                    let strTemplate = strInfowindow;

                    if (this.mapInfoWindow) {
                        this.mapInfoWindow.close();
                    }

                    const matches = strTemplate.match(/\((.*?)\)/g);

                    if (matches) {
                        matches.forEach((match) => {
                            strTemplate = strTemplate.replace(match, this.templateMatch(match, objData, event));
                        });
                    }

                    strTemplate = this.templateMatch(strTemplate, objData, event);

                    if (strTemplate) {
                        this.mapInfoWindow = new google.maps.InfoWindow({
                            position: event.latLng,
                            content: strTemplate,
                            map: this.map,
                        });
                    }
                });

                objLayers.addListener('mouseover', function(event) {
                    objLayers.revertStyle();
                    objLayers.overrideStyle(event.feature, {strokeWeight: 2});
                });

                objLayers.addListener('mouseout', function() {
                    objLayers.revertStyle();
                });
            }

            objLayers.setStyle((feature) => {
                let strColor = objData.color;

                if (objData.api) {
                    strColor = this.returnColours(objData.api)[feature.getProperty('A_pred')];
                }

                return {
                    fillColor: strColor,
                    strokeColor: strColor,
                    strokeWeight: 1,
                };
            });
        },

        runAPICalls(objQuery) {
            clearTimeout(this.apiTimeout);

            this.apiTimeout = setTimeout(() => {
                const blnZoomedIn = this.map.getZoom() > this.currentZoomDelayed;
                const blnPanned = this.map.getZoom() === this.currentZoomDelayed;

                if (this.map.getZoom() >= this.apiZoomEnabled && (!blnZoomedIn || blnPanned || (this.currentZoomDelayed <= this.apiZoomEnabled - 1 && this.map.getZoom() >= this.apiZoomEnabled))) {
                    if (Object.keys(objQuery).length) {
                        Object.entries(objQuery).forEach(([key, value]) => {
                            this.retrunGeoData(this.map, key, {
                                outFields: 'ID,A_pred,B_pred,Shape__Area',
                                where: value,
                            }).then((data) => {
                                if (this.formattedLayers[key]) {
                                    this.formattedLayers[key].setMap(null);
                                }

                                this.formattedLayers[key] = new google.maps.Data();

                                this.formattedLayers[key].addGeoJson(data.data);

                                this.setMapStyle(this.formattedLayers[key], {
                                    api: key,
                                }, '{property_A_pred}({property_B_pred}? &#8212; {property_B_pred}:)<br>({property_Shape__Area}/10000) Hectares');

                                this.formattedLayers[key].setMap(this.map);
                            });
                        });
                    }
                } else if (this.map.getZoom() < this.apiZoomEnabled) {
                    if (Object.keys(objQuery).length) {
                        Object.entries(objQuery).forEach(([key]) => {
                            if (this.formattedLayers[key]) {
                                this.formattedLayers[key].setMap(null);
                            }
                        });
                    }
                }

                if (this.map.getZoom() >= this.apiZoomEnabled) {
                    this.currentZoomDelayed = this.map.getZoom();
                }
            }, 1000);
        },

        getColor(objLayer) {
            if (objLayer.api) {
                return this.returnColours(objLayer.api)[objLayer.name];
            } else if (mapColors[objLayer.color]) {
                return mapColors[objLayer.color];
            }

            return objLayer.color;
        },

        templateMatch(strTemplate, objData, event) {
            strTemplate = strTemplate.replace(/^\(|\)$/g, '');
            strTemplate.match(/\{(.*?)\}/g).forEach((match) => {
                let strProperty = '';

                if (strTemplate.indexOf('layer_') === 1) {
                    strProperty = match.replace(match, objData[match.replace('{layer_', '').replace(/\}$/, '')]);
                } else {
                    strProperty = match.replace(match, event.feature.getProperty(match.replace('{property_', '').replace(/\}$/, '')));
                }

                strProperty = strProperty && strProperty !== 'undefined' ?
                    strProperty.replace(/\//g, '&#47;')
                        .replace(/\+/g, '&#43;')
                        .replace(/\*/g, '&#42;')
                        .replace(/-/g, '&#45;')
                        .trim()
                    : '';

                strTemplate = strTemplate.replace(match, strProperty);
            });

            // IF
            if (strTemplate.indexOf('?') > -1 && strTemplate.indexOf(':') > -1) {
                const arrIf = strTemplate.split('?');
                const arrOutcome = arrIf[1].split(':');
                const arrAll = [arrIf[0], arrOutcome[0], arrOutcome[1]];

                strTemplate = arrAll[0] ? arrAll[1] : arrAll[2];
            }

            // Math
            if (strTemplate.indexOf('/') > -1) {
                const arrMath = strTemplate.split('/');

                strTemplate = parseInt(arrMath[0]) / parseInt(arrMath[1]);
            } else if (strTemplate.indexOf('+') > -1) {
                const arrMath = strTemplate.split('+');

                strTemplate = parseInt(arrMath[0]) + parseInt(arrMath[1]);
            } else if (strTemplate.indexOf('*') > -1) {
                const arrMath = strTemplate.split('*');

                strTemplate = parseInt(arrMath[0]) * parseInt(arrMath[1]);
            } else if (strTemplate.indexOf('-') > -1) {
                const arrMath = strTemplate.split('-');

                strTemplate = parseInt(arrMath[0]) - parseInt(arrMath[1]);
            }

            return strTemplate;
        },

        parseCircleList(layer) {
            if (layer.points) {
                return layer.points.map(circle => this.parseCircle(circle));
            }

            if (layer.shape) {
                if (layer.lat && layer.lng) {
                    layer = this.parseCircle(layer);
                }

                return [layer];
            }

            return null;
        },

        parseCircle(point) {
            return {
                shape: 'circle',
                radius: point.radius || 50,
                center: {
                    lat: point.lat,
                    lng: point.lng,
                },
            };
        },
    },
};
