<template>
    <section :class="`convert-file convert-file--${state}`">
        <label v-if="label">
            {{ label }}
        </label>
        <div class="convert-file-input">
            <DropZone
                :document-name="documentName"
            />
            <input
                type="file"
                accept=".xls,.xlsx,.xlsm,.xlsb"
                @change="documentChange"
            >
        </div>
    </section>
</template>

<script>
import { read } from 'xlsx';
import DropZone from './DropZone';

export default {
    name: 'ExcelConvert',
    components: {
        DropZone,
    },
    props: {
        label: {
            type: String,
            default: null,
        },
        iconWaiting: {
            type: String,
            default: 'arrow-up',
        },
        iconUploading: {
            type: String,
            default: 'dots',
        },
        iconDone: {
            type: String,
            default: 'tick',
        },
    },
    data() {
        return {
            documentName: '',
            state: 'waiting',
        };
    },
    methods: {
        documentChange(event) {
            const selectedFile = event.target.files[0];

            this.state = 'uploading';

            this.documentName = selectedFile.name;

            if (selectedFile) {
                this.state = 'done';
                const fileReader = new FileReader();

                fileReader.onload = (event) => {
                    const data = event.target.result;

                    const workbook = read(data, {
                        type: 'binary',
                    });

                    this.$emit('input', workbook, selectedFile);
                };

                fileReader.readAsBinaryString(selectedFile);
            }
        },
    },
};
</script>
