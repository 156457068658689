<template>
    <app-layout section="plots">
        <page-header
            :title="pageTitle"
            icon="plots"
            :background="false"
        >
            <inertia-link
                class="btn-reset btn"
                :href="$route('plot.show', plot.id)"
                :data="{selectedTab}"
            >
                Cancel
            </inertia-link>
            <button
                id="form-submit"
                class="btn-reset btn btn--primary"
                @click="submitFormButton"
            >
                <SvgController
                    type="tick"
                />
                <span>Save Agreement</span>
            </button>
        </page-header>
        <div class="center">
            <FormulateForm
                ref="form"
                v-model="form"
                :errors="form.errors"
                class="formulate-form--plot flex-col"
                @submit="submitForm"
            >
                <div class="form-flex--lap">
                    <div>
                        <div class="formulate-heading">
                            <h6>Agreement Details</h6>
                        </div>
                        <div class="form-flex--tab">
                            <FormulateInput
                                type="text"
                                name="units"
                                label="BNG units required"
                                validation="required"
                                validation-name="BNG units required"
                            />
                            <FormulateInput
                                type="text"
                                name="per_unit_price"
                                label="Agreement price per unit"
                                validation="required"
                                validation-name="Agreement price per unit"
                            />
                        </div>
                        <FormulateInput
                            type="select-plugin"
                            label="Development"
                            name="project_id"
                            :options="projects"
                            validation="required"
                            validation-name="Project"
                        />
                        <InputSuffix>
                            <FormulateInput
                                type="text"
                                name="size"
                                label="Size"
                                validation="required"
                                outer-class="formulate-input formulate-size"
                                :disabled="true"
                            />
                            <template #suffix>
                                <div class="formulate-input-info">
                                    <p>Hectares</p>
                                </div>
                            </template>
                        </InputSuffix>
                        <FormulateInput
                            type="select"
                            name="status"
                            label="Status"
                            validation="required"
                            validation-name="Status"
                            :options="statusOptions"
                            placeholder="Select Agreement status"
                        />
                        <FormulateInput
                            type="datetime-plugin"
                            name="rental_startdate"
                            label="Agreement Start Date"
                            validation="required"
                            validation-name="Agreement Start Date"
                        />

                        <FormulateInput
                            v-if="$page.props.loggedUser.isSuperUser"
                            type="datetime-plugin"
                            name="audit_date"
                            label="Audit Date"
                            validation-name="Audit Date"
                            format="dd MMMM"
                            class="hide-year"
                            :flow="['month', 'date']"
                        />
                    </div>
                    <div>
                        <div class="formulate-heading">
                            <h6>Location</h6>
                        </div>
                        <alert type="info">
                            <p>Use the map below to draw around the part of the plot to be implemented, please be as accurate as possible.</p>
                        </alert>
                        <FormulateInput
                            ref="map"
                            key="map"
                            v-model="form.map_location"
                            type="map"
                            :item="form"
                            :show-geo-input="false"
                            :land-parcels="[plot]"
                            :leases="leases"
                            map-type="lease"
                            :edit-fill-color="mapColors.lease"
                            :zoom="10"
                            validation="required"
                            :errors="[$page.props.errors.location_data]"
                            fit-bounds="landParcels"
                        />
                    </div>
                </div>
            </FormulateForm>
        </div>
    </app-layout>
</template>
<script>
import Alert from 'Utilities/alert/Alert';
import { mapColors } from 'Lib/defaults';
import InputSuffix from 'Utilities/vue-formulate/InputSuffix';

export default {
    name: 'RentalForm',
    components: {
        Alert,
        InputSuffix,
    },
    props: {
        rental: {
            type: Object,
            default: () => ({}),
        },
        plot: {
            type: Object,
            required: true,
        },
        selectedTab: {
            type: Number,
            default: 1,
        },
        edit: {
            type: Boolean,
            default: false,
        },
        leases: {
            type: Array,
            default: () => ([]),
        },
    },
    data() {
        return {
            form: this.$inertia.form({
                units: this.rental.units,
                per_unit_price: this.rental.per_unit_price,
                project_id: this.edit ? this.rental.project_id.toString() : null,
                size: this.rental.size,
                plot_id: this.plot.id,
                status: this.rental.status,
                rental_startdate: this.rental.startdate,
                location_data: [],
                map_location: {
                    ...this.rental.location_data ? this.rental.location_data : {},
                },
                audit_date: this.rental.audit_date,
            }),
            zoom: 6,
            statusOptions: [],
            mapColors: mapColors,
        };
    },
    computed: {
        projects() {
            const projects = [];

            this.$page.props.projects.forEach((project) => {
                projects.push({
                    value: project.id.toString(),
                    label: project.name + ' (' + project.companyName + ')',
                });
            });

            return projects;
        },
        pageTitle() {
            return this.edit ? 'Edit Agreement' : 'Create Agreement';
        },
    },
    watch: {
        form() {
            if (this.form.map_location.area) {
                this.form.size = this.form.map_location.area.hectares.toFixed(2);
            }
        },
    },
    created() {
        this.statusOptions = ['Pending', 'Contract Issued', 'Contract Signed', 'Agreement Started'];
    },
    methods: {
        submitFormButton() {
            this.$refs.form.formSubmitted();
        },
        submitForm() {
            if (this.form.project_id) {
                this.form.project_id = parseInt(this.form.project_id);
            }

            const {
                area,
                ...mapLocation
            } = this.form.map_location;

            if (mapLocation) {
                this.form.location_data = mapLocation;
            }

            if (area) {
                this.form.size = area.hectares.toFixed(2);
            }

            if (this.edit) {
                this.form.put(this.$route('rental.update', this.rental.id));
            } else {
                this.form.post(this.$route('rental.store'));
            }
        },
    },
};
</script>
