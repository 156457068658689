<template>
    <modal ref="create">
        <template #header>
            <h3>Add operating in LPA</h3>
        </template>
        <template #body>
            <FormulateForm
                ref="form"
                v-model="form"
                :errors="$page.props.errors"
                class="flex-col"
                @submit="submitForm"
            >
                <FormulateInput
                    type="select-plugin"
                    name="lpa"
                    validation="required"
                    label="Local Planning Authority"
                    validation-name="lpa"
                    reduce-key="auth_id"
                    label-key="name"
                    :options="planningAuthoritiesSelect"
                    placeholder="Local Planning Authority"
                />
                <div class="formulate-input btn--group">
                    <a
                        href="#"
                        class="btn"
                        @click="$refs.create.closeModal"
                    >
                        Cancel
                    </a>
                    <button
                        class="btn--reset btn btn--primary"
                        type="submit"
                    >
                        <SvgController type="tick" />
                        Save
                    </button>
                </div>
            </FormulateForm>
        </template>
    </modal>
</template>

<script>
import Modal from 'Utilities/modal/Modal';
export default {
    components: {
        Modal,
    },

    data() {
        return {
            form: this.$inertia.form({
                lpa: null,
            }),
            planningAuthoritiesExclude: [],
            planningAuthorities: [],
        };
    },

    computed: {
        planningAuthoritiesSelect() {
            return this.planningAuthorities.map(lpa => ({auth_id: lpa.id, name: lpa.name})).filter((lpa) => !this.planningAuthoritiesExclude.includes(lpa.auth_id));
        },
    },

    methods:{
        open() {
            axios.get('/operating-in', {
                pp: 500,
            }).then((response) => {
                if (response.data['local-planning-authorities'].data) {
                    this.planningAuthoritiesExclude = response.data['local-planning-authorities'].data.map((lpa) => lpa.id);
                }
            });
            window.axios.get('/lpa/list?orderBy=name')
                .then((response) => {
                    this.planningAuthorities = response.data;
                });
            this.$refs.create.openModal();
        },

        submitForm() {
            this.form.post(this.$route('operating-in.store'), {
                onSuccess: () => {
                    this.$refs.create.closeModal();
                },
            });
        },
    },
};
</script>
