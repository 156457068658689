<template>
    <div class="accordion">
        <slot />
    </div>
</template>

<script>
export default {
    name: 'Accordion',
    components: {},

    provide() {
        return { Accordion: this.Accordion };
    },

    props: {
        defaultActive: {
            type: Number,
            default: null,
        },
    },
    data() {
        return {
            Accordion: {
                count: 0,
                active: this.defaultActive,
            },
        };
    },
};
</script>
