var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        _vm.type,
        _vm._b({ tag: "component" }, "component", _vm.reportData, false)
      ),
      _vm._v(" "),
      _c(
        "html2pdf",
        {
          key: "html2Pdf",
          ref: "html2Pdf",
          attrs: {
            filename: _vm.filename,
            "page-numbers": "",
            "pdf-content-width": "1122px",
            "enable-download": "",
            "pdf-orientation": "landscape",
            "has-map": "",
          },
          on: {
            afterDownload: _vm.afterDownload,
            progress: _vm.updateProgress,
          },
        },
        [
          _c(
            "section",
            { attrs: { slot: "pdf-content" }, slot: "pdf-content" },
            [
              _vm.isDownloading
                ? _c(
                    _vm.type,
                    _vm._b(
                      {
                        ref: "downloadReport",
                        tag: "component",
                        attrs: { "is-download": "" },
                      },
                      "component",
                      _vm.reportData,
                      false
                    )
                  )
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "center" }, [
        _c("div", { staticClass: "flex justify-end" }, [
          _c(
            "button",
            {
              staticClass: "btn-reset btn btn--primary",
              on: { click: _vm.generateReport },
            },
            [_vm._v("\n                Download\n            ")]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("modal", {
        ref: "downloadProgress",
        attrs: { "close-mask-click": false, "show-close": false },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "flex-col justify-center items-center" },
                  [
                    _c("loading-progress", {
                      attrs: {
                        progress: _vm.progress,
                        size: "64",
                        rotate: "",
                        "fill-duration": "2",
                        "rotation-duration": "1",
                      },
                    }),
                    _vm._v(" "),
                    _c("h4", [_vm._v("Generating and Downloading")]),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }