var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "formulate-input-datewrap",
      attrs: { "data-show-clear": _vm.showClear },
    },
    [
      _c("DateRangePicker", {
        ref: "picker",
        staticClass: "formulate-input-element",
        class: [
          `formulate-input-element--${_vm.context.type}`,
          { disabled: _vm.disabled },
        ],
        attrs: {
          "auto-apply": true,
          "single-date-picker": _vm.pickerType,
          "locale-data": { firstDay: 1, format: "dd/mm/yyyy" },
          disabled: _vm.disabled,
          ranges: false,
        },
        scopedSlots: _vm._u([
          {
            key: "input",
            fn: function (picker) {
              return [
                picker.startDate === null && picker.endDate === null
                  ? _c("span", { staticClass: "text-grey4" }, [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.context.attributes.placeholder) +
                          "\n            "
                      ),
                    ])
                  : _c("span", { staticClass: "flex-space-between" }, [
                      _c("span", [
                        _vm._v(
                          "\n                    " +
                            _vm._s(picker.rangeText) +
                            "\n                "
                        ),
                      ]),
                      _vm._v(" "),
                      _vm.showClear
                        ? _c(
                            "button",
                            {
                              staticClass: "vue-daterange-picker--clear",
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  _vm.context.model = {
                                    startDate: null,
                                    endDate: null,
                                  }
                                },
                              },
                            },
                            [
                              _c("svg-controller", {
                                attrs: { type: "times" },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]),
              ]
            },
          },
        ]),
        model: {
          value: _vm.context.model,
          callback: function ($$v) {
            _vm.$set(_vm.context, "model", $$v)
          },
          expression: "context.model",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }