<template>
    <div :class="context.classes.file">
        <div
            v-if="!!(imagePreview && currentFile.previewData)"
            :class="`formulate-file-${fileType}-preview`"
        >
            <img
                v-if="fileType === 'image'"
                :src="currentFile.previewData"
                :class="context.classes.fileImagePreviewImage"
            >
            <video
                v-if="fileType === 'video'"
                class="formulate-file-video-preview-video"
            >
                <source
                    :src="currentFile.previewData"
                >
                Your browser does not support the video tag.
            </video>
        </div>
        <div
            :class="context.classes.fileName"
            :title="currentFile.name"
            v-text="currentFile.name"
        />
        <div
            v-if="currentFile.progress !== false"
            :data-just-finished="currentFile.justFinished"
            :data-is-finished="!currentFile.justFinished && currentFile.complete"
            :class="context.classes.fileProgress"
        >
            <div
                :class="context.classes.fileProgressInner"
                :style="{width: currentFile.progress + '%'}"
            />
        </div>
        <div
            v-if="(file.complete && !file.justFinished) || file.progress === false"
            :class="context.classes.fileRemove"
            @click="file.removeFile"
        />
    </div>
</template>

<script>
export default {
    name:'File',
    props: {
        file: {
            type: Object,
            required: true,
        },
        imagePreview: {
            type: Boolean,
            default: false,
        },
        context: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            currentFile: {},
        };
    },

    computed: {
        fileType() {
            if (this.file.file.type.includes('video/') || this.file.file.type === 'application/x-mpegURL') {
                return 'video';
            } else if (this.file.file.type.includes('image/')) {
                return 'image';
            }

            return this.context.type;
        },
    },

    mounted() {
        this.currentFile = this.file;

        if (this.imagePreview && this.fileType === 'video') {
            const reader = new FileReader();

            reader.onload = (evt) => {
                this.currentFile.previewData = evt.target.result;
            };

            reader.readAsDataURL(this.currentFile.file);
        }

    },
};
</script>
