<template>
    <section
        class="notifications-dropdown"
    >
        <DropdownMenu
            v-model="show"
        >
            <button class="btn-reset flex-center site-header-item-button">
                <div class="notifications-dropdown-icon">
                    <div
                        v-show="notificationList.length"
                        class="notifications-dropdown-dot"
                    />
                    <SvgController type="bell" />
                </div>
            </button>
            <div
                slot="dropdown"
                class="flex-col"
                style="height: 400px;"
            >
                <header class="flex-space-between">
                    <h6>Notifications</h6>
                    <button
                        class="btn-reset notifications-dropdown-close"
                        @click="closeDropdown"
                    >
                        <SvgController type="times" />
                    </button>
                </header>
                <transition name="fade">
                    <section>
                        <div
                            v-for="item in notificationList"
                            :key="item.id"
                            ref="notificationArea"
                            :style="styleNotification"
                            class="notifications-dropdown-menu"
                        >
                            <p>{{ item.id }}{{ item.name }}</p>
                            <dropdownMenuList :data="item.list" />
                        </div>
                    </section>
                </transition>
                <div
                    v-if="!notificationList.length"
                    class="notifications-dropdown-menu"
                >
                    <p>No Notifications to show</p>
                </div>
                <footer>
                    <button
                        v-if="data.length"
                        class="btn-reset"
                        @click="dismissNotifications()"
                    >
                        Dismiss All
                    </button>
                </footer>
            </div>
        </DropdownMenu>
    </section>
</template>
<script>
import DropdownMenuList from 'Utilities/dropdown/DropdownMenuList';
import padStart from 'lodash/padStart';
export default {
    name: 'UserProfileDropdown',
    components: {
        DropdownMenuList,
    },
    data() {
        return {
            show: false,
            showNotifications: true,
            styleNotification: {},
            data: this.$page.props.notifications || [],
            urlFrom: this.$page.props.urlFrom || {},
        };
    },
    computed: {
        notificationList() {
            const arrList = [];
            const objKeys = {};
            const objToday = new Date();
            const intDay = (1000 * 60 * 60 * 24);
            const objDateMap = {
                0: 'Today',
                1: 'Yesterday',
                2: 'Days',
                7: 'Ago',
            };
            const arrDays = [
                'Sunday',
                'Monday',
                'Tuesday',
                'Wednesday',
                'Thursday',
                'Friday',
                'Saturday',
            ];

            this.data.forEach((objItem) => {
                const objDate = new Date(objItem.created_at);
                const intDaysPassed = Math.round((objToday - objDate) / intDay);
                let strDayText = '';

                Object.keys(objDateMap).forEach((intKey) => {
                    if (parseInt(intKey) <= parseInt(intDaysPassed)) {
                        strDayText = objDateMap[intKey];

                        if (strDayText === 'Days') {
                            strDayText = arrDays[objDate.getMonth()];
                        } else if (strDayText === 'Ago') {
                            strDayText = intDaysPassed + ' Days Ago';
                        }
                    }
                });


                if (objKeys[strDayText] === undefined) {
                    objKeys[strDayText] = arrList.length;
                    arrList.push({
                        name: strDayText,
                        list: [],
                    });
                }

                const objItemSettings = this.getItemOptions(objItem.data);

                if (objItemSettings) {
                    arrList[objKeys[strDayText]].list.push({
                        name: objItem.data.name,
                        small: padStart(objDate.getHours(), 2, 0) + ':' + padStart(objDate.getMinutes(), 2, 0),
                        icon: objItemSettings.icon,
                        href: objItemSettings.url,
                    });
                }
            });

            return arrList;
        },
    },
    created() {
        if (this.urlFrom.via === 'email') {
            this.dismissNotifications(this.urlFrom.notificationID);
        }
    },
    methods: {
        getItemOptions(data) {
            if (!data.type && !data.id) {
                return false;
            }

            const objReturn = {
                icon: 'info-information',
                url: '/',
            };

            switch (data.type) {
                case 'project':
                    objReturn.url = this.$route('projects', data.id);
                    objReturn.icon = 'files';

                    if (data.from === 'destroy') {
                        objReturn.url = this.$route('projects');
                    }

                    break;

                case 'plot':
                    objReturn.url = this.$route('plots', data.id);
                    objReturn.icon = 'plots';

                    if (data.from === 'destroy') {
                        objReturn.url = this.$route('plots');
                    }

                    break;

                case 'rental':
                    objReturn.url = this.$route('rentals', data.id);
                    objReturn.icon = 'plots';

                    if (data.from === 'destroy') {
                        objReturn.url = this.$route('rentals');
                    }

                    break;

                case 'agent':
                    objReturn.url = this.$route('agents', data.id);
                    objReturn.icon = 'files';

                    if (data.from === 'destroy') {
                        objReturn.url = this.$route('agents');
                    }

                    break;

                case 'property':
                    objReturn.url = this.$route('property-developers', data.id);
                    objReturn.icon = 'files';

                    if (data.from === 'destroy') {
                        objReturn.url = this.$route('property-developers');
                    }

                    break;

                case 'report':
                    objReturn.url = this.$route('reports.csv/', data.id);
                    objReturn.icon = 'files';
                    break;

                case 'user':
                    objReturn.url = this.$route('users');
                    objReturn.icon = 'people';
                    break;

                default:
                    break;
            }

            return objReturn;
        },
        closeDropdown() {
            this.show = false;
        },
        dismissNotifications(intNotification) {
            let strUrl = this.$route('notification.read', 'all');

            if (intNotification) {
                strUrl = this.$route('notification.read', intNotification);
            }

            axios
                .post(strUrl)
                .then(response => {
                    if (response.status === 200) {
                        this.closeDropdown();
                        this.showNotifications = false;
                        setTimeout(() => {
                            this.data = [];
                        }, 400);
                    }
                });
        },
    },
};
</script>
