<template>
    <app-layout section="plots">
        <page-header
            title="Land Parcel Details"
            icon="plots"
            :back="{url: $route('plots'), text: 'All Land Parcels'}"
        >
            <div
                v-if="selectedTab === 'information'"
                class="flex-center"
            >
                <button
                    v-if="deletable"
                    class="btn-reset btn btn--red--secondary"
                    @click="deletePlot"
                >
                    <SvgController type="trashcan" />
                    <span>Delete Land Parcel</span>
                </button>
                <inertia-link
                    class="btn-reset btn btn--primary"
                    :class="{'disabled': !editable}"
                    :href="editable ? $route('plot.edit', plot.id) : '#'"
                >
                    Edit Land Parcel
                </inertia-link>
            </div>
            <div
                v-else-if="selectedTab === 'leases'"
                class="flex-center"
            >
                <inertia-link
                    v-if="hasGroup(['sequest', 'lpa']) && hasPermission('rental_save') && plot.site_type === 'Offsite'"
                    class="btn-reset btn btn--primary"
                    :href="$route('rental.create', plot.id)"
                >
                    Create Agreement
                </inertia-link>
            </div>
            <div
                v-else-if="selectedTab === 'habitats'"
                class="flex-center"
            >
                <button
                    v-if="!editingHabitats"
                    :class="{'disabled': !canEditHabitats}"
                    class="btn-reset btn btn--primary"
                    @click="editingHabitats = true"
                >
                    Edit Habitats
                </button>
                <template
                    v-else-if="editingHabitats"
                >
                    <button
                        class="btn-reset btn btn--primary"
                        @click="saveHabitats"
                    >
                        <Processing v-if="uploading">
                            Submitting
                        </Processing>
                        <template v-else>
                            Save Habitats
                        </template>
                    </button>
                    <button
                        class="btn-reset btn"
                        @click="editingHabitats = false"
                    >
                        Cancel
                    </button>
                </template>
            </div>
            <template #page-header-bottom>
                <tabs
                    v-model="selectedTab"
                    :tabs="tabs"
                />
            </template>
        </page-header>
        <div class="center">
            <Alert
                v-if="payment"
                type="info"
            >
                <p>{{ `Based on the information you have provided, your potential annual payment could be &pound;${payment} per annum.` }}</p>
            </Alert>
            <div
                v-if="selectedTab === 'information'"
                class="details"
            >
                <h5>Land Parcel Name</h5>
                <div class="details-header">
                    <h3>{{ plot.name }}</h3>
                    <span
                        class="status-pill"
                        :class="statusFormat"
                    >
                        {{ plot.status }}
                    </span>
                </div>
                <div class="form-flex--lap">
                    <div>
                        <div class="details-block details-bg">
                            <div class="formulate-heading">
                                <h6>Land Parcel Details</h6>
                            </div>
                            <div v-if="plot.unique_reference_number">
                                <h5>Unique Reference Number</h5>
                                <p>{{ plot.unique_reference_number }}</p>
                            </div>
                            <div v-if="plot.offset_type">
                                <h5>Offset Type</h5>
                                <p>{{ offsetTypeString }}</p>
                            </div>
                            <div class="form-flex--tab">
                                <div>
                                    <h5>Size</h5>
                                    <p>{{ plot.size }} {{ plot.size_units }}</p>
                                </div>
                            </div>
                            <div>
                                <h5>Country</h5>
                                <p>{{ plot.country.name }}</p>
                            </div>
                            <div v-if="plot.local_planning_authority">
                                <h5>Planning Authority</h5>
                                <p>{{ plot.local_planning_authority.name }}</p>
                            </div>
                            <div v-if="plot.river_basin_district">
                                <h5>River basin district</h5>
                                <p>{{ plot.river_basin_district.name }}</p>
                            </div>
                            <div v-if="$page.props.loggedUser.isSuperUser && (nationalCharacterAreas || (!nationalCharacterAreas && plot.country_code === 'GB'))">
                                <h5>National Character Areas</h5>
                                <p v-if="nationalCharacterAreas">
                                    {{ nationalCharacterAreas }}
                                </p>
                                <Processing v-else>
                                    Processing
                                </Processing>
                            </div>
                        </div>
                        <div
                            v-if="offsetType.includes('bng')"
                            class="details-block details-bg"
                        >
                            <div class="formulate-heading">
                                <h6>BNG Details</h6>
                            </div>
                            <div v-if="plot.management_style">
                                <h5>Current Management Style</h5>
                                <p>{{ plot.management_style }}</p>
                            </div>
                            <div>
                                <h5>Has an ecological study been carried out in the last 2 years?</h5>
                                <p>{{ study }}</p>
                            </div>
                            <template v-if="plot.study === 1">
                                <div v-if="plot.confirmed_units">
                                    <h5>Confirmed Units</h5>
                                    <p>{{ plot.confirmed_units }}</p>
                                </div>
                                <template v-if="plot.offset_type === 'bng'">
                                    <div v-if="plot.habitat_units">
                                        <h5>Habitat Units</h5>
                                        <p>{{ plot.habitat_units }}</p>
                                    </div>
                                    <div v-if="plot.hedgerow_units">
                                        <h5>Hedgerow Units</h5>
                                        <p>{{ plot.hedgerow_units }}</p>
                                    </div>
                                    <div v-if="plot.river_units">
                                        <h5>River Units</h5>
                                        <p>{{ plot.river_units }}</p>
                                    </div>
                                </template>
                            </template>
                            <template v-else>
                                <div>
                                    <h5>Estimated Units</h5>
                                    <p>{{ plot.potential_estimated_units }}</p>
                                </div>
                            </template>
                            <div v-if="$page.props.loggedUser.isSuperUser && plot.potential_units">
                                <h5>Potential Units</h5>
                                <p>{{ plot.potential_units }}</p>
                            </div>
                        </div>
                        <div
                            v-if="offsetType.includes('nutrient_offset')"
                            class="details-block details-bg"
                        >
                            <div class="formulate-heading">
                                <h6>Nutrient Offset Details</h6>
                            </div>
                            <div v-if="plot.drainage">
                                <h5>Drainage</h5>
                                <p>{{ plot.drainage }}</p>
                            </div>
                            <div v-if="plot.soil_type">
                                <h5>Soil Type</h5>
                                <p>{{ plot.soil_type }}</p>
                            </div>
                            <div v-if="plot.river_running">
                                <h5>Stream or river on the land</h5>
                                <p>{{ plot.river_running ? 'Yes' : 'No' }}</p>
                            </div>
                            <div v-if="plot.livestock">
                                <h5>Livestock</h5>
                                <ul class="lst-reset">
                                    <li
                                        v-for="livestock in plot.livestock_data"
                                        :key="livestock.id"
                                    >
                                        {{ livestockInformation(livestock) }}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="details-block flex flex-wrap align-content-start">
                        <div class="w-full order-1 lap-order-0">
                            <div class="formulate-heading">
                                <h6>Location</h6>
                            </div>
                            <p class="details-location">
                                <SvgController type="map-icon" />{{ plot.address }}
                            </p>
                            <draw-map
                                ref="map"
                                :edit="false"
                                :leases="leases"
                                :show-geo-input="false"
                                :lat-long="plot.location_data.layer.center"
                                :zoom="10"
                                type="plot"
                                show-keys
                                fit-bounds="layers"
                                :layers="mapLayers"
                                :vectors="mapVectors(plot.planning_authority_id, plot.river_basin_district_id)"
                            />
                        </div>
                        <div class="w-full order-0 lap-order-1">
                            <file-label
                                id="documents"
                                label="Documents"
                                text="Upload a document"
                                :disabled="!editable"
                                @click.native="openFileModal('Document')"
                            />
                            <Files
                                :files="documents"
                                filetype="document"
                                :deletable="true"
                            />
                            <alert
                                v-if="!plot.report_uploaded"
                                type="info"
                            >
                                <p>Please upload your ecology report</p>
                            </alert>
                            <file-label
                                id="photos"
                                label="Photos"
                                text="Upload photos"
                                :disabled="!editable"
                                @click.native="openFileModal('Photo')"
                            />
                            <gallery
                                :images="photos"
                                :deletable="true"
                            />
                            <modal ref="fileModal">
                                <template #header>
                                    <h3>Add {{ fileType }}</h3>
                                </template>
                                <template #body>
                                    <add-files
                                        :file-type="fileType"
                                        :document-types="{document: 'Attachment', report: 'Ecology Report'}"
                                        :parent="{
                                            id: $page.props.plot.id,
                                            name: 'plot',
                                            type: 'plot',
                                        }"
                                    />
                                </template>
                            </modal>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else-if="selectedTab === 'agreements'">
                <rentals />
            </div>
            <div v-else-if="selectedTab === 'habitats'">
                <habitats
                    ref="habitats"
                    v-model="editingHabitats"
                    :plot="plot"
                    :lpas="plot.local_planning_authority ? [plot.local_planning_authority] : null"
                    :rivers="plot.river_basin_district ? [plot.river_basin_district] : null"
                    :habitats="habitats"
                    :is-selected="selectedTab === 'habitats'"
                    @submit-start="uploading = true"
                    @submit-complete="uploading = false"
                />
            </div>
        </div>
        <div v-if="selectedTab === 'contracts'">
            <contracts
                :contracts="contracts"
                :parent="{
                    id: $page.props.plot.id,
                    name: $page.props.plot.name,
                    type: 'plot',
                }"
            />
        </div>
    </app-layout>
</template>
<script>
import PlotPages from 'Mixins/plotPages';
import DrawMap from 'Utilities/maps/DrawMap';
import FileLabel from 'Utilities/vue-formulate/FileLabel';
import AddFiles from 'Utilities/uploader/AddFiles.vue';
import Modal from 'Utilities/modal/Modal';
import Alert from 'Utilities/alert/Alert';
import Gallery from 'Utilities/gallery/Gallery';
import Files from 'Utilities/files/Files';
import Rentals from './Rentals';
import Habitats from './Habitats';
import Contracts from './Contracts';
import Processing from 'Utilities/processing/processing';
import Tabs from 'Components/tabs/Tabs';
import { mapColors } from 'Lib/defaults';

export default {
    name: 'PlotShow',
    components: {
        DrawMap,
        FileLabel,
        AddFiles,
        Modal,
        Alert,
        Gallery,
        Files,
        Rentals,
        Habitats,
        Contracts,
        Processing,
        Tabs,
    },
    mixins: [
        PlotPages,
    ],
    props: {
        plot: {
            type: Object,
            required: true,
        },
        habitats: {
            type: Array,
            default: () => [],
        },
        leases: {
            type: Array,
            default: null,
        },
        lpas: {
            type: Array,
            default: () => ([]),
        },
        contracts: {
            type: Array,
            default: () => ([]),
        },
    },
    data() {
        return {
            selectedTab: null,
            tabs: [{
                id: 'information',
                title: 'Land Parcel Information',
                link: route('plot.show', this.plot.id),
                only: [],
            },{
                id: 'agreements',
                title: 'BNG Agreements',
                link: route('plot.show.agreements', {
                    plot: this.plot.id,
                }),
                only: ['leases'],
            },{
                id: 'habitats',
                title: 'Habitats',
                link: route('plot.show.habitats', {
                    plot: this.plot.id,
                }),
                only: ['habitats'],
            },{
                id: 'contracts',
                title: 'Contracts',
                link: route('plot.show.contracts', {
                    plot: this.plot.id,
                }),
                only: ['contracts'],
            }],
            fileType: 'Document',
            editingHabitats: false,
            planningAuthorities: this.plot.local_planning_authority ? [this.plot.local_planning_authority] : null,
            riverBasinDistricts: this.plot.river_basin_district ? [this.plot.river_basin_district] : null,
            uploading: false,
        };
    },
    computed: {
        mapLayers() {
            const layers = [];

            if (this.plot.location_data && this.plot.location_data.layer) {
                layers.push({location_data : this.plot.location_data, fillColor: mapColors.plot});
            }

            return layers;
        },
        statusFormat() {
            return 'type-' + this.plot.status.replace(/\s/g, '_').toLowerCase();
        },
        deletable() {
            return this.$page.props.loggedUser.can.includes('plot_delete') && this.plot.status === 'BNG Units available';
        },
        editable() {
            if (this.hasGroup(['sequest'])) {
                return true;
            }

            return this.$page.props.loggedUser.can.includes('plot_save') && this.plot.status === 'BNG Units available';
        },
        canEditHabitats() {
            if (this.hasGroup(['sequest'])) {
                return true;
            }

            return this.$page.props.loggedUser.can.includes('plot_save');
        },
        study() {
            return this.plot.study === 1 ? 'Yes' : 'No';
        },
        documents() {
            const documents = this.plot.attachments.filter(document => {
                return (document.type === 'document' || document.type === 'report') && document.is_contract < 1;
            });

            return documents;
        },
        photos() {
            const photos = this.plot.attachments.filter(photo => {
                return photo.type === 'photo';
            });

            return photos;
        },
        payment() {
            if (this.plot.size_units === 'hectares') {
                return (this.plot.size * 850).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            }

            return null;
        },
        nationalCharacterAreas() {
            if (!this.plot.national_character_areas) return '';

            return this.plot.national_character_areas.map(national_character_areas => {
                return national_character_areas.name;
            }).join(', ');
        },
        offsetTypeString() {
            return this.plot.offset_type.map(type => type.name).join(', ').replace(/_/g, ' ');
        },
        offsetType() {
            return this.plot.offset_type.map(offset => offset.name);
        },
    },
    methods: {
        deletePlot() {
            this.$confirm(
                {
                    title: 'Delete Land Parcel',
                    message: 'Are you sure you want to delete this land parcel? This action can\'t be undone.',
                    confirmBtnClass: 'btn--red',
                    confirmBtnText: 'Delete',
                    callback: confirm => {
                        if (confirm) {
                            this.$inertia.delete(this.$route('plot.destroy', this.plot.id));
                            this.$confirm.close();
                        }
                    },
                },
            );
        },
        formatNum(num) {
            return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        },
        openFileModal(strFileType) {
            this.fileType = strFileType;
            this.$refs.fileModal.openModal();
        },

        saveHabitats() {
            this.$refs.habitats.submitFormButton();
        },
        livestockInformation(livestock) {
            return `${livestock.name}: ${livestock.min_value === livestock.max_value ? livestock.min_value : `${livestock.min_value} - ${livestock.max_value}`}`;
        },
    },
};
</script>
