var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "app-layout",
    { attrs: { section: "planning-applications" } },
    [
      _c("page-header", {
        attrs: {
          title: "Planning Applications",
          icon: "map",
          count: _vm.applications.total,
        },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "center" },
        [
          _c("TableGridLayout", {
            attrs: {
              columns: _vm.columns,
              rows: _vm.applications.data,
              mode: "remote",
              output: ["table"],
              "total-records": _vm.applications.total,
            },
            on: { onRowClick: _vm.onRowClick },
            scopedSlots: _vm._u(
              [
                {
                  key: "header-left",
                  fn: function () {
                    return [
                      _c("list-filter", {
                        attrs: {
                          fields: _vm.filterOptions,
                          "search-placeholder": "Search Applications",
                          section: "applications",
                        },
                      }),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "cell(lpas)",
                  fn: function ({ row }) {
                    return _vm._l(row.lpas, function (lpa) {
                      return _c(
                        "span",
                        { key: lpa.id, staticClass: "block mb-2" },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(lpa.name) +
                              "\n                "
                          ),
                        ]
                      )
                    })
                  },
                },
                _vm.applications.links
                  ? {
                      key: "footer",
                      fn: function () {
                        return [
                          _c("pagination", {
                            attrs: {
                              links: _vm.applications.links,
                              section: "applications",
                            },
                          }),
                        ]
                      },
                      proxy: true,
                    }
                  : null,
              ],
              null,
              true
            ),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }