var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "vue-formulate-drawmap" },
    [
      _c(
        "draw-map",
        _vm._b(
          {
            key: "map",
            ref: "map",
            attrs: { type: _vm.mapType, edit: "" },
            model: {
              value: _vm.context.model,
              callback: function ($$v) {
                _vm.$set(_vm.context, "model", $$v)
              },
              expression: "context.model",
            },
          },
          "draw-map",
          _vm.context.attributes,
          false
        )
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }