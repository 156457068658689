var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "collapsable-group-item",
      attrs: { "data-is-open": _vm.context.index === _vm.showIndex },
    },
    [
      _c(
        "div",
        {
          staticClass: "collapsable-group-item-title",
          on: { click: _vm.toggle },
        },
        [
          _c("SvgController", { attrs: { type: "caret-down" } }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "collapsable-group-item-title-text" },
            [
              _vm._t(
                "title",
                function () {
                  return [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.title) +
                        " #" +
                        _vm._s(_vm.context.index + 1) +
                        "\n            "
                    ),
                  ]
                },
                { index: _vm.context.index }
              ),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            _vm.context.slotComponents.remove,
            _vm._b(
              {
                tag: "component",
                attrs: {
                  context: _vm.context,
                  index: _vm.context.index,
                  "remove-item": _vm.removeItem,
                },
              },
              "component",
              _vm.context.slotProps.remove,
              false
            )
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.context.index === _vm.showIndex,
              expression: "context.index === showIndex",
            },
          ],
          staticClass: "collapsable-group-item-body",
        },
        [_vm._t("default", null, { index: _vm.context.index })],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }