var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "button",
    {
      class: _vm.btnClass,
      attrs: { title: _vm.title },
      on: {
        click: function ($event) {
          $event.preventDefault()
          return _vm.$emit("click")
        },
      },
    },
    [
      _vm._t("default", function () {
        return [
          _c("SvgController", {
            style: _vm.iconStyle,
            attrs: { type: _vm.icon },
          }),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }