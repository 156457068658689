<template>
    <app-layout section="projects">
        <page-header
            :title="title"
            icon="files"
            :count="projects.total"
        >
            <inertia-link
                v-if="$page.props.loggedUser.can.includes('project_save') && !$page.props.loggedUser.isLPA"
                class="btn-reset btn btn--primary"
                :href="$route('project.create')"
            >
                <SvgController type="plus" />
                <span>New Development</span>
            </inertia-link>
        </page-header>
        <div class="center">
            <project-table
                :projects="projects"
                :statuses="$page.props.statuses"
                :force-hide-company="isPropertyDeveloperPage"
            >
                <template #cell(study)="{row}">
                    <span>
                        {{ studyStatus(row.study, row.study_requested, row.contacted) }}
                    </span>
                </template>
            </project-table>
        </div>
    </app-layout>
</template>
<script>

import ProjectTable from './Table';
export default {
    name: 'Projects',
    components: {
        ProjectTable,
    },
    props: {
        projects: {
            type: Object,
            default: () => ({}),
        },
        companyName: {
            type: String,
            default: null,
        },
    },
    computed: {
        filterRoute() {
            if (this.isPropertyDeveloperPage) {
                return this.$route('propertyDeveloper.projects', this.$route().params.propertyDeveloper);
            }

            return this.$route('projects');
        },

        isPropertyDeveloperPage() {
            return this.$route().current('propertyDeveloper.projects');
        },

        title() {
            let title = '';

            if (this.companyName) {
                title = `${this.companyName} - `;
            }

            title += 'Developments';

            return title;
        },
    },
    methods: {
        studyStatus(studied, studyRequested, contacted) {
            if (studied) {
                return 'Completed';
            } else {
                if (studyRequested) {
                    if (contacted) {
                        return 'Contacted';
                    } else {
                        return 'Requested';
                    }
                } else {
                    return 'Not Completed';
                }
            }
        },
    },
};
</script>
