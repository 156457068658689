var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "tooltip",
      staticClass: "tooltip tooltip--center",
      class: {
        "tooltip--hovered": _vm.hovered,
      },
    },
    [
      _c("div", { staticClass: "tooltip--content" }, [
        _vm._v("\n        " + _vm._s(_vm.content) + "\n    "),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }