<template>
    <ul class="lst-reset dropdown-menu-list">
        <dropdownMenuItem
            v-for="(item, index) in data"
            :key="index"
            :show="item.show"
            :target-id="item.id"
            :name="item.name"
            :small="item.small"
            :icon="item.icon"
            :href="item.href"
            :method="item.method"
            :active="item.active"
            :border="item.border"
            :color="item.color"
            :svg-color="item.svgColor"
            :on-click="item.onClick"
        />
    </ul>
</template>
<script>
import dropdownMenuItem from './DropdownMenuItem';
export default {
    name: 'DropdownMenuList',
    components: {
        dropdownMenuItem,
    },
    props: {
        /* data: [
            {
                name: 'STRING',
                small: 'STRING',
                icon: 'STRING',
                active: 'BOOLEAN',
                href: 'STRING',
                method: 'STRING',
                border: BOOL,
                color: 'STRING',
                svgColor: 'STRING',
                onClick: 'function',
                show: 'BOOL',
            }
        ] */
        data: {
            type: Array,
            default() {
                return [];
            },
        },
    },
};
</script>
