var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "app-layout",
    { attrs: { section: "habitats" } },
    [
      _c(
        "page-header",
        { attrs: { title: _vm.pageTitle, icon: "plots", background: false } },
        [
          _c(
            "a",
            {
              staticClass: "btn-reset btn",
              attrs: { href: _vm.$route("map") },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.closeForm.apply(null, arguments)
                },
              },
            },
            [_vm._v("\n            Cancel\n        ")]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn-reset btn btn--primary",
              attrs: { id: "form-submit" },
              on: { click: _vm.submitFormButton },
            },
            [
              _vm.uploading
                ? _c("Processing", [
                    _vm._v("\n                Submitting\n            "),
                  ])
                : [
                    !_vm.edit
                      ? _c("SvgController", { attrs: { type: "tick" } })
                      : _vm._e(),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.buttonText) +
                          "\n                "
                      ),
                    ]),
                  ],
            ],
            2
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "center" },
        [
          _c("HabitatFormComponent", {
            ref: "habitatForm",
            attrs: {
              habitat: _vm.habitat,
              edit: _vm.edit,
              lpas: _vm.getLpas,
              rivers: _vm.rivers,
              "save-id": _vm.owner.id,
              "save-type": "habitat",
              habitats: _vm.habitats,
            },
            on: {
              "submit-start": function ($event) {
                _vm.uploading = true
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }