var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.loaded,
          expression: "loaded",
        },
      ],
      staticClass: "map-select",
      class: {
        "map-select--disabled": _vm.disabled,
        "map-select--fullscreen": _vm.fullScreenEnabled,
      },
    },
    [
      _vm.showGeoInput
        ? _c(
            "div",
            { staticClass: "map-select-geo" },
            [
              _c("SvgController", { attrs: { type: "map-icon" } }),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.data.inputGeo,
                    expression: "data.inputGeo",
                  },
                ],
                ref: "geoInput",
                staticClass: "map-select-geo-input",
                attrs: { type: "text", placeholder: "Address" },
                domProps: { value: _vm.data.inputGeo },
                on: {
                  keyup: _vm.setGeoLocationKey,
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.data, "inputGeo", $event.target.value)
                  },
                },
              }),
              _vm._v(" "),
              _c(
                "button",
                {
                  class: `btn-reset map-select-geo-button ${
                    _vm.geoInputEnabled ? " active" : ""
                  }`,
                  attrs: { type: "button", title: "Get Location" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.toggleGeoLocation.apply(null, arguments)
                    },
                  },
                },
                [_c("SvgController", { attrs: { type: "location-arrow" } })],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "map-select-wrapper",
          class: { "map-select-wrapper--habitat": _vm.type === "habitat" },
        },
        [
          _vm.edit
            ? [
                _c(
                  "google-map-autocomplete",
                  {
                    ref: "autoComplete",
                    attrs: {
                      placeholder: "Search",
                      "update-map": false,
                      controls: false,
                    },
                    on: { "place-changed": _vm.placeChanged },
                  },
                  [
                    _c(
                      "template",
                      { slot: "after-input" },
                      [_c("SvgController", { attrs: { type: "search" } })],
                      1
                    ),
                  ],
                  2
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "map-select-header flex-space-between" },
                  [
                    _c("div", { staticClass: "map-select-header-left" }),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "map-select-header-right flex" },
                      [
                        !_vm.disabled
                          ? [
                              _vm.message
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "map-select-header-right-group",
                                    },
                                    [_c("p", [_vm._v(_vm._s(_vm.message))])]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.editType === "shape"
                                ? [
                                    _vm.showLayersEnabled
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "map-select-header-right-group flex",
                                          },
                                          [
                                            _c(
                                              "map-button",
                                              {
                                                attrs: {
                                                  title: "Toggle Habitats",
                                                  name: "habitatToggle",
                                                  active: _vm.showLayers,
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    _vm.showLayers =
                                                      !_vm.showLayers
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                    " +
                                                    _vm._s(_vm.habitatBtnText) +
                                                    "\n                                "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "map-select-header-right-group flex",
                                      },
                                      [
                                        _c("map-button", {
                                          attrs: {
                                            disabled: _vm.historyIndex === 1,
                                            title: "Undo",
                                            name: "undo",
                                            icon: "undo",
                                          },
                                          on: { click: _vm.undo },
                                        }),
                                        _vm._v(" "),
                                        _c("map-button", {
                                          attrs: {
                                            disabled:
                                              _vm.history.length ===
                                              _vm.historyIndex,
                                            title: "Redo",
                                            name: "redo",
                                            icon: "redo",
                                          },
                                          on: { click: _vm.redo },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "map-select-header-right-group flex",
                                      },
                                      [
                                        _c("map-button", {
                                          attrs: {
                                            active: _vm.clickAction === "pan",
                                            title: "Pan",
                                            name: "pan",
                                            icon: "hand",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.setAction("pan")
                                            },
                                          },
                                        }),
                                        _vm._v(" "),
                                        _vm.type === "lease" &&
                                        _vm.landParcels[0].location_data &&
                                        !Object.keys(_vm.data).length
                                          ? _c("map-button", {
                                              attrs: {
                                                title: "Duplicate Area",
                                                name: "duplicate_area",
                                                icon: "plus",
                                                "icon-style": {
                                                  fontSize: "0.75em",
                                                },
                                              },
                                              on: { click: _vm.duplicateArea },
                                            })
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _c(
                                          "DropdownMenu",
                                          {
                                            attrs: {
                                              "dropdown-menu-class":
                                                "dropdown-menu--map",
                                              trigger: "hover",
                                            },
                                            model: {
                                              value: _vm.show,
                                              callback: function ($$v) {
                                                _vm.show = $$v
                                              },
                                              expression: "show",
                                            },
                                          },
                                          [
                                            _c("map-button", {
                                              attrs: {
                                                active:
                                                  _vm.clickAction === "draw",
                                                title: "Add Shape",
                                                name: "add",
                                                icon: _vm.selectedIcon,
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                attrs: { slot: "dropdown" },
                                                slot: "dropdown",
                                              },
                                              [
                                                _c("map-button", {
                                                  attrs: {
                                                    active:
                                                      _vm.clickAction ===
                                                        "draw" &&
                                                      _vm.currentShape ===
                                                        "polygon",
                                                    title: "Polygon",
                                                    name: "add",
                                                    icon: "draw-polygon",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.setAction(
                                                        "draw",
                                                        "polygon"
                                                      )
                                                    },
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c("map-button", {
                                                  attrs: {
                                                    active:
                                                      _vm.clickAction ===
                                                        "draw" &&
                                                      _vm.currentShape ===
                                                        "circle",
                                                    title: "Circle",
                                                    name: "add",
                                                    icon: "draw-circle",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.setAction(
                                                        "draw",
                                                        "circle"
                                                      )
                                                    },
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c("map-button", {
                                                  attrs: {
                                                    active:
                                                      _vm.clickAction ===
                                                        "draw" &&
                                                      _vm.currentShape ===
                                                        "rectangle",
                                                    title: "Rectangle",
                                                    name: "add",
                                                    icon: "draw-square",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.setAction(
                                                        "draw",
                                                        "rectangle"
                                                      )
                                                    },
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c("map-button", {
                                          attrs: {
                                            active: _vm.clickAction === "edit",
                                            title: "Edit",
                                            name: "edit",
                                            icon: "edit",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.setAction("edit")
                                            },
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("map-button", {
                                          attrs: {
                                            active:
                                              _vm.clickAction === "delete",
                                            title: "Delete",
                                            name: "delete",
                                            icon: "eraser",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.setAction("delete")
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                : _vm.editType === "circle"
                                ? [
                                    _vm.showLayersEnabled
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "map-select-header-right-group flex",
                                          },
                                          [
                                            _c(
                                              "map-button",
                                              {
                                                attrs: {
                                                  title: "Toggle Habitats",
                                                  name: "habitatToggle",
                                                  active: _vm.showLayers,
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    _vm.showLayers =
                                                      !_vm.showLayers
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                    " +
                                                    _vm._s(_vm.habitatBtnText) +
                                                    "\n                                "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "map-select-header-right-group flex",
                                      },
                                      [
                                        _c("map-button", {
                                          attrs: {
                                            disabled: _vm.historyIndex === 1,
                                            title: "Undo",
                                            name: "undo",
                                            icon: "undo",
                                          },
                                          on: { click: _vm.undo },
                                        }),
                                        _vm._v(" "),
                                        _c("map-button", {
                                          attrs: {
                                            disabled:
                                              _vm.history.length ===
                                              _vm.historyIndex,
                                            title: "Redo",
                                            name: "redo",
                                            icon: "redo",
                                          },
                                          on: { click: _vm.redo },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "map-select-header-right-group flex",
                                      },
                                      [
                                        _c("map-button", {
                                          attrs: {
                                            active: _vm.clickAction === "pan",
                                            title: "Pan",
                                            name: "pan",
                                            icon: "hand",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.setAction("pan")
                                            },
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("map-button", {
                                          attrs: {
                                            active:
                                              _vm.clickAction === "draw" &&
                                              _vm.currentShape === "circle",
                                            title: "Circle",
                                            name: "add",
                                            icon: "plus",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.setAction(
                                                "draw",
                                                "circle",
                                                1.5
                                              )
                                            },
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("map-button", {
                                          attrs: {
                                            active:
                                              _vm.clickAction === "delete",
                                            title: "Delete",
                                            name: "delete",
                                            icon: "eraser",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.setAction("delete")
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.editType === "marker" ||
                              _vm.editType === "circle" ||
                              _vm.editType === "shape"
                                ? [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "map-select-header-right-group",
                                      },
                                      [
                                        _c("map-button", {
                                          attrs: {
                                            active: _vm.fullScreenEnabled,
                                            title: "Toggle FullScreen",
                                            name: "fullScreen",
                                            icon: "expand",
                                          },
                                          on: {
                                            click: function ($event) {
                                              _vm.fullScreenEnabled =
                                                !_vm.fullScreenEnabled
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                : _vm._e(),
                            ]
                          : _vm.disabledMessage
                          ? _c(
                              "div",
                              { staticClass: "map-select-header-right-group" },
                              [_c("p", [_vm._v(_vm._s(_vm.disabledMessage))])]
                            )
                          : _vm._e(),
                      ],
                      2
                    ),
                  ]
                ),
              ]
            : _vm._e(),
          _vm._v(" "),
          _c(
            "google-map",
            {
              ref: "mapRef",
              attrs: {
                zoom: _vm.zoom,
                options: _vm.mapOptions,
                center: _vm.center,
                tilt: 0,
              },
              on: {
                ready: _vm.mapReady,
                click: function ($event) {
                  return _vm.mapClick($event, "map")
                },
              },
            },
            [
              _vm.developments.length > 0
                ? [
                    _vm._l(_vm.developments, function (development, index) {
                      return [
                        development.location_data.layer
                          ? [
                              _c("shape", {
                                key: `development-${index}`,
                                ref: "developments",
                                refInFor: true,
                                attrs: {
                                  shape: development.location_data.layer,
                                  item: development,
                                  type: "project",
                                  "z-index": 2,
                                },
                                on: { click: _vm.showInfoBox },
                              }),
                              _vm._v(" "),
                              _vm.showMarkers
                                ? [
                                    _c("google-map-marker", {
                                      key: `development-marker-${index}`,
                                      attrs: {
                                        icon: _vm.getMapIcon("project"),
                                        position:
                                          development.location_data.layer
                                            .center,
                                        "z-index": 3,
                                        clickable: _vm.popupShapeClick,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.showInfoBox(
                                            development,
                                            "project-legacy"
                                          )
                                        },
                                      },
                                    }),
                                  ]
                                : _vm._e(),
                            ]
                          : _c("google-map-marker", {
                              key: `developments-${index}`,
                              ref: "developments",
                              refInFor: true,
                              attrs: {
                                icon: _vm.getMapIcon(),
                                position: development.location_data,
                                "z-index": 5,
                                clickable: _vm.popupShapeClick,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.showInfoBox(
                                    development,
                                    "project-legacy"
                                  )
                                },
                              },
                            }),
                      ]
                    }),
                  ]
                : _vm._e(),
              _vm._v(" "),
              _vm.computedLandParcels
                ? [
                    _vm._l(
                      _vm.computedLandParcels,
                      function (landParcel, index) {
                        return _c("shape", {
                          key: `landParcel-${index}`,
                          ref: "landParcels",
                          refInFor: true,
                          attrs: {
                            shape: landParcel.location_data.layer,
                            item: landParcel,
                            type: "plot",
                            "z-index": 2,
                          },
                          on: { click: _vm.showInfoBox },
                        })
                      }
                    ),
                    _vm._v(" "),
                    _vm.showMarkers
                      ? _vm._l(_vm.landParcels, function (landParcel, index) {
                          return _c("google-map-marker", {
                            key: `landParcel-marker-${index}`,
                            attrs: {
                              icon: _vm.getMapIcon("plotMarker"),
                              position: landParcel.location_data.layer.center,
                              "z-index": 3,
                              clickable: _vm.popupShapeClick,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.showInfoBox(landParcel, "landParcel")
                              },
                            },
                          })
                        })
                      : _vm._e(),
                  ]
                : _vm._e(),
              _vm._v(" "),
              _vm.computedLeases.length > 0
                ? _vm._l(_vm.computedLeases, function (lease, index) {
                    return _c("shape", {
                      key: `lease-${index}`,
                      ref: "leases",
                      refInFor: true,
                      attrs: {
                        shape: lease.location_data.layer,
                        item: lease,
                        type: "lease",
                        "z-index": 4,
                      },
                      on: { click: _vm.showInfoBox },
                    })
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.computedLandParcelHabitats
                ? [
                    _vm._l(
                      _vm.computedLandParcelHabitats,
                      function (habitat, index) {
                        return [
                          _c("shape", {
                            key: `habitat-${habitat.id}-${index}`,
                            ref: "habitatArea",
                            refInFor: true,
                            attrs: {
                              type: "habitat",
                              "habitat-type": habitat.type,
                              "z-index": 2,
                              shape: habitat.location_data.layer,
                              item: habitat,
                            },
                            on: { click: _vm.showInfoBox },
                          }),
                        ]
                      }
                    ),
                  ]
                : _vm._e(),
              _vm._v(" "),
              _vm.showLayers && _vm.layers.length && _vm.computedHabitats
                ? [
                    _vm._l(_vm.computedHabitats, function (habitat, index) {
                      return [
                        habitat.location_data &&
                        habitat.location_data.layer &&
                        habitat.location_data.layer.shape === "circle"
                          ? _vm._l(
                              _vm.parseCircleList(habitat.location_data.layer),
                              function (point, locIndex) {
                                return _c("MapLayer", {
                                  key: `map-layer-${index}-${locIndex}`,
                                  attrs: {
                                    index: locIndex,
                                    tmp: habitat.location_data.layer.shape,
                                    layer: {
                                      ...habitat,
                                      location_data: { layer: point },
                                    },
                                    multiple:
                                      _vm.parseCircleList(
                                        habitat.location_data.layer
                                      ).length > 1,
                                  },
                                })
                              }
                            )
                          : [
                              _c("MapLayer", {
                                key: `map-layer-${index}`,
                                attrs: { layer: habitat },
                              }),
                            ],
                      ]
                    }),
                  ]
                : _vm._e(),
              _vm._v(" "),
              _vm.edit && _vm.editType === "shape"
                ? [
                    _vm.isMapReady && _vm.data.layer
                      ? _c("shape", {
                          key: "editShape",
                          ref: "editShape",
                          attrs: {
                            editable:
                              _vm.clickAction === "edit" ||
                              _vm.clickAction === "delete",
                            draggable: !_vm.disabled,
                            shape: _vm.data.layer,
                            "z-index": 5,
                            type: _vm.type,
                            item: _vm.item,
                            "habitat-type": _vm.habitatType,
                            "click-action": _vm.clickAction,
                            "fill-color": _vm.getEditFillColor.fillColor,
                            theme: _vm.getEditFillColor.theme,
                          },
                          on: { layerUpdated: _vm.layerUpdated },
                        })
                      : _vm._e(),
                  ]
                : _vm.edit && _vm.editType === "marker"
                ? [
                    _vm.data.points
                      ? _c("google-map-marker", {
                          attrs: {
                            icon: _vm.getMapIcon(),
                            draggable: !_vm.disabled,
                            position: _vm.data.points,
                          },
                        })
                      : _vm._e(),
                  ]
                : _vm.edit && _vm.editType === "circle"
                ? [
                    !_vm.multiple
                      ? [
                          _vm.data.layer && _vm.data.layer.points
                            ? _c("google-map-marker", {
                                attrs: {
                                  icon: _vm.getMapIcon(),
                                  draggable: !_vm.disabled,
                                  position: _vm.data.layer.points,
                                },
                              })
                            : _vm._e(),
                        ]
                      : _vm.data.layer && _vm.data.layer.points
                      ? _vm._l(_vm.data.layer.points, function (point, index) {
                          return _c("shape", {
                            key: `${point.lat},${point.lng}`,
                            ref: "circles",
                            refInFor: true,
                            attrs: {
                              type: "habitat",
                              editable: _vm.edit && !point.radius,
                              multiple: _vm.multiple,
                              draggable: !_vm.disabled,
                              "click-action": _vm.clickAction,
                              shape: _vm.parseCircle(point),
                              "z-index": 2,
                              "shape-index": index,
                              "fill-color": _vm.getEditFillColor.fillColor,
                              theme: _vm.getEditFillColor.theme,
                            },
                            on: {
                              layerUpdated: _vm.updateMultipleShape,
                              deleteShape: _vm.deleteMultipleShape,
                            },
                          })
                        })
                      : _vm._e(),
                  ]
                : _vm._e(),
              _vm._v(" "),
              _c(
                "google-map-infowindow",
                {
                  attrs: {
                    show: _vm.showInfo,
                    position: _vm.infoWindowContext.position,
                  },
                  on: {
                    "update:show": function ($event) {
                      _vm.showInfo = $event
                    },
                  },
                },
                [_c("info-box", { attrs: { item: _vm.infoWindowContext } })],
                1
              ),
              _vm._v(" "),
              _vm._l(_vm.vectors, function (vector) {
                return _c("vector", {
                  key: vector.id || vector.url,
                  ref: "vectorList",
                  refInFor: true,
                  attrs: {
                    "vector-url": vector.url,
                    "attributes-url": vector.attributesUrl,
                    background: vector.background,
                    "background-opacity": vector.backgroundOpacity,
                    "line-width": vector.lineWidth,
                    stroke: vector.stroke,
                    "stroke-opacity": vector.strokeOpacity,
                    filter: vector.filter,
                  },
                  on: {
                    ready: _vm.vectorLoad,
                    filtered: _vm.calculateBoundsChange,
                  },
                })
              }),
            ],
            2
          ),
          _vm._v(" "),
          _vm.showKeys
            ? _c("div", { staticClass: "map-select-keys" }, [
                _c(
                  "ul",
                  { staticClass: "lst-reset flex" },
                  _vm._l(_vm.keys, function (key) {
                    return _c(
                      "li",
                      {
                        key: `map-keys-${key.type}`,
                        staticClass: "flex-center",
                      },
                      [
                        _c("div", {
                          style: `color:${key.color};background:${key.color};`,
                        }),
                        _vm._v(" " + _vm._s(key.title) + "\n                "),
                      ]
                    )
                  }),
                  0
                ),
              ])
            : _vm._e(),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }