<template>
    <auth-layout :component="$options.name">
        <template #button>
            <span>Don't have an account?</span>
            <button
                type="button"
                class="btn btn--primary"
                @click="$inertia.visit($route('register'))"
            >
                Sign up
            </button>
        </template>
        <FormulateForm
            :errors="form.errors"
            @submit="onSubmit"
        >
            <div class="form-page">
                <h3>{{ $options.name }}</h3>
                <FormulateInput
                    v-model="form.email"
                    type="email"
                    name="email"
                    label="Email"
                    validation="required|email"
                />
                <FormulateInput
                    v-model="form.password"
                    type="password"
                    name="password"
                    label="Password"
                />
                <div class="formulate-input">
                    <inertia-link :href="$route('password.request')">
                        Forgot your password?
                    </inertia-link>
                </div>
                <div v-if="flash.status === 'verification-link-sent'">
                    <p>A new verification link has been sent to the email address you provided during registration.</p>
                </div>
                <FormulateInput
                    type="submit"
                    label="Login"
                />
            </div>
        </FormulateForm>
    </auth-layout>
</template>

<script>
import AuthLayout from 'Components/Layouts/AuthLayout.vue';
export default {
    name: 'Login',
    components: {
        AuthLayout,
    },
    props: {
        flash: {
            type: Object,
            required: true,
        },
        errors: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            form: this.$inertia.form({
                email: null,
                password: null,
            }),
        };
    },
    methods: {
        onSubmit() {
            this.form.post(this.$route('login'));
        },
    },
};
</script>
