const mapThemes = {
    dimonds: {
        type: 'dimonds',
        size: 7,
    },
    circles: {
        type: 'circles',
    },
    lines: {
        type: 'lines',
        size: 10,
    },
    linesVertical: {
        type: 'lines',
        rotate: '90',
        size: 10,
    },
    lines45: {
        type: 'lines',
        rotate: '45',
        size: 10,
    },
    'lines-45': {
        type: 'lines',
        rotate: '-45',
        size: 10,
    },
    'squares': {
        type: 'squares',
        size: 10,
    },
};

const getTheme = (mapThemeName, options) => {
    let theme = mapThemes[mapThemeName];

    if (options && theme) {
        theme = {...theme, ...options};
    }

    return theme;
};

const getSVG = (theme, id = Math.floor(Math.random() * 1000)) => {
    let objects = null;

    if (theme) {
        const svgns_ = 'http://www.w3.org/2000/svg';

        objects = {};

        //create the svg element
        objects.svg = document.createElementNS(svgns_, 'svg');
        objects.svg.setAttributeNS(null, 'preserveAspectRatio', 'xMidYMid meet');
        objects.svg.style.width = '100%';
        objects.svg.style.height = '100%';

        objects.def = document.createElementNS(svgns_, 'defs');

        //create the pattern fill
        objects.pattern = document.createElementNS(svgns_, 'pattern');
        const sizeW = parseFloat(theme.width || theme.size || '1');
        const sizeH = parseFloat(theme.height || theme.size || '1');
        const radius = parseFloat(theme.size || '2');
        const space = theme.space ? parseFloat(theme.space) : null;

        objects.pattern.setAttributeNS(null, 'id', `fillPattern-${id}`);
        objects.pattern.setAttributeNS(null, 'patternUnits', 'userSpaceOnUse');
        objects.pattern.setAttributeNS(null, 'patternTransform', `rotate(${theme.rotate || '0'})`);
        objects.pattern.setAttributeNS(null, 'width', sizeW);
        objects.pattern.setAttributeNS(null, 'height', sizeH);
        objects.def.appendChild(objects.pattern);

        const inner = {};

        switch (theme.type) {
            case 'circles':
                if (!theme.width) objects.pattern.setAttributeNS(null, 'width', radius * 4);
                if (!theme.height) objects.pattern.setAttributeNS(null, 'height', radius * 4);

                inner.circle = document.createElementNS(svgns_, 'circle');
                inner.circle.setAttributeNS(null, 'id', `fillPatternInner-${id}`);
                inner.circle.setAttributeNS(null, 'fill', theme.stroke);
                inner.circle.setAttributeNS(null, 'fill-opacity', '1');
                inner.circle.setAttributeNS(null, 'cx', radius);
                inner.circle.setAttributeNS(null, 'cy', radius);
                inner.circle.setAttributeNS(null, 'r', radius);

                inner.circle2 = document.createElementNS(svgns_, 'circle');
                inner.circle2.setAttributeNS(null, 'id', `fillPatternInner-${id}`);
                inner.circle2.setAttributeNS(null, 'fill', theme.stroke2 ? theme.stroke2 : theme.stroke);
                inner.circle2.setAttributeNS(null, 'fill-opacity', '1');
                inner.circle2.setAttributeNS(null, 'cx', space ? space : radius * 3);
                inner.circle2.setAttributeNS(null, 'cy', space ? space : radius * 3);
                inner.circle2.setAttributeNS(null, 'r', radius);
                objects.pattern.appendChild(inner.circle);
                objects.pattern.appendChild(inner.circle2);

                break;

            case 'lines':
                inner.line = document.createElementNS(svgns_, 'rect');
                inner.line.setAttributeNS(null, 'id', `fillPatternInner-${id}`);
                inner.line.setAttributeNS(null, 'fill', 'transparent');
                inner.line.setAttributeNS(null, 'stroke', theme.stroke || '#000');
                inner.line.setAttributeNS(null, 'stroke-width', theme.strokeWidth || '1');
                inner.line.setAttributeNS(null, 'stroke-dasharray', `${sizeW},${sizeH}`);
                inner.line.setAttributeNS(null, 'width', sizeW);
                inner.line.setAttributeNS(null, 'height', sizeH);
                objects.pattern.appendChild(inner.line);

                break;

            case 'dimonds':
                if (!theme.width) objects.pattern.setAttributeNS(null, 'width', radius * 2.5);
                if (!theme.height) objects.pattern.setAttributeNS(null, 'height', radius * 1.5);

                inner.rect = document.createElementNS(svgns_, 'rect');
                inner.rect.setAttributeNS(null, 'id', `fillPatternInner-${id}`);
                inner.rect.setAttributeNS(null, 'fill', theme.stroke || '#000');
                inner.rect.setAttributeNS(null, 'stroke-width', '0');
                inner.rect.setAttributeNS(null, 'transform', 'rotate(45)');
                inner.rect.setAttributeNS(null, 'width', sizeW);
                inner.rect.setAttributeNS(null, 'height', sizeH);
                inner.rect.setAttributeNS(null, 'x', (sizeW / 2));
                inner.rect.setAttributeNS(null, 'y', (sizeH / 2) * -1);
                objects.pattern.appendChild(inner.rect);

                break;

            case 'squares':
                if (!theme.width) objects.pattern.setAttributeNS(null, 'width', sizeW);
                if (!theme.height) objects.pattern.setAttributeNS(null, 'height', sizeH);

                inner.rect = document.createElementNS(svgns_, 'rect');
                inner.rect.setAttributeNS(null, 'id', `fillPatternInner-${id}`);
                inner.rect.setAttributeNS(null, 'fill', theme.fill || '#000');
                inner.rect.setAttributeNS(null, 'stroke-width', '0');
                inner.rect.setAttributeNS(null, 'width', sizeW / 2);
                inner.rect.setAttributeNS(null, 'height', sizeH / 2);
                inner.rect.setAttributeNS(null, 'x', 0);
                inner.rect.setAttributeNS(null, 'y', 0);
                objects.pattern.appendChild(inner.rect);

                inner.rect2 = document.createElementNS(svgns_, 'rect');
                inner.rect2.setAttributeNS(null, 'id', `fillPatternInner2-${id}`);
                inner.rect2.setAttributeNS(null, 'fill', theme.stroke || '#000');
                inner.rect2.setAttributeNS(null, 'stroke-width', '0');
                inner.rect2.setAttributeNS(null, 'width', sizeW / 2);
                inner.rect2.setAttributeNS(null, 'height', sizeH / 2);
                inner.rect2.setAttributeNS(null, 'x', sizeH / 2);
                inner.rect2.setAttributeNS(null, 'y', sizeH / 2);
                objects.pattern.appendChild(inner.rect2);

                break;

            default:
                break;
        }

        objects.inner = inner;
        objects.svg.appendChild(objects.def);

        //add path to the div
        objects.path = document.createElementNS(svgns_, 'path');

        objects.path.setAttributeNS(null, 'fill', `url(#fillPattern-${id})`);
        objects.path.setAttributeNS(null, 'stroke-width', '0');

        objects.pathBg = document.createElementNS(svgns_, 'path');

        objects.pathBg.setAttributeNS(null, 'fill', theme.fill);
        objects.pathBg.setAttributeNS(null, 'fill-opacity', theme.fillOpacity !== undefined ? theme.fillOpacity : '0.6');
        objects.pathBg.setAttributeNS(null, 'stroke-width', '0');
        objects.svg.appendChild(objects.pathBg);
        objects.svg.appendChild(objects.path);
    }

    return objects;
};

export default mapThemes;
export { mapThemes, getTheme, getSVG };
