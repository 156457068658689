var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "app-layout",
    { attrs: { section: "profile" } },
    [
      _c(
        "page-header",
        { attrs: { title: "Edit Profile", icon: "user", background: false } },
        [
          _c(
            "inertia-link",
            { staticClass: "btn", attrs: { href: _vm.$route("profile.show") } },
            [_vm._v("\n            Cancel\n        ")]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn-reset btn btn--primary",
              attrs: { id: "form-submit" },
              on: { click: _vm.submitForm },
            },
            [
              _c("SvgController", { attrs: { type: "tick" } }),
              _vm._v(" "),
              _c("span", [_vm._v("Save")]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "center" },
        [
          _vm.flash.status === "profile-information-updated"
            ? _c("alert", { attrs: { type: "success", inline: true } }, [
                _c("p", [_vm._v("Profile updated successfully.")]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "profile-info" },
            [
              _c(
                "FormulateForm",
                {
                  staticClass: "flex-col",
                  attrs: { errors: _vm.form.errors },
                  on: { submit: _vm.submitForm },
                  model: {
                    value: _vm.form,
                    callback: function ($$v) {
                      _vm.form = $$v
                    },
                    expression: "form",
                  },
                },
                [
                  _c(
                    "div",
                    [
                      _c("FormulateInput", {
                        attrs: {
                          type: "text",
                          name: "first_name",
                          label: "First Name",
                          validation: "required",
                          "validation-name": "First Name",
                        },
                      }),
                      _vm._v(" "),
                      _c("FormulateInput", {
                        attrs: {
                          type: "text",
                          name: "last_name",
                          label: "Surname",
                          validation: "required",
                          "validation-name": "Surname",
                        },
                      }),
                      _vm._v(" "),
                      _c("FormulateInput", {
                        attrs: {
                          type: "email",
                          name: "email",
                          label: "Email Address",
                          validation: "required|email",
                          "validation-name": "Email address",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }