<template>
    <li
        v-show="show"
        :class="className"
        :name="nameSafe"
    >
        <inertia-link
            v-if="href"
            class="btn-reset dropdown-menu-list-item-button"
            :href="href"
            :method="method"
            as="button"
            type="button"
        >
            <span>
                <SvgController
                    v-if="icon"
                    :type="icon"
                />
                {{ name }}
            </span>
            <small v-if="small">{{ small }}</small>
        </inertia-link>
        <button
            v-else
            class="btn-reset dropdown-menu-list-item-button"
            type="button"
            @click="onClick"
        >
            <span>
                <SvgController
                    v-if="icon"
                    :type="icon"
                />
                {{ name }}
            </span>
            <small v-if="small">{{ small }}</small>
        </button>
    </li>
</template>
<script>
export default {
    name: 'DropdownMenuItem',
    props: {
        name: {
            type: String,
            default: 'No Value',
        },
        small: {
            type: String,
            default: null,
        },
        icon: {
            type: String,
            default: null,
        },
        active: {
            type: Boolean,
            default: false,
        },
        href: {
            type: String,
            default: '',
        },
        method: {
            type: String,
            default: '',
        },
        border: {
            type: Boolean,
            default: true,
        },
        color: {
            type: String,
            default: '',
        },
        svgColor: {
            type: String,
            default: '',
        },
        onClick: {
            type: Function,
            default:() => {},
        },
        show: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            innerContent: {
                template: '',
            },
        };
    },
    computed:{
        nameSafe() {
            return this.name.replace(/"/g, '');
        },

        className() {
            const classNames = ['dropdown-menu-list-item'];

            if (this.icon) {
                classNames.push('has-icon');
            }

            if (this.active) {
                classNames.push('active');
            }

            if (this.color) {
                classNames.push(`dropdown-menu-list-item--color-${this.color}`);
            }

            if (this.svgColor) {
                classNames.push(`dropdown-menu-list-item--svgcolor-${this.svgColor}`);
            }

            if (this.border) {
                classNames.push('dropdown-menu-list-item--border');
            }

            return classNames;
        },
    },


};
</script>
