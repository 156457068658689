var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      !_vm.$page.props.hasItems
        ? _c(
            "empty",
            { attrs: { type: "projects" } },
            [
              _vm.$page.props.loggedUser.can.includes("project_save")
                ? _c(
                    "inertia-link",
                    {
                      staticClass: "btn-reset btn btn--primary",
                      attrs: { href: _vm.$route("project.create") },
                    },
                    [
                      _c("SvgController", { attrs: { type: "plus" } }),
                      _vm._v(" "),
                      _c("span", [_vm._v("Create My First Development")]),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _c(
            "div",
            [
              _c("TableGridLayout", {
                attrs: {
                  columns: _vm.columns,
                  rows: _vm.dataFormat,
                  mode: "remote",
                  "total-records": _vm.projects.total,
                  "grid-layout": _vm.gridLayout,
                  "has-header": _vm.hasHeader,
                  "has-footer": _vm.hasFooter,
                  output: _vm.output,
                },
                on: { onRowClick: _vm.onRowClick },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "header-left",
                      fn: function () {
                        return [
                          _c("list-filter", {
                            attrs: {
                              fields: _vm.filterOptions,
                              route: _vm.filterRoute,
                              "search-placeholder": "Search projects",
                              section: ["projects", "items"],
                            },
                          }),
                        ]
                      },
                      proxy: true,
                    },
                    _vm._l(_vm.$scopedSlots, function (_, name) {
                      return {
                        key: name,
                        fn: function (slotData) {
                          return [
                            name.indexOf("cell(") === 0
                              ? _vm._t(name, null, null, slotData)
                              : _vm._e(),
                          ]
                        },
                      }
                    }),
                    {
                      key: "footer",
                      fn: function () {
                        return [
                          _c("pagination", {
                            attrs: {
                              links: _vm.projects.links,
                              section: ["projects", "items"],
                            },
                          }),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  true
                ),
              }),
              _vm._v(" "),
              _c("modal", {
                ref: "locationModal",
                staticClass: "modal--location",
                scopedSlots: _vm._u([
                  {
                    key: "header",
                    fn: function () {
                      return [_c("h3", [_vm._v("Development Location")])]
                    },
                    proxy: true,
                  },
                  {
                    key: "body",
                    fn: function () {
                      return [
                        _c(
                          "DevelopmentLocation",
                          _vm._b(
                            {},
                            "DevelopmentLocation",
                            _vm.locationData,
                            false
                          )
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }