<template>
    <div>
        <empty
            v-if="!$page.props.hasItems"
            type="operating in areas"
        />
        <div v-else>
            <TableGridLayout
                :columns="columns"
                :rows="localPlanningAuthorities.data"
                :has-header="hasHeader"
                :has-footer="hasFooter"
                :output="['table']"
                :grid-layout-for-table="gridLayout"
            >
                <template #header-left>
                    <list-filter
                        :route="filterRoute"
                        search-placeholder="Search LPAs"
                        section="local-planning-authorities"
                    />
                </template>

                <template #footer>
                    <pagination
                        :links="localPlanningAuthorities.links"
                        section="local-planning-authorities"
                    />
                </template>
            </TableGridLayout>
        </div>
    </div>
</template>

<script>

import ListFilter from 'Utilities/list-filter/ListFilter.vue';
import Pagination from 'Utilities/pagination/Pagination.vue';
import Empty from 'Utilities/empty/Empty';

export default {
    name: 'LocalPlanningAuthorityTable',
    components: {
        ListFilter,
        Pagination,
        Empty,
    },

    props: {
        localPlanningAuthorities: {
            type: Object,
            default: () => ({}),
        },

        hasHeader: {
            type: Boolean,
            default: true,
        },

        hasFooter: {
            type: Boolean,
            default: true,
        },

        filterRoute: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            columns: [],
            gridLayout: {},
        };
    },

    created() {
        this.columns = [{
            label: 'Name',
            field: 'name',
            sort: 'name',
        }];

        this.gridLayout = {
            title: 'name',
        };

        if (this.hasPolicy('operatingIn_delete')) {
            const dropdown =[{
                name: 'Delete',
                color: 'red',
                onClick: (evt, row, index) => {
                    this.deleteRelationship(this.localPlanningAuthorities.data[index]);
                },
            }];

            this.columns.push({
                label: '',
                field: 'permissions',
                type: 'dropdown',
                sortable: false,
                position: 'right',
                options: dropdown,
            });

            this.gridLayout['dropdown'] = dropdown;
        }
    },

    methods: {
        deleteRelationship(LPA) {
            this.$confirm(
                {
                    title: 'Delete Development',
                    message: 'Are you sure you want to delete this association? This action can\'t be undone.',
                    confirmBtnClass: 'btn--red',
                    confirmBtnText: 'Delete',
                    callback: confirm => {
                        if (confirm) {
                            this.$inertia.delete(this.$route('operating-in.destroy', LPA.id));
                        }
                    },
                },
            );
        },
    },
};
</script>
