<template>
    <div
        :class="{
            'pdf-report' : isDownload
        }"
    >
        <section class="pdf-item">
            <page-header
                :title="title"
                icon="reports"
            >
                <template #page-header-center>
                    {{ date }}
                </template>
                <div class="">
                    <h3>Dev Site Report</h3>
                    <h3>{{ upperFirst(project.status) }}</h3>
                </div>

                <template #page-header-bottom>
                    <div class="center">
                        <div class="tile-wrap flex-space-between">
                            <div class="tile">
                                <div class="tile-inner tile-inner--single">
                                    <div class="tile-text">
                                        <h4>
                                            <span>
                                                Development Name:
                                            </span>
                                            {{ project.name }}
                                        </h4>
                                        <h4>
                                            <span>
                                                URN:
                                            </span>
                                            {{ project.unique_reference_number }}
                                        </h4>
                                        <h4>
                                            <span>Address:</span>
                                            {{ project.address }}
                                        </h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="link-wrap flex-space-between">
                            <dashboard-link
                                title="Size"
                                color="#627086"
                                :text="floatOutput(project.size)"
                            />
                            <dashboard-link
                                title="Planning No"
                                color="#6967D5"
                                :text="project.planning_ref"
                            />
                            <dashboard-link
                                title="Status"
                                color="#E47D44"
                                :text="project.status"
                            />
                            <dashboard-link
                                title="Dev Type"
                                color="#1782FF"
                                :text="project.development_type"
                            />
                            <dashboard-link
                                title="Land Status"
                                color="#5EBD8A"
                                :text="project.land_status"
                            />
                            <dashboard-link
                                title="Total Units"
                                color="#E34B5D"
                                :text="floatOutput(project.onsite_baseline_total)"
                            />
                            <dashboard-link
                                title="Desired Units"
                                color="#6967D5"
                                :text="floatOutput(project.desired_total)"
                            />
                        </div>
                    </div>
                </template>
            </page-header>
        </section>
        <div class="center">
            <section
                class="pdf-item"
            >
                <div class="report-lease-map">
                    <report-map
                        ref="rentalmap"
                        type="projects"
                        :zoom="10"
                        :projects="[project]"
                        :is-download="isDownload"
                    />
                </div>
            </section>
        </div>
    </div>
</template>

<script>

import { DateTime } from 'luxon';
import { DashboardLink } from 'Components/Dashboard';

import ReportMap from 'Utilities/maps/ReportMap';

import { upperFirst } from 'lodash';

export default {
    name: 'DevelopmentReport',
    components: {
        ReportMap,
        DashboardLink,
    },
    mixins: [{methods: {upperFirst}}],

    props: {
        project: {
            type: Object,
            required: true,
        },
        isDownload: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
        };
    },

    computed: {
        lpa() {
            return this.project.local_planning_authority;
        },

        date() {
            return DateTime.fromJSDate(new Date(this.project.updated_at)).toLocaleString();
        },
    },

    mounted() {

    },

    methods:{},
};
</script>
