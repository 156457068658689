<template>
    <transition name="fade">
        <div
            v-show="show"
            class="flash-alert"
        >
            <alert
                :type="$page.props.flash.status || 'success'"
            >
                {{ $page.props.flash.message }}

                <button
                    class="btn-reset modal-close-btn"
                    @click="show = false"
                >
                    <SvgController type="times" />
                </button>
            </alert>
        </div>
    </transition>
</template>

<script>
import Alert from './Alert.vue';
export default {
    components: {Alert},

    data() {
        return {
        };
    },

    computed: {
        show: {
            get() {

                if (this.$page.props.flash.status && this.$page.props.flash.message) {
                    return true;
                }

                return false;
            },

            set() {
                this.$page.props.flash = {};
            },
        },
    },
};
</script>
