<template>
    <div class="tile">
        <div
            :class="`tile-inner${href ? ' clickable' : ''}`"
            @click="handleClick"
        >
            <div
                class="tile-icon"
                :style="style"
            >
                <SvgController :type="icon" />
            </div>
            <div class="tile-text">
                <h5>{{ title }}</h5>
                <h2>{{ number }}</h2>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'Tile',
    components: {},
    props: {
        title: {
            type: String,
            required: true,
            default: 'Title',
        },
        icon: {
            type: String,
            default: 'files',
        },
        count: {
            type: Number,
            default: 0,
        },
        color: {
            type: String,
            default: '#1782FF',
        },
        href: {
            type: String,
            default: null,
        },
    },
    computed: {
        style() {
            return 'color: ' + this.color + ';background-color: ' + this.convertHex(this.color, 0.1);
        },
        number() {
            return this.count.toLocaleString();
        },
    },
    methods: {
        convertHex(color, opacity) {
            color = color.replace('#', '');
            const intR = parseInt(color.substring(0, 2), 16);
            const intG = parseInt(color.substring(2, 4), 16);
            const intB = parseInt(color.substring(4, 6), 16);

            return `rgba(${intR}, ${intG}, ${intB}, ${opacity})`;
        },
        handleClick() {
            if (this.href) {
                this.$inertia.visit(this.href);
            }
        },
    },
};
</script>
