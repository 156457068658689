var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: `${_vm.columnFormat.rtl ? " text-right" : ""}` },
    [
      _vm.columnFormat.type === "dropdown" && _vm.dropdownOptions.length > 0
        ? _c(
            "span",
            [
              _c(
                "DropdownMenu",
                {
                  attrs: {
                    position: _vm.row.position || _vm.columnFormat.position,
                  },
                  model: {
                    value: _vm.show,
                    callback: function ($$v) {
                      _vm.show = $$v
                    },
                    expression: "show",
                  },
                },
                [
                  _c(
                    "button",
                    {
                      staticClass: "btn-reset flex-center vgt-button-dropdown",
                    },
                    [
                      _c("SvgController", { attrs: { type: "three-dots" } }),
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.rowFormat.label) +
                          "\n            "
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { attrs: { slot: "dropdown" }, slot: "dropdown" },
                    [
                      _c("DropdownMenuList", {
                        attrs: { data: _vm.dropdownOptions },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          )
        : _vm.columnFormat.type === "checkbox"
        ? _c(
            "span",
            [
              _c("FormulateInput", {
                attrs: {
                  name: "item_" + _vm.fullRow.id,
                  type: "checkbox",
                  value: "true",
                },
                model: {
                  value: _vm.rowFormat.selected,
                  callback: function ($$v) {
                    _vm.$set(_vm.rowFormat, "selected", $$v)
                  },
                  expression: "rowFormat.selected",
                },
              }),
            ],
            1
          )
        : _vm.$scopedSlots[`cell(${_vm.columnFormat.field})`]
        ? _vm._t(`cell(${_vm.columnFormat.field})`, null, { row: _vm.fullRow })
        : _c(
            "span",
            {
              class: [
                _vm.columnFormat.spanClass,
                {
                  hasIcon: _vm.columnFormat.icon,
                  clickable: _vm.columnFormat.onClick,
                },
              ],
              style: _vm.columnStyle,
              on: {
                click: (event) => {
                  if (_vm.columnFormat.onClick)
                    _vm.columnFormat.onClick(event, _vm.columnFormat, _vm.index)
                },
              },
            },
            [
              _vm.rowFormat.icon || _vm.columnFormat.icon
                ? _c("SvgController", {
                    attrs: {
                      type: _vm.rowFormat.icon || _vm.columnFormat.icon,
                    },
                  })
                : _vm._e(),
              _vm._v("\n        " + _vm._s(_vm.rowFormat.label) + "\n    "),
            ],
            1
          ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }