<template>
    <div
        class="formulate-input-datewrap"
        :data-show-clear="showClear"
    >
        <!-- :class="`formulate-input-element formulate-input-element--${context.type} ${context.attributes.disabled ? 'disabled' : ''}`" -->
        <DateRangePicker
            ref="picker"
            v-model="context.model"
            :auto-apply="true"
            class="formulate-input-element"
            :class="[
                `formulate-input-element--${context.type}`,
                {'disabled' : disabled},
            ]"
            :single-date-picker="pickerType"
            :locale-data="{ firstDay: 1, format: 'dd/mm/yyyy' }"
            :disabled="disabled"
            :ranges="false"
        >
            <template #input="picker">
                <span
                    v-if="picker.startDate === null && picker.endDate === null"
                    class="text-grey4"
                >
                    {{ context.attributes.placeholder }}
                </span>
                <span
                    v-else
                    class="flex-space-between"
                >
                    <span>
                        {{ picker.rangeText }}
                    </span>
                    <button
                        v-if="showClear"
                        class="vue-daterange-picker--clear"
                        @click.prevent="context.model = {startDate: null, endDate: null}"
                    >
                        <svg-controller
                            type="times"
                        />
                    </button>
                </span>
            </template>
        </DateRangePicker>
    </div>
</template>

<script>
import DateRangePicker from 'vue2-daterange-picker';
import FormulateInputMixin from '@braid/vue-formulate/src/FormulateInputMixin';
export default {
    name: 'FormulateDateRange',
    components: {
        DateRangePicker,
    },
    mixins: [FormulateInputMixin],
    props: {
        showClear: {
            type: Boolean,
            default: false,
        },

        pickerType: {
            type: String,
            default: 'range',
        },

        disabled: {
            type: Boolean,
            default: false,
        },
    },
};
</script>
