<template>
    <auth-layout :component="$options.name">
        <div>
            <FormulateForm
                v-model="form"
                :errors="$page.props.errors"
                @validation="validation = $event"
                @submit="onSubmit"
            >
                <div
                    v-show="page === 1"
                    class="form-page bg-white"
                >
                    <div class="form-page-info">
                        <h3>You've been invited</h3>
                        <p>
                            {{ invitedBy }} has invited you to BNG Partnership platform.  Please check if information below is correct.
                        </p>
                    </div>
                    <FormulateInput
                        type="text"
                        name="first_name"
                        label="First Name"
                        validation="required"
                        validation-name="First Name"
                    />
                    <FormulateInput
                        type="text"
                        name="last_name"
                        label="Surname"
                        validation="required"
                        validation-name="Surname"
                    />
                    <FormulateInput
                        type="email"
                        name="email"
                        label="Email Address"
                        validation="required|email"
                        validation-name="Email address"
                    />
                    <FormulateInput
                        type="button"
                        label="Looks good"
                        @click="nextPage"
                    />
                </div>
                <div
                    v-show="page === 2"
                    class="form-page bg-white"
                >
                    <page-two
                        @back="page = 1"
                    />
                </div>
            </FormulateForm>
        </div>
    </auth-layout>
</template>
<script>
import AuthLayout from 'Components/Layouts/AuthLayout';
import PageTwo from './PageTwo';

export default {
    name: 'InvitationRegister',
    components: {
        AuthLayout,
        PageTwo,
    },
    props: {
        invitedBy: {
            type: String,
            required: true,
        },
        data: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            form: this.$inertia.form({
                ...this.data,
                password: null,
                password_confirmation: null,
                terms: null,
                token: this.$route().params.token,
                invite: true,
                type: 'user',
            }),
            page: 1,
            validation: {},
        };
    },

    methods: {
        onSubmit() {
            this.form.post(this.$route('register'));
        },

        nextPage() {
            if (!this.validation.hasErrors || !['first_name','last_name','email'].includes(this.validation.name)) {
                this.page = 2;
            }
        },
    },
};
</script>
