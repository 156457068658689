<template>
    <li
        v-if="show"
        class="page-header-tabs-list-item"
        :class="{
            'page-header-tabs-list-item--active' : selected,
            'page-header-tabs-list-item--disabled' : disabled,
        }"
        :type="type"
    >
        <slot name="list-item" />
        <tab-button
            :link="link"
            :type="type"
            :title="title"
            :only="only"
            :disabled="disabled"
            :selected="selected"
            @click="handleClick"
        >
            <slot name="button-before" />
            <slot
                v-if="tabId && $scopedSlots[`button(${tabId})`]"
                :name="`button(${tabId})`"
            />
            <slot
                v-else
                name="button"
            >
                <slot>
                    {{ title }}
                </slot>
            </slot>
            <slot name="button-after" />
        </tab-button>
    </li>
</template>

<script>
import TabButton from './TabButton';

export default {
    name: 'TabItem',
    components: {
        TabButton,
    },
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
        link: {
            type: String,
            default: null,
            required: true,
        },
        only: {
            type: Array,
            default: () => [],
        },
        selected: {
            type: Boolean,
            default: false,
        },
        show: {
            type: Boolean,
            default: true,
        },
        tabId: {
            type: String,
            default: null,
            required: true,
        },
        title: {
            type: String,
            default: null,
        },
        type: {
            type: String,
            default: 'only',
            validator(value) {
                return ['hash', 'link', 'only'].includes(value);
            },
        },
    },
    methods: {
        handleClick(event) {
            this.$emit('click', event);
        },
    },
};
</script>
