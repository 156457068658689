var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("modal", {
    ref: "edit",
    scopedSlots: _vm._u([
      {
        key: "header",
        fn: function () {
          return [_c("h3", [_vm._v("New Landowner")])]
        },
        proxy: true,
      },
      {
        key: "body",
        fn: function () {
          return [
            _c(
              "FormulateForm",
              {
                ref: "form",
                staticClass: "flex-col",
                attrs: { errors: _vm.$page.props.errors },
                on: { submit: _vm.submitForm },
                model: {
                  value: _vm.form,
                  callback: function ($$v) {
                    _vm.form = $$v
                  },
                  expression: "form",
                },
              },
              [
                _c("FormulateInput", {
                  attrs: {
                    type: "text",
                    name: "company_name",
                    label: "Company Name",
                    validation: "required",
                    "validation-name": "Company Name",
                  },
                }),
                _vm._v(" "),
                _c("FormulateInput", {
                  attrs: {
                    type: "select-plugin",
                    name: "country_code",
                    validation: "required",
                    label: "Country",
                    "validation-name": "Country",
                    "reduce-key": "code",
                    "label-key": "name",
                    options: _vm.countries,
                    placeholder: "Country",
                  },
                }),
                _vm._v(" "),
                _c("FormulateInput", {
                  attrs: {
                    type: "email",
                    name: "email",
                    label: "Email Address",
                    validation: "required|email",
                    "validation-name": "Email address",
                  },
                }),
                _vm._v(" "),
                _c("FormulateInput", {
                  attrs: {
                    type: "text",
                    name: "first_name",
                    label: "First Name",
                    validation: "required",
                    "validation-name": "First Name",
                  },
                }),
                _vm._v(" "),
                _c("FormulateInput", {
                  attrs: {
                    type: "text",
                    name: "last_name",
                    label: "Surname",
                    validation: "required",
                    "validation-name": "Surname",
                  },
                }),
                _vm._v(" "),
                _c("FormulateInput", {
                  attrs: {
                    type: "toggle-button",
                    name: "invite",
                    label: "Send Invite",
                    description:
                      "Do you want to send the contact an invite to use the BNG Partnership system?",
                    "has-label": false,
                  },
                }),
                _vm._v(" "),
                _c("div", { staticClass: "formulate-input btn--group" }, [
                  _c(
                    "a",
                    {
                      staticClass: "btn",
                      attrs: { href: "#" },
                      on: { click: _vm.$refs.edit.closeModal },
                    },
                    [_vm._v("\n                    Cancel\n                ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn--reset btn btn--primary",
                      attrs: { type: "submit" },
                    },
                    [
                      _c("SvgController", { attrs: { type: "tick" } }),
                      _vm._v("\n                    Save\n                "),
                    ],
                    1
                  ),
                ]),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }