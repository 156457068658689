module.exports = {
    get tileDefaults() {
        return require('./tiles').default;
    },
    get linkDefaults() {
        return require('./links').default;
    },
    get mapColors() {
        return require('./mapColors').default;
    },
    get mapThemes() {
        return require('./mapThemes').default;
    },
    get mapServiceProperties() {
        return require('./mapServiceProperties').default;
    },
    get habitatData() {
        return require('./habitatData').default;
    },
    get orderedHabitatData() {
        return require('./habitatData').orderedData;
    },
};
