<template>
    <div>
        <p v-if="address">
            <SvgController type="map-icon" />
            {{ address }}
        </p>
        <draw-map
            v-bind="location && project"
            ref="map"
            :edit="false"
            :show-geo-input="false"
            :zoom="10"
            type="plot"
            show-keys
            :fit-bounds="['layers', 'vectors']"
            :layers="mapProjectLayers(project)"
            :vectors="mapVectors(project.planning_authority_id, project.river_basin_district_id)"
        />
    </div>
</template>

<script>
import DrawMap from 'Utilities/maps/DrawMap';
import ProjectPages from 'Mixins/projectPages';

export default {
    name: 'DevelopmentLocation',
    components: {
        DrawMap,
    },
    mixins: [
        ProjectPages,
    ],
    props: {
        address: {
            type: String,
            default: null,
        },
        location: {
            type: Object,
            default: () => null,
        },
        project: {
            type: Object,
            default: () => {},
        },
        planningAuthorities: {
            type: Array,
            default: () => [],
        },
        riverBasinDistricts: {
            type: Array,
            default: () => [],
        },
    },
};
</script>
