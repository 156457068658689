var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("p", [
      _vm._v("\n        User who made the Audit: "),
      _c("span", { staticClass: "text-green" }, [
        _vm._v(_vm._s(_vm.update.user_name)),
      ]),
    ]),
    _vm._v(" "),
    _vm.hasGroup(["sequest"])
      ? _c(
          "div",
          [
            _c(
              "FormulateForm",
              {
                ref: "updateForm",
                staticClass: "flex-col",
                attrs: { errors: _vm.form.errors },
                on: { submit: _vm.submitUpdate },
                model: {
                  value: _vm.form,
                  callback: function ($$v) {
                    _vm.form = $$v
                  },
                  expression: "form",
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "details" },
                  [
                    _c("div", { staticClass: "formulate-heading" }, [
                      _c("div", { staticClass: "formulate-heading" }, [
                        _c("h6", [_vm._v("Description")]),
                      ]),
                      _vm._v(" "),
                      _c("p", [_vm._v(_vm._s(_vm.update.description))]),
                    ]),
                    _vm._v(" "),
                    _c("FormulateInput", {
                      attrs: {
                        type: "select",
                        name: "status",
                        label: "Change Status",
                        options: _vm.statusOptions,
                      },
                    }),
                    _vm._v(" "),
                    _vm.form.status === "paid"
                      ? _c(
                          "div",
                          [
                            _c("FormulateInput", {
                              staticClass: "margin-0",
                              attrs: {
                                type: "text",
                                name: "amount_paid",
                                label: "Amount Paid",
                                validation: "required|number",
                                "validation-name": "Amount paid",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm.form.status === "rejected"
                      ? _c(
                          "div",
                          [
                            _c("FormulateInput", {
                              staticClass: "margin-0",
                              attrs: {
                                type: "text",
                                name: "reason",
                                label: "Reason",
                                validation: "required",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c("FormulateInput", {
                      attrs: {
                        type: "file",
                        name: "documents",
                        accept:
                          ".pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                        validation:
                          "mime:application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                        "upload-url": "/attachments/upload",
                        "upload-behavior": "live",
                        multiple: "",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "label",
                            fn: function () {
                              return [
                                _c("FileLabel", {
                                  attrs: {
                                    id: "documents",
                                    label: "Documents",
                                    text: "Upload documents",
                                  },
                                }),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        1317056171
                      ),
                    }),
                    _vm._v(" "),
                    _c("Files", {
                      attrs: {
                        files: _vm.formDocs,
                        filetype: "document",
                        deletable: true,
                      },
                    }),
                    _vm._v(" "),
                    _c("FormulateInput", {
                      attrs: {
                        type: "file",
                        name: "photos",
                        accept: "image/jpeg,image/png,image/gif",
                        validation: "mime:image/jpeg,image/png,image/gif",
                        "upload-url": "/attachments/upload",
                        "upload-behavior": "live",
                        multiple: "",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "label",
                            fn: function () {
                              return [
                                _c("FileLabel", {
                                  attrs: {
                                    id: "photos",
                                    label: "Photos",
                                    text: "Upload photos",
                                  },
                                }),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        3162339912
                      ),
                    }),
                    _vm._v(" "),
                    _c("Gallery", {
                      attrs: { images: _vm.formPhotos, deletable: true },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("FormulateInput", { attrs: { type: "submit" } }),
              ],
              1
            ),
          ],
          1
        )
      : _c(
          "div",
          [
            _c("gallery", {
              attrs: { images: _vm.formPhotos, deletable: false },
            }),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }