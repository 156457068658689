<template>
    <div
        :class="context.classes.element"
        :data-type="context.type"
    >
        <label
            class="toggle-checkbox-container"
        >
            <input
                v-model="context.model"
                type="checkbox"
                :value="context.value"
                :disabled="disabled"
                v-bind="attributes"
                v-on="$listeners"
                @blur="context.blurHandler"
            >
            <div class="toggle-checkbox-buttons">
                <div
                    class="toggle-checkbox-buttons--item"
                    :class="{
                        'toggle-checkbox--active': !context.model,
                        'toggle-checkbox--disabled': disabled
                    }"
                    :disabled="disabled"
                >
                    {{ context.attributes.placeholder && typeof context.attributes.placeholder === 'object' ? context.attributes.placeholder[0] : 'No' }}
                </div>
                <div
                    class="toggle-checkbox-buttons--item"
                    :class="{
                        'toggle-checkbox--active': context.model,
                        'toggle-checkbox--disabled': disabled
                    }"
                    :disabled="disabled"
                >
                    {{ context.attributes.placeholder && typeof context.attributes.placeholder === 'object' ? context.attributes.placeholder[1] : 'Yes' }}
                </div>
            </div>
        </label>
    </div>
</template>

<script>

import FormulateInputMixin from '@braid/vue-formulate/src/FormulateInputMixin';
export default {
    name:'FormulateCheckboxButton',
    mixins: [FormulateInputMixin],
    props: {
        disabled: {
            type: Boolean,
            required: false,
        },
    },
};
</script>
