<template>
    <app-layout section="landowners">
        <page-header
            :title="title"
            icon="briefcase"
            :count="landowners.total"
        >
            <button
                v-if="!companyName"
                type="button"
                class="btn btn--primary"
                @click="addLandowner()"
            >
                <SvgController
                    type="plus"
                />
                New Landowner
            </button>
        </page-header>
        <div class="center">
            <landowner-table
                :landowners="landowners"
                :filter-route="filterRoute"
            />
        </div>
        <edit ref="landownerEdit" />
    </app-layout>
</template>

<script>

import LandownerTable from './Table';

import Edit from './Edit';

export default {
    name: 'Landowners',
    components: {
        LandownerTable,
        Edit,
    },
    props: {
        landowners: {
            type: Object,
            default: () => ({}),
        },
        companyName: {
            type: String,
            default: null,
        },
    },

    computed: {
        filterRoute() {
            if (this.isAgentPage) {
                return this.$route('agent.landowners', this.$route().params.agent);
            }

            return this.$route('landowners');
        },
        isAgentPage() {
            return this.$route().current('agent.landowners');
        },
        title() {
            let title = '';

            if (this.companyName) {
                title = `${this.companyName} - `;
            }

            title += 'Landowners';

            return title;
        },
    },

    methods: {
        addLandowner() {
            this.$refs.landownerEdit.open();
        },
    },
};
</script>
