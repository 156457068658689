<template>
    <div
        class="map-filters"
        :class="{
            'map-filters-slide': slide,
            'map-filters-slide--active': slideActive,
        }"
    >
        <HeaderSmall
            v-if="slide || title"
            :title="title"
            :button="{
                svg: 'times',
                click: close,
            }"
        />
        <accordion
            :default-active="hasGroup('sequest') ? 0 : null"
        >
            <accordion-item
                v-for="accordion in accordions"
                :key="accordion.name"
                :title="accordion.name"
            >
                <template
                    v-for="layer in accordion.items"
                >
                    <div
                        :key="layer.name"
                        class="accordion-main"
                        :class="{
                            'accordion-main--has-more': true,
                            'accordion-main--expanded': expanded[layer.name]
                        }"
                    >
                        <button
                            v-if="(layer.items && layer.items.length) || (layer.options && layer.options.length)"
                            class="accordion-expand"
                            @click="event => updateExpand(event, layer)"
                        >
                            <div>
                                <SvgController
                                    type="caret-down"
                                />
                            </div>
                        </button>
                        <FormulateInput
                            :key="`${layer.name}-input`"
                            v-model="filters[layer.name]"
                            :label="layer.label"
                            :name="layer.label"
                            type="toggle-button"
                            element-class="map-filter"
                            :color="getLocalColor(layer)"
                            :svg-pattern="getSvg(layer)"
                            :disabled="currentZoom < layer.api_zoom && layer.api ? true : false"
                            :tooltip-help="currentZoom < layer.api_zoom && layer.api ? 'Please zoom into the map to use this layer' : null"
                            @input="event => updateFilter(event, layer)"
                        />
                    </div>
                    <div
                        v-show="expanded[layer.name] && ((layer.items && layer.items.length) || (layer.options && layer.options.length))"
                        :key="`${layer.name}-more`"
                        class="accordion-more"
                    >
                        <FormulateInput
                            v-for="layerInner in layer.items"
                            :key="`${layer.name}-${layerInner.name}-input`"
                            v-model="filters[layerInner.name]"
                            :label="`${layerInner.label}`"
                            :name="layerInner.label"
                            type="toggle-button"
                            element-class="map-filter"
                            :color="getLocalColor(layerInner)"
                            :svg-pattern="getSvg(layerInner)"
                            :disabled="currentZoom < layerInner.api_zoom && layerInner.api ? true : false"
                            :tooltip-help="currentZoom < layerInner.api_zoom && layerInner.api ? 'Please zoom into the map to use this layer' : null"
                            @input="event => updateFilterChild(event, layerInner)"
                        />
                        <template v-if="layer.options">
                            <div
                                :key="`options-${layer.name}`"
                                class="accordion-options"
                            >
                                <FormulateInput
                                    v-for="option in layer.options"
                                    :key="`option-${option.name}`"
                                    v-model="options[`${layer.name}_${option.name}`]"
                                    type="select-plugin"
                                    :name="option.name"
                                    :label="option.label"
                                    :placeholder="option.placeholder || ''"
                                    :options="option.options"
                                    @input="event => updateOptions(event, layer, option)"
                                />
                            </div>
                        </template>
                    </div>
                </template>
            </accordion-item>
        </accordion>
    </div>
</template>

<script>
import { getSVG } from 'Lib/defaults/mapThemes';
import mapMixin from 'Mixins/map';
import arcgisAPI from 'Mixins/arcgisAPI';
import HeaderSmall from 'Components/Header/HeaderSmallButtons';
import { Accordion, AccordionItem } from 'Utilities/accordion';

export default {
    name: 'MapSidebar',
    components: {
        Accordion,
        AccordionItem,
        HeaderSmall,
    },
    mixins: [
        mapMixin,
        arcgisAPI,
    ],
    props: {
        accordions: {
            type: Array,
            default: () => [],
        },
        currentZoom: {
            type: Number,
            default: 10,
        },
        title: {
            type: String,
            default: null,
        },
        slide: {
            type: Boolean,
            default: false,
        },
        value: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            slideActive: false,
            filters: this.value,
            options: {},
            expanded: {},
            updatingFilters: false,
            updatingFiltersChildren: false,
        };
    },
    computed: {
        optionList() {
            const list = [];

            this.accordions.forEach(accordion => {
                accordion.items.filter(layer => layer.options).forEach(layer => {
                    list.push(layer);
                });
            });

            return list;
        },
    },
    methods: {
        updateOptions(event, layer) {
            this.updateFilter(event, layer);
        },

        updateExpand(event, layer) {
            const expanded = {};

            expanded[layer.name] = !this.expanded[layer.name];
            this.expanded = {...this.expanded, ...expanded};
        },

        updateFilter(event, layer) {
            if (!this.updatingFiltersChildren) {
                this.updatingFilters = true;

                if (layer.items) {
                    layer.items.forEach(children => {
                        this.filters[children.name] = this.filters[layer.name];
                    });
                }

                this.$emit('changeParent', this.filters, this.options, layer);
                this.$emit('change', this.filters, this.options, layer);

                setTimeout(() => {
                    this.updatingFilters = false;
                }, 1);
            }
        },

        updateFilterChild(event, layer) {
            if (!this.updatingFilters) {
                this.updatingFiltersChildren = true;

                if (layer.parent && layer.parent.items) {
                    let parentEnabled = false;

                    layer.parent.items.forEach(children => {
                        if (this.filters[children.name]) {
                            parentEnabled = true;
                        }
                    });

                    this.filters[layer.parent.name] = parentEnabled;
                }

                this.$emit('changeChild', this.filters, this.options, layer);
                this.$emit('change', this.filters, this.options, layer);

                setTimeout(() => {
                    this.updatingFiltersChildren = false;
                }, 1);
            }
        },

        getLocalColor(layer) {
            if (layer.items && layer.items.length) {
                return null;
            }

            let theme = this.getColor(layer);

            if (typeof theme === 'object') {
                theme = theme.fill ?? '#000';
            }

            return theme;
        },

        parseTheme(theme) {
            return {
                ...theme.theme,
                fill: theme.fill,
                stroke: theme.theme.fill,
            };
        },

        getSvg(layer) {
            const theme = this.getColor(layer);

            if (typeof theme === 'object' && typeof theme.theme === 'object') {
                const svg = getSVG(this.parseTheme(theme));

                if (svg) {
                    if (svg.pathBg) {
                        const pathBg = document.createElement('rect');

                        pathBg.style.width = '100%';
                        pathBg.style.height = '100%';
                        pathBg.setAttribute('fill', svg.pathBg.getAttribute('fill'));

                        svg.pathBg.outerHTML = pathBg.outerHTML;
                    }

                    if (svg.path) {
                        const path = document.createElement('rect');

                        path.style.width = '100%';
                        path.style.height = '100%';
                        path.setAttribute('fill', svg.path.getAttribute('fill'));

                        svg.path.outerHTML = path.outerHTML;
                    }

                    if (svg.inner && svg.inner.line) {
                        svg.inner.line.setAttribute('stroke-width', 2);
                    }

                    return svg.svg;
                }
            }

            return null;
        },

        open() {
            if (this.slide) {
                this.slideActive = true;
            }
        },

        close() {
            if (this.slide) {
                this.slideActive = false;
            }
        },

        toggle() {
            if (this.slide) {
                this.slideActive = !this.slideActive;
            }
        },
    },
};
</script>
