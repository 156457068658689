var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "app-layout",
    { attrs: { section: "plots" } },
    [
      _c(
        "page-header",
        {
          attrs: {
            title: "Land Parcel Details",
            icon: "plots",
            back: { url: _vm.$route("plots"), text: "All Land Parcels" },
          },
          scopedSlots: _vm._u([
            {
              key: "page-header-bottom",
              fn: function () {
                return [
                  _c("tabs", {
                    attrs: { tabs: _vm.tabs },
                    model: {
                      value: _vm.selectedTab,
                      callback: function ($$v) {
                        _vm.selectedTab = $$v
                      },
                      expression: "selectedTab",
                    },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _vm.selectedTab === "information"
            ? _c(
                "div",
                { staticClass: "flex-center" },
                [
                  _vm.deletable
                    ? _c(
                        "button",
                        {
                          staticClass: "btn-reset btn btn--red--secondary",
                          on: { click: _vm.deletePlot },
                        },
                        [
                          _c("SvgController", { attrs: { type: "trashcan" } }),
                          _vm._v(" "),
                          _c("span", [_vm._v("Delete Land Parcel")]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "inertia-link",
                    {
                      staticClass: "btn-reset btn btn--primary",
                      class: { disabled: !_vm.editable },
                      attrs: {
                        href: _vm.editable
                          ? _vm.$route("plot.edit", _vm.plot.id)
                          : "#",
                      },
                    },
                    [_vm._v("\n                Edit Land Parcel\n            ")]
                  ),
                ],
                1
              )
            : _vm.selectedTab === "leases"
            ? _c(
                "div",
                { staticClass: "flex-center" },
                [
                  _vm.hasGroup(["sequest", "lpa"]) &&
                  _vm.hasPermission("rental_save") &&
                  _vm.plot.site_type === "Offsite"
                    ? _c(
                        "inertia-link",
                        {
                          staticClass: "btn-reset btn btn--primary",
                          attrs: {
                            href: _vm.$route("rental.create", _vm.plot.id),
                          },
                        },
                        [
                          _vm._v(
                            "\n                Create Agreement\n            "
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm.selectedTab === "habitats"
            ? _c(
                "div",
                { staticClass: "flex-center" },
                [
                  !_vm.editingHabitats
                    ? _c(
                        "button",
                        {
                          staticClass: "btn-reset btn btn--primary",
                          class: { disabled: !_vm.canEditHabitats },
                          on: {
                            click: function ($event) {
                              _vm.editingHabitats = true
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                Edit Habitats\n            "
                          ),
                        ]
                      )
                    : _vm.editingHabitats
                    ? [
                        _c(
                          "button",
                          {
                            staticClass: "btn-reset btn btn--primary",
                            on: { click: _vm.saveHabitats },
                          },
                          [
                            _vm.uploading
                              ? _c("Processing", [
                                  _vm._v(
                                    "\n                        Submitting\n                    "
                                  ),
                                ])
                              : [
                                  _vm._v(
                                    "\n                        Save Habitats\n                    "
                                  ),
                                ],
                          ],
                          2
                        ),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "btn-reset btn",
                            on: {
                              click: function ($event) {
                                _vm.editingHabitats = false
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                    Cancel\n                "
                            ),
                          ]
                        ),
                      ]
                    : _vm._e(),
                ],
                2
              )
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "center" },
        [
          _vm.payment
            ? _c("Alert", { attrs: { type: "info" } }, [
                _c("p", [
                  _vm._v(
                    _vm._s(
                      `Based on the information you have provided, your potential annual payment could be £${_vm.payment} per annum.`
                    )
                  ),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.selectedTab === "information"
            ? _c("div", { staticClass: "details" }, [
                _c("h5", [_vm._v("Land Parcel Name")]),
                _vm._v(" "),
                _c("div", { staticClass: "details-header" }, [
                  _c("h3", [_vm._v(_vm._s(_vm.plot.name))]),
                  _vm._v(" "),
                  _c(
                    "span",
                    { staticClass: "status-pill", class: _vm.statusFormat },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.plot.status) +
                          "\n                "
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-flex--lap" }, [
                  _c("div", [
                    _c("div", { staticClass: "details-block details-bg" }, [
                      _c("div", { staticClass: "formulate-heading" }, [
                        _c("h6", [_vm._v("Land Parcel Details")]),
                      ]),
                      _vm._v(" "),
                      _vm.plot.unique_reference_number
                        ? _c("div", [
                            _c("h5", [_vm._v("Unique Reference Number")]),
                            _vm._v(" "),
                            _c("p", [
                              _vm._v(_vm._s(_vm.plot.unique_reference_number)),
                            ]),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.plot.offset_type
                        ? _c("div", [
                            _c("h5", [_vm._v("Offset Type")]),
                            _vm._v(" "),
                            _c("p", [_vm._v(_vm._s(_vm.offsetTypeString))]),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-flex--tab" }, [
                        _c("div", [
                          _c("h5", [_vm._v("Size")]),
                          _vm._v(" "),
                          _c("p", [
                            _vm._v(
                              _vm._s(_vm.plot.size) +
                                " " +
                                _vm._s(_vm.plot.size_units)
                            ),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", [
                        _c("h5", [_vm._v("Country")]),
                        _vm._v(" "),
                        _c("p", [_vm._v(_vm._s(_vm.plot.country.name))]),
                      ]),
                      _vm._v(" "),
                      _vm.plot.local_planning_authority
                        ? _c("div", [
                            _c("h5", [_vm._v("Planning Authority")]),
                            _vm._v(" "),
                            _c("p", [
                              _vm._v(
                                _vm._s(_vm.plot.local_planning_authority.name)
                              ),
                            ]),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.plot.river_basin_district
                        ? _c("div", [
                            _c("h5", [_vm._v("River basin district")]),
                            _vm._v(" "),
                            _c("p", [
                              _vm._v(
                                _vm._s(_vm.plot.river_basin_district.name)
                              ),
                            ]),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.$page.props.loggedUser.isSuperUser &&
                      (_vm.nationalCharacterAreas ||
                        (!_vm.nationalCharacterAreas &&
                          _vm.plot.country_code === "GB"))
                        ? _c(
                            "div",
                            [
                              _c("h5", [_vm._v("National Character Areas")]),
                              _vm._v(" "),
                              _vm.nationalCharacterAreas
                                ? _c("p", [
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(_vm.nationalCharacterAreas) +
                                        "\n                            "
                                    ),
                                  ])
                                : _c("Processing", [
                                    _vm._v(
                                      "\n                                Processing\n                            "
                                    ),
                                  ]),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]),
                    _vm._v(" "),
                    _vm.offsetType.includes("bng")
                      ? _c(
                          "div",
                          { staticClass: "details-block details-bg" },
                          [
                            _c("div", { staticClass: "formulate-heading" }, [
                              _c("h6", [_vm._v("BNG Details")]),
                            ]),
                            _vm._v(" "),
                            _vm.plot.management_style
                              ? _c("div", [
                                  _c("h5", [
                                    _vm._v("Current Management Style"),
                                  ]),
                                  _vm._v(" "),
                                  _c("p", [
                                    _vm._v(_vm._s(_vm.plot.management_style)),
                                  ]),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c("div", [
                              _c("h5", [
                                _vm._v(
                                  "Has an ecological study been carried out in the last 2 years?"
                                ),
                              ]),
                              _vm._v(" "),
                              _c("p", [_vm._v(_vm._s(_vm.study))]),
                            ]),
                            _vm._v(" "),
                            _vm.plot.study === 1
                              ? [
                                  _vm.plot.confirmed_units
                                    ? _c("div", [
                                        _c("h5", [_vm._v("Confirmed Units")]),
                                        _vm._v(" "),
                                        _c("p", [
                                          _vm._v(
                                            _vm._s(_vm.plot.confirmed_units)
                                          ),
                                        ]),
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.plot.offset_type === "bng"
                                    ? [
                                        _vm.plot.habitat_units
                                          ? _c("div", [
                                              _c("h5", [
                                                _vm._v("Habitat Units"),
                                              ]),
                                              _vm._v(" "),
                                              _c("p", [
                                                _vm._v(
                                                  _vm._s(_vm.plot.habitat_units)
                                                ),
                                              ]),
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.plot.hedgerow_units
                                          ? _c("div", [
                                              _c("h5", [
                                                _vm._v("Hedgerow Units"),
                                              ]),
                                              _vm._v(" "),
                                              _c("p", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.plot.hedgerow_units
                                                  )
                                                ),
                                              ]),
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.plot.river_units
                                          ? _c("div", [
                                              _c("h5", [_vm._v("River Units")]),
                                              _vm._v(" "),
                                              _c("p", [
                                                _vm._v(
                                                  _vm._s(_vm.plot.river_units)
                                                ),
                                              ]),
                                            ])
                                          : _vm._e(),
                                      ]
                                    : _vm._e(),
                                ]
                              : [
                                  _c("div", [
                                    _c("h5", [_vm._v("Estimated Units")]),
                                    _vm._v(" "),
                                    _c("p", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.plot.potential_estimated_units
                                        )
                                      ),
                                    ]),
                                  ]),
                                ],
                            _vm._v(" "),
                            _vm.$page.props.loggedUser.isSuperUser &&
                            _vm.plot.potential_units
                              ? _c("div", [
                                  _c("h5", [_vm._v("Potential Units")]),
                                  _vm._v(" "),
                                  _c("p", [
                                    _vm._v(_vm._s(_vm.plot.potential_units)),
                                  ]),
                                ])
                              : _vm._e(),
                          ],
                          2
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.offsetType.includes("nutrient_offset")
                      ? _c("div", { staticClass: "details-block details-bg" }, [
                          _c("div", { staticClass: "formulate-heading" }, [
                            _c("h6", [_vm._v("Nutrient Offset Details")]),
                          ]),
                          _vm._v(" "),
                          _vm.plot.drainage
                            ? _c("div", [
                                _c("h5", [_vm._v("Drainage")]),
                                _vm._v(" "),
                                _c("p", [_vm._v(_vm._s(_vm.plot.drainage))]),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.plot.soil_type
                            ? _c("div", [
                                _c("h5", [_vm._v("Soil Type")]),
                                _vm._v(" "),
                                _c("p", [_vm._v(_vm._s(_vm.plot.soil_type))]),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.plot.river_running
                            ? _c("div", [
                                _c("h5", [
                                  _vm._v("Stream or river on the land"),
                                ]),
                                _vm._v(" "),
                                _c("p", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.plot.river_running ? "Yes" : "No"
                                    )
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.plot.livestock
                            ? _c("div", [
                                _c("h5", [_vm._v("Livestock")]),
                                _vm._v(" "),
                                _c(
                                  "ul",
                                  { staticClass: "lst-reset" },
                                  _vm._l(
                                    _vm.plot.livestock_data,
                                    function (livestock) {
                                      return _c("li", { key: livestock.id }, [
                                        _vm._v(
                                          "\n                                    " +
                                            _vm._s(
                                              _vm.livestockInformation(
                                                livestock
                                              )
                                            ) +
                                            "\n                                "
                                        ),
                                      ])
                                    }
                                  ),
                                  0
                                ),
                              ])
                            : _vm._e(),
                        ])
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "details-block flex flex-wrap align-content-start",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "w-full order-1 lap-order-0" },
                        [
                          _c("div", { staticClass: "formulate-heading" }, [
                            _c("h6", [_vm._v("Location")]),
                          ]),
                          _vm._v(" "),
                          _c(
                            "p",
                            { staticClass: "details-location" },
                            [
                              _c("SvgController", {
                                attrs: { type: "map-icon" },
                              }),
                              _vm._v(
                                _vm._s(_vm.plot.address) +
                                  "\n                        "
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("draw-map", {
                            ref: "map",
                            attrs: {
                              edit: false,
                              leases: _vm.leases,
                              "show-geo-input": false,
                              "lat-long": _vm.plot.location_data.layer.center,
                              zoom: 10,
                              type: "plot",
                              "show-keys": "",
                              "fit-bounds": "layers",
                              layers: _vm.mapLayers,
                              vectors: _vm.mapVectors(
                                _vm.plot.planning_authority_id,
                                _vm.plot.river_basin_district_id
                              ),
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "w-full order-0 lap-order-1" },
                        [
                          _c("file-label", {
                            attrs: {
                              id: "documents",
                              label: "Documents",
                              text: "Upload a document",
                              disabled: !_vm.editable,
                            },
                            nativeOn: {
                              click: function ($event) {
                                return _vm.openFileModal("Document")
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c("Files", {
                            attrs: {
                              files: _vm.documents,
                              filetype: "document",
                              deletable: true,
                            },
                          }),
                          _vm._v(" "),
                          !_vm.plot.report_uploaded
                            ? _c("alert", { attrs: { type: "info" } }, [
                                _c("p", [
                                  _vm._v("Please upload your ecology report"),
                                ]),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c("file-label", {
                            attrs: {
                              id: "photos",
                              label: "Photos",
                              text: "Upload photos",
                              disabled: !_vm.editable,
                            },
                            nativeOn: {
                              click: function ($event) {
                                return _vm.openFileModal("Photo")
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c("gallery", {
                            attrs: { images: _vm.photos, deletable: true },
                          }),
                          _vm._v(" "),
                          _c("modal", {
                            ref: "fileModal",
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "header",
                                  fn: function () {
                                    return [
                                      _c("h3", [
                                        _vm._v("Add " + _vm._s(_vm.fileType)),
                                      ]),
                                    ]
                                  },
                                  proxy: true,
                                },
                                {
                                  key: "body",
                                  fn: function () {
                                    return [
                                      _c("add-files", {
                                        attrs: {
                                          "file-type": _vm.fileType,
                                          "document-types": {
                                            document: "Attachment",
                                            report: "Ecology Report",
                                          },
                                          parent: {
                                            id: _vm.$page.props.plot.id,
                                            name: "plot",
                                            type: "plot",
                                          },
                                        },
                                      }),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              1466795292
                            ),
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ]),
              ])
            : _vm.selectedTab === "agreements"
            ? _c("div", [_c("rentals")], 1)
            : _vm.selectedTab === "habitats"
            ? _c(
                "div",
                [
                  _c("habitats", {
                    ref: "habitats",
                    attrs: {
                      plot: _vm.plot,
                      lpas: _vm.plot.local_planning_authority
                        ? [_vm.plot.local_planning_authority]
                        : null,
                      rivers: _vm.plot.river_basin_district
                        ? [_vm.plot.river_basin_district]
                        : null,
                      habitats: _vm.habitats,
                      "is-selected": _vm.selectedTab === "habitats",
                    },
                    on: {
                      "submit-start": function ($event) {
                        _vm.uploading = true
                      },
                      "submit-complete": function ($event) {
                        _vm.uploading = false
                      },
                    },
                    model: {
                      value: _vm.editingHabitats,
                      callback: function ($$v) {
                        _vm.editingHabitats = $$v
                      },
                      expression: "editingHabitats",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _vm.selectedTab === "contracts"
        ? _c(
            "div",
            [
              _c("contracts", {
                attrs: {
                  contracts: _vm.contracts,
                  parent: {
                    id: _vm.$page.props.plot.id,
                    name: _vm.$page.props.plot.name,
                    type: "plot",
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }