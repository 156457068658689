<template>
    <div>
        <FormulateForm
            v-if="parent"
            v-model="form"
            :schema="schema"
            :errors="form.errors"
            class="flex-col"
            @submit="submitForm"
        />
    </div>
</template>
<script>
export default {
    props: {
        fileType: {
            type: String,
            default: 'document',
        },
        documentTypes: {
            type: Object||Array,
            default: () => ({
                document: 'Attachment',
            }),
        },
        parent: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            form: null,
            schema: [],
        };
    },
    computed: {
        computedFileType() {
            return this.fileType.toLowerCase();
        },
    },
    created() {
        if (!this.parent) return;
        this.form = this.$inertia.form({
            display_name: null,
            filepath: null,
            document_type: null,
            status: null,
            type: this.computedFileType,
            parent_type: this.parent.type,
            parent_id: this.parent.id,
        });
        this.schema = [
            {
                name: 'display_name',
                label: 'Display Name',
                type: 'text',
                required: true,
            },
        ];

        if (this.computedFileType === 'document') {
            let strDocumentTypeValue = '',
                blnShowType = false;

            if (Array.isArray(this.documentTypes)) {

                if (this.documentTypes.length) {
                    blnShowType = this.documentTypes.length > 1;

                    if (this.documentTypes[0].length) {
                        strDocumentTypeValue = this.documentTypes[0][1];
                    } else {
                        strDocumentTypeValue = this.documentTypes[0];
                    }
                }
            } else {
                const arrKeys = Object.keys(this.documentTypes);

                if (arrKeys.length) {
                    blnShowType = arrKeys.length > 1;

                    if (this.documentTypes[arrKeys[0]].value) {
                        strDocumentTypeValue = this.documentTypes[arrKeys[0]].value;
                    } else {
                        strDocumentTypeValue = arrKeys[0];
                    }
                }
            }

            const arrSchema = [{
                name: 'filepath',
                label: 'Select File',
                type: 'file',
                required: true,
                accept: '.pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                validation: 'mime:application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                uploadUrl: '/attachments/upload',
                uploadBehavior: 'delayed',
            }, {
                name: 'document_type',
                label: blnShowType ? 'Type of Document' : '',
                type: blnShowType ? 'select' : 'hidden',
                value: strDocumentTypeValue,
                options: this.documentTypes,
                required: true,
            }];

            this.schema.push(...arrSchema);
        } else {
            this.schema.push({
                name: 'filepath',
                label: 'Photos',
                type: 'image',
                required: true,
                validation: 'mime:image/jpeg,image/png,image/gif',
                uploadUrl: '/attachments/upload',
                uploadBehavior: 'delayed',
            });
        }

        this.schema.push({
            type: 'submit',
        });
    },
    methods: {
        submitForm(data) {
            data.type = this.computedFileType;

            if (data.document_type && data.document_type !== 'Contract') {
                data.type = data.document_type;
            }

            this.$inertia.post(this.$route('attachment.store'), data, {
                onSuccess: () => {
                    this.$parent.closeModal();
                },
            });
        },
    },
};
</script>
