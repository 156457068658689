var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("FormulateInput", {
        attrs: {
          type: "select-plugin",
          name: "management_style",
          validation: "required",
          label: "Current Management Style",
          "validation-name": "Current Management Style",
          options: [
            "Non-tillage/direct drilling",
            "Minimum tillage",
            "Medium tillage (spring tining or discing",
            "Ploughed",
          ],
          placeholder: "Current Management Style",
          disabled: _vm.disabled,
        },
      }),
      _vm._v(" "),
      _c("FormulateInput", {
        attrs: {
          type: "toggle-checkbox",
          name: "study",
          label:
            "Has an ecological study been carried out in the last 2 years?",
        },
      }),
      _vm._v(" "),
      _vm.value.study
        ? _c(
            "div",
            { key: "study_1" },
            [
              _vm.value.offset_type.includes("bng") &&
              !(
                _vm.documentUploaded &&
                _vm.tmpValue.current_habitats &&
                _vm.tmpValue.current_habitats.length &&
                Object.keys(_vm.tmpValue.current_habitats[0]).length
              )
                ? _c("ExcelConvert", {
                    key: "excel_convert_1",
                    attrs: { label: "Upload Biodiversity Metric document" },
                    on: { input: _vm.convertDocument },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "form-flex--tab form-flex--vcenter" },
                [
                  _c("FormulateInput", {
                    attrs: {
                      type: _vm.documentUploaded ? "fake" : "text",
                      name: "confirmed_units",
                      label: _vm.confirmText,
                      validation: "required|number:float",
                      "validation-name": _vm.confirmText,
                      disabled:
                        _vm.disabled || _vm.documentUploaded ? true : false,
                      "parse-value": _vm.documentUploaded
                        ? () => _vm.floatOutput(_vm.value.confirmed_units)
                        : "",
                    },
                  }),
                  _vm._v(" "),
                  _vm.value.offset_type.includes("carbon")
                    ? _c("FormulateInput", {
                        key: "secondary_confirmed_units_1",
                        attrs: {
                          type: "text",
                          name: "secondary_confirmed_units",
                          label: "Confirmed CO2-e(kg)",
                          validation: "required|number:float",
                          "validation-name": "Confirmed CO2-e(kg)",
                          disabled: _vm.disabled,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.value.offset_type.includes("bng"),
                      expression: "value.offset_type.includes('bng')",
                    },
                  ],
                  staticClass: "form-flex--tab form-flex--vcenter",
                },
                [
                  _c("FormulateInput", {
                    attrs: {
                      type: _vm.documentUploaded ? "fake" : "text",
                      name: "habitat_units",
                      label: "Habitat units",
                      "validation-rules": {
                        rule: _vm.validateUnits,
                      },
                      "validation-name": "Habitat units",
                      disabled:
                        _vm.disabled || _vm.documentUploaded ? true : false,
                      "parse-value": _vm.documentUploaded
                        ? () => _vm.floatOutput(_vm.value.habitat_units)
                        : "",
                    },
                  }),
                  _vm._v(" "),
                  _c("FormulateInput", {
                    attrs: {
                      type: _vm.documentUploaded ? "fake" : "text",
                      name: "hedgerow_units",
                      label: "Hedgerow units",
                      "validation-rules": {
                        rule: _vm.validateUnits,
                      },
                      "validation-name": "Hedgerow units",
                      disabled:
                        _vm.disabled || _vm.documentUploaded ? true : false,
                      "parse-value": _vm.documentUploaded
                        ? () => _vm.floatOutput(_vm.value.hedgerow_units)
                        : "",
                    },
                  }),
                  _vm._v(" "),
                  _c("FormulateInput", {
                    attrs: {
                      type: _vm.documentUploaded ? "fake" : "text",
                      name: "river_units",
                      label: "River units",
                      "validation-rules": {
                        rule: _vm.validateUnits,
                      },
                      "validation-name": "River units",
                      disabled:
                        _vm.disabled || _vm.documentUploaded ? true : false,
                      "parse-value": _vm.documentUploaded
                        ? () => _vm.floatOutput(_vm.value.river_units)
                        : "",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _vm.value.offset_type.includes("bng") && _vm.documentUploaded
                ? _c("BNGCalculator", {
                    key: "bng_calculator_1",
                    ref: "calcutator",
                    attrs: {
                      "max-area": _vm.value.size
                        ? parseFloat(_vm.value.size)
                        : null,
                      disabled: true,
                      pill: "",
                    },
                    on: {
                      input: _vm.updateHabitatData,
                      units: _vm.updateHabitatUnits,
                    },
                    model: {
                      value: _vm.tmpValue.current_habitats,
                      callback: function ($$v) {
                        _vm.$set(_vm.tmpValue, "current_habitats", $$v)
                      },
                      expression: "tmpValue.current_habitats",
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        : !_vm.value.study
        ? _c(
            "div",
            { key: "study_0" },
            [
              _c(
                "div",
                { staticClass: "form-flex--tab form-flex--vcenter" },
                [
                  _c("FormulateInput", {
                    key: "potential_estimated_units_3",
                    attrs: {
                      type: "text",
                      name: "current_estimated_units",
                      label: _vm.confirmTextEstimate,
                      "validation-name": _vm.confirmText,
                      disabled: true,
                    },
                  }),
                  _vm._v(" "),
                  _vm.value.offset_type.includes("carbon")
                    ? _c("FormulateInput", {
                        key: "potential_secondary_estimated_units_3",
                        attrs: {
                          type: "text",
                          name: "current_secondary_estimated_units",
                          label: "Estimated CO2-e(kg)",
                          disabled: true,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _vm.value.offset_type.includes("bng")
                ? _c("BNGCalculator", {
                    key: "bng_calculator_3",
                    ref: "calcutator",
                    attrs: {
                      "max-area": _vm.value.size
                        ? parseFloat(_vm.value.size)
                        : null,
                    },
                    on: {
                      input: _vm.updateHabitatData,
                      area: _vm.updateHabitatArea,
                    },
                    model: {
                      value: _vm.tmpValue.current_habitats,
                      callback: function ($$v) {
                        _vm.$set(_vm.tmpValue, "current_habitats", $$v)
                      },
                      expression: "tmpValue.current_habitats",
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.value.offset_type.includes("carbon")
                ? _c("SouthernCarbon", {
                    key: "southern_carbon_3",
                    ref: "calcutator",
                    on: { updateUnits: _vm.updateUnits },
                    model: {
                      value: _vm.tmpValue.current_habitats,
                      callback: function ($$v) {
                        _vm.$set(_vm.tmpValue, "current_habitats", $$v)
                      },
                      expression: "tmpValue.current_habitats",
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }