<template>
    <div>
        <FormulateInput
            type="select-plugin"
            name="management_style"
            validation="required"
            label="Current Management Style"
            validation-name="Current Management Style"
            :options="['Non-tillage/direct drilling', 'Minimum tillage', 'Medium tillage (spring tining or discing', 'Ploughed']"
            placeholder="Current Management Style"
            :disabled="disabled"
        />

        <FormulateInput
            type="toggle-checkbox"
            name="study"
            label="Has an ecological study been carried out in the last 2 years?"
        />
        <div
            v-if="value.study"
            key="study_1"
        >
            <ExcelConvert
                v-if="value.offset_type.includes('bng') && !(documentUploaded && tmpValue.current_habitats && tmpValue.current_habitats.length && Object.keys(tmpValue.current_habitats[0]).length)"
                key="excel_convert_1"
                label="Upload Biodiversity Metric document"
                @input="convertDocument"
            />
            <div
                class="form-flex--tab form-flex--vcenter"
            >
                <FormulateInput
                    :type="documentUploaded ? 'fake' : 'text'"
                    name="confirmed_units"
                    :label="confirmText"
                    validation="required|number:float"
                    :validation-name="confirmText"
                    :disabled="disabled || documentUploaded ? true : false"
                    :parse-value="documentUploaded ? () => floatOutput(value.confirmed_units) : ''"
                />
                <FormulateInput
                    v-if="value.offset_type.includes('carbon')"
                    key="secondary_confirmed_units_1"
                    type="text"
                    name="secondary_confirmed_units"
                    label="Confirmed CO2-e(kg)"
                    validation="required|number:float"
                    validation-name="Confirmed CO2-e(kg)"
                    :disabled="disabled"
                />
            </div>
            <div
                v-show="value.offset_type.includes('bng')"
                class="form-flex--tab form-flex--vcenter"
            >
                <FormulateInput
                    :type="documentUploaded ? 'fake' : 'text'"
                    name="habitat_units"
                    label="Habitat units"
                    :validation-rules="{
                        rule: validateUnits,
                    }"
                    validation-name="Habitat units"
                    :disabled="disabled || documentUploaded ? true : false"
                    :parse-value="documentUploaded ? () => floatOutput(value.habitat_units) : ''"
                />
                <FormulateInput
                    :type="documentUploaded ? 'fake' : 'text'"
                    name="hedgerow_units"
                    label="Hedgerow units"
                    :validation-rules="{
                        rule: validateUnits,
                    }"
                    validation-name="Hedgerow units"
                    :disabled="disabled || documentUploaded ? true : false"
                    :parse-value="documentUploaded ? () => floatOutput(value.hedgerow_units) : ''"
                />
                <FormulateInput
                    :type="documentUploaded ? 'fake' : 'text'"
                    name="river_units"
                    label="River units"
                    :validation-rules="{
                        rule: validateUnits,
                    }"
                    validation-name="River units"
                    :disabled="disabled || documentUploaded ? true : false"
                    :parse-value="documentUploaded ? () => floatOutput(value.river_units) : ''"
                />
            </div>
            <BNGCalculator
                v-if="value.offset_type.includes('bng') && documentUploaded"
                key="bng_calculator_1"
                ref="calcutator"
                v-model="tmpValue.current_habitats"
                :max-area="value.size ? parseFloat(value.size) : null"
                :disabled="true"
                pill=""
                @input="updateHabitatData"
                @units="updateHabitatUnits"
            />
        </div>
        <div
            v-else-if="!value.study"
            key="study_0"
        >
            <div
                class="form-flex--tab form-flex--vcenter"
            >
                <FormulateInput
                    key="potential_estimated_units_3"
                    type="text"
                    name="current_estimated_units"
                    :label="confirmTextEstimate"
                    :validation-name="confirmText"
                    :disabled="true"
                />
                <FormulateInput
                    v-if="value.offset_type.includes('carbon')"
                    key="potential_secondary_estimated_units_3"
                    type="text"
                    name="current_secondary_estimated_units"
                    label="Estimated CO2-e(kg)"
                    :disabled="true"
                />
            </div>
            <BNGCalculator
                v-if="value.offset_type.includes('bng')"
                key="bng_calculator_3"
                ref="calcutator"
                v-model="tmpValue.current_habitats"
                :max-area="value.size ? parseFloat(value.size) : null"
                @input="updateHabitatData"
                @area="updateHabitatArea"
            />
            <SouthernCarbon
                v-if="value.offset_type.includes('carbon')"
                key="southern_carbon_3"
                ref="calcutator"
                v-model="tmpValue.current_habitats"
                @updateUnits="updateUnits"
            />
        </div>
    </div>
</template>

<script>
import BNGCalculator from 'Components/Calculator/Bng';
import SouthernCarbon from 'Components/Calculator/SouthernCarbon';
import ExcelConvert from 'Utilities/file-convert/ExcelConvert';
import ExcelConvertMixin from 'Mixins/excelConvert';

export default {
    name: 'PlotFormPage2',
    components: {
        BNGCalculator,
        SouthernCarbon,
        ExcelConvert,
    },
    mixins: [
        ExcelConvertMixin,
    ],
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
        edit: {
            type: Boolean,
            default: false,
        },
        value: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            areaDetails: null,
            documentUploaded: this.value.study && this.value.current_habitats && this.value.current_habitats.length && Object.keys(this.value.current_habitats[0]).length ? true : false,
            tmpValue: this.value,
            habitatsUnits: {},
        };
    },
    computed: {
        confirmText() {
            return this.value.offset_type.includes('bng') ? 'Confirmed total units' : 'Confirmed Carbon(kg)';
        },
        confirmTextEstimate() {
            return this.value.offset_type.includes('bng') ? 'Estimated total units' : 'Estimated Carbon(kg)';
        },
    },
    watch: {
        'value.current_habitats': {
            handler(value) {
                this.tmpValue.current_habitats = value;
            },
            deep: true,
        },
    },
    methods: {
        updateHabitatArea(value) {
            this.areaDetails = value;
        },

        updateHabitatData() {
            if (!this.value.study && !this.documentUploaded) {
                this.habitatsUnits = {
                    units: this.$refs.calcutator.getUnits('all'),
                };
            }

            this.$emit('change', this.tmpValue);
        },

        updateHabitatUnits(value) {
            if (this.value.study && this.documentUploaded) {
                this.habitatsUnits = {
                    units: value,
                };
            }

            this.$emit('change', this.tmpValue);
        },

        updateUnits(type, units) {
            if (type === 'carbon') {
                this.habitatsUnits = {
                    units: units.carbon,
                    secondary_units: units.co2e,
                };
            }

            this.$emit('change', this.tmpValue);
        },

        convertDocument(workbook, selectedFile) {
            const habitatList = this.onSiteBaselineConvert(workbook);

            habitatList.forEach(habitat => {
                this.tmpValue.current_habitats.push(habitat);
            });
            this.documentUploaded = true;
            this.biodiversity_metric_document = selectedFile;
        },

        validateUnits({ value }) {
            if (value.offset_type.includes('bng')) {
                return this.isInt(value) || this.isfloatOutput(value);
            }

            return true;
        },
    },
};
</script>
