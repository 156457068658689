<template>
    <div class="vue-google-map">
        <google-map
            ref="mapRef"
            :zoom="zoom"
            :center="center"
            :options="mapOptions"
            class="fullpage-map"
        >
            <template
                v-for="(layer, index) in mapLayers"
            >
                <template
                    v-if="layer.location_data.layer.shape === 'circle'"
                >
                    <MapLayer
                        v-for="(point, locIndex) in parseCircleList(layer.location_data.layer)"
                        :key="`map-layer-${index}-${locIndex}`"
                        :index="locIndex"
                        :layer="{...layer, location_data: {layer: point} }"
                        :type="layer.type"
                        :multiple="parseCircleList(layer.location_data.layer).length > 1"
                        @click="showInfoWindow"
                    />
                </template>
                <template
                    v-else
                >
                    <MapLayer
                        :key="`map-layer-${index}`"
                        :layer="layer"
                        :type="layer.type"
                        @click="showInfoWindow"
                    />
                </template>
            </template>

            <google-map-infowindow
                :show.sync="showInfo"
                :position="infoWindowContext.position"
            >
                <info-box
                    :item="infoWindowContext"
                    @showImage="showImage"
                    @on-delete="reload"
                />
            </google-map-infowindow>

            <vector
                v-for="vector in vectors"
                ref="vectors"
                :key="vector.id"
                :vector-url="vector.url"
                :background="vector.background"
                :background-opacity="vector.backgroundOpacity"
                :line-width="vector.lineWidth"
                :stroke="vector.stroke"
                :stroke-opacity="vector.strokeOpacity"
                :attributes-url="vector.attributesUrl"
                :filter="vector.filter"
                @ready="vectorLoad"
                @onClick="(event) => showInfoWindow(event, {'data-type': 'vector', ...vector.data})"
            />
        </google-map>
        <Modal
            ref="imageModal"
            class="modal--img"
        >
            <template #body>
                <div
                    v-if="photo"
                    class="modal-image"
                    :style="{'background-image': 'url(' + photo + ')'}"
                />
            </template>
        </Modal>
    </div>
</template>

<script>
import arcgisAPI from 'Mixins/arcgisAPI';
import InfoBox from 'Utilities/maps/InfoBox';
import mapMixin from 'Mixins/map';
import Vector from 'Utilities/maps/Vector';
import MapLayer from 'Utilities/maps/MapLayer';
import Modal from 'Utilities/modal/Modal';

export default {
    name: 'Map',
    components: {
        InfoBox,
        Vector,
        MapLayer,
        Modal,
    },
    mixins: [
        mapMixin,
        arcgisAPI,
    ],
    props: {
        filters: {
            type: Object,
            default: () => {},
        },
        layers: {
            type: Array,
            default: () => [],
        },
        data: {
            type: Array,
            default: () => [],
        },
        fitBounds: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            map: null,
            loaded: false,
            loadedVector: false,
            readyVector: false,
            zoom: 6,
            center: {
                lat: 53.428720,
                lng: -1.659736,
            },
            mapOptions: {
                styles: this.mapStyle,
                zoomControl: true,
                mapTypeControl: true,
                scaleControl: false,
                streetViewControl: false,
                rotateControl: false,
                fullscreenControl: false,
                clickableIcons: false,
                mapTypeIds: ['roadmap', 'satellite'],
            },
            currentZoom: null,
            outputLayers: [],
            vectors: [],
            infoWindowContext: {
                position: {
                    lat: 53.428720,
                    lng: -1.659736,
                },
            },
            showInfo: false,
            formattedLayers: {},
            mapLayers: [],
            mapListener: null,
            currentZoomDelayed: null,
            apiZoomEnabled: null,
            vectorLoadTimeout: null,
        };
    },
    watch: {
        data: {
            handler() {
                this.runQuery();
            },
            deep: false,
        },
        filters: {
            handler() {
                this.runQuery();
            },
            deep: true,
        },
        layers: {
            handler() {
                this.runQuery();
            },
            deep: true,
        },
    },
    mounted() {
        this.mapReady();
    },
    methods: {
        mapReady() {
            if (this.$refs.mapRef.googleMapsReady && google && google.maps && google.maps.geometry && google.maps.geometry.spherical) {
                this.$refs.mapRef.$_getMap().then((event) => {
                    this.map = event;
                    this.loaded = true;
                    this.runQuery();

                    this.currentZoom = this.map.getZoom();
                    this.currentZoomDelayed = this.currentZoom;

                    this.$emit('loaded', (this.map, this.currentZoom));
                    this.map.addListener('zoom_changed', () => {
                        this.currentZoom = this.map.getZoom();

                        this.$emit('zoomChanged', this.currentZoom);
                    });
                });
            } else {
                setTimeout(() => {
                    this.mapReady();
                }, 1000);
            }
        },

        vectorLoad() {
            if (!this.map) {
                setTimeout(() => {
                    this.vectorLoad();
                }, 1000);

                return;
            }

            if (!this.loadedVector && this.readyVector && this.fitBounds) {
                this.vectorLoadTimeout = setTimeout(() => {
                    this.loadedVector = true;

                    if (this.$refs.vectors) {
                        const bounds = new google.maps.LatLngBounds();
                        let foundBounds = false;

                        this.$refs.vectors.forEach(vector => {
                            if (vector.getFilteredBounds()) {
                                bounds.union(vector.getFilteredBounds());
                                foundBounds = true;
                            }
                        });

                        if (foundBounds) {
                            this.map.fitBounds(bounds);
                        }
                    }
                }, 100);
            }
        },

        runQuery() {
            this.showInfo = false;
            this.runLocal();
            this.runVectors();
            this.runFiles();
            this.runArcGis();
        },

        runLocal() {
            this.mapLayers = [];
            this.layers
                .filter(layer => layer.type === 'local' && this.filters[layer.name])
                .forEach(layer => {
                    let data = this.data.filter(data => data['data-type'] === layer['data-type']);

                    if (layer['data-type'] === 'habitats') {
                        let layerFilter = layer.name,
                            dataFilter = 'type';

                        if (layer['data-filter']) {
                            layerFilter = layer['data-filter'];
                        }

                        if (layer['data-filter-key']) {
                            dataFilter = layer['data-filter-key'];
                        }

                        const plotIds = this.filters.plots ? this.data.filter(data => data['data-type'] === 'plots').map(data => data.id) : [];

                        data = data.filter(data => {
                            if (data.related_type === 'plot-habitat' && !plotIds.includes(data.related_id)) {
                                return false;
                            }

                            return data[dataFilter] === layerFilter;
                        });
                    }

                    this.mapLayers = this.mapLayers.concat(this.formatType(data, layer['data-type']));
                });
        },

        runVectors() {
            let vectors = [];
            const lpas = this.data.filter(data => data['data-type'] === 'lpa');

            if (lpas && lpas.length) {
                const filters = lpas.map(lpa => {
                    return {
                        property: 'name',
                        compare: '=',
                        type: 'OR',
                        value: [lpa.name.replace(' LPA', ''), lpa.name, `${lpa.name} LPA`],
                    };
                });

                vectors.push({
                    name: 'lpa-areas',
                    url: this.$mapService + '/lpa/{z}/{x}/{y}.pbf',
                    attributesUrl: this.$mapService + '/lpa.attributes.json',
                    background: this.mapColors.lpa,
                    backgroundOpacity: 0.1,
                    lineWidth: 2,
                    stroke: this.mapColors.lpa,
                    strokeOpacity: 1,
                    filter: filters,
                    data: {name: 'lpa'},
                });
            }

            vectors = vectors.concat(
                this.layers
                    .filter(layer => layer.type === 'vector' && this.filters[layer.name])
                    .map(layer => {
                        let colour = this.mapColors[layer.color];

                        if (!colour) {
                            const ctx = document.createElement('canvas').getContext('2d');

                            ctx.fillStyle = layer.color;
                            colour = ctx.fillStyle;
                        }

                        return {
                            name: `vector-${layer.name}`,
                            url: layer.url,
                            background: colour,
                            backgroundOpacity: 0.3,
                            lineWidth: 2,
                            stroke: colour,
                            strokeOpacity: 1,
                            data: layer,
                        };
                    }),
            );

            if (vectors.length) {
                this.readyVector = true;
            }

            this.vectors = [...vectors];
        },

        runFiles() {
            this.layers
                .filter(layer => layer.type === 'file' && !this.filters[layer.name] && this.formattedLayers[layer.name]).forEach(layer => this.formattedLayers[layer.name].setMap(null));

            this.layers
                .filter(layer => layer.type === 'file' && this.filters[layer.name])
                .forEach(layer => {
                    if (!this.formattedLayers[layer.name]) {
                        this.formattedLayers[layer.name] = new google.maps.Data();
                        this.formattedLayers[layer.name].loadGeoJson(layer.url);
                        this.setMapStyle(this.formattedLayers[layer.name], layer, '{layer_label}');
                    }

                    this.formattedLayers[layer.name].setMap(this.map);
                });
        },

        runArcGis() {
            if (!this.map) return;
            const objQuery = {};

            this.layers
                .filter(layer => layer.type === 'arcgisAPI' && this.filters[layer.name])
                .forEach(layer => {
                    if (layer.api) {
                        objQuery[layer.api] = '';
                        this.apiZoomEnabled = layer.api_zoom;

                        if (!objQuery[layer.url]) {
                            objQuery[layer.url] = '';
                        }

                        if (objQuery[layer.url].indexOf(layer.url) === -1) {
                            objQuery[layer.url] += `${objQuery[layer.url] ? ' OR ' : ''}${layer.api_filter}`;
                        }
                    }
                });

            if (this.mapListener) google.maps.event.removeListener(this.mapListener);

            this.runAPICalls(objQuery);
            this.mapListener = this.map.addListener('bounds_changed', () => this.runAPICalls(objQuery));
        },

        showInfoWindow(event, item) {
            this.showInfo = false;
            let infoContent = {},
                type;
            const properties = {};

            switch (item['data-type']) {
                case 'vector':
                    if (this.mapServiceProperties[item.name]) {
                        Object.keys(this.mapServiceProperties[item.name]).filter(key => {
                            const value = event.feature.properties[this.mapServiceProperties[item.name][key]];

                            if (key.toLowerCase().indexOf('id') === -1 && value && String(value).trim() !== '') {
                                return true;
                            }
                        }).forEach(key => {
                            properties[this.capitalizeFirstLetter(key.trim().replace(/_/g, ' '))] = String(event.feature.properties[this.mapServiceProperties[item.name][key]]).trim().replace(/_/g, ' ');
                        });
                    } else {
                        Object.keys(event.feature.properties).filter(key => {
                            const value = event.feature.properties[key];

                            if (/^[a-zA-Z\s]+$/.test(event.feature.properties[key]) && value && String(value).trim() !== '') {
                                return true;
                            }
                        }).forEach(key => {
                            properties[this.capitalizeFirstLetter(key.trim().replace(/_/g, ' '))] = String(event.feature.properties[key]).trim().replace(/_/g, ' ');
                        });
                    }

                    infoContent = {
                        properties: properties,
                        position: {
                            lat: event.latLng.lat(),
                            lng: event.latLng.lng(),
                        },
                    };
                    type = 'vector';
                    break;

                case 'projects':
                    infoContent = {
                        ...item,
                        position: item.location_data.lat ? {
                            lat: item.location.lat,
                            lng: item.location.lng,
                        } : {
                            lat: event.latLng.lat(),
                            lng: event.latLng.lng(),
                        },
                    };
                    type = 'project';
                    break;


                case 'plots':
                    infoContent = {
                        ...item,
                        position: {
                            lat: event.latLng.lat(),
                            lng: event.latLng.lng(),
                        },
                    };
                    type = 'plot';
                    break;

                case 'habitats':
                    infoContent = {
                        ...item,
                        position: {
                            lat: event.latLng.lat(),
                            lng: event.latLng.lng(),
                        },
                    };
                    type = 'habitat';
                    break;

                default:
                    infoContent = item;
                    break;
            }

            if (infoContent.position) {
                this.infoWindowContext = infoContent;
                this.infoWindowContext.layerType = type;
                this.showInfo = true;
            }

        },

        formatType(data, type) {
            switch (type) {
                case 'projects':
                    return this.formatProject(data);

                case 'plots':
                    return this.formatPlots(data);

                case 'habitats':
                    return this.formatHabitats(data);

                default:
                    return [];
            }
        },

        formatProject(projects) {
            if (!Array.isArray(projects)) projects = [projects];

            return projects.filter(project => project.location_data).map(project => {
                return {
                    ...project,
                    'item-type': 'project',
                    fillColor: this.mapColors.project,
                    location_data: project.location_data.lat ? {
                        layer: {
                            shape: 'marker',
                            points: project.location_data,
                        },
                    } : project.location_data,
                };
            });
        },

        formatPlots(plots) {
            if (!Array.isArray(plots)) plots = [plots];

            return plots.filter(plot => plot.location_data && plot.location_data.layer && plot.location_data.layer.shape).map(plot => {
                return {
                    ...plot,
                    'item-type': 'plot',
                    location_data: plot.location_data,
                    fillColor: this.mapColors.plot,
                };
            });
        },

        formatHabitats(habitats) {
            if (!Array.isArray(habitats)) habitats = [habitats];

            return habitats.filter(habitat => habitat.location_data && habitat.location_data.layer && habitat.location_data.layer.shape).map(habitat => {
                let color = this.mapColors[habitat.type],
                    theme = null;

                if (typeof color === 'object') {
                    color = this.mapColors[habitat.type].fill;
                    theme = this.mapColors[habitat.type].theme;
                }

                return {
                    ...habitat,
                    type: 'habitat',
                    'habitat-type': habitat.type,
                    location_data: habitat.location_data,
                    fillColor: color,
                    theme: theme,
                };
            });
        },

        showImage(photo) {
            this.photo = photo;
            this.$refs.imageModal.openModal();
        },

        reload() {
            this.InfoBox = false;
            this.$emit('delete');
        },
    },
};
</script>
