<template>
    <button
        :class="btnClass"
        :title="title"
        @click.prevent="$emit('click')"
    >
        <slot>
            <SvgController
                :style="iconStyle"
                :type="icon"
            />
        </slot>
    </button>
</template>

<script>
export default {
    name: 'MapButton',
    components: {},

    props: {
        title: {
            type: String,
            default: '',
        },
        active: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        name: {
            type: String,
            required: true,
        },

        icon: {
            type: String,
            default: '',
        },

        iconStyle: {
            type: [Object, String],
            default: '',
        },
    },
    data() {
        return {
        };
    },

    computed: {
        btnClass() {
            return [
                'map-select-header-button',
                'btn-reset',
                `map-select-header-button--${this.name}`,
                { 'active' : this.active},
                { 'disabled' : this.disabled},
            ];
        },
    },
};
</script>
