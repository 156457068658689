<template>
    <div>
        <FormulateInput
            type="text"
            name="name"
            label="Name"
            validation="required"
            validation-name="Name"
            :disabled="disabled"
        />
        <div class="form-flex--tab form-flex--vcenter">
            <FormulateInput
                key="offset_type_1"
                type="select-plugin"
                name="offset_type"
                label="Offset Type"
                validation="required"
                validation-name="Offset Type"
                :options="offsetOptions"
                placeholder="Select Offset Type"
                :disabled="disabled"
                :multiple="true"
            />
            <FormulateInput
                v-if="hasGroup(['sequest'])"
                key="status_1"
                type="select-plugin"
                name="status"
                label="Stage"
                placeholder="Stage"
                :options="statuses"
            />
        </div>
        <div
            v-if="planningAuthorities.length > 1 || riverBasinDistricts.length > 1 || companies.length > 1"
            class="form-flex--tab form-flex--vcenter"
        >
            <template v-if="planningAuthorities.length > 1 && value.offset_type.includes('bng')">
                <FormulateInput
                    key="planning_authority_id_1"
                    type="select-plugin"
                    name="planning_authority_id"
                    label="Planning Authority"
                    validation="required"
                    validation-name="Planning Authority"
                    :options="planningAuthoritiesSelect"
                    placeholder="Select Planning Authority"
                    :disabled="disabled"
                />
            </template>
            <template v-if="riverBasinDistricts.length > 1 && value.offset_type.includes('nutrient_offset')">
                <FormulateInput
                    key="river_basin_district_id_1"
                    type="select-plugin"
                    name="river_basin_district_id"
                    label="River Catchment Area"
                    validation="required"
                    validation-name="River Catchment Area"
                    :options="riverBasinDistrictSelect"
                    placeholder="Select River Catchment Area"
                    :disabled="disabled"
                />
            </template>
            <template v-if="companies.length > 1">
                <FormulateInput
                    key="company_id_1"
                    type="select-plugin"
                    name="company_id"
                    label="Company"
                    validation="required"
                    validation-name="Company"
                    :options="companiesSelect"
                    placeholder="Select Company"
                    :disabled="!disabled && !editable"
                />
            </template>
        </div>
        <FormulateInput
            ref="map"
            key="map"
            v-model="tmpValue.map_location"
            name="map_location"
            :item="value"
            type="map"
            map-type="plot"
            :zoom="2"
            default-click-action="draw"
            validation="required|location"
            :validation-rules="{
                location: validateMap
            }"
            :validation-messages="{
                location: mapMessageTextError
            }"
            :errors="[$page.props.errors.address,$page.props.errors.location_data]"
            :disabled="disabled"
            :lpa="currentLpa"
            :fit-bounds="editable ? 'edit' : 'vectors'"
            fit-bounds-change="vectors"
            :edit-fill-color="mapColors.plot"
            :vectors="mapVectors(value.planning_authority_id, value.river_basin_district_id)"
        />
        <FormulateInput
            v-if="invalidCountryCode"
            key="country_code_1"
            type="select-plugin"
            name="country_code"
            validation="required"
            label="We are unable to find a country from the map. Please choose a country"
            validation-name="Country"
            reduce-key="code"
            label-key="name"
            :options="countries"
            placeholder="Country"
        />
    </div>
</template>

<script>
import PlotPages from 'Mixins/plotPages';
import { mapColors } from 'Lib/defaults';

export default {
    name: 'PlotFormPage1',
    mixins: [PlotPages],
    props: {
        companies: {
            type: Array,
            default: () => [],
        },
        countries: {
            type: Array,
            default: () => [],
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        editable: {
            type: Boolean,
            default: false,
        },
        planningAuthorities: {
            type: Array,
            default: () => [],
        },
        riverBasinDistricts: {
            type: Array,
            default: () => [],
        },
        value: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            invalidCountryCode: false,
            tmpValue: this.value,
            currentLpa: null,
            mapColors: mapColors,
            statuses: [
                'Identified land',
                'Ecological Study Certified',
                'Pending contracts',
                'Completed contracts',
                'BNG Units available',
                'Land Fully Implemented',
            ],
            mapMessageTextError: 'Please use the map above to plot your land parcel.',
        };
    },
    computed: {
        offsetOptions() {
            const options = {
                'bng': 'BNG',
            };

            if (this.riverBasinDistricts.length) {
                options['nutrient_offset'] = 'Nutrient Offset';
            }

            return options;
        },

        companiesSelect() {
            return this.companies.map(item => {
                return {
                    label: item.name,
                    value: item.id,
                };
            });
        },

        planningAuthoritiesSelect() {
            const listReturn = [];

            this.planningAuthorities.forEach((item) => {
                if (!this.hasGroup('lpa') || (this.$page.props.loggedUser.LPAs.includes(item.id))) {
                    listReturn.push({
                        value: item.id,
                        label: item.name,
                    });
                }
            });

            return listReturn;
        },

        riverBasinDistrictSelect() {
            const listReturn = [];
            let riverBasinDistricts = this.riverBasinDistricts;

            if (this.value.offset_type.includes('bng') && this.value.planning_authority_id) {
                const riverBasinDistrictsTmp = riverBasinDistricts.filter(river => {
                    const lpaIDs = river.planning_authority.map(lpa => lpa.id);

                    return lpaIDs.includes(parseInt(this.value.planning_authority_id));
                });

                if (riverBasinDistrictsTmp.length) {
                    riverBasinDistricts = riverBasinDistrictsTmp;
                }
            }

            riverBasinDistricts.forEach((item) => {
                listReturn.push({
                    value: parseInt(item.id),
                    label: item.name,
                });
            });

            return listReturn;
        },
    },
    methods: {
        mapChange(value) {
            let countryInvalid = true;

            if (this.value && value) {
                const {
                    inputCountry,
                } = value;


                if (inputCountry) {
                    const country = this.countries.filter(country => country.code === inputCountry);

                    if (country.length) {
                        countryInvalid = false;
                    }
                }
            }

            this.invalidCountryCode = countryInvalid;
        },

        validateMap(value) {
            if (value.value && value.value.layer && ((value.value.layer.paths && value.value.layer.paths.length && value.value.inputGeo) || (value.value.layer.shape && value.value.layer.radius))) {
                return true;
            }

            return false;
        },
    },
};
</script>
