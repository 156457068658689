<template>
    <div class="form-file form-file--bg">
        <SvgController type="paperclip" />
        <a
            :href="url"
            target="_blank"
        >
            {{ name }}
        </a>
        <div class="form-file-date">
            {{ displayDate }}
        </div>
        <SvgController
            v-if="deletable && ($page.props.loggedUser.isSuperUser || $page.props.loggedUser.id === file.user_id)"
            class="form-file-delete"
            type="trashcan"
            @click.native="deleteFile"
        />
    </div>
</template>

<script>
export default {
    name: 'File',
    components: {},

    props: {
        file: {
            type: Object,
            required: true,
        },
        deletable: {
            type: Boolean,
            default: false,
        },
        filetype: {
            type: String,
            default: 'file',
        },
    },
    data() {
        return {
        };
    },

    computed: {
        name() {
            if (this.file.title) {
                return this.file.title;
            }

            return this.file.display_name;
        },

        url() {
            if (this.file.url) {
                return this.file.url;
            }

            return this.$route('attachment.show', this.file.id);
        },

        displayDate() {
            if (this.file.date_published) {
                return this.file.date_published;
            }

            return this.file.created_at;
        },
    },

    methods:{
        deleteFile() {
            this.$confirm(
                {
                    title: `Delete ${this.$options.filters.capitalize(this.filetype)}`,
                    message: `Are you sure you want to delete this ${this.filetype}? This action can't be undone.`,
                    confirmBtnClass: 'btn--red',
                    confirmBtnText: 'Delete',
                    callback: confirm => {
                        if (confirm) {
                            this.$inertia.delete(this.$route('attachment.destroy', this.file.id));
                            this.$confirm.close();
                        }
                    },
                },
            );
        },
    },
};
</script>
