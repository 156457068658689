var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: _vm.context.classes.element,
      attrs: { "data-type": _vm.context.type },
    },
    [
      _c("label", { staticClass: "toggle-checkbox-container" }, [
        _c(
          "input",
          _vm._g(
            _vm._b(
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.context.model,
                    expression: "context.model",
                  },
                ],
                attrs: { type: "text" },
                domProps: { value: _vm.context.model },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.context, "model", $event.target.value)
                  },
                },
              },
              "input",
              _vm.attributes,
              false
            ),
            _vm.$listeners
          )
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "toggle-checkbox-buttons" },
          _vm._l(_vm.context.options, function (option) {
            return _c(
              "div",
              {
                key: option.value,
                staticClass: "toggle-checkbox-buttons--item",
                class: {
                  "toggle-checkbox--active": _vm.context.model === option.value,
                  "toggle-checkbox--disabled": _vm.disabled,
                },
                attrs: { disabled: _vm.disabled },
                on: {
                  click: function ($event) {
                    return _vm.changeValue(option)
                  },
                },
              },
              [
                _vm._v(
                  "\n                " + _vm._s(option.label) + "\n            "
                ),
              ]
            )
          }),
          0
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }