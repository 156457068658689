<template>
    <auth-layout
        :logo="true"
        :component="$options.name"
    >
        <FormulateForm
            :errors="{
                email: errors.email,
            }"
            @submit="form.post($route('password.email'))"
        >
            <div class="form-page">
                <div
                    v-if="flash.status === 'We have emailed your password reset link!'"
                    class="text-center"
                >
                    <div class="icon-success">
                        <SvgController
                            type="circle-tick"
                        />
                    </div>
                    <h3>Password Reset Email Sent</h3>
                    <div class="formulate-input">
                        <p>
                            An email has been sent to your email address, <strong>{{ form.email }}</strong>.
                            <br>
                            Follow the direction in the email to reset your password.
                        </p>
                    </div>
                    <div class="formulate-input">
                        <a
                            class="btn--reset btn btn--secondary"
                            :href="$route('login')"
                        >
                            Back to Login
                        </a>
                    </div>
                </div>
                <div v-else>
                    <h3>Forgotten Your Password?</h3>
                    <div class="formulate-input">
                        <p>No worries! Enter your email address below and if it matches an account, we'll email you with instructions for resetting your password.</p>
                    </div>
                    <FormulateInput
                        v-model="form.email"
                        type="email"
                        name="email"
                        label="Email Address"
                        validation="required|email"
                    />
                    <div class="formulate-input btn--group">
                        <inertia-link
                            :href="$route('login')"
                            class="btn btn--secondary"
                        >
                            Back
                        </inertia-link>
                        <button
                            class="btn--reset btn btn--primary"
                            type="submit"
                        >
                            Submit
                        </button>
                    </div>
                </div>
            </div>
        </FormulateForm>
    </auth-layout>
</template>

<script>
import AuthLayout from 'Components/Layouts/AuthLayout.vue';
export default {
    name: 'ForgotPassword',

    components: {AuthLayout},

    props: {
        errors: {
            type: Object,
            required: true,
        },
        flash: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            form: this.$inertia.form({
                email: null,
            }),
        };
    },
};
</script>
