<template>
    <shape
        v-if="shape === 'circle'"
        :key="key"
        ref="habitatAreaCircles"
        :type="type"
        :habitat-type="layer.type"
        :fill-color="layer.fillColor"
        :theme="layer.theme"
        :all="layer"
        :multiple="multiple"
        :shape="layer.location_data.layer"
        :z-index="2"
        :shape-index="index"
        :item="layer"
        @click="(event) => onClick(event, layer)"
    />
    <google-map-marker
        v-else-if="shape === 'marker'"
        :key="key"
        :icon="icon"
        :position="layer.location_data.layer.points"
        :clickable="hasClick ? true : false"
        @click="(event) => onClick(event, layer)"
    />
    <shape
        v-else-if="shape === 'polygon'"
        :key="key"
        ref="habitatArea"
        :type="type"
        :habitat-type="layer.type"
        :fill-color="layer.fillColor"
        :theme="layer.theme"
        :all="layer"
        :z-index="2"
        :shape="layer.location_data.layer"
        :item="layer"
        @click="(event) => onClick(event, layer)"
    />
</template>

<script>
import MapMixin from 'Mixins/map';
import Shape from './Shape';

export default {
    name: 'MapLayer',
    components: {
        Shape,
    },
    mixins: [
        MapMixin,
    ],
    props: {
        index: {
            type: Number,
            default: 0,
        },
        layer: {
            type: Object,
            default: () => {},
        },
        type: {
            type: String,
            default: 'habitat',
        },
        multiple: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            options: {},
        };
    },
    computed: {
        icon() {
            const icon = this.getMapIcon();

            if (this.layer.fillColor) {
                icon.fillColor = this.layer.fillColor;
            }

            return icon;
        },

        shape() {
            if (this.layer.location_data && this.layer.location_data.layer && this.layer.location_data.layer.shape) {
                return this.layer.location_data.layer.shape;
            }

            return '';
        },
        hasClick() {
            return this.$listeners && (typeof this.$listeners.click === 'function');
        },
        key() {
            return `${Math.floor(Math.random() * 1000)}-${Date.now()}-${Math.floor(Date.now() * Math.random() * 1000)}`;
        },
    },
    methods: {
        onClick(event, layer) {
            this.$emit('click', event, layer);
        },
    },
};
</script>
