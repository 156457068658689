<template>
    <vue-good-table
        :columns="columnFormat"
        :rows="rows"
        :row-style-class="hasRowClick ? 'bng-clickable' : ''"
        @on-row-click="onRowClick"
    >
        <template
            slot="table-column"
            slot-scope="props"
        >
            <span
                :class="{
                    'sortable' : props.column.customSortable,
                    'sorting-asc' : props.column.sortDirection === 'ASC',
                    'sorting-desc' : props.column.sortDirection === 'DESC',
                }"
                :style="props.column.style"
            >
                {{ props.column.label }}
                <button
                    v-if="props.column.customSortable"
                    class="btn-reset tablegrid-sort"
                    @click="handleSort(props.column)"
                >
                    <SvgController type="table-sort" />
                    <SvgController type="table-up" />
                    <SvgController type="table-down" />
                </button>
            </span>
        </template>
        <template
            slot="table-row"
            slot-scope="props"
        >
            <TableGridLayoutTableRow
                :index="props.index"
                :column="props.column"
                :row="props.formattedRow[props.column.field]"
                :all-rows="props.formattedRow"
                :full-row="props.row"
                :init-row="rows[props.index]"
            >
                <template
                    v-for="(_, name) in $scopedSlots"
                    :slot="name"
                    slot-scope="slotData"
                >
                    <slot
                        :name="name"
                        v-bind="slotData"
                    />
                </template>
            </TableGridLayoutTableRow>
        </template>
    </vue-good-table>
</template>

<script>
import { VueGoodTable } from 'vue-good-table';
import TableGridLayoutTableRow from './TableRow';
import filter from 'Mixins/filter';

export default {
    name: 'Table',
    components: {
        VueGoodTable,
        TableGridLayoutTableRow,
    },
    mixins: [filter],
    props: {
        columns: {
            type: Array,
            default() {
                return [];
            },
        },
        rows: {
            type: Array,
            default() {
                return [];
            },
        },
        onSort: {
            type: Function,
            default: () => {},
        },
        hasRowClick: {
            type: Boolean,
            default: false,
        },
        route: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            columnFormat: [],
        };
    },

    updated() {
        this.columnFormat.forEach((objCol) => {
            if ((objCol.sort || objCol.field) === this.$route().params.sortby) {
                if (objCol.sortDirection !== this.$route().params.sortdir) {
                    objCol.sortDirection = this.$route().params.sortdir;
                    objCol.sortby = this.$route().params.sortby;
                }
            } else {
                objCol.sortDirection = '';
            }
        });
    },

    created() {
        // Turn Default Table Sorting off
        this.columnFormat = this.columns.map(({...objCol}, index) => {
            objCol.index = index;
            objCol.customSortable = objCol.sortable === false ? false : true;

            if (objCol.type === 'checkbox' || objCol.type === 'dropdown') {
                objCol.customSortable = false;
            }

            if (objCol.colour) {
                objCol.style = {
                    color: objCol.colour,
                };
            }

            objCol.sortDirection = '';

            if (this.$route().params.sortby === (objCol.sort || objCol.field)) {
                objCol.sortDirection = this.$route().params.sortdir;
            }

            objCol.sortable = false;

            return objCol;
        });
    },
    methods: {
        handleSort(column) {
            const data = this.buildFilter();

            delete data.sortby;
            delete data.sortdir;

            this.columnFormat = this.columnFormat.map((objCol) => {
                if (column.index === objCol.index) {
                    if (objCol.sortDirection === 'ASC') {
                        objCol.sortDirection = 'DESC';
                    } else if (objCol.sortDirection === 'DESC') {
                        objCol.sortDirection = '';
                    } else {
                        objCol.sortDirection = 'ASC';
                    }

                    if ((objCol.sort || objCol.field) && objCol.sortDirection) {
                        data.sortby = objCol.sort || objCol.field;
                        data.sortdir = objCol.sortDirection;
                    }

                    this.onSort(column, objCol.sortDirection);
                } else {
                    objCol.sortDirection = '';
                }

                return objCol;
            });

            this.$inertia.get(this.route || this.$route(this.$route().current()), data, {
                preserveState: true,
                preserveScroll: true,
                only: this.reloadSections,
                replace: true,
            });
        },

        onRowClick(params) {
            if (!params.event.target.classList.contains('vgt-button-dropdown') && !params.event.target.closest('.vgt-button-dropdown')) {
                this.$emit('onRowClick', params);
            }
        },
    },
};
</script>
