<template>
    <app-layout section="planning-applications">
        <page-header
            title="Planning Application Details"
            icon="files"
            :back="{url: $route('planningApplications'),text: 'Planning Applications'}"
        >
            <button
                class="btn-reset btn btn--primary"
                @click="edit"
            >
                Edit BNG Details
            </button>
        </page-header>

        <div class="center details">
            <h5>Application Name</h5>
            <div class="details-header">
                <h3>{{ application.name }}</h3>
            </div>

            <div class="form-flex--lap">
                <div>
                    <div class="details-block details-bg">
                        <div class="formulate-heading">
                            <h6>Application Details</h6>
                        </div>
                        <h5>Reference</h5>
                        <p>{{ application.reference }}</p>
                        <h5>Alternative Reference</h5>
                        <p>{{ application.alternative_reference }}</p>
                        <h5>Date Application Received</h5>
                        <p>{{ application.date_received }}</p>
                        <h5>Address</h5>
                        <p>{{ application.address }}</p>
                        <h5>Status</h5>
                        <p>{{ application.status }}</p>
                        <h5>Environmental Assessment Requested</h5>
                        <p>{{ application.environmental_assessment_requested }}</p>
                        <h4>
                            <a
                                :href="application.url"
                                target="_blank"
                            >Planning application Link</a>
                        </h4>
                    </div>
                    <div class="details-block details-bg">
                        <div class="formulate-heading">
                            <h6>Appeal Details</h6>
                        </div>
                        <h5>Appeal Status</h5>
                        <p>{{ application.appeal_status }}</p>
                        <h5>Appeal Decision</h5>
                        <p>{{ application.appeal_decision }}</p>
                    </div>

                    <div class="details-block details-bg">
                        <div class="formulate-heading">
                            <h6>Applicant</h6>
                        </div>
                        <h5>Applicant Name</h5>
                        <p>{{ application.applicant_name }}</p>
                        <h5>Applicant Address</h5>
                        <p>{{ application.applicant_address }}</p>
                    </div>
                </div>
                <div>
                    <div class="details-block">
                        <div class="formulate-heading">
                            <h6>BNG Details</h6>
                        </div>
                        <h5>Bng Status</h5>
                        <p>{{ application.bng_status }}</p>
                        <h5>Bng Notes</h5>
                        <!-- eslint-disable-next-line vue/no-v-html -->
                        <p v-html="application.bng_notes" />
                        <h5>Bng Next Action Date</h5>
                        <p>{{ application.bng_next_action_date }}</p>
                    </div>
                    <div class="">
                        <div class="formulate-heading">
                            <h6>Documents</h6>
                        </div>
                        <files
                            :files="application.documents"
                            filetype="document"
                            item-type="planning application"
                        />
                    </div>
                </div>
            </div>
        </div>
        <modal ref="editModal">
            <template #header>
                <h3>Edit BNG Details</h3>
            </template>
            <template #body>
                <FormulateForm
                    v-model="form"
                    :errors="form.errors"
                    :schema="formSchema"
                    class="flex-col"
                    @submit="submitForm"
                />
            </template>
        </modal>
    </app-layout>
</template>

<script>
import Files from 'Utilities/files/Files';
import Modal from 'Utilities/modal/Modal';

export default {
    name: 'PlanningApplicationShow',
    components: {
        Files,
        Modal,
    },

    props: {
        application: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            formSchema: [{
                name: 'bng_status',
                type: 'text',
                label: 'BNG Status',
            }, {
                name: 'bng_notes',
                type: 'rich-text',
                label: 'BNG Notes',
            }, {
                name: 'bng_next_action_date',
                type: 'datetime-plugin',
                label: 'BNG Next Action Date',
            }, {
                type: 'submit',
            }],
            form: this.$inertia.form({
                bng_status: this.application.bng_status,
                bng_notes: this.application.bng_notes,
                bng_next_action_date: this.application.bng_next_action_date,
            }),
        };
    },

    mounted() {},

    methods:{
        edit() {
            this.$refs.editModal.openModal();
        },

        submitForm(data) {
            this.$inertia.patch(this.$route('planningApplications.update', this.application.id), data, {
                onSuccess: () => {
                    this.$refs.editModal.closeModal();
                },
            });

            return;
        },
    },
};
</script>
