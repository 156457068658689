var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("FormulateInput", {
        attrs: {
          type: "text",
          name: "name",
          label: "Name",
          validation: "required",
          "validation-name": "Name",
          disabled: _vm.disabled,
        },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "form-flex--tab form-flex--vcenter" },
        [
          _c("FormulateInput", {
            key: "offset_type_1",
            attrs: {
              type: "select-plugin",
              name: "offset_type",
              label: "Offset Type",
              validation: "required",
              "validation-name": "Offset Type",
              options: _vm.offsetOptions,
              placeholder: "Select Offset Type",
              disabled: _vm.disabled,
              multiple: true,
            },
          }),
          _vm._v(" "),
          _vm.hasGroup(["sequest"])
            ? _c("FormulateInput", {
                key: "status_1",
                attrs: {
                  type: "select-plugin",
                  name: "status",
                  label: "Stage",
                  placeholder: "Stage",
                  options: _vm.statuses,
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _vm.planningAuthorities.length > 1 ||
      _vm.riverBasinDistricts.length > 1 ||
      _vm.companies.length > 1
        ? _c(
            "div",
            { staticClass: "form-flex--tab form-flex--vcenter" },
            [
              _vm.planningAuthorities.length > 1 &&
              _vm.value.offset_type.includes("bng")
                ? [
                    _c("FormulateInput", {
                      key: "planning_authority_id_1",
                      attrs: {
                        type: "select-plugin",
                        name: "planning_authority_id",
                        label: "Planning Authority",
                        validation: "required",
                        "validation-name": "Planning Authority",
                        options: _vm.planningAuthoritiesSelect,
                        placeholder: "Select Planning Authority",
                        disabled: _vm.disabled,
                      },
                    }),
                  ]
                : _vm._e(),
              _vm._v(" "),
              _vm.riverBasinDistricts.length > 1 &&
              _vm.value.offset_type.includes("nutrient_offset")
                ? [
                    _c("FormulateInput", {
                      key: "river_basin_district_id_1",
                      attrs: {
                        type: "select-plugin",
                        name: "river_basin_district_id",
                        label: "River Catchment Area",
                        validation: "required",
                        "validation-name": "River Catchment Area",
                        options: _vm.riverBasinDistrictSelect,
                        placeholder: "Select River Catchment Area",
                        disabled: _vm.disabled,
                      },
                    }),
                  ]
                : _vm._e(),
              _vm._v(" "),
              _vm.companies.length > 1
                ? [
                    _c("FormulateInput", {
                      key: "company_id_1",
                      attrs: {
                        type: "select-plugin",
                        name: "company_id",
                        label: "Company",
                        validation: "required",
                        "validation-name": "Company",
                        options: _vm.companiesSelect,
                        placeholder: "Select Company",
                        disabled: !_vm.disabled && !_vm.editable,
                      },
                    }),
                  ]
                : _vm._e(),
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _c("FormulateInput", {
        key: "map",
        ref: "map",
        attrs: {
          name: "map_location",
          item: _vm.value,
          type: "map",
          "map-type": "plot",
          zoom: 2,
          "default-click-action": "draw",
          validation: "required|location",
          "validation-rules": {
            location: _vm.validateMap,
          },
          "validation-messages": {
            location: _vm.mapMessageTextError,
          },
          errors: [
            _vm.$page.props.errors.address,
            _vm.$page.props.errors.location_data,
          ],
          disabled: _vm.disabled,
          lpa: _vm.currentLpa,
          "fit-bounds": _vm.editable ? "edit" : "vectors",
          "fit-bounds-change": "vectors",
          "edit-fill-color": _vm.mapColors.plot,
          vectors: _vm.mapVectors(
            _vm.value.planning_authority_id,
            _vm.value.river_basin_district_id
          ),
        },
        model: {
          value: _vm.tmpValue.map_location,
          callback: function ($$v) {
            _vm.$set(_vm.tmpValue, "map_location", $$v)
          },
          expression: "tmpValue.map_location",
        },
      }),
      _vm._v(" "),
      _vm.invalidCountryCode
        ? _c("FormulateInput", {
            key: "country_code_1",
            attrs: {
              type: "select-plugin",
              name: "country_code",
              validation: "required",
              label:
                "We are unable to find a country from the map. Please choose a country",
              "validation-name": "Country",
              "reduce-key": "code",
              "label-key": "name",
              options: _vm.countries,
              placeholder: "Country",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }