var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "page-header",
      attrs: { "data-background": _vm.background },
    },
    [
      _c("div", { staticClass: "page-header-center flex-space-between" }, [
        _c(
          "div",
          { staticClass: "page-header-left flex-center" },
          [
            _vm.back
              ? _c("div", { staticClass: "page-header-back" }, [
                  _c(
                    "a",
                    { attrs: { href: _vm.back.url } },
                    [
                      _c("SvgController", { attrs: { type: "arrow-left" } }),
                      _vm._v(" "),
                      _c("span", [_vm._v(_vm._s(_vm.back.text))]),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.icon
              ? _c("SvgController", { attrs: { type: _vm.icon } })
              : _vm._e(),
            _vm._v(" "),
            _c("h2", [
              _vm._v(
                "\n                " + _vm._s(_vm.title) + "\n                "
              ),
              _vm.count
                ? _c("span", { staticClass: "page-header-count" }, [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.count) +
                        "\n                "
                    ),
                  ])
                : _vm._e(),
            ]),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "flex-center" },
          [_vm._t("page-header-center")],
          2
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "page-header-right flex-center" },
          [_vm._t("default")],
          2
        ),
      ]),
      _vm._v(" "),
      _vm._t("page-header-bottom"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }