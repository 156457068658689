<template>
    <div>
        <ExcelConvert
            v-if="value.study && !documentUploaded"
            key="excel_convert"
            label="Upload Biodiversity Metric document"
            @input="convertDocument"
        />
        <template
            v-else
        >
            <h2>
                Baseline
            </h2>
            <BNGCalculator
                ref="calcutatorBaseline"
                v-model="tmpValue.onsite_baseline"
                :disabled="true"
                pill=""
                :default-value="[]"
                @input="updateHabitatData"
                @units="updateHabitatData"
            />
            <h2>
                Creation
            </h2>
            <BNGCalculator
                ref="calcutatorCreation"
                v-model="tmpValue.onsite_creation"
                :disabled="true"
                pill=""
                type="creation"
                :default-value="[]"
                @input="updateHabitatData"
                @units="updateHabitatData"
            />
            <h2>
                Enhancement
            </h2>
            <BNGCalculator
                ref="calcutatorEnhancement"
                v-model="tmpValue.onsite_enhancement"
                :disabled="true"
                pill=""
                type="enhancement"
                :default-value="[]"
                :exclude-missing="true"
                @input="updateHabitatData"
                @units="updateHabitatData"
            />
            <div class="form-flex--tab">
                <FormulateInput
                    key="onsite_baseline_total_4"
                    type="fake"
                    name="onsite_baseline_total"
                    label="Onsite baseline total"
                    validation-name="Onsite baseline total"
                    :parse-value="() => floatOutput(value.onsite_baseline_total)"
                />
                <FormulateInput
                    key="onsite_post_intervention_total_4"
                    type="fake"
                    name="onsite_post_intervention_total"
                    label="Onsite Post Intervention total"
                    validation-name="Onsite Post Intervention total"
                    :parse-value="() => floatOutput(value.onsite_post_intervention_total)"
                />
            </div>
            <div class="form-flex--tab">
                <FormulateInput
                    key="onsite_desired_percentage_4"
                    type="number"
                    name="onsite_desired_percentage"
                    label="Desired net gain percentage"
                    placeholder="Desired net gain percentage (%)"
                    validation="required|number|between:9,201"
                    validation-name="Desired net gain percentage"
                />
                <FormulateInput
                    key="desired_total_4"
                    type="fake"
                    name="desired_total"
                    label="Desired offsite BNG units"
                    validation-name="Desired offsite BNG units"
                    :parse-value="() => floatOutput(value.desired_total)"
                />
            </div>
        </template>
    </div>
</template>

<script>
import BNGCalculator from 'Components/Calculator/Bng';
import ExcelConvert from 'Utilities/file-convert/ExcelConvert';
import ExcelConvertMixin from 'Mixins/excelConvert';

export default {
    name: 'ProjectFormPage4',
    components: {
        BNGCalculator,
        ExcelConvert,
    },
    mixins: [
        ExcelConvertMixin,
    ],
    props: {
        editable: {
            type: Boolean,
            default: false,
        },
        value: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            documentUploaded: this.value.study && (
                (this.value.onsite_baseline.length && Object.keys(this.value.onsite_baseline[0]).length) || (this.value.onsite_creation.length && Object.keys(this.value.onsite_creation[0]).length) || (this.value.onsite_enhancement.length && Object.keys(this.value.onsite_enhancement[0]).length)
            ) ? true : false,
            tmpValue: this.value,
            habitatsUnits: {},
            changeTimeout: null,
        };
    },
    methods: {
        convertDocument(workbook, selectedFile) {
            const habitatListBaseline = this.onSiteBaselineConvert(workbook);
            const habitatListCreated = this.onSiteCreatedConvert(workbook);
            const habitatListEnhancement = this.onSiteEnhancementConvert(workbook);

            this.tmpValue.onsite_baseline = [];
            this.tmpValue.onsite_creation = [];
            this.tmpValue.onsite_enhancement = [];

            habitatListBaseline.forEach(habitat => {
                this.tmpValue.onsite_baseline.push(habitat);
            });
            habitatListCreated.forEach(habitat => {
                this.tmpValue.onsite_creation.push(habitat);
            });
            habitatListEnhancement.forEach(habitat => {
                this.tmpValue.onsite_enhancement.push(habitat);
            });
            this.documentUploaded = true;
            this.tmpValue.biodiversity_metric_document = selectedFile;
        },

        updateHabitatData() {
            clearTimeout(this.changeTimeout);
            this.changeTimeout = setTimeout(() => {
                this.habitatsUnits = {
                    baseline: {
                        total: this.$refs.calcutatorBaseline.getUnits('all'),
                        land: this.$refs.calcutatorBaseline.getUnits('land'),
                        hedgerows: this.$refs.calcutatorBaseline.getUnits('hedgerows'),
                        rivers: this.$refs.calcutatorBaseline.getUnits('rivers'),
                    },
                    creation: {
                        total: this.$refs.calcutatorCreation.getUnits('all'),
                        land: this.$refs.calcutatorCreation.getUnits('land'),
                        hedgerows: this.$refs.calcutatorCreation.getUnits('hedgerows'),
                        rivers: this.$refs.calcutatorCreation.getUnits('rivers'),
                    },
                    enhancement: {
                        total: this.$refs.calcutatorEnhancement.getUnits('all'),
                        land: this.$refs.calcutatorEnhancement.getUnits('land'),
                        hedgerows: this.$refs.calcutatorEnhancement.getUnits('hedgerows'),
                        rivers: this.$refs.calcutatorEnhancement.getUnits('rivers'),
                    },
                    retained: {
                        total: this.$refs.calcutatorBaseline.getRetained(),
                        land: this.$refs.calcutatorBaseline.getRetained('land'),
                        hedgerows: this.$refs.calcutatorBaseline.getRetained('hedgerows'),
                        rivers: this.$refs.calcutatorBaseline.getRetained('rivers'),
                    },
                };

                this.$emit('change', this.tmpValue);
            }, 10);
        },
    },
};
</script>
