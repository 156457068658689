var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.address
        ? _c(
            "p",
            [
              _c("SvgController", { attrs: { type: "map-icon" } }),
              _vm._v("\n        " + _vm._s(_vm.address) + "\n    "),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "draw-map",
        _vm._b(
          {
            ref: "map",
            attrs: {
              edit: false,
              "show-geo-input": false,
              zoom: 10,
              type: "plot",
              "show-keys": "",
              "fit-bounds": ["layers", "vectors"],
              layers: _vm.mapProjectLayers(_vm.project),
              vectors: _vm.mapVectors(
                _vm.project.planning_authority_id,
                _vm.project.river_basin_district_id
              ),
            },
          },
          "draw-map",
          _vm.location && _vm.project,
          false
        )
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }