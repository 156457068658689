var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "app-layout",
    { staticClass: "agents", attrs: { section: "agents" } },
    [
      _c(
        "page-header",
        {
          attrs: {
            title: "Invite Agent",
            icon: "tie",
            back: { url: _vm.$route("agents"), text: "All Agents" },
            background: false,
          },
        },
        [
          _c(
            "inertia-link",
            { staticClass: "btn", attrs: { href: _vm.$route("agents") } },
            [_vm._v("\n            Cancel\n        ")]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn-reset btn btn--primary",
              attrs: { id: "form-submit" },
              on: { click: _vm.submitForm },
            },
            [
              _c("SvgController", { attrs: { type: "tick" } }),
              _vm._v(" "),
              !_vm.agent.id
                ? _c("span", [_vm._v("Invite")])
                : _c("span", [_vm._v("Update")]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "center" },
        [
          _c(
            "FormulateForm",
            {
              ref: "form",
              staticClass: "flex-col",
              attrs: { errors: _vm.$page.props.errors },
              model: {
                value: _vm.form,
                callback: function ($$v) {
                  _vm.form = $$v
                },
                expression: "form",
              },
            },
            [
              _c("section", { staticClass: "agent-info" }, [
                _c("h6", { staticClass: "section-header" }, [
                  _vm._v(
                    "\n                    Agent Details\n                "
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "flex-space-between field-row" },
                  [
                    _c("FormulateInput", {
                      attrs: {
                        type: "text",
                        name: "company_name",
                        label: "Company Name",
                        validation: "required",
                        "validation-name": "Company Name",
                        disabled: _vm.editable,
                      },
                    }),
                    _vm._v(" "),
                    _c("FormulateInput", {
                      attrs: {
                        type: "email",
                        name: "email",
                        label: "Email Address",
                        validation: "required|email",
                        "validation-name": "Email address",
                        disabled: _vm.editable,
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "flex-space-between field-row" },
                  [
                    _c("FormulateInput", {
                      attrs: {
                        type: "text",
                        name: "first_name",
                        label: "First Name",
                        validation: "required",
                        "validation-name": "First Name",
                        disabled: _vm.editable,
                      },
                    }),
                    _vm._v(" "),
                    _c("FormulateInput", {
                      attrs: {
                        type: "text",
                        name: "last_name",
                        label: "Surname",
                        validation: "required",
                        "validation-name": "Surname",
                        disabled: _vm.editable,
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c(
                "section",
                { staticClass: "agent-permissions" },
                [
                  _c("h6", { staticClass: "section-header" }, [
                    _vm._v(
                      "\n                    Permissions\n                "
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "FormulateInput",
                    { attrs: { type: "group", name: "permissions" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          type: "toggle-button",
                          name: "item_save",
                          label: `Create New ${_vm.itemsTitle}`,
                          description: `Agent is allowed to create new ${_vm.itemsName} on your behalf.`,
                          "has-label": false,
                        },
                      }),
                      _vm._v(" "),
                      _c("FormulateInput", {
                        attrs: {
                          type: "toggle-button",
                          name: "item_all",
                          label: `Access All ${_vm.itemsTitle}`,
                          description: `Agent will be able to access and make changes to all your ${_vm.itemsName}. Turn this option off to select specific ${_vm.itemsName} for the Agent to access.`,
                        },
                        on: { input: _vm.updateItems },
                      }),
                      _vm._v(" "),
                      _c(
                        "section",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.form.permissions[0].item_all,
                              expression: "!form.permissions[0].item_all",
                            },
                          ],
                          staticClass: "agent-projects",
                        },
                        [
                          _c("h6", { staticClass: "section-header" }, [
                            _vm._v(
                              "\n                            allowed " +
                                _vm._s(_vm.itemsName) +
                                "  "
                            ),
                            _c("span", [
                              _vm._v("|  "),
                              _c("span", [
                                _vm._v(
                                  "mark " +
                                    _vm._s(_vm.itemsName) +
                                    " you want the agent to access"
                                ),
                              ]),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c(
                            "FormulateInput",
                            { attrs: { type: "group", name: "items" } },
                            [
                              _vm.agent.group === 2
                                ? _c("plot-table", {
                                    attrs: {
                                      plots: _vm.itemList,
                                      "has-dropdown": false,
                                      "filter-route": _vm.filterRoute,
                                      "has-checkbox": true,
                                      "has-items": _vm.hasItems,
                                      output: ["table"],
                                    },
                                  })
                                : _vm.agent.group === 3
                                ? _c("project-table", {
                                    attrs: {
                                      projects: _vm.itemList,
                                      "has-dropdown": false,
                                      "filter-route": _vm.filterRoute,
                                      "has-checkbox": true,
                                      "has-items": _vm.hasItems,
                                      output: ["table"],
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("FormulateInput", {
                        attrs: {
                          type: "toggle-button",
                          name: "contract_view",
                          label: "View Contracts",
                          description:
                            "Agent is allowed to view and edit all your contracts.",
                        },
                      }),
                      _vm._v(" "),
                      _c("FormulateInput", {
                        attrs: {
                          type: "toggle-button",
                          name: "contract_confirm",
                          label: "Can Confirm Contracts",
                          description:
                            "Agent is allowed to confirm contracts of your behalf.",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }