var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.rental.project === null || _vm.rental.plot === null
    ? _c("empty", {
        attrs: { type: "error" },
        scopedSlots: _vm._u(
          [
            {
              key: "message",
              fn: function () {
                return [_c("h3", [_vm._v(_vm._s(_vm.errorMessage))])]
              },
              proxy: true,
            },
          ],
          null,
          false,
          1096529764
        ),
      })
    : _c("report", {
        attrs: {
          type: "rental",
          "report-data": {
            rental: _vm.rental,
            title: _vm.title,
          },
          filename: _vm.filename,
        },
      })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }