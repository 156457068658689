var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      !_vm.$page.props.hasItems
        ? _c("empty", { attrs: { type: "operating in areas" } })
        : _c(
            "div",
            [
              _c("TableGridLayout", {
                attrs: {
                  columns: _vm.columns,
                  rows: _vm.localPlanningAuthorities.data,
                  "has-header": _vm.hasHeader,
                  "has-footer": _vm.hasFooter,
                  output: ["table"],
                  "grid-layout-for-table": _vm.gridLayout,
                },
                scopedSlots: _vm._u([
                  {
                    key: "header-left",
                    fn: function () {
                      return [
                        _c("list-filter", {
                          attrs: {
                            route: _vm.filterRoute,
                            "search-placeholder": "Search LPAs",
                            section: "local-planning-authorities",
                          },
                        }),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "footer",
                    fn: function () {
                      return [
                        _c("pagination", {
                          attrs: {
                            links: _vm.localPlanningAuthorities.links,
                            section: "local-planning-authorities",
                          },
                        }),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }