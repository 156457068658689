<template>
    <modal ref="edit">
        <template #header>
            <h3>Edit {{ localPlanningAuthority.company_name }}</h3>
        </template>
        <template #body>
            <FormulateForm
                ref="form"
                v-model="form"
                :errors="$page.props.errors"
                class="flex-col"
                @submit="submitForm"
            >
                <FormulateInput
                    type="number"
                    name="min_price_per_unit"
                    label="Min Price Per Unit"
                />

                <FormulateInput
                    type="number"
                    name="max_price_per_unit"
                    label="Max Price Per Unit"
                />

                <FormulateInput
                    type="url"
                    name="url"
                    label="Website URL"
                    validation="url"
                />

                <div class="formulate-input btn--group">
                    <a
                        href="#"
                        class="btn"
                        @click="$refs.edit.closeModal"
                    >
                        Cancel
                    </a>
                    <button
                        class="btn--reset btn btn--primary"
                        type="submit"
                    >
                        <SvgController type="tick" />
                        Save
                    </button>
                </div>
            </FormulateForm>
        </template>
    </modal>
</template>

<script>
import Modal from 'Utilities/modal/Modal';
export default {
    components: {
        Modal,
    },

    props: {},

    data() {
        return {
            form: this.$inertia.form({
                min_price_per_unit: null,
                max_price_per_unit: null,
                url: null,
            }),
        };
    },

    methods:{
        open(lpa) {
            this.localPlanningAuthority = lpa;
            this.form.min_price_per_unit = this.localPlanningAuthority.min_price;
            this.form.max_price_per_unit = this.localPlanningAuthority.max_price;
            this.form.url = this.localPlanningAuthority.url;

            if (this.form.min_price_per_unit === '-') {
                this.form.min_price_per_unit = null;
            }

            if (this.form.max_price_per_unit === '-') {
                this.form.max_price_per_unit = null;
            }

            this.$refs.edit.openModal();
        },

        submitForm() {
            const route = this.$route('localPlanningAuthority.update', this.localPlanningAuthority.planning_auth_id);

            this.form.patch(route, {
                onSuccess: () => {
                    this.$refs.edit.closeModal();
                },
            });
        },
    },
};
</script>
