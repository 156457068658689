var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "app-layout",
    { attrs: { section: "profile" } },
    [
      _c(
        "page-header",
        { attrs: { title: "My Profile", icon: "user" } },
        [
          _c(
            "inertia-link",
            {
              staticClass: "btn btn--primary",
              attrs: { href: _vm.$route("profile.edit") },
            },
            [_vm._v("\n            Edit Profile\n        ")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "center" }, [
        _c("div", { staticClass: "profile-info" }, [
          _c("div", { staticClass: "profile-info-img flex-center" }, [
            _c("span", [_vm._v(_vm._s(_vm.userInitials))]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "profile-info-content" }, [
            _c("h3", [
              _vm._v(
                _vm._s(
                  _vm.loggedUser.first_name + " " + _vm.loggedUser.last_name
                )
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "profile-info-content-email" },
              [
                _c("h5", [_vm._v("Email Address")]),
                _vm._v(" "),
                _c("div", [
                  _vm._v(
                    "\n                        " +
                      _vm._s(_vm.loggedUser.email) +
                      "\n                        "
                  ),
                  _c("p", [
                    _c(
                      "a",
                      {
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            return _vm.changePassword()
                          },
                        },
                      },
                      [_vm._v("Change password")]
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _vm.flash.status === "password-updated"
                  ? _c("alert", { attrs: { type: "success", inline: true } }, [
                      _c("p", [_vm._v("Password updated successfully.")]),
                    ])
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "profile-info-content-company" },
              [
                _c("h5", [
                  _vm._v(
                    _vm._s(_vm.positions.length > 1 ? "Companies" : "Company")
                  ),
                ]),
                _vm._v(" "),
                _vm._l(_vm.positions, function (position) {
                  return _c(
                    "ul",
                    { key: position.id, staticClass: "lst-reset" },
                    [
                      _c("li", [
                        _vm.loggedUser.can.includes("main_admin_edit")
                          ? _c(
                              "a",
                              {
                                attrs: { href: "#" },
                                on: {
                                  click: function ($event) {
                                    return _vm.updateCompany(
                                      position.company,
                                      position.group.name
                                    )
                                  },
                                },
                              },
                              [_vm._v(_vm._s(position.company.name))]
                            )
                          : _c("span", [_vm._v(_vm._s(position.company.name))]),
                        _vm._v(" "),
                        _vm.positions.length > 1
                          ? _c("span", [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(position.role.label) +
                                  "\n                            "
                              ),
                            ])
                          : _vm._e(),
                      ]),
                    ]
                  )
                }),
              ],
              2
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("modal", {
        ref: "passwordModal",
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function () {
              return [_c("h3", [_vm._v("Change Password")])]
            },
            proxy: true,
          },
          {
            key: "body",
            fn: function () {
              return [
                _c("update-password", {
                  attrs: { errors: _vm.errors },
                  on: { closeModal: _vm.closePasswordModal },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _vm._v(" "),
      _c("modal", {
        ref: "companyModal",
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function () {
              return [_c("h3", [_vm._v("Edit Company")])]
            },
            proxy: true,
          },
          {
            key: "body",
            fn: function () {
              return [
                _c("CompanyEdit", {
                  attrs: {
                    company: _vm.editCompany,
                    "company-type": _vm.editCompanyType,
                  },
                  on: { closeModal: _vm.closeCompanyModal },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }