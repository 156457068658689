var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("modal", {
    ref: "settingModal",
    scopedSlots: _vm._u([
      {
        key: "header",
        fn: function () {
          return [_c("h3", [_vm._v("Edit Setting")])]
        },
        proxy: true,
      },
      {
        key: "body",
        fn: function () {
          return [
            _c("FormulateForm", {
              staticClass: "flex-col",
              attrs: { errors: _vm.form.errors, schema: _vm.formSchema },
              on: { submit: _vm.submitForm },
              model: {
                value: _vm.form,
                callback: function ($$v) {
                  _vm.form = $$v
                },
                expression: "form",
              },
            }),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }