var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "formulate-heading flex-space-between" }, [
        _c("h6", [_vm._v("Recent Audits")]),
        _vm._v(" "),
        _vm.showNewButton
          ? _c(
              "button",
              {
                staticClass:
                  "btn-reset btn--link btn--link--green btn--link--icon--left",
                on: { click: _vm.openSubmissionModal },
              },
              [
                _c("SvgController", { attrs: { type: "plus" } }),
                _vm._v("\n            New Audit\n        "),
              ],
              1
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("TableGridLayout", {
        attrs: {
          columns: _vm.columns,
          rows: _vm.rental.updates,
          mode: "remote",
          "has-header": false,
          "has-footer": false,
          "has-dropdown": false,
          "total-records": _vm.rental.updates.length,
          output: ["table"],
        },
        on: { onRowClick: _vm.onRowClick },
      }),
      _vm._v(" "),
      _c("Modal", {
        ref: "newSubmissionModal",
        staticClass: "modal--submission",
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function () {
              return [_c("h3", [_vm._v("New Agreement Audit")])]
            },
            proxy: true,
          },
          {
            key: "body",
            fn: function () {
              return [
                _c("Alert", { attrs: { type: "info" } }, [
                  _c("p", [
                    _vm._v(
                      "Please upload photographs and provide further details to show that you are maintaining the land as per your BNG Agreement"
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "FormulateForm",
                  {
                    ref: "submissionForm",
                    staticClass: "formulate-form--plot flex-col",
                    attrs: { errors: _vm.form.errors },
                    on: { submit: _vm.submitUpdate },
                    model: {
                      value: _vm.form,
                      callback: function ($$v) {
                        _vm.form = $$v
                      },
                      expression: "form",
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "details" },
                      [
                        _c("FormulateInput", {
                          attrs: {
                            type: "textarea",
                            name: "description",
                            label: "Audit Details",
                            validation: "required",
                            "validation-name": "Details",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("FormulateInput", {
                      attrs: {
                        type: "file",
                        name: "documents",
                        accept:
                          ".pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                        validation:
                          "mime:application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                        "upload-url": "/attachments/upload",
                        "upload-behavior": "live",
                        multiple: "",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "label",
                          fn: function () {
                            return [
                              _c("FileLabel", {
                                attrs: {
                                  id: "documents",
                                  label: "Documents",
                                  text: "Upload documents",
                                },
                              }),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("Files", {
                      attrs: {
                        files: _vm.formDocs,
                        filetype: "document",
                        deletable: false,
                      },
                    }),
                    _vm._v(" "),
                    _c("FormulateInput", {
                      attrs: {
                        type: "file",
                        name: "photos",
                        accept: "image/jpeg,image/png,image/gif",
                        validation: "mime:image/jpeg,image/png,image/gif",
                        "upload-url": "/attachments/upload",
                        "upload-behavior": "live",
                        multiple: "",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "label",
                          fn: function () {
                            return [
                              _c("FileLabel", {
                                attrs: {
                                  id: "photos",
                                  label: "Photos",
                                  text: "Upload photos",
                                },
                              }),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("Gallery", {
                      attrs: { images: _vm.formPhotos, deletable: false },
                    }),
                    _vm._v(" "),
                    _c("FormulateInput", { attrs: { type: "submit" } }),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _vm._v(" "),
      _c("Modal", {
        ref: "updateModal",
        staticClass: "modal--submission",
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function () {
              return [
                _c("h3", [
                  _vm._v("Agreement Audit"),
                  _c("span", { staticClass: "modal-header-date" }, [
                    _vm._v(_vm._s(_vm.submission.created_at)),
                  ]),
                ]),
              ]
            },
            proxy: true,
          },
          {
            key: "body",
            fn: function () {
              return [
                _c("ShowUpdate", {
                  attrs: { update: _vm.submission },
                  on: { closeModal: _vm.closeUpdateModal },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }