var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("HabitatFormComponent", {
    ref: "habitatForm",
    attrs: {
      edit: _vm.edit,
      lpas: _vm.lpas,
      rivers: _vm.rivers,
      "default-filters": {
        lpa: true,
        plots: true,
        river_basin_districts: true,
        habitats: true,
      },
      "save-id": _vm.plot.id,
      "save-type": "plot-habitat",
      habitats: _vm.habitats,
      disabled: !_vm.value,
      sidebar: _vm.value,
      "show-layers-text": _vm.showLayersText,
      "url-type": "plots",
    },
    on: {
      "submit-start": _vm.submitStart,
      "submit-complete": _vm.submitComplete,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }