var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "user-profile-dropdown" },
    [
      _c(
        "DropdownMenu",
        {
          model: {
            value: _vm.show,
            callback: function ($$v) {
              _vm.show = $$v
            },
            expression: "show",
          },
        },
        [
          _c(
            "button",
            { staticClass: "btn-reset flex-center site-header-item-button" },
            [
              _c(
                "div",
                { staticClass: "user-profile-dropdown-image flex-center" },
                [_c("span", [_vm._v(_vm._s(_vm.userInitials))])]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "user-profile-dropdown-info" }, [
                _c("div", { staticClass: "user-profile-dropdown-info-name" }, [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.loggedUser.first_name) +
                      " " +
                      _vm._s(_vm.loggedUser.last_name) +
                      "\n                "
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "user-profile-dropdown-info-company" },
                  [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.loggedUser.positions[0].company.name) +
                        "\n                "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "user-profile-dropdown-down" },
                  [_c("SvgController", { attrs: { type: "caret-down" } })],
                  1
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { attrs: { slot: "dropdown" }, slot: "dropdown" },
            [_c("dropdownMenuList", { attrs: { data: _vm.data } })],
            1
          ),
        ]
      ),
      _vm._v(" "),
      !_vm.loggedUser.isImpersonator
        ? _c("form", {
            attrs: {
              id: "logoutForm",
              method: "POST",
              action: _vm.$route("logout"),
            },
          })
        : _c("form", {
            attrs: {
              id: "logoutForm",
              method: "GET",
              action: "/impersonate/stop",
            },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }