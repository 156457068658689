var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.files && _vm.files.length
    ? _c(
        "div",
        { staticClass: "form-file-list" },
        _vm._l(_vm.files, function (file) {
          return _c(
            "div",
            { key: file.id },
            [
              _c("file", {
                attrs: {
                  file: file,
                  deletable: _vm.deletable,
                  filetype: _vm.filetype,
                },
              }),
            ],
            1
          )
        }),
        0
      )
    : _c("p", { staticClass: "text-grey4" }, [
        _vm._v(
          "\n    This " +
            _vm._s(_vm.itemType) +
            " has no " +
            _vm._s(_vm.filetype) +
            "s\n"
        ),
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }