var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.show
    ? _c(
        "li",
        {
          staticClass: "page-header-tabs-list-item",
          class: {
            "page-header-tabs-list-item--active": _vm.selected,
            "page-header-tabs-list-item--disabled": _vm.disabled,
          },
          attrs: { type: _vm.type },
        },
        [
          _vm._t("list-item"),
          _vm._v(" "),
          _c(
            "tab-button",
            {
              attrs: {
                link: _vm.link,
                type: _vm.type,
                title: _vm.title,
                only: _vm.only,
                disabled: _vm.disabled,
                selected: _vm.selected,
              },
              on: { click: _vm.handleClick },
            },
            [
              _vm._t("button-before"),
              _vm._v(" "),
              _vm.tabId && _vm.$scopedSlots[`button(${_vm.tabId})`]
                ? _vm._t(`button(${_vm.tabId})`)
                : _vm._t("button", function () {
                    return [
                      _vm._t("default", function () {
                        return [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.title) +
                              "\n            "
                          ),
                        ]
                      }),
                    ]
                  }),
              _vm._v(" "),
              _vm._t("button-after"),
            ],
            2
          ),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }