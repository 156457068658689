<template>
    <div>
        <TableGridLayout
            v-if="rentals.length > 0"
            :columns="columns"
            :rows="rentals"
            mode="remote"
            :total-records="rentals.length"
            :grid-layout="gridLayout"
            :output="['table']"
            @onRowClick="onRowClick"
        />
        <Empty
            v-else
            type="agreements"
        >
            <inertia-link
                v-if="hasGroup(['sequest', 'lpa']) && hasPermission('rental_save')"
                class="btn-reset btn btn--primary"
                :href="$route('rental.create', $page.props.plot.id)"
            >
                Create BNG Agreement
            </inertia-link>
        </Empty>
    </div>
</template>
<script>
import Empty from 'Utilities/empty/Empty';
export default {
    name: 'Rentals',
    components: {
        Empty,
    },

    props: {},
    data() {
        return {
            columns: [],
            gridLayout: {},
        };
    },
    computed: {
        rentals() {
            return this.$page.props.leases;
        },
        plot() {
            return this.$page.props.plot;
        },
    },
    created() {
        this.columns = [{
            label: 'Date Agreement Started',
            field: 'rental_startdate',
            sortable: true,
        },{
            label: 'Agreement Period',
            field: 'rental_period',
            sortable: true,
        },{
            label: 'Annual Payment',
            field: 'annual_rent',
            sortable: true,

        },{
            label: 'Date next audit due',
            field: 'next_audit_date',
            sortable: true,
        }];

        this.gridLayout = {
            title: 'rental_startdate',
            description: 'Period: ${rental_period}',
            status: ['Annual Rent: ${annual_rent}', 'Next Audit: ${next_audit_date}'],
            onClick: (evt, row, index) => {
                this.goToRental({row: this.rentals.data[index]});
            },
        };
    },
    methods: {
        onRowClick({ row }) {
            this.$inertia.visit(this.$route('rental.show', row.id));
        },
    },
};
</script>
