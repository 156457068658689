<template>
    <HabitatFormComponent
        ref="habitatForm"
        :edit="edit"
        :lpas="lpas"
        :rivers="rivers"
        :default-filters="{
            lpa: true,
            plots: true,
            river_basin_districts: true,
            habitats: true,
        }"
        :save-id="plot.id"
        save-type="plot-habitat"
        :habitats="habitats"
        :disabled="!value"
        :sidebar="value"
        :show-layers-text="showLayersText"
        url-type="plots"
        @submit-start="submitStart"
        @submit-complete="submitComplete"
    />
</template>

<script>
import HabitatFormComponent from 'Components/habitats/Form';

export default {
    name: 'PlotHabitats',
    components: {
        HabitatFormComponent,
    },

    props: {
        edit: {
            type: Boolean,
            default: false,
        },
        plot: {
            type: Object,
            default: () => ({}),
        },
        habitats: {
            type: Array,
            default: () => ([]),
        },
        lpas: {
            type: Array,
            default: () => ([]),
        },
        rivers: {
            type: Array,
            default: () => ([]),
        },
        value: {
            type: Boolean,
            default: false,
        },
        isSelected: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            currentHabitat: -1,
            showLayersText: ['Show Habitats', 'Hide Habitats'],
            form: {
                habitats: [...this.$page.props.habitats],
            },
            habitatTypes: {
                'woodland': 'Woodland',
                'hedgerows': 'Hedgerows',
                'grassland': 'Grassland',
                'water': 'Water',
                'nature_reserves': 'Nature Reserves',
                'sssi': 'SSSI (Sites of Special Scientific Interest)',
                'national_conservation_sites': 'National Conservation Sites',
                'house': 'House',
                'barn': 'Barn',
                'shed': 'Shed',
                'associated_land': 'Associated Land',
            },
            uploading: false,
        };
    },
    computed: {
        isEditing: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            },
        },
    },

    mounted() {
        if (this.form.habitats.length === 0) {
            this.currentHabitat = 0;
        }
    },

    methods: {
        habitatClick(idx) {
            if (this.isEditing) {
                this.currentHabitat = idx;

                return;
            }

            this.$refs.map.showInfoBox(this.form.habitats[idx], 'habitat');

        },

        submitStart(data) {
            this.uploading = true;
            this.$emit('submit-start', data);
        },

        submitComplete(data) {
            this.uploading = false;
            this.$emit('submit-complete', data);
        },

        submitFormButton() {
            if (!this.uploading) {
                this.$refs.habitatForm.submitFormButton();
            }
        },

        addHabitat(habitats) {
            this.currentHabitat = habitats.length - 1;
        },

        removeHabitat(event) {
            this.currentHabitat = event.length - 1;
        },

        cancelEditing() {
            this.currentHabitat = -1;
            this.form.habitats = [...this.$page.props.habitats];
        },
    },
};
</script>
