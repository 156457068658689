<template>
    <div>
        <empty
            v-if="!$page.props.hasItems"
            type="land parcels"
        >
            <inertia-link
                v-if="$page.props.loggedUser.can.includes('plot_save')"
                class="btn-reset btn btn--primary"
                :href="$route('plot.create')"
            >
                <SvgController type="plus" />
                <span>Create My First Land Parcel</span>
            </inertia-link>
        </empty>
        <div v-else>
            <TableGridLayout
                :columns="columns"
                :rows="dataFormat"
                mode="remote"
                :total-records="plots.total"
                :grid-layout="gridLayout"
                :has-header="hasHeader"
                :has-footer="hasFooter"
                :output="output"
                @onRowClick="onRowClick"
            >
                <template #header-left>
                    <list-filter
                        :fields="filterOptions"
                        :route="filterRoute"
                        search-placeholder="Search plots"
                        section="plots"
                    />
                </template>
                <template
                    v-for="(_, name) in $scopedSlots"
                    :slot="name"
                    slot-scope="slotData"
                >
                    <slot
                        v-if="name.indexOf('cell(') === 0"
                        :name="name"
                        v-bind="slotData"
                    />
                </template>
                <template
                    v-if="plots.links"
                    #footer
                >
                    <pagination
                        :links="plots.links"
                        section="plots"
                    />
                </template>
            </TableGridLayout>
            <modal
                ref="locationModal"
                class="modal--location"
            >
                <template #header>
                    <h3>Land Parcel Location</h3>
                </template>
                <template #body>
                    <p><SvgController type="map-icon" />{{ locationData.address }}</p>
                    <draw-map
                        ref="map"
                        :edit="false"
                        :land-parcels="[locationData.plot]"
                        :leases="locationData.rentalLocations"
                        :show-geo-input="false"
                        :lat-long="locationData.location_data.layer.center"
                        :zoom="10"
                        type="plot"
                        show-keys
                        fit-bounds="landParcels"
                    />
                    <p>&nbsp;</p>
                </template>
            </modal>
        </div>
    </div>
</template>
<script>
import Pagination from 'Utilities/pagination/Pagination';
import ListFilter from 'Utilities/list-filter/ListFilter';
import Modal from 'Utilities/modal/Modal';
import DrawMap from 'Utilities/maps/DrawMap';
import Empty from 'Utilities/empty/Empty';

export default {
    name: 'PlotTable',
    components: {
        Pagination,
        ListFilter,
        Modal,
        DrawMap,
        Empty,
    },
    props: {
        plots: {
            type: Object,
            default: () => ({}),
        },

        hasDropdown: {
            type: Boolean,
            default: true,
        },

        filterRoute: {
            type: String,
            default: null,
        },

        allowSorting: {
            type: Boolean,
            default: true,
        },

        hasCheckbox: {
            type: Boolean,
            default: false,
        },

        hasHeader: {
            type: Boolean,
            default: true,
        },

        hasFooter: {
            type: Boolean,
            default: true,
        },
        output: {
            type: Array,
            default() {
                return ['table', 'grid'];
            },
        },

        forceHideCompany: {
            type: Boolean,
            default: false,
        },

        tableColumns: {
            type: Array,
            default: null,
        },
    },
    data() {
        return {
            filterOptions: [{
                name: 'status',
                placeholder: 'All statuses',
                options: this.$page.props.statuses,
                type: 'select-plugin',
            },{
                name: 'date',
                placeholder: 'All dates',
                type: 'daterange-plugin',
            }],
            dropdown: [],
            gridLayout: {},
            locationData: {},
            plot: {},
        };
    },
    computed: {
        dataFormat() {
            return this.plots.data.map(plot => ({...plot, ...{
                offset_type: plot.offset_type.map(offset => this.capitalizeFirstLetter(offset.name.replace(/_/g, ' ').replace('bng', 'BNG'))).join(', '),
                area: (`${plot.local_planning_authority ? plot.local_planning_authority.name : ''}, ${plot.river_basin_district ? plot.river_basin_district.name : ''}`).replace(/,\s$|^,\s/, ''),
            }}));
        },

        companies() {
            return this.$page.props.companies.map((company) => {
                return {
                    value: company.id,
                    label: company.name,
                };
            });
        },

        columns() {
            if (this.tableColumns) {
                return this.tableColumns;
            }

            const columns = [];

            if (this.hasCheckbox) {
                columns.push({
                    label: '',
                    field: 'checkbox',
                    type: 'checkbox',
                    selected: 'selected',
                    sortable: this.allowSorting,
                });
            }

            columns.push({
                label: 'Title',
                field: 'name',
                sortable: this.allowSorting,
            }, {
                label: 'Offset Type',
                field: 'offset_type',
                sortable: this.allowSorting,
            },{
                label: 'Area',
                field: 'area',
                sortable: this.allowSorting,
            });

            if (this.hasGroup(['sequest','agent']) && !this.forceHideCompany) {
                columns.push({
                    label: 'company',
                    field: 'company.name',
                    sortable: this.allowSorting,
                }, {
                    label: 'Status',
                    field: 'status',
                    sortable: this.allowSorting,
                    spanClass: 'status-pill type-{status}',
                });
            }

            columns.push({
                label: 'Date Added',
                field: 'created_at',
                sortable: this.allowSorting,
            });

            if (this.hasDropdown) {
                columns.push({
                    label: '',
                    field: 'permissions',
                    type: 'dropdown',
                    sortable: false,
                    position: 'right',
                    options: this.dropdown,
                });
            }

            return columns;

        },
    },
    created() {
        if (this.hasDropdown) {
            this.dropdown = [{
                name: 'Edit Land Parcel',
                border: false,
                show: 'editable',
                onClick:(evt, row, index) => {
                    this.$inertia.visit(this.$route('plot.edit', this.plots.data[index].id));
                },
            },{
                name: 'Show Land Parcel Location',
                onClick:(evt, row, index) => {
                    this.showLocation(this.plots.data[index]);
                },
            },{
                name: 'Delete Land Parcel',
                show: 'deletable',
                color: 'red',
                onClick: (evt, row, index) => {
                    this.deletePlot(this.plots.data[index]);
                },
            }];
        }

        this.gridLayout = {
            title: 'name',
            description: 'address',
            status: 'status',
            showMap: true,
            type: 'plot',
            onClick: (evt, row, index) => {
                this.$inertia.visit(this.$route('plot.show', this.plots.data[index].id));
            },
        };

        if (this.hasGroup(['sequest','agent'])) {
            this.gridLayout.company = 'company';
        }

        if (this.$page.props.nationalCharacterAreas && this.$page.props.loggedUser.isSuperUser) {
            this.filterOptions.push({
                name: 'national-character-areas',
                placeholder: 'All Areas',
                options: this.$page.props.nationalCharacterAreas.map(area => ({label: area.name, value: area.id})),
                type: 'select-plugin',
            });
        }
    },
    methods: {
        deletePlot(plot) {
            this.$confirm(
                {
                    title: 'Delete Land Parcel',
                    message: 'Are you sure you want to delete this plot? This action can\'t be undone.',
                    confirmBtnClass: 'btn--red',
                    confirmBtnText: 'Delete',
                    callback: confirm => {
                        if (confirm) {
                            this.$inertia.delete(this.$route('plot.destroy', plot.id));
                            this.$confirm.close();
                        }
                    },
                },
            );
        },
        returnLatLng(latLng) {
            if (latLng && typeof latLng === 'string') {
                return JSON.parse(latLng);
            }

            return {};
        },
        showLocation(plot) {
            this.locationData.plot = plot;
            this.locationData.address = plot.address;
            this.locationData.location_data = plot.location_data;
            this.locationData.rentalLocations = plot.rentalLocations;
            this.$refs.locationModal.openModal();
        },
        onRowClick({ row }) {
            this.$inertia.visit(this.$route('plot.show', row.id));
        },
    },
};
</script>
