<template>
    <section
        v-show="loaded"
        class="map-select"
        :class="{
            'map-select--disabled': disabled,
            'map-select--fullscreen': fullScreenEnabled
        }"
    >
        <div
            v-if="showGeoInput"
            class="map-select-geo"
        >
            <SvgController type="map-icon" />
            <input
                ref="geoInput"
                v-model="data.inputGeo"
                type="text"
                class="map-select-geo-input"
                placeholder="Address"
                @keyup="setGeoLocationKey"
            >
            <button
                type="button"
                :class="`btn-reset map-select-geo-button ${geoInputEnabled ? ' active' : ''}`"
                title="Get Location"
                @click.prevent="toggleGeoLocation"
            >
                <SvgController type="location-arrow" />
            </button>
        </div>
        <div
            class="map-select-wrapper"
            :class="{'map-select-wrapper--habitat': type === 'habitat'}"
        >
            <template v-if="edit">
                <google-map-autocomplete
                    ref="autoComplete"
                    placeholder="Search"
                    :update-map="false"
                    :controls="false"
                    @place-changed="placeChanged"
                >
                    <template
                        slot="after-input"
                    >
                        <SvgController
                            type="search"
                        />
                    </template>
                </google-map-autocomplete>
                <div
                    class="map-select-header flex-space-between"
                >
                    <div class="map-select-header-left" />
                    <div class="map-select-header-right flex">
                        <template v-if="!disabled">
                            <div
                                v-if="message"
                                class="map-select-header-right-group"
                            >
                                <p>{{ message }}</p>
                            </div>
                            <template v-if="editType === 'shape'">
                                <div
                                    v-if="showLayersEnabled"
                                    class="map-select-header-right-group flex"
                                >
                                    <map-button
                                        title="Toggle Habitats"
                                        name="habitatToggle"
                                        :active="showLayers"
                                        @click="showLayers = !showLayers"
                                    >
                                        {{ habitatBtnText }}
                                    </map-button>
                                </div>
                                <div class="map-select-header-right-group flex">
                                    <map-button
                                        :disabled="historyIndex === 1"
                                        title="Undo"
                                        name="undo"
                                        icon="undo"
                                        @click="undo"
                                    />
                                    <map-button
                                        :disabled="history.length === historyIndex"
                                        title="Redo"
                                        name="redo"
                                        icon="redo"
                                        @click="redo"
                                    />
                                </div>
                                <div class="map-select-header-right-group flex">
                                    <map-button
                                        :active="clickAction === 'pan'"
                                        title="Pan"
                                        name="pan"
                                        icon="hand"
                                        @click="setAction('pan')"
                                    />
                                    <map-button
                                        v-if="type === 'lease' && landParcels[0].location_data && !Object.keys(data).length"
                                        title="Duplicate Area"
                                        name="duplicate_area"
                                        icon="plus"
                                        :icon-style="{
                                            fontSize: '0.75em'
                                        }"
                                        @click="duplicateArea"
                                    />
                                    <DropdownMenu
                                        v-model="show"
                                        dropdown-menu-class="dropdown-menu--map"
                                        trigger="hover"
                                    >
                                        <map-button
                                            :active="clickAction === 'draw'"
                                            title="Add Shape"
                                            name="add"
                                            :icon="selectedIcon"
                                        />
                                        <div slot="dropdown">
                                            <map-button
                                                :active="clickAction === 'draw' && currentShape === 'polygon'"
                                                title="Polygon"
                                                name="add"
                                                icon="draw-polygon"
                                                @click="setAction('draw', 'polygon')"
                                            />
                                            <map-button
                                                :active="clickAction === 'draw' && currentShape === 'circle'"
                                                title="Circle"
                                                name="add"
                                                icon="draw-circle"
                                                @click="setAction('draw', 'circle')"
                                            />
                                            <map-button
                                                :active="clickAction === 'draw' && currentShape === 'rectangle'"
                                                title="Rectangle"
                                                name="add"
                                                icon="draw-square"
                                                @click="setAction('draw', 'rectangle')"
                                            />
                                        </div>
                                    </DropdownMenu>
                                    <map-button
                                        :active="clickAction === 'edit'"
                                        title="Edit"
                                        name="edit"
                                        icon="edit"
                                        @click="setAction('edit')"
                                    />

                                    <map-button
                                        :active="clickAction === 'delete'"
                                        title="Delete"
                                        name="delete"
                                        icon="eraser"
                                        @click="setAction('delete')"
                                    />
                                </div>
                                <!-- <div
                                    v-if="type === 'plot'"
                                    class="map-select-header-right-group flex"
                                >
                                    <button
                                        class="map-select-header-button map-select-header-button--snap btn-reset"
                                        :class="{ 'active' : snapEnabled}"
                                        title="Toggle Snap"
                                        @click.prevent="toggleSnap"
                                    >
                                        <SvgController type="anchor-solid" />
                                    </button>
                                </div> -->
                            </template>

                            <template v-else-if="editType === 'circle'">
                                <div
                                    v-if="showLayersEnabled"
                                    class="map-select-header-right-group flex"
                                >
                                    <map-button
                                        title="Toggle Habitats"
                                        name="habitatToggle"
                                        :active="showLayers"
                                        @click="showLayers = !showLayers"
                                    >
                                        {{ habitatBtnText }}
                                    </map-button>
                                </div>
                                <div class="map-select-header-right-group flex">
                                    <map-button
                                        :disabled="historyIndex === 1"
                                        title="Undo"
                                        name="undo"
                                        icon="undo"
                                        @click="undo"
                                    />
                                    <map-button
                                        :disabled="history.length === historyIndex"
                                        title="Redo"
                                        name="redo"
                                        icon="redo"
                                        @click="redo"
                                    />
                                </div>
                                <div class="map-select-header-right-group flex">
                                    <map-button
                                        :active="clickAction === 'pan'"
                                        title="Pan"
                                        name="pan"
                                        icon="hand"
                                        @click="setAction('pan')"
                                    />
                                    <map-button
                                        :active="clickAction === 'draw' && currentShape === 'circle'"
                                        title="Circle"
                                        name="add"
                                        icon="plus"
                                        @click="setAction('draw', 'circle', 1.5)"
                                    />
                                    <map-button
                                        :active="clickAction === 'delete'"
                                        title="Delete"
                                        name="delete"
                                        icon="eraser"
                                        @click="setAction('delete')"
                                    />
                                </div>
                            </template>
                            <template v-if="editType === 'marker' || editType === 'circle' || editType === 'shape'">
                                <div
                                    class="map-select-header-right-group"
                                >
                                    <map-button
                                        :active="fullScreenEnabled"
                                        title="Toggle FullScreen"
                                        name="fullScreen"
                                        icon="expand"
                                        @click="fullScreenEnabled = !fullScreenEnabled"
                                    />
                                </div>
                            </template>
                        </template>
                        <div
                            v-else-if="disabledMessage"
                            class="map-select-header-right-group"
                        >
                            <p>{{ disabledMessage }}</p>
                        </div>
                    </div>
                </div>
            </template>
            <google-map
                ref="mapRef"
                :zoom="zoom"
                :options="mapOptions"
                :center="center"
                :tilt="0"
                @ready="mapReady"
                @click="mapClick($event, 'map')"
            >
                <template v-if="developments.length > 0">
                    <template
                        v-for="(development, index) in developments"
                    >
                        <template v-if="development.location_data.layer">
                            <shape
                                :key="`development-${index}`"
                                ref="developments"
                                :shape="development.location_data.layer"
                                :item="development"
                                type="project"
                                :z-index="2"
                                @click="showInfoBox"
                            />
                            <template v-if="showMarkers">
                                <google-map-marker
                                    :key="`development-marker-${index}`"
                                    :icon="getMapIcon('project')"
                                    :position="development.location_data.layer.center"
                                    :z-index="3"
                                    :clickable="popupShapeClick"
                                    @click="showInfoBox(development, 'project-legacy')"
                                />
                            </template>
                        </template>
                        <google-map-marker
                            v-else
                            :key="`developments-${index}`"
                            ref="developments"
                            :icon="getMapIcon()"
                            :position="development.location_data"
                            :z-index="5"
                            :clickable="popupShapeClick"
                            @click="showInfoBox(development, 'project-legacy')"
                        />
                    </template>
                </template>

                <template v-if="computedLandParcels">
                    <shape
                        v-for="(landParcel, index) in computedLandParcels"
                        :key="`landParcel-${index}`"
                        ref="landParcels"
                        :shape="landParcel.location_data.layer"
                        :item="landParcel"
                        type="plot"
                        :z-index="2"
                        @click="showInfoBox"
                    />

                    <template v-if="showMarkers">
                        <google-map-marker
                            v-for="(landParcel, index) in landParcels"
                            :key="`landParcel-marker-${index}`"
                            :icon="getMapIcon('plotMarker')"
                            :position="landParcel.location_data.layer.center"
                            :z-index="3"
                            :clickable="popupShapeClick"
                            @click="showInfoBox(landParcel, 'landParcel')"
                        />
                    </template>
                </template>
                <template v-if="computedLeases.length > 0">
                    <shape
                        v-for="(lease, index) in computedLeases"
                        :key="`lease-${index}`"
                        ref="leases"
                        :shape="lease.location_data.layer"
                        :item="lease"
                        type="lease"
                        :z-index="4"
                        @click="showInfoBox"
                    />
                </template>

                <template v-if="computedLandParcelHabitats">
                    <template v-for="(habitat, index) in computedLandParcelHabitats">
                        <shape
                            :key="`habitat-${habitat.id}-${index}`"
                            ref="habitatArea"
                            type="habitat"
                            :habitat-type="habitat.type"
                            :z-index="2"
                            :shape="habitat.location_data.layer"
                            :item="habitat"
                            @click="showInfoBox"
                        />
                    </template>
                </template>

                <template v-if="showLayers && layers.length && computedHabitats">
                    <template v-for="(habitat, index) in computedHabitats">
                        <template
                            v-if="habitat.location_data && habitat.location_data.layer && habitat.location_data.layer.shape === 'circle'"
                        >
                            <MapLayer
                                v-for="(point, locIndex) in parseCircleList(habitat.location_data.layer)"
                                :key="`map-layer-${index}-${locIndex}`"
                                :index="locIndex"
                                :tmp="habitat.location_data.layer.shape"
                                :layer="{...habitat, location_data: {layer: point} }"
                                :multiple="parseCircleList(habitat.location_data.layer).length > 1"
                            />
                        </template>
                        <template
                            v-else
                        >
                            <MapLayer
                                :key="`map-layer-${index}`"
                                :layer="habitat"
                            />
                        </template>
                    </template>
                </template>

                <template v-if="edit && editType === 'shape'">
                    <shape
                        v-if="isMapReady && data.layer"
                        key="editShape"
                        ref="editShape"
                        :editable="(clickAction === 'edit' ||clickAction === 'delete')"
                        :draggable="!disabled"
                        :shape="data.layer"
                        :z-index="5"
                        :type="type"
                        :item="item"
                        :habitat-type="habitatType"
                        :click-action="clickAction"
                        :fill-color="getEditFillColor.fillColor"
                        :theme="getEditFillColor.theme"
                        @layerUpdated="layerUpdated"
                    />
                </template>

                <template v-else-if="edit && editType === 'marker'">
                    <google-map-marker
                        v-if="data.points"
                        :icon="getMapIcon()"
                        :draggable="!disabled"
                        :position="data.points"
                    />
                </template>

                <template v-else-if="edit && editType === 'circle'">
                    <template
                        v-if="!multiple"
                    >
                        <google-map-marker
                            v-if="data.layer && data.layer.points"
                            :icon="getMapIcon()"
                            :draggable="!disabled"
                            :position="data.layer.points"
                        />
                    </template>
                    <template
                        v-else-if="data.layer && data.layer.points"
                    >
                        <shape
                            v-for="(point, index) in data.layer.points"
                            :key="`${point.lat},${point.lng}`"
                            ref="circles"
                            type="habitat"
                            :editable="edit && !point.radius"
                            :multiple="multiple"
                            :draggable="!disabled"
                            :click-action="clickAction"
                            :shape="parseCircle(point)"
                            :z-index="2"
                            :shape-index="index"
                            :fill-color="getEditFillColor.fillColor"
                            :theme="getEditFillColor.theme"
                            @layerUpdated="updateMultipleShape"
                            @deleteShape="deleteMultipleShape"
                        />
                    </template>
                </template>

                <google-map-infowindow
                    :show.sync="showInfo"
                    :position="infoWindowContext.position"
                >
                    <info-box
                        :item="infoWindowContext"
                    />
                </google-map-infowindow>

                <vector
                    v-for="vector in vectors"
                    ref="vectorList"
                    :key="vector.id || vector.url"
                    :vector-url="vector.url"
                    :attributes-url="vector.attributesUrl"
                    :background="vector.background"
                    :background-opacity="vector.backgroundOpacity"
                    :line-width="vector.lineWidth"
                    :stroke="vector.stroke"
                    :stroke-opacity="vector.strokeOpacity"
                    :filter="vector.filter"
                    @ready="vectorLoad"
                    @filtered="calculateBoundsChange"
                />
            </google-map>
            <div
                v-if="showKeys"
                class="map-select-keys"
            >
                <ul class="lst-reset flex">
                    <li
                        v-for="(key) in keys"
                        :key="`map-keys-${key.type}`"
                        class="flex-center"
                    >
                        <div :style="`color:${key.color};background:${key.color};`" /> {{ key.title }}
                    </li>
                </ul>
            </div>
        </div>
    </section>
</template>

<script>
import MapMixin from 'Mixins/map';
import MapButton from './MapButton';
import Shape from './Shape';
import MapLayer from './MapLayer.vue';
import InfoBox from './InfoBox';
import Vector from 'Utilities/maps/Vector';

export default {
    name: 'MapSelect',
    components: {
        MapButton,
        Shape,
        InfoBox,
        Vector,
        MapLayer,
    },

    mixins: [
        MapMixin,
    ],

    props: {
        type: {
            type: String,
            required: true,
            validator: function(value) {
                return ['plot', 'project', 'project-legacy', 'habitat', 'lease', 'lpa'].indexOf(value) !== -1;
            },
        },
        shapeType: {
            type: String,
            required: false,
            default: null,
            validator: function(value) {
                return ['shape', 'circle'].indexOf(value) !== -1;
            },
        },
        edit: {
            type: Boolean,
            default: false,
        },
        popupShapeClick: {
            type: Boolean,
            default: false,
        },
        zoom: {
            type: Number,
            default: 6,
        },
        multiple: {
            type: Boolean,
            default: false,
        },
        defaultClickAction: {
            type: String,
            required: false,
            default: 'pan',
            validator: function(value) {
                return ['pan', 'draw', 'delete'].indexOf(value) !== -1;
            },
        },

        latLong: {
            type: Object,
            default: () => ({
                lat: 53.428720,
                lng: -1.659736,
            }),
        },
        country: {
            type: String,
            default: null,
        },
        lpa: {
            type: [Object,Array],
            default: null,
        },
        developments: {
            type: Array,
            default:() => ([]),
        },
        landParcels: {
            type: Array,
            default:() => ([]),
        },
        showMarkers: {
            type: Boolean,
            default: false,
        },
        leases: {
            type: Array,
            default:() => ([]),
        },
        habitats: {
            type: [Array, Object],
            default:() => ([]),
        },
        landParcelHabitats: {
            type: [Array, Object],
            default:() => ([]),
        },
        value: {
            type: [Array, Object],
            default() {
                return {};
            },
        },
        item: {
            type: Object,
            default: null,
        },
        habitatType: {
            type: String,
            default: null,
        },
        showGeoInput: {
            type: Boolean,
            default: true,
        },
        geoInput: {
            type: Boolean,
            default: true,
        },
        showKeys: {
            type: Boolean,
            default: false,
        },
        fitBounds: {
            type: [String, Array],
            default: null,
        },
        fitBoundsChange: {
            type: [String, Array],
            default: null,
        },
        fitBoundsOffset: {
            type: Number,
            default: null,
        },
        fitBoundsOffsetChange: {
            type: Number,
            default: null,
        },
        options: {
            type: Object,
            default() {
                return {};
            },
        },

        disabled: {
            type: Boolean,
            default: false,
        },
        disabledMessage: {
            type: String,
            default: null,
        },

        message: {
            type: String,
            default: null,
        },

        showLayersEnabled: {
            type: Boolean,
            default: false,
        },
        defaultShowLayers: {
            type: Boolean,
            default: true,
        },
        showLayersText: {
            type: Array,
            default: () => ['Show Layers', 'Hide Layers'],
        },

        editFillColor: {
            type: [String, Object],
            default: null,
        },

        layers: {
            type: [Array],
            default: () => [],
        },

        // [{
        //   name: '',
        //   url: '',
        //   attributeUrl: '',
        //   filter: [{
        //     property: 'name',
        //     compare: '=',
        //     value: ['name'],
        //   }],
        // }]
        vectors: {
            type: [Array],
            default: () => [],
        },
    },
    data() {
        return {
            // Enabled Disabled
            loaded: false,
            loadedBounds: false,

            // Current Action
            clickAction: this.defaultClickAction,
            show: false,
            // Map
            map: null,
            isMapReady: false,
            fullScreenEnabled: false,
            mapOptions: {
                zoomControl: true,
                mapTypeControl: true,
                scaleControl: false,
                streetViewControl: false,
                rotateControl: false,
                fullscreenControl: false,
                clickableIcons: false,
                mapTypeId: 'roadmap',
            },

            // Data Points
            dragInfo: {},
            currentShape: 'polygon',
            currentSize: null,

            // Geo
            geoInputEnabled: this.geoInput,

            // Snap
            snapEnabled: true,
            snapVisible: false,
            snapPosition: {
                lat: 0,
                lng: 0,
            },
            allPoints: [],

            // Histroy
            history: [{}],
            historyIndex: 1,

            // Drawing Manager
            drawingManager: null,

            showInfo: false,
            infoWindowContext: {
                position: {
                    lat: 53.428720,
                    lng: -1.659736,
                },
            },

            // LPA
            lpaList: [],

            showLayers: this.defaultShowLayers,
            vectorTimeout: null,
        };
    },
    computed: {
        data: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            },
        },

        habitatBtnText() {
            if (this.showLayers) {
                return this.showLayersText[1];
            }

            return this.showLayersText[0];
        },
        center() {
            if (this.latLong) {
                return this.latLong;
            }

            return {
                lat: 53.428720,
                lng: -1.659736,
            };
        },
        wrapperClass() {
            return `map-select-wrapper--${this.type}`;
        },
        activeColor() {
            if (this.type === 'habitat') {
                if (this.habitatType) {
                    return this.mapColors[this.habitatType];
                }

                return '#4285F4';
            }

            return this.mapColors[this.type];
        },
        editType() {
            if (this.shapeType) {
                return this.shapeType;
            } else {
                if (this.type === 'project-legacy') {
                    return 'marker';
                }
            }

            return 'shape';
        },

        shapeOptions() {
            return {
                fillColor: this.activeColor,
                strokeColor: this.activeColor,
                fillOpacity: 0.4,
                strokeWeight: 1,
                strokeOpacity: 0.5,
                clickable: false,
                editable: true,
            };
        },

        keys() {
            const keys = [];

            if (this.type === 'landParcel') {
                keys.push({
                    title: 'BNG Units available',
                    color: this.mapColors.plot,
                    type: 'landParcel',
                });
                keys.push({
                    title: 'Implemented land',
                    color: this.mapColors.lease,
                    type: 'lease',
                });
            }

            return keys;
        },

        computedLandParcels() {
            if (this.map) {
                return this.landParcels.map((landParcel) => {
                    if (!landParcel.location_data.layer.center) {
                        const bounds = new google.maps.LatLngBounds();

                        landParcel.location_data.layer.paths.forEach((path) => {
                            bounds.extend(path);
                        });
                        landParcel.location_data.layer.center = bounds.getCenter();
                    }

                    return landParcel;
                });
            }

            return [];
        },

        computedLeases() {
            if (this.map) {

                return this.leases.map((lease) => {
                    if (!lease.location_data.layer.center) {
                        const bounds = new google.maps.LatLngBounds();

                        lease.location_data.layer.paths.forEach((path) => {
                            bounds.extend(path);
                        });
                        lease.location_data.layer.center = bounds.getCenter();
                    }

                    return lease;
                });
            }

            return [];
        },

        computedHabitats() {
            if (this.map) {
                const mapLayers = this.habitats.map((habitat) => {
                    if (!habitat.location_data.layer.center) {
                        const bounds = new google.maps.LatLngBounds();
                        const boundsLoop = habitat.location_data.layer.paths || habitat.location_data.layer.points;

                        boundsLoop.forEach((path) => {
                            bounds.extend(path);
                        });
                        habitat.location_data.layer.center = bounds.getCenter();
                    }

                    return habitat;
                });

                return [...mapLayers, ...this.layers];
            }

            return [];
        },

        computedLandParcelHabitats() {
            if (this.map) {

                return this.landParcelHabitats.map((habitat) => {
                    if (!habitat.location_data.layer.center) {
                        const bounds = new google.maps.LatLngBounds();

                        habitat.location_data.layer.paths.forEach((path) => {
                            bounds.extend(path);
                        });
                        habitat.location_data.layer.center = bounds.getCenter();
                    }

                    return habitat;
                });
            }

            return [];
        },

        selectedIcon() {
            let shape = this.currentShape;

            if (shape === 'rectangle') {
                shape = 'square';
            }

            return `draw-${shape}`;
        },

        fitBoundList() {
            if (!this.fitBounds) return [];

            if (this.fitBounds === 'all') {
                return [
                    'edit',
                    'lpa',
                    'layers',
                    'vectors',
                ];
            }

            return Array.isArray(this.fitBounds) ? this.fitBounds : [this.fitBounds];
        },

        fitBoundsChangeList() {
            if (!this.fitBoundsChange) return [];

            if (this.fitBoundsChange === 'all') {
                return [
                    'edit',
                    'lpa',
                    'layers',
                    'vectors',
                ];
            }

            return Array.isArray(this.fitBoundsChange) ? this.fitBoundsChange : [this.fitBoundsChange];
        },

        getEditFillColor() {
            if (this.editFillColor && typeof this.editFillColor === 'object') {
                return this.editFillColor;
            }

            return {fillColor: this.editFillColor ?? '#216C5F'};
        },
    },

    watch: {
        country() {
            if (this.map) {
                this.setCountry();
            }
        },
        lpa() {
            this.convertLpaList();

            if (this.map) {
                setTimeout(() => {
                    this.setLpa();
                }, 100);
            }
        },
    },
    mounted() {
        this.convertLpaList();
    },
    methods:{
        mapReady() {
            this.$refs.mapRef.$_getMap().then((event) => {
                this.loaded = true;
                this.map = event;
                this.isMapReady = true;

                if (this.lpa) {
                    this.lpaList = Array.isArray(this.lpa) ? this.lpa : [this.lpa];
                }

                this.mapOptions = {
                    ...this.mapOptions,
                    mapTypeControlOptions: {
                        mapTypeIds: ['roadmap', 'satellite'],
                        style: google.maps.MapTypeControlStyle.DEFAULT,
                        position: google.maps.ControlPosition.LEFT_BOTTOM,
                    },
                    ...this.options,
                };

                if (this.edit && this.editType === 'shape') {
                    this.drawingManager = new google.maps.drawing.DrawingManager({
                        drawingMode: null,
                        drawingControl: false,
                    });

                    this.drawingManager.addListener('overlaycomplete', this.overlayComplete);
                    this.drawingManager.setMap(this.map);
                } else if (this.edit && this.editType === 'circle') {
                    // this.drawingManager.addListener('overlaycomplete', this.overlayComplete);
                    // this.drawingManager.setMap(this.map);
                }


                setTimeout(() => {
                    this.calculateBounds(this.fitBoundList, this.fitBoundsOffset);
                }, 10);

                this.$emit('mapReady', this.map);
            });
        },

        async mapClick(event) {
            if (!this.edit || this.disabled) return;

            const position = {
                lat: event.latLng.lat(),
                lng: event.latLng.lng(),
            };

            const data = {
                ...this.data,
            };

            if (this.clickAction === 'draw') {

                if (this.editType === 'marker') {
                    data.points = position;

                    if (this.geoInputEnabled && this.showGeoInput) {
                        const geoCoder = await this.getGeoCoder(position);

                        data.inputGeo = await this.getGeoLocation(position, geoCoder);
                        data.inputCountry = await this.getCountryLocation(position, geoCoder);
                    }

                    this.data = data;
                } else if (this.editType === 'circle') {
                    if (this.currentSize) {
                        position.radius = this.currentSize;
                    }

                    if (!data.layer) data.layer = {shape: 'circle'};
                    if (!data.layer.points) data.layer.points = [];

                    if (this.multiple) {
                        data.layer.points.push(position);
                    } else {
                        data.layer.points = [position];
                    }

                    if (this.geoInputEnabled && this.showGeoInput) {
                        const geoCoder = await this.getGeoCoder(position);

                        data.inputGeo = await this.getGeoLocation(position);
                        data.inputCountry = await this.getCountryLocation(position, geoCoder);
                    }

                    this.data = data;
                }
            }
        },

        updateMultipleShape(value, index) {
            if (this.multiple) {
                const data = {...this.data};

                data.layer.points = data.layer.points.map((point, loopIndex) => {
                    if (index === loopIndex) {
                        return {
                            ...point,
                            lat: value.center.lat(),
                            lng: value.center.lng(),
                        };
                    }

                    return point;
                });

                this.data = data;
            }
        },

        deleteMultipleShape(index) {
            if (this.multiple) {
                const data = {...this.data};

                data.layer.points.splice(index, 1);
                this.data = data;
            }
        },

        placeChanged(place) {
            if (place.geometry.viewport) {
                this.map.fitBounds(place.geometry.viewport);
            } else {
                this.map.setCenter(place.geometry.location);
            }
        },

        // Get location
        toggleGeoLocation() {
            this.geoInputEnabled = !this.geoInputEnabled;
        },

        setGeoLocationKey() {
            this.geoInputEnabled = false;
            this.data = {
                ...this.data,
                inputGeo: this.$refs.geoInput.value,
            };
        },

        async getGeoCoder(position) {
            if (!position) {
                return '';
            }

            const results = await new google.maps.Geocoder().geocode({location: position});

            return results;
        },

        async getGeoLocation(position, geoCoder = null) {
            if (!position) {
                return '';
            }

            const { results } = geoCoder || await this.getGeoCoder(position);

            if (results.length > 0) {
                return results[0].formatted_address;
            }

            return '';
        },

        async getCountryLocation(position, geoCoder = null) {
            if (!position) {
                return '';
            }

            const { results } = geoCoder || await this.getGeoCoder(position);

            if (results.length > 0) {
                const filtered = results.filter(location => location.types.includes('country'));

                if (filtered.length > 0 && filtered[0].address_components.length && filtered[0].address_components[0].short_name) {
                    return filtered[0].address_components[0].short_name;
                }
            }

            return '';
        },

        // History
        addHistory() {
            const newHistory = this.data;

            this.history.push(newHistory);
            this.historyIndex = this.history.length;
        },

        undo() {
            this.historyIndex -= 1;

            if (this.historyIndex <= 1) {
                this.historyIndex = 1;
            }

            this.data = this.history[this.historyIndex - 1];
        },

        redo() {
            this.historyIndex += 1;

            if (this.historyIndex >= this.history.length) {
                this.historyIndex = this.history.length;
            }

            this.data = this.history[this.historyIndex - 1];
        },

        // Layers
        async layerUpdated(layer, omitHistoryEntry) {
            let inputGeo = null,
                inputCountry = null;

            if (this.geoInputEnabled && this.showGeoInput) {
                const geoCoder = await this.getGeoCoder(layer.center);

                inputGeo = await this.getGeoLocation(layer.center, geoCoder);
                inputCountry = await this.getCountryLocation(layer.center, geoCoder);
            }

            const {
                area,
                ...layerUpdate
            } = layer;

            this.data = {
                ...this.data,
                area: {...area},
                inputGeo,
                inputCountry,
                layer: layerUpdate,
            };

            if (!omitHistoryEntry) {
                this.addHistory();
            }
        },

        overlayComplete(event) {
            const type = event.type;
            const overlay = event.overlay;
            const layer = {
                shape: type,
            };

            switch (type) {
                case 'circle': {
                    layer.radius = overlay.getRadius();
                    layer.center = overlay.getCenter();

                    break;
                }

                case 'rectangle': {
                    const bounds = overlay.getBounds();
                    const northEast = bounds.getNorthEast();
                    const southWest = bounds.getSouthWest();

                    const north = northEast.lat();
                    const east = northEast.lng();
                    const south = southWest.lat();
                    const west = southWest.lng();

                    layer.shape = 'polygon';

                    layer.paths = [
                        new google.maps.LatLng(north, east),
                        new google.maps.LatLng(south, east),
                        new google.maps.LatLng(south, west),
                        new google.maps.LatLng(north, west),
                        new google.maps.LatLng(north, east),
                    ];

                    layer.center = bounds.getCenter();
                    break;
                }

                case 'polygon': {
                    const paths = [];
                    const bounds = new google.maps.LatLngBounds();

                    overlay.getPath().forEach((path) => {
                        bounds.extend(new google.maps.LatLng(path.lat(), path.lng()));
                        paths.push({
                            lat: path.lat(),
                            lng: path.lng(),
                        });
                    });

                    layer.paths = paths;
                    layer.center = bounds.getCenter();

                    break;
                }

                case 'polyline': {
                    break;
                }

                default: break;
            }


            this.layerUpdated(layer);
            event.overlay.setMap(null);
            this.drawingManager.setDrawingMode(null);
            this.clickAction = 'edit';
        },

        setAction(action, shape, size) {
            this.clickAction = action;

            if (action === 'draw') {
                if (size) {
                    if (!shape) {
                        shape = 'circle';
                    }

                    this.currentShape = shape;
                    this.currentSize = size;
                } else {
                    if (!shape) {
                        shape = 'polygon';
                    }

                    this.currentShape = shape;
                    this.currentSize = null;

                    const options = {
                        [`${shape}Options`]: this.shapeOptions,
                    };

                    this.drawingManager.setDrawingMode(shape);
                    this.drawingManager.setOptions(options);
                }

                return;
            }

            this.drawingManager.setDrawingMode(null);
        },

        async setCountry() {
            if (this.country && Object.keys(this.data).length === 0) {
                const geocoder = new google.maps.Geocoder();

                await geocoder.geocode({ 'address': this.country}, (results, status) => {
                    if (status === google.maps.GeocoderStatus.OK) {
                        this.map.fitBounds(results[0].geometry.bounds);
                    }
                });

                return;
            }

            this.map.setZoom(2);
        },

        async setLpa() {
            this.setCountry();
        },

        returnLpaPaths(array) {
            let returnArray = [];

            array.forEach((latLng) => {
                if (Array.isArray(latLng)) {
                    returnArray = returnArray.concat(this.returnLpaPaths(latLng));
                } else {
                    returnArray.push(latLng);
                }
            });

            return returnArray;
        },

        showInfoBox(item, type) {
            if (!this.popupShapeClick) {
                return;
            }

            if (!item || !type) {
                this.showInfo = false;

                return;
            }

            this.infoWindowContext = item;
            this.infoWindowContext.layerType = type;

            if (type === 'project-legacy') {
                this.infoWindowContext.position = JSON.parse(item.location);
            } else {
                this.infoWindowContext.position = item.location_data.layer.center;
            }

            this.showInfo = true;
        },

        duplicateArea() {
            this.data = {...this.landParcels[0].location_data};
            this.setAction('edit');
        },

        async convertLpaList() {
            if (!this.lpa) {
                return null;
            }

            const lpaTmp = Array.isArray(this.lpa) ? this.lpa : [this.lpa];

            this.lpaList = lpaTmp;
        },

        vectorLoad() {
            clearTimeout(this.vectorTimeout);
            this.vectorTimeout = setTimeout(() => {
                this.calculateBounds(this.fitBoundList, this.fitBoundsOffset);
            }, 100);
        },

        calculateBoundsChange() {
            if (this.loadedBounds) {
                this.calculateBounds(this.fitBoundsChangeList, this.fitBoundsOffsetChange);
            }
        },

        calculateBounds(list = this.fitBoundList, offset = null) {
            let data,
                bounds;

            list.forEach(targetBounds => {
                let boundsInner;

                switch (targetBounds) {
                    case 'edit':
                    case 'landParcels':
                    case 'layers':
                        if (targetBounds === 'layers') {
                            data = this.layers.map(layer => layer.location_data);
                        } else if (targetBounds === 'landParcels') {
                            data = this.landParcels.map(layer => layer.location_data);
                        } else {
                            if (Object.keys(this.data).length === 0) {
                                return;
                            }

                            data = [this.data];
                        }

                        boundsInner = new google.maps.LatLngBounds();

                        data.forEach(layer => {
                            if (layer.layer) {
                                if (layer.layer.shape === 'polygon' && layer.layer.paths) {
                                    layer.layer.paths.forEach(path => {
                                        const pathLoc = {
                                            lat: path.lat,
                                            lng: path.lng,
                                        };

                                        if (typeof path.lat === 'function') {
                                            pathLoc.lat = pathLoc.lat();
                                        }

                                        if (typeof path.lng === 'function') {
                                            pathLoc.lng = pathLoc.lng();
                                        }

                                        boundsInner.extend(new google.maps.LatLng(pathLoc.lat, pathLoc.lng));
                                    });
                                } else if (layer.layer.shape === 'circle' && layer.layer.radius && layer.layer.center) {
                                    const circle = new google.maps.Circle({
                                        center: layer.layer.center,
                                        radius: layer.layer.radius,
                                    });

                                    boundsInner.union(circle.getBounds());
                                } else if (layer.layer.shape === 'marker' && layer.layer.points) {
                                    boundsInner.extend(new google.maps.LatLng(layer.layer.points.lat, layer.layer.points.lng));
                                }
                            }
                        });

                        break;

                    case 'lpa':
                        if (!this.lpa) {
                            return;
                        }

                        this.setLpa();
                        break;

                    case 'vectors':
                        if (this.$refs.vectorList && this.$refs.vectorList.length) {
                            this.$refs.vectorList.forEach(vector => {
                                if (!boundsInner) {
                                    boundsInner = vector.getFilteredBounds();
                                } else {
                                    boundsInner.union(vector.getFilteredBounds());
                                }
                            });
                        }

                        break;

                    default:
                        break;
                }

                if (!boundsInner || boundsInner.isEmpty()) {
                    return;
                } else if (!bounds) {
                    bounds = boundsInner;

                    return;
                }

                bounds.union(boundsInner);
            });

            if (offset && bounds && bounds.getNorthEast().equals(bounds.getSouthWest())) {
                const extendPointPlus = new google.maps.LatLng(bounds.getNorthEast().lat() + offset, bounds.getNorthEast().lng() + offset);
                const extendPointMinus = new google.maps.LatLng(bounds.getNorthEast().lat() - offset, bounds.getNorthEast().lng() - offset);

                bounds.extend(extendPointPlus);
                bounds.extend(extendPointMinus);
            }

            if (bounds) this.map.fitBounds(bounds);

            setTimeout(() => {
                this.loadedBounds = true;
            }, 1000);
        },
    },
};
</script>
