var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "form",
      staticClass:
        "formulate-form--project form-flex--lap overflow-hidden relative",
      attrs: { errors: _vm.$page.props.errors },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.sidebar,
              expression: "sidebar",
            },
          ],
          staticClass: "w-1/3",
        },
        [
          _c("HeaderSmall", {
            attrs: {
              title: "Habitats",
              button: {
                svg: "filter",
                click: _vm.openFilters,
              },
            },
          }),
          _vm._v(" "),
          _c("BNGCalculator", {
            key: "bng_calculator",
            ref: "calcutator",
            attrs: {
              "show-id": _vm.habitat,
              "show-units": false,
              repeatable: true,
              small: true,
              photo: true,
              "photo-upload-url": _vm.$route("habitat.upload"),
              pill: "",
              "fields-enabled": { size: false },
              "additional-fields": _vm.additionalFields,
            },
            on: {
              switch: _vm.changeActive,
              input: _vm.updateHabitat,
              "submit-start": _vm.submitStart,
              submit: _vm.submitForm,
            },
            model: {
              value: _vm.current_habitats,
              callback: function ($$v) {
                _vm.current_habitats = $$v
              },
              expression: "current_habitats",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          class: {
            "w-2/3": _vm.sidebar,
            "w-full": !_vm.sidebar,
          },
        },
        [
          _c("HeaderSmall", { attrs: { title: "Location" } }),
          _vm._v(" "),
          _c("alert", { attrs: { type: "info" } }, [
            _c("p", [
              _vm._v(
                "Use the map below to draw your habitat - please be as accurate as possible."
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("FormulateInput", {
            key: "map",
            ref: "map",
            attrs: {
              "edit-fill-color": _vm.editFillColor,
              name: "map_location",
              item: _vm.form,
              type: "map",
              "map-type": "habitat",
              zoom: 2,
              "fit-bounds": _vm.vectors.length ? "vectors" : "layers",
              vectors: _vm.vectors,
              "show-geo-input": false,
              layers: _vm.layers,
              "shape-type":
                _vm.current_habitats[_vm.showIndex] &&
                _vm.current_habitats[_vm.showIndex].type === "individual-trees"
                  ? "circle"
                  : null,
              multiple:
                _vm.current_habitats[_vm.showIndex] &&
                _vm.current_habitats[_vm.showIndex].type === "individual-trees"
                  ? true
                  : false,
              disabled: _vm.isDisabled,
              "disabled-message": _vm.message,
            },
            on: { input: _vm.mapChange },
            model: {
              value: _vm.activeMap,
              callback: function ($$v) {
                _vm.activeMap = $$v
              },
              expression: "activeMap",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("Sidebar", {
        key: "sidebar",
        ref: "sidebar",
        staticClass: "w-1/3",
        attrs: { accordions: _vm.accordions, slide: true, title: "Filters" },
        on: { change: _vm.sidebarChange },
        model: {
          value: _vm.filters,
          callback: function ($$v) {
            _vm.filters = $$v
          },
          expression: "filters",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }