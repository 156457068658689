var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "empty-container text-center" }, [
    _vm._m(0),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "empty-text" },
      [
        _vm._t("message", function () {
          return [_c("h3", [_vm._v(_vm._s(_vm.message))])]
        }),
        _vm._v(" "),
        _vm._t("default"),
      ],
      2
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "empty-img" }, [
      _c("img", { attrs: { src: "/images/empty.svg" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }