var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "page-header-tabs center" }, [
    _c(
      "ul",
      { staticClass: "page-header-tabs-list lst-reset flex" },
      _vm._l(_vm.tabsParsed, function (tab, index) {
        return _c(
          "tab",
          {
            key: `${tab.link}-${index}`,
            attrs: {
              "tab-id": tab.id,
              show: tab.show,
              selected: _vm.valueInterim === tab.id,
              type: _vm.type,
              link: tab.link,
              title: tab.title,
              only: tab.only,
              disabled: _vm.valueInterim === "loading",
            },
            on: { click: (event) => _vm.setTab(event, tab) },
          },
          [
            _vm._t("default"),
            _vm._v(" "),
            _vm._l(_vm.$scopedSlots, function (_, name) {
              return _c("template", { slot: name }, [_vm._t(name)], 2)
            }),
          ],
          2
        )
      }),
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }