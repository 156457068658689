var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.shape === "circle"
    ? _c("shape", {
        key: _vm.key,
        ref: "habitatAreaCircles",
        attrs: {
          type: _vm.type,
          "habitat-type": _vm.layer.type,
          "fill-color": _vm.layer.fillColor,
          theme: _vm.layer.theme,
          all: _vm.layer,
          multiple: _vm.multiple,
          shape: _vm.layer.location_data.layer,
          "z-index": 2,
          "shape-index": _vm.index,
          item: _vm.layer,
        },
        on: { click: (event) => _vm.onClick(event, _vm.layer) },
      })
    : _vm.shape === "marker"
    ? _c("google-map-marker", {
        key: _vm.key,
        attrs: {
          icon: _vm.icon,
          position: _vm.layer.location_data.layer.points,
          clickable: _vm.hasClick ? true : false,
        },
        on: { click: (event) => _vm.onClick(event, _vm.layer) },
      })
    : _vm.shape === "polygon"
    ? _c("shape", {
        key: _vm.key,
        ref: "habitatArea",
        attrs: {
          type: _vm.type,
          "habitat-type": _vm.layer.type,
          "fill-color": _vm.layer.fillColor,
          theme: _vm.layer.theme,
          all: _vm.layer,
          "z-index": 2,
          shape: _vm.layer.location_data.layer,
          item: _vm.layer,
        },
        on: { click: (event) => _vm.onClick(event, _vm.layer) },
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }