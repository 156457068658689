export default {
    data() {
        return {
            defaultType: 'baseline',
        };
    },

    methods: {
        onSiteBaselineConvert(workbook) {
            const pages = [{
                page: 'A-1 Site Habitat Baseline',
                category: 'land',
                start: 10,
                fields: {
                    'Broad Habitat': {
                        column: 'E',
                        required: true,
                    },
                    'Habitat Type': {
                        column: 'F',
                        required: true,
                    },
                    'Area (hectares)': {
                        column: 'H',
                        required: true,
                    },
                    'Distinctiveness': {
                        column: 'I',
                        required: true,
                    },
                    'Condition': {
                        column: 'K',
                        required: true,
                    },
                    'Strategic significance': {
                        column: 'N',
                        required: true,
                    },
                    'Retained': {
                        column: 'S',
                    },
                    'Enhanced': {
                        column: 'T',
                    },
                },
            }, {
                page: 'B-1 Site Hedge Baseline',
                category: 'hedgerows',
                start: 9,
                fields: {
                    'Hedgerow type': {
                        column: 'D',
                        required: true,
                    },
                    'Length (km)': {
                        column: 'E',
                        required: true,
                    },
                    'Distinctiveness': {
                        column: 'F',
                        required: true,
                    },
                    'Condition': {
                        column: 'H',
                        required: true,
                    },
                    'Strategic significance': {
                        column: 'K',
                        required: true,
                    },
                    'Retained': {
                        column: 'P',
                    },
                    'Enhanced': {
                        column: 'Q',
                    },
                },
            }, {
                page: 'C-1 Site River Baseline',
                category: 'rivers',
                start: 9,
                fields: {
                    'River type': {
                        column: 'D',
                        required: true,
                    },
                    'Length (km)': {
                        column: 'E',
                        required: true,
                    },
                    'Distinctiveness': {
                        column: 'F',
                        required: true,
                    },
                    'Condition': {
                        column: 'H',
                        required: true,
                    },
                    'Strategic significance': {
                        column: 'K',
                        required: true,
                    },
                    'Watercourse encroachment': {
                        column: 'M',
                        required: true,
                    },
                    'Riparian encroachment': {
                        column: 'O',
                        required: true,
                    },
                    'Retained': {
                        column: 'T',
                    },
                    'Enhanced': {
                        column: 'U',
                    },
                },
            }];

            const { habitats } = this.pullValues(pages, workbook);

            return habitats;
        },

        onSiteCreatedConvert(workbook) {
            const pages = [{
                page: 'A-2 Site Habitat Creation',
                category: 'land',
                start: 10,
                fields: {
                    'Broad Habitat': {
                        column: 'D',
                        required: true,
                    },
                    'Habitat Type': {
                        column: 'E',
                        required: true,
                    },
                    'Area (hectares)': {
                        column: 'G',
                        required: true,
                    },
                    'Distinctiveness': {
                        column: 'H',
                        required: true,
                    },
                    'Condition': {
                        column: 'J',
                        required: true,
                    },
                    'Strategic significance': {
                        column: 'M',
                        required: true,
                    },
                    'Habitat created in advance/years': {
                        column: 'P',
                    },
                    'Delay in starting habitat creation/years': {
                        column: 'Q',
                    },
                },
            }, {
                page: 'B-2 Site Hedge Creation',
                category: 'hedgerows',
                start: 11,
                fields: {
                    'Hedgerow type': {
                        column: 'D',
                        required: true,
                    },
                    'Length (km)': {
                        column: 'E',
                        required: true,
                    },
                    'Distinctiveness': {
                        column: 'F',
                        required: true,
                    },
                    'Condition': {
                        column: 'H',
                        required: true,
                    },
                    'Strategic significance': {
                        column: 'K',
                        required: true,
                    },
                    'Habitat created in advance/years': {
                        column: 'N',
                    },
                    'Delay in starting habitat creation/years': {
                        column: 'O',
                    },
                },
            }, {
                page: 'C-2 Site River Creation',
                category: 'rivers',
                start: 11,
                fields: {
                    'River type': {
                        column: 'C',
                        required: true,
                    },
                    'Length (km)': {
                        column: 'D',
                        required: true,
                    },
                    'Distinctiveness': {
                        column: 'E',
                        required: true,
                    },
                    'Condition': {
                        column: 'G',
                        required: true,
                    },
                    'Strategic significance': {
                        column: 'J',
                        required: true,
                    },
                    'Watercourse encroachment': {
                        column: 'V',
                        required: true,
                    },
                    'Riparian encroachment': {
                        column: 'X',
                        required: true,
                    },
                    'Habitat created in advance/years': {
                        column: 'M',
                    },
                    'Delay in starting habitat creation/years': {
                        column: 'N',
                    },
                },
            }];

            const { habitats } = this.pullValues(pages, workbook, 'creation');

            return habitats;
        },

        onSiteEnhancementConvert(workbook) {
            const pages = [{
                page: 'A-3 Site Habitat Enhancement',
                category: 'land',
                start: 11,
                fields: {
                    'Broad Habitat': {
                        column: 'Q',
                        required: true,
                    },
                    'Habitat Type': {
                        column: 'R',
                        required: true,
                    },
                    'Area (hectares)': {
                        column: 'V',
                        required: true,
                    },
                    'Distinctiveness': {
                        column: 'W',
                        required: true,
                    },
                    'Condition': {
                        column: 'Y',
                        required: true,
                    },
                    'Strategic significance': {
                        column: 'AB',
                        required: true,
                    },
                    'Habitat created in advance/years': {
                        column: 'AE',
                    },
                    'Delay in starting habitat creation/years': {
                        column: 'AF',
                    },

                    'Baseline - Habitat': {
                        column: 'F',
                        required: true,
                    },
                    'Baseline - Area (hectares)': {
                        column: 'G',
                        required: true,
                    },
                    'Baseline - Distinctiveness': {
                        column: 'H',
                        required: true,
                    },
                    'Baseline - Condition': {
                        column: 'J',
                        required: true,
                    },
                    'Baseline - Strategic significance': {
                        column: 'L',
                        required: true,
                    },
                },
            }, {
                page: 'B-3 Site Hedge Enhancement',
                category: 'hedgerows',
                start: 11,
                fields: {
                    'Hedgerow type': {
                        column: 'M',
                        required: true,
                    },
                    'Length (km)': {
                        column: 'P',
                        required: true,
                    },
                    'Distinctiveness': {
                        column: 'Q',
                        required: true,
                    },
                    'Condition': {
                        column: 'S',
                        required: true,
                    },
                    'Strategic significance': {
                        column: 'V',
                        required: true,
                    },
                    'Habitat created in advance/years': {
                        column: 'Y',
                    },
                    'Delay in starting habitat creation/years': {
                        column: 'Z',
                    },

                    'Baseline - Hedgerow type': {
                        column: 'C',
                        required: true,
                    },
                    'Baseline - Length (km)': {
                        column: 'D',
                        required: true,
                    },
                    'Baseline - Distinctiveness': {
                        column: 'E',
                        required: true,
                    },
                    'Baseline - Condition': {
                        column: 'G',
                        required: true,
                    },
                    'Baseline - Strategic significance': {
                        column: 'I',
                        required: true,
                    },
                },
            }, {
                page: 'C-3 Site River Enhancement',
                category: 'rivers',
                start: 11,
                fields: {
                    'River type': {
                        column: 'N',
                        required: true,
                    },
                    'Length (km)': {
                        column: 'Q',
                        required: true,
                    },
                    'Distinctiveness': {
                        column: 'R',
                        required: true,
                    },
                    'Condition': {
                        column: 'T',
                        required: true,
                    },
                    'Strategic significance': {
                        column: 'W',
                        required: true,
                    },
                    'Watercourse encroachment': {
                        column: 'AI',
                        required: true,
                    },
                    'Riparian encroachment': {
                        column: 'AK',
                        required: true,
                    },
                    'Habitat created in advance/years': {
                        column: 'Z',
                    },
                    'Delay in starting habitat creation/years': {
                        column: 'AA',
                    },

                    'Baseline - River type': {
                        column: 'C',
                        required: true,
                    },
                    'Baseline - Length (km)': {
                        column: 'D',
                        required: true,
                    },
                    'Baseline - Distinctiveness': {
                        column: 'E',
                        required: true,
                    },
                    'Baseline - Condition': {
                        column: 'G',
                        required: true,
                    },
                    'Baseline - Strategic significance': {
                        column: 'J',
                        required: true,
                    },
                },
            }];

            const { habitats } = this.pullValues(pages, workbook, 'enhancement');

            return habitats;
        },

        pullValues(pages, workbook, type = this.defaultType) {
            const sheetData = [];

            pages.forEach(item => {
                const {page, category, start, fields} = item;

                if (workbook.Sheets[page]) {
                    const sheet = workbook.Sheets[page];
                    const requiredFields = Object.keys(fields);
                    let looping = true,
                        index = start + 1;

                    while (looping) {
                        let hasData = false;
                        const dataMissing = [];
                        const push = {
                            category: category,
                            missing: [],
                        };

                        requiredFields.forEach(field => {
                            const { column, required } = fields[field];

                            if (sheet[`${column}${index}`] && sheet[`${column}${index}`].v !== undefined && sheet[`${column}${index}`].v !== '') {
                                push[field] = sheet[`${column}${index}`].v;
                                hasData = true;
                            } else if (required) {
                                dataMissing.push(field);
                            }
                        });

                        if (!hasData || index > 257) {
                            looping = false;
                        } else {
                            if (dataMissing.length) push.missing = dataMissing;
                            sheetData.push(push);
                        }

                        index++;
                    }
                }
            });

            const habitats = [];

            sheetData.forEach(habitat => {
                const push = this.parseHabitat(habitat, type);

                if (Object.keys(push).length) {
                    habitats.push(this.parseHabitat(habitat, type));
                }
            });

            return { sheetData, habitats };
        },

        parseHabitat(habitat, type = this.defaultType) {
            let parsed = {};

            switch (habitat.category) {
                case 'land':
                    if (
                        habitat.category &&
                        habitat['Broad Habitat'] &&
                        habitat['Habitat Type'] &&
                        habitat['Distinctiveness'] &&
                        habitat['Area (hectares)'] &&
                        habitat['Condition'] &&
                        habitat['Strategic significance']
                    ) {
                        parsed = {
                            category: this.parseHabitatString(habitat.category),
                            broad_habitat: this.parseHabitatString(habitat['Broad Habitat']),
                            type: this.parseHabitatString(habitat['Habitat Type']),
                            size: habitat['Area (hectares)'],
                            distinctiveness: this.getDistinctivness(habitat['Distinctiveness']),
                            condition: this.parseHabitatString(habitat['Condition']),
                            strategic_significance: this.getStrategicSignificance(habitat['Strategic significance']),
                            retained: this.parseHabitatString(habitat['Retained']),
                            enhanced: this.parseHabitatString(habitat['Enhanced']),
                            missing: habitat.missing,
                        };

                        if (type === 'enhancement') {
                            const baesline = habitat['Baseline - Habitat'].split(' - ')[0];
                            const type = habitat['Baseline - Habitat'].split(' - ')[1];

                            parsed.baseline = {
                                broad_habitat: this.parseHabitatString(baesline),
                                type: this.parseHabitatString(type),
                                size: habitat['Baseline - Area (hectares)'],
                                distinctiveness: this.getDistinctivness(habitat['Baseline - Distinctiveness']),
                                condition: this.parseHabitatString(habitat['Baseline - Condition']),
                                strategic_significance: this.getStrategicSignificance(habitat['Baseline - Strategic significance']),
                            };

                            if (!habitat['Retained'] && habitat['Retained'] !== 0 && !parsed.missing.includes('Baseline - Retained')) {
                                parsed.missing.push('Baseline - Retained');
                            }

                            if (!habitat['Enhanced'] && habitat['Enhanced'] !== 0 && !parsed.missing.includes('Baseline - Enhanced')) {
                                parsed.missing.push('Baseline - Enhanced');
                            }
                        }
                    }

                    break;

                case 'hedgerows':
                    if (
                        habitat.category &&
                        habitat['Hedgerow type'] &&
                        habitat['Distinctiveness'] &&
                        habitat['Length (km)'] &&
                        habitat['Condition'] &&
                        habitat['Strategic significance']
                    ) {
                        parsed = {
                            category: habitat.category,
                            type: this.parseHabitatString(habitat['Hedgerow type']),
                            size: habitat['Length (km)'] * 1000,
                            distinctiveness: this.getDistinctivness(habitat['Distinctiveness']),
                            condition: this.parseHabitatString(habitat['Condition']),
                            strategic_significance: this.getStrategicSignificance(habitat['Strategic significance']),
                            retained: this.parseHabitatString(habitat['Retained']),
                            enhanced: this.parseHabitatString(habitat['Enhanced']),
                            missing: habitat.missing,
                        };

                        if (type === 'enhancement') {
                            parsed.baseline = {
                                type: this.parseHabitatString(habitat['Baseline - Hedgerow type']),
                                size: habitat['Baseline - Length (km)'] * 1000,
                                distinctiveness: this.getDistinctivness(habitat['Baseline - Distinctiveness']),
                                condition: this.parseHabitatString(habitat['Baseline - Condition']),
                                strategic_significance: this.getStrategicSignificance(habitat['Baseline - Strategic significance']),
                            };

                            if (!habitat['Retained'] && !parsed.missing.includes('Baseline Retained')) {
                                parsed.missing.push('Baseline Retained');
                            }

                            if (!habitat['Enhanced'] && !parsed.missing.includes('Baseline Enhanced')) {
                                parsed.missing.push('Baseline Enhanced');
                            }
                        }
                    }

                    break;

                case 'rivers':
                    if (
                        habitat.category &&
                        habitat['River type'] &&
                        habitat['Distinctiveness'] &&
                        habitat['Length (km)'] &&
                        habitat['Condition'] &&
                        habitat['Strategic significance'] &&
                        habitat['Watercourse encroachment'] &&
                        habitat['Riparian encroachment']
                    ) {
                        parsed = {
                            category: habitat.category,
                            type: this.parseHabitatString(habitat['River type']),
                            size: habitat['Length (km)'],
                            distinctiveness: this.getDistinctivness(habitat['Distinctiveness']),
                            condition: this.parseHabitatString(habitat['Condition']),
                            strategic_significance: this.getStrategicSignificance(habitat['Strategic significance']),
                            watercourse_encroachment: this.parseHabitatString(habitat['Watercourse encroachment']),
                            riparian_encroachment: this.parseHabitatString(habitat['Riparian encroachment']),
                            retained: this.parseHabitatString(habitat['Retained']),
                            enhanced: this.parseHabitatString(habitat['Enhanced']),
                            missing: habitat.missing,
                        };

                        if (type === 'enhancement') {
                            parsed.baseline = {
                                type: this.parseHabitatString(habitat['Baseline - River type']),
                                size: habitat['Baseline - Length (km)'],
                                distinctiveness: this.getDistinctivness(habitat['Baseline - Distinctiveness']),
                                condition: this.parseHabitatString(habitat['Baseline - Condition']),
                                strategic_significance: this.getStrategicSignificance(habitat['Baseline - Strategic significance']),
                            };

                            if (!habitat['Retained'] && !parsed.missing.includes('Baseline Retained')) {
                                parsed.missing.push('Baseline Retained');
                            }

                            if (!habitat['Enhanced'] && !parsed.missing.includes('Baseline Enhanced')) {
                                parsed.missing.push('Baseline Enhanced');
                            }
                        }
                    }

                    break;

                default:
                    parsed = {};
                    break;
            }

            if (parsed.type) {
                if (type === 'creation' || type === 'enhancement') {
                    parsed.year_created_advance = habitat['Habitat created in advance/years'] || 0;
                    parsed.year_start_delay = habitat['Delay in starting habitat creation/years'] || 0;
                }
            }

            return parsed;
        },

        getStrategicSignificance(value) {
            value = this.parseHabitatString(value);

            switch (value) {
                case 'high-strategic-significance':
                    return 'high';
                case 'medium-strategic-significance':
                    return 'medium';
                case 'low-strategic-significance':
                    return 'low';
                default:
                    return value;
            }
        },

        getDistinctivness(value) {
            value = this.parseHabitatString(value);

            switch (value) {
                case 'vhigh':
                    return 'very-high';
                case 'vlow':
                    return 'very-low';
                default:
                    return value;
            }
        },

        parseHabitatString(value) {
            if (value && typeof value === 'string') {
                return value.trim().toLowerCase().replace(/[[\]\\().;:,]/g, '').replace(/\s|\//g, '-').replace(/--+/g, '-');
            } else if (value !== undefined) {
                return value;
            }

            return '';
        },
    },
};
