var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "gallery" },
    [
      _vm.images.length > 0
        ? _c(
            "ul",
            { staticClass: "gallery-list lst-reset" },
            _vm._l(_vm.images, function (image, index) {
              return _c(
                "li",
                { key: index, staticClass: "gallery-list-item" },
                [
                  image.type === "photo" || image.type === "image"
                    ? _c("div", {
                        staticClass: "gallery-list-item-img",
                        style: {
                          "background-image": "url(" + image.filepath + ")",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.openImage(image)
                          },
                        },
                      })
                    : image.type === "video"
                    ? _c(
                        "video",
                        {
                          staticClass: "gallery-list-item-video",
                          on: {
                            click: function ($event) {
                              return _vm.openImage(image)
                            },
                          },
                        },
                        [
                          _c("source", { attrs: { src: image.filepath } }),
                          _vm._v(
                            "\n                Your browser does not support the video tag.\n            "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "gallery-list-item-info flex-space-between",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "gallery-list-item-info-title" },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(image.display_name) +
                              "\n                "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _vm.deletable &&
                      (_vm.$page.props.loggedUser.isSuperUser ||
                        _vm.$page.props.loggedUser.id === image.user_id)
                        ? _c(
                            "div",
                            { staticClass: "gallery-list-item-info-delete" },
                            [
                              _c("SvgController", {
                                attrs: { type: "trashcan" },
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.deleteImage(image)
                                  },
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  ),
                ]
              )
            }),
            0
          )
        : _c("p", { staticClass: "text-grey4" }, [
            _vm._v("\n        There are no photos yet.\n    "),
          ]),
      _vm._v(" "),
      _c("Modal", {
        ref: "imageModal",
        staticClass: "modal--img",
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function () {
              return [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.selectedImage.display_name) +
                    "\n        "
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "body",
            fn: function () {
              return [
                _vm.selectedImage.type === "photo" ||
                _vm.selectedImage.type === "image"
                  ? _c("div", {
                      staticClass: "modal-image",
                      style: {
                        "background-image":
                          "url(" + _vm.selectedImage.filepath + ")",
                      },
                    })
                  : _vm.selectedImage.type === "video"
                  ? _c(
                      "video",
                      {
                        staticClass: "modal-image",
                        attrs: { controls: "", autoplay: "" },
                      },
                      [
                        _c("source", {
                          attrs: { src: _vm.selectedImage.filepath },
                        }),
                        _vm._v(
                          "\n                Your browser does not support the video tag.\n            "
                        ),
                      ]
                    )
                  : _vm._e(),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }