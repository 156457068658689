var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("modal", {
    ref: "create",
    scopedSlots: _vm._u([
      {
        key: "header",
        fn: function () {
          return [_c("h3", [_vm._v("Add operating in LPA")])]
        },
        proxy: true,
      },
      {
        key: "body",
        fn: function () {
          return [
            _c(
              "FormulateForm",
              {
                ref: "form",
                staticClass: "flex-col",
                attrs: { errors: _vm.$page.props.errors },
                on: { submit: _vm.submitForm },
                model: {
                  value: _vm.form,
                  callback: function ($$v) {
                    _vm.form = $$v
                  },
                  expression: "form",
                },
              },
              [
                _c("FormulateInput", {
                  attrs: {
                    type: "select-plugin",
                    name: "lpa",
                    validation: "required",
                    label: "Local Planning Authority",
                    "validation-name": "lpa",
                    "reduce-key": "auth_id",
                    "label-key": "name",
                    options: _vm.planningAuthoritiesSelect,
                    placeholder: "Local Planning Authority",
                  },
                }),
                _vm._v(" "),
                _c("div", { staticClass: "formulate-input btn--group" }, [
                  _c(
                    "a",
                    {
                      staticClass: "btn",
                      attrs: { href: "#" },
                      on: { click: _vm.$refs.create.closeModal },
                    },
                    [_vm._v("\n                    Cancel\n                ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn--reset btn btn--primary",
                      attrs: { type: "submit" },
                    },
                    [
                      _c("SvgController", { attrs: { type: "tick" } }),
                      _vm._v("\n                    Save\n                "),
                    ],
                    1
                  ),
                ]),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }