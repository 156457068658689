<template>
    <section class="vue-formulate-drawmap">
        <draw-map
            ref="map"
            key="map"
            v-model="context.model"
            :type="mapType"
            edit
            v-bind="context.attributes"
        />
    </section>
</template>

<script>
import DrawMap from 'Utilities/maps/DrawMap';
import FormulateInputMixin from '@braid/vue-formulate/src/FormulateInputMixin';

export default {
    name: 'FormulateMap',
    components: {
        DrawMap,
    },
    mixins: [FormulateInputMixin],

    props: {
        mapType: {
            type: String,
            required: true,
        },
    },

    mounted() {},
};
</script>
