var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "app-layout",
    { attrs: { section: "dashboard" } },
    [
      _c(
        "page-header",
        {
          attrs: { title: _vm.headerTitle, icon: _vm.headerIcon },
          scopedSlots: _vm._u([
            {
              key: "page-header-bottom",
              fn: function () {
                return [
                  _c("div", { staticClass: "center" }, [
                    !_vm.superuserDashboard
                      ? _c(
                          "div",
                          { staticClass: "tile-wrap flex-space-between" },
                          [
                            _vm.show.includes("landownersTotal")
                              ? _c("tile", {
                                  attrs: {
                                    href: _vm.landownersLink,
                                    title: "Landowners",
                                    icon: "briefcase",
                                    count: !isNaN(
                                      _vm.landowners && _vm.landowners.total
                                    )
                                      ? _vm.landowners.total
                                      : 0,
                                    color: "#E34B5D",
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.show.includes("projectsTotal")
                              ? _c("tile", {
                                  attrs: {
                                    href: _vm.projectsLink,
                                    title: "Total Developments",
                                    icon: "file-tick",
                                    count: !isNaN(
                                      _vm.projects && _vm.projects.total
                                    )
                                      ? _vm.projects.total
                                      : 0,
                                    color: "#5EBD8A",
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.show.includes("projectsPending")
                              ? _c("tile", {
                                  attrs: {
                                    href: _vm.projectsLink,
                                    title: "Pending Developments",
                                    icon: "file-info",
                                    count: !isNaN(
                                      _vm.projects && _vm.projects.pending
                                    )
                                      ? _vm.projects.pending
                                      : 0,
                                    color: "#E47D44",
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.show.includes("projectsCompleted")
                              ? _c("tile", {
                                  attrs: {
                                    href: _vm.projectsLink,
                                    title: "Completed Developments",
                                    icon: "file-tick",
                                    count: !isNaN(
                                      _vm.projects && _vm.projects.completed
                                    )
                                      ? _vm.projects.completed
                                      : 0,
                                    color: "#5EBD8A",
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.show.includes("plotsTotal")
                              ? _c("tile", {
                                  attrs: {
                                    href: _vm.plotsLink,
                                    title: "Total Land Parcels",
                                    icon: "plots",
                                    count: !isNaN(_vm.plots && _vm.plots.total)
                                      ? _vm.plots.total
                                      : 0,
                                    color: "#5EBD8A",
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.show.includes("propertyDevelopersTotal")
                              ? _c("tile", {
                                  attrs: {
                                    href: _vm.propertyDevelopersLink,
                                    title: "Property Devs",
                                    icon: "building",
                                    count: !isNaN(
                                      _vm.propertyDevelopers &&
                                        _vm.propertyDevelopers.total
                                    )
                                      ? _vm.propertyDevelopers.total
                                      : 0,
                                    color: "#1782FF",
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.show.includes("projectsActive")
                              ? _c("tile", {
                                  attrs: {
                                    href: _vm.projectsLink,
                                    title: "Active Developments",
                                    icon: "files",
                                    count: !isNaN(
                                      _vm.projects && _vm.projects.total
                                    )
                                      ? _vm.projects.total
                                      : 0,
                                    color: "#6967D5",
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.show.includes("totalCredits")
                              ? _c("tile", {
                                  attrs: {
                                    title: "Total Credits",
                                    icon: "hex",
                                    count: !isNaN(
                                      _vm.plots && _vm.plots.totalCredit
                                    )
                                      ? _vm.plots.totalCredit
                                      : 0,
                                    color: "#6967D5",
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.show.includes("availableCredits")
                              ? _c("tile", {
                                  attrs: {
                                    title: "Available Credits",
                                    icon: "hex-tick",
                                    count: !isNaN(
                                      _vm.plots && _vm.plots.availableCredit
                                    )
                                      ? _vm.plots.availableCredit
                                      : 0,
                                    color: "#5EBD8A",
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.show.includes("rentedCredits")
                              ? _c("tile", {
                                  attrs: {
                                    title: "Implemented Credits",
                                    icon: "hex-sketch",
                                    count: !isNaN(
                                      _vm.plots && _vm.plots.rentedCredit
                                    )
                                      ? _vm.plots.rentedCredit
                                      : 0,
                                    color: "#E47D44",
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        )
                      : _c("div", [
                          _c(
                            "div",
                            {
                              staticClass:
                                "tile-wrap tile-wrap-top flex-space-between",
                            },
                            [
                              _c("tile", {
                                attrs: {
                                  href: _vm.landownersLink,
                                  title: "Landowners",
                                  icon: "briefcase",
                                  count: !isNaN(
                                    _vm.landowners && _vm.landowners.total
                                  )
                                    ? _vm.landowners.total
                                    : 0,
                                  color: "#E34B5D",
                                },
                              }),
                              _vm._v(" "),
                              _c("tile", {
                                attrs: {
                                  href: _vm.plotsLink,
                                  title: "Total Land Parcels",
                                  icon: "plots",
                                  count: !isNaN(_vm.plots && _vm.plots.total)
                                    ? _vm.plots.total
                                    : 0,
                                  color: "#5EBD8A",
                                },
                              }),
                              _vm._v(" "),
                              _c("tile", {
                                attrs: {
                                  title: "Total Available Units",
                                  icon: "hex",
                                  count: !isNaN(
                                    _vm.plots && _vm.plots.availableCredit
                                  )
                                    ? _vm.plots.availableCredit
                                    : 0,
                                  color: "#6967D5",
                                },
                              }),
                              _vm._v(" "),
                              _c("tile", {
                                attrs: {
                                  title: "Total Hectares",
                                  icon: "hex-tick",
                                  count: !isNaN(
                                    _vm.plots && _vm.plots.totalHectares
                                  )
                                    ? _vm.plots.totalHectares
                                    : 0,
                                  color: "#5EBD8A",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "tile-wrap flex-space-between" },
                            [
                              _c("tile", {
                                attrs: {
                                  href: _vm.propertyDevelopersLink,
                                  title: "Property Devs",
                                  icon: "building",
                                  count: !isNaN(
                                    _vm.propertyDevelopers &&
                                      _vm.propertyDevelopers.total
                                  )
                                    ? _vm.propertyDevelopers.total
                                    : 0,
                                  color: "#1782FF",
                                },
                              }),
                              _vm._v(" "),
                              _c("tile", {
                                attrs: {
                                  href: _vm.projectsLink,
                                  title: "Total Developments",
                                  icon: "files",
                                  count: !isNaN(
                                    _vm.projects && _vm.projects.total
                                  )
                                    ? _vm.projects.total
                                    : 0,
                                  color: "#6967D5",
                                },
                              }),
                              _vm._v(" "),
                              _c("tile", {
                                attrs: {
                                  title: "Total Required Units",
                                  icon: "hex-tick",
                                  count: !isNaN(
                                    _vm.projects &&
                                      _vm.projects.totalRequiredUnits
                                  )
                                    ? _vm.projects.totalRequiredUnits
                                    : 0,
                                  color: "#5EBD8A",
                                },
                              }),
                              _vm._v(" "),
                              _c("tile", {
                                attrs: {
                                  title: "Total Units Offset",
                                  icon: "hex-tick",
                                  count: !isNaN(
                                    _vm.projects &&
                                      _vm.projects.totalUnitsOffset
                                  )
                                    ? _vm.projects.totalUnitsOffset
                                    : 0,
                                  color: "#5EBD8A",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                  ]),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _vm.show.includes("buttonProject")
            ? _c(
                "inertia-link",
                {
                  staticClass: "btn btn--primary",
                  attrs: { href: "$route('project.create')" },
                },
                [
                  _c("SvgController", { attrs: { type: "plus" } }),
                  _vm._v(" "),
                  _c("span", [_vm._v("New Development")]),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.show.includes("buttonPlot")
            ? _c(
                "inertia-link",
                {
                  staticClass: "btn btn--primary",
                  attrs: { href: _vm.$route("plot.create") },
                },
                [
                  _c("SvgController", { attrs: { type: "plus" } }),
                  _vm._v(" "),
                  _c("span", [_vm._v("New Land Parcel")]),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.show.includes("buttonCompany") && _vm.hasGroup(["sequest"])
            ? _c(
                "div",
                [
                  _c(
                    "a",
                    {
                      staticClass: "btn btn--primary",
                      attrs: { href: "#" },
                      on: { click: _vm.showCompanyModal },
                    },
                    [_vm._v("Edit Company")]
                  ),
                  _vm._v(" "),
                  _c("modal", {
                    ref: "companyModal",
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "header",
                          fn: function () {
                            return [_c("h3", [_vm._v("Edit Company")])]
                          },
                          proxy: true,
                        },
                        {
                          key: "body",
                          fn: function () {
                            return [
                              _c("CompanyEdit", {
                                attrs: {
                                  company: _vm.company,
                                  "company-type": _vm.companyType,
                                },
                                on: { closeModal: _vm.closeCompanyModal },
                              }),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      2105361956
                    ),
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "center dashboard" },
        [
          _vm.show.includes("propertyDevelopers")
            ? [
                _c(
                  "div",
                  { staticClass: "dashboard-heading flex-space-between" },
                  [
                    _c("h6", [_vm._v("Property Developers")]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "dashboard-heading-buttons" },
                      [
                        _c(
                          "inertia-link",
                          {
                            staticClass:
                              "btn-reset btn--link btn--link--icon--right",
                            attrs: { href: _vm.propertyDevelopersLink },
                          },
                          [
                            _vm._v(
                              "\n                        All Property Developers\n                        "
                            ),
                            _c("SvgController", {
                              attrs: { type: "arrow-right" },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _vm.propertyDevelopers && _vm.propertyDevelopers.total
                  ? [
                      _c("property-developers-table", {
                        attrs: {
                          "property-developers":
                            _vm.propertyDevelopers.propertyDevelopers,
                          "allow-sorting": false,
                          "has-header": false,
                          "has-footer": false,
                          "has-dropdown": false,
                        },
                      }),
                    ]
                  : [_c("p", [_vm._v("No Property Developers")])],
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.show.includes("projects") &&
          _vm.projects &&
          _vm.projects.projects.data
            ? [
                _c(
                  "div",
                  { staticClass: "dashboard-heading flex-space-between" },
                  [
                    _c("h6", [_vm._v("Latest Developments")]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "dashboard-heading-buttons" },
                      [
                        _c(
                          "inertia-link",
                          {
                            staticClass:
                              "btn-reset btn--link btn--link--icon--right",
                            attrs: { href: _vm.projectsLink },
                          },
                          [
                            _vm._v(
                              "\n                        All Developments\n                        "
                            ),
                            _c("SvgController", {
                              attrs: { type: "arrow-right" },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm.show.includes("buttonNew")
                          ? _c(
                              "inertia-link",
                              {
                                staticClass:
                                  "btn-reset btn--link btn--link--green btn--link--icon--left",
                                attrs: {
                                  href: _vm.$route("project.create"),
                                  as: "button",
                                  type: "button",
                                },
                              },
                              [
                                _c("SvgController", {
                                  attrs: { type: "plus" },
                                }),
                                _vm._v(
                                  "\n                        New Development\n                    "
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _vm.projects.projects.data.length
                  ? [
                      _c("project-table", {
                        attrs: {
                          projects: _vm.projects.projects,
                          statuses: _vm.$page.props.statuses,
                          "allow-sorting": false,
                          "has-header": false,
                          "has-footer": false,
                          "has-dropdown": false,
                          "force-hide-company": _vm.section !== "dashboard",
                        },
                      }),
                    ]
                  : [_c("p", [_vm._v("No Developments Created")])],
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.show.includes("landowners")
            ? [
                _c(
                  "div",
                  { staticClass: "dashboard-heading flex-space-between" },
                  [
                    _c("h6", [_vm._v("Landowners")]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "dashboard-heading-buttons" },
                      [
                        _c(
                          "inertia-link",
                          {
                            staticClass:
                              "btn-reset btn--link btn--link--icon--right",
                            attrs: { href: _vm.landownersLink },
                          },
                          [
                            _vm._v(
                              "\n                        All Landowners\n                        "
                            ),
                            _c("SvgController", {
                              attrs: { type: "arrow-right" },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _vm.landowners && _vm.landowners.total
                  ? [
                      _c("landowner-table", {
                        attrs: {
                          landowners: _vm.landowners.landowners,
                          "allow-sorting": false,
                          "has-header": false,
                          "has-footer": false,
                          "has-dropdown": false,
                        },
                      }),
                    ]
                  : [_c("p", [_vm._v("No Landowners")])],
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.show.includes("plots")
            ? [
                _c(
                  "div",
                  { staticClass: "dashboard-heading flex-space-between" },
                  [
                    _c("h6", [_vm._v("Latest Land Parcels")]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "dashboard-heading-buttons" },
                      [
                        _c(
                          "inertia-link",
                          {
                            staticClass:
                              "btn-reset btn--link btn--link--icon--right",
                            attrs: { href: _vm.plotsLink },
                          },
                          [
                            _vm._v(
                              "\n                        All Land Parcels\n                        "
                            ),
                            _c("SvgController", {
                              attrs: { type: "arrow-right" },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm.show.includes("buttonNew")
                          ? _c(
                              "inertia-link",
                              {
                                staticClass:
                                  "btn-reset btn--link btn--link--green btn--link--icon--left",
                                attrs: {
                                  href: _vm.$route("plot.create"),
                                  as: "button",
                                  type: "button",
                                },
                              },
                              [
                                _c("SvgController", {
                                  attrs: { type: "plus" },
                                }),
                                _vm._v(
                                  "\n                        New Land Parcel\n                    "
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _vm.plots && _vm.plots.plots.data.length
                  ? [
                      _c("plot-table", {
                        attrs: {
                          plots: _vm.plots.plots,
                          statuses: _vm.$page.props.statuses,
                          "allow-sorting": false,
                          "has-header": false,
                          "has-footer": false,
                          "has-dropdown": false,
                          "force-hide-company": _vm.section !== "dashboard",
                        },
                      }),
                    ]
                  : [_c("p", [_vm._v("No Land Parcels Created")])],
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.show.includes("reports") &&
          _vm.reports &&
          _vm.reports.data &&
          _vm.reports.data.length
            ? [
                _c(
                  "div",
                  { staticClass: "dashboard-heading flex-space-between" },
                  [
                    _c("h6", [_vm._v("Latest Reports")]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "dashboard-heading-buttons" },
                      [
                        _c(
                          "inertia-link",
                          {
                            staticClass:
                              "btn-reset btn--link btn--link--icon--right",
                            attrs: {
                              href: _vm.$route("reports"),
                              as: "button",
                              type: "button",
                            },
                          },
                          [
                            _vm._v(
                              "\n                        All Reports\n                        "
                            ),
                            _c("SvgController", {
                              attrs: { type: "arrow-right" },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _vm.reports.data.length
                  ? [
                      _c("reports-table", {
                        attrs: { list: _vm.reports.data, "has-header": false },
                      }),
                    ]
                  : [_c("p", [_vm._v("No Reports Found")])],
              ]
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }