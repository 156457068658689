var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: _vm.active === true ? "active" : "",
      attrs: { id: "site-sidebar" },
    },
    [
      _c("dropdownMenuList", { attrs: { data: _vm.data } }),
      _vm._v(" "),
      _c("div", {
        staticClass:
          "tab-d-none absolute left-full top-0 bottom-0 w-100vw cursor-pointer",
        class: {
          "d-none": !_vm.active,
        },
        on: {
          click: function ($event) {
            return _vm.$emit("toggleSidebar")
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }