<template>
    <app-layout section="habitats">
        <page-header
            :title="pageTitle"
            icon="plots"
            :background="false"
        >
            <a
                class="btn-reset btn"
                :href="$route('map')"
                @click.prevent="closeForm"
            >
                Cancel
            </a>
            <button
                id="form-submit"
                class="btn-reset btn btn--primary"
                @click="submitFormButton"
            >
                <Processing v-if="uploading">
                    Submitting
                </Processing>
                <template v-else>
                    <SvgController
                        v-if="!edit"
                        type="tick"
                    />
                    <span>
                        {{ buttonText }}
                    </span>
                </template>
            </button>
        </page-header>
        <div class="center">
            <HabitatFormComponent
                ref="habitatForm"
                :habitat="habitat"
                :edit="edit"
                :lpas="getLpas"
                :rivers="rivers"
                :save-id="owner.id"
                save-type="habitat"
                :habitats="habitats"
                @submit-start="uploading = true"
            />
        </div>
    </app-layout>
</template>

<script>
import HabitatFormComponent from 'Components/habitats/Form';
import Processing from 'Utilities/processing/processing';

export default {
    name: 'HabitatForm',
    components: {
        HabitatFormComponent,
        Processing,
    },

    props: {
        habitat: {
            type: Number,
            default: null,
        },
        edit: {
            type: Boolean,
            default: false,
        },
        owner: {
            type: Object,
            required: true,
        },
        lpas: {
            type: Array,
            default: () => [],
        },
        rivers: {
            type: Array,
            default: () => [],
        },
        habitats: {
            type: Array,
            default: () => ([]),
        },
    },

    data() {
        return {
            uploading: false,
        };
    },

    computed: {
        pageTitle() {
            return this.edit ? 'Edit Habitat' : 'New Habitat';
        },

        buttonText() {
            return this.edit ? 'Save Changes' : 'Save';
        },

        getLpas() {
            if (this.hasGroup('lpa')) {
                return [this.owner.local_planning_authority];
            } else if (this.lpas && this.lpas.length) {
                return this.lpas;
            }

            return null;
        },
    },

    methods: {
        closeForm() {
            if (this.formChange === false || confirm('Are you sure you want to leave and discard any changes?')) {
                this.$inertia.visit(this.$route('map'));
            }
        },

        submitFormButton() {
            if (!this.uploading) {
                this.$refs.habitatForm.submitFormButton();
            }
        },
    },
};
</script>
