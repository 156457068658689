<template>
    <div class="empty-container text-center">
        <div class="empty-img">
            <img src="/images/empty.svg">
        </div>
        <div class="empty-text">
            <slot name="message">
                <h3>{{ message }}</h3>
            </slot>
            <slot />
        </div>
    </div>
</template>
<script>
export default {
    name: 'Empty',
    props: {
        type: {
            type: String,
            default: null,
        },
    },
    computed: {
        message() {
            let type = this.type;

            if (!type) {
                type = 'item';
            }

            if (type.slice(-1) === 's') {
                type = type.slice(0, -1);
            }

            return `You don't have any ${type}s yet`;
        },
    },
};
</script>
