<template>
    <component
        :is="component"
        v-if="shapeData.shape"
        :ref="`${type}${shapeIndex}`"
        :key="`${type}${shapeIndex}`"
        v-bind="shapeData"
        :fill-color="color"
        :stroke-color="isActive ? 'black' : strokeColor"
        :fill-opacity="fillOpacity"
        :stroke-weight="1"
        :z-index="zIndex"
        :editable="editable"
        :draggable="draggable"
        @dragstart="dragStart"
        @dragend="dragEnd"
        @bounds_changed="dragEnd"
        @ready="ready"
    />
</template>

<script>

import MapMixin from 'Mixins/map';
import polyFill from 'Lib/mapFunctionality/polyFill';
import { getTheme } from 'Lib/defaults';

export default {
    name: '',
    components: {},

    mixins: [
        MapMixin,
    ],

    props: {
        shapeIndex: {
            type: Number,
            default: 0,
        },
        shape: {
            type: [Object, Array, String],
            required: true,
        },

        type: {
            type: String,
            required: true,
            validator: function(value) {
                return ['plot', 'project', 'habitat', 'lease', 'lpa'].indexOf(value) !== -1;
            },
        },

        fillColor: {
            type: String,
            default: null,
        },

        theme: {
            type: [String, Object],
            default: null,
        },

        habitatType: {
            type: String,
            default: null,
        },
        zIndex: {
            type: Number,
            default: null,
        },
        item: {
            type: Object,
            default: null,
        },
        editable: {
            type: Boolean,
            default: false,
        },
        draggable: {
            type: Boolean,
            default: false,
        },

        clickAction: {
            type: String,
            default: null,
        },

        isActive: {
            type: Boolean,
            default: false,
        },
        multiple: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            polyFill: null,
            mapListeners: {},
            isDragging: false,
            shapeReady: false,
        };
    },

    computed: {
        shapeData: {
            get() {
                let shapeData = this.shape;

                if (typeof shapeData === 'string') {
                    shapeData = JSON.parse(shapeData);
                }

                if (Array.isArray(shapeData)) {
                    shapeData = {
                        paths: shapeData,
                        shape: 'polygon',
                    };
                }

                if (this.shapeReady) {
                    this.attachListeners(shapeData.shape);
                }

                if (shapeData.shape === 'polygon') {
                    shapeData.paths = shapeData.paths.map(path => ({
                        lat: typeof path.lat === 'function' ? parseFloat(path.lat()) : parseFloat(path.lat),
                        lng: typeof path.lng === 'function' ? parseFloat(path.lng()) : parseFloat(path.lng),
                    }));
                }

                return shapeData;
            },
            set(value) {
                this.$emit('layerUpdated', value, this.shapeIndex);
            },
        },

        shapeItem() {
            const item = Object.assign(this.item, {});

            if (item.location_data && item.location_data.layer) item.location_data.layer = {...this.shapeData};

            return item;
        },

        component() {
            return `google-map-${this.shapeData.shape}`;
        },

        color() {
            if (this.fillColor) {
                return this.fillColor;
            } else if (this.type === 'habitat') {
                if (this.habitatType) {
                    if (this.$refs[`${this.type}${this.shapeIndex}`]) {
                        this.$refs[`${this.type}${this.shapeIndex}`].$_mapElement.setOptions({
                            fillColor: this.mapColors[this.habitatType],
                            strokeColor: this.mapColors[this.habitatType],
                        });
                    }

                    return this.mapColors[this.habitatType];
                }
            }

            return this.mapColors[this.type];
        },

        fillOpacity() {
            if (this.type === 'lpa' || this.theme) {
                return 0;
            }

            return 0.4;
        },

        strokeColor() {
            if (this.theme) {
                return this.parseTheme.fill;
            }

            return this.color;
        },

        parseTheme() {
            return {
                ...this.theme,
                fill: this.fillColor,
                stroke: this.theme.fill,
            };
        },
    },

    watch: {
        isActive(value) {
            if (this.shapeData.shape === 'circle') {
                if (value) {
                    this.$refs[`${this.type}${this.shapeIndex}`].$_mapElement.setOptions({
                        strokeColor: 'black',
                    });

                    return;
                }

                this.$refs[`${this.type}${this.shapeIndex}`].$_mapElement.setOptions({
                    strokeColor: this.mapColors[this.habitatType],
                });
            }
        },

        theme() {
            this.refreshFill();
        },

        editable() {
            this.attachListeners(this.shapeData);
        },
    },

    beforeDestroy() {
        if (this.polyFill) {
            this.polyFill.remove();
        }
    },

    methods:{
        ready() {
            this.shapeReady = true;

            if (!this.multiple) {
                switch(this.shapeData.shape) {
                    case 'circle':
                        this.updateCircle();
                        break;
                    case 'rectangle':
                        this.updateRectangle();
                        break;
                    case 'polygon':
                        this.updatePolygon();
                        break;
                    default:
                        break;
                }
            }

            this.shapeFill();
        },
        dragStart() {
            this.isDragging = true;
        },

        dragEnd() {
            this.isDragging = false;

            switch(this.shapeData.shape) {
                case 'circle':
                    this.updateCircle();
                    break;
                case 'rectangle':
                    this.updateRectangle();
                    break;
                case 'polygon':
                    this.updatePolygon();
                    break;
                default:
                    break;
            }
        },

        attachListeners(shape) {
            this.detachListners();
            setTimeout(() => {
                const shapeElement = this.$refs[`${this.type}${this.shapeIndex}`].$_mapElement;

                this.mapListeners.click = google.maps.event.addListener(shapeElement, 'click', (event) => {
                    if (this.editable || this.multiple) {
                        if (this.clickAction === 'delete') {
                            if (shape === 'polygon' && event.vertex) {
                                shapeElement.getPath().removeAt(event.vertex);

                                return;
                            }

                            this.deleteShape();

                            return;
                        }
                    }

                    if (this.type !== 'lpa') {
                        this.$emit('click', event, this.shapeItem, this.type);
                    } else {
                        this.$emit('click', event);
                    }
                });

                switch(shape) {
                    case 'circle': {
                        this.mapListeners.radius_changed = google.maps.event.addListener(shapeElement, 'radius_changed', this.updateCircle);

                        this.mapListeners.center_changed = google.maps.event.addListener(shapeElement, 'center_changed', this.updateCircle);

                        break;
                    }

                    case 'rectangle':
                        this.mapListeners.bounds_changed = google.maps.event.addListener(shapeElement, 'bounds_changed', this.updateRectangle);
                        break;

                    case 'polygon':
                        this.attachPolygonListeners();
                        break;
                    default:
                        break;
                }
            }, 200);
        },

        detachListners() {
            Object.keys(this.mapListeners).forEach((key) => {
                this.mapListeners[key].remove();
            });
            this.mapListeners = {};
        },

        attachPolygonListeners() {
            const shape = this.$refs[`${this.type}${this.shapeIndex}`].$_mapElement;

            this.mapListeners.set_at = google.maps.event.addListener(shape.getPath(), 'set_at', this.updatePolygon);

            this.mapListeners.insert_at = google.maps.event.addListener(shape.getPath(), 'insert_at', this.updatePolygon);

            this.mapListeners.remove_at = google.maps.event.addListener(shape.getPath(), 'remove_at', (idx) => {
                this.shapeData.paths.splice(idx, 1);
            });
        },

        updatePolygon() {
            if (this.isDragging) {
                return;
            }

            const bounds = new google.maps.LatLngBounds();
            const paths = [];

            this.$refs[`${this.type}${this.shapeIndex}`].$_mapElement.getPath().forEach((path) => {
                bounds.extend(new google.maps.LatLng(path.lat(), path.lng()));
                paths.push(path);
            });
            const center = bounds.getCenter();

            const meters = google.maps.geometry.spherical.computeArea(paths);

            const hectares = meters / 10000;

            this.shapeData = {
                ...this.shapeData,
                center: {
                    lat: center.lat(),
                    lng: center.lng(),
                },
                paths: paths,
                area: {
                    meters: meters,
                    hectares: hectares,
                },
            };
        },

        updateCircle() {
            if (this.isDragging) {
                return;
            }

            const radius = this.$refs[`${this.type}${this.shapeIndex}`].getRadius();

            const meters = Math.pow(radius, 2) * Math.PI;
            const hectares = meters / 10000;

            this.shapeData = {
                ...this.shapeData,
                radius,
                center: this.$refs[`${this.type}${this.shapeIndex}`].getCenter(),
                area: {
                    meters: meters,
                    hectares: hectares,
                },
            };
        },

        updateRectangle() {
            if (this.isDragging) {
                return;
            }

            const bounds = this.$refs[`${this.type}${this.shapeIndex}`].$_mapElement.getBounds();

            const northEast = bounds.getNorthEast();
            const southWest = bounds.getSouthWest();

            const paths = [
                new google.maps.LatLng(northEast.lat(), northEast.lng()),
                new google.maps.LatLng(southWest.lat(), northEast.lng()),
                new google.maps.LatLng(southWest.lat(), southWest.lng()),
                new google.maps.LatLng(northEast.lat(), southWest.lng()),
                new google.maps.LatLng(northEast.lat(), northEast.lng()),
            ];
            const meters = google.maps.geometry.spherical.computeArea(paths);
            const hectares = meters / 10000;

            this.shapeData = {
                ...this.shapeData,
                bounds: {
                    north: northEast.lat(),
                    east: northEast.lng(),
                    south: southWest.lat(),
                    west: southWest.lng(),
                },
                center: bounds.getCenter(),
                area: {
                    meters: meters,
                    hectares: hectares,
                },
            };
        },

        deleteShape() {
            if (this.multiple) {
                this.$emit('deleteShape', this.shapeIndex);

                return;
            }

            this.shapeData = {};
        },

        getShapeElement() {
            return this.$refs[`${this.type}${this.shapeIndex}`].$_mapElement;
        },

        getCenter() {
            switch(this.shapeData.shape) {
                case 'circle':
                    return this.$refs[`${this.type}${this.shapeIndex}`].getCenter();
                case 'rectangle':
                    return this.$refs[`${this.type}${this.shapeIndex}`].$_mapElement.getBounds().getCenter();

                case 'polygon': {

                    const bounds = new google.maps.LatLngBounds();


                    this.$refs[`${this.type}${this.shapeIndex}`].$_mapElement.getPath().forEach((path) => {
                        bounds.extend(new google.maps.LatLng(path.lat(), path.lng()));
                    });

                    return bounds.getCenter();
                }

                default:
                    break;
            }
        },

        shapeFill() {
            if ((this.shapeData.shape === 'polygon' || this.shapeData.shape === 'circle') && this.theme) {
                let theme = this.theme;

                if (typeof theme === 'string') {
                    theme = getTheme(theme);
                    theme.fillOpacity = '0.4';
                }

                if (this.polyFill) {
                    this.polyFill.remove();
                }

                this.polyFill = new polyFill(this.getRandomID, this.$refs[`${this.type}${this.shapeIndex}`].$_mapElement, this.$refs[`${this.type}${this.shapeIndex}`].$_mapElement.map, this.parseTheme);
            }
        },

        refreshFill() {
            if (this.polyFill) {
                this.polyFill.remove();
            }

            this.shapeFill();
        },
    },
};
</script>
