<template>
    <label
        :id="`${context.id}_label`"
        :class="context.classes.label"
        :for="context.id"
    >
        <span
            class="formulate-input-label-lebel"
            v-text="context.label"
        />
        <span
            class="formulate-input-label-description"
            v-text="description"
        />
    </label>
</template>

<script>
export default {
    props: {
        context: {
            type: Object,
            required: true,
        },
        description: {
            type: String,
            default: '',
        },
    },
};
</script>
