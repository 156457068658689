<template>
    <RangeBetween
        ref="range"
        v-model="value"
        :min="min"
        :max="max"
        :bar-color="barColor"
        :active-bar-color="activeBarColor"
        :max-between="maxBetween"
        :edit-values="editValues"
        :allow-over-max="allowOverMax"
        :disabled="disabled"
    />
</template>

<script>
import { RangeBetween } from 'vue2-range-between';

export default {
    components: {
        RangeBetween,
    },
    props: {
        context: {
            type: Object,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        min: {
            type: Number,
            required: true,
        },
        max: {
            type: Number,
            required: true,
        },
        barColor: {
            type: String,
            default: '#BEBEBE',
        },
        activeBarColor: {
            type: String,
            default: '#5EBD8A',
        },
        maxBetween: {
            type: Number,
            default: 15,
        },
        editValues: {
            type: Boolean,
            default: false,
        },
        allowOverMax: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            value: this.context.model,
        };
    },
    watch: {
        value(newValue, oldValue) {
            if (Array.isArray(this.value) && this.value.length && this.maxBetween && this.value[0] - this.value[1] + this.maxBetween < 0) {
                if (newValue[0] !== oldValue[0]) {
                    this.value = [
                        this.value[0],
                        this.value[0] + this.maxBetween,
                    ];
                } else if (newValue[1] !== oldValue[1]) {
                    this.value = [
                        this.value[1] - this.maxBetween,
                        this.value[1],
                    ];
                }
            }

            // eslint-disable-next-line vue/no-mutating-props
            this.context.model = this.value;
        },
    },
    methods: {
    },
};
</script>
