<!-- Development TAB -->
<template>
    <div>
        <empty
            v-if="!$page.props.hasItems"
            type="projects"
        >
            <inertia-link
                v-if="$page.props.loggedUser.can.includes('project_save')"
                class="btn-reset btn btn--primary"
                :href="$route('project.create')"
            >
                <SvgController type="plus" />
                <span>Create My First Development</span>
            </inertia-link>
        </empty>
        <div v-else>
            <TableGridLayout
                :columns="columns"
                :rows="dataFormat"
                mode="remote"
                :total-records="projects.total"
                :grid-layout="gridLayout"
                :has-header="hasHeader"
                :has-footer="hasFooter"
                :output="output"
                @onRowClick="onRowClick"
            >
                <template #header-left>
                    <list-filter
                        :fields="filterOptions"
                        :route="filterRoute"
                        search-placeholder="Search projects"
                        :section="['projects', 'items']"
                    />
                </template>
                <template
                    v-for="(_, name) in $scopedSlots"
                    :slot="name"
                    slot-scope="slotData"
                >
                    <slot
                        v-if="name.indexOf('cell(') === 0"
                        :name="name"
                        v-bind="slotData"
                    />
                </template>
                <template #footer>
                    <pagination
                        :links="projects.links"
                        :section="['projects', 'items']"
                    />
                </template>
            </TableGridLayout>
            <modal
                ref="locationModal"
                class="modal--location"
            >
                <template #header>
                    <h3>Development Location</h3>
                </template>
                <template #body>
                    <DevelopmentLocation
                        v-bind="locationData"
                    />
                </template>
            </modal>
        </div>
    </div>
</template>

<script>
import Pagination from 'Utilities/pagination/Pagination';
import ListFilter from 'Utilities/list-filter/ListFilter';
import Modal from 'Utilities/modal/Modal';
import Empty from 'Utilities/empty/Empty';
import DevelopmentLocation from './DevelopmentLocation';

export default {
    name: 'ProjectTable',
    components: {
        Pagination,
        ListFilter,
        Modal,
        Empty,
        DevelopmentLocation,
    },
    props: {
        projects: {
            type: Object,
            default: () => ({}),
        },

        hasDropdown: {
            type: Boolean,
            default: true,
        },

        filterRoute: {
            type: String,
            default: null,
        },

        hasCheckbox: {
            type: Boolean,
            default: false,
        },

        allowSorting: {
            type: Boolean,
            default: true,
        },

        hasHeader: {
            type: Boolean,
            default: true,
        },

        hasFooter: {
            type: Boolean,
            default: true,
        },

        output: {
            type: Array,
            default() {
                return ['table', 'grid'];
            },
        },

        forceHideCompany: {
            type: Boolean,
            default: false,
        },

        tableColumns: {
            type: Array,
            default: null,
        },
    },
    data() {
        return {
            filterOptions: [{
                name: 'status',
                placeholder: 'All statuses',
                options: this.$page.props.statuses,
                type: 'select-plugin',
            },{
                name: 'date',
                placeholder: 'All dates',
                type: 'daterange-plugin',
            },{
                name: 'development_type',
                placeholder: 'Development Type',
                options: this.$page.props.development_types,
                type: 'select-plugin',
            },{
                name: 'planning_auth',
                placeholder: 'Planning Authority',
                options: this.$page.props.planning_auths,
                type: 'select-plugin',
            },{
                name: 'study',
                placeholder: 'Baseline Study',
                options: this.$page.props.studyStatus,
                type: 'select-plugin',
            }],
            dropdown: [],
            gridLayout: {},
            locationData: {},
        };
    },
    computed: {
        hasRowClick() {
            return this.$listeners && (typeof this.$listeners.onRowClick === 'function');
        },

        companies() {
            return this.$page.props.companies.map((company) => {
                return {
                    value: company.id,
                    label: company.name,
                };
            });
        },

        dataFormat() {
            return this.projects.data.map(project => ({...project, ...{
                offset_type: project.offset_type.map(offset => this.capitalizeFirstLetter(offset.name.replace(/_/g, ' ').replace('bng', 'BNG'))).join(', '),
                area: (`${project.local_planning_authority ? project.local_planning_authority.name : ''}, ${project.river_basin_district ? project.river_basin_district.name : ''}`).replace(/,\s$|^,\s/, ''),
            }}));
        },

        columns() {
            if (this.tableColumns) {
                return this.tableColumns;
            }

            const columns = [];

            if (this.hasCheckbox) {
                columns.push({
                    label: '',
                    field: 'checkbox',
                    type: 'checkbox',
                    selected: 'selected',
                    sortable: this.allowSorting,
                });
            }

            columns.push({
                label: 'Title',
                field: 'name',
                sortable: this.allowSorting,
            }, {
                label: 'Offset Type',
                field: 'offset_type',
                sortable: this.allowSorting,
            }, {
                label: 'Area',
                field: 'area',
                sortable: this.allowSorting,
            });

            if (this.hasGroup(['sequest', 'agent', 'lpa']) && !this.forceHideCompany) {
                columns.push({
                    label: 'company',
                    field: 'company.name',
                    sortable: this.allowSorting,
                });
            }

            columns.push({
                label: 'Date Added',
                field: 'created_at',
                sortable: this.allowSorting,
            }, {
                label: 'Status',
                field: 'status',
                sortable: this.allowSorting,
                spanClass: 'status-pill type-{status}',
            });

            if (this.hasGroup(['sequest'])) {
                columns.push({
                    label: 'Baseline Study',
                    field: 'study',
                    sortable: this.allowSorting,
                });
            }

            if (this.hasDropdown) {
                columns.push({
                    label: '',
                    field: 'permissions',
                    type: 'dropdown',
                    sortable: false,
                    position: 'right',
                    options: this.dropdown,
                });
            }

            return columns;
        },
    },
    created() {
        if (this.hasDropdown) {
            this.dropdown = [{
                name: 'Edit Development',
                border: false,
                show: 'editable',
                onClick:(evt, row, index) => {
                    this.$inertia.visit(this.$route('project.edit', this.projects.data[index].id));
                },
            },{
                name: 'Show Development Location',
                border: false,
                onClick:(evt, row, index) => {
                    this.showLocation(this.projects.data[index]);
                },
            },{
                name: 'Contact',
                show: 'contactable',
                border: false,
                onClick: (event, row, index) => {
                    window.location.href = 'mailto:' + this.projects.data[index].contact_email;
                },
            }, {
                name: 'Mark as Contacted',
                show: 'contacted',
                onClick: (event, row, index) => {
                    this.projectContacted(this.projects.data[index]);
                },
            },{
                name: 'Delete Development',
                show: 'deletable',
                color: 'red',
                border: true,
                onClick: (evt, row, index) => {
                    this.deleteProject(this.projects.data[index]);
                },
            }];
        }

        this.gridLayout = {
            title: 'name',
            description: 'address',
            status: 'status',
            showMap: true,
            type: 'project',
            onClick: (evt, row, index) => {
                this.$inertia.visit(this.$route('project.show', this.projects.data[index].id));
            },
        };

        if (this.hasGroup(['sequest','agent'])) {
            this.gridLayout.company = 'company';
        }

        if (this.$page.props.nationalCharacterAreas) {
            this.filterOptions.push({
                name: 'national-character-areas',
                placeholder: 'All Areas',
                options: this.$page.props.nationalCharacterAreas.map(area => ({label: area.name, value: area.id})),
                type: 'select-plugin',
            });
        }
    },
    methods: {
        deleteProject(project) {
            this.$confirm(
                {
                    title: 'Delete Development',
                    message: 'Are you sure you want to delete this development? This action can\'t be undone.',
                    confirmBtnClass: 'btn--red',
                    confirmBtnText: 'Delete',
                    callback: confirm => {
                        if (confirm) {
                            this.$inertia.delete(this.$route('project.destroy', project.id));
                            this.$confirm.close();
                        }
                    },
                },
            );
        },
        returnLatLng(latLng) {
            if (latLng && typeof latLng === 'string') {
                return JSON.parse(latLng);
            }

            return {};
        },
        showLocation(project) {
            this.locationData.address = project.address;
            this.locationData.location = project.location_data;
            this.locationData.project = project;
            this.locationData.planningAuthorities = this.$page.props.planningAuthorities ?? [];
            this.locationData.riverBasinDistricts = this.$page.props.riverBasinDistricts ?? [];
            this.$refs.locationModal.openModal();
        },
        projectContacted(project) {
            this.$confirm(
                {
                    title: 'Confirm Contact',
                    message: 'Mark this development as contacted regarding a study request.',
                    confirmBtnClass: 'btn--primary',
                    confirmBtnText: 'Contacted',
                    callback: confirm => {
                        if (confirm) {
                            this.$inertia.put(this.$route('project.contacted', project.id));
                            this.$confirm.close();
                        }
                    },
                },
            );
        },
        onRowClick(params) {
            if(this.hasRowClick) {
                this.$emit('onRowClick', params);
            } else{
                this.$inertia.visit(this.$route('project.show', params.row.id));
            }

        },
    },
};
</script>
