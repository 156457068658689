var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    _vm._g({ class: `dropdown dropdown--${_vm.position}` }, _vm.listeners),
    [
      _vm._t("default"),
      _vm._v(" "),
      _c("transition", { attrs: { name: "fade" } }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.value,
                expression: "value",
              },
            ],
            ref: "dropdown",
            staticClass: "dropdown-menu",
            class: _vm.dropdownMenuClass,
            style: [_vm.positionStyles],
            on: {
              click: function ($event) {
                $event.stopPropagation()
              },
            },
          },
          [_vm._t("dropdown")],
          2
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }