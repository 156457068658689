<template>
    <modal
        ref="historyModal"
        width="50em"
    >
        <template #header>
            <h3>
                HISTORY
            </h3>
        </template>
        <template #body>
            <TableGridLayout
                :columns="columns"
                :rows="attachments"
                :output="['table']"
                mode="remote"
                :total-records="attachments.length"
            />
        </template>
    </modal>
</template>

<script>
import Modal from 'Utilities/modal/Modal';
export default {
    name: 'ContractHistory',
    components: {
        Modal,
    },
    props: {
        show: {
            type: Boolean,
            default: false,
        },
        attachments: {
            type: Array,
            default: () => ([]),
        },
    },
    data() {
        return {
            form: null,
            displayNameFocus: false,
            columns: [{
                label: 'Date',
                field: 'created_at',
                sortable: false,
            },{
                label: 'Status',
                field: 'status',
                spanClass: 'status-pill type-{status}',
                sortable: false,
            },{
                label: '',
                field: 'display_name',
                sortable: false,
                onClick: (evt, row, index) => {
                    this.downloadAttachment(this.contract[index].attachment);
                },
            }],
        };
    },
    watch: {
        show(value) {
            if (value) {
                this.$refs.historyModal.openModal();
            } else {
                this.$refs.historyModal.closeModal();
            }
        },
    },
    methods: {
        openModal() {
            this.$refs.historyModal.openModal();
        },
        closeModal() {
            this.$refs.historyModal.closeModal();
        },
    },
};
</script>
