<template>
    <div>
        <FormulateInput
            key="name_1"
            type="text"
            name="name"
            label="Name"
            validation="required"
            validation-name="Name"
            :disabled="disabled"
        />
        <div class="form-flex--tab form-flex--vcenter">
            <FormulateInput
                key="offset_type_1"
                type="select-plugin"
                name="offset_type"
                label="Offset Type"
                validation="required"
                validation-name="Offset Type"
                :options="offsetOptions"
                placeholder="Select Offset Type"
                :disabled="disabled"
                :multiple="true"
            />
            <template v-if="isSuperUser">
                <FormulateInput
                    key="status_1"
                    type="select-plugin"
                    name="status"
                    label="Status"
                    validation="required"
                    validation-name="Status"
                    :options="statusOptions"
                    placeholder="Select Status"
                    :disabled="disabled"
                />
            </template>
        </div>
        <div
            class="form-flex--tab form-flex--vcenter"
        >
            <template v-if="value.offset_type.includes('bng')">
                <FormulateInput
                    key="planning_authority_id_1"
                    :type="planningAuthorities.length > 1 ? 'select-plugin' : 'fake'"
                    name="planning_authority_id"
                    label="Planning Authority"
                    validation="required"
                    validation-name="Planning Authority"
                    :options="planningAuthoritiesSelect"
                    placeholder="Select Planning Authority"
                    :disabled="disabled"
                    :parse-value="() => planningAuthorities[0].name"
                />
            </template>
            <template v-if="value.offset_type.includes('nutrient_offset')">
                <FormulateInput
                    key="river_basin_district_id_1"
                    :type="riverBasinDistricts.length > 1 ? 'select-plugin' : 'fake'"
                    name="river_basin_district_id"
                    label="River Catchment Area"
                    validation="required"
                    validation-name="River Catchment Area"
                    :options="riverBasinDistrictSelect"
                    placeholder="Select River Catchment Area"
                    :disabled="disabled"
                    :parse-value="() => riverBasinDistricts[0].name"
                />
            </template>
            <template v-if="companies && companies.length > 1">
                <FormulateInput
                    v-if="hasGroup(['sequest','agent'])"
                    key="company_id_1"
                    type="select-plugin"
                    name="company_id"
                    label="Company"
                    validation="required"
                    placeholder="Select Company"
                    :options="companiesSelect"
                    :disabled="disabled"
                />
            </template>
        </div>
        <template v-if="isLegacy && !showMap">
            <p>The map functionality has been updated to use area polygons instead of a single point. Would you like to:</p>
            <p>
                <button
                    class="btn btn--primary"
                    @click.prevent="toggleLegacy(false)"
                >
                    Use new plotting
                </button>
                <button
                    class="btn btn--primary"
                    @click.prevent="toggleLegacy(true)"
                >
                    Keep using point
                </button>
                <FormulateInput
                    type="hidden"
                    name="mapCheck"
                    validation="required"
                    :validation-messages="{
                        required: 'Please select the map type you wish to use.'
                    }"
                />
            </p>
        </template>
        <FormulateInput
            v-if="showMap"
            ref="map"
            key="map"
            v-model="tmpValue.map_location"
            :lat-long="tmpValue.map_location.points"
            name="map_location"
            type="map"
            default-click-action="pan"
            validation="required|location"
            :validation-rules="{
                location: validateMap
            }"
            :validation-messages="{
                location: isLegacy ? mapMessageTextErrorLegacy : mapMessageTextError
            }"
            :zoom="zoom"
            show-geo-input
            :lpa="currentLpa"
            :map-type="isLegacy ? 'project-legacy': 'project'"
            country="England"
            :fit-bounds="editable ? 'edit' : 'vectors'"
            fit-bounds-change="vectors"
            :message="mapMessage"
            :edit-fill-color="mapColors.project"
            :vectors="mapVectors(
                !isNaN(parseInt(value.planning_authority_id)) ? parseInt(value.planning_authority_id) : null,
                !isNaN(parseInt(value.river_basin_district_id)) ? parseInt(value.river_basin_district_id) : null
            )"
            :disabled="!showMap"
        />
        <FormulateInput
            v-if="invalidCountryCode"
            key="country_code"
            type="select-plugin"
            name="country_code"
            validation="required"
            label="We are unable to find a country from the map. Please choose a country"
            validation-name="Country"
            reduce-key="code"
            label-key="name"
            :options="countries"
            placeholder="Country"
        />
    </div>
</template>

<script>
import projectPages from 'Mixins/projectPages';
import { mapColors } from 'Lib/defaults';

export default {
    name: 'ProjectFormPage1',
    mixins: [projectPages],
    props: {
        companies: {
            type: Array,
            default: () => [],
        },
        countries: {
            type: Array,
            default: () => [],
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        editable: {
            type: Boolean,
            default: false,
        },
        planningAuthorities: {
            type: Array,
            default: () => [],
        },
        riverBasinDistricts: {
            type: Array,
            default: () => [],
        },
        value: {
            type: Object,
            default: () => {},
        },
        developmentTypeOptions: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            isLegacy: this.value.map_location && this.value.map_location.lat ? true : false,
            showMap: this.value.map_location && this.value.map_location.lat ? false : true,
            invalidCountryCode: false,
            tmpValue: this.value,
            currentLpa: null,
            mapMessageText: 'Please use the map below to plot your development.',
            mapMessageTextError: 'Please use the map above to plot your development.',
            mapMessageTextLegacy: 'Please use the map below to mark your development.',
            mapMessageTextErrorLegacy: 'Please use the map above to mark your development.',
            zoom: 6,
            isSuperUser: this.$page.props.loggedUser.isSuperUser,
            mapColors: mapColors,
        };
    },
    computed: {
        offsetOptions() {
            const options = {
                'bng': 'BNG',
            };

            if (this.riverBasinDistricts.length) {
                options['nutrient_offset'] = 'Nutrient Offset';
            }

            return options;
        },

        statusOptions() {
            return [{
                value: 'created',
                label: 'Created',
            },{
                value: 'applied',
                label: 'Applied',
            },{
                value: 'land matched',
                label: 'Land Matched',
            },{
                value: 'contract issued',
                label: 'Contract Issued',
            },{
                value: 'land implemented',
                label: 'Land Implemented',
            }];
        },

        companiesSelect() {
            return this.companies.map(item => {
                return {
                    label: item.name,
                    value: item.id,
                };
            });
        },

        planningAuthoritiesSelect() {
            const listReturn = [];

            this.planningAuthorities.forEach((item) => {
                if (!this.hasGroup('lpa') || (this.$page.props.loggedUser.LPAs.includes(item.id))) {
                    listReturn.push({
                        value: item.id,
                        label: item.name,
                    });
                }
            });

            return listReturn;
        },

        riverBasinDistrictSelect() {
            const listReturn = [];
            let riverBasinDistricts = this.riverBasinDistricts;

            if (this.value.offset_type.includes('bng') && this.value.planning_authority_id) {
                const riverBasinDistrictsTmp = riverBasinDistricts.filter(river => {
                    const lpaIDs = river.planning_authority.map(lpa => lpa.id);

                    return lpaIDs.includes(parseInt(this.value.planning_authority_id));
                });

                if (riverBasinDistrictsTmp.length) {
                    riverBasinDistricts = riverBasinDistrictsTmp;
                }
            }

            riverBasinDistricts.forEach((item) => {
                listReturn.push({
                    value: parseInt(item.id),
                    label: item.name,
                });
            });

            return listReturn;
        },

        mapMessage() {
            if (this.tmpValue.map_location) {
                if (this.isLegacy) {
                    return this.mapMessageTextLegacy;
                } else {
                    return this.mapMessageText;
                }
            }

            return null;
        },
    },
    methods: {
        mapChange(value) {
            let countryInvalid = true;

            if (this.value && value) {
                const {
                    inputCountry,
                } = value;


                if (inputCountry) {
                    const country = this.countries.filter(country => country.code === inputCountry);

                    if (country.length) {
                        countryInvalid = false;
                    }
                }
            }

            this.invalidCountryCode = countryInvalid;
        },

        validateMap(value) {
            if (this.isLegacy) {
                if (value.value.points && value.value.lat && value.value.lng) {
                    return true;
                }

                return false;
            }

            if (value.value && value.value.layer && ((value.value.layer.paths && value.value.layer.paths.length && value.value.inputGeo) || (value.value.layer.shape && value.value.layer.radius))) {
                return true;
            }

            return false;
        },

        toggleLegacy(isLegacy) {
            if (isLegacy) {
                this.showMap = true;
            } else {
                this.showMap = true;
                this.isLegacy = false;
            }
        },
    },
};
</script>
