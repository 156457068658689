<template>
    <div class="auth-background">
        <div
            class="auth-container"
            :data-component="component"
            :data-logo="logo"
        >
            <div class="auth-top">
                <div
                    v-if="logo"
                    class="auth-logo"
                >
                    <SvgController type="logo-full" />
                </div>
                <div class="auth-button">
                    <slot name="button" />
                </div>
            </div>
            <div class="auth-bottom">
                <div class="auth-form-container">
                    <slot />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AuthLayout',

    components: {},

    props: {
        logo: {
            type: Boolean,
            default: true,
        },
        component: {
            type: String,
            default: '',
        },
    },

    data() {
        return {

        };
    },
    mounted() {},
    methods:{},
};
</script>
