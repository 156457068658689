var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.value.study && !_vm.documentUploaded
        ? _c("ExcelConvert", {
            key: "excel_convert",
            attrs: { label: "Upload Biodiversity Metric document" },
            on: { input: _vm.convertDocument },
          })
        : [
            _c("h2", [_vm._v("\n            Baseline\n        ")]),
            _vm._v(" "),
            _c("BNGCalculator", {
              ref: "calcutatorBaseline",
              attrs: { disabled: true, pill: "", "default-value": [] },
              on: {
                input: _vm.updateHabitatData,
                units: _vm.updateHabitatData,
              },
              model: {
                value: _vm.tmpValue.onsite_baseline,
                callback: function ($$v) {
                  _vm.$set(_vm.tmpValue, "onsite_baseline", $$v)
                },
                expression: "tmpValue.onsite_baseline",
              },
            }),
            _vm._v(" "),
            _c("h2", [_vm._v("\n            Creation\n        ")]),
            _vm._v(" "),
            _c("BNGCalculator", {
              ref: "calcutatorCreation",
              attrs: {
                disabled: true,
                pill: "",
                type: "creation",
                "default-value": [],
              },
              on: {
                input: _vm.updateHabitatData,
                units: _vm.updateHabitatData,
              },
              model: {
                value: _vm.tmpValue.onsite_creation,
                callback: function ($$v) {
                  _vm.$set(_vm.tmpValue, "onsite_creation", $$v)
                },
                expression: "tmpValue.onsite_creation",
              },
            }),
            _vm._v(" "),
            _c("h2", [_vm._v("\n            Enhancement\n        ")]),
            _vm._v(" "),
            _c("BNGCalculator", {
              ref: "calcutatorEnhancement",
              attrs: {
                disabled: true,
                pill: "",
                type: "enhancement",
                "default-value": [],
                "exclude-missing": true,
              },
              on: {
                input: _vm.updateHabitatData,
                units: _vm.updateHabitatData,
              },
              model: {
                value: _vm.tmpValue.onsite_enhancement,
                callback: function ($$v) {
                  _vm.$set(_vm.tmpValue, "onsite_enhancement", $$v)
                },
                expression: "tmpValue.onsite_enhancement",
              },
            }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "form-flex--tab" },
              [
                _c("FormulateInput", {
                  key: "onsite_baseline_total_4",
                  attrs: {
                    type: "fake",
                    name: "onsite_baseline_total",
                    label: "Onsite baseline total",
                    "validation-name": "Onsite baseline total",
                    "parse-value": () =>
                      _vm.floatOutput(_vm.value.onsite_baseline_total),
                  },
                }),
                _vm._v(" "),
                _c("FormulateInput", {
                  key: "onsite_post_intervention_total_4",
                  attrs: {
                    type: "fake",
                    name: "onsite_post_intervention_total",
                    label: "Onsite Post Intervention total",
                    "validation-name": "Onsite Post Intervention total",
                    "parse-value": () =>
                      _vm.floatOutput(_vm.value.onsite_post_intervention_total),
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "form-flex--tab" },
              [
                _c("FormulateInput", {
                  key: "onsite_desired_percentage_4",
                  attrs: {
                    type: "number",
                    name: "onsite_desired_percentage",
                    label: "Desired net gain percentage",
                    placeholder: "Desired net gain percentage (%)",
                    validation: "required|number|between:9,201",
                    "validation-name": "Desired net gain percentage",
                  },
                }),
                _vm._v(" "),
                _c("FormulateInput", {
                  key: "desired_total_4",
                  attrs: {
                    type: "fake",
                    name: "desired_total",
                    label: "Desired offsite BNG units",
                    "validation-name": "Desired offsite BNG units",
                    "parse-value": () =>
                      _vm.floatOutput(_vm.value.desired_total),
                  },
                }),
              ],
              1
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }