<template>
    <div>
        <p>
            User who made the Audit: <span class="text-green">{{ update.user_name }}</span>
        </p>
        <div v-if="hasGroup(['sequest'])">
            <FormulateForm
                ref="updateForm"
                v-model="form"
                :errors="form.errors"
                class="flex-col"
                @submit="submitUpdate"
            >
                <div class="details">
                    <div class="formulate-heading">
                        <div class="formulate-heading">
                            <h6>Description</h6>
                        </div>
                        <p>{{ update.description }}</p>
                    </div>
                    <FormulateInput
                        type="select"
                        name="status"
                        label="Change Status"
                        :options="statusOptions"
                    />
                    <div v-if="form.status === 'paid'">
                        <FormulateInput
                            type="text"
                            name="amount_paid"
                            label="Amount Paid"
                            validation="required|number"
                            validation-name="Amount paid"
                            class="margin-0"
                        />
                    </div>
                    <div v-else-if="form.status === 'rejected'">
                        <FormulateInput
                            type="text"
                            name="reason"
                            label="Reason"
                            validation="required"
                            class="margin-0"
                        />
                    </div>
                    <FormulateInput
                        type="file"
                        name="documents"
                        accept=".pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                        validation="mime:application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                        upload-url="/attachments/upload"
                        upload-behavior="live"
                        multiple
                    >
                        <template #label>
                            <FileLabel
                                id="documents"
                                label="Documents"
                                text="Upload documents"
                            />
                        </template>
                    </FormulateInput>
                    <Files
                        :files="formDocs"
                        filetype="document"
                        :deletable="true"
                    />
                    <FormulateInput
                        type="file"
                        name="photos"
                        accept="image/jpeg,image/png,image/gif"
                        validation="mime:image/jpeg,image/png,image/gif"
                        upload-url="/attachments/upload"
                        upload-behavior="live"
                        multiple
                    >
                        <template #label>
                            <FileLabel
                                id="photos"
                                label="Photos"
                                text="Upload photos"
                            />
                        </template>
                    </FormulateInput>
                    <Gallery
                        :images="formPhotos"
                        :deletable="true"
                    />
                </div>
                <FormulateInput type="submit" />
            </FormulateForm>
        </div>
        <div v-else>
            <gallery
                :images="formPhotos"
                :deletable="false"
            />
        </div>
    </div>
</template>
<script>
import Gallery from 'Utilities/gallery/Gallery';
import FileLabel from 'Utilities/vue-formulate/FileLabel';
import Files from 'Utilities/files/Files';
export default {
    name: 'ShowUpdate',
    components: {
        Gallery,
        FileLabel,
        Files,
    },
    props: {
        update: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            form: this.$inertia.form({
                description: this.update.description,
                status: this.update.status,
                amount_paid: this.update.amount_paid.replace('£', ''),
                reason: this.update.reason,
                photos: null,
                documents: null,
            }),
            statusOptions: [
                'awaiting review',
                'awaiting payment',
                'paid',
                'rejected',
            ],
            existingPhotos: this.update.media,
            existingDocs: this.update.documents,
        };
    },
    computed: {
        formPhotos() {
            const photos = this.existingPhotos ? [...this.existingPhotos] : [];

            if (this.form.photos) {
                this.form.photos.files.forEach((file, index) => {
                    const data = {
                        id: index,
                        display_name: file.path.name,
                        filepath: file.path.url,
                        type: 'new',
                    };

                    photos.push(data);
                });
            }

            return photos;
        },
        formDocs() {
            const docs = [...this.existingDocs];

            if (this.form.docs) {
                this.form.docs.files.forEach((file, index) => {
                    const data = {
                        id: index,
                        display_name: file.path.name,
                        filepath: file.path.url,
                        type: 'new',
                    };

                    docs.push(data);
                });
            }

            return docs;
        },
    },
    created() {

    },
    methods: {
        submitUpdate(data) {
            this.$inertia.put(this.$route('update.update', this.update.id), data, {
                onSuccess: () => {
                    this.$emit('closeModal');
                },
            });
        },
        openFileModal() {
            this.$refs.fileModal.openModal();
        },
    },
};
</script>
