<template>
    <app-layout section="projects">
        <page-header
            title="Development Details"
            icon="files"
            :back="{url: $route('projects'),text: 'All Developments'}"
        >
            <button
                v-if="deletable"
                class="btn-reset btn btn--red--secondary"
                @click="deleteProject"
            >
                <SvgController type="trashcan" />
                <span>Delete Development</span>
            </button>
            <inertia-link
                class="btn-reset btn btn--primary"
                :class="{'disabled': !editable}"
                :href="editable ? $route('project.edit', project.id) : '#'"
            >
                Edit Development
            </inertia-link>
            <template #page-header-bottom>
                <tabs
                    v-model="selectedTab"
                    :tabs="tabs"
                />
            </template>
        </page-header>
        <div class="center">
            <div
                v-if="selectedTab === 'information'"
                class="details"
            >
                <h5>Development Name</h5>
                <div class="details-header">
                    <h3>{{ project.name }}</h3>
                    <span
                        class="status-pill"
                        :class="statusFormat"
                    >
                        {{ project.status }}
                    </span>
                </div>
                <div class="form-flex--lap">
                    <div>
                        <div class="details-block details-bg">
                            <div class="formulate-heading">
                                <h6>Development Details</h6>
                            </div>
                            <div v-if="project.unique_reference_number">
                                <h5>Unique Reference Number</h5>
                                <p>{{ referenceNumber }}</p>
                            </div>
                            <div v-if="project.offset_type">
                                <h5>Offset Type</h5>
                                <p>{{ offsetTypeString | capitalize }}</p>
                            </div>
                            <div v-if="$page.props.loggedUser.isSuperUser && (nationalCharacterAreas || (!nationalCharacterAreas && project.country_code === 'GB'))">
                                <h5>National Character Areas</h5>
                                <p v-if="nationalCharacterAreas">
                                    {{ nationalCharacterAreas }}
                                </p>
                                <Processing v-else>
                                    Processing
                                </Processing>
                            </div>
                        </div>
                        <div
                            v-if="offsetType.includes('bng')"
                            class="details-block details-bg"
                        >
                            <div class="formulate-heading">
                                <h6>BNG Details</h6>
                            </div>
                            <div v-if="project.local_planning_authority">
                                <h5>Planning Authority</h5>
                                <p>{{ project.local_planning_authority.name }}</p>
                            </div>
                            <div class="form-flex--tab">
                                <div>
                                    <h5>Size</h5>
                                    <p>{{ floatOutput(project.size) }} {{ project.size_units }}</p>
                                </div>
                                <div>
                                    <h5>Development Type</h5>
                                    <p>{{ project.development_type | capitalize }}</p>
                                </div>
                            </div>
                            <div class="form-flex--tab">
                                <div>
                                    <h5>Planning Reference Number</h5>
                                    <p>{{ project.planning_ref }}</p>
                                </div>
                                <div>
                                    <h5>Planning Status</h5>
                                    <p>{{ project.planning_status | capitalize }}</p>
                                </div>
                            </div>
                            <div class="form-flex--tab">
                                <div v-if="project.land_status">
                                    <h5>Land Status</h5>
                                    <p>{{ project.land_status | capitalize }}</p>
                                </div>
                                <div v-if="project.unit_price">
                                    <h5>Price Per Offset Unit</h5>
                                    <p>{{ project.unit_price }}</p>
                                </div>
                            </div>
                            <br>
                            <div class="formulate-heading">
                                <h6>BNG Units</h6>
                            </div>
                            <div class="form-flex--tab">
                                <div>
                                    <h5>Total Baseline Units</h5>
                                    <p>{{ floatOutput(project.onsite_baseline_total) }}</p>
                                </div>
                                <div>
                                    <h5>Total Post Intervention Units</h5>
                                    <p>{{ floatOutput(project.onsite_post_intervention_total) }}</p>
                                </div>
                                <div>
                                    <h5>Desired Net Gain Percentage</h5>
                                    <p>{{ project.onsite_desired_percentage }}%</p>
                                </div>
                            </div>
                            <div class="form-flex--tab">
                                <div v-if="project.desired_total">
                                    <h5>Desired Units</h5>
                                    <p>{{ floatOutput(project.desired_total) }}</p>
                                </div>
                            </div>
                        </div>
                        <div
                            v-if="offsetType.includes('nutrient_offset')"
                            class="details-block details-bg"
                        >
                            <div class="formulate-heading">
                                <h6>Nutrient Offset Details</h6>
                            </div>
                            <div v-if="project.river_basin_district">
                                <h5>River basin district</h5>
                                <p>{{ project.river_basin_district.name }}</p>
                            </div>
                            <div v-if="project.nutrient_development_type">
                                <h5>Type of Development</h5>
                                <p>{{ project.nutrient_development_type }}</p>
                            </div>
                            <div v-if="project.nutrient_development_amount">
                                <h5>{{ typeNumberAmount }}</h5>
                                <p>{{ project.nutrient_development_amount }}</p>
                            </div>
                            <div v-if="project.nutrient_planning_status">
                                <h5>Planning Status</h5>
                                <p>{{ project.nutrient_planning_status }}</p>
                            </div>
                            <div v-if="project.nutrient_type && project.nutrient_type.length">
                                <div
                                    v-for="type in project.nutrient_type"
                                    :key="type.id"
                                >
                                    <h5>{{ type.name }}</h5>
                                    <div class="form-flex--tab">
                                        <p v-if="type.pre">
                                            Pre: {{ type.pre }}
                                        </p>
                                        <p v-if="type.post">
                                            Post: {{ type.post }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div v-if="project.nutrient_study === 0 || project.nutrient_study === 1">
                                <h5>Have you calculated nutrients associated with development?</h5>
                                <p v-if="project.nutrient_study === 0">
                                    No
                                </p>
                                <div v-else>
                                    <Files
                                        :files="nutrientCalculator"
                                        filetype="document"
                                        :deletable="false"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="details-block flex flex-wrap align-content-start">
                        <div class="w-full order-2 lap-order-0 mb-3">
                            <div class="formulate-heading">
                                <h6>Baseline Study</h6>
                            </div>
                            <p v-if="project.study">
                                Baseline study has been completed.
                            </p>
                            <p
                                v-else-if="!project.study && project.study_requested && !project.contacted"
                                class="alert-box alert-box-info"
                            >
                                You have requested a baseline survey, we will be in touch to organise a date.
                            </p>
                            <p v-else-if="!project.study && project.study_requested && project.contacted">
                                We have contacted you in regards to a baseline study.
                            </p>
                            <p v-else>
                                Baseline study has not been completed nor requested.
                            </p>
                            <a
                                v-if="hasGroup(['sequest']) && !project.study && project.study_requested"
                                class="btn-reset btn btn--primary"
                                :href="'mailto:' + project.contact_email"
                            >
                                Email
                            </a>
                            <button
                                v-if="hasGroup(['sequest']) && !project.study && project.study_requested && !project.contacted"
                                class="btn-reset btn btn--primary"
                                @click="projectContacted()"
                            >
                                Mark as Contacted
                            </button>
                        </div>
                        <div class="w-full order-1 lap-order-1 mb-3">
                            <div class="formulate-heading">
                                <h6>Location</h6>
                            </div>
                            <p class="details-location">
                                <SvgController type="map-icon" />{{ project.address }}
                            </p>
                            <draw-map
                                ref="map"
                                :edit="false"
                                :show-geo-input="false"
                                :zoom="10"
                                type="project"
                                show-keys
                                fit-bounds="layers"
                                :fit-bounds-offset="1"
                                :layers="mapProjectLayers()"
                                :vectors="mapVectors(project.planning_authority_id, project.river_basin_district_id)"
                            />
                        </div>
                        <div class="w-full order-0 lap-order-2">
                            <file-label
                                id="attachments"
                                label="Documents"
                                text="Upload a document"
                                :disabled="!editable"
                                @click.native="openFileModal"
                            />
                            <Files
                                :files="attachments"
                                filetype="document"
                                :deletable="true"
                                item-type="development"
                            />
                            <modal ref="fileModal">
                                <template #header>
                                    <h3>Add Document</h3>
                                </template>
                                <template #body>
                                    <add-files
                                        :parent="{
                                            id: $page.props.project.id,
                                            type: 'project',
                                        }"
                                    />
                                </template>
                            </modal>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="selectedTab === 'contracts'">
            <contracts
                :contracts="contracts"
                :parent="{
                    id: $page.props.project.id,
                    name: $page.props.project.name,
                    type: 'project',
                }"
            />
        </div>
        <div v-else-if="selectedTab === 'report'">
            <project-report
                :project="project"
            />
        </div>
        <div v-else-if="selectedTab === 'agreements'">
            <rentals />
        </div>
    </app-layout>
</template>
<script>
import ProjectPages from 'Mixins/projectPages';
import DrawMap from 'Utilities/maps/DrawMap';
import FileLabel from 'Utilities/vue-formulate/FileLabel';
import AddFiles from 'Utilities/uploader/AddFiles.vue';
import Modal from 'Utilities/modal/Modal';
import Contracts from './Contracts';
import Files from 'Utilities/files/Files';
import ProjectReport from './Report';
import Rentals from './Rentals';
import Tabs from 'Components/tabs/Tabs';
import Processing from 'Utilities/processing/processing';

export default {
    name: 'ProjectShow',
    components: {
        DrawMap,
        FileLabel,
        AddFiles,
        Modal,
        Contracts,
        Files,
        ProjectReport,
        Rentals,
        Processing,
        Tabs,
    },
    mixins: [
        ProjectPages,
    ],
    props: {
        project: {
            type: Object,
            required: true,
        },
        settings: {
            type: Object,
            default: () => ({}),
        },
        contracts: {
            type: Array,
            default: () => ([]),
        },
    },
    data() {
        return {
            selectedTab: null,
            tabs: [{
                id: 'information',
                title: 'Development Information',
                link: route('project.show', this.project.id),
                show: true,
            },{
                id: 'agreements',
                title: 'BNG Agreements',
                link: route('project.show.agreements', {
                    project: this.project.id,
                }),
                only: ['rentals'],
                show: true,
            },{
                id: 'contracts',
                title: 'Contracts',
                link: route('project.show.contracts', {
                    project: this.project.id,
                }),
                only: ['contracts'],
                show: true,
            },{
                id: 'report',
                title: 'Report',
                link: route('project.show.reports', {
                    project: this.project.id,
                }),
                only: [],
                show: this.hasPermission('reports_view'),
            }],
            planningAuthorities: this.project.local_planning_authority ? [this.project.local_planning_authority] : null,
            riverBasinDistricts: this.project.river_basin_district ? [this.project.river_basin_district] : null,
        };
    },
    computed: {
        statusFormat() {
            return 'type-' + this.project.status.replace(/\s/g, '_').toLowerCase();
        },
        locationFormat() {
            return this.project.location_data;
        },
        attachments() {
            if (!this.project.attachments) {
                return [];
            }

            const attachments = this.project.attachments.filter((item) => {
                return item.is_contract < 1 && item.type !== 'nutrient_calculator';
            });

            return attachments;
        },
        nutrientCalculator() {
            if (!this.project.attachments) {
                return null;
            }

            const attachments = this.project.attachments.filter((item) => {
                return item.type === 'nutrient_calculator';
            });

            return attachments;
        },
        deletable() {
            return this.$page.props.loggedUser.can.includes('project_delete') && (this.project.status === 'created' || this.project.status === 'confirmed');
        },
        editable() {
            if (this.hasGroup(['sequest'])) {
                return true;
            }

            return this.$page.props.loggedUser.can.includes('project_save') && (this.project.status === 'created' || this.project.status === 'confirmed');
        },
        offsiteMin() {
            return (this.project.desired_total * this.settings['dev-min-price'].value);
        },
        offsiteMax() {
            return (this.project.desired_total * this.settings['dev-max-price'].value);
        },
        offsetTypeString() {
            return this.project.offset_type.map(type => type.name).join(', ').replace(/_/g, ' ');
        },
        offsetType() {
            return this.project.offset_type.map(offset => offset.name);
        },
        typeNumberAmount() {
            return this.project.nutrient_development_type === 'Residential' ? 'Number of residential units' : 'Number of rooms';
        },
        referenceNumber() {
            if (this.project.unique_reference_number) {
                return this.project.unique_reference_number;
            }

            return 'n/a';
        },
        nationalCharacterAreas() {
            if (!this.project.national_character_areas) return '';

            return this.project.national_character_areas.map(national_character_areas => {
                return national_character_areas.name;
            }).join(', ');
        },
    },

    methods: {
        deleteProject() {
            this.$confirm(
                {
                    title: 'Delete Development',
                    message: 'Are you sure you want to delete this project? This action can\'t be undone.',
                    confirmBtnClass: 'btn--red',
                    confirmBtnText: 'Delete',
                    callback: confirm => {
                        if (confirm) {
                            this.$inertia.delete(this.$route('project.destroy', this.project.id));
                            this.$confirm.close();
                        }
                    },
                },
            );
        },
        projectContacted() {
            this.$confirm(
                {
                    title: 'Confirm Contact',
                    message: 'Mark this development as contacted regarding a study request.',
                    confirmBtnClass: 'btn--primary',
                    confirmBtnText: 'Contacted',
                    callback: confirm => {
                        if (confirm) {
                            this.$inertia.put(this.$route('project.contacted', this.project.id));
                            this.$confirm.close();
                        }
                    },
                },
            );
        },
        formatNum(num) {
            return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        },
        confirmProject() {
            this.$inertia.put(this.$route('project.confirm', this.project.id));
        },
        openFileModal() {
            this.$refs.fileModal.openModal();
        },
        deleteAttachment(id) {
            this.$confirm(
                {
                    title: 'Delete Attachment',
                    message: 'Are you sure you want to delete this attachment? This action can\'t be undone.',
                    confirmBtnClass: 'btn--red',
                    confirmBtnText: 'Delete',
                    callback: confirm => {
                        if (confirm) {
                            this.$inertia.delete(this.$route('attachment.destroy', id));
                            this.$confirm.close();
                        }
                    },
                },
            );
        },
    },
};
</script>
