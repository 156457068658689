var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { attrs: { id: "site-header" } }, [
    _c(
      "div",
      {
        staticClass: "center flex-space-between",
        attrs: { id: "site-header-center" },
      },
      [
        _c("div", { attrs: { id: "site-header-left" } }, [
          _c(
            "div",
            { staticClass: "flex-center", attrs: { id: "site-header-logo" } },
            [
              _c(
                "button",
                {
                  staticClass: "btn-reset",
                  attrs: { id: "site-header-sidebar" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("toggleSidebar")
                    },
                  },
                },
                [_c("div")]
              ),
              _vm._v(" "),
              _c(
                "inertia-link",
                { attrs: { href: _vm.$route("home") } },
                [
                  _c("h1", { staticClass: "hidden" }, [
                    _vm._v(
                      "\n                        BNG Partnership\n                    "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("SvgController", { attrs: { type: "logo-full" } }),
                  _vm._v(" "),
                  _c("SvgController", { attrs: { type: "logo" } }),
                ],
                1
              ),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "flex-center", attrs: { id: "site-header-right" } },
          [
            _c(
              "div",
              {
                staticClass: "site-header-item",
                attrs: { id: "site-header-notification" },
              },
              [_c("HeaderNotifications")],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "site-header-item",
                attrs: { id: "site-header-user" },
              },
              [_c("HeaderUser")],
              1
            ),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }