var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ul",
    { staticClass: "lst-reset dropdown-menu-list" },
    _vm._l(_vm.data, function (item, index) {
      return _c("dropdownMenuItem", {
        key: index,
        attrs: {
          show: item.show,
          "target-id": item.id,
          name: item.name,
          small: item.small,
          icon: item.icon,
          href: item.href,
          method: item.method,
          active: item.active,
          border: item.border,
          color: item.color,
          "svg-color": item.svgColor,
          "on-click": item.onClick,
        },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }