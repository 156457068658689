var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "app-layout",
    { attrs: { section: "users" } },
    [
      _c(
        "page-header",
        { attrs: { title: "Users", icon: "people", count: _vm.users.total } },
        [
          _vm.loggedUser.can.includes("user_invite")
            ? _c(
                "button",
                {
                  staticClass: "btn btn--primary",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.addUser()
                    },
                  },
                },
                [
                  _c("SvgController", { attrs: { type: "plus" } }),
                  _vm._v("\n            New User\n        "),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "center" },
        [
          _c("TableGridLayout", {
            attrs: {
              columns: _vm.columns,
              rows: _vm.users.data,
              output: ["table"],
              "grid-layout-for-table": _vm.gridLayout,
            },
            scopedSlots: _vm._u([
              {
                key: "header-left",
                fn: function () {
                  return [
                    _c("list-filter", {
                      attrs: {
                        fields: _vm.filterOptions,
                        route: _vm.$route("users"),
                        "search-placeholder": "Search Users",
                        section: "users",
                      },
                    }),
                  ]
                },
                proxy: true,
              },
              {
                key: "footer",
                fn: function () {
                  return [
                    _c("pagination", {
                      attrs: { links: _vm.users.links, section: "users" },
                    }),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("modal", {
        ref: "newUserModal",
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function () {
              return [
                _c("h3", [
                  _vm.editMode === "add"
                    ? _c("span", [_vm._v("New")])
                    : _vm.editMode === "edit"
                    ? _c("span", [_vm._v("Edit")])
                    : _vm._e(),
                  _vm._v("\n                User\n            "),
                ]),
              ]
            },
            proxy: true,
          },
          {
            key: "body",
            fn: function () {
              return [
                _c("edit", {
                  attrs: { "edit-mode": _vm.editMode, user: _vm.editingUser },
                  on: { closeModal: _vm.closeModal },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }