<template>
    <app-layout section="profile">
        <page-header
            title="Edit Profile"
            icon="user"
            :background="false"
        >
            <inertia-link
                :href="$route('profile.show')"
                class="btn"
            >
                Cancel
            </inertia-link>
            <button
                id="form-submit"
                class="btn-reset btn btn--primary"
                @click="submitForm"
            >
                <SvgController type="tick" />
                <span>Save</span>
            </button>
        </page-header>
        <div class="center">
            <alert
                v-if="flash.status === 'profile-information-updated'"
                type="success"
                :inline="true"
            >
                <p>Profile updated successfully.</p>
            </alert>
            <div class="profile-info">
                <FormulateForm
                    v-model="form"
                    :errors="form.errors"
                    class="flex-col"
                    @submit="submitForm"
                >
                    <div>
                        <FormulateInput
                            type="text"
                            name="first_name"
                            label="First Name"
                            validation="required"
                            validation-name="First Name"
                        />
                        <FormulateInput
                            type="text"
                            name="last_name"
                            label="Surname"
                            validation="required"
                            validation-name="Surname"
                        />
                        <FormulateInput
                            type="email"
                            name="email"
                            label="Email Address"
                            validation="required|email"
                            validation-name="Email address"
                        />
                    </div>
                </FormulateForm>
            </div>
        </div>
    </app-layout>
</template>

<script>
import Alert from 'Utilities/alert/Alert';
export default {
    name: 'ProfileEdit',
    components: {
        Alert,
    },
    props: {
        loggedUser: {
            type: Object,
            required: true,
        },
        errors: {
            type: Object,
            required: true,
        },
        flash: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            form: this.$inertia.form({
                first_name: this.loggedUser.first_name,
                last_name: this.loggedUser.last_name,
                email: this.loggedUser.email,
            }),
        };
    },
    methods: {
        submitForm() {
            this.form.put(this.$route('user-profile-information.update'), {
                onSuccess: () => {
                    this.$inertia.visit(this.$route('profile.show'));
                },
            });
        },
    },
};
</script>
