<!-- eslint-disable vue/no-mutating-props -->
<template>
    <div
        class="formulate-help"
        @mouseover="hover = true"
        @mouseleave="hover = false"
        @click="toggleHover"
    >
        <div class="formulate-help-text">
            <SvgController
                class="formulate-help-icon"
                type="help"
            />
            <span v-show="hover">{{ context.help }}</span>
            <span v-show="!hover">&nbsp;</span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'FormHelp',
    components: {},
    props: {
        context: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            hover: false,
        };
    },
    methods: {
        toggleHover() {
            this.hover = !this.hover;
        },
    },
};
</script>
