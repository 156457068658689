var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "alert-box", class: [_vm.alertType, _vm.inlineFlex] },
    [
      _c("SvgController", {
        staticClass: "alert-box-icon",
        attrs: { type: _vm.icon },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "alert-box-text" }, [_vm._t("default")], 2),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }