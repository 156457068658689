<template>
    <app-layout section="settings">
        <page-header
            title="Trees"
        >
            <button
                type="button"
                class="btn btn--primary"
                @click="editTree({})"
            >
                <SvgController
                    type="plus"
                />
                Add Tree
            </button>
        </page-header>
        <div
            ref="container"
            class="center"
        >
            <TableGridLayout
                :columns="columns"
                :rows="trees"
                :output="['table']"
                :grid-layout="gridLayout"
            />
        </div>
    </app-layout>
</template>

<script>
import TreeEdit from './Edit';
import Vue from 'vue';

export default {
    name: 'TreeList',
    components: {},

    props: {
        trees: {
            type: Array,
            default: () => ([]),
        },
    },
    data() {
        return {
            columns: [],
            gridLayout: {},
        };
    },

    created() {
        const dropdown = [{
            name: 'Edit Tree',
            onClick: (evt, row, index) => {
                this.editTree(this.trees[index]);
            },
        }, {
            name: 'Delete Agent',
            color: 'red',
            onClick: (evt, row, index) => {
                this.deleteTree(this.trees[index]);
            },
        }];

        this.columns = [{
            label: 'Name',
            field: 'name',
        }, {
            label: 'Slope',
            field: 'slope',
        }, {
            label: 'Constant',
            field: 'constant',
        }, {
            label: '',
            field: 'dropdown',
            type: 'dropdown',
            sortable: false,
            position: 'right',
            options: dropdown,
        }];

        this.gridLayout = {
            title: 'name',
            dropdown: dropdown,
        };
    },

    methods:{
        editTree(tree) {
            const ComponentClass = Vue.extend(TreeEdit);

            const instance = new ComponentClass({
                propsData: {
                    tree: tree,
                },
            });

            instance.$mount();
            this.$refs.container.appendChild(instance.$el);
        },

        deleteTree(tree) {
            this.$confirm(
                {
                    title: 'Delete Tree',
                    message: 'Are you sure you want to delete this tree? This action can\'t be undone.',
                    confirmBtnClass: 'btn--red',
                    confirmBtnText: 'Delete',
                    callback: confirm => {
                        if (confirm) {
                            this.$inertia.delete(this.$route('tree.destroy', tree.id), {
                                onSuccess: () => {
                                    this.$refs.deleteModal.closeModal();
                                },
                            });
                        }
                    },
                },
            );

        },
    },
};
</script>
