<template>
    <div>
        <FormulateInput
            key="development_type_3"
            type="select-plugin"
            name="development_type"
            label="Development Type"
            validation="required"
            validation-name="Development type"
            :options="developmentTypeOptions"
            placeholder="Select Development Type"
            :disabled="disabled"
        />
        <div class="form-flex--tab">
            <FormulateInput
                v-if="value.planning_status && value.planning_status !== 'pre-application'"
                key="planning_ref_3"
                type="text"
                name="planning_ref"
                label="Planning reference number"
                validation="required"
                validation-name="Planning reference number"
                :disabled="disabled"
            />
            <FormulateInput
                key="planning_status_3"
                type="select-plugin"
                name="planning_status"
                label="Planning Status"
                placeholder="Select Planning Status"
                :options="planningStatusOptions"
                validation="required"
                validation-name="Planning status"
                :disabled="disabled"
            />
        </div>
        <FormulateInput
            key="land_status_3"
            type="select-plugin"
            name="land_status"
            label="Land status"
            validation="required"
            validation-name="Land status"
            :options="landStatusOptions"
            placeholder="Select Land status"
            :disabled="disabled"
        />
        <FormulateInput
            key="study_3"
            type="toggle-checkbox"
            name="study"
            label="Have you had a Baseline Study done?"
        />
        <FormulateInput
            v-if="!value.study"
            key="study_requested_3"
            type="toggle-checkbox"
            name="study_requested"
            label="Would you like a Baseline Study done?"
            help="By selecting &quot;Yes&quot; we will send you an email to arrange a visit."
        />
    </div>
</template>

<script>
export default {
    name: 'ProjectFormPage3',
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
        value: {
            type: Object,
            default: () => {},
        },
        developmentTypeOptions: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
        };
    },
    computed: {
        landStatusOptions() {
            return [{
                value: 'green field',
                label: 'Green Field',
            },{
                value: 'brown field',
                label: 'Brown Field',
            }];
        },

        planningStatusOptions() {
            return [{
                value:'pre-application',
                label:'Pre-application',
            },{
                value:'submitted',
                label:'Submitted',
            }, {
                value:'approved',
                label:'Approved',
            }, {
                value:'resolution to approve',
                label:'Resolution to Approve',
            }];
        },
    },
    methods: {
        convertDocument(workbook, selectedFile) {
            const habitatListBaseline = this.onSiteBaselineConvert(workbook);
            const habitatListCreated = this.onSiteCreatedConvert(workbook);
            const habitatListEnhancement = this.onSiteEnhancementConvert(workbook);

            this.tmpValue.onsite_baseline = [];
            this.tmpValue.onsite_creation = [];
            this.tmpValue.onsite_enhancement = [];

            habitatListBaseline.forEach(habitat => {
                this.tmpValue.onsite_baseline.push(habitat);
            });
            habitatListCreated.forEach(habitat => {
                this.tmpValue.onsite_creation.push(habitat);
            });
            habitatListEnhancement.forEach(habitat => {
                this.tmpValue.onsite_enhancement.push(habitat);
            });
            this.documentUploaded = true;
            this.tmpValue.biodiversity_metric_document = selectedFile;
        },
    },
};
</script>
