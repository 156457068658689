var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "convert-file-dropzone" },
    [
      _c(
        "div",
        { staticClass: "convert-file-dropzone-icon" },
        [
          _vm._t("icon", function () {
            return [
              _vm.state === "waiting"
                ? _c("SvgController", { attrs: { type: _vm.iconWaiting } })
                : _vm._e(),
              _vm._v(" "),
              _vm.state === "uploading"
                ? _c("SvgController", { attrs: { type: _vm.iconUploading } })
                : _vm._e(),
              _vm._v(" "),
              _vm.state === "done"
                ? _c("SvgController", { attrs: { type: _vm.iconDone } })
                : _vm._e(),
            ]
          }),
        ],
        2
      ),
      _vm._v(" "),
      _vm._t("default"),
      _vm._v(" "),
      _vm._t("document", function () {
        return [
          _vm.documentName
            ? _c("small", [
                _vm._v(
                  "\n            " + _vm._s(_vm.documentName) + "\n        "
                ),
              ])
            : _vm._e(),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }