var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("report", {
    attrs: {
      type: "development",
      "report-data": {
        project: _vm.project,
        title: _vm.title,
      },
      filename: _vm.filename,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }