<template>
    <app-layout section="users">
        <page-header
            title="Users"
            icon="people"
            :count="users.total"
        >
            <button
                v-if="loggedUser.can.includes('user_invite')"
                type="button"
                class="btn btn--primary"
                @click="addUser()"
            >
                <SvgController
                    type="plus"
                />
                New User
            </button>
        </page-header>

        <div
            class="center"
        >
            <TableGridLayout
                :columns="columns"
                :rows="users.data"
                :output="['table']"
                :grid-layout-for-table="gridLayout"
            >
                <template #header-left>
                    <list-filter
                        :fields="filterOptions"
                        :route="$route('users')"
                        search-placeholder="Search Users"
                        section="users"
                    />
                </template>

                <template #footer>
                    <pagination
                        :links="users.links"
                        section="users"
                    />
                </template>
            </TableGridLayout>
        </div>


        <modal ref="newUserModal">
            <template #header>
                <h3>
                    <span v-if="editMode === 'add'">New</span>
                    <span v-else-if="editMode === 'edit'">Edit</span>
                    User
                </h3>
            </template>
            <template #body>
                <edit
                    :edit-mode="editMode"
                    :user="editingUser"
                    @closeModal="closeModal"
                />
            </template>
        </modal>
    </app-layout>
</template>

<script>

import Modal from 'Utilities/modal/Modal';
import ListFilter from 'Utilities/list-filter/ListFilter.vue';
import Pagination from 'Utilities/pagination/Pagination.vue';

import Edit from './Edit';

export default {
    name: 'Users',
    components: {
        Edit,
        Modal,
        ListFilter,
        Pagination,
    },
    props: {
        users: {
            type: Object,
            default: () => ({}),
        },
        loggedUser: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            editMode: null,
            filterOptions: [{
                name: 'role',
                options: this.$page.props.roles,
                placeholder: 'All Roles',
                reduce: 'name',
            }],
            dropdown: [],
            editingUser: {},
            gridLayout: {},
        };
    },

    computed: {
        companies() {
            return this.$page.props.companies.map((company) => {
                return {
                    value: company.id,
                    label: company.name,
                };
            });
        },
    },

    created() {
        const dropdown = [{
            name: 'Edit User',
            show: 'editable',
            onClick: (evt, row, index) => {
                this.editingUser = this.users.data[index];

                if (this.editingUser.id === this.loggedUser.id) {
                    this.$inertia.visit(this.$route('profile.show'));
                } else {
                    this.editMode = 'edit';
                    this.$refs.newUserModal.openModal();
                }
            },
        }, {
            name: 'Delete User',
            show: 'deletable',
            color: 'red',
            onClick: (evt, row, index) => {
                this.deleteUser(this.users.data[index]);
            },
        }];

        if (this.hasGroup('sequest') && this.$page.props.loggedUser.isSuperUser) {
            dropdown.push({
                name: 'Impersonate User',
                color: 'green',
                onClick: (evt, row, index) => {
                    this.$inertia.visit(`impersonate/${this.users.data[index].id}`);
                },
            });
        }

        this.columns = [{
            label: 'First Name',
            field: 'first_name',
        },{
            label: 'Surname',
            field: 'last_name',
        },{
            label: 'Email Address',
            field: 'email',
        },{
            label: 'Role',
            field: 'positions.0.role_label',
            spanClass: 'status-pill type-{role}',
        },{
            label: 'Last Login',
            field: 'last_login',
        },{
            label: '',
            field: 'permissions',
            type: 'dropdown',
            sortable: false,
            position: 'right',
            options: dropdown,
        }];

        this.gridLayout = {
            title: 'full_name',
            description: 'email',
            dropdown,
        };

        if (this.hasGroup(['sequest'])) {
            this.columns.splice(5, 0, {
                label: 'company',
                field: 'company.name',
                sortable: this.allowSorting,
            });
            this.gridLayout.company = 'company';
        }
    },

    methods: {
        addUser() {
            this.editMode = 'add';
            this.editingUser = {};
            this.$refs.newUserModal.openModal();
        },
        closeModal() {
            this.editingUser = {};
            this.$refs.newUserModal.closeModal();
        },

        deleteUser(user) {
            this.$confirm(
                {
                    title: 'Delete User',
                    message: 'Are you sure you want to delete this user? This action can\'t be undone.',
                    confirmBtnClass: 'btn--red',
                    confirmBtnText: 'Delete',
                    callback: confirm => {
                        if (confirm) {
                            this.$inertia.delete(this.$route('user.delete', user.id));
                            this.$confirm.close();
                        }
                    },
                },
            );
        },
    },

};
</script>
