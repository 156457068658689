<template>
    <section class="page-header-tabs center">
        <ul class="page-header-tabs-list lst-reset flex">
            <tab
                v-for="(tab, index) in tabsParsed"
                :key="`${tab.link}-${index}`"
                :tab-id="tab.id"
                :show="tab.show"
                :selected="valueInterim === tab.id"
                :type="type"
                :link="tab.link"
                :title="tab.title"
                :only="tab.only"
                :disabled="valueInterim === 'loading'"
                @click="(event) => setTab(event, tab)"
            >
                <slot />
                <template
                    v-for="(_, name) in $scopedSlots"
                    :slot="name"
                >
                    <slot
                        :name="name"
                    />
                </template>
            </tab>
        </ul>
    </section>
</template>

<script>
import Tab from './Tab';

export default {
    name: 'TabWrapper',
    components: {
        Tab,
    },
    props: {
        tabs: {
            type: Array,
            default: () => [],
        },
        value: {
            type: String,
            default: null,
        },
        type: {
            type: String,
            default: 'only',
            validator(value) {
                return ['hash', 'link', 'only'].includes(value);
            },
        },
    },
    data() {
        return {
            valueInterim: this.value,
        };
    },
    computed: {
        location() {
            if (typeof window !== 'undefined') {
                const {
                    hash,
                    href,
                    origin,
                    pathname,
                } = window.location;

                return {
                    hash,
                    href,
                    origin,
                    pathname,
                };
            }

            return {};
        },

        tabsParsed() {
            return this.tabs.map(tab => {
                if (!tab.id) tab.id = tab.link;

                return tab;
            });
        },
    },
    created() {
        const selected = this.findSelectedTab();

        if (selected.length) {
            this.valueInterim = selected[0].id;
            this.$emit('input', selected[0].id);
        } else {
            this.valueInterim = this.tabsParsed[0].id;
            this.$emit('input', this.tabsParsed[0].id);
        }
    },
    methods: {
        setTab(event, tab) {
            this.valueInterim = tab.id;

            if (event.type === 'loading') {
                this.$emit('input', 'loading');

                return;
            }

            if (this.type === 'only' && tab.link === null) {
                this.$emit('input', tab.id);

                return;
            }

            const selected = this.findSelectedTab(tab.link);

            if (selected.length) {
                this.valueInterim = selected[0].id;
                this.$emit('input', selected[0].id);
            } else {
                this.valueInterim = this.tabsParsed[0].id;
                this.$emit('input', this.tabsParsed[0].id);
            }
        },

        findSelectedTab(url = null) {
            const location = this.location;

            if (this.type === 'link' || this.type === 'only') {
                if (url) location.pathname = url.replace(location.origin, '');

                return this.tabsParsed.filter(tab => {
                    return (tab.link ? tab.link : '').replace(location.origin, '') === location.pathname;
                });
            } else if (this.type === 'hash') {
                if (url) location.hash = url;

                return this.tabsParsed.filter(tab => {
                    const hash = (tab.link.indexOf('#') !== 0 ? '#' : '') + tab.link;

                    return hash === location.hash;
                });
            }

            return [];
        },
    },
};
</script>
