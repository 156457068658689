import habitatData from './habitatData';

const habitatThemes = {};

Object.keys(habitatData).forEach(key => habitatData[key].filter(habitat => habitat.theme).forEach(habitat => habitatThemes[habitat.value] = habitat.theme));

export default {
    plot: '#429975',
    plotMarker: '#429975',
    lease: '#b61e2f',
    project: '#E47D44',
    lpa: '#191970',
    national_character_areas: '#8B4513',
    river_basin_districts: '#00BFFF',

    // Habitats
    woodland: '#663300',
    individual_trees: '#3a7d2e',
    hedgerows: '#336600',
    grassland: '#92D050',
    water: '#00B0F0',
    nature_reserves: '#FFC000',
    sssi: '#ff0000',
    national_conservation_sites: '#ffff00',
    house: '#F44336',
    barn: '#1565C0',
    shed: '#3E2723',
    associated_land: '#4A148C',

    // Habitat BNG Calculator
    ...habitatThemes,

    // arcgisAPI
    'Acid, Calcareous, Neutral Grassland': '#F9BEE8',
    'Arable and Horticultural': '#C29FD7',
    'Bare Ground': '#EAEDEF',
    'Bare Sand': '#FDFF03',
    'Bog': '#C29FD7',
    'Bracken': '#CE8867',
    'Broadleaved, Mixed and Yew Woodland': '#39A800',
    'Built-up Areas and Gardens': '#333333',
    'Coastal Saltmarsh': '#42A9E6',
    'Coastal Sand Dunes': '#FEFFBE',
    'Coniferous Woodland': '#3CA885',
    'Dwarf Shrub Heath': '#A966CE',
    'Fen, Marsh and Swamp': '#7B8DF5',
    'Improved Grassland': '#CEF57B',
    'Scrub': '#886F45',
    'Unclassified': '#E1E1E1',
    'Water': '#BEE8FF',
};
