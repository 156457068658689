<template>
    <div
        ref="div"
        class="formulate-suffix"
    >
        <div class="formulate-size">
            <div
                ref="wrapper"
                class="formulate-input-wrapper formulate-input-wrapper--padding"
                :style="{
                    paddingRight: `${padding}px`
                }"
            >
                <slot />
                <div
                    ref="suffix"
                    class="formulate-input-wrapper-suffix"
                >
                    <slot
                        name="suffix"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'InputSuffix',
    props: {
        target: {
            type: String,
            default: 'input',
        },
        extra: {
            type: Number,
            default: 16,
        },
        percentExtra: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            addPadding: null,
            padding: 0,
            paddingTarget: 0,
        };
    },
    watch: {
        paddingTarget() {
            if (this.addPadding) this.addPadding.style.paddingRight = `${this.paddingTarget}px`;
        },
    },
    mounted() {
        this.setWidth();
    },
    methods: {
        setWidth() {
            let boxSuffix = this.$refs.suffix.getBoundingClientRect();

            if (boxSuffix.width === 0) {
                boxSuffix = this.getHiddenWidth();
            }

            this.addPadding = this.$refs.wrapper.querySelector(this.target);

            if (!this.addPadding) {
                this.padding = boxSuffix.width + this.extra + ((boxSuffix.width / 100) * this.percentExtra);
            } else {
                this.paddingTarget = boxSuffix.width + this.extra + ((boxSuffix.width / 100) * this.percentExtra);
            }
        },
        getHiddenWidth() {
            const clone = this.$refs.div.cloneNode(true);

            document.body.appendChild(clone);
            const boxSuffix = clone.querySelector('.formulate-input-wrapper-suffix').getBoundingClientRect();

            clone.remove();

            return boxSuffix;
        },
    },
};
</script>
