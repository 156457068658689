<template>
    <modal
        ref="editModal"
        width="50em"
    >
        <template #header>
            <h3>
                Edit Contract
            </h3>
        </template>
        <template #body>
            <FormulateForm
                v-model="form"
                :schema="scemaForm"
                :errors="form.errors"
                class="flex-col"
                @submit="submitForm"
                @focus-display="displayNameFocus = true"
                @blur-display="displayNameFocus = false"
            />
        </template>
    </modal>
</template>

<script>
import Modal from 'Utilities/modal/Modal';
export default {
    name: 'ContractEdit',
    components: {
        Modal,
    },
    props: {
        show: {
            type: Boolean,
            default: false,
        },
        contract: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            form: this.$inertia.form({
                status: this.contract.status,
            }),
        };
    },
    computed: {
        scemaForm() {
            return [
                {
                    name: 'status',
                    label: 'Contract Status',
                    type: 'select-plugin',
                    placeholder: 'Please Select',
                    options: [
                        'contract issued',
                        'contract signed',
                    ],
                    required: true,
                },
                {
                    type: 'submit',
                },
            ];
        },
    },
    watch: {
        show(value) {
            if (value) {
                this.$refs.editModal.openModal();
            } else {
                this.$refs.editModal.closeModal();
            }
        },
    },
    methods: {
        openModal() {
            this.$refs.editModal.openModal();
        },
        closeModal() {
            this.$refs.editModal.closeModal();
        },
        submitForm(data) {
            this.$inertia.put(this.$route('contract.update', this.contract.id), data, {
                onSuccess: () => {
                    this.$emit('afterUpdate');
                    this.$refs.editModal.closeModal();
                },
            });
        },
    },
};
</script>
