var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.link && _vm.type === "link"
    ? _c(
        "inertia-link",
        { attrs: { href: _vm.link }, on: { click: _vm.handleClick } },
        [
          _vm._t("default", function () {
            return [_vm._v("\n        " + _vm._s(_vm.title) + "\n    ")]
          }),
        ],
        2
      )
    : _vm.link && _vm.type === "hash"
    ? _c(
        "a",
        { attrs: { href: _vm.getHash }, on: { click: _vm.handleClick } },
        [
          _vm._t("default", function () {
            return [_vm._v("\n        " + _vm._s(_vm.title) + "\n    ")]
          }),
        ],
        2
      )
    : _vm.link && _vm.type === "only"
    ? _c(
        "a",
        {
          attrs: { href: _vm.link || _vm.location.href },
          on: { click: _vm.handleClick },
        },
        [
          _vm._t("default", function () {
            return [_vm._v("\n        " + _vm._s(_vm.title) + "\n    ")]
          }),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }