<template>
    <div
        id="site-sidebar"
        :class="active === true ? 'active' : ''"
    >
        <dropdownMenuList :data="data" />
        <div
            class="tab-d-none absolute left-full top-0 bottom-0 w-100vw cursor-pointer"
            :class="{
                'd-none': !active,
            }"
            @click="$emit('toggleSidebar')"
        />
    </div>
</template>
<script>

import DropdownMenuList from 'Utilities/dropdown/DropdownMenuList';

export default {
    name: 'Sidebar',
    components: {
        DropdownMenuList,
    },
    props: {
        section: {
            type: String,
            default: '',
        },
        active: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            relationshipCount: this.$page.props.relationshipCount,
            data: this.buildSidebar(),
        };
    },

    watch: {
        '$page.props.relationshipCount': {
            deep: true,
            handler() {
                this.data = this.buildSidebar();
            },
        },
    },

    methods: {
        buildSidebar() {
            return [{
                name: 'Dashboard',
                icon: 'dashboard',
                active: this.$route().current('home'),
                href: this.$route('home'),
            },
            {
                name: 'Developments',
                icon: 'files',
                active: this.$route().current('project*'),
                href: this.$route('projects'),
                show: this.showItem({
                    groups: [
                        'sequest',
                        'property_developer',
                        'agent',
                        'lpa',
                    ],
                    permissions: ['project_view'],
                    relationshipCount: {
                        propertyDevelopers: ['agent'],
                    },
                }),
            },
            {
                name: 'Land Parcels',
                icon: 'plots',
                active: this.$route().current('plot*'),
                href: this.$route('plots'),
                show: this.showItem({
                    groups: [
                        'sequest',
                        'landowner',
                        'agent',
                        'lpa',
                    ],
                    permissions: ['plot_view'],
                    relationshipCount: {
                        landowners: ['agent'],
                    },
                }),
            },
            {
                name: 'BNG Agreements',
                icon: 'plots',
                active: this.$route().current('rental*'),
                href: this.$route('rentals'),
                show: this.showItem({
                    groups: [
                        'sequest',
                        'agent',
                        'lpa',
                    ],
                }),
            },
            {
                name: 'Users',
                icon: 'people',
                active: this.$route().current('user*'),
                href: this.$route('users'),
                show: this.showItem({
                    permissions: ['user_view'],
                }),
            },
            {
                name: 'Landowners',
                icon: 'briefcase',
                active: this.$route().current('landowner*'),
                href: this.$route('landowners'),
                show: this.showItem({
                    groups: [
                        'sequest',
                        'agent',
                    ],
                }),
            },
            {
                name: 'Property Developers',
                icon: 'building',
                active: this.$route().current('propertyDeveloper*'),
                href: this.$route('propertyDevelopers'),
                show: this.showItem({
                    groups: ['sequest','agent'],
                }),
            },
            {
                name: 'LPA',
                icon: 'building',
                active: this.$route().current('localPlanningAuthorities*'),
                href: this.$route('localPlanningAuthorities'),
                show: this.showItem({
                    groups: ['sequest'],
                }),
            },
            {
                name: 'Operating In',
                icon: 'building',
                active: this.$route().current('operating-in*'),
                href: this.$route('operating-in'),
                show: this.showItem({
                    policies: ['operatingIn_viewList'],
                    groups: ['agent', 'property_developer'],
                }),
            },
            {
                name: 'Agents',
                icon: 'tie',
                active: this.$route().current('agent*'),
                href: this.$route('agents'),
                show: this.showItem({
                    permissions: ['agent_view'],
                    groups: [
                        'sequest',
                        'landowner',
                        'property_developer',
                    ],
                }),
            },
            {
                name: 'Reports',
                icon: 'reports',
                active: this.$route().current('report*'),
                href: this.$route('reports'),
                show: this.showItem({
                    groups: [
                        'sequest',
                        'lpa',
                        'agent',
                    ],
                    permissions: ['reports_view'],
                }),
            },
            {
                name: 'Map',
                icon: 'map-icon',
                active: this.$route().current('map'),
                href: this.$route('map'),
                show: this.showItem({
                    groups: [
                        'lpa',
                        'sequest',
                        'property_developer',
                        'agent',
                    ],
                }),
            },
            {
                name: 'Planning Applications',
                icon: 'map-icon',
                active: this.$route().current('planningApplications*'),
                href: this.$route('planningApplications'),
                show: this.showItem({
                    groups: ['sequest'],
                }),
            },
            {
                name: 'Contact Us',
                icon: 'contact',
                active: this.$route().current('contact'),
                href: this.$route('contact'),
                show: this.showItem({
                    groups: [
                        'agent',
                        'landowner',
                        'lpa',
                        'property_developer',
                    ],
                }),
            },
            {
                name: 'Settings',
                icon: 'cog',
                active: this.$route().current('setting*'),
                href: this.$route('settings'),
                show: this.showItem({
                    groups: ['sequest'],
                }),
            }];
        },
        showItem({ groups, permissions, policies, relationshipCount }) {
            if (groups && !this.hasGroup(groups)) {
                return false;
            }

            if (relationshipCount) {
                let groupFound = false;
                const relationShipAllow = Object.entries(relationshipCount).filter(([relationship, groupArray]) => {
                    if (groupArray.filter((groupName) => this.hasGroup(groupName)).length) {
                        groupFound = true;

                        return this.$page.props.relationshipCount[relationship] > 0;
                    }
                });

                if (!relationShipAllow.length && groupFound) {
                    return false;
                }
            }

            if (permissions && !this.hasPermission(permissions)) {
                return false;
            }

            if (policies && !this.hasPolicy(policies)) {
                return false;
            }

            return true;
        },
    },
};
</script>
