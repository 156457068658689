import { mapColors } from 'Lib/defaults';

export default {
    methods: {
        mapPointToLayers(location) {
            let layer = false;

            if (location && location.lat && location.lng) {
                layer = {location_data : {
                    layer: {
                        shape: 'marker',
                        points: location,
                    },
                }, fillColor: mapColors.project};
            }

            return layer;
        },
        mapProjectLayers(project = this.project, includePin = false) {
            const layers = [];

            if (project.location_data) {
                if (project.location_data.layer) {
                    layers.push({location_data : project.location_data, fillColor: mapColors.project});

                    if (includePin && project.location_data.layer.center) {
                        const layer = this.mapPointToLayers(project.location_data.layer.center);

                        if (layer) {
                            layers.push(layer);
                        }
                    }
                } else {
                    const layer = this.mapPointToLayers(project.location_data);

                    if (layer) {
                        layers.push(layer);
                    }
                }
            }

            return layers;
        },

        mapVectors(planning_authority_id, river_basin_district_id) {
            const vectors = [];

            if (!Array.isArray(planning_authority_id)) {
                planning_authority_id = planning_authority_id ? [planning_authority_id] : [];
            }

            if (!Array.isArray(river_basin_district_id)) {
                river_basin_district_id = river_basin_district_id ? [river_basin_district_id] : [];
            }

            if (planning_authority_id.length && this.planningAuthorities) {
                const lpa = this.planningAuthorities.filter(lpa => planning_authority_id.includes(lpa.id));

                let lpaNames = null;

                if (lpa.length) {
                    lpaNames = lpa.map(lpa => [lpa.name.replace(' LPA', ''), lpa.name, `${lpa.name} LPA`]).flat();
                }

                vectors.push({
                    name: 'lpa',
                    url: this.$mapService + '/lpa/{z}/{x}/{y}.pbf',
                    attributesUrl: this.$mapService + '/lpa.attributes.json',
                    background: mapColors.lpa,
                    backgroundOpacity: 0.1,
                    lineWidth: 2,
                    stroke: mapColors.lpa,
                    strokeOpacity: 1,
                    filter: [{
                        property: 'name',
                        compare: '=',
                        type: 'OR',
                        value: lpaNames,
                    }],
                });
            }

            if (river_basin_district_id.length && this.riverBasinDistricts) {
                const district = this.riverBasinDistricts.filter(district => river_basin_district_id.includes(district.id));

                vectors.push({
                    name: 'riverBasinDistricts',
                    url: this.$mapService + '/surface_water_operational_catchments/{z}/{x}/{y}.pbf',
                    attributesUrl: this.$mapService + '/surface_water_operational_catchments.attributes.json',
                    background: mapColors.river_basin_districts,
                    backgroundOpacity: 0.1,
                    lineWidth: 2,
                    stroke: mapColors.river_basin_districts,
                    strokeOpacity: 1,
                    filter: [{
                        property: 'name',
                        compare: '=',
                        value: district.length ? district.map(district => district.name) : null,
                    }],
                });
            }

            return vectors;
        },
        validateMap(value) {
            if (value.value.layer && ((value.value.layer.paths && value.value.layer.paths.length && value.value.inputGeo) || (value.value.layer.shape && value.value.layer.radius))) {
                return true;
            }

            return false;
        },
    },
};

