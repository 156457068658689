var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      !_vm.value.study
        ? [
            _c(
              "div",
              { staticClass: "form-flex--tab form-flex--vcenter" },
              [
                _c("FormulateInput", {
                  attrs: {
                    type: "text",
                    name: "potential_estimated_units",
                    label: _vm.potentialTextEstimate,
                    disabled: true,
                  },
                }),
                _vm._v(" "),
                _vm.value.offset_type.includes("carbon")
                  ? _c("FormulateInput", {
                      attrs: {
                        type: "text",
                        name: "potential_secondary_estimated_units",
                        label: " Potential CO2-e(kg)",
                        disabled: true,
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _vm.value.offset_type.includes("bng")
              ? _c("BNGCalculator", {
                  key: "bng_calculator",
                  ref: "calcutator",
                  attrs: {
                    "max-area": _vm.value.size
                      ? parseFloat(_vm.value.size)
                      : null,
                  },
                  on: {
                    input: _vm.updateHabitatData,
                    area: _vm.updateHabitatArea,
                  },
                  model: {
                    value: _vm.tmpValue.potential_habitats,
                    callback: function ($$v) {
                      _vm.$set(_vm.tmpValue, "potential_habitats", $$v)
                    },
                    expression: "tmpValue.potential_habitats",
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.value.offset_type.includes("carbon")
              ? _c("SouthernCarbon", {
                  key: "southern_carbon",
                  ref: "calcutator",
                  on: { updateUnits: _vm.updateUnits },
                  model: {
                    value: _vm.tmpValue.potential_habitats,
                    callback: function ($$v) {
                      _vm.$set(_vm.tmpValue, "potential_habitats", $$v)
                    },
                    expression: "tmpValue.potential_habitats",
                  },
                })
              : _vm._e(),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }