<template>
    <div>
        <empty
            v-if="!$page.props.hasItems"
            type="property developers"
        />
        <div v-else>
            <TableGridLayout
                :columns="columns"
                :rows="propertyDevelopers.data"
                :has-header="hasHeader"
                :has-footer="hasFooter"
                :output="['table']"
                :grid-layout-for-table="gridLayout"
                @onRowClick="onRowClick"
            >
                <template #header-left>
                    <list-filter
                        search-placeholder="Search Property Developers"
                        section="property-developers"
                    />
                </template>

                <template #footer>
                    <pagination
                        :links="propertyDevelopers.links"
                        section="property-developers"
                    />
                </template>
            </TableGridLayout>
        </div>
    </div>
</template>

<script>

import ListFilter from 'Utilities/list-filter/ListFilter.vue';
import Pagination from 'Utilities/pagination/Pagination.vue';
import Empty from 'Utilities/empty/Empty';

export default {
    name: 'PropertyDevelopersTable',
    components: {
        ListFilter,
        Pagination,
        Empty,
    },

    props: {
        propertyDevelopers: {
            type: Object,
            default: () => ({}),
        },

        hasHeader: {
            type: Boolean,
            default: true,
        },

        hasFooter: {
            type: Boolean,
            default: true,
        },

        filterRoute: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            columns: [],
            gridLayout: {},
        };
    },

    created() {
        this.columns = [{
            label: 'Property Developer Name',
            'field': 'company_name',
        },{
            label: 'First Name',
            field: 'first_name',
        },{
            label: 'Surname',
            field: 'last_name',
        }, {
            label: 'Pending developments',
            field: 'pending',
            colour: '#6967D5',
            sortable: false,

        }, {
            label: 'Completed developments',
            field: 'completed',
            colour: '#5EBD8A',
            sortable: false,
        }];

        this.gridLayout = {
            title: 'company_name',
            description: 'full_name',
        };
    },

    methods: {
        onRowClick({ row }) {
            this.$inertia.visit(this.$route('propertyDeveloper.show', row.company_id));
        },
    },
};
</script>
