var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: {
        "pdf-report": _vm.isDownload,
      },
    },
    [
      _c(
        "section",
        { staticClass: "pdf-item" },
        [
          _c(
            "page-header",
            {
              attrs: { title: _vm.title, icon: "reports" },
              scopedSlots: _vm._u([
                {
                  key: "page-header-center",
                  fn: function () {
                    return [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.date) +
                          "\n            "
                      ),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "page-header-bottom",
                  fn: function () {
                    return [
                      _c("div", { staticClass: "center" }, [
                        _c(
                          "div",
                          { staticClass: "tile-wrap flex-space-between" },
                          [
                            _c("div", { staticClass: "tile" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "tile-inner tile-inner--single",
                                },
                                [
                                  _c("div", { staticClass: "tile-text" }, [
                                    _c("h4", [
                                      _c("span", [
                                        _vm._v(
                                          "\n                                            Offsite Location:\n                                        "
                                        ),
                                      ]),
                                      _vm._v(
                                        "\n                                        " +
                                          _vm._s(_vm.rental.plot.address) +
                                          "\n                                    "
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("h4", [
                                      _c("span", [
                                        _vm._v(
                                          "\n                                            URN:\n                                        "
                                        ),
                                      ]),
                                      _vm._v(
                                        "\n                                        " +
                                          _vm._s(
                                            _vm.rental.unique_reference_number
                                          ) +
                                          "\n                                    "
                                      ),
                                    ]),
                                  ]),
                                ]
                              ),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "link-wrap flex-space-between" },
                          [
                            _c("dashboard-link", {
                              attrs: {
                                title: "Size",
                                color: "#627086",
                                text: _vm.rental.size,
                              },
                            }),
                            _vm._v(" "),
                            _c("dashboard-link", {
                              attrs: {
                                title: "Agreement Start Date",
                                color: "#6967D5",
                                text: _vm.rental.rental_startdate,
                              },
                            }),
                            _vm._v(" "),
                            _c("dashboard-link", {
                              attrs: {
                                title: "Agreement End Date",
                                color: "#E47D44",
                                text: _vm.rental.rental_enddate,
                              },
                            }),
                            _vm._v(" "),
                            _c("dashboard-link", {
                              attrs: {
                                title: "BNG Units",
                                color: "#1782FF",
                                text: _vm.rental.units,
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _vm._v(" "),
              _c("div", {}, [
                _c("h3", [_vm._v("BNG Agreement Site Report")]),
                _vm._v(" "),
                _c("h3", [_vm._v(_vm._s(_vm.upperFirst(_vm.rental.status)))]),
              ]),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "center" }, [
        _c("section", { staticClass: "pdf-item" }, [
          _vm._m(0),
          _vm._v(" "),
          _c("div", { staticClass: "flex-col" }, [
            _c("div", { staticClass: "flex" }, [
              _c("div", { staticClass: "report-dev-info" }, [
                _c("h4", [
                  _c("span", [_vm._v("Development Name:")]),
                  _vm._v(
                    "\n                            " +
                      _vm._s(_vm.rental.project.name) +
                      "\n                        "
                  ),
                ]),
                _vm._v(" "),
                _c("h4", [
                  _c("span", [_vm._v("URN:")]),
                  _vm._v(
                    "\n                            " +
                      _vm._s(_vm.rental.project.unique_reference_number) +
                      "\n                        "
                  ),
                ]),
                _vm._v(" "),
                _c("h4", [
                  _c("span", [_vm._v("Address:")]),
                  _vm._v(
                    "\n                            " +
                      _vm._s(_vm.rental.project.address) +
                      "\n                        "
                  ),
                ]),
                _vm._v(" "),
                _c("h4", [
                  _c("span", [_vm._v("Size:")]),
                  _vm._v(
                    "\n                            " +
                      _vm._s(_vm.floatOutput(_vm.rental.project.size)) +
                      "\n                        "
                  ),
                ]),
                _vm._v(" "),
                _c("h4", [
                  _c("span", [_vm._v("Planning No:")]),
                  _vm._v(
                    "\n                            " +
                      _vm._s(_vm.rental.project.planning_ref) +
                      "\n                        "
                  ),
                ]),
                _vm._v(" "),
                _c("h4", [
                  _c("span", [_vm._v("Status:")]),
                  _vm._v(
                    "\n                            " +
                      _vm._s(_vm.rental.project.status) +
                      "\n                        "
                  ),
                ]),
                _vm._v(" "),
                _c("h4", [
                  _c("span", [_vm._v("Dev Type:")]),
                  _vm._v(
                    "\n                            " +
                      _vm._s(_vm.rental.project.development_type) +
                      "\n                        "
                  ),
                ]),
                _vm._v(" "),
                _c("h4", [
                  _c("span", [_vm._v("Land Status:")]),
                  _vm._v(
                    "\n                            " +
                      _vm._s(_vm.rental.project.land_status) +
                      "\n                        "
                  ),
                ]),
                _vm._v(" "),
                _c("h4", [
                  _c("span", [_vm._v("Total Units:")]),
                  _vm._v(
                    "\n                            " +
                      _vm._s(
                        _vm.floatOutput(
                          _vm.rental.project.onsite_baseline_total
                        )
                      ) +
                      "\n                        "
                  ),
                ]),
                _vm._v(" "),
                _c("h4", [
                  _c("span", [_vm._v("Desired Units:")]),
                  _vm._v(
                    "\n                            " +
                      _vm._s(
                        _vm.floatOutput(_vm.rental.project.desired_total)
                      ) +
                      "\n                        "
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "report-dev-map" },
                [
                  _c("report-map", {
                    ref: "rentalmap",
                    attrs: {
                      type: "rental",
                      zoom: 10,
                      rental: _vm.rental,
                      projects: [_vm.rental.project],
                      "is-download": _vm.isDownload,
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "dashboard-heading flex-space-between" }, [
      _c("h6", [_vm._v("Development")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }