var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "notifications-dropdown" },
    [
      _c(
        "DropdownMenu",
        {
          model: {
            value: _vm.show,
            callback: function ($$v) {
              _vm.show = $$v
            },
            expression: "show",
          },
        },
        [
          _c(
            "button",
            { staticClass: "btn-reset flex-center site-header-item-button" },
            [
              _c(
                "div",
                { staticClass: "notifications-dropdown-icon" },
                [
                  _c("div", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.notificationList.length,
                        expression: "notificationList.length",
                      },
                    ],
                    staticClass: "notifications-dropdown-dot",
                  }),
                  _vm._v(" "),
                  _c("SvgController", { attrs: { type: "bell" } }),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "flex-col",
              staticStyle: { height: "400px" },
              attrs: { slot: "dropdown" },
              slot: "dropdown",
            },
            [
              _c("header", { staticClass: "flex-space-between" }, [
                _c("h6", [_vm._v("Notifications")]),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn-reset notifications-dropdown-close",
                    on: { click: _vm.closeDropdown },
                  },
                  [_c("SvgController", { attrs: { type: "times" } })],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("transition", { attrs: { name: "fade" } }, [
                _c(
                  "section",
                  _vm._l(_vm.notificationList, function (item) {
                    return _c(
                      "div",
                      {
                        key: item.id,
                        ref: "notificationArea",
                        refInFor: true,
                        staticClass: "notifications-dropdown-menu",
                        style: _vm.styleNotification,
                      },
                      [
                        _c("p", [_vm._v(_vm._s(item.id) + _vm._s(item.name))]),
                        _vm._v(" "),
                        _c("dropdownMenuList", { attrs: { data: item.list } }),
                      ],
                      1
                    )
                  }),
                  0
                ),
              ]),
              _vm._v(" "),
              !_vm.notificationList.length
                ? _c("div", { staticClass: "notifications-dropdown-menu" }, [
                    _c("p", [_vm._v("No Notifications to show")]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("footer", [
                _vm.data.length
                  ? _c(
                      "button",
                      {
                        staticClass: "btn-reset",
                        on: {
                          click: function ($event) {
                            return _vm.dismissNotifications()
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n                    Dismiss All\n                "
                        ),
                      ]
                    )
                  : _vm._e(),
              ]),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }