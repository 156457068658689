var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "form-flex--tab form-flex--vcenter" },
        [
          _c("FormulateInput", {
            key: "drainage_2",
            attrs: {
              type: "select-plugin",
              name: "drainage",
              label: "drainage",
              validation: "required",
              "validation-name": "drainage",
              options: _vm.drainage,
              placeholder: "Select Drainage Type",
              disabled: _vm.disabled,
            },
          }),
          _vm._v(" "),
          _c("FormulateInput", {
            key: "soilType_2",
            attrs: {
              type: "select-plugin",
              name: "soil_type",
              label: "Soil Type",
              validation: "required",
              "validation-name": "Soil Type",
              options: _vm.soilType,
              placeholder: "Select Soil Type",
              disabled: _vm.disabled,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "form-flex--tab form-flex--auto form-flex--vcenter" },
        [
          _c("FormulateInput", {
            key: "stream_2",
            staticClass: "max-w-2",
            attrs: {
              type: "toggle-checkbox",
              name: "river_running",
              label: "Is there a stream or river running through the land?",
            },
          }),
          _vm._v(" "),
          _c("FormulateInput", {
            key: "livestock_2",
            staticClass: "max-w-2",
            attrs: {
              type: "toggle-checkbox",
              name: "livestock",
              label: "Is there any livestock currently on the land?",
            },
          }),
          _vm._v(" "),
          _c("div"),
        ],
        1
      ),
      _vm._v(" "),
      _vm.value.livestock
        ? _c("FormulateInput", {
            key: "animals_2",
            attrs: {
              type: "select-plugin",
              name: "livestock_type",
              label: "animals",
              validation: "required",
              "validation-name": "livestock_type",
              options: _vm.animalList,
              placeholder: "Select Animals",
              disabled: _vm.disabled,
              multiple: true,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.value.livestock_type && _vm.value.livestock_type.length
        ? _vm._l(_vm.value.livestock_type, function (animal) {
            return _c(
              "div",
              { key: `animal-${animal}` },
              [
                _c("FormulateInput", {
                  key: `animal-${animal}_input_2`,
                  attrs: {
                    type: "range-between",
                    name: `livestock_${animal}`,
                    label: `How many ${
                      _vm.animalSettings[animal]
                        ? _vm.animalSettings[animal].label
                        : animal
                    } are on the land?`,
                    min: _vm.animalSettings[animal]
                      ? _vm.animalSettings[animal].min
                      : 0,
                    max: _vm.animalSettings[animal]
                      ? _vm.animalSettings[animal].max
                      : 200,
                    "max-between": _vm.animalSettings[animal]
                      ? _vm.animalSettings[animal].inBetween
                      : 15,
                    "edit-values": true,
                    "allow-over-max": true,
                    validation: "required",
                    "validation-name": "Name",
                  },
                }),
              ],
              1
            )
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }