<template>
    <div
        class="alert-box"
        :class="[alertType, inlineFlex]"
    >
        <SvgController
            :type="icon"
            class="alert-box-icon"
        />
        <div class="alert-box-text">
            <slot />
        </div>
    </div>
</template>
<script>
export default {
    name: 'Alert',
    props: {
        type: {
            type: String,
            required: true,
        },
        inline: {
            type: Boolean,
            required: false,
        },
    },
    computed: {
        alertType() {
            return 'alert-box-' + this.type;
        },
        inlineFlex() {
            return this.inline ? 'alert-box-inline' : '';
        },
        icon() {
            switch(this.type) {
                case 'warning':
                    return 'alert';
                case 'success':
                    return 'info-correct';
                default:
                    return 'info-information';
            }
        },
    },

};
</script>
