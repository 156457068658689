var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "tile" }, [
    _c(
      "div",
      {
        class: `tile-inner${_vm.href ? " clickable" : ""}`,
        on: { click: _vm.handleClick },
      },
      [
        _c(
          "div",
          { staticClass: "tile-icon", style: _vm.style },
          [_c("SvgController", { attrs: { type: _vm.icon } })],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "tile-text" }, [
          _c("h5", [_vm._v(_vm._s(_vm.title))]),
          _vm._v(" "),
          _c("h2", [_vm._v(_vm._s(_vm.number))]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }