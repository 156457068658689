<template>
    <app-layout section="rentals">
        <page-header
            title="BNG Agreements"
            icon="plots"
        />
        <div class="center">
            <TableGridLayout
                :columns="columns"
                :rows="rentals.data"
                mode="remote"
                :total-records="rentals.total"
                :has-header="true"
                :has-footer="true"
                :output="output"
                :grid-layout-for-table="gridLayout"
                @onRowClick="goToRental"
            >
                <template #header-left>
                    <list-filter
                        :fields="filterOptions"
                        :route="filterRoute"
                        :section="['rentals', 'items']"
                    />
                </template>
                <template #footer>
                    <pagination
                        :links="rentals.links"
                        section="rentals"
                    />
                </template>
            </TableGridLayout>
        </div>
    </app-layout>
</template>
<script>
import Pagination from 'Utilities/pagination/Pagination';
import ListFilter from 'Utilities/list-filter/ListFilter';
export default {
    name: 'Rentals',
    components: {
        Pagination,
        ListFilter,
    },
    props: {
        rentals: {
            type: Object,
            default: () => ({}),
        },
        filterRoute: {
            type: String,
            default: null,
        },

        allowSorting: {
            type: Boolean,
            default: true,
        },

        hasCheckbox: {
            type: Boolean,
            default: false,
        },

        hasHeader: {
            type: Boolean,
            default: true,
        },

        hasFooter: {
            type: Boolean,
            default: true,
        },
        output: {
            type: Array,
            default() {
                return ['table'];
            },
        },
    },
    data() {
        return {
            columns: [],
            gridLayout: {},
            filterOptions: [{
                name: 'rental_startdate',
                placeholder: 'Agreement Start Date',
                type: 'daterange-plugin',
            },{
                name: 'next_audit',
                placeholder: 'Next Update Due',
                type: 'daterange-plugin',
            },{
                name: 'plot_status',
                placeholder: 'Land Parcel Status',
                options: this.$page.props.plot_statuses,
                type: 'select-plugin',
            },{
                name: 'latest_update_status',
                placeholder: 'Update Status',
                options: this.$page.props.update_statuses,
                type: 'select-plugin',
            },{
                name: 'update_overdue',
                type: 'toggle-checkbox',
                placeholder: ['All', 'Overdue'],
            }],
        };
    },
    computed: {
        companies() {
            return this.$page.props.companies.map((company) => {
                return {
                    value: company.id,
                    label: company.name,
                };
            });
        },
    },
    created() {
        this.columns = [{
            label: 'Company',
            field: 'plot.company.name',
            sortable: this.allowSorting,
        },{
            label: 'Land Parcel',
            field: 'plot.name',
            sortable: this.allowSorting,
        },{
            label: 'Agreement Start Date',
            field: 'rental_startdate',
            sortable: this.allowSorting,
        },{
            label: 'Next Update Due',
            field: 'next_audit',
            sortable: this.allowSorting,
        },{
            label: 'Land Parcel Status',
            field: 'plot_status',
            spanClass: 'status-pill type-{plot_status}',
            sortable: this.allowSorting,
        },{
            label: 'Update Status',
            field: 'latest_update_status',
            spanClass: 'status-pill type-{latest_update_status}',
            sortable: this.allowSorting,
        }];

        this.gridLayout = {
            title: 'plot.company.name',
            description: 'plot.name',
            status: ['plot_status', 'latest_update_status'],
            onClick: (evt, row, index) => {
                this.goToRental({row: this.rentals.data[index]});
            },
        };

    },
    methods: {
        goToRental({ row }) {
            this.$inertia.visit(this.$route('rental.show', row.id));
        },
    },
};
</script>
