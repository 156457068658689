var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("FormulateInput", {
        key: "name_1",
        attrs: {
          type: "text",
          name: "name",
          label: "Name",
          validation: "required",
          "validation-name": "Name",
          disabled: _vm.disabled,
        },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "form-flex--tab form-flex--vcenter" },
        [
          _c("FormulateInput", {
            key: "offset_type_1",
            attrs: {
              type: "select-plugin",
              name: "offset_type",
              label: "Offset Type",
              validation: "required",
              "validation-name": "Offset Type",
              options: _vm.offsetOptions,
              placeholder: "Select Offset Type",
              disabled: _vm.disabled,
              multiple: true,
            },
          }),
          _vm._v(" "),
          _vm.isSuperUser
            ? [
                _c("FormulateInput", {
                  key: "status_1",
                  attrs: {
                    type: "select-plugin",
                    name: "status",
                    label: "Status",
                    validation: "required",
                    "validation-name": "Status",
                    options: _vm.statusOptions,
                    placeholder: "Select Status",
                    disabled: _vm.disabled,
                  },
                }),
              ]
            : _vm._e(),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "form-flex--tab form-flex--vcenter" },
        [
          _vm.value.offset_type.includes("bng")
            ? [
                _c("FormulateInput", {
                  key: "planning_authority_id_1",
                  attrs: {
                    type:
                      _vm.planningAuthorities.length > 1
                        ? "select-plugin"
                        : "fake",
                    name: "planning_authority_id",
                    label: "Planning Authority",
                    validation: "required",
                    "validation-name": "Planning Authority",
                    options: _vm.planningAuthoritiesSelect,
                    placeholder: "Select Planning Authority",
                    disabled: _vm.disabled,
                    "parse-value": () => _vm.planningAuthorities[0].name,
                  },
                }),
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.value.offset_type.includes("nutrient_offset")
            ? [
                _c("FormulateInput", {
                  key: "river_basin_district_id_1",
                  attrs: {
                    type:
                      _vm.riverBasinDistricts.length > 1
                        ? "select-plugin"
                        : "fake",
                    name: "river_basin_district_id",
                    label: "River Catchment Area",
                    validation: "required",
                    "validation-name": "River Catchment Area",
                    options: _vm.riverBasinDistrictSelect,
                    placeholder: "Select River Catchment Area",
                    disabled: _vm.disabled,
                    "parse-value": () => _vm.riverBasinDistricts[0].name,
                  },
                }),
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.companies && _vm.companies.length > 1
            ? [
                _vm.hasGroup(["sequest", "agent"])
                  ? _c("FormulateInput", {
                      key: "company_id_1",
                      attrs: {
                        type: "select-plugin",
                        name: "company_id",
                        label: "Company",
                        validation: "required",
                        placeholder: "Select Company",
                        options: _vm.companiesSelect,
                        disabled: _vm.disabled,
                      },
                    })
                  : _vm._e(),
              ]
            : _vm._e(),
        ],
        2
      ),
      _vm._v(" "),
      _vm.isLegacy && !_vm.showMap
        ? [
            _c("p", [
              _vm._v(
                "The map functionality has been updated to use area polygons instead of a single point. Would you like to:"
              ),
            ]),
            _vm._v(" "),
            _c(
              "p",
              [
                _c(
                  "button",
                  {
                    staticClass: "btn btn--primary",
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.toggleLegacy(false)
                      },
                    },
                  },
                  [_vm._v("\n                Use new plotting\n            ")]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn--primary",
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.toggleLegacy(true)
                      },
                    },
                  },
                  [_vm._v("\n                Keep using point\n            ")]
                ),
                _vm._v(" "),
                _c("FormulateInput", {
                  attrs: {
                    type: "hidden",
                    name: "mapCheck",
                    validation: "required",
                    "validation-messages": {
                      required: "Please select the map type you wish to use.",
                    },
                  },
                }),
              ],
              1
            ),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.showMap
        ? _c("FormulateInput", {
            key: "map",
            ref: "map",
            attrs: {
              "lat-long": _vm.tmpValue.map_location.points,
              name: "map_location",
              type: "map",
              "default-click-action": "pan",
              validation: "required|location",
              "validation-rules": {
                location: _vm.validateMap,
              },
              "validation-messages": {
                location: _vm.isLegacy
                  ? _vm.mapMessageTextErrorLegacy
                  : _vm.mapMessageTextError,
              },
              zoom: _vm.zoom,
              "show-geo-input": "",
              lpa: _vm.currentLpa,
              "map-type": _vm.isLegacy ? "project-legacy" : "project",
              country: "England",
              "fit-bounds": _vm.editable ? "edit" : "vectors",
              "fit-bounds-change": "vectors",
              message: _vm.mapMessage,
              "edit-fill-color": _vm.mapColors.project,
              vectors: _vm.mapVectors(
                !isNaN(parseInt(_vm.value.planning_authority_id))
                  ? parseInt(_vm.value.planning_authority_id)
                  : null,
                !isNaN(parseInt(_vm.value.river_basin_district_id))
                  ? parseInt(_vm.value.river_basin_district_id)
                  : null
              ),
              disabled: !_vm.showMap,
            },
            model: {
              value: _vm.tmpValue.map_location,
              callback: function ($$v) {
                _vm.$set(_vm.tmpValue, "map_location", $$v)
              },
              expression: "tmpValue.map_location",
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.invalidCountryCode
        ? _c("FormulateInput", {
            key: "country_code",
            attrs: {
              type: "select-plugin",
              name: "country_code",
              validation: "required",
              label:
                "We are unable to find a country from the map. Please choose a country",
              "validation-name": "Country",
              "reduce-key": "code",
              "label-key": "name",
              options: _vm.countries,
              placeholder: "Country",
            },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }