<template>
    <modal ref="registerUser">
        <template #header>
            <h3>Register Admin</h3>
        </template>
        <template #body>
            <template v-if="form.company_id === null">
                <p>No company id found.</p>
            </template>
            <template v-else>
                <p>
                    Send an invite to the main admin of {{ localPlanningAuthority.company_name }}.
                </p>
                <FormulateForm
                    v-model="form"
                    class="flex-col"
                    :errors="form.errors"
                    @submit="submitForm"
                >
                    <FormulateInput
                        type="text"
                        name="first_name"
                        label="First Name"
                        validation="required"
                        validation-name="First Name"
                    />
                    <FormulateInput
                        type="text"
                        name="last_name"
                        label="Surname"
                        validation="required"
                        validation-name="Surname"
                    />
                    <FormulateInput
                        type="email"
                        name="email"
                        label="Email Address"
                        validation="required|email"
                        validation-name="Email address"
                    />

                    <div class="formulate-input btn--group">
                        <a
                            href="#"
                            class="btn"
                            @click="$refs.registerUser.closeModal"
                        >
                            Cancel
                        </a>
                        <button
                            class="btn--reset btn btn--primary"
                            type="submit"
                        >
                            <SvgController type="tick" />
                            Save
                        </button>
                    </div>
                </FormulateForm>
            </template>
        </template>
    </modal>
</template>

<script>
import Modal from 'Utilities/modal/Modal';
export default {
    components: {
        Modal,
    },

    props: {},
    data() {
        return {
            form: this.$inertia.form({
                first_name: null,
                last_name: null,
                email: null,
                company_id: null,
                group_id: 5,
                role_id: 1,
                company_name: 'null', // this is really the planning authority id
                type: 'localPlanningAuthority',
            }),
        };
    },

    methods:{
        open(localPlanningAuthority) {
            this.localPlanningAuthority = localPlanningAuthority;
            this.form.company_id = localPlanningAuthority.company_id ? localPlanningAuthority.company_id.toString() : null;
            this.form.company_name = localPlanningAuthority.planning_auth_id ? localPlanningAuthority.planning_auth_id.toString() : null;
            this.$refs.registerUser.openModal();
        },

        submitForm() {
            this.form.put(this.$route('invite.send'), {
                onSuccess: () => {
                    this.$refs.registerUser.closeModal();
                },
            });
        },
    },
};
</script>
