<template>
    <inertia-link
        v-if="link && type === 'link'"
        :href="link"
        @click="handleClick"
    >
        <slot>
            {{ title }}
        </slot>
    </inertia-link>
    <a
        v-else-if="link && type === 'hash'"
        :href="getHash"
        @click="handleClick"
    >
        <slot>
            {{ title }}
        </slot>
    </a>
    <a
        v-else-if="link && type === 'only'"
        :href="link || location.href"
        @click="handleClick"
    >
        <slot>
            {{ title }}
        </slot>
    </a>
</template>

<script>
export default {
    name: 'TabButton',
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
        link: {
            type: String,
            default: null,
        },
        only: {
            type: Array,
            default: () => [],
        },
        selected: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default: null,
        },
        type: {
            type: String,
            default: 'link',
            validator(value) {
                return ['hash', 'link', 'only'].includes(value);
            },
        },
    },
    computed: {
        getHash() {
            if (this.link.indexOf('#') !== 0) {
                return `#${this.link}`;
            }

            return this.link;
        },

        location() {
            if (typeof window !== 'undefined') {
                const {
                    hash,
                    href,
                    origin,
                    pathname,
                } = window.location;

                return {
                    hash,
                    href,
                    origin,
                    pathname,
                };
            }

            return {};
        },
    },
    methods: {
        handleClick(event) {
            if (this.type === 'only') {
                event.preventDefault();
            }

            if (!this.disabled && !this.selected) {
                if (this.type === 'only') {
                    this.$emit('click', {
                        type: 'loading',
                    });

                    this.$inertia.get(this.link, {}, {
                        preserveState: true,
                        preserveScroll: true,
                        only: this.only,
                        replace: true,
                        onSuccess: (data) => {
                            this.$emit('click', {
                                type: 'loaded',
                                ...data,
                            });
                        },
                    });
                } else {
                    this.$emit('click', {
                        type: 'click',
                    });
                }
            }
        },
    },
};
</script>
