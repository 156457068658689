// All items will require at least id and a name
const returnData = (type) => {
    switch(type) {
        case 'local_nature_reserves':
            return {
                id: 'GlobalID',
                object_id: 'OBJECTID',
                name: 'LNR_NAME',
                nnr_area: 'NNR_AREA',
                reference_id: 'REFERENCE',
                status: 'STATUS',
                g_id: 'GID',
                g_id_file: 'GIS_FILE',
                number: 'NUMBER',
                area: 'AREA',
                date: 'GIS_DATE',
                version: 'VERSION',
            };

        case 'lpa':
            return {
                id: 'LPA23CD',
                name: 'LPA23NM',
            };

        case 'national_character_areas_england':
            return {
                id: 'GlobalID',
                name: 'NCA_Name',
                area: 'NANAME',
            };

        case 'national_nature_reserves':
            return {
                id: 'GlobalID',
                object_id: 'OBJECTID',
                name: 'NNR_NAME',
                nnr_area: 'NNR_AREA',
                reference_id: 'REFERENCE',
                status: 'STATUS',
                g_id: 'GID',
                g_id_file: 'GIS_FILE',
                number: 'NUMBER',
                area: 'AREA',
                date: 'GIS_DATE',
                version: 'VERSION',
            };

        case 'national_parks':
            return {
                object_id: 'OBJECTID',
                code_id: 'CODE',
                name: 'NAME',
                measure: 'MEASURE',
                date: 'DESIG_DATE',
                link: 'HOTLINK',
                status: 'STATUS',
            };

        case 'potential_special_protection_areas':
            return {
                object_id: 'OBJECTID',
                name: 'SPA_NAME',
                code_id: 'SPA_CODE',
                spa_area: 'SPA_AREA',
                grid_id: 'GRID_REF',
                name_2: 'NAME',
                status: 'STATUS',
                id: 'ID',
                area: 'AREA',
                date: 'GIS_DATE',
                version: 'VERSION',
            };

        case 'proposed_ramsar_sites':
            return {
                id: 'ID',
                f_id: 'FID',
                name: 'NAME',
                code_id: 'CODE',
                area: 'AREA',
                grid_id: 'GRID_REF',
                status: 'STATUS',
                date: 'GIS_DATE',
                version: 'VERSION',
            };

        case 'ramsar_sites':
            return {
                object_id: 'OBJECTID',
                name: 'Teesmouth and Cleveland CoastNAME',
                CODE: 'CODE',
                area: 'AREA',
                grid_id: 'GRID_REF',
                NAME_2: 'NAME0',
                status: 'STATUS',
                id: 'ID',
                date: 'GIS_DATE',
                version: 'VERSION',
            };

        case 'sites_of_special_scientific_interest_units':
            return {
                object_id: 'OBJECTID',
                all_consult: 'AllConsult',
                infrastructure: 'Infrastruc',
                wind_solar: 'WindSolar',
                min_oil_gas: 'MinOilGas',
                rural_non_residential: 'RuralNonRe',
                residential: 'Residentia',
                rural_residential: 'RuralResid',
                air_pollution: 'AirPolluti',
                combustion: 'Combustion',
                waste: 'Waste',
                compost: 'Compost',
                discharge: 'Discharge',
                water_supply: 'Water_Sply',
                notes_1: 'NOTES_1',
                notes_2: 'NOTES_2',
            };

        case 'special_areas_of_conservation':
            return {
                object_id: 'OBJECTID',
                name: 'SAC_NAME',
                code_id: 'UK0030060',
                SAC_AREA: 'SAC_AREA',
                grid_id: 'GRID_REF',
                name_2: 'NAME',
                status: 'STATUS',
                id: 'ID',
                area: 'AREA',
                date: 'GIS_DATE',
                version: 'VERSION',
            };

        case 'special_protection_areas':
            return {
                object_id: 'OBJECTID',
                name: 'N Norfolk Coast',
                code_id: 'UK9009031',
                spa_area: 7862.28765112,
                grid_id: 'GRID_REF',
                name_2: 'NAME',
                status: 'STATUS',
                id: 'ID',
                area: 'AREA',
                date: 'GIS_DATE',
                version: 'VERSION',
            };

        case 'sssi_impact_risk_zones':
            return {
                object_id: 'OBJECTID',
                all_consult: 'AllConsult',
                infrastructure: 'Infrastruc',
                wind_solar: 'WindSolar',
                min_oil_gas: 'MinOilGas',
                rural_non_residential: 'RuralNonRe',
                residential: 'Residentia',
                rural_residential: 'RuralResid',
                air_pollution: 'AirPolluti',
                combustion: 'Combustion',
                waste: 'Waste',
                compost: 'Compost',
                discharge: 'Discharge',
                water_supply: 'Water_Sply',
                notes_1: 'NOTES_1',
                notes_2: 'NOTES_2',
            };

        case 'surface_water_operational_catchments':
            return {
                id: 'OPCAT_ID',
                name: 'OPCAT_NAME',
                management_id: 'MANCAT_ID',
                management: 'MNCAT_NAME',
                district_id: 'RBD_ID',
                district: 'RBD_NAME',
                sub_type: 'sub-type',
            };


        default: {
            return null;
        }
    }
};

export default {
    local_nature_reserves: returnData('local_nature_reserves'),
    lpa: returnData('lpa'),
    national_character_areas: returnData('national_character_areas_england'),
    national_character_areas_england: returnData('national_character_areas_england'),
    national_nature_reserves: returnData('national_nature_reserves'),
    national_parks: returnData('national_parks'),
    potential_special_protection_areas: returnData('potential_special_protection_areas'),
    proposed_ramsar_sites: returnData('proposed_ramsar_sites'),
    ramsar_sites: returnData('ramsar_sites'),
    sites_of_special_scientific_interest_units: returnData('sites_of_special_scientific_interest_units'),
    special_areas_of_conservation: returnData('special_areas_of_conservation'),
    special_protection_areas: returnData('special_protection_areas'),
    sssi_impact_risk_zones: returnData('sssi_impact_risk_zones'),
    surface_water_operational_catchments: returnData('surface_water_operational_catchments'),
};
