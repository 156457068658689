var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "map-filters",
      class: {
        "map-filters-slide": _vm.slide,
        "map-filters-slide--active": _vm.slideActive,
      },
    },
    [
      _vm.slide || _vm.title
        ? _c("HeaderSmall", {
            attrs: {
              title: _vm.title,
              button: {
                svg: "times",
                click: _vm.close,
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "accordion",
        { attrs: { "default-active": _vm.hasGroup("sequest") ? 0 : null } },
        _vm._l(_vm.accordions, function (accordion) {
          return _c(
            "accordion-item",
            { key: accordion.name, attrs: { title: accordion.name } },
            [
              _vm._l(accordion.items, function (layer) {
                return [
                  _c(
                    "div",
                    {
                      key: layer.name,
                      staticClass: "accordion-main",
                      class: {
                        "accordion-main--has-more": true,
                        "accordion-main--expanded": _vm.expanded[layer.name],
                      },
                    },
                    [
                      (layer.items && layer.items.length) ||
                      (layer.options && layer.options.length)
                        ? _c(
                            "button",
                            {
                              staticClass: "accordion-expand",
                              on: {
                                click: (event) =>
                                  _vm.updateExpand(event, layer),
                              },
                            },
                            [
                              _c(
                                "div",
                                [
                                  _c("SvgController", {
                                    attrs: { type: "caret-down" },
                                  }),
                                ],
                                1
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c("FormulateInput", {
                        key: `${layer.name}-input`,
                        attrs: {
                          label: layer.label,
                          name: layer.label,
                          type: "toggle-button",
                          "element-class": "map-filter",
                          color: _vm.getLocalColor(layer),
                          "svg-pattern": _vm.getSvg(layer),
                          disabled:
                            _vm.currentZoom < layer.api_zoom && layer.api
                              ? true
                              : false,
                          "tooltip-help":
                            _vm.currentZoom < layer.api_zoom && layer.api
                              ? "Please zoom into the map to use this layer"
                              : null,
                        },
                        on: {
                          input: (event) => _vm.updateFilter(event, layer),
                        },
                        model: {
                          value: _vm.filters[layer.name],
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, layer.name, $$v)
                          },
                          expression: "filters[layer.name]",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            _vm.expanded[layer.name] &&
                            ((layer.items && layer.items.length) ||
                              (layer.options && layer.options.length)),
                          expression:
                            "expanded[layer.name] && ((layer.items && layer.items.length) || (layer.options && layer.options.length))",
                        },
                      ],
                      key: `${layer.name}-more`,
                      staticClass: "accordion-more",
                    },
                    [
                      _vm._l(layer.items, function (layerInner) {
                        return _c("FormulateInput", {
                          key: `${layer.name}-${layerInner.name}-input`,
                          attrs: {
                            label: `${layerInner.label}`,
                            name: layerInner.label,
                            type: "toggle-button",
                            "element-class": "map-filter",
                            color: _vm.getLocalColor(layerInner),
                            "svg-pattern": _vm.getSvg(layerInner),
                            disabled:
                              _vm.currentZoom < layerInner.api_zoom &&
                              layerInner.api
                                ? true
                                : false,
                            "tooltip-help":
                              _vm.currentZoom < layerInner.api_zoom &&
                              layerInner.api
                                ? "Please zoom into the map to use this layer"
                                : null,
                          },
                          on: {
                            input: (event) =>
                              _vm.updateFilterChild(event, layerInner),
                          },
                          model: {
                            value: _vm.filters[layerInner.name],
                            callback: function ($$v) {
                              _vm.$set(_vm.filters, layerInner.name, $$v)
                            },
                            expression: "filters[layerInner.name]",
                          },
                        })
                      }),
                      _vm._v(" "),
                      layer.options
                        ? [
                            _c(
                              "div",
                              {
                                key: `options-${layer.name}`,
                                staticClass: "accordion-options",
                              },
                              _vm._l(layer.options, function (option) {
                                return _c("FormulateInput", {
                                  key: `option-${option.name}`,
                                  attrs: {
                                    type: "select-plugin",
                                    name: option.name,
                                    label: option.label,
                                    placeholder: option.placeholder || "",
                                    options: option.options,
                                  },
                                  on: {
                                    input: (event) =>
                                      _vm.updateOptions(event, layer, option),
                                  },
                                  model: {
                                    value:
                                      _vm.options[
                                        `${layer.name}_${option.name}`
                                      ],
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.options,
                                        `${layer.name}_${option.name}`,
                                        $$v
                                      )
                                    },
                                    expression:
                                      "options[`${layer.name}_${option.name}`]",
                                  },
                                })
                              }),
                              1
                            ),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                ]
              }),
            ],
            2
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }