<template>
    <modal ref="fileModal">
        <template #header>
            <h3>
                <template v-if="isSigned">
                    Upload signed document for {{ contract.attachments[0].display_name }}
                </template>
                <template v-else>
                    Add Document
                </template>
            </h3>
        </template>
        <template #body>
            <FormulateForm
                v-model="form"
                :schema="scemaForm"
                :errors="form.errors"
                class="flex-col"
                @submit="submitForm"
                @focus-display="displayNameFocus = true"
                @blur-display="displayNameFocus = false"
            />
        </template>
    </modal>
</template>

<script>
import Modal from 'Utilities/modal/Modal';
export default {
    name: 'ContractUpload',
    components: {
        Modal,
    },
    props: {
        show: {
            type: Boolean,
            default: false,
        },
        parent: {
            type: Object,
            default: () => {},
        },
        contract: {
            type: Object,
            default: () => {},
        },
        isSigned: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            form: null,
            displayNameFocus: false,
        };
    },
    computed: {
        scemaForm() {
            const options = [];
            const settings = this.$page.props.settings;

            switch (this.parent.type) {
                case 'project':
                    if (settings['d-head-terms-contract'] && settings['d-head-terms-contract'].value) {
                        options.push('Heads of Terms Contract');
                    }

                    if (settings['d-main-contract'] && settings['d-main-contract'].value) {
                        options.push('Main Contract');
                    }

                    break;
                case 'rental':
                    if (settings['lp-head-terms-contract'] && settings['lp-head-terms-contract'].value) {
                        options.push('Heads of Terms Contract');
                    }

                    if (settings['lp-main-contract'] && settings['lp-main-contract'].value) {
                        options.push('Main Contract');
                    }

                    break;

                default:
                    break;
            }

            options.push('Bespoke Contract');
            const arrReturn = [];

            if (!this.isSigned) {
                arrReturn.push(
                    {
                        name: 'contract_type',
                        label: 'Contract Type',
                        type: 'select-plugin',
                        placeholder: 'Please Select',
                        options: options,
                        required: true,
                    },
                    {
                        name: 'display_name',
                        label: this.displayNameDefualt(),
                        type: 'text',
                        required: false,
                        '@focus': 'focus-display',
                        '@blur': 'blur-display',
                    },
                );
            }

            if (this.isSigned || this.form.contract_type === 'Bespoke Contract') {
                arrReturn.push(
                    {
                        name: 'filepath',
                        label: 'Select File',
                        type: 'file',
                        required: true,
                        accept: '.pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                        validation: 'mime:application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                        uploadUrl: '/attachments/upload',
                        uploadBehavior: 'delayed',
                    },
                );
            }

            arrReturn.push({
                type: 'submit',
            });

            return arrReturn;
        },
    },
    watch: {
        show(value) {
            if (value) {
                this.$refs.fileModal.openModal();
            } else {
                this.$refs.fileModal.closeModal();
            }
        },
    },
    mounted() {
        this.form = this.$inertia.form({
            contract_type: null,
            display_name: null,
            filepath: null,
            is_contract: 1,
            status: null,
            parent_type: this.parent.type,
            parent_id: this.parent.id,
            contract_id: this.contract ? this.contract.id : null,
        });
    },
    methods: {
        openModal() {
            this.$refs.fileModal.openModal();
        },
        closeModal() {
            this.$refs.fileModal.closeModal();
        },
        displayNameDefualt() {
            if (this.form.display_name || this.displayNameFocus) {
                return 'Display Name';
            }

            return `${this.parent.name}-BNG_Partnership${this.form.contract_type ? '-' + this.form.contract_type : ''}`;
        },
        submitForm(data) {

            if (!data.display_name) {
                data.display_name = `${this.parent.name}-BNG_Partnership${data.contract_type ? '-' + data.contract_type : ''}`;
            }

            if (this.isSigned) {
                data.status = 'contract signed';
                data.contract_type = 'Signed Contract';
                data.contract_id = this.contract.id;
            }

            this.$inertia.post(this.$route('attachment.store'), data, {
                onSuccess: () => {
                    this.$refs.fileModal.closeModal();
                    this.$emit('afterUpdate');

                    if (this.parent.type === 'project' && this.isSigned) {
                        this.$emit('afterUpload');
                    }
                },
            });
        },
    },
};
</script>
