var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "app-layout",
    { attrs: { section: "map" } },
    [
      _c(
        "page-header",
        { attrs: { title: "Map", icon: "map-icon" } },
        [
          _c(
            "inertia-link",
            {
              staticClass: "btn-reset btn btn--primary",
              attrs: { href: _vm.$route("habitat.create") },
            },
            [_c("span", [_vm._v("Edit Habitats")])]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "center" }, [
        _c(
          "div",
          { staticClass: "flex relative-height" },
          [
            _c("Sidebar", {
              key: "sidebar",
              attrs: {
                accordions: _vm.accordions,
                "current-zoom": _vm.currentZoom,
              },
              on: { change: _vm.sidebarChange },
              model: {
                value: _vm.filters,
                callback: function ($$v) {
                  _vm.filters = $$v
                },
                expression: "filters",
              },
            }),
            _vm._v(" "),
            _c("Map", {
              attrs: {
                filters: _vm.filters,
                layers: _vm.layersList,
                data: _vm.data,
                "fit-bounds": _vm.operatingIn.length ? true : false,
              },
              on: {
                loaded: (map, zoom) => (_vm.currentZoom = zoom),
                zoomChanged: (zoom) => (_vm.currentZoom = zoom),
                delete: _vm.refreshHabitats,
              },
            }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }