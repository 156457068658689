var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "li",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.show,
          expression: "show",
        },
      ],
      class: _vm.className,
      attrs: { name: _vm.nameSafe },
    },
    [
      _vm.href
        ? _c(
            "inertia-link",
            {
              staticClass: "btn-reset dropdown-menu-list-item-button",
              attrs: {
                href: _vm.href,
                method: _vm.method,
                as: "button",
                type: "button",
              },
            },
            [
              _c(
                "span",
                [
                  _vm.icon
                    ? _c("SvgController", { attrs: { type: _vm.icon } })
                    : _vm._e(),
                  _vm._v("\n            " + _vm._s(_vm.name) + "\n        "),
                ],
                1
              ),
              _vm._v(" "),
              _vm.small ? _c("small", [_vm._v(_vm._s(_vm.small))]) : _vm._e(),
            ]
          )
        : _c(
            "button",
            {
              staticClass: "btn-reset dropdown-menu-list-item-button",
              attrs: { type: "button" },
              on: { click: _vm.onClick },
            },
            [
              _c(
                "span",
                [
                  _vm.icon
                    ? _c("SvgController", { attrs: { type: _vm.icon } })
                    : _vm._e(),
                  _vm._v("\n            " + _vm._s(_vm.name) + "\n        "),
                ],
                1
              ),
              _vm._v(" "),
              _vm.small ? _c("small", [_vm._v(_vm._s(_vm.small))]) : _vm._e(),
            ]
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }