<!-- eslint-disable vue/no-mutating-props -->
<template>
    <datetime
        v-model="context.model"
        :class="`formulate-input-element formulate-input-element--${context.type}`"
        :data-type="context.type"
        v-bind="context.attributes"
        :type="context.attributes.datetype"
        :min-datetime="context.attributes.min"
        :max-datetime="context.attributes.max"
        :format="context.slotProps.component.format"
        :flow="context.slotProps.component.flow"
        :value-zone="valueZone"
        v-on="$listeners"
    />
</template>

<script>
import { Datetime } from 'vue-datetime';
export default {
    components: {
        'datetime': Datetime,
    },
    props: {
        context: {
            type: Object,
            required: true,
        },
    },

    computed: {
        valueZone() {

            if (this.context.slotProps.component.valueZone) {
                return this.context.slotProps.component.valueZone;
            }

            if (process.env.MIX_APP_TIMEZONE) {
                return process.env.MIX_APP_TIMEZONE;
            }

            return 'UTC';
        },
    },
};
</script>
