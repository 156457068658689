<template>
    <div class="pagination">
        <template v-for="(link, key) in links">
            <span
                v-if="link.label === '...'"
                :key="key"
                class="btn"
                disabled
            >
                {{ link.label }}
            </span>

            <inertia-link
                v-else
                :key="key"
                class="btn"
                :class="{
                    'btn--prev': link.label === 'Previous',
                    'btn--next': link.label === 'Next',
                    'btn--active': link.active
                }"
                :href="link.url ? link.url : ''"
                :only="reloadSections"
                :disabled="link.url === null"
                :preserve-state="true"
                :replace="true"
            >
                <SvgController
                    v-if="link.label === 'Previous'"
                    type="arrow-left"
                />
                <span>{{ link.label }}</span>
                <SvgController
                    v-if="link.label === 'Next'"
                    type="arrow-right"
                />
            </inertia-link>
        </template>
    </div>
</template>

<script>
export default {
    components: {},

    props: {
        links: {
            type: Array,
            required: true,
        },
        section: {
            type: [String, Array],
            required: true,
        },
    },

    computed: {
        reloadSections() {
            let section = this.section;

            if (typeof section === 'string') {
                section = [section];
            }

            return section;
        },
    },
};
</script>
