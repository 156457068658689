<!-- eslint-disable vue/no-v-html -->
<template>
    <app-layout section="settings">
        <page-header
            title="Settings"
        />
        <div
            ref="container"
            class="center"
        >
            <TableGridLayout
                :columns="columns"
                :rows="rows"
                :output="['table']"
                :grid-layout="gridLayout"
            >
                <template #cell(value)="{row}">
                    <span
                        v-if="row.type === 'rich-text'"
                        v-html="row.value"
                    />
                    <span v-else-if="row.type === 'upload'">
                        <a
                            v-if="row.value"
                            class="btn--link btn--link--icon--right"
                            :href="$route('settings.download', row.id)"
                            target="_blank"
                        >Download <SvgController type="download" /></a>
                    </span>
                    <span v-else-if="row.type === 'option'">
                        <span
                            v-for="(option, index) in JSON.parse(row.value)"
                            :key="index"
                        >
                            <span>{{ option.option }}</span>
                            <span v-if="index != JSON.parse(row.value).length -1">, </span>
                        </span>
                    </span>
                    <span v-else-if="row.type === 'countries'">
                        {{ row.value.length }} Countries
                    </span>
                    <span v-else-if="row.type === 'date'">
                        {{ formatDate(row.value, row.config.format) }}
                    </span>
                    <span v-else>
                        {{ row.value }}
                    </span>
                </template>
            </TableGridLayout>
        </div>
    </app-layout>
</template>
<script>

import SettingsEdit from './Edit';
import { DateTime } from 'luxon';
import Vue from 'vue';
export default {
    name: 'Settings',

    props: {
        settings: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            columns: [],
            dropdown: [],
            gridLayout: {},
        };
    },
    computed: {
        rows() {
            return this.settings;
        },
    },
    created() {
        this.dropdown = [
            {
                name: 'Edit Setting',
                border: true,
                onClick: (event, row, index) => {
                    if (this.rows[index].type === 'trees') {
                        this.$inertia.visit(this.$route('trees'));

                        return;
                    }

                    this.editSetting(this.rows[index]);
                },
            },
        ];
        this.columns = [
            {
                label: 'Title',
                field: 'title',
                sortable: false,
            },
            {
                label: 'Description',
                field: 'description',
                sortable: false,
            },
            {
                label: 'Value',
                field: 'value',
                sortable: false,
            },
            {
                label: '',
                field: 'dropdown',
                type: 'dropdown',
                sortable: false,
                position: 'right',
                options: this.dropdown,
            },
        ];
        this.gridLayout = {
            title: 'title',
            description: 'description',
            dropdown: this.dropdown,
        };
    },
    methods: {
        editSetting(data) {
            const ComponentClass = Vue.extend(SettingsEdit);
            const instance = new ComponentClass({
                propsData: {
                    data: data,
                },
            });

            instance.$mount();
            this.$refs.container.appendChild(instance.$el);
        },
        formatDate(value, format) {
            return DateTime.fromISO(value).toFormat(format);
        },
    },
};
</script>
