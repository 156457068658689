import { getSVG } from '../defaults/mapThemes';

///Start custom poly fill code
class PolyLineFill {
    constructor(id, poly, map, theme) {
        this.id = id;
        this.poly = poly;
        this.map = map;
        this.theme_ = theme;
        this.type_ = poly.getRadius ? 'circle' : 'poly';
        this.svgns_ = 'http://www.w3.org/2000/svg';

        if (google && poly && map) {
            this.overlayView = new google.maps.OverlayView();

            this.PolyLineFill();

            return;
        }

        // eslint-disable-next-line no-console
        console.error('Unable to find google for PolyLineFill', poly, map, theme);
    }

    PolyLineFill() {
        //initialize all properties.
        this.overlayView.map_ = this.map;
        this.overlayView.div_ = null;
        this.overlayView.poly_ = this.poly;
        this.overlayView.polysvg_ = null;

        // Explicitly call setMap on this overlay
        const _this = this;

        this.overlayView.onAdd = function() {
            _this.onAdd(this);
        };

        this.overlayView.AdjustPoints = function() {
            _this.AdjustPoints(this);
        };

        this.overlayView.draw = function() {
            _this.draw(this);
        };

        this.overlayView.onRemove = function() {
            _this.onRemove(this);
        };

        this.overlayView.setMap(this.map);
    }

    getBounds() {
        if (this.poly.getBounds) {
            return this.poly.getBounds();
        }

        const bounds = new google.maps.LatLngBounds();

        if (this.poly.getPath) {
            const polyPoints = this.poly.getPath().getArray();

            for (let index = 0; index < polyPoints.length; index++) {
                bounds.extend(polyPoints[index]);
            }
        }

        return bounds;
    }

    onAdd(event) {
        // Create the DIV and set some basic attributes.
        const div = document.createElement('div');

        div.style.borderStyle = 'none';
        div.style.borderWidth = '0px';
        div.style.position = 'absolute';
        const svg = this.returnSvg(event);

        div.appendChild(svg);

        // Set the overlay's div_ property to this DIV
        event.div_ = div;
        this.div_ = div;
        event.div_.className = 'polygon';

        // We add an overlay to a map via one of the map's panes.
        // We'll add this overlay to the overlayLayer pane.
        const panes = event.getPanes();

        panes.overlayLayer.appendChild(div);
    }

    AdjustPoints(event) {
        event.bounds_ = this.getBounds();

        //adjust the polygon points based on the projection.
        const proj = event.getProjection();
        const southWest = proj.fromLatLngToDivPixel(event.bounds_.getSouthWest());
        const northEast = proj.fromLatLngToDivPixel(event.bounds_.getNorthEast());

        let points = '',
            polyPoints;


        if (this.type_ === 'circle') {
            const radius = event.poly_.getRadius();
            const center = event.poly_.getCenter();

            polyPoints = [];
            const numSides = 360;
            const degreeStep = 360 / numSides;

            for(let index = 0; index < numSides; index++) {
                const gpos = google.maps.geometry.spherical.computeOffset(center, radius, degreeStep * index);

                polyPoints.push({
                    lng: gpos.lng(),
                    lat: gpos.lat(),
                });
            }

            polyPoints.push(polyPoints[0]);
        } else {
            polyPoints = event.poly_.getPath().getArray();
        }

        for (let index = 0; index < polyPoints.length; index++) {
            const point = proj.fromLatLngToDivPixel(polyPoints[index]);

            if (point) {
                if (index === 0) {
                    points += (point.x - southWest.x) + ', ' + (point.y - northEast.y);
                } else {
                    points += ' ' + (point.x - southWest.x) + ', ' + (point.y - northEast.y);
                }
            }
        }

        return points;
    }

    draw(event) {
        event.bounds_ = this.getBounds();

        // Size and position the overlay. We use a southwest and northeast
        // position of the overlay to peg it to the correct position and size.
        // We need to retrieve the projection from this overlay to do event.
        const overlayProjection = event.getProjection();

        // Retrieve the southwest and northeast coordinates of this overlay
        // in latlngs and convert them to pixels coordinates.
        // We'll use these coordinates to resize the DIV.
        const southWest = overlayProjection.fromLatLngToDivPixel(event.bounds_.getSouthWest());
        const northEast = overlayProjection.fromLatLngToDivPixel(event.bounds_.getNorthEast());

        // Resize the image's DIV to fit the indicated dimensions.
        const div = event.div_;

        div.style.left = southWest.x + 'px';
        div.style.top = northEast.y + 'px';
        div.style.width = (northEast.x - southWest.x) + 'px';
        div.style.height = (southWest.y - northEast.y) + 'px';
        const points = this.AdjustPoints(event);

        event.path_.setAttributeNS(null, 'd', 'M' + points + 'z');
        event.pathBg_.setAttributeNS(null, 'd', 'M' + points + 'z');
    }

    onRemove(event) {
        if (event.div_) {
            event.onAdd = () => {};

            event.AdjustPoints = () => {};

            event.draw = () => {};

            event.onRemove = () => {};

            event.setMap(null);

            event.div_.remove();
            event.div_ = null;
        }
    }

    remove() {
        this.onRemove(this.overlayView);
    }

    returnSvg(event) {
        const svg = getSVG(this.theme_, this.id);

        if (svg) {
            event.path_ = svg.path;
            event.pathBg_ = svg.pathBg;
        }

        return svg.svg;
    }
}

export default PolyLineFill;
