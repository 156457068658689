var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.rentals.length > 0
        ? _c("TableGridLayout", {
            attrs: {
              columns: _vm.columns,
              rows: _vm.rentals,
              mode: "remote",
              "total-records": _vm.rentals.length,
              "grid-layout": _vm.gridLayout,
              output: ["table"],
            },
            on: { onRowClick: _vm.onRowClick },
          })
        : _c(
            "Empty",
            { attrs: { type: "agreements" } },
            [
              _vm.hasGroup(["sequest", "lpa"]) &&
              _vm.hasPermission("rental_save")
                ? _c(
                    "inertia-link",
                    {
                      staticClass: "btn-reset btn btn--primary",
                      attrs: {
                        href: _vm.$route(
                          "rental.create",
                          _vm.$page.props.plot.id
                        ),
                      },
                    },
                    [_vm._v("\n            Create BNG Agreement\n        ")]
                  )
                : _vm._e(),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }