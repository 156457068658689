var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "app-layout",
    { attrs: { section: "planning-applications" } },
    [
      _c(
        "page-header",
        {
          attrs: {
            title: "Planning Application Details",
            icon: "files",
            back: {
              url: _vm.$route("planningApplications"),
              text: "Planning Applications",
            },
          },
        },
        [
          _c(
            "button",
            {
              staticClass: "btn-reset btn btn--primary",
              on: { click: _vm.edit },
            },
            [_vm._v("\n            Edit BNG Details\n        ")]
          ),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "center details" }, [
        _c("h5", [_vm._v("Application Name")]),
        _vm._v(" "),
        _c("div", { staticClass: "details-header" }, [
          _c("h3", [_vm._v(_vm._s(_vm.application.name))]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "form-flex--lap" }, [
          _c("div", [
            _c("div", { staticClass: "details-block details-bg" }, [
              _c("div", { staticClass: "formulate-heading" }, [
                _c("h6", [_vm._v("Application Details")]),
              ]),
              _vm._v(" "),
              _c("h5", [_vm._v("Reference")]),
              _vm._v(" "),
              _c("p", [_vm._v(_vm._s(_vm.application.reference))]),
              _vm._v(" "),
              _c("h5", [_vm._v("Alternative Reference")]),
              _vm._v(" "),
              _c("p", [_vm._v(_vm._s(_vm.application.alternative_reference))]),
              _vm._v(" "),
              _c("h5", [_vm._v("Date Application Received")]),
              _vm._v(" "),
              _c("p", [_vm._v(_vm._s(_vm.application.date_received))]),
              _vm._v(" "),
              _c("h5", [_vm._v("Address")]),
              _vm._v(" "),
              _c("p", [_vm._v(_vm._s(_vm.application.address))]),
              _vm._v(" "),
              _c("h5", [_vm._v("Status")]),
              _vm._v(" "),
              _c("p", [_vm._v(_vm._s(_vm.application.status))]),
              _vm._v(" "),
              _c("h5", [_vm._v("Environmental Assessment Requested")]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  _vm._s(_vm.application.environmental_assessment_requested)
                ),
              ]),
              _vm._v(" "),
              _c("h4", [
                _c(
                  "a",
                  { attrs: { href: _vm.application.url, target: "_blank" } },
                  [_vm._v("Planning application Link")]
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "details-block details-bg" }, [
              _c("div", { staticClass: "formulate-heading" }, [
                _c("h6", [_vm._v("Appeal Details")]),
              ]),
              _vm._v(" "),
              _c("h5", [_vm._v("Appeal Status")]),
              _vm._v(" "),
              _c("p", [_vm._v(_vm._s(_vm.application.appeal_status))]),
              _vm._v(" "),
              _c("h5", [_vm._v("Appeal Decision")]),
              _vm._v(" "),
              _c("p", [_vm._v(_vm._s(_vm.application.appeal_decision))]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "details-block details-bg" }, [
              _c("div", { staticClass: "formulate-heading" }, [
                _c("h6", [_vm._v("Applicant")]),
              ]),
              _vm._v(" "),
              _c("h5", [_vm._v("Applicant Name")]),
              _vm._v(" "),
              _c("p", [_vm._v(_vm._s(_vm.application.applicant_name))]),
              _vm._v(" "),
              _c("h5", [_vm._v("Applicant Address")]),
              _vm._v(" "),
              _c("p", [_vm._v(_vm._s(_vm.application.applicant_address))]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", [
            _c("div", { staticClass: "details-block" }, [
              _c("div", { staticClass: "formulate-heading" }, [
                _c("h6", [_vm._v("BNG Details")]),
              ]),
              _vm._v(" "),
              _c("h5", [_vm._v("Bng Status")]),
              _vm._v(" "),
              _c("p", [_vm._v(_vm._s(_vm.application.bng_status))]),
              _vm._v(" "),
              _c("h5", [_vm._v("Bng Notes")]),
              _vm._v(" "),
              _c("p", {
                domProps: { innerHTML: _vm._s(_vm.application.bng_notes) },
              }),
              _vm._v(" "),
              _c("h5", [_vm._v("Bng Next Action Date")]),
              _vm._v(" "),
              _c("p", [_vm._v(_vm._s(_vm.application.bng_next_action_date))]),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {},
              [
                _c("div", { staticClass: "formulate-heading" }, [
                  _c("h6", [_vm._v("Documents")]),
                ]),
                _vm._v(" "),
                _c("files", {
                  attrs: {
                    files: _vm.application.documents,
                    filetype: "document",
                    "item-type": "planning application",
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("modal", {
        ref: "editModal",
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function () {
              return [_c("h3", [_vm._v("Edit BNG Details")])]
            },
            proxy: true,
          },
          {
            key: "body",
            fn: function () {
              return [
                _c("FormulateForm", {
                  staticClass: "flex-col",
                  attrs: { errors: _vm.form.errors, schema: _vm.formSchema },
                  on: { submit: _vm.submitForm },
                  model: {
                    value: _vm.form,
                    callback: function ($$v) {
                      _vm.form = $$v
                    },
                    expression: "form",
                  },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }