<template>
    <app-layout section="plots">
        <page-header
            :title="title"
            icon="plots"
            :count="plots.total"
        >
            <inertia-link
                v-if="$page.props.loggedUser.can.includes('plot_save') && !$page.props.loggedUser.isLPA"
                class="btn-reset btn btn--primary"
                :href="$route('plot.create')"
            >
                <SvgController type="plus" />
                <span>New Land Parcel</span>
            </inertia-link>
            <template #page-header-bottom>
                <div class="center">
                    <div
                        v-if="hasGroup(['sequest', 'lpa'])"
                        class="tile-wrap flex-space-between"
                    >
                        <tile
                            :href="$route('rentals')"
                            title="All BNG Agreements"
                            icon="plots"
                            :count="rentals.length"
                            color="#5EBD8A"
                        />
                        <tile
                            :href="$route('rentals', {latest_update_status: 'awaiting review'})"
                            title="BNG Agreements Awaiting Review"
                            icon="file-info"
                            :count="rentalsAwaitingReview"
                            color="#1782ff"
                        />
                        <tile
                            :href="$route('rentals', {latest_update_status: 'awaiting payment'})"
                            title="BNG Agreements Awaiting Payment"
                            icon="card"
                            :count="rentalsAwaitingPayment"
                            color="#E47D44"
                        />
                        <tile
                            :href="$route('rentals', {update_overdue: 'true'})"
                            title="BNG Agreements Overdue Updates"
                            icon="bell"
                            :count="rentalsOverdue"
                            color="#E34B5D"
                        />
                    </div>
                </div>
            </template>
        </page-header>
        <div class="center">
            <plot-table
                :plots="plots"
                :filter-route="filterRoute"
                :force-hide-company="isLandownerPage"
            />
        </div>
    </app-layout>
</template>
<script>
import Tile from 'Components/Dashboard/Tile';
import PlotTable from './Table';
export default {
    name: 'Plots',
    components: {
        PlotTable,
        Tile,
    },

    props: {
        plots: {
            type: Object,
            default: () => ({}),
        },
        rentals: {
            type: Array,
            default: () => ([]),
        },
        hasItems: {
            type: Boolean,
            required: true,
        },
        companyName: {
            type: String,
            default: null,
        },
    },
    computed: {
        filterRoute() {
            if (this.isLandownerPage) {
                return this.$route('landowner.plots', this.$route().params.landowner);
            }

            return this.$route('plots');
        },

        isLandownerPage() {
            return this.$route().current('landowner.plots');
        },

        title() {
            let title = '';

            if (this.companyName) {
                title = `${this.companyName} - `;
            }

            title += 'Land Parcels';

            return title;
        },
        rentalsAwaitingReview() {
            const filtered = this.rentals.filter(rental => {
                if (rental.latest_update) {
                    return rental.latest_update.status === 'awaiting review';
                }
            });

            return filtered.length;
        },
        rentalsAwaitingPayment() {
            const filtered = this.rentals.filter(rental => {
                if (rental.latest_update) {
                    return rental.latest_update.status === 'awaiting payment';
                }
            });

            return filtered.length;
        },
        rentalsOverdue() {
            const filtered = this.rentals.filter(rental => {
                return rental.update_overdue === 1;
            });

            return filtered.length;
        },
    },
};
</script>
