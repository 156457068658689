<template>
    <app-layout section="dashboard">
        <page-header
            :title="headerTitle"
            :icon="headerIcon"
        >
            <inertia-link
                v-if="show.includes('buttonProject')"
                href="$route('project.create')"
                class="btn btn--primary"
            >
                <SvgController type="plus" />
                <span>New Development</span>
            </inertia-link>
            <inertia-link
                v-if="show.includes('buttonPlot')"
                :href="$route('plot.create')"
                class="btn btn--primary"
            >
                <SvgController type="plus" />
                <span>New Land Parcel</span>
            </inertia-link>
            <div v-if="show.includes('buttonCompany') && hasGroup(['sequest'])">
                <a
                    href="#"
                    class="btn btn--primary"
                    @click="showCompanyModal"
                >Edit Company</a>
                <modal ref="companyModal">
                    <template #header>
                        <h3>Edit Company</h3>
                    </template>
                    <template #body>
                        <CompanyEdit
                            :company="company"
                            :company-type="companyType"
                            @closeModal="closeCompanyModal"
                        />
                    </template>
                </modal>
            </div>
            <template #page-header-bottom>
                <div class="center">
                    <div
                        v-if="!superuserDashboard"
                        class="tile-wrap flex-space-between"
                    >
                        <tile
                            v-if="show.includes('landownersTotal')"
                            :href="landownersLink"
                            title="Landowners"
                            icon="briefcase"
                            :count="!isNaN(landowners && landowners.total) ? landowners.total : 0"
                            color="#E34B5D"
                        />
                        <tile
                            v-if="show.includes('projectsTotal')"
                            :href="projectsLink"
                            title="Total Developments"
                            icon="file-tick"
                            :count="!isNaN(projects && projects.total) ? projects.total : 0"
                            color="#5EBD8A"
                        />
                        <tile
                            v-if="show.includes('projectsPending')"
                            :href="projectsLink"
                            title="Pending Developments"
                            icon="file-info"
                            :count="!isNaN(projects && projects.pending) ? projects.pending : 0"
                            color="#E47D44"
                        />
                        <tile
                            v-if="show.includes('projectsCompleted')"
                            :href="projectsLink"
                            title="Completed Developments"
                            icon="file-tick"
                            :count="!isNaN(projects && projects.completed) ? projects.completed : 0"
                            color="#5EBD8A"
                        />
                        <tile
                            v-if="show.includes('plotsTotal')"
                            :href="plotsLink"
                            title="Total Land Parcels"
                            icon="plots"
                            :count="!isNaN(plots && plots.total) ? plots.total : 0"
                            color="#5EBD8A"
                        />

                        <tile
                            v-if="show.includes('propertyDevelopersTotal')"
                            :href="propertyDevelopersLink"
                            title="Property Devs"
                            icon="building"
                            :count="!isNaN(propertyDevelopers && propertyDevelopers.total) ? propertyDevelopers.total : 0"
                            color="#1782FF"
                        />
                        <tile
                            v-if="show.includes('projectsActive')"
                            :href="projectsLink"
                            title="Active Developments"
                            icon="files"
                            :count="!isNaN(projects && projects.total) ? projects.total : 0"
                            color="#6967D5"
                        />
                        <tile
                            v-if="show.includes('totalCredits')"
                            title="Total Credits"
                            icon="hex"
                            :count="!isNaN(plots && plots.totalCredit) ? plots.totalCredit : 0"
                            color="#6967D5"
                        />
                        <tile
                            v-if="show.includes('availableCredits')"
                            title="Available Credits"
                            icon="hex-tick"
                            :count="!isNaN(plots && plots.availableCredit) ? plots.availableCredit : 0"
                            color="#5EBD8A"
                        />
                        <tile
                            v-if="show.includes('rentedCredits')"
                            title="Implemented Credits"
                            icon="hex-sketch"
                            :count="!isNaN(plots && plots.rentedCredit) ? plots.rentedCredit : 0"
                            color="#E47D44"
                        />
                    </div>
                    <div v-else>
                        <div class="tile-wrap tile-wrap-top flex-space-between">
                            <tile
                                :href="landownersLink"
                                title="Landowners"
                                icon="briefcase"
                                :count="!isNaN(landowners && landowners.total) ? landowners.total : 0"
                                color="#E34B5D"
                            />
                            <tile
                                :href="plotsLink"
                                title="Total Land Parcels"
                                icon="plots"
                                :count="!isNaN(plots && plots.total) ? plots.total : 0"
                                color="#5EBD8A"
                            />
                            <tile
                                title="Total Available Units"
                                icon="hex"
                                :count="!isNaN(plots && plots.availableCredit) ? plots.availableCredit : 0"
                                color="#6967D5"
                            />
                            <tile
                                title="Total Hectares"
                                icon="hex-tick"
                                :count="!isNaN(plots && plots.totalHectares) ? plots.totalHectares : 0"
                                color="#5EBD8A"
                            />
                        </div>
                        <!-- Row 2 -->
                        <div class="tile-wrap flex-space-between">
                            <tile
                                :href="propertyDevelopersLink"
                                title="Property Devs"
                                icon="building"
                                :count="!isNaN(propertyDevelopers && propertyDevelopers.total) ? propertyDevelopers.total : 0"
                                color="#1782FF"
                            />
                            <tile
                                :href="projectsLink"
                                title="Total Developments"
                                icon="files"
                                :count="!isNaN(projects && projects.total) ? projects.total : 0"
                                color="#6967D5"
                            />
                            <tile
                                title="Total Required Units"
                                icon="hex-tick"
                                :count="!isNaN(projects && projects.totalRequiredUnits) ? projects.totalRequiredUnits : 0"
                                color="#5EBD8A"
                            />
                            <tile
                                title="Total Units Offset"
                                icon="hex-tick"
                                :count="!isNaN(projects && projects.totalUnitsOffset) ? projects.totalUnitsOffset : 0"
                                color="#5EBD8A"
                            />
                        </div>
                    </div>
                </div>
            </template>
        </page-header>
        <div class="center dashboard">
            <template v-if="show.includes('propertyDevelopers')">
                <div class="dashboard-heading flex-space-between">
                    <h6>Property Developers</h6>
                    <div class="dashboard-heading-buttons">
                        <inertia-link
                            :href="propertyDevelopersLink"
                            class="btn-reset btn--link btn--link--icon--right"
                        >
                            All Property Developers
                            <SvgController type="arrow-right" />
                        </inertia-link>
                    </div>
                </div>
                <template v-if="propertyDevelopers && propertyDevelopers.total">
                    <property-developers-table
                        :property-developers="propertyDevelopers.propertyDevelopers"
                        :allow-sorting="false"
                        :has-header="false"
                        :has-footer="false"
                        :has-dropdown="false"
                    />
                </template>
                <template v-else>
                    <p>No Property Developers</p>
                </template>
            </template>

            <template v-if="show.includes('projects') && projects && projects.projects.data">
                <div class="dashboard-heading flex-space-between">
                    <h6>Latest Developments</h6>
                    <div class="dashboard-heading-buttons">
                        <inertia-link
                            :href="projectsLink"
                            class="btn-reset btn--link btn--link--icon--right"
                        >
                            All Developments
                            <SvgController type="arrow-right" />
                        </inertia-link>
                        <inertia-link
                            v-if="show.includes('buttonNew')"
                            :href="$route('project.create')"
                            class="btn-reset btn--link btn--link--green btn--link--icon--left"
                            as="button"
                            type="button"
                        >
                            <SvgController type="plus" />
                            New Development
                        </inertia-link>
                    </div>
                </div>
                <template v-if="projects.projects.data.length">
                    <project-table
                        :projects="projects.projects"
                        :statuses="$page.props.statuses"
                        :allow-sorting="false"
                        :has-header="false"
                        :has-footer="false"
                        :has-dropdown="false"
                        :force-hide-company="section !== 'dashboard'"
                    />
                </template>
                <template v-else>
                    <p>No Developments Created</p>
                </template>
            </template>

            <template v-if="show.includes('landowners')">
                <div class="dashboard-heading flex-space-between">
                    <h6>Landowners</h6>
                    <div class="dashboard-heading-buttons">
                        <inertia-link
                            :href="landownersLink"
                            class="btn-reset btn--link btn--link--icon--right"
                        >
                            All Landowners
                            <SvgController type="arrow-right" />
                        </inertia-link>
                    </div>
                </div>
                <template v-if="landowners && landowners.total">
                    <landowner-table
                        :landowners="landowners.landowners"
                        :allow-sorting="false"
                        :has-header="false"
                        :has-footer="false"
                        :has-dropdown="false"
                    />
                </template>
                <template v-else>
                    <p>No Landowners</p>
                </template>
            </template>

            <template v-if="show.includes('plots')">
                <div class="dashboard-heading flex-space-between">
                    <h6>Latest Land Parcels</h6>
                    <div class="dashboard-heading-buttons">
                        <inertia-link
                            :href="plotsLink"
                            class="btn-reset btn--link btn--link--icon--right"
                        >
                            All Land Parcels
                            <SvgController type="arrow-right" />
                        </inertia-link>
                        <inertia-link
                            v-if="show.includes('buttonNew')"
                            :href="$route('plot.create')"
                            class="btn-reset btn--link btn--link--green btn--link--icon--left"
                            as="button"
                            type="button"
                        >
                            <SvgController type="plus" />
                            New Land Parcel
                        </inertia-link>
                    </div>
                </div>
                <template v-if="plots && plots.plots.data.length">
                    <plot-table
                        :plots="plots.plots"
                        :statuses="$page.props.statuses"
                        :allow-sorting="false"
                        :has-header="false"
                        :has-footer="false"
                        :has-dropdown="false"
                        :force-hide-company="section !== 'dashboard'"
                    />
                </template>
                <template v-else>
                    <p>No Land Parcels Created</p>
                </template>
            </template>
            <template v-if="show.includes('reports') && reports && reports.data && reports.data.length">
                <div class="dashboard-heading flex-space-between">
                    <h6>Latest Reports</h6>
                    <div class="dashboard-heading-buttons">
                        <inertia-link
                            :href="$route('reports')"
                            class="btn-reset btn--link btn--link--icon--right"
                            as="button"
                            type="button"
                        >
                            All Reports
                            <SvgController type="arrow-right" />
                        </inertia-link>
                    </div>
                </div>
                <template v-if="reports.data.length">
                    <reports-table
                        :list="reports.data"
                        :has-header="false"
                    />
                </template>
                <template v-else>
                    <p>No Reports Found</p>
                </template>
            </template>
        </div>
    </app-layout>
</template>
<script>
import Tile from 'Components/Dashboard/Tile';
import ProjectTable from './projects/Table';
import PlotTable from './plots/Table';
import ReportsTable from './reports/Table';
import LandownerTable from './landowners/Table';
import PropertyDevelopersTable from './property-developers/Table';

import Modal from 'Utilities/modal/Modal';
import CompanyEdit from './profile/CompanyEdit';
export default {
    name: 'Dashboard',
    components: {
        Tile,
        ProjectTable,
        PlotTable,
        ReportsTable,
        LandownerTable,
        PropertyDevelopersTable,
        CompanyEdit,
        Modal,
    },
    props: {
        show: {
            type: Array,
            default: () => ([]),
        },
        projects: {
            type: Object,
            default: null,
        },
        plots: {
            type: Object,
            default: null,
        },
        reports: {
            type: Object,
            default: null,
        },
        propertyDevelopers: {
            type: Object,
            default: null,
        },
        landowners: {
            type: Object,
            default: null,
        },
        credits: {
            type: Object,
            default: null,
        },
        headerTitle: {
            type: String,
            default: 'Dashboard',
        },
        headerIcon: {
            type: String,
            default: 'dashboard',
        },
        section: {
            type: String,
            default: 'dashboard',
        },
        companyId: {
            type: Number,
            default: null,
        },
        superuserDashboard: {
            type: Boolean,
            default: false,
        },
        company: {
            type: Object,
            default: () => ({}),
        },
        companyType: {
            type: String,
            default: '',
        },
    },
    computed: {
        plotsLink() {
            if (this.companyId) {
                return this.$route('landowner.plots', this.companyId);
            }

            return this.$route('plots');
        },
        projectsLink() {
            if (this.companyId) {
                return this.$route('propertyDeveloper.projects', this.companyId);
            }

            return this.$route('projects');
        },

        landownersLink() {
            if (this.companyId) {
                return this.$route('agent.landowners', this.companyId);
            }

            return this.$route('landowners');
        },
        propertyDevelopersLink() {
            if (this.companyId) {
                return this.$route('agent.propertyDevelopers', this.companyId);
            }

            return this.$route('propertyDevelopers');
        },
    },

    methods: {
        showCompanyModal() {
            this.$refs.companyModal.openModal();
        },
        closeCompanyModal() {
            this.$refs.companyModal.closeModal();
        },
    },
};
</script>
