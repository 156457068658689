var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("modal", {
    ref: "editModal",
    attrs: { width: "50em" },
    scopedSlots: _vm._u([
      {
        key: "header",
        fn: function () {
          return [_c("h3", [_vm._v("\n            Edit Contract\n        ")])]
        },
        proxy: true,
      },
      {
        key: "body",
        fn: function () {
          return [
            _c("FormulateForm", {
              staticClass: "flex-col",
              attrs: { schema: _vm.scemaForm, errors: _vm.form.errors },
              on: {
                submit: _vm.submitForm,
                "focus-display": function ($event) {
                  _vm.displayNameFocus = true
                },
                "blur-display": function ($event) {
                  _vm.displayNameFocus = false
                },
              },
              model: {
                value: _vm.form,
                callback: function ($$v) {
                  _vm.form = $$v
                },
                expression: "form",
              },
            }),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }