var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "app-layout",
    { attrs: { section: "dashboard" } },
    [
      _c("page-header", {
        attrs: { title: "Dashboard", icon: "dashboard" },
        scopedSlots: _vm._u([
          {
            key: "page-header-bottom",
            fn: function () {
              return [
                _c("div", { staticClass: "center" }, [
                  _c(
                    "div",
                    {
                      staticClass: "tile-wrap tile-wrap-top flex-space-between",
                    },
                    [
                      _c("tile", {
                        attrs: {
                          href: _vm.$route("plots"),
                          title: "Total Net Gain Sites",
                          icon: "plots",
                          count: !isNaN(_vm.plots && _vm.plots.total)
                            ? _vm.plots.total
                            : 0,
                          color: "#5EBD8A",
                        },
                      }),
                      _vm._v(" "),
                      _c("tile", {
                        attrs: {
                          href: _vm.$route("plots", {
                            "lease-status": "agreement started",
                          }),
                          title: "Implemented Net Gain Sites",
                          icon: "plots",
                          count: _vm.implementedSites,
                          color: "#5EBD8A",
                        },
                      }),
                      _vm._v(" "),
                      _c("tile", {
                        attrs: {
                          href: _vm.$route("plots", {
                            status: "BNG Units available",
                          }),
                          title: "Available Net Gain Sites",
                          icon: "plots",
                          count: _vm.availableSites,
                          color: "#5EBD8A",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "tile-wrap tile-wrap-top flex-space-between",
                    },
                    [
                      _c("tile", {
                        attrs: {
                          href: _vm.$route("plots", {
                            sortby: "units",
                            sortdir: "DESC",
                          }),
                          title: "Total BNG units",
                          icon: "plots",
                          count: _vm.totalUnits,
                          color: "#5EBD8A",
                        },
                      }),
                      _vm._v(" "),
                      _c("tile", {
                        attrs: {
                          href: _vm.$route("rentals"),
                          title: "BNG units sold",
                          icon: "plots",
                          count: _vm.soldUnits,
                          color: "#5EBD8A",
                        },
                      }),
                      _vm._v(" "),
                      _c("tile", {
                        attrs: {
                          href: _vm.$route("plots", {
                            status: "BNG Units available",
                          }),
                          title: "BNG units available",
                          icon: "plots",
                          count: _vm.availableUnits,
                          color: "#5EBD8A",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "link-wrap flex-space-between" },
                    [
                      _c("dashboard-link", {
                        attrs: {
                          href: _vm.$route("plots", {
                            status: "Identified land",
                          }),
                          title: "Identified land",
                          count: _vm.identifiedLand,
                        },
                      }),
                      _vm._v(" "),
                      _c("dashboard-link", {
                        attrs: {
                          href: _vm.$route("plots", {
                            status: "Ecological Study Certified",
                          }),
                          title: "Ecological Study Certified",
                          count: _vm.ecologicalStudyCertified,
                          color: "#6967D5",
                        },
                      }),
                      _vm._v(" "),
                      _c("dashboard-link", {
                        attrs: {
                          href: _vm.$route("plots", {
                            status: "Pending contracts",
                          }),
                          title: "Pending contracts",
                          count: _vm.pendingContracts,
                          color: "#E47D44",
                        },
                      }),
                      _vm._v(" "),
                      _c("dashboard-link", {
                        attrs: {
                          href: _vm.$route("plots", {
                            status: "Completed contracts",
                          }),
                          title: "Completed contracts",
                          count: _vm.completedContracts,
                          color: "#1782FF",
                        },
                      }),
                      _vm._v(" "),
                      _c("dashboard-link", {
                        attrs: {
                          href: _vm.$route("plots", {
                            status: "BNG Units available",
                          }),
                          title: "BNG Units available",
                          count: _vm.availableSites,
                          color: "#5EBD8A",
                        },
                      }),
                      _vm._v(" "),
                      _c("dashboard-link", {
                        attrs: {
                          href: _vm.$route("plots", {
                            status: "Land Fully Leased",
                          }),
                          title: "Land Fully Implemented",
                          count: _vm.landFullyLeased,
                          "show-icon": false,
                          "show-count": false,
                          color: "#E34B5D",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "center dashboard" },
        [
          _vm.show.includes("projects") &&
          _vm.projects &&
          _vm.projects.projects.data
            ? [
                _c(
                  "div",
                  { staticClass: "dashboard-heading flex-space-between" },
                  [
                    _c("h6", [_vm._v("Latest Developments")]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "dashboard-heading-buttons" },
                      [
                        _c(
                          "inertia-link",
                          {
                            staticClass:
                              "btn-reset btn--link btn--link--icon--right",
                            attrs: { href: _vm.$route("projects") },
                          },
                          [
                            _vm._v(
                              "\n                        All Developments\n                        "
                            ),
                            _c("SvgController", {
                              attrs: { type: "arrow-right" },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm.show.includes("buttonNew")
                          ? _c(
                              "inertia-link",
                              {
                                staticClass:
                                  "btn-reset btn--link btn--link--green btn--link--icon--left",
                                attrs: {
                                  href: _vm.$route("project.create"),
                                  as: "button",
                                  type: "button",
                                },
                              },
                              [
                                _c("SvgController", {
                                  attrs: { type: "plus" },
                                }),
                                _vm._v(
                                  "\n                        New Development\n                    "
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _vm.projects.projects.data.length
                  ? [
                      _c("project-table", {
                        attrs: {
                          projects: _vm.projects.projects,
                          statuses: _vm.$page.props.statuses,
                          "allow-sorting": false,
                          "has-header": false,
                          "has-footer": false,
                          "has-dropdown": false,
                          "force-hide-company": _vm.section !== "dashboard",
                        },
                      }),
                    ]
                  : [_c("p", [_vm._v("No Developments Created")])],
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.show.includes("plots")
            ? [
                _c(
                  "div",
                  { staticClass: "dashboard-heading flex-space-between" },
                  [
                    _c("h6", [_vm._v("Latest Land Parcels")]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "dashboard-heading-buttons" },
                      [
                        _c(
                          "inertia-link",
                          {
                            staticClass:
                              "btn-reset btn--link btn--link--icon--right",
                            attrs: { href: _vm.$route("plots") },
                          },
                          [
                            _vm._v(
                              "\n                        All Land Parcels\n                        "
                            ),
                            _c("SvgController", {
                              attrs: { type: "arrow-right" },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm.show.includes("buttonNew")
                          ? _c(
                              "inertia-link",
                              {
                                staticClass:
                                  "btn-reset btn--link btn--link--green btn--link--icon--left",
                                attrs: {
                                  href: _vm.$route("plot.create"),
                                  as: "button",
                                  type: "button",
                                },
                              },
                              [
                                _c("SvgController", {
                                  attrs: { type: "plus" },
                                }),
                                _vm._v(
                                  "\n                        New Land Parcel\n                    "
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _vm.plots && _vm.plots.plots.data.length
                  ? [
                      _c("plot-table", {
                        attrs: {
                          plots: _vm.plots.plots,
                          statuses: _vm.$page.props.statuses,
                          "allow-sorting": false,
                          "has-header": false,
                          "has-footer": false,
                          "has-dropdown": false,
                          "force-hide-company": _vm.section !== "dashboard",
                        },
                      }),
                    ]
                  : [_c("p", [_vm._v("No Land Parcels Created")])],
              ]
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }