import { render, staticRenderFns } from "./Habitats.vue?vue&type=template&id=79150cc8&"
import script from "./Habitats.vue?vue&type=script&lang=js&"
export * from "./Habitats.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/amazon-livesequesteco/amazon-live.sequest.eco/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('79150cc8')) {
      api.createRecord('79150cc8', component.options)
    } else {
      api.reload('79150cc8', component.options)
    }
    module.hot.accept("./Habitats.vue?vue&type=template&id=79150cc8&", function () {
      api.rerender('79150cc8', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/views/plots/Habitats.vue"
export default component.exports