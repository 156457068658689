<template>
    <app-layout section="plots">
        <page-header
            :title="pageTitle"
            icon="plots"
            :background="false"
        >
            <inertia-link
                class="btn-reset btn"
                :href="cancelRoute"
            >
                Cancel
            </inertia-link>
        </page-header>
        <FormulateForm
            ref="form"
            v-model="form"
            :errors="$page.props.errors"
            class="formulate-form--plot flex-col"
            :keep-model-data="true"
            @submit="submitForm"
        >
            <PagerContent
                ref="pager"
                class="center"
                :errors="pageErrors"
                :formulate="$refs.form"
                :formulate-errors="$page.props.errors"
                theme="bng"
                :validation="{
                    current_habitats: () => form.offset_type.includes('bng') || (form.offset_type.includes('carbon') && !form.study) ? validateCustomHabitats('current_habitats') : true,
                    potential_habitats: () => validateCustomHabitats('potential_habitats'),
                }"
                :pagination="{
                    type: 'nav',
                }"
            >
                <PagerPage
                    title="Land Parcel Details"
                >
                    <FormPage1
                        v-model="form"
                        :disabled="!editable"
                        :companies="companies"
                        :countries="countries"
                        :planning-authorities="planningAuthorities"
                        :river-basin-districts="riverBasinDistricts"
                        :editable="editable"
                    />
                </PagerPage>
                <PagerPage
                    name="nutrient_offset"
                    title="Nutrient Offset"
                    :enabled="form.offset_type.includes('nutrient_offset')"
                >
                    <FormPage2
                        ref="nutrient_offset"
                        v-model="form"
                        :edit="edit"
                        :animal-settings="animalSettings"
                        :planning-authorities="planningAuthorities"
                        :river-basin-districts="riverBasinDistricts"
                        :disabled="!editable"
                    />
                </PagerPage>
                <PagerPage
                    name="current_habitats"
                    title="Current Habitats"
                    description="This page is to enter all current habitat data."
                    :enabled="form.offset_type.includes('bng')"
                >
                    <FormPage3
                        ref="current_habitats"
                        v-model="form"
                        :edit="edit"
                        :disabled="!editable"
                        @change="currentHabitatChange"
                    />
                </PagerPage>
                <PagerPage
                    name="potential_habitats"
                    title="Potential Habitats"
                    description="This page is to enter all habitat data that will be used on site."
                    :enabled="!form.study && form.offset_type.includes('bng')"
                >
                    <FormPage4
                        ref="potential_habitats"
                        v-model="form"
                        :disabled="!editable"
                        :enabled="form.country_code !== null"
                        @change="potentialHabitatChange"
                    />
                </PagerPage>
                <template #pagination-prev-button>
                    <SvgController type="arrow-left" />
                    <span>Previous</span>
                </template>
                <template #pagination-next-button>
                    <span>Next</span>
                    <SvgController type="arrow-right" />
                </template>
                <template #pagination-next-last>
                    <button
                        class="btn-reset btn btn--primary"
                        @click.prevent="submitFormButton"
                    >
                        <SvgController
                            v-if="!edit"
                            type="tick"
                        />
                        <span>{{ buttonText }}</span>
                    </button>
                </template>
            </PagerContent>
        </FormulateForm>
    </app-layout>
</template>
<script>
import FormPage1 from './form/FormPage1';
import FormPage2 from './form/FormPage2';
import FormPage3 from './form/FormPage3';
import FormPage4 from './form/FormPage4';

import { PagerContent, PagerPage } from 'vue2-pager';

export default {
    name: 'PlotForm',
    components: {
        FormPage1,
        FormPage2,
        FormPage3,
        FormPage4,
        PagerContent,
        PagerPage,
    },
    props: {
        edit: {
            type: Boolean,
            default: false,
        },
        plot: {
            type: Object,
            default: ()=>({}),
        },
        companies: {
            type: Array,
            default: () => [],
        },
        countries: {
            type: Array,
            default: () => ([]),
        },
        planningAuthorities: {
            type: Array,
            default: () => ([]),
        },
        riverBasinDistricts: {
            type: Array,
            default: () => ([]),
        },
    },
    data() {
        const offsetTypes = this.edit && this.plot.offset_type ? this.plot.offset_type.map(offset => offset.name) : [];
        const animalSettings = {
            'Dairy': {
                label: 'dairy cows',
                min: 0,
                max: 1000,
                inBetween: 100,
                allowMore: true,
            },
            'Beef': {
                label: 'beef cows',
                min: 0,
                max: 200,
                inBetween: 15,
                allowMore: true,
            },
            'Sheep': {
                label: 'sheep',
                min: 0,
                max: 200,
                inBetween: 15,
                allowMore: true,
            },
            'Indoor pigs': {
                label: 'indoor pigs',
                min: 0,
                max: 200,
                inBetween: 15,
                allowMore: true,
            },
            'Outdoor pigs': {
                label: 'outdoor pigs',
                min: 0,
                max: 200,
                inBetween: 15,
                allowMore: true,
            },
        };

        const animalTypesNumbers = {};

        Object.keys(animalSettings).forEach(animal => {
            animalTypesNumbers[`livestock_${animal}`] = [animalSettings[animal].min + animalSettings[animal].inBetween, animalSettings[animal].min + (animalSettings[animal].inBetween * 2)];
            const findLivestock = this.plot && this.plot.livestock_data ? this.plot.livestock_data.filter(livestock => animal === livestock.name) : [];

            if (findLivestock.length) {
                animalTypesNumbers[`livestock_${animal}`] = [findLivestock[0].min_value, findLivestock[0].max_value];
            }
        });

        return {
            form: this.$inertia.form({
                // General
                name: this.plot.name,
                country_code: this.plot.country_code,
                offset_type: offsetTypes,
                size: this.plot.size,
                size_units: 'hectares',
                company_id: this.plot.company_id ? String(this.plot.company_id) : null,
                status: this.edit ? this.plot.status : 'Identified land',
                map_location: {
                    inputGeo: this.plot.address,
                    ...this.plot.location_data,
                },
                address: '',
                location_data: [],

                // BNG
                planning_authority_id: offsetTypes.includes('bng') ? this.lpaLocation() : null,
                management_style: this.plot.management_style,
                potential_units: this.plot.potential_units,
                study: this.plot.study === true || this.plot.study === 1 ? true : false,

                confirmed_units: this.plot.confirmed_units ? this.plot.confirmed_units : null,
                secondary_confirmed_units: this.plot.secondary_confirmed_units || null,

                habitat_units: this.plot.habitat_units || null,
                hedgerow_units: this.plot.hedgerow_units || null,
                river_units: this.plot.river_units || null,

                current_habitats: this.plot.current_habitats ? this.plot.current_habitats : [],
                current_estimated_units: this.plot.current_estimated_units ? this.plot.current_estimated_units : null,
                current_secondary_estimated_units: this.plot.current_secondary_estimated_units ? this.plot.current_secondary_estimated_units : null,
                current_potential_secondary_estimated_units: this.plot.current_potential_secondary_estimated_units,

                potential_habitats: this.plot.potential_habitats ? this.plot.potential_habitats : [],
                potential_estimated_units: this.plot.potential_estimated_units ? this.plot.potential_estimated_units : null,
                potential_secondary_estimated_units: this.plot.potential_secondary_estimated_units ? this.plot.potential_secondary_estimated_units : null,

                biodiversity_metric_document: null,

                // RIVERS
                river_basin_district_id: offsetTypes.includes('nutrient_offset') ? String(this.plot.river_basin_district_id) : null,
                drainage: this.plot.drainage ? this.plot.drainage : null,
                soil_type: this.plot.soil_type ? this.plot.soil_type : null,
                river_running: this.plot.river_running ? this.plot.river_running : false,
                livestock: this.plot.livestock ? this.plot.livestock : false,
                livestock_type: this.plot.livestock_data ? this.plot.livestock_data.map(livestock => livestock.name) : [],
                ...animalTypesNumbers,
            }),
            zoom: 6,
            lpa: null,
            pageErrors: {},
            changingValue: false,
            animalSettings,
        };
    },
    computed: {
        pageTitle() {
            return this.edit ? 'Edit Land Parcel' : 'New Land Parcel';
        },
        buttonText() {
            return this.edit ? 'Save Changes' : 'Save';
        },
        cancelRoute() {
            return this.edit ? this.$route('plot.show', this.plot.id) : this.$route('plots');
        },
        editable() {
            if (this.hasGroup(['sequest'])) {
                return true;
            }

            if (!this.edit) {
                return true;
            }

            return (this.edit && this.plot.status === 'BNG Units available');
        },
    },
    watch: {
        'form.planning_authority_id'(newValue, oldValue) {
            if (this.form.offset_type.includes('bng') && newValue !== oldValue) {
                this.form.river_basin_district_id = null;
            }
        },
        'form.map_location': {
            handler(newValue) {
                this.mapChange(newValue);
            },
            deep: true,
        },
        'form.offset_type'(newValue, oldValue) {
            if (!this.changingValue && newValue !== oldValue) {
                this.changingValue = true;

                if (newValue.includes('bng') && !this.form.planning_authority_id) {
                    this.form.planning_authority_id = this.lpaLocation();
                }

                if (this.form.potential_habitats.length || this.form.current_habitats.length) {
                    if (this.changingValue && confirm('Changing the offset type will reset the habitat information. Are you sure you wish to continue?')) {
                        this.form.current_habitats = [];
                        this.form.potential_habitats = [];
                    } else {
                        this.form.offset_type = oldValue;
                    }
                }
            } else {
                this.changingValue = false;
            }
        },
        'form.livestock'(value) {
            if (!value) {
                this.form.livestock_type = [];
            }
        },
        'form.livestock_type'(newValue, oldValue) {
            if (oldValue) {
                oldValue.forEach(animal => {
                    if (this.form[`livestock_${animal}`] && !newValue.includes(animal)) {
                        this.form[`livestock_${animal}`] = null;
                    }
                });
            }

            newValue.forEach(animal => {
                if (!this.form[`livestock_${animal}`]) {
                    let defaultValue = [15, 30];

                    if (this.$refs.nutrient_offset.animalSettings && this.$refs.nutrient_offset.animalSettings[animal]) {
                        const min = this.$refs.nutrient_offset.animalSettings[animal].min + this.$refs.nutrient_offset.animalSettings[animal].inBetween;
                        const max = min + this.$refs.nutrient_offset.animalSettings[animal].inBetween;

                        defaultValue = [min, max];
                    }

                    this.form[`livestock_${animal}`] = defaultValue;
                    this.form[`livestock_${animal}_custom`] = null;
                }
            });
        },
    },
    created() {
        this.mapZoom();
    },
    methods: {
        async submitForm() {
            this.$refs.pager.submitForm(async ({ validated }) => {
                if (validated) {
                    const {
                        inputGeo,
                        inputCountry,
                    } = this.form.map_location;

                    const mapLocation = {
                        area: {
                            meters: this.form.map_location.area.meters,
                            hectares: this.form.map_location.area.hectares,
                        },
                        layer: {
                            center: {
                                lat: this.form.map_location.layer.center.lat,
                                lng: this.form.map_location.layer.center.lng,
                            },
                            paths: this.form.map_location.layer.paths.map(path => {
                                return {
                                    lat: typeof path.lat === 'function' ? parseFloat(path.lat()) : parseFloat(path.lat),
                                    lng: typeof path.lng === 'function' ? parseFloat(path.lng()) : parseFloat(path.lng),
                                };
                            }),
                            shape: this.form.map_location.layer.shape,
                        },
                    };

                    const data = {...this.form};

                    if (data.company_id) {
                        data.company_id = parseInt(data.company_id);
                    } else if (this.companies.length) {
                        data.company_id = parseInt(this.companies[0].id);
                    }

                    if (inputGeo) {
                        data.address = inputGeo;
                    }

                    if (inputCountry) {
                        const country = this.countries.filter(country => country.code === inputCountry);

                        if (country.length) {
                            data.country_code = country[0].code;
                        }
                    }

                    if (mapLocation) {
                        data.location_data = mapLocation;
                    }

                    if (!data.study) {
                        data.study = false;
                    }

                    delete data.map_location;

                    data.current_habitats = data.current_habitats.filter(habitat => Object.keys(habitat).length > 1);
                    data.potential_habitats = data.potential_habitats.filter(habitat => Object.keys(habitat).length > 1);


                    if (this.edit) {
                        this.$inertia.post(this.$route('plot.update', this.plot.id), {
                            _method: 'patch',
                            ...data,
                        });
                    } else {
                        this.$inertia.post(this.$route('plot.store'), {
                            _method: 'put',
                            ...data,
                        });
                    }
                }
            });
        },

        submitFormButton() {
            this.$refs.form.formSubmitted();
        },

        mapZoom() {
            this.zoom = this.form.map_location.points && this.form.map_location.points.length > 0 ? 13 : 6;
        },

        openCalculator(event) {
            event.preventDefault();
            this.$refs.calcutator.open();
        },

        lpaLocation() {
            let value = this.edit && this.plot.planning_authority_id ? this.plot.planning_authority_id.toString() : null;

            if (this.hasGroup('lpa') && this.$page.props.loggedUser.LPAs.length === 1) {
                value = this.planningAuthorities.filter((item) => item.id === this.$page.props.loggedUser.LPAs[0])[0].id.toString();
            } else if (this.planningAuthorities.length === 1) {
                value = this.planningAuthorities[0].id.toString();
            }

            return value;
        },

        validateCustomHabitats(ref) {
            delete this.pageErrors[ref];
            const refElement = this.$refs[ref];
            const refValue = this.form[ref];
            const errors = {...this.pageErrors};
            let returnVal = true;


            if (refElement.areaDetails && refElement.areaDetails.remaining < 0) {

                errors[ref] = `The values entered exceeds the max area of ${refElement.areaDetails.max} Hectares`;

                returnVal = false;
            }

            if (refValue) {
                const issues = refValue.filter(habitat => Object.keys(habitat).length > 1).filter(habitat => {
                    switch (habitat.category) {
                        case 'land':
                            return habitat.size === undefined && habitat.type === undefined && habitat.broad_habitat === undefined;

                        case 'rivers':
                        case 'hedgerows':
                            return habitat.size === undefined && habitat.type === undefined;

                        case 'tree':
                            return habitat.size === undefined && habitat.type === undefined;

                        case 'shrub':
                            return habitat.type === undefined;

                        default:
                            return true;
                    }
                });

                if (issues.length) {
                    errors[ref] = `Please fill out all values for: ${issues.map((habitat, index) => `Habitat ${index + 1}`).join(', ')}`;

                    returnVal = false;
                }
            }

            this.pageErrors = errors;

            return returnVal;
        },

        mapChange(value) {
            if (value.area && value.area.hectares) {
                this.form.size = value.area.hectares.toFixed(2);
                this.form.size_units = 'hectares';
                this.form.potential_units = this.form.size * 4 * 2 * 0.7;
            }
        },

        currentHabitatChange() {
            this.updateHabitatData('current');
            this.updateHabitatUnits();
            this.updateUnits('current');
        },

        potentialHabitatChange() {
            this.updateHabitatData('potential');
            this.updateUnits('potential');
        },

        updateHabitatData(target) {
            this.form[`${target}_habitats`] = this.$refs[`${target}_habitats`].tmpValue[`${target}_habitats`];

            if (!this.form.study && !this.$refs[`${target}_habitats`].documentUploaded) {
                this.form[`${target}_estimated_units`] = this.$refs[`${target}_habitats`].habitatsUnits.units;
            }
        },

        updateHabitatUnits() {
            if (this.form.study && this.$refs.current_habitats.documentUploaded) {
                const {all, land, hedgerows, rivers} = this.$refs.current_habitats.habitatsUnits.units;

                this.form.confirmed_units = all;
                this.form.habitat_units = land;
                this.form.hedgerow_units = hedgerows;
                this.form.river_units = rivers;

                this.form.biodiversity_metric_document = this.$refs.current_habitats.biodiversity_metric_document;
            }
        },

        updateUnits(target) {
            if (this.form.offset_type === 'carbon') {
                if (target === 'current') {
                    if (this.form.study) {
                        this.form.confirmed_units = this.$refs[`${target}_habitats`].habitatsUnits.units;
                        this.form.secondary_confirmed_units = this.$refs[`${target}_habitats`].habitatsUnits.secondary_units;
                    } else {
                        this.form.current_estimated_units = this.$refs[`${target}_habitats`].habitatsUnits.units;
                        this.form.current_secondary_estimated_units = this.$refs[`${target}_habitats`].habitatsUnits.secondary_units;
                    }
                } else {
                    this.form.potential_estimated_units = this.$refs[`${target}_habitats`].habitatsUnits.units;
                    this.form.potential_secondary_estimated_units = this.$refs[`${target}_habitats`].habitatsUnits.secondary_units;
                }
            }
        },
    },
};
</script>
