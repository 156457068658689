<template>
    <div
        id="site-app"
        :page-name="$route().current()"
    >
        <Header @toggleSidebar="toggleSidebar" />
        <Sidebar
            :active="sidebarActive"
            profile-type="biodiverity"
            :section="section"
            @toggleSidebar="sidebarActive = !sidebarActive"
        />
        <div
            id="site-page"
        >
            <slot />
            <flash-message />
        </div>

        <conformation-dialog />
    </div>
</template>
<script>
import FlashMessage from 'Utilities/alert/FlashMessage.vue';
import Header from '../Header/Header';
import Sidebar from '../sidebar/Sidebar';
export default {
    name: 'AppLayout',
    components: {
        Header,
        Sidebar,
        FlashMessage,
    },
    props: {
        section: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            sidebarActive: false,
        };
    },
    methods: {
        toggleSidebar() {
            this.sidebarActive = !this.sidebarActive;
        },
    },
};
</script>
