var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "app-layout",
    { attrs: { section: "rentals" } },
    [
      _c("page-header", { attrs: { title: "BNG Agreements", icon: "plots" } }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "center" },
        [
          _c("TableGridLayout", {
            attrs: {
              columns: _vm.columns,
              rows: _vm.rentals.data,
              mode: "remote",
              "total-records": _vm.rentals.total,
              "has-header": true,
              "has-footer": true,
              output: _vm.output,
              "grid-layout-for-table": _vm.gridLayout,
            },
            on: { onRowClick: _vm.goToRental },
            scopedSlots: _vm._u([
              {
                key: "header-left",
                fn: function () {
                  return [
                    _c("list-filter", {
                      attrs: {
                        fields: _vm.filterOptions,
                        route: _vm.filterRoute,
                        section: ["rentals", "items"],
                      },
                    }),
                  ]
                },
                proxy: true,
              },
              {
                key: "footer",
                fn: function () {
                  return [
                    _c("pagination", {
                      attrs: { links: _vm.rentals.links, section: "rentals" },
                    }),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }