<template>
    <auth-layout
        :component="$options.name"
    >
        <form
            method="POST"
            @submit.prevent="form.post($route('verification.send'))"
        >
            <div class="form-page bg-white">
                <div
                    v-if="flash.status === 'verification-link-sent'"
                    class="text-center"
                >
                    <div class="icon-success">
                        <SvgController
                            type="circle-tick"
                        />
                    </div>
                    <h3>Verification Email Sent</h3>
                    <div class="formulate-input">
                        <p>A new verification link has been sent to the email address you provided during registration.</p>
                    </div>
                    <div class="formulate-input">
                        <inertia-link
                            :href="$route('login')"
                            class="btn btn--secondary btn--large"
                        >
                            Back to Login
                        </inertia-link>
                    </div>
                </div>
                <div v-else>
                    <h3>Verify Email Address</h3>
                    <div class="formulate-input">
                        <p>
                            <strong>Thank you for signing up.</strong>
                            <br>
                            All you need to do now is confirm your email address. In the next 5 minutes you should receive an email from us with a link to confirm your email address. You will then be able to log in.
                        </p>
                    </div>
                    <FormulateInput
                        type="submit"
                        label="Resend Verification Email"
                    />
                </div>
            </div>
        </form>
    </auth-layout>
</template>

<script>
import AuthLayout from 'Components/Layouts/AuthLayout.vue';
export default {
    name: 'VerifyEmail',

    components: {AuthLayout},

    props: {
        flash: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            form: this.$inertia.form({}),
        };
    },
};
</script>

