
<template>
    <div
        class="example"
        :name="context.attributes.name"
    >
        <quill-editor
            ref="myTextEditor"
            v-model="context.model"
            class="editor"
            :value="content"
            :options="options"
            @blur="context.blurHandler"
        />
    </div>
</template>

<script>
import { quillEditor } from 'vue-quill-editor';
import FormulateInputMixin from '@braid/vue-formulate/src/FormulateInputMixin';

export default {
    name:'FormulateCheckboxButton',

    components: {
        quillEditor,
    },

    mixins: [FormulateInputMixin],
    props: {
        name: {
            type: String,
            default: '',
        },
        editorOptions: {
            type: Object,
            default: () => ({}),
        },
    },

    data() {
        return {
            content: '',
            options: {},
        };
    },

    created() {
        this.options = {
            modules: {
                toolbar: [
                    ['bold', 'italic', 'underline'],
                ],
            },
            ...this.editorOptions,
        };
    },

};
</script>
