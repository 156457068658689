var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.shapeData.shape
    ? _c(
        _vm.component,
        _vm._b(
          {
            key: `${_vm.type}${_vm.shapeIndex}`,
            ref: `${_vm.type}${_vm.shapeIndex}`,
            tag: "component",
            attrs: {
              "fill-color": _vm.color,
              "stroke-color": _vm.isActive ? "black" : _vm.strokeColor,
              "fill-opacity": _vm.fillOpacity,
              "stroke-weight": 1,
              "z-index": _vm.zIndex,
              editable: _vm.editable,
              draggable: _vm.draggable,
            },
            on: {
              dragstart: _vm.dragStart,
              dragend: _vm.dragEnd,
              bounds_changed: _vm.dragEnd,
              ready: _vm.ready,
            },
          },
          "component",
          _vm.shapeData,
          false
        )
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }