<template>
    <app-layout section="local-planning-authorities">
        <page-header
            title="Operating in"
            icon="building"
        >
            <button
                v-if="hasPolicy('operatingIn_create')"
                type="button"
                class="btn btn--primary"
                @click="addLPA()"
            >
                <SvgController
                    type="plus"
                />
                Add Area
            </button>
        </page-header>
        <div class="center">
            <local-planning-authority-table
                :local-planning-authorities="localPlanningAuthorities"
            />
        </div>
        <operating-in-form ref="operatingInCreate" />
    </app-layout>
</template>

<script>

import LocalPlanningAuthorityTable from './Table';
import OperatingInForm from './Form';

export default {
    components: {
        LocalPlanningAuthorityTable,
        OperatingInForm,
    },

    props: {
        localPlanningAuthorities: {
            type: Object,
            default: () => ({}),
        },
        loggedUser: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {};
    },
    methods:{
        updatePlanningArea() {
            this.$refs.fileModal.open();
        },
        addLPA() {
            this.$refs.operatingInCreate.open();
        },
    },
};
</script>
