<template>
    <report
        type="development"
        :report-data="{
            project,
            title
        }"
        :filename="filename"
    />
</template>

<script>

import Report from 'Components/Reports/Report';

export default {
    name: 'ProjectReport',
    components: {
        Report,
    },

    props: {
        project: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {};
    },

    computed: {
        filename() {
            return `${this.title}-dev-site-report-${this.project.name}`;
        },

        title() {
            return this.$page.props.loggedUser.positions[0].company.name;
        },
    },

    mounted() {},

    methods: {},
};
</script>
