var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "app-layout",
    { attrs: { section: "reports" } },
    [
      _c("report", {
        attrs: {
          type: "overview",
          "report-data": {
            title: _vm.title,
            lpa: _vm.lpa,
            tileValues: _vm.tileValues,
            linkValues: _vm.linkValues,
            companyId: _vm.companyId,
            plots: _vm.plots,
            projects: _vm.projects,
            mapPlots: _vm.mapPlots,
            mapProjects: _vm.mapProjects,
          },
          filename: _vm.filename,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }