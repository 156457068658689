var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "accordion-item",
      class: {
        "accordion-item--active": _vm.visible,
      },
    },
    [
      _c(
        "div",
        {
          ref: "accordianToggle",
          staticClass: "accordion-item-title",
          on: { click: _vm.toggle },
        },
        [
          _c("h4", [_vm._v(_vm._s(_vm.title))]),
          _vm._v(" "),
          _c(
            "span",
            [_c("SvgController", { attrs: { type: "caret-down" } })],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "transition",
        {
          attrs: { name: "accordion" },
          on: {
            enter: _vm.start,
            "after-enter": _vm.end,
            "before-leave": _vm.start,
            "after-leave": _vm.end,
          },
        },
        [
          _vm._v("\n        >\n        "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.visible,
                  expression: "visible",
                },
              ],
              staticClass: "accordion-item-body",
            },
            [_vm._t("default")],
            2
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }