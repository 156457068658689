<template>
    <div
        ref="form"
        :errors="$page.props.errors"
        class="formulate-form--project form-flex--lap overflow-hidden relative"
    >
        <div
            v-show="sidebar"
            class="w-1/3"
        >
            <HeaderSmall
                title="Habitats"
                :button="{
                    svg: 'filter',
                    click: openFilters,
                }"
            />
            <BNGCalculator
                key="bng_calculator"
                ref="calcutator"
                v-model="current_habitats"
                :show-id="habitat"
                :show-units="false"
                :repeatable="true"
                :small="true"
                :photo="true"
                :photo-upload-url="$route('habitat.upload')"
                pill=""
                :fields-enabled="{size: false}"
                :additional-fields="additionalFields"
                @switch="changeActive"
                @input="updateHabitat"
                @submit-start="submitStart"
                @submit="submitForm"
            />
        </div>
        <div
            :class="{
                'w-2/3': sidebar,
                'w-full': !sidebar,
            }"
        >
            <HeaderSmall
                title="Location"
            />
            <alert
                type="info"
            >
                <p>Use the map below to draw your habitat - please be as accurate as possible.</p>
            </alert>
            <FormulateInput
                ref="map"
                key="map"
                v-model="activeMap"
                :edit-fill-color="editFillColor"
                name="map_location"
                :item="form"
                type="map"
                map-type="habitat"
                :zoom="2"
                :fit-bounds="vectors.length ? 'vectors' : 'layers'"
                :vectors="vectors"
                :show-geo-input="false"
                :layers="layers"
                :shape-type="current_habitats[showIndex] && current_habitats[showIndex].type === 'individual-trees' ? 'circle' : null"
                :multiple="current_habitats[showIndex] && current_habitats[showIndex].type === 'individual-trees' ? true : false"
                :disabled="isDisabled"
                :disabled-message="message"
                @input="mapChange"
            />
        </div>
        <Sidebar
            key="sidebar"
            ref="sidebar"
            v-model="filters"
            class="w-1/3"
            :accordions="accordions"
            :slide="true"
            title="Filters"
            @change="sidebarChange"
        />
    </div>
</template>

<script>
import { mapColors } from 'Lib/defaults';
import Alert from 'Utilities/alert/Alert';
import BNGCalculator from 'Components/Calculator/Bng';
import Sidebar from '../../views/map/Sidebar';
import HeaderSmall from 'Components/Header/HeaderSmallButtons';

export default {
    name: 'HabitatFormComponent',
    components: {
        BNGCalculator,
        Alert,
        Sidebar,
        HeaderSmall,
    },

    props: {
        defaultFilters: {
            type: Object,
            default: () => ({
                lpa: true,
            }),
        },
        habitat: {
            type: Number,
            default: null,
        },
        edit: {
            type: Boolean,
            default: false,
        },
        lpas: {
            type: Array,
            default: () => ([]),
        },
        rivers: {
            type: Array,
            default: () => ([]),
        },
        saveId: {
            type: Number,
            required: true,
        },
        saveType: {
            type: String,
            required: true,
        },
        sidebar: {
            type: Boolean,
            default: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        habitats: {
            type: Array,
            default: () => ([]),
        },
        urlType: {
            type: String,
            default: 'habitat',
        },
    },

    data() {
        return {
            showIndex: 0,
            form: this.$inertia.form({}),
            activeMap: {},
            delete: [],
            accordions: [],
            habitatTypes: {
                'woodland': 'Woodland',
                'individual-trees': 'Individual Trees',
                'hedgerows': 'Hedgerows',
                'grassland': 'Grassland',
                'water': 'Water',
                'nature_reserves': 'Nature Reserves',
                'sssi': 'SSSI (Sites of Special Scientific Interest)',
                'national_conservation_sites': 'National Conservation Sites',
                'house': 'House',
                'barn': 'Barn',
                'shed': 'Shed',
                'associated_land': 'Associated Land',
            },
            formInialise: false,
            formChange: false,
            current_habitats: this.habitats && this.habitats.length ?
                this.habitats.map(habitat => ({
                    ...habitat,
                    map_location: habitat.location_data,
                }))
                : [{
                    size: 0,
                    category: null,
                    broad_habitat: null,
                    type: null,
                    photo: null,
                    location_data: [],
                    map_location: {},
                }],
            additionalFields: {
                land: {
                    cropland: [{
                        value: 'associated-land',
                        label: 'Associated Land',
                    }],
                    'woodland-and-forest': [{
                        value: 'individual-trees',
                        label: 'Individual Trees',
                    }],
                    urban: [{
                        value: 'house',
                        label: 'House',
                    }, {
                        value: 'barn',
                        label: 'Barn',
                    }, {
                        value: 'shed',
                        label: 'Shed',
                    }],
                },
            },
            data: [],
            filters: this.defaultFilters,
            options: {},
            editingPlot: this.urlType === 'plots' ? this.$page.props.plot.id : null,
            plots: this.$page.props.plots ?? {},
            plotsAmount: 1,
            projects: this.$page.props.projects ?? {},
            projectsAmount: 1,
        };
    },

    computed: {
        url() {
            if (this.urlType === 'plots') {
                return this.$route('plot.show.habitats', {
                    plot: this.editingPlot,
                });
            }

            return this.edit ? this.$route('habitat.edit') : this.$route('habitat.create');
        },

        isDisabled() {
            if (this.disabled || this.showIndex === -1) {
                return true;
            }

            if (this.current_habitats.length) {
                if (this.current_habitats[this.showIndex].category === 'land' && this.current_habitats[this.showIndex].type && this.current_habitats[this.showIndex].broad_habitat) {
                    return false;
                } else if ((this.current_habitats[this.showIndex].category === 'hedgerows' || this.current_habitats[this.showIndex].category === 'rivers') && this.current_habitats[this.showIndex].type) {
                    return false;
                }
            }

            return true;
        },

        message() {
            if (this.disabled) {
                return '';
            }

            if (this.showIndex === -1) {
                return 'Please select a habitat or add a new one.';
            }

            if (this.current_habitats.length) {
                if (this.current_habitats[this.showIndex].category === 'land') {
                    return 'Please select a habitat category and type to enable editing.';
                }
            }

            return 'Please select a habitat type to enable editing.';
        },

        getSizeTitle() {
            return this.current_habitats[this.showIndex].type === 'individual-trees' ? 'Trees' : 'Hectares';
        },

        layers() {
            return this.data.map(habitat => {
                const fill = this.getMapFillColor(habitat);

                return fill;
            });
        },

        editFillColor() {
            return this.getMapFillColor(this.current_habitats[this.showIndex]);
        },

        vectors() {
            const vectors = [];

            if (this.lpas && this.lpas.length && this.filters.lpa) {
                vectors.push({
                    name: 'lpa',
                    url: this.$mapService + '/lpa/{z}/{x}/{y}.pbf',
                    attributesUrl: this.$mapService + '/lpa.attributes.json',
                    background: mapColors.lpa,
                    backgroundOpacity: 0.1,
                    lineWidth: 2,
                    stroke: mapColors.lpa,
                    strokeOpacity: 1,
                    filter: this.lpas.map(lpa => ({
                        property: 'name',
                        compare: '=',
                        type: 'OR',
                        value: [lpa.name.replace(' LPA', ''), lpa.name, `${lpa.name} LPA`],
                    })),
                });
            }

            if (this.rivers && this.rivers.length && this.filters.river_basin_districts) {
                vectors.push({
                    name: 'river_basin_districts',
                    url: this.$mapService + '/surface_water_operational_catchments/{z}/{x}/{y}.pbf',
                    attributesUrl: this.$mapService + '/surface_water_operational_catchments.attributes.json',
                    background: mapColors.river_basin_districts,
                    backgroundOpacity: 0.1,
                    lineWidth: 2,
                    stroke: mapColors.river_basin_districts,
                    strokeOpacity: 1,
                    filter: this.rivers.map(river => ({
                        property: 'name',
                        compare: '=',
                        value: river.name,
                    })),
                });
            }

            return vectors;
        },
        buildList() {
            const list = [{
                name: 'Layers',
                items: [],
            }];
            let lpaFilter, riverFilter;

            if (this.lpas && this.lpas.length) {
                lpaFilter = {
                    name: 'lpa',
                    placeholder: 'LPA',
                    options: this.lpas.map(lpa => ({
                        label: lpa.name,
                        value: lpa.name,
                    })),
                };
            }

            if (this.rivers && this.rivers.length) {
                riverFilter = {
                    name: 'river_basin_district',
                    placeholder: 'River Catchment Area',
                    options: this.rivers.map(river => ({
                        label: river.name,
                        value: river.name,
                    })),
                };
            }

            if (this.projectsAmount && !this.editingPlot) {
                const options = [];

                if (lpaFilter) {
                    options.push({...lpaFilter});
                }

                if (riverFilter) {
                    options.push({...riverFilter});
                }

                if (this.projectsAmount > 1) {
                    options.push({
                        name: 'page',
                        Label: 'Page',
                        placeholder: 'Page 1',
                        options: Array(this.projects ? this.projectsAmount : 0).fill(0).map((value, index) => ({
                            label: 'Page ' + (index + 1),
                            value: index + 1,
                        })),
                    });
                }

                list[0].items.push({
                    type: 'local',
                    'data-type': 'projects',
                    name: 'projects',
                    label: 'Developments',
                    color: mapColors.project,
                    options: options,
                });
            }

            if (this.plotsAmount) {
                const options = [];

                if (lpaFilter && !this.editingPlot) {
                    options.push({...lpaFilter});
                }

                if (riverFilter && !this.editingPlot) {
                    options.push({...riverFilter});
                }

                if (this.plotsAmount > 1) {
                    options.push({
                        name: 'page',
                        Label: 'Page',
                        placeholder: 'Page 1',
                        options: Array(this.plots ? this.plotsAmount : 0).fill(0).map((value, index) => ({
                            label: 'Page ' + (index + 1),
                            value: index + 1,
                        })),
                    });
                }

                list[0].items.push({
                    type: 'local',
                    'data-type': 'plots',
                    name: 'plots',
                    label: 'Land Parcels',
                    color: mapColors.plot,
                    options: options,
                });
            }

            list[0].items.push({
                type: 'local',
                'data-type': 'plots',
                name: 'habitats',
                label: 'Habitats',
                color: mapColors.plot,
            });

            list[0].items.push({
                label: 'LPA',
                name: 'lpa',
                color: mapColors.lpa,
            });

            list[0].items.push({
                label: 'River basin district map',
                name: 'river_basin_districts',
                color: mapColors.river_basin_districts,
            });

            return list;
        },
    },

    watch: {
        activeMap() {
            this.updateHabitat();
        },
    },

    async mounted() {
        this.changeActive(0);

        await this.refreshProjects();
        await this.refreshPlots();

        this.accordions = this.buildList;
    },

    methods: {
        async sidebarChange(filters, options) {
            const oldOptions = {...this.options};

            this.options = {...options};

            if (!this.projects.data || (oldOptions !== this.options)) {
                await this.refreshProjects();
            }

            if (!this.plots.data || (oldOptions !== this.options)) {
                await this.refreshPlots();
            }

            this.accordions = this.buildList;

            this.combineData();
        },

        async refreshProjects() {
            return new Promise(resolve => {
                if (this.filters.projects) {
                    this.$inertia.get(this.url, {
                        page: this.options.projects_page ?? 1,
                        planning_auth: this.options.projects_lpa ?? null,
                        river_basin_district: this.options.projects_river_basin_district ?? null,
                    }, {
                        preserveState: true,
                        preserveScroll: true,
                        only: ['projects'],
                        replace: true,
                        onSuccess: (data) => {
                            this.projectsAmount = data.props.projects.last_page;
                            this.projects = data.props.projects;
                            this.combineData();
                            resolve();
                        },
                    });
                } else {
                    resolve();
                }
            });
        },

        async refreshPlots() {
            return new Promise(resolve => {
                if (this.filters.plots) {
                    this.$inertia.get(this.url, {
                        id: this.editingPlot,
                        page: this.options.plots_page ?? 1,
                        planning_auth: this.options.plots_lpa ?? null,
                        river_basin_district: this.options.plots_river_basin_district ?? null,
                    }, {
                        preserveState: true,
                        preserveScroll: true,
                        only: ['plots'],
                        replace: true,
                        onSuccess: (data) => {
                            this.plotsAmount = data.props.plots.last_page;
                            this.plots = data.props.plots;
                            this.combineData();
                            resolve();
                        },
                    });
                } else {
                    resolve();
                }
            });
        },

        updateHabitat() {
            if (this.$refs.calcutator && this.$refs.calcutator.showIndex !== -1) {
                this.showIndex = this.$refs.calcutator.showIndex;
                this.current_habitats[this.showIndex].location_data = this.activeMap;
                this.current_habitats[this.showIndex].map_location = this.activeMap;

                if (this.current_habitats[this.showIndex].map_location) {
                    if (this.current_habitats[this.showIndex].type === 'individual-trees' && this.current_habitats[this.showIndex].map_location.layer) {
                        this.current_habitats[this.showIndex].size = this.current_habitats[this.showIndex].map_location.layer.points.length;
                    } else if (this.current_habitats[this.showIndex].map_location.area) {
                        this.current_habitats[this.showIndex].size = this.current_habitats[this.showIndex].map_location.area.hectares.toFixed(2);
                    }
                } else {
                    this.current_habitats[this.showIndex].map_location = {};
                }
            }
        },

        changeActive(showIndex) {
            this.showIndex = showIndex;
            this.activeMap = showIndex !== -1 && typeof this.current_habitats[showIndex].location_data === 'object' ? this.current_habitats[showIndex].location_data : {};
            this.combineData();
        },

        calcSize() {
            if (this.showIndex === -1) return;

            if (this.activeMap) {
                const { area } = this.activeMap;

                if (area && area.hectares) {
                    if (this.$refs.calcutator.sizeUnits(0) === 'Hectares') {
                        this.current_habitats[this.showIndex].size = area.hectares;
                    } else if (this.$refs.calcutator.sizeUnits(0) === 'M') {
                        this.current_habitats[this.showIndex].size = this.hectaresToMetersSq(area.hectares);
                    } else if (this.$refs.calcutator.sizeUnits(0) === 'KM') {
                        this.current_habitats[this.showIndex].size = this.kmSqToHectares(area.hectares);
                    }
                }
            }

            this.current_habitats[this.showIndex].size = 0;
        },

        onTypeChange() {
            if (this.showIndex === -1) return;
            const alertCatch = 'individual-trees';

            if (
                this.current_habitats[this.showIndex] &&
                this.current_habitats[this.showIndex].map_location.layer &&
                (
                    this.form.type === alertCatch && this.current_habitats[this.showIndex] !== alertCatch ||
                    this.form.type !== alertCatch && this.current_habitats[this.showIndex] === alertCatch
                )) {
                this.$confirm(
                    {
                        title: 'Warning',
                        message: `Changing the type from ${this.current_habitats[this.showIndex]} to ${this.form.type} will loose any map data.<br>Do you wish to continue?`,
                        callback: confirm => {
                            if (confirm) {
                                this.current_habitats[this.showIndex] = 0;
                                this.current_habitats[this.showIndex].map_location = {};
                            } else {
                                this.current_habitats[this.showIndex].type = this.current_habitats[this.showIndex];
                                this.current_habitats[this.showIndex] = this.form.type;
                            }
                        },
                    },
                );
            } else {
                this.current_habitats[this.showIndex] = this.form.type;
            }
        },

        submitFormButton() {
            this.$refs.calcutator.$refs.form.formSubmitted();
        },

        changeForm() {
            if (this.formInialise === true) {
                this.formChange = true;

                window.onbeforeunload = () => {
                    return 'Are you sure you want to leave and discard any changes?';
                };
            }
        },

        submitStart(data) {
            this.$emit('submit-start', data);
        },

        submitComplete(data) {
            this.$emit('submit-complete', data);
        },

        async submitForm(data) {
            data.habitats = this.current_habitats.map(habitat => {
                const dataReturn = {...habitat};
                const {
                    ...mapLocation
                } = habitat.map_location;

                if (mapLocation) {
                    dataReturn.location_data = mapLocation;
                }

                dataReturn.related_id = this.saveId;
                dataReturn.related_type = this.saveType;

                if (dataReturn.photo_upload) {
                    if (dataReturn.photo && dataReturn.photo.length && dataReturn.photo[0].url) {
                        dataReturn.photo = dataReturn.photo[0].url;
                    } else {
                        dataReturn.photo = null;
                    }
                }

                delete dataReturn.map_location;
                delete dataReturn.photos;

                return dataReturn;
            });

            data.related_id = this.saveId;
            data.related_type = this.saveType;

            await this.$inertia.put(this.$route('habitat.bulk'), data);
            this.submitComplete();

            window.onbeforeunload = null;
        },

        getMapFillColor(habitat) {
            if (!habitat) habitat = {};
            let color = mapColors[habitat.type] ?? null,
                theme = null;

            if (mapColors[habitat.type] && typeof color === 'object') {
                color = mapColors[habitat.type].fill;
                theme = mapColors[habitat.type].theme;
            }

            if (habitat.map_location) {
                habitat.location_data = habitat.map_location;
            } else if (habitat.location_data && habitat.location_data.lat) {
                habitat.location_data = {layer: {shape: 'marker', points: habitat.location_data}};
            }

            return {
                ...habitat,
                type: habitat['data-type'],
                'habitat-type': habitat.type ?? null,
                location_data: habitat.location_data ?? {},
                fillColor: color,
                theme: theme,
            };
        },

        mapChange() {
            this.calcSize();
        },

        combineData() {
            this.data = [
                ...(this.projects && this.projects.data && this.filters.projects ? this.projects.data.map(data => ({...data, type: 'project', 'data-type': 'projects'})) : []),
                ...(this.plots && this.plots.data && this.filters.plots ? this.plots.data.map(data => ({...data, type: 'plot', 'data-type': 'plots'})) : []),
                ...(this.current_habitats && this.filters.habitats ? this.current_habitats.filter((data, index) => index !== this.showIndex).map(data => ({...data, 'data-type': 'habitat'})) : []),
            ];
        },

        openFilters() {
            this.$refs.sidebar.toggle();
        },
    },
};
</script>
