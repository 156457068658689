<template>
    <div>
        <empty
            v-if="!$page.props.hasItems"
            type="landowners"
        />
        <div v-else>
            <TableGridLayout
                :columns="columns"
                :rows="landowners.data"
                :has-header="hasHeader"
                :has-footer="hasFooter"
                :output="['table']"
                :grid-layout-for-table="gridLayout"
                @onRowClick="onRowClick"
            >
                <template #header-left>
                    <list-filter
                        :route="filterRoute"
                        search-placeholder="Search Landowners"
                        section="landowners"
                    />
                </template>

                <template #footer>
                    <pagination
                        :links="landowners.links"
                        section="landowners"
                    />
                </template>
            </TableGridLayout>
        </div>
    </div>
</template>

<script>

import ListFilter from 'Utilities/list-filter/ListFilter.vue';
import Pagination from 'Utilities/pagination/Pagination.vue';
import Empty from 'Utilities/empty/Empty';

export default {
    name: 'LandownerTable',
    components: {
        ListFilter,
        Pagination,
        Empty,
    },

    props: {
        landowners: {
            type: Object,
            default: () => ({}),
        },

        hasHeader: {
            type: Boolean,
            default: true,
        },

        hasFooter: {
            type: Boolean,
            default: true,
        },

        filterRoute: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            columns: [],
            gridLayout: {},
        };
    },

    created() {
        this.columns = [{
            label: 'Landowner Name',
            'field': 'company_name',
        },{
            label: 'First Name',
            field: 'first_name',
        },{
            label: 'Surname',
            field: 'last_name',
        },{
            label: 'offset credits:',
            thClass: 'offset-credits-column',
            field: '&nbsp;',
            sortable: false,
        }, {
            label: 'Total',
            field: 'totalCredit',
            colour: '#6967D5',
            sortable: false,

        }, {
            label: 'Available',
            field: 'availableCredit',
            colour: '#5EBD8A',
            sortable: false,
        }, {
            label: 'Implemented',
            field: 'rentedCredit',
            colour: '#E47D44',
            sortable: false,
        }];

        this.gridLayout = {
            title: 'company_name',
            description: 'full_name',
            onClick: (evt, row, index) => {
                this.$inertia.visit(this.$route('landowner.show', this.landowners.data[index].company_id));
            },
        };
    },

    methods: {
        onRowClick({ row }) {
            this.$inertia.visit(this.$route('landowner.show', row.company_id));
        },
    },
};
</script>
