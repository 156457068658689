<template>
    <component
        :is="tag"
        class="processing"
    >
        <div
            v-if="spinner"
            class="processing-spinner"
        />
        <span>
            <slot />
        </span>
    </component>
</template>

<script>
export default {
    name: 'Processing',
    props: {
        spinner: {
            type: Boolean,
            default: true,
        },
        tag: {
            type: String,
            default: 'p',
            validator: value => {
                return ['p', 'h1', 'h2', 'h3', 'h4', 'div'].includes(value);
            },
        },
    },
};
</script>
