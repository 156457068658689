<template>
    <div>
        <div class="form-flex--tab form-flex--vcenter">
            <FormulateInput
                key="drainage_2"
                type="select-plugin"
                name="drainage"
                label="drainage"
                validation="required"
                validation-name="drainage"
                :options="drainage"
                placeholder="Select Drainage Type"
                :disabled="disabled"
            />
            <FormulateInput
                key="soilType_2"
                type="select-plugin"
                name="soil_type"
                label="Soil Type"
                validation="required"
                validation-name="Soil Type"
                :options="soilType"
                placeholder="Select Soil Type"
                :disabled="disabled"
            />
        </div>
        <div class="form-flex--tab form-flex--auto form-flex--vcenter">
            <FormulateInput
                key="stream_2"
                type="toggle-checkbox"
                name="river_running"
                class="max-w-2"
                label="Is there a stream or river running through the land?"
            />
            <FormulateInput
                key="livestock_2"
                type="toggle-checkbox"
                name="livestock"
                class="max-w-2"
                label="Is there any livestock currently on the land?"
            />
            <div />
        </div>
        <FormulateInput
            v-if="value.livestock"
            key="animals_2"
            type="select-plugin"
            name="livestock_type"
            label="animals"
            validation="required"
            validation-name="livestock_type"
            :options="animalList"
            placeholder="Select Animals"
            :disabled="disabled"
            :multiple="true"
        />
        <template v-if="value.livestock_type && value.livestock_type.length">
            <div
                v-for="animal in value.livestock_type"
                :key="`animal-${animal}`"
            >
                <FormulateInput
                    :key="`animal-${animal}_input_2`"
                    type="range-between"
                    :name="`livestock_${animal}`"
                    :label="`How many ${animalSettings[animal] ? animalSettings[animal].label : animal} are on the land?`"
                    :min="animalSettings[animal] ? animalSettings[animal].min : 0"
                    :max="animalSettings[animal] ? animalSettings[animal].max : 200"
                    :max-between="animalSettings[animal] ? animalSettings[animal].inBetween : 15"
                    :edit-values="true"
                    :allow-over-max="true"
                    validation="required"
                    validation-name="Name"
                />
            </div>
        </template>
    </div>
</template>

<script>
import PlotPages from 'Mixins/plotPages';
import { mapColors } from 'Lib/defaults';

export default {
    name: 'PlotFormPage2',
    components: {
    },
    mixins: [PlotPages],
    props: {
        animalSettings: {
            type: Object,
            default: () => {},
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        editable: {
            type: Boolean,
            default: false,
        },
        value: {
            type: Object,
            default: () => {},
        },
        planningAuthorities: {
            type: Array,
            default: () => [],
        },
        riverBasinDistricts: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            pageShowing: false,
            tmpValue: this.value,
            drainage: [{
                label: 'Artificially Drained',
                value: 'Artificially Drained',
            }, {
                label: 'Naturally Drained',
                value: 'Naturally Drained',
            }],
            soilType: [{
                label: 'Permeable',
                value: 'Permeable',
            }, {
                label: 'Non-permeable',
                value: 'Non-permeable',
            }],
            mapColors: mapColors,
        };
    },
    computed: {
        animalList() {
            return Object.keys(this.animalSettings).map(animal => ({label: animal, value: animal}));
        },

        backgroundLayer() {
            return this.value.map_location && this.value.map_location.layer ? [{location_data : this.value.map_location, fillColor: mapColors.plot}] : [];
        },
    },
    watch: {
        '$parent.showElement'() {
            this.pageShowing = this.$parent.showElement;
        },
    },
    methods: {
        parseAnimal(animal) {
            return `livestock_${animal}`;
        },
    },
};
</script>
