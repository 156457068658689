var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "app-layout",
    { attrs: { section: "local-planning-authorities" } },
    [
      _c("page-header", {
        attrs: { title: "Local Planning Authorities", icon: "building" },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "center" },
        [
          _c("local-planning-authority-table", {
            attrs: {
              "local-planning-authorities": _vm.localPlanningAuthorities,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }