<!-- eslint-disable-next-line vue/valid-template-root -->
<template />

<script>
import { mapServiceProperties } from 'Lib/defaults';
import MVTFunctionality from 'Lib/mapFunctionality/mvtSource.js';
import { throttle } from 'lodash';

export default {
    name: 'MapVector',
    props: {
        vectorUrl: {
            type: String,
            required: true,
        },
        attributesUrl: {
            type: String,
            default: null,
        },
        background: {
            type: String,
            default: '#5EBD8A',
            validator: value => {
                return value.indexOf('#') === 0 || value.indexOf('rgb') === 0;
            },
        },
        backgroundOpacity: {
            type: Number,
            default: 0.5,
        },
        lineWidth: {
            type: Number,
            default: 1,
        },
        stroke: {
            type: String,
            default: '#000000',
        },
        strokeOpacity: {
            type: Number,
            default: 0.2,
        },
        fitBounds: {
            type: String,
            default: null,
            validator: value => {
                const allowed = ['vector', null];

                return allowed.indexOf(value) > -1;
            },
        },

        // filter: [{
        //   property: 'name',
        //   compare: '=',
        //   value: ['name'],
        // }],
        filter: {
            type: Array,
            default: null,
        },
    },
    data() {
        return {
            id: null,
            loaded: false,
            map: null,
            mvt: {},
            filterShowingIDs: [],
            filterShowing: [],
            layers: null,
            loadIndex: 0,
            attributes: null,
        };
    },
    watch: {
        vectorUrl(newValue, oldValue) {
            if (newValue !== oldValue) {
                this.removeItem();
                this.initVectorTiles();
            }
        },

        filter() {
            if (this.mvt && this.mvt.setFilter) {
                this.runFilterPre();
                this.mvt.setFilter(this.runFilter, true);
            }
        },

        filterShowing: {
            handler: throttle(function(newValue, oldValue) {
                try {
                    if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
                        this.fitFilteredBounds();
                    }
                } catch (error) {}
            }, 1),
        },
    },
    mounted() {
        this.$parent.$_getMap().then(map => {
            this.map = map;
            this.initVectorTiles();
        });
    },
    beforeDestroy() {
        this.removeItem();
    },
    methods: {
        async initVectorTiles() {
            const options = {
                url: this.vectorUrl,
                debug: false,
                cache: true,
                getIDForLayerFeature: function(feature) {
                    return JSON.stringify(feature.properties);
                },
                filter: () => this.filter ? false : true,
                style: () => {
                    return {
                        fillStyle: `rgba(${this.hexToRgb(this.background)}, ${this.backgroundOpacity})`,
                        strokeStyle: `rgba(${this.hexToRgb(this.stroke)}, ${this.strokeOpacity})`,
                        lineWidth: this.lineWidth,
                    };
                },
            };

            this.tileJson = await this.fetchTileJson();

            if (this.tileJson && this.tileJson.maxzoom !== undefined) {
                options.sourceMaxZoom = this.tileJson.maxzoom;
            }

            new MVTFunctionality(this.map, options, async (MVTSource) => {
                this.mvt = MVTSource;

                this.map.addListener('click', event => {
                    this.mvt.onClick(event, this.layerClick, {});
                });
                this.map.addListener('mousemove', event => {
                    this.mvt.onMouseHover(event, this.layerMousemove, {});
                });
                this.map.addListener('load', event => {
                    this.mvt.onMapLoad(event);
                });
                this.map.overlayMapTypes.insertAt(0, this.mvt);

                this.id = this.mvt.id;

                this.layers = this.tileJson.tilestats.layers.map(layer => layer.layer);

                this.attributes = await this.fetchAttributes();

                this.loaded = true;
                this.$emit('load');

                this.filterShowing = [];
                this.filterShowingIDs = [];
                this.runFilterPre();
                this.mvt.setFilter(this.runFilter, true);

                this.$emit('ready');
            });
        },

        fetchTileJson() {
            return new Promise(resolve => {
                const tileJsonUrl = this.vectorUrl.replace(/\/{z}\/{x}\/{y}.pbf/g, '.json');

                fetch(tileJsonUrl)
                    .then((response) => response.json())
                    .then((data) => {
                        resolve(data);
                    });
            });
        },

        fetchAttributes() {
            return new Promise(resolve => {
                if (this.attributesUrl) {
                    if (!this.$mapAttributes) {
                        this.$mapAttributes = {};
                    }

                    if (Array.isArray(this.$mapAttributes[this.attributesUrl])) {
                        resolve(this.$mapAttributes[this.attributesUrl]);

                        return;
                    } else if (typeof this.$mapAttributes[this.attributesUrl] === 'string') {
                        setTimeout(() => {
                            this.fetchAttributes();
                        }, 1000);

                        return;
                    } else {
                        this.$mapAttributes[this.attributesUrl] = 'loading';
                    }

                    fetch(this.attributesUrl)
                        .then((response) => response.json())
                        .then((data) => {
                            this.$mapAttributes[this.attributesUrl] = data;
                            resolve(data);
                        });
                } else {
                    resolve([]);
                }
            });
        },

        runFilterPre() {
            this.filterShowing = [];
            this.filterShowingIDs = [];

            if (this.layers && this.filter && this.attributes) {
                let show = true;

                this.attributes.filter(feature => {
                    this.filter.forEach(filter => {
                        const propertyName = mapServiceProperties[this.layers[0]][filter.property];
                        const property = feature.properties[propertyName] || feature.properties[filter.property];

                        show = property ? true : false;

                        if (show === true) {
                            const matchType = filter.type ? filter.type.toLowerCase() : 'and';
                            let showInner = matchType === 'and';

                            if (filter.value) {
                                const values = Array.isArray(filter.value) ? filter.value : [filter.value];

                                values.forEach(value => {
                                    if (
                                        (showInner === true && matchType === 'and') ||
                                        (showInner === false && matchType === 'or')
                                    ) {
                                        switch (value.compare ?? '=') {
                                            case '<=':
                                                showInner = property < value;
                                                break;

                                            case '>=':
                                                showInner = property >= value;
                                                break;

                                            case '<':
                                                showInner = property <= value;
                                                break;

                                            case '>':
                                                showInner = property > value;
                                                break;

                                            default:
                                                showInner = property === value;
                                                break;
                                        }
                                    }
                                });
                            }

                            show = showInner;
                        }

                        if (show === true || !this.filter) {
                            this.addToFilter(feature);
                        }
                    });
                });
            }
        },

        runFilter(feature) {
            if (!this.layers || !this.filter) return true;
            const propertyID = feature.properties[mapServiceProperties[this.layers[0]].id];

            return this.filter ? this.filterShowingIDs.includes(propertyID) : true;
        },

        layerClick(event) {
            if (event.feature) {
                this.$emit('onClick', event);
            }
        },

        layerMousemove(event) {
            if (event.feature) {
                this.$emit('onMousemove', event);
            }
        },

        hexToRgb(hex) {
            if (hex.indexOf('#') === 0) {
                if (hex.length === 4) {
                    hex += hex.replace('#', '');
                }

                const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

                return result ? `${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}` : null;
            }

            return hex;
        },

        getFilteredBounds() {
            const bounds = new google.maps.LatLngBounds();

            if (this.attributes && this.filterShowing.length) {
                this.filterShowing.forEach(feature => {
                    bounds.union(feature.bounds);
                });
            }

            return bounds;
        },

        fitFilteredBounds() {
            const bounds = this.getFilteredBounds();

            if (this.fitBounds === 'vector') {
                this.map.fitBounds(bounds);
            }

            this.$emit('filtered');
        },

        addToFilter(feature, context) {
            const propertyID = feature.properties[mapServiceProperties[this.layers[0]].id];

            if (!this.filterShowingIDs.includes(propertyID)) {
                this.filterShowingIDs.push(propertyID);
                this.filterShowing.push({...feature, context});
            }
        },

        removeItem() {
            const removeAt = [];

            this.map.overlayMapTypes.forEach((layer, index) => {
                if (layer.mVTLayers && layer.id === this.id) {
                    removeAt.push(index);
                }
            });

            removeAt.forEach(index => {
                this.map.overlayMapTypes.removeAt(index);
            });
        },
    },
};
</script>
