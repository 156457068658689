<template>
    <div
        class="reportMap"
        :class="{'reportMap--download' : isDownload}"
    >
        <div
            v-if="label"
            class="dashboard-heading flex-space-between"
        >
            <h6>{{ label }}</h6>
        </div>
        <div class="map">
            <draw-map
                ref="map"
                :edit="false"
                :show-geo-input="false"
                :zoom="10"
                type="project"
                fit-bounds="layers"
                :layers="mapLayers"
                :vectors="computeMapVectors"
                popup-shape-click
            />
        </div>
    </div>
</template>

<script>
import mapMixin from 'Mixins/map';
import DrawMap from 'Utilities/maps/DrawMap';
import projectPages from 'Mixins/projectPages';
import mapColors from 'Lib/defaults/mapColors';

export default {
    name: 'ReportMap',
    components: {
        DrawMap,
    },

    mixins: [mapMixin, projectPages],

    props: {
        label: {
            typs: String,
            default: null,
        },
        type: {
            type: String,
            required: true,
            validator: function(value) {
                return ['plots', 'projects', 'rental'].indexOf(value) !== -1;
            },
        },

        zoom: {
            type: Number,
            default: 10,
        },

        center: {
            type: Object,
            default() {
                return {
                    lat: 53.428720,
                    lng: -1.659736,
                };
            },
        },
        lpa: {
            type: Object,
            default: null,
        },
        isDownload: {
            type: Boolean,
            default: false,
        },

        plots: {
            type: Array,
            default: () => ([]),
        },
        projects: {
            type: Array,
            default: () => ([]),
        },
        rental: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            mapLoaded: false,
        };
    },

    computed: {
        mapLayers() {
            let layers = [];

            this.computedPlots.forEach(plot => {
                layers.push({location_data : plot.location_data, fillColor: mapColors.plot});

                if (plot.location_data.layer.center) {
                    const layer = this.mapPointToLayers(plot.location_data.layer.center);

                    if (layer) {
                        layer.fillColor = mapColors.plot;
                        layers.push(layer);
                    }
                }
            });

            this.computedProjects.forEach(project => {
                layers = layers.concat(this.mapProjectLayers(project, true));
            });

            this.computedLeases.forEach(rental => {
                if (rental.location_data && rental.location_data.layer) {
                    layers.push({location_data : rental.location_data, fillColor: mapColors.lease});
                }
            });

            return layers;
        },


        computedProjects() {
            if (this.type === 'rental' && this.projects.length === 0) {
                return [this.rental.project];
            }

            return this.projects;
        },
        computedPlots() {
            if (this.type === 'rental' && this.plots.length === 0) {
                return [this.rental.plot];
            }

            return this.plots;
        },
        computedLeases() {
            if (this.type === 'rental' && Object.keys(this.rental).length > 0) {
                return [this.rental];
            }

            return [];
        },
        planningAuthorities() {
            return [
                ...this.computedPlots.filter(plot => plot.local_planning_authority).map(plot => plot.local_planning_authority),
                ...this.computedProjects.filter(plot => plot.local_planning_authority).map(plot => plot.local_planning_authority),
            ];
        },
        riverBasinDistricts() {
            return [
                ...this.computedPlots.filter(plot => plot.river_basin_district).map(plot => plot.river_basin_district),
                ...this.computedProjects.filter(plot => plot.river_basin_district).map(plot => plot.river_basin_district),
            ];
        },
        computeMapVectors() {
            switch (this.type) {
                case 'plots':
                    return this.mapVectors(
                        this.computedPlots.filter(plot => plot.planning_authority_id).map(plot => plot.planning_authority_id),
                        this.computedPlots.filter(plot => plot.river_basin_district_id).map(plot => plot.river_basin_district_id),
                    );

                case 'projects':
                    return this.mapVectors(
                        this.computedProjects.filter(project => project.planning_authority_id).map(project => project.planning_authority_id),
                        this.computedProjects.filter(project => project.river_basin_district_id).map(project => project.river_basin_district_id),
                    );

                case 'rental' :
                    return this.mapVectors(this.rental.plot.planning_authority_id, this.rental.plot.river_basin_district_id);

                default:
                    return {};
            }
        },
    },

    methods:{},
};
</script>
