<template>
    <app-layout section="local-planning-authorities">
        <page-header
            title="Local Planning Authorities"
            icon="building"
        />
        <div class="center">
            <local-planning-authority-table
                :local-planning-authorities="localPlanningAuthorities"
            />
            <!-- :filter-route="filterRoute" -->
        </div>
    </app-layout>
</template>

<script>

import LocalPlanningAuthorityTable from './Table';

export default {
    components: {
        LocalPlanningAuthorityTable,
    },

    props: {
        localPlanningAuthorities: {
            type: Object,
            default: () => ({}),
        },
        loggedUser: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {};
    },
    mounted() {

    },
};
</script>
