export default [{
    name: 'identifiedLand',
    title: 'Identified Land',
    color: '#627086',
    routeName: 'plots',
    query: {
        status: 'Identified land',
    },
}, {
    name: 'ecologicalStudyCertified',
    title: 'Ecological Study Certified',
    color: '#6967D5',
    routeName: 'plots',
    query: {
        status: 'Ecological Study Certified',
    },
}, {
    name: 'pendingContracts',
    title: 'Pending Contracts',
    color: '#E47D44',
    routeName: 'plots',
    query: {
        status: 'Pending contracts',
    },
}, {
    name: 'completedContracts',
    title: 'Completed Contracts',
    color: '#1782FF',
    routeName: 'plots',
    query: {
        status: 'Completed contracts',
    },
}, {
    name: 'bngUnitsAvailable',
    title: 'Bng Units Available',
    color: '#5EBD8A',
    routeName: 'plots',
    query: {
        status: 'BNG Units available',
    },
}, {
    name: 'landFullyImplemented',
    title: 'Land Fully Implemented',
    color: '#E34B5D',
    routeName: 'plots',
    query: {
        status: 'Land Fully Implemented',
    },
}];
