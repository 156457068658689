var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "html2pdf" },
    [
      _c(
        "section",
        {
          staticClass: "layout-container",
          class: {
            "show-layout": _vm.showLayout,
          },
        },
        [
          _c(
            "section",
            {
              ref: "pdfContent",
              staticClass: "content-wraper",
              style: `width: ${_vm.pdfContentWidth};`,
            },
            [_vm._t("pdf-content")],
            2
          ),
        ]
      ),
      _vm._v(" "),
      _c("transition", { attrs: { name: "transition-anim" } }, [
        _vm.pdfFile && _vm.previewModal
          ? _c("section", { staticClass: "pdf-preview" }, [
              _c(
                "button",
                {
                  on: {
                    click: function ($event) {
                      if ($event.target !== $event.currentTarget) return null
                      _vm.pdfFile = null
                    },
                  },
                },
                [_vm._v("\n                ×\n            ")]
              ),
              _vm._v(" "),
              _c("iframe", {
                attrs: { src: _vm.pdfFile, width: "100%", height: "100%" },
              }),
            ])
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }