var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "example", attrs: { name: _vm.context.attributes.name } },
    [
      _c("quill-editor", {
        ref: "myTextEditor",
        staticClass: "editor",
        attrs: { value: _vm.content, options: _vm.options },
        on: { blur: _vm.context.blurHandler },
        model: {
          value: _vm.context.model,
          callback: function ($$v) {
            _vm.$set(_vm.context, "model", $$v)
          },
          expression: "context.model",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }