<template>
    <div class="html2pdf">
        <section
            class="layout-container"
            :class="{
                'show-layout' : showLayout,
            }"
        >
            <section
                ref="pdfContent"
                class="content-wraper"
                :style="`width: ${pdfContentWidth};`"
            >
                <slot name="pdf-content" />
            </section>
        </section>

        <transition name="transition-anim">
            <section
                v-if="pdfFile && previewModal"
                class="pdf-preview"
            >
                <button @click.self="pdfFile = null">
                    &times;
                </button>

                <iframe
                    :src="pdfFile"
                    width="100%"
                    height="100%"
                />
            </section>
        </transition>
    </div>
</template>

<script>

import html2pdf from 'html2pdf.js';

export default {
    name: 'Html2pdf',


    props: {
        pdfContentWidth: {
            type: String,
            default: '100%',
            // default: '1150px',
        },

        showLayout: {
            type: Boolean,
            default: false,
        },

        htmlToPdfOptions: {
            type: Object,
            default: null,
        },

        filename: {
            type: String,
            default: `${new Date().getTime()}`,
        },
        pdfQuality: {
            type: Number,
            default: 2,
        },

        pdfFormat: {
            default: 'a4',
        },
        enableDownload: {
            type: Boolean,
            default: true,
        },

        pdfOrientation: {
            type: String,
            default: 'portrait',
        },
        pageNumbers: {
            type: Boolean,
            default: true,
        },

        previewModal: {
            type: Boolean,
            default: false,
        },
        margin: {
            type: [Number, Array],
            default: () => [10, 0],
        },

        hasMap: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            completedSteps: 0,
            pdfFile: null,
        };
    },

    computed: {
        progress() {
            return (this.completedSteps / this.totalSteps);
        },

        options() {
            const defaults = {
                margin: this.margin,
                filename: `${this.filename}.pdf`,

                image: {
                    type: 'jpeg',
                    quality: 0.98,
                },

                enableLinks: false,

                html2canvas: {
                    scale: this.pdfQuality,
                    useCORS: true,
                },

                jsPDF: {
                    unit: 'mm',
                    format: this.pdfFormat,
                    orientation: this.pdfOrientation,
                },
            };

            return { ...defaults, ...this.htmlToPdfOptions };
        },

        totalSteps() {
            let steps = 3;

            if(this.pageNumbers) {
                steps++;
            }

            if(this.hasMap) {
                steps++;
            }

            if(this.enableDownload) {
                steps++;
            }

            return steps;
        },
    },

    watch: {
        progress(val) {
            this.$emit('progress', val);
        },
    },

    methods:{
        generatePdf() {
            this.completedSteps = 1;

            if (this.hasMap) {
                this.completedSteps++;
                setTimeout(() => {
                    this.downloadPdf();
                }, 1000);
            } else {
                this.downloadPdf();
            }
        },

        async downloadPdf() {
            const pdfContent = this.$refs.pdfContent;
            const options = this.options;

            this.completedSteps++;

            const html2PdfSetup = html2pdf().set(options).from(pdfContent);


            let pdfBlobUrl = null;

            if (this.pageNumbers) {
                await html2PdfSetup.toPdf().get('pdf').then((pdf) => {
                    const totalPages = pdf.internal.getNumberOfPages();

                    for (let idx = 1; idx <= totalPages; idx++) {
                        pdf.setPage(idx);
                        pdf.setFontSize(10);
                        pdf.setTextColor(150);
                        pdf.text('Page ' + idx + ' of ' + totalPages, (pdf.internal.pageSize.getWidth() * 0.88), (pdf.internal.pageSize.getHeight() - 4));
                    }
                });

                this.completedSteps++;
            }

            if (this.previewModal) {
                this.pdfFile = await html2PdfSetup.output('bloburl');
                pdfBlobUrl = this.pdfFile;
            }

            if (this.enableDownload) {
                this.completedSteps++;
                pdfBlobUrl = await html2PdfSetup.save().output('bloburl');
            }

            if (pdfBlobUrl) {
                const res = await fetch(pdfBlobUrl);
                const blobFile = await res.blob();

                this.$emit('afterDownload', blobFile);
            }

            this.completedSteps++;
        },
    },
};
</script>
