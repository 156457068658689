<template>
    <app-layout section="map">
        <page-header
            title="Map"
            icon="map-icon"
        >
            <inertia-link
                class="btn-reset btn btn--primary"
                :href="$route('habitat.create')"
            >
                <span>Edit Habitats</span>
            </inertia-link>
        </page-header>
        <div class="center">
            <div class="flex relative-height">
                <Sidebar
                    key="sidebar"
                    v-model="filters"
                    :accordions="accordions"
                    :current-zoom="currentZoom"
                    @change="sidebarChange"
                />

                <Map
                    :filters="filters"
                    :layers="layersList"
                    :data="data"
                    :fit-bounds="operatingIn.length ? true : false"
                    @loaded="(map, zoom) => currentZoom = zoom"
                    @zoomChanged="zoom => currentZoom = zoom"
                    @delete="refreshHabitats"
                />
            </div>
        </div>
    </app-layout>
</template>

<script>
import mapMixin from 'Mixins/map';
import arcgisAPI from 'Mixins/arcgisAPI';
import Map from './Map';
import Sidebar from './Sidebar';
import { addAdditionalFields } from 'Lib/defaults/habitatData';

export default {
    name: 'MapPage',
    components: {
        Map,
        Sidebar,
    },
    mixins: [
        mapMixin,
        arcgisAPI,
    ],
    props: {
        lpas: {
            type: Array,
            default: () => ([]),
        },
        operatingIn: {
            type: Array,
            default: () => ([]),
        },
        rivers: {
            type: Array,
            default: () => ([]),
        },
        layers: {
            type: Array,
            default: () => ([]),
        },
        projects: {
            type: Object,
            default: () => ({}),
        },
        plots: {
            type: Object,
            default: () => ({}),
        },
        habitats: {
            type: Array,
            default: () => ([]),
        },
    },
    data() {
        return {
            data: [],
            accordions: [],
            currentZoom: null,
            filters: {
                projects: true,
                plots: true,
            },
            options: {},
            layersList: this.layers,
            additionalFields: {
                land: {
                    cropland: [{
                        value: 'associated-land',
                        label: 'Associated Land',
                    }],
                    'woodland-and-forest': [{
                        value: 'individual-trees',
                        label: 'Individual Trees',
                    }],
                    urban: [{
                        value: 'house',
                        label: 'House',
                    }, {
                        value: 'barn',
                        label: 'Barn',
                    }, {
                        value: 'shed',
                        label: 'Shed',
                    }],
                },
            },
            orderedHabitatData: null,
            plotsAmount: 1,
            projectsAmount: 1,
            lpaFilters: this.operatingIn.length ? this.operatingIn : this.lpas,
        };
    },
    computed: {
        sequestLayers() {
            const layers = [];

            let lpaFilter, riverFilter;

            if (this.lpaFilters && this.lpaFilters.length) {
                lpaFilter = {
                    name: 'lpa',
                    placeholder: 'LPA',
                    options: this.lpaFilters.map(lpa => ({
                        label: lpa.name,
                        value: lpa.name,
                    })),
                };
            }

            if (this.rivers && this.rivers.length) {
                riverFilter = {
                    name: 'river_basin_district',
                    placeholder: 'River Catchment Area',
                    options: this.rivers.map(lpa => ({
                        label: lpa.name,
                        value: lpa.name,
                    })),
                };
            }

            if (this.projectsAmount) {
                const options = [];

                if (lpaFilter) {
                    options.push({...lpaFilter});
                }

                if (riverFilter) {
                    options.push({...riverFilter});
                }

                if (this.projectsAmount > 1) {
                    options.push({
                        name: 'page',
                        Label: 'Page',
                        placeholder: 'Page 1',
                        options: Array(this.projects ? this.projectsAmount : 0).fill(0).map((value, index) => ({
                            label: 'Page ' + (index + 1),
                            value: index + 1,
                        })),
                    });
                }

                layers.push({
                    type: 'local',
                    'data-type': 'projects',
                    name: 'projects',
                    label: 'Developments',
                    color: this.mapColors.project,
                    options: options,
                });
            }

            if (this.plotsAmount) {
                const options = [];

                if (lpaFilter) {
                    options.push({...lpaFilter});
                }

                if (riverFilter) {
                    options.push({...riverFilter});
                }

                if (this.plotsAmount > 1) {
                    options.push({
                        name: 'page',
                        Label: 'Page',
                        placeholder: 'Page 1',
                        options: Array(this.plots ? this.plotsAmount : 0).fill(0).map((value, index) => ({
                            label: 'Page ' + (index + 1),
                            value: index + 1,
                        })),
                    });
                }

                layers.push({
                    type: 'local',
                    'data-type': 'plots',
                    name: 'plots',
                    label: 'Land Parcels',
                    color: this.mapColors.plot,
                    options: options,
                });
            }

            return layers;
        },

        habitatLayersAccordion() {
            const mainMenu = Object.keys(this.orderedHabitatData).map(category => {
                const parent = {
                    type: 'local',
                    'data-type': 'habitats',
                    name: category,
                    label: this.capitalizeFirstLetter(category.replace(/-/g, ' ')),
                    color: this.colors[category],
                };
                const children = this.orderedHabitatData[category].length > 1 ?
                    this.orderedHabitatData[category].map(habitat => {
                        return {
                            type: 'local',
                            'data-type': 'habitats',
                            name: habitat.value,
                            label: habitat.label,
                            color: this.colors[habitat.value],
                            parent: parent,
                        };
                    }) : [];

                parent.items = children;

                return parent;
            });

            return mainMenu;
        },

        habitatLayers() {
            let allItems = [];

            Object.keys(this.orderedHabitatData).forEach(category => {
                allItems = allItems.concat(this.orderedHabitatData[category].map(habitat => ({
                    type: 'local',
                    'data-type': 'habitats',
                    name: habitat.value,
                    label: habitat.label,
                    color: this.colors[habitat.value],
                })));
            });

            return allItems;
        },

        buildList() {
            const accordionsDefault = [{
                name: 'BNG Partnership Layers',
                items: [],
            }];

            accordionsDefault[0].items = this.sequestLayers;

            accordionsDefault.push({
                name: 'Habitat Layers',
                items: this.habitatLayersAccordion,
            });

            this.layers.forEach((layer) => {
                this.pushOtherLayers(layer, accordionsDefault);
            });

            return [...accordionsDefault];
        },
    },
    async mounted() {
        this.colors = this.mapColors;
        this.orderedHabitatData = addAdditionalFields(this.additionalFields);

        this.layersList = this.layersList.concat(this.sequestLayers);
        this.layersList = this.layersList.concat(this.habitatLayers);

        await this.getOtherLayers();
        await this.refreshProjects();
        await this.refreshPlots();
        await this.refreshHabitats();

        this.accordions = this.buildList;

        this.combineData();
    },
    methods: {
        async sidebarChange(filters, options, layer) {
            const oldOptions = {...this.options};

            this.options = {...options};

            if (layer.name === 'projects') {
                if (oldOptions.projects_page === this.options.projects_page) {
                    this.options.projects_page = 1;
                }

                if (!this.projects.data || (oldOptions !== this.options)) {
                    await this.refreshProjects();
                }
            }

            if (layer.name === 'plots') {
                if (oldOptions.plots_page === this.options.plots_page) {
                    this.options.plots_page = 1;
                }

                if (!this.plots.data || (oldOptions !== this.options)) {
                    await this.refreshPlots();
                }
            }

            if (!this.habitats || !this.habitats.length) {
                await this.refreshHabitats();
            }

            this.accordions = this.buildList;

            this.combineData();
        },

        async refreshProjects() {
            return new Promise(resolve => {
                if (this.filters.projects) {
                    this.$inertia.get(this.$route('map'), {
                        page: this.options.projects_page ?? 1,
                        planning_auth: this.options.projects_lpa ?? null,
                        river_basin_district: this.options.projects_river_basin_district ?? null,
                    }, {
                        preserveState: true,
                        preserveScroll: true,
                        only: ['projects'],
                        replace: true,
                        onSuccess: (data) => {
                            this.projectsAmount = data.props.projects.last_page;
                            this.combineData();
                            resolve();
                        },
                    });
                } else {
                    resolve();
                }
            });
        },

        async refreshPlots() {
            return new Promise(resolve => {
                if (this.filters.plots) {
                    this.$inertia.get(this.$route('map'), {
                        page: this.options.plots_page ?? 1,
                        planning_auth: this.options.plots_lpa ?? null,
                        river_basin_district: this.options.plots_river_basin_district ?? null,
                    }, {
                        preserveState: true,
                        preserveScroll: true,
                        only: ['plots'],
                        replace: true,
                        onSuccess: (data) => {
                            this.plotsAmount = data.props.plots.last_page;
                            this.combineData();
                            resolve();
                        },
                    });
                } else {
                    resolve();
                }
            });
        },

        async refreshHabitats() {
            return new Promise(resolve => {
                const habitatEnabled = this.habitatLayers.map(habitat => {
                    return this.filters[habitat.name] ? habitat.name : null;
                }).filter(habitat => habitat !== null);

                if (habitatEnabled.length) {
                    this.$inertia.get(this.$route('map'), {}, {
                        preserveState: true,
                        preserveScroll: true,
                        only: ['habitats'],
                        replace: true,
                        onSuccess: () => {
                            this.combineData();
                            resolve();
                        },
                    });
                } else {
                    resolve();
                }
            });
        },

        async getOtherLayers() {
            return new Promise(resolve => {
                if (this.layers.length) {
                    this.layers.forEach((layer) => {
                        this.pushOtherLayers(layer);
                    });
                    resolve();

                    return;
                }

                this.$inertia.get(this.$route('map'), {}, {
                    preserveState: true,
                    preserveScroll: true,
                    only: ['layers'],
                    replace: true,
                    onSuccess: () => {
                        this.layers.forEach((layer) => {
                            this.pushOtherLayers(layer);

                            if (this.layersList.filter(list => layer.name !== list.name).length) {
                                this.layersList.push(layer);
                            }
                        });

                        resolve();
                    },
                });
            });
        },

        pushOtherLayers(layer, list = this.accordions) {
            const objPush = {
                name: layer.name,
                label: layer.label,
                color: layer.color.toLowerCase(),
                api: null,
            };

            if (layer.type === 'arcgisAPI') {
                objPush.api = layer.url;
                objPush.api_filter = layer.api_filter;
                objPush.api_zoom = 12;
                layer.api_zoom = 12;
            }

            let blnPushed = false;

            list.forEach((accordion) => {
                if (accordion.name === layer.section) {
                    accordion.items.push(objPush);
                    blnPushed = true;
                }
            });

            if (!blnPushed) {
                list.push({
                    name: layer.section,
                    items: [objPush],
                });
            }
        },

        combineData() {
            this.data = [
                ...(this.operatingIn ? this.operatingIn.map(data => ({...data, 'data-type': 'lpa'})) : []),
                ...(this.projects && this.projects.data ? this.projects.data.map(data => ({...data, 'data-type': 'projects'})) : []),
                ...(this.plots && this.plots.data ? this.plots.data.map(data => ({...data, 'data-type': 'plots'})) : []),
                ...(this.habitats ? this.habitats.map(data => ({...data, 'data-type': 'habitats'})) : []),
            ];
        },
    },
};
</script>
