var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "app-layout",
    { attrs: { section: "local-planning-authorities" } },
    [
      _c(
        "page-header",
        { attrs: { title: "Operating in", icon: "building" } },
        [
          _vm.hasPolicy("operatingIn_create")
            ? _c(
                "button",
                {
                  staticClass: "btn btn--primary",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.addLPA()
                    },
                  },
                },
                [
                  _c("SvgController", { attrs: { type: "plus" } }),
                  _vm._v("\n            Add Area\n        "),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "center" },
        [
          _c("local-planning-authority-table", {
            attrs: {
              "local-planning-authorities": _vm.localPlanningAuthorities,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("operating-in-form", { ref: "operatingInCreate" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }