var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "filter", staticClass: "filter" },
    [
      _c("div", { staticClass: "filter-toggle" }, [
        _c(
          "button",
          {
            staticClass: "filter-toggle-btn",
            class: { active: _vm.showFilter },
            on: { click: _vm.toggleShowFilter },
          },
          [_c("SvgController", { attrs: { type: "filter" } })],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "FormulateForm",
        { staticClass: "filter-form", class: { active: _vm.showFilter } },
        [
          _c("div", { staticClass: "filter-form-header" }, [
            _c(
              "div",
              {
                staticClass: "btn filter-toggle-btn",
                on: { click: _vm.toggleShowFilter },
              },
              [_vm._v("\n                Close\n            ")]
            ),
          ]),
          _vm._v(" "),
          _c(
            "InputSuffix",
            {
              scopedSlots: _vm._u([
                {
                  key: "suffix",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        { staticClass: "formulate-input-info" },
                        [_c("SvgController", { attrs: { type: "search" } })],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _vm.includeSearch ||
              Object.prototype.hasOwnProperty.call(_vm.filterValues, "search")
                ? _c("FormulateInput", {
                    staticClass: "search",
                    attrs: { type: "text", placeholder: _vm.searchPlaceholder },
                    on: {
                      change: function ($event) {
                        return _vm.handleFilter()
                      },
                    },
                    model: {
                      value: _vm.filterValues.search,
                      callback: function ($$v) {
                        _vm.$set(_vm.filterValues, "search", $$v)
                      },
                      expression: "filterValues.search",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _vm._l(_vm.fields, function (field) {
            return [
              _c("FormulateInput", {
                key: field.name,
                attrs: {
                  options: field.options,
                  type: field.type || "select-plugin",
                  searchable: field.searchable ? field.searchable : false,
                  "reduce-key": field.reduce || "value",
                  "label-key": "label",
                  label: field.label,
                  placeholder: field.placeholder,
                  "show-clear": "",
                },
                on: {
                  change: function ($event) {
                    return _vm.handleFilter()
                  },
                },
                model: {
                  value: _vm.filterValues[field.name],
                  callback: function ($$v) {
                    _vm.$set(_vm.filterValues, field.name, $$v)
                  },
                  expression: "filterValues[field.name]",
                },
              }),
            ]
          }),
          _vm._v(" "),
          _vm.companies && _vm.companies.length > 1
            ? _c("FormulateInput", {
                attrs: {
                  options: _vm.companies,
                  type: "select-plugin",
                  placeholder: "All Companies",
                  searchable: false,
                },
                on: {
                  change: function ($event) {
                    return _vm.handleFilter()
                  },
                },
                model: {
                  value: _vm.filterValues.company_id,
                  callback: function ($$v) {
                    _vm.$set(_vm.filterValues, "company_id", $$v)
                  },
                  expression: "filterValues.company_id",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.clear
            ? _c(
                "button",
                {
                  staticClass: "btn-reset btn formulate-input filter-clear-btn",
                  on: { click: _vm.resetFilter },
                },
                [_vm._v("\n            Clear Filter\n        ")]
              )
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }