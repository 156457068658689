<template>
    <modal ref="treeModal">
        <template #header>
            <h3>Edit Tree</h3>
        </template>
        <template #body>
            <FormulateForm
                v-model="form"
                :errors="form.errors"
                :schema="formSchema"
                class="flex-col"
                @submit="submitForm"
            />
        </template>
    </modal>
</template>

<script>

import Modal from 'Utilities/modal/Modal';

export default {
    name: '',
    components: {
        Modal,
    },

    props: {
        tree: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            form: {
                name: null,
                slope: null,
                constant: null,
                ...this.tree,
            },
            formSchema: [{
                name: 'name',
                type: 'text',
                label: 'Name',
                required: true,
            }, {
                name: 'slope',
                type: 'text',
                label: 'Slope',
                required: true,
                validation: 'required|number',
            }, {
                name: 'constant',
                type: 'text',
                label: 'Constant',
                required: true,
                validation: 'required|number',
            }, {
                type: 'submit',
            }],
        };
    },

    mounted() {
        this.$refs.treeModal.openModal();
    },

    methods: {
        submitForm(data) {
            if (this.tree.id) {
                this.$inertia.patch(this.$route('tree.update', this.tree.id), data, {
                    onSuccess: () => {
                        this.$refs.treeModal.closeModal();
                    },
                });

                return;
            }

            this.$inertia.put(this.$route('tree.store'), data, {
                onSuccess: () => {
                    this.$refs.treeModal.closeModal();
                },
            });

        },
    },
};
</script>
