var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("modal", {
    ref: "historyModal",
    attrs: { width: "50em" },
    scopedSlots: _vm._u([
      {
        key: "header",
        fn: function () {
          return [_c("h3", [_vm._v("\n            HISTORY\n        ")])]
        },
        proxy: true,
      },
      {
        key: "body",
        fn: function () {
          return [
            _c("TableGridLayout", {
              attrs: {
                columns: _vm.columns,
                rows: _vm.attachments,
                output: ["table"],
                mode: "remote",
                "total-records": _vm.attachments.length,
              },
            }),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }