export default [{
    name: 'landownersTotal',
    title: 'Landowners',
    icon: 'breifcase',
    color: '#E34B5D',

}, {
    name: 'totalNetGainSites',
    title: 'Total Net Gain Sites',
    icon: 'plots',
    color: '#5EBD8A',
    routeName: 'plots',
    query: {},
}, {
    name: 'implementedNetGainSites',
    title: 'Implemented Net Gain Sites',
    icon: 'plots',
    color: '#5EBD8A',
    routeName: 'plots',
    query: {},
}, {
    name: 'availableNetGainSites',
    title: 'Available Net Gain Sites',
    icon: 'plots',
    color: '#5EBD8A',
    routeName: 'plots',
    query: {},
}, {
    name: 'totalBngUnits',
    title: 'Total BNG units',
    icon: 'plots',
    color: '#5EBD8A',
    routeName: 'plots',
    query: {},
}, {
    name: 'bngUnitsSold',
    title: 'BNG units sold',
    icon: 'plots',
    color: '#5EBD8A',
    routeName: 'plots',
    query: {},
}, {
    name: 'bngUnitsAvailable',
    title: 'BNG units available',
    icon: 'plots',
    color: '#5EBD8A',
    routeName: 'plots',
    query: {},
}];
