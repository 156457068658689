<template>
    <app-layout section="profile">
        <page-header
            title="My Profile"
            icon="user"
        >
            <inertia-link
                :href="$route('profile.edit')"
                class="btn btn--primary"
            >
                Edit Profile
            </inertia-link>
        </page-header>
        <div class="center">
            <div class="profile-info">
                <div class="profile-info-img flex-center">
                    <span>{{ userInitials }}</span>
                </div>
                <div class="profile-info-content">
                    <h3>{{ loggedUser.first_name + ' ' + loggedUser.last_name }}</h3>
                    <div class="profile-info-content-email">
                        <h5>Email Address</h5>
                        <div>
                            {{ loggedUser.email }}
                            <p>
                                <a
                                    href="#"
                                    @click="changePassword()"
                                >Change password</a>
                            </p>
                        </div>

                        <alert
                            v-if="flash.status === 'password-updated'"
                            type="success"
                            :inline="true"
                        >
                            <p>Password updated successfully.</p>
                        </alert>
                    </div>
                    <div class="profile-info-content-company">
                        <h5>{{ positions.length > 1 ? 'Companies' : 'Company' }}</h5>
                        <ul
                            v-for="position in positions"
                            :key="position.id"
                            class="lst-reset"
                        >
                            <li>
                                <a
                                    v-if="loggedUser.can.includes('main_admin_edit')"
                                    href="#"
                                    @click="updateCompany(position.company, position.group.name)"
                                >{{ position.company.name }}</a>
                                <span v-else>{{ position.company.name }}</span>
                                <span v-if="positions.length > 1">
                                    {{ position.role.label }}
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <modal ref="passwordModal">
            <template #header>
                <h3>Change Password</h3>
            </template>
            <template #body>
                <update-password
                    :errors="errors"
                    @closeModal="closePasswordModal"
                />
            </template>
        </modal>
        <modal ref="companyModal">
            <template #header>
                <h3>Edit Company</h3>
            </template>
            <template #body>
                <CompanyEdit
                    :company="editCompany"
                    :company-type="editCompanyType"
                    @closeModal="closeCompanyModal"
                />
            </template>
        </modal>
    </app-layout>
</template>

<script>
import Modal from 'Utilities/modal/Modal';
import Alert from 'Utilities/alert/Alert';
import UpdatePassword from './UpdatePassword';
import CompanyEdit from './CompanyEdit';
export default {
    name: 'ProfileShow',
    components: {
        Modal,
        Alert,
        UpdatePassword,
        CompanyEdit,
    },
    props: {
        loggedUser: {
            type: Object,
            required: true,
        },
        errors: {
            type: Object,
            required: true,
        },
        flash: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            positions: [],
            form: this.$inertia.form({
                current_password: null,
                password: null,
                password_confirmation: null,
            }),
            editCompany: {},
            editCompanyType: '',
        };
    },
    computed: {
        userInitials() {
            let initials = this.loggedUser.first_name.substring(0, 1).toUpperCase();

            initials += this.loggedUser.last_name.substring(0, 1).toUpperCase();

            return initials;
        },
    },
    created() {
        this.positions = this.loggedUser.positions;
    },
    methods: {
        submitForm() {
            this.form.put(this.$route('profile-password.update'));
            if (Object.keys(this.errors).length < 1)
                this.$refs.passwordModal.closeModal();
        },
        changePassword() {
            this.$refs.passwordModal.openModal();
        },
        closePasswordModal() {
            this.$refs.passwordModal.closeModal();
        },
        updateCompany(company, type) {
            this.editCompany = company;

            if (type) {
                this.editCompanyType = type;
            }

            this.$refs.companyModal.openModal();
        },
        closeCompanyModal() {
            this.editCompany = {};
            this.$refs.companyModal.closeModal();
        },
    },
};
</script>
