var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "calclator" },
    [
      _c(
        "FormulateForm",
        {
          staticClass: "flex-col margin-0",
          model: {
            value: _vm.form,
            callback: function ($$v) {
              _vm.form = $$v
            },
            expression: "form",
          },
        },
        [
          _c("p", { staticClass: "align-right" }, [
            _vm._v(
              "\n            Total Carbon " +
                _vm._s(_vm.totalCarbon) +
                "kg\n            "
            ),
            _c("br"),
            _vm._v("\n            Total CO"),
            _c("sub", [_vm._v("2")]),
            _vm._v("-e " + _vm._s(_vm.totalCo2e) + "kg\n        "),
          ]),
          _vm._v(" "),
          _c("FormulateInput", {
            attrs: {
              name: "trees",
              "add-label": "+ Add Tree",
              type: "group",
              repeatable: true,
              "element-class":
                "formulate-input-element formulate-input-element--collapsable-group",
              "outer-class": "formulate-input collapsable-group",
            },
            on: {
              repeatableAdded: function ($event) {
                _vm.showIndex = $event.length - 1
              },
              repeatableRemoved: function ($event) {
                _vm.showIndex = $event.length - 1
              },
            },
            scopedSlots: _vm._u([
              {
                key: "repeatable",
                fn: function (context) {
                  return [
                    _c("collapsable-group-item", {
                      attrs: {
                        context: context,
                        title: "Tree",
                        "show-index": _vm.showIndex,
                      },
                      on: {
                        open: function ($event) {
                          _vm.showIndex = $event
                        },
                        itemRemoval: _vm.removeItem,
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "title",
                            fn: function ({ index }) {
                              return [
                                _c("div", { staticClass: "title-left" }, [
                                  _vm._v(
                                    "\n                            Tree #" +
                                      _vm._s(index + 1) +
                                      "\n                        "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "title-left" }, [
                                  _vm._v(
                                    "\n                            Carbon " +
                                      _vm._s(_vm.carbonValues[index] || 0) +
                                      "kg\n                            "
                                  ),
                                  _c("br"),
                                  _vm._v("\n                            CO"),
                                  _c("sub", [_vm._v("2")]),
                                  _vm._v(
                                    "-e " +
                                      _vm._s(_vm.co2eValues[index] || 0) +
                                      "kg\n                        "
                                  ),
                                ]),
                              ]
                            },
                          },
                          {
                            key: "default",
                            fn: function ({ index }) {
                              return [
                                _c("p", [
                                  _vm._v(
                                    "getCategory: " +
                                      _vm._s(_vm.getCategory(index))
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("p", [
                                  _vm._v(
                                    "soze: " +
                                      _vm._s(
                                        _vm.form.trees[index]
                                          ? _vm.form.trees[index].size
                                          : "CANNOT FIND"
                                      )
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("FormulateInput", {
                                  attrs: {
                                    type: "select-button",
                                    name: "category",
                                    options: ["Tree", "Shrub"],
                                    "element-class":
                                      "formulate-input-element formulate-input-element--select-button formulate-input-element-habitat-btn",
                                  },
                                }),
                                _vm._v(" "),
                                _c("FormulateInput", {
                                  attrs: { type: "hidden", name: "id" },
                                }),
                                _vm._v(" "),
                                _c("FormulateInput", {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.getCategory(index) === "Tree",
                                      expression:
                                        "getCategory(index) === 'Tree'",
                                    },
                                  ],
                                  attrs: {
                                    type: "select-plugin",
                                    name: "type",
                                    label: "Type",
                                    validation: "required",
                                    "validation-name": "Type",
                                    placeholder: "Select Type",
                                    "label-key": "name",
                                    "reduce-key": "name",
                                    options: _vm.treeTypes,
                                  },
                                  on: {
                                    input: function ($event) {
                                      return _vm.calculate(index)
                                    },
                                  },
                                }),
                                _vm._v(" "),
                                _c(
                                  "InputSuffix",
                                  {
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "suffix",
                                          fn: function () {
                                            return [
                                              _vm.getCategory(index) === "Tree"
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "formulate-input-info",
                                                    },
                                                    [_c("p", [_vm._v("CM")])]
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _vm.getCategory(index) === "Shrub"
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "formulate-input-info",
                                                    },
                                                    [_c("p", [_vm._v("M")])]
                                                  )
                                                : _vm._e(),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  },
                                  [
                                    _c("FormulateInput", {
                                      attrs: {
                                        type: "text",
                                        name: "size",
                                        label: _vm.getSizeLabel(index),
                                        validation: "required|number",
                                        "outer-class":
                                          "formulate-input formulate-size",
                                      },
                                      on: {
                                        input: function ($event) {
                                          return _vm.calculate(index)
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    }),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }