var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "label",
    { class: _vm.selected ? "selected" : "", attrs: { for: _vm.group.label } },
    [
      _c(
        "div",
        { staticClass: "account-select-item-checkbox" },
        [
          _vm.selected
            ? _c("SvgController", { attrs: { type: "tick" } })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "account-select-item-icon" },
        [_c("SvgController", { attrs: { type: _vm.group.name } })],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "account-select-item-text" }, [
        _c("h4", [_vm._v(_vm._s(_vm.group.label))]),
        _vm._v(" "),
        _c("p", [_vm._v(_vm._s(_vm.group.description))]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }